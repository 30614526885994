@import "../../../../assets/styles/Variables";
@import "../../../../assets/styles/Responsive";

.send-smacktalk-message-sections > .send-smacktalk-message-1-section {
  padding: 0 20px 30px 20px;
  max-width: 33%;
  & > .hme-toggle {
    & > .toggle-button-group.btn-group {
      & > button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &.hme-submit-button-component {
          & > svg > g > path#Path_57 {
            fill: $main-white;
          }
          & > svg > g > g > path {
            fill: $main-white;
          }
        }
      }
    }
  }
  & > .hme-select-stacked-wrapper {
    & > .hme-select-stacked {
      margin: 15px 15px 0 0;
      & > .hme-select-stacked-text {
        margin: 13px 0 0 25px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }  
    }
    & > .hme-select-stacked-dropdown {
      & > .hme-select-stacked-dropdown-item {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
@include smaller($l-desktop) {
  .send-smacktalk-message-sections > .send-smacktalk-message-1-section {
    max-width: unset;
  }
}