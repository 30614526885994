@import 'src/assets/styles/Variables';

.configuration-history {
    --gap: 20px;

    &__accordion {
        padding: 20px 0;

        border-bottom: 1px solid $separator-color;

        .hme-label {
            color: $main-gray;
        }

        &.hme-accordion.hme-accordion--expanded .hme-accordion__content {
            display: flex;
            flex-direction: column;
            gap: calc(2 * var(--gap));
        }
    }

    &__item,
    &__item-content {
        display: flex;
        gap: var(--gap);
    }

    &__item {
        flex-direction: column;
    }

    &__item-title {
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0.2px;
        color: $main-black;
    }

    &__item-content {
        flex-wrap: wrap;
    }

    &__info-field {
        flex-basis: calc(50% - var(--gap));
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 8px;

        &--full-width {
            flex-basis: 100%;
        }

        .hme-label {
            margin: 0;
            font-size: 14px;
            line-height: 1.14;
            letter-spacing: 1.26px;
            color: $main-gray;
        }

        &--changed {
            color: #cc0738;
        }

        .hme-info-field-content:empty {
            display: none;
        }
    }
}
