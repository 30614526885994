@import 'src/assets/styles/Variables';

.hme-sidebar-overlay {
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    transition: ease 0.2s all;

    &--active {
        opacity: 1;
        visibility: visible;
    }

    &__overlay {
        position: absolute;
        z-index: 3;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
        width: 100%;
        height: 100%;
    }

    &__sidebar {
        left: -$sidebar-overlay-width;
        width: $sidebar-overlay-width;
        height: 100%;
        background: #fff;
        padding: 20px;
        position: relative;
        z-index: 4;
        transition: ease 0.2s all;
        box-shadow: inset 0 7px 9px -7px rgba(0, 0, 0, 0.4); // TODO remove this shadow when the main sub header shadow is fixed

        &--active {
            left: 0;
        }

        &__close-btn {
            position: absolute;
            top: 16px;
            right: 16px;
            width: 16px;
            height: 16px;
            box-sizing: content-box;
        }

        &__container {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 100%;

            display: flex;
            flex-direction: column;
        }

        &__wrapper {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            /* for Firefox */
            min-height: 0;
        }

        &__header {
            display: flex;
            flex: 0 0 auto;
            padding: 20px 24px 17px 0;
            margin: 0 20px;
            border-bottom: 1px solid #d1cdcd;

            .hme-label {
                font-family: Assistant;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 1.28px;
                text-align: left;
                color: #606672;
                line-height: 30px;
            }
        }

        &__header-icon {
            margin-right: 8px;

            svg {
                width: 30px;
                height: 30px;
            }
        }

        &__content {
            margin: 0 0 0 20px;
            padding: 0 20px 0 0;
            flex-grow: 1;
            overflow: auto;
            /* for Firefox */
            min-height: 0;
        }
    }

    &--right-variant {
        flex-direction: row-reverse;

        .hme-sidebar-overlay {
            &__sidebar {
                left: auto;
                right: -$sidebar-overlay-width;

                &--active {
                    left: auto;
                    right: 0;
                }
            }
        }
    }
}
