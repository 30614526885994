.hme-error-display {
  justify-content: center;
  flex-direction: row;
  text-align: center;
  height: 68vh;

  margin-top: 7%;

  .hme-error-display__warning-icon {
    height: 60px;
  }

  .hme-error-display__error-text {
    font-family: Assistant;
    font-size: 22px;
    margin-top: 20px;
    font-weight: 600;

  }

  .hme-error-display__report-text {
    font-size: 16px;
    font-family: Assistant;
    margin: 0px;
    color: #000819;
  }

  .hme-error-display__back-button {
    margin-top: 15px;
  }

  .hme-contact {
    align-items: center;
    justify-content: center;
  }

  .contact-hme__title {
    text-align: center;

  }

  .hme-contact .hme-title-section {
    text-align: center;
    margin-bottom: 15px;
    margin-top: 15px;
    font-size: 20px;
  }

  .hme-contact__labels .hme-label {
    margin-bottom: 10px;
  }

  .hme-contact__value {
    margin-bottom: 10px;
    margin-left: 0px;
    text-align: left;
  }


}
