.hme-tooltip {
    &.device-upgrade-tooltip {
        min-width: 360px;
        max-width: 500px;

        &.bs-tooltip-bottom {
            padding: 0.8rem 0;
        }
        .tooltip-inner {
            border-radius: 10px;
            border: 1px solid #0074ef;

            .hme-tooltip-content {
                padding: 21px 16px;

                .tooltip-headline {
                    .icon {
                        margin: 7px 9px 0 0;
                    }
                }
            }
        }

        .hme-grid {
            .hme-grid-header {
                position: static;
            }

            .hme-grid-row {
                padding: 0 10px;

                &.status-not-applicable {
                    .hme-grid-cell-content {
                        color: #5c6670;
                    }
                }

                &.status-failed {
                    .status-cell {
                        .hme-grid-cell-content {
                            color: #f00;
                        }
                    }
                }
            }

            .hme-grid-cell {
                padding: 6px 0;

                .hme-grid-cell-content {
                    word-break: break-word;
                    padding-left: 13px;
                }

                &.progress-cell {
                    .hme-grid-cell-content {
                        padding-left: 8px;
                    }
                }

                &:first-of-type {
                    .hme-grid-cell-content {
                        padding-left: 0;
                    }
                }
            }
        }

        .arrow {
            overflow: hidden;
            width: 2rem;
            height: calc(0.8rem + 1px);
            top: 0;

            &::before {
                border-top-color: #ffffff;
                border-bottom-color: #ffffff;
                border-width: 0 1rem 0.8rem;
            }

            &::after {
                content: '';
                position: absolute;
                bottom: 1px;
                border: solid transparent;
                border-width: 0 1rem 0.8rem;
                border-bottom-color: #0074ef;

                margin-left: 0;
                transform: none;
                box-shadow: none;
            }
        }
    }
}
