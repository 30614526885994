.hme-titled-row-select {
    & > .hme-select {
        width: 100%;
        height: 48px;
        border-radius: 24px;
        border: solid 1px #a7adba;
        background-color: #ffffff;
        position: relative;
        padding-right: 52px;
    
        & > div.hme-label {
            margin-left: 25px;
            margin-top: 3px;
            font-size: 12px;
            line-height: 1.33;
            letter-spacing: 1.08px;
            color: #606672;
            position: relative;
            text-transform: none;
    
            &:before {
                position: absolute;
                left: -9px;
                top: 2px;
                content: '*';
                font-family: Assistant;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: 1.44px;
                text-align: left;
                color: #186df4;
            }
        }

        & > .hme-select-icon {
            position: absolute;
            right: 6px;
            top: 10px;
            width: 42px;
            height: 24px;
            color: #606672;
        }
    
        & > .hme-select-dropdown {
            list-style-type: none;
            position: absolute;
            top: 100%;
            left: 10px;
            right: 10px;
            margin-top: 8px;
            border-radius: 8px;
            box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
            background-color: #ffffff;
            padding: 8px 0;
            display: none;
            overflow: auto;
            max-height: 200px;
            z-index: 100;
    
            & > .hme-select-dropdown-item {
                width: 100%;
                height: 40px;
                font-family: Assistant;
                padding: 10px 15px;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.31;
                letter-spacing: 1.28px;
                text-align: left;
                color: #000819;
    
                &:hover {
                    background-color: #2c6df4;
                    font-weight: 600;
                    color: #ffffff;
                }
            }
        }
    
        &.hme-select-opened {   
            .hme-select-dropdown {
                display: block;
            }
    
            .hme-select-icon {
                transform: rotate(180deg);
                color: #0074ef;
            }
        }
    }
    

    display: flex;
    position: relative;
    flex-direction: column;
    padding: 11px 0 10px 0;
    margin: 0;
    height: 60px;
    border-top: solid 1px #e8e9ed;
    & > .hme-titled-row-linc-icon {
        position: absolute;
        top: 29px;
        left: 0;
        width: 16px;
        color: #000819;
    }
    & > .hme-select, & > .hme-select-opened {
        border: none;
        border-radius: unset;
        background-color: unset;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 0 0 25px;
        user-select: none;

        & > .hme-select-text {
            display: none;
        }
        & > div.hme-label {
            margin-left: 0;
            margin-top: 0;
            font-family: Assistant;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.31;
            letter-spacing: 1.44px;
            text-align: left;
            color: #000819;
            &::before {
                display: none;
            }
        }
        & > .hme-select-icon {
            top: 5px;
        }
        & > .hme-select-dropdown {
            list-style-type: none;
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            margin-top: 0;
            border-radius: unset;
            box-shadow: none;
            background-color: unset;
            overflow: auto;
            max-height: unset;
            font-family: Assistant;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.29;
            letter-spacing: normal;
            text-align: left;
            color: #000819;
            & > .hme-select-dropdown-item {
                height: unset;
                padding: 0 0 10px 25px;
                user-select: none;
                font-family: Assistant;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.29;
                letter-spacing: normal;
                text-align: left;
                color: #000819;
                &:hover {
                    background-color:unset;
                    font-weight: normal;
                    color: #000819;
                }
            }
        }
    }
    &.no-hme-select-icon {
        & > .hme-select, & > .hme-select-opened {
            & > .hme-select-icon {
                display: none;
            }
        }
    }
}