@import 'src/mixins/breakpoints.scss';

@include before-desktop-only {
    .hme-roles__header {
        min-width: unset;

        .hme-account-email-header {
            margin-top: 0;
        }
    }
}

@include mobile-only {
    .hme-roles__header {
        flex-direction: column;

        .hme-title {
            margin-bottom: 11px;
        }

        .hme-roles__header__create-button {
            order: 3;
            margin-bottom: 0;
            width: 100%;
        }

        .hme-button-component {
            width: 100%;
        }

        .hme-account-email-header {
            order: 2;
            margin-bottom: 20px;
        }
    }
}