.hme-contact {
    display: flex;
    flex-flow: row wrap;

    .hme-title-section {
        margin-bottom: 30px;
        width: 100%;
    }

    &__labels {
        min-width: 83px;
        width: max-content;
        margin-right: 10px;

        .hme-label {
            display: block;
            margin-bottom: 30px;
        }
    }

    &__value {
        display: block;
        margin-bottom: 30px;
    }

    &__email {
        font-family: Assistant;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #0074ef;

        &:hover {
            color: #0074ef;
        }
    }
}

@import './Responsive.scss';
