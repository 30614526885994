@import 'src/mixins/breakpoints.scss';

@include mobile-only {
    .create-edit-scheduler {
        --sections-gap: 20px;
        --field-gap: 15px;

        &__form-wrapper {
            flex-wrap: unset;
            flex-direction: column;
        }

        &__form-fieldset {
            flex-basis: unset;
            max-width: unset;
            gap: 30px;
            
            &:not(:first-of-type) {
                padding-top: var(--sections-gap);
                padding-left: 0;

                border-left: none;
                border-top: var(--border);
            }

            &--3:not(:first-of-type) {
                flex-basis: 100%;
                max-width: 100%;

                border-left: none;
                border-top: var(--border);
            }
        }
    }
}
