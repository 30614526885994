@import "../../assets/styles/Variables";
@import "../../assets/styles/Responsive";

.hme-page-component.custom-smacktalk-page {
    padding-top: 50px;
    & > .hme-title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 0 25px 0;
        user-select: none;
        & > span {
            padding: 0 20px 0 0;
        }
        & > .smack-talk-logo {
            width: 36px;
        }
    }
    & > .hme-tabs-container {
        & > .hme-tabs-container__tabs {
            padding: 0 0 30px 0;
            border: none;
            margin: 0;
            & > .hme-button {
                height: 30px;
                margin: 0;
                padding: 2px 17px;
                font-family: Assistant;
                font-size: 15px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.27;
                letter-spacing: 0.5px;
                text-align: left;
                color: $main-gray;
                text-decoration: none;
                white-space: nowrap;

                &--bold {
                    height: 30px;
                    margin: 0;
                    padding: 2px 17px;
                    font-family: Assistant;
                    font-size: 15px;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.27;
                    letter-spacing: 0.5px;
                    text-align: left;
                    color: $main-color;
                    border-bottom: 2px solid $main-color;
                    text-decoration: none;
    
                }
            }
        }
    }
    & > .Toastify {
        & > .Toastify__toast-container {
            & > .Toastify__toast {
                & > .Toastify__toast-body {
                    & > .hme-notification-message {
                        & > .hme-notification-message-icon-block {
                            & > svg > g > g > path {
                                fill: $main-white;
                            }
                        }
                    }
                }
            }
        }
    }
}
@include smaller($l-desktop) {
    .hme-page-component.custom-smacktalk-page {
        padding: 15px 0 0 0;
        & > .hme-title {
            display: none;
        }
        & > .hme-tabs-container {
            padding: 0;
            & > .hme-tabs-container__tabs {
                display: flex;
                justify-content: center;
                box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.16);
                background-color: $main-white;
                padding: 0;
                margin: 0 0 21px 0;
                & > .hme-button {
                    font-size: 11px;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.27;
                    letter-spacing: 0.36px;
                }
            }
        }
        & > .Toastify {
            & > .Toastify__toast-container {
                width: 100%;
                display: flex;
                justify-content: center;
            }
        }
    }
}