.apply-device-settings-status-page-applied-by-filter-search-container {
    .hme-toggle {
        margin-bottom: 8px;

        .apply-device-settings-status-page-applied-by-filter-all-item {
            flex: 80;
        }

        .apply-device-settings-status-page-applied-by-filter-hme-item {
            flex: 90;
        }

        .apply-device-settings-status-page-applied-by-filter-customer-item {
            flex: 110;
        }
    }
}
