.hme-result-count .hme-result-count-count {
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0.03px;
  color: #606672; }
  .hme-result-count .hme-result-count-count .hme-result-count-label {
    font-family: Assistant;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.02px;
    text-align: left;
    color: #000819;
    vertical-align: text-bottom; }
