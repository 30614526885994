.hme-view-user__access__tree-text {
    display: inline-block;

    &.hme-view-user__access__tree-text--store {
        display: flex;
        width: 374px;

        .hme-view-user__access__tree-text__store-name {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            flex: 1;
        }

        .hme-view-user__access__tree-text__brand {
            width: 206px;
            padding-left: 15px;
        }
    }
}
