.device-upgrade-info {
    display: flex;
    justify-content: space-between;
    margin: 0 0 20px;

    .device-upgrade-info__box {
        width: 50%;
        margin: 0 10px;

        .device-upgrade-info__title {
            font-size: 14px;
            font-weight: normal;
            color: #606672;
            text-transform: uppercase;
        }

        .device-upgrade-info__text {
            font-size: 16px;
            color: #000819;
            margin: 0;
        }       
    }
}
