
.store-modal-popup.add-device-popup {
  width: 90% !important;
  max-width: 1110px!important;
}

.store-modal-popup.add-device-popup .store-modal-content{
  height: auto;
  min-height: auto;
  width: auto;
}
.store-modal-popup.add-device-popup .store-modal-content #store-tabs {
  width: 100%;  
}
#store-tabs {
  margin-right: 30px;
}
.store-modal-popup.adminPopUp.add-device-popup table.user_form {
  margin: 0 15px 0 0;
}
.store-modal-popup.adminPopUp.add-device-popup div.settings_list {
  padding: 0 0 0 15px;
  border-left: 1px solid rgb(100, 84, 84);

}
.store-modal-popup.adminPopUp.add-device-popup div.settings_list table {
  margin: 0;
}


.store-modal-popup.adminPopUp.add-device-popup div.settings_list td {
  font-size: 12px;
}

.store-modal-popup.adminPopUp.add-device-popup div.settings_list .ctable tr.theader th {
  vertical-align: middle;
  padding: 5px;
}

.store-modal-popup.adminPopUp.add-device-popup div.settings_list .ctable tr.theader th a span{
  font-size: 14px !important;
}

.add-device-container {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
}

.add-device-container .snapshots-no-records {
  width: 100%;
  text-align: center;
}

.add-device-title {
  font-size: 14px;
  font-weight: bold;
  padding: 20px 0 0 15px;
  margin: 0 0 15px 0;
}
.store-modal-popup.add-device-popup .store-modal-content #store-tabs .nav-item.active.disabled {
  color: rgba(0, 0, 0, 0);
}
.snapshot-table {
  width: 100%
}