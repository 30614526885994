/* -- container -- */
.avatar-modal,
.avatar-modal-mask {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.avatar-modal {
    position: fixed;
}

/* -- mask -- */
.avatar-modal-mask {
    position: absolute;
}

/* -- dialog -- */
.avatar-modal-dialog {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 101;
    /* padding: 15px; */
    /* background: #fff; */
    /* border-radius: 3px; */
    /* box-shadow: 0 1px 3px rgba(0, 0, 0, .2); */
    border: 1px solid #ccc;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
    overflow: hidden;
    background: #ffffff;
}

.avatar-modal-dialog:focus {
    outline: none;
}

/* -- close button -- */
.avatar-modal-close {
    position: absolute;
    cursor: pointer;
    top: 16px;
    right: 16px;
    width: 16px;
    height: 16px;
}

.avatar-modal-close:before,
.avatar-modal-close:after {
    position: absolute;
    content: '';
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #999;
    border-radius: 100%;
    -webkit-transition: background .2s;
    transition: background .2s;
}

.avatar-modal-close:before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.avatar-modal-close:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.avatar-modal-close:hover:before,
.avatar-modal-close:hover:after {
    background: #333;
}

/* -- fade -- */
@-webkit-keyframes avatar-modal-fade-enter {
    from {
        opacity: 0;
    }
}

@keyframes avatar-modal-fade-enter {
    from {
        opacity: 0;
    }
}

.avatar-modal-fade-enter {
    -webkit-animation: avatar-modal-fade-enter both ease-in;
    animation: avatar-modal-fade-enter both ease-in;
}

@-webkit-keyframes avatar-modal-fade-leave {
    to {
        opacity: 0
    }
}

@keyframes avatar-modal-fade-leave {
    to {
        opacity: 0
    }
}

.avatar-modal-fade-leave {
    -webkit-animation: avatar-modal-fade-leave both ease-out;
    animation: avatar-modal-fade-leave both ease-out;
}

/* -- zoom -- */
@-webkit-keyframes avatar-modal-zoom-enter {
    from {
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3);
    }
}

@keyframes avatar-modal-zoom-enter {
    from {
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3);
    }
}

.avatar-modal-zoom-enter {
    /*
    -webkit-animation: avatar-modal-zoom-enter both cubic-bezier(0.4, 0, 0, 1.5);
    animation: avatar-modal-zoom-enter both cubic-bezier(0.4, 0, 0, 1.5);
    */
    -webkit-animation: avatar-modal-zoom-enter both cubic-bezier(.34,1.61,.7,1.5);
    animation: avatar-modal-zoom-enter both cubic-bezier(.34,1.61,.7,1.5);
}

@-webkit-keyframes avatar-modal-zoom-leave {
    to {
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3);
    }
}

@keyframes avatar-modal-zoom-leave {
    to {
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3);
    }
}

.avatar-modal-zoom-leave {
    -webkit-animation: avatar-modal-zoom-leave both;
    animation: avatar-modal-zoom-leave both;
}
 
