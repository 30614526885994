.dxs-feeds-wrapper {
  height: 100%;
  min-width: 1380px;

  &.loading {
    display: flex;
  }

  .dxs-feeds {
    height: 100%;
    display: flex;
    flex-direction: column;

    .dxs-feeds-content {
      overflow: auto;
      height: 100%;
      padding: 0 48px;
    }

    .dxs-feeds-paginate {
      margin: 20px 0;

      .hme-label {
        text-transform: none;
        font-weight: normal;
      }
    }
  }
}
