@import "../../../../assets/styles/Variables";
@import "../../../../assets/styles/Responsive";

.hme-section.send-smacktalk-message-3-section {
  overflow-y: scroll;
  max-width: 33%;
  & > .smacktalk-categories-carousel {
   & > .carousel-inner {
     & > .carousel-item {
       & > .smacktalk-categories-carousel-item {
         & > .smacktalk-categories-carousel-slide {
           & > .smacktalk-categories-carousel-slide-title {
            margin: 0 0 15px 0;
           }
         }
       }
     }
   }
  }
  & > .category-label {
    text-transform: unset;
    margin: 0 0 8px 0;
    font-family: Assistant;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.28;
    letter-spacing: 0.18px;
    text-align: left;
    color: #000819;
  }
  & > .hme-grid {
    height: 400px;
    overflow-y: scroll;
    user-select: none;
    & > .hme-grid-header {
      display: none;
    }
    & > .hme-grid-row {
      font-family: Assistant;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: #000819;

      &.selected {
        color: $main-white;
        background-color: $main-color;
        & > .hme-grid-cell {
          color: $main-white;
        }
      }
    }
  }
}

@include smaller($l-desktop) {
    .hme-section.send-smacktalk-message-3-section {
      padding: 0;
      max-width: unset;
      & > .smacktalk-categories-carousel {
        width: 100%;
      }
      & > .hme-grid {
        height: unset;
        overflow-y: unset;
      }
    }
}
