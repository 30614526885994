.device-upgrades-list-grid {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  min-height: 530px; }
  .device-upgrades-list-grid .device-upgrade-list-wrapper__header {
    margin-bottom: 31px; }
  .device-upgrades-list-grid .device-upgrade-list-wrapper__grid {
    flex: 1;
    min-width: 1823px; }
  .device-upgrades-list-grid .device-upgrade-list-wrapper__paginate {
    margin-top: 40px;
    flex: 0; }

.device-upgrade-list-wrapper .hme-paginate.hme-paginate--hidden {
  display: flex; }

.device-upgrade-list-wrapper .Toastify .Toastify__toast--error.hme-info-message {
  background-color: #186df4 !important; }

@media screen and (max-height: 960px) {
  .device-upgrades-list-grid {
    min-height: 330px; }
    .device-upgrades-list-grid .hme-grid-filter-cell-popup .hme-grid-filter-cell-popup-body {
      max-height: 150px; } }
