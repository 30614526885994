@import 'src/assets/styles/Variables';

.hme-form-input {
    // (vertical padding * 2) + (font-size * line-height) + (vertical-border * 2) = (3px * 2) + (16px * 2.5) + (1px * 2) = 48
    padding: 3px 15px;
    padding-left: 25px;

    font-family: $font-family;
    font-size: 16px;
    color: $main-black;
    line-height: 2.5;
    letter-spacing: $letter-spacing-144;

    border-radius: 24px;
    border: 1px solid $gray-border;
    outline-offset: -2px;

    &:focus {
        outline: 2px solid $main-color;
    }
    
    &[aria-invalid] {
        border-color: $error-field;
        outline-color: $error-field;
    }
}    
