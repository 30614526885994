@import '../../../../mixins/breakpoints.scss';

@include before-desktop-only {
    .hme-manage-hierarchy__move-popup {
        &.modal-dialog.hme-modal-component {
            .modal-content {
                min-width: 330px;
            }
        }
    }
}
