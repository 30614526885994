@import 'src/assets/styles/Variables';

.hme-reward-image-input {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__preview-container {
        position: relative;
        
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 260px;
        height: 210px;
        margin: 0 auto;
        padding: 0 40px;
        box-sizing: content-box;
    }

    &__preview-thumb {
        position: relative;

        display: flex;
        justify-content: center;
        flex-grow: 1;
        width: 80%;
        height: auto;
        margin-top: auto;
        
        border: 4px solid #1a6bfa;
        border-bottom: none;
        border-radius: 20px 20px 0 0;
        overflow: hidden;

        img {
            max-width: 100%;
            height: auto;
        }
    }

    &__ribbon-container {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 60px;

        font-size: 18px;
        font-family: $font-family;
        font-weight: 700;
        color: $main-white;
        text-transform: uppercase;
        text-align: center;

        background-image: url('~images/ribbon.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .hme-label-error {
        text-align: center;
    }

    &__upload-image-btn {}

    &__remove-preview-btn.hme-transparent-button-component {
        position: absolute;
        right: 0;

        padding: 15px;
        padding-top: 10px;
    }
}
