@import 'src/mixins/breakpoints.scss';

@include tablet-only {
    .admin-create-apply-device-settings-task {
        .hme-title {
            font-size: 20px;
            font-weight: 600;
            line-height: 1.3;
            letter-spacing: -0.28px;
            margin: 0 0 20px;
        }

        .hme-section {
            height: auto;
        }

        .hme-checkbox-component-disabled {
            .hme-checkbox-label {
                color: #a7adba;
            }
        }

        .hme-tile-list-item__wrapper {
            .hme-tile-list-item__rows__wrapper {
                .hme-tile-list-item__rows__column {
                    .hme-tile-list__row {
                        .hme-tile-list__row__value {
                            text-overflow: clip;
                            white-space: normal;
                            word-break: break-all; 
                        }
                    }
                }
            }

            &.hme-tile-item-disabled {
                cursor: default;

                .hme-tile-list-item__rows__column {
                    .hme-tile-list__row {
                        .hme-tile-list__row__name,
                        .hme-tile-list__row__value {
                            color: #a7adba;
                        }
                    }
                }
            }
        }

        .admin-create-apply-device-settings-task-sections {
            flex-direction: row;
            height: auto;
            min-height: auto;

            .admin-create-apply-device-settings-task-source-area {
                flex-direction: column;
                width: 345px;
        
                .hme-section {
                    &:last-of-type {
                        padding-right: 0;
                    }
                }

                .hme-account-brand {
                    .hme-grid {
                        min-height: 125px;
                    }
                }
            }

            .hme-title {
                font-size: 18px;
                line-height: 1.28;
                letter-spacing: 0.18px;
                margin: 0 0 15px;
            }

            .admin-create-apply-device-settings-task-source-serial {
                .hme-source-device-input {
                    .hme-auto-complete-suggestions {
                        min-width: 100%;
                    }
                }

                .btn-group {
                    width: 100%;

                    .source-device-settings-radio {
                        flex: 1;
                        max-width: 100%;
                    }
                }

                .group-setting-inputs {
                    max-width: 100%;

                    .hme-checkbox-component {
                        width: 49%;
                        margin-left: 1%;
                    }
                }

                .hme-auto-complete-suggestions {
                    min-width: 100%;
                    max-height: 480px;
                    top: 49px;
    
                    .hme-grid{
                        display: none;
                    }

                    .hme-tile-list {
                        padding: 0;
                    }

                    .hme-tile-list__wrapper {
                        padding: 0;
                    }
    
                    .hme-tile-list-item__rows__wrapper {
                        flex-direction: row;
    
                        .hme-tile-list-item__rows__column {
                            &:first-child {
                                .hme-tile-list__row__name,
                                .hme-tile-list__row__value {
                                    position: relative;
                                    padding-left: 0;
                                    white-space: normal;

                                    &::before {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
    
                    .hme-tile-list-item__wrapper {
                        padding: 0 15px 15px;
                        border-radius: 0;
                        box-shadow: none;
                        background-image: none;
                        margin: 0 0 10px;
                        border-bottom: 1px solid #e5e5e5;
    
                        .hme-tile-list__row {
                            flex-direction: column;
                            margin: 0 8px 9px 0;
    
                            .hme-tile-list__row__name {
                                color: #5c6670;
                                font-size: 14px;
                                line-height: 1.29;
                                margin: 0 0 6px;
                            }
                            .hme-tile-list__row__value {
                                color: #000819;
                                font-size: 16px;
                                line-height: 1.13;
                                white-space: normal;
                                word-break: break-all;
                            }

                            .hme-tile-list__row__name,
                            .hme-tile-list__row__value {
                                padding-left: 15px;
                                position: relative;

                                &::before {
                                    content: '|';
                                    display: block;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                }
                            }
                        }

                        &.hme-tile-item-disabled {
                            cursor: default;

                            .hme-tile-list-item__rows__column {
                                .hme-tile-list__row {
                                    .hme-tile-list__row__name,
                                    .hme-tile-list__row__value {
                                        color: #a7adba;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .admin-create-apply-device-settings-task-header {
            margin: 21px 0 0;
        }

        .admin-create-apply-device-settings-task-content {
            padding: 0 15px;
        }

        .admin-create-apply-device-settings-task-source {
            width: 100%;
            padding: 0;
        }

        .admin-create-apply-device-settings-task-filters {
            width: 100%;
            padding: 20px 0 0;
            border-top: 1px solid #e8e9ed;
            border-left: 0;
            margin: 0 0 20px;

            .hme-auto-complete-suggestions {
                min-width: 100%;
                max-height: 480px;
                top: 49px;

                .hme-grid{
                    display: none;
                }

                .hme-tile-list {
                    padding: 0;
                }

                .hme-tile-list__wrapper {
                    padding: 0;
                }

                .hme-tile-list-item__rows__wrapper {
                    flex-direction: row;

                    .hme-tile-list-item__rows__column {
                        &:first-child {
                            .hme-tile-list__row__name,
                            .hme-tile-list__row__value {
                                position: relative;
                                padding-left: 0;
                                white-space: normal;

                                &::before {
                                    display: none;
                                }
                            }
                        }
                    }
                }

                .hme-tile-list-item__wrapper {
                    padding: 0 15px 15px;
                    border-radius: 0;
                    box-shadow: none;
                    background-image: none;
                    margin: 0 0 10px;
                    border-bottom: 1px solid #e5e5e5;

                    .hme-tile-list__row {
                        flex-direction: column;
                        margin: 0 8px 9px 0;

                        .hme-tile-list__row__name {
                            color: #5c6670;
                            font-size: 14px;
                            line-height: 1.29;
                            margin: 0 0 6px;
                        }
                        .hme-tile-list__row__value {
                            color: #000819;
                            font-size: 16px;
                            line-height: 1.13;
                            white-space: normal;
                            word-break: break-all;
                        }

                        .hme-tile-list__row__name,
                        .hme-tile-list__row__value {
                            padding-left: 15px;
                            position: relative;

                            &::before {
                                content: '|';
                                display: block;
                                position: absolute;
                                top: 0;
                                left: 0;
                            }
                        }
                    }

                    &.hme-tile-item-disabled {
                        cursor: default;
                
                        .hme-tile-list-item__rows__column {
                            .hme-tile-list__row {
                                .hme-tile-list__row__name,
                                .hme-tile-list__row__value {
                                    color: #a7adba;
                                }
                            }
                        }
                    }
                }
            }

            .hme-create-task-account-form {
                .hme-auto-complete-suggestions {
                    .hme-grid {
                        display: block;
                    }
                }
            }
        }

        .admin-create-apply-device-settings-task-target {
            min-width: auto;
            padding: 20px 0 0 20px;
            border-top: 0;
            border-left: 1px solid #e8e9ed;
            margin-left: 20px;

            .hme-admin-target-device-list {
                &__filters-row {
                    flex-direction: column;
                    margin: 0 0 17px;

                    .hme-input-search-variant {
                        width: 100%;
                    }
                }

                &__filters-panel {
                    display: flex;
                    justify-content: flex-start;
                    margin: 0 0 17px;

                    .hme-button-component {
                        margin: 11px 20px 0 0;
                    }
                }

                &__target-count {
                    font-size: 24px;
                }

                &__target-count-label {
                    font-size: 18px;
                }

                &__filters-count {
                    display: none;
                }
            }

            .hme-grid {
                display: none;
            }

            .hme-tile-list__wrapper {
                padding: 0;

                .hme-grid-filters-count-reset-button {
                    display: none;
                }
            }

            .hme-paginate {
                margin-top: 20px;
            }
        }

        .admin-create-apply-device-settings-task-footer {
            text-align: center;
            padding: 20px 23px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            .hme-button-component {
                margin-left: 0 0 0 20px;
                order: 0;
                width: auto;
            }

            .hme-submit-button-component {
                order: 0;
            }
        }
    }

    .hme-select-snapshot-modal-dialog {
        &.modal-dialog {
            .modal-content {
                margin: 0;
                padding: 0;
            }

            .modal-header {
                width: 75px;
                position: absolute;
                top: 14px;
                right: 0;
                z-index: 1;
            }

            .select-snapshot-taskbar {
                display: block;
                margin: 0 75px 22px 0;
                line-height: 1.3;
                padding: 0;

                .hme-title {
                    font-family: Assistant;
                    font-size: 20px;
                    font-weight: 600;
                    letter-spacing: -0.28px;
                }

                .select-snapshot-taskbar-filters-count {
                    display: none;
                }
            }
            .hme-select-snapshot-modal {
                padding: 20px 15px 0 25px;
                min-width: auto;
            }
    
            .device-settings-snapshot-list {
                .hme-grid {
                    display: none;
                }

                .hme-tile-list__wrapper {
                    padding: 0;

                    .hme-tile-list__row {
                        .hme-tile-list__row__value {
                            white-space: normal;
                            word-break: break-all;
                        }
                    }
                }
            }

            .modal-footer {
                z-index: 10;
                box-shadow: 0 -10px 20px 0 rgba(0, 0, 0, 0.16);
                background-color: #ffffff;
                padding: 20px 30px;

                .hme-button-component {
                    width: 100%;
                }
            }
        }
    }
}
