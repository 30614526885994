.hme-file-input-file-name {
    &.hme-file-input-file-name-hidden {
        visibility: hidden;
    }

    .hme-file-input-file-name-icon {
        width: 43px;
        height: 48px;
        color: #5db431;
        display: inline-block;
    }

    .hme-file-input & .hme-label {
        font-weight: 600;
        line-height: 48px;
        color: #000819;
        display: inline-block;
        margin-bottom: 0;
        text-align: top;
    }
}