.text-connect {
    &__history {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex: 1;

        &__list {
            overflow-y: auto;
            height: 100%;
        }

        .hme-paginate {
            margin: 40px 0;

            .hme-label {
                font-weight: normal;
                text-transform: none;
                color: #606672;
            }
        }
    }
}
