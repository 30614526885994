@import '../../../../../../assets/styles/_Variables.scss';

.hme-device-settings-snapshots__name-tooltip__info {
    padding: 15px;
    border-radius: 10px;
    border: solid 1px $tooltip-info-bg;
    background-color: $tooltip-info-bg;
    display: flex;
    width: 315px;
    font-family: $font-family;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: 1.12px;
    color: $main-black;
    margin-bottom: 20px;

    &__number {
        flex: 0;
        padding-right: 10px;
        font-family: $font-family;
        font-size: 20px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: 1.6px;
        color: $main-black;
    }

    &__content {
        flex: 1;
    }

    &__example {
        margin-top: 11px;
    }

    &__plus {
        display: block;
        width: 18px;
        height: 18px;
        margin-top: 15px;
        margin-bottom: 15px;
        color: $main-color;
        transform: rotate(45deg);
    }
}
@import './Mobile.scss';
