
.hme-notification-message {
    display: flex;

    .hme-notification-message-icon-block {
        width: 22px;
        height: 22px;
        border: 2px solid #ffffff;
        border-radius: 50%;
        position: relative;
        flex: none;

        .hme-notification-message-icon {
            width: 19px;
            height: 19px;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    .hme-notification-warning-icon-block {
        width: 22px;
        height: 22px;
        position: relative;
        flex: none;

        .hme-notification-warning-icon {
            stroke: white;
            stroke-width: 0%;
            width: 19px;
            height: 19px;
            position: absolute;
            filter: brightness(5);
            transform: rotate(180deg);
        }
    }

    .hme-notification-message-text {
        flex: 1;
        margin-left: 10px;
        padding-right: 16px;
        font-family: Assistant;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        text-align: left;
    }
}
