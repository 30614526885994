.hme-auto-complete-suggestions {
    .hme-auto-complete-suggestions-label {
        font-family: Assistant;
        padding: 10px 0 10px 15px;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.31;
        letter-spacing: 1.28px;
        text-align: left;
        color: #a6a1a1;
    }

    .hme-grid {
        .hme-grid-row {
            padding-top: 10px;
            padding-bottom: 10px;

            &.hme-grid-row-odd {
                background-color: #ffffff;
            }

            &.hme-grid-row-hovered {
                background-color: #0074ef;

                &.hme-grid-row-message {
                    background-color: #ffffff;

                    .hme-grid-cell {
                        .hme-grid-cell-content {
                            color: #000819;
                        }
                    }
                }

                &.hme-grid-header-row {
                    background-color: #ffffff;

                    .hme-grid-cell {
                        border-left-color: #a6a1a1;

                        .hme-grid-cell-content {
                            color:#5c6670;
                        }
                    }
                }

                .hme-grid-cell {
                    border-left-color: #ffffff;

                    .hme-grid-cell-content {
                        color: #ffffff;
                    }
                }
            }

            &.hme-grid-header-row {
                .hme-grid-cell {
                    border-left-color: #a6a1a1;

                    .hme-grid-cell-content {
                        font-size: 14px;
                        font-weight: 600;
                        color: #5c6670;
                    }
                }
            }

            .hme-grid-cell {
                border-left: 1px solid #000819;
                padding: 0;

                .hme-grid-cell-content {
                    font-family: Assistant;
                    font-size: 16px;
                    line-height: 1.31;
                    letter-spacing: 1.28px;
                    color: #000819;
                    padding-left: 10px;
                    padding-right: 0;
                }

                &:first-of-type {
                    border-left: none;
                }
            }
        }
    }
}