.hme-view-edit-store__header {
  font-family: Assistant, sans-serif;
  font-size: 31px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.32;
  letter-spacing: -0.43px;
  text-align: left;
  color: #000819;
  padding-bottom: 30px;
}
