.hme-datepicker {
    &--with-reset {
        display: flex;

        .hme-button-component {
            margin: 0 15px 0 25px;
        }
    }
}

.hme-input-date-picker-variant {
    position: relative;

    .hme-calendar {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 10;
    }

    .input-datepicker-icon {
        position: absolute;
        top: 14px;
        left: 14px;
        width: 20px;
        height: 20px;
        color: #606672;
    }

    &.hme-input-container {
        .hme-label {
            left: 47px;
        }

        .hme-input-component {
            padding-left: 44px;

            &:focus {
                padding-left: 43px;
            }
        }
    }
}