@import 'src/mixins/breakpoints.scss';

@include mobile-only {
    .hme-brand-sharing__header {
        flex-direction: column;
        gap: 20px;

        .hme-title {
            padding-top: 0;

            font-size: 20px;
        }

        &-separator {
            display: none;
        }

        &__agreement.hme-button-component.hme-transparent-button-component {
            padding: 0;
            
            font-size: 14px;
            text-align: left;
        }
    }
}
