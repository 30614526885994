@import 'src/mixins/breakpoints';
@import 'src/assets/styles/Variables';

@include before-desktop-only {
    .hme-contest-view-edit-form {
        --gap: 25px;
        --fields-gap: 20px;

        padding-left: 15px;
        padding-right: 15px;

        &__content {
            flex-wrap: wrap;
        }

        &__section {
            min-height: unset;
            padding-bottom: 0;

            .hme-title {
                font-size: 18px;
            }

            &--limited-width {
                flex-basis: calc(50% - 26px);
                flex-grow: 0;
                width: unset;
                max-width: none;
            }

            &--stores {
                flex-basis: 100%;
                padding-left: 0;
                padding-bottom: var(--gap);
                padding-top: var(--gap);
                border-left: none;
                border-top: 1px solid $input-underline;
            }
        }

        .hme-tree-input .hme-tree {
            max-height: 305px;
        }

        .hme-input-date-picker-variant .hme-calendar-selector {
            top: unset;
            bottom: 100%;
        }
    }
}
