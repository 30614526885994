.contest-download-button {
  display: flex;
  &__tooltip {
    min-width: 75px;
    & > .tooltip-inner > .hme-tooltip-content {
      padding: 7px 0px !important;
    }
    & > .tooltip-inner {
      border-radius: 10px !important;
    }
    & > .tooltip-arrow {
      display: none;
    }
  }
  &__dropdown-icon {
    color: #0074ef !important;
    cursor: pointer;
    user-select: none;
  }
  &__format-options {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    border-radius: 10px !important;   
    &__item {
      text-align: left;
      padding: 5px 10px;
      cursor: pointer;
    }
    & > li:hover {
      font-weight: 600;
      color: #ffffff !important;
      background-color: #2c6df4;
    }
  }
}