@import 'src/mixins/breakpoints.scss';

.hme-form-field {
    --gap: 2px;

    display: grid;
    flex-direction: column;
    gap: var(--gap);

    &__wrapper {
        --field-gap: 5px;

        display: flex;
        gap: var(--field-gap);
        flex-direction: column;
    }

    &__errors {
        min-height: 25px;
    }

    // inline variant
    &--inline {
        flex-wrap: wrap;
        gap: var(--field-gap);
    }

    &--inline &__wrapper {
        flex-direction: row;
        align-items: center;

        .hme-form-input {
            flex-grow: 1;
        }
    }

    &--inline &__errors {
        flex-grow: 1;
    }
}

@include mobile-only {
    .hme-form-field {
        &--inline {

        }

        &--inline &__wrapper {
            flex-direction: column;
            align-items: unset;
        }
    }
}
