.hme-toggle.hme-toggle--rounded {
    .toggle-button-group {
        width: max-content;
        gap: 20px;

        .hme-button-component {
            border-radius: 24px;
            text-wrap: nowrap;
            padding-left: 25px;
            padding-right: 25px;
        }
    }
}
