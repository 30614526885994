.admin-create-apply-store-settings-task-search {
    overflow: visible;

    .admin-create-apply-store-settings-task-search-task-name {
        margin-bottom: 30px;
    }

    .hme-single-select {
        margin-top: 8px;
    }

    .create-apply-store-settings-task-account-input {
        margin-top: 8px;
    }
}
