.hme-edit-contest-page {
    .hme-page-content {
        width: 100% !important;
    }
}

.hme-view-edit-contest--results {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;

    .hme-save-footer {
        position: sticky;
        bottom: 0;
    }
}
