@import 'mixins/breakpoints.scss';

.hme-header--mobile {
    background-color: #FFF;
    display: flex;
    align-items: center;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.16);
    position: relative;
    flex-wrap: wrap;

    .hme-header--mobile__link {
        flex: 0;
        margin-right: auto;
    }

    .hme-header--mobile__logo {
        margin-left: 15px;
        width: 97px;
        height: 50px;
        margin-right: auto;
        padding-top: 18px;
        padding-bottom: 18px;
    }

    .hme-header--mobile__aditional-items {
        flex: 0;
    }

    .hme-header--mobile__content {
        height: 0;
        background-color: #FFF;
        overflow: hidden;
        transition: height .35s ease;
        flex-basis: 100%;
        display: flex;
        flex-direction: column;

        .hme-header--mobile__content__items {
            flex: 0;
            transition: flex .35s ease;
        }

        .hme-header--mobile__content__sub-items {
            height: 0;
            position: relative;
            transition: height .35s ease;
            overflow: hidden;

            .hme-header--mobile__content__sub-items__hidden {
                visibility: hidden;
                position: absolute;
            }

            .hme-menu-item {
                margin-left: 82px;
            }
        }

        .hme-account-dropdown--mobile {
            flex: 0;
            margin-top: auto;
            border-bottom: solid 1px #cacbd0;
        }

        .hme-header--mobile__log-out {
            flex: 0;
            padding-top: 24px;
            padding-bottom: 24px;

            .hme-menu-item__icon {
                margin-top: -15px;
                margin-right: 17px;
                font-size: 28px;
            }
        }
    }

    &.hme-header--mobile--open {
        .hme-header--mobile__content {
            height: calc(100vh - 50px);
            background-color: #FFF;
            overflow: auto;
            padding-bottom: 100px;

            .hme-header--mobile__content__items {
                flex: 1;
            }
        }
    }

    .hme-header--mobile__top-menu {
        display: flex;

        .hme-menu-item {
            flex-direction: row-reverse;
            margin: 0 6px 0 0;

            &--text-connect {
                margin-right: 2px;
            }

            .hme-menu-item__icon {
                margin-right: 0;
                margin-left: 0;
                max-height: 26px;
                height: auto;
            }

            .hme-menu-item__text {
                max-width: 48px;
                word-wrap: break-word;
                text-transform: uppercase;
                font-size: 13px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.08;
                letter-spacing: 0.43px;
                text-align: center;
            }

            &--text-connect {
                .hme-menu-item__text {
                    max-width: 60px;
                }
            }
        }
    }

    .hme-smacktalk_icon {
        background-size: 26px 25px;
        width: 26px;
        height: 25px;
    }

    .hme-text-connect_icon {
        background-size: 26px 25px;
        width: 26px;
        height: 25px;
    }

    .hme-noification-bell {
        margin-left: 5px;
        margin-bottom: 0;

        svg {
            width: 35px;
            height: 35px;
        }

        .dropdown-icon-badge:hover {
            color: #000;
        }
    }

    .hme-language-select {
        min-width: 200px;
        max-width: 260px;
        flex: 1;
        margin-top: 5px;
        margin-bottom: 5px;
        margin-right: 15px;
        padding-left: 10px;
    }
}

@include tablet-only {
    .hme-header--mobile {
        .hme-header--mobile__top-menu {
            .hme-menu-item {
                margin: 0 20px 0 0;

                &--text-connect {
                    margin-right: 10px;
                }

                .hme-menu-item__icon {
                    margin-right: 5px;
                    margin-left: 5px;
                    max-height: 31px;
                }
            }
        }

        .hme-smacktalk_icon {
            background-size: 35px 31px;
            width: 35px;
            height: 31px;
        }

        .hme-text-connect_icon {
            background-size: 32px 30px;
            width: 32px;
            height: 30px;
        }
    }
}
