@import 'src/mixins/breakpoints.scss';

@include mobile-only {
    .hme-view-edit-store__footer {
        justify-content: center;
        padding: 0 20px;

        &__button {
            margin: 0;
        }
    }
}
