@import 'src/mixins/breakpoints.scss';

@include before-desktop-only {
    .modal-dialog.template-preview-box {
        padding: 0 15px;
        max-width: 738px;

        .modal-content {
            margin-top: 0;
        }

        .modal-header {
            margin-bottom: 25px;
        }
    }
}
