
/* div.addSettingsButton{
    background-color: white;
    border: 1px solid #757575;
    color: black;
    font-weight: bold;
    font-size: 13px !important;
    max-width: 12em;
    padding: 10px 20px;
    margin-right:10px;
    margin-bottom: 10px;
    cursor: pointer;
    text-align: center;
  }

  div.dblock{
    display: block;
  }

  .fleft{
    float: left;
  } */

  .coffee-table {
    margin-bottom: 25px;
    margin-top: 47px;
    font-size: 12px;
  }

  /* .add-setting-form-container {
    padding: 4rem;
    font-family: Assistant;
    font-size: 17px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.88;
    letter-spacing: normal;
    color: var(--black);
  } */

/* .btn-cancel {
    margin-right: 15px;
    margin-top: 28px;
    width: 10rem;
    background-image: linear-gradient(to bottom, #dfe3eb, #c7ccd6);
    font-family: Assistant;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: 0.2px;
  }

  .btn-retrieve-settings {
    margin-top: 28px;
    width: 16rem;
    background-image: linear-gradient(to bottom, #186df4, #0040ba);
    font-family: Assistant;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: 0.2px;
    color: #ffffff;
  }

  .add-setting-button {
    margin-left: -15px;
  }

  .shape {
    height: 48px;
    border-radius: 24px;
    border: solid 1px #a7adba;
    background-color: #ffffff;
  }

  .button-shape {
    width: 150px;
    height: 40px;
    border-radius: 24px;
    border: solid 1px #c7ccd6;
  }

  .btn-addApplySettings {
    margin-right: 15px;
    margin-top: 28px;
    width: 12rem;
    background-image: linear-gradient(to bottom, #dfe3eb, #c7ccd6);
    font-family: Assistant;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: 0.2px;
    border-radius: 12px;
    border: solid 1px #c7ccd6;
    height: 2rem;
  }

  .padAroundButtons{
    margin-bottom: 12px;
    margin-left: 6px;
  }

  .h3-bold {
    font-family: Assistant;
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: 0.1px;
  }

  .h4-subheader {
    font-family: Assistant;
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    margin-left: 20px;
} */

  .vertically-align {
    align-items: center;
  }

  .coffee-table .ctable .theader, .systems .ctable .cheader {
    border: 1px solid #b9b9b9;
    color: #757575;
  }

  .coffee-table .ctable {
    margin: 0 auto;
  }

  .coffee-table tr.theader th {
    font-size: 16px;
    cursor: pointer;
  }

  .coffee-table .ctable th {
    padding: 10px;
  }

  .coffee-table tr.theader th a {
    font-size: 16px !important;
  }

  .coffee-table td.cdet {
    margin-left: 4px;
    text-align: center;
  }

  .coffee-table .ctable td {
    vertical-align: top;
  }

  .coffee-table .tdata, .coffee-table .cdata {
    background-color: #ffffff;
    border-bottom: 1px solid #b9b9b9;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    color: #000000;
  }
  .coffee-table .tdata:hover {
    background-color: #c4e9f1;
    border-bottom: 1px solid #709da2;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
  }


  @media screen and (max-width: 899px) {
    .coffee-table .theader.clear span {
      font-size: 11px;
    }
    .coffee-table .ctable tr.tdata td {
      font-size: 10px;
    }
    .coffee-table .ctable tr.tdata td .tdBefore a span{
      font-size: 11px;
    }
    .coffee-table a.view_details span {
      font-size: 11px;
    }
  }
  @media screen and (min-width: 900px) {
    .coffee-table .theader.clear span {
        font-size: 16px;
    }
    .coffee-table .ctable tr.tdata td {
        font-size: 14px;
    }
    .coffee-table a.view_details span {
        font-size: 14px;
    }
  }

  .apply-setting-summary .coffee-table-radio-btn {
    display: none;
  }
  .coffee-table-table {
    table-layout: auto;
  }

  /* .store-number-dropdown:after {
    position: absolute;
    content: "";
    top: 14px;
    right: 10px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-color: #fff transparent transparent transparent;
  } */

/* .select-styled {
    border-radius: 24px;
    outline: 0;
} */

  /* .coffee-table .type-ahead-custom-message {
    color: red;
  }

  .col-device-serial-number{
    width: 100%;
    padding-left: 0px;
  } */

  /* .padding-on-top{
    padding-top: .5em;
  } */

.action-remove {
  color: #FF0101;
  cursor: pointer;
}

.ctable .td-action-remove {
  vertical-align: middle;
  text-align: center;
}