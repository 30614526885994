.hme-create-device-upgrade-account-brand {
  height: calc(100% - 68px); }
  .hme-create-device-upgrade-account-brand .hme-create-device-upgrade-account-brand-info {
    font-family: Assistant;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.02px;
    text-align: left;
    color: #000819; }
    .hme-create-device-upgrade-account-brand .hme-create-device-upgrade-account-brand-info .hme-create-device-upgrade-account-brand-count {
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0.02px;
      color: #606672; }
  .hme-create-device-upgrade-account-brand .hme-grid {
    margin-top: 35px;
    height: calc(100% - 89px);
    overflow: auto; }
