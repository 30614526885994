.hme-tile-list__sort-filter-modal__sorting {
  display: flex;
  flex-direction: column;
  gap: 10px;

  padding: 0 0 24px 0;

  .hme-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 1.44px;
    color: #000819;
    text-transform: uppercase;
    padding-bottom: 8px;
  }

  .hme-radio {
    padding: 8px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .hme-label {
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: 1.28px;
      font-weight: normal;
      color: #000819;
      padding-left: 10px;
      text-transform: none;
    }
  }
}
