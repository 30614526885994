$l-breakpoint: 1174px;
$s-breakpoint: 768px;

// we use this mixin for old page without tablet version
@mixin before-desktop-only {
    @media screen and (max-width: $l-breakpoint - 1px) {
        @content;
    }
}

@mixin mobile-only {
    @media screen and (max-width: $s-breakpoint - 1px) {
        @content;
    }
}

@mixin tablet-only {
    @media screen and (min-width: $s-breakpoint) and (max-width: $l-breakpoint - 1px) {
        @content;
    }
}

@mixin desktop-only {
    @media screen and (min-width: $l-breakpoint) {
        @content;
    }
}
