@import 'src/mixins/breakpoints.scss';

@include mobile-only {
  .hme-help-page {

  .hme-contact__labels {
    font-size: 16px;
  }
   .hme-contact__value {
     font-size: 16px;
  }

  .hme-help__container {
    padding: 17px;

    .hme-help__header-text {
      font-size: 20px;
      font-weight: 600;
      width:15%;
      height:20px;
    }

    .hme-help__column-container {
      flex-direction: column;

      .hme-help__faq-column {
        width: 100%;
        border: 0px;
        padding-right: 0px;

        .hme-help__faq-header-text {
          font-size: 18px;
          font-weight: 600;
          margin-bottom: -10px;
        }

        .hme-help__faq-category-text {
          font-size: 18px;
        }

        .hme-accordion-wide {
          .hme-accordion__title__text {
            font-size: 16px;
            font-weight: 500;
          }

          .hme-accordion__content {
            .hme-help-faq-answer-text {
              font-size: 14px !important;
            }
          }
        }
      }

      .hme-help__contact-column {
        width: 100%;

        .hme-help__contact-support-text {
          font-size: 18px;
          font-weight: 600;
        }

        .hme-help__documentation-link {
          font-size: 16px;
          font-weight: 500;
        }

        .hme-help__training-portal-container {
          width: 100%;

          .training-portal-header {
            font-size: 18px;
            font-weight: 600;
          }

          .training-portal-text {
            font-size: 16px;
          }

          .training-portal-link {
            font-size: 16px;
            text-align: left;
          }
        }
      }
    }
  }
}
}

