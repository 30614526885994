@import 'mixins/breakpoints.scss';

@include mobile-only {
    .hme-notifications-center {
        padding: 20px 15px;
    }

    .modal.notifications-center {
        div.modal-dialog.hme-modal-component.confirm-popup {
            padding: 0 35px;

            .modal-content .modal-footer {
                flex-direction: column;
                align-items: unset;
                gap: 10px;
    
                .hme-submit-button-component {
                    order: -1;
                }
            }
        }
    }
}