.hme-info-box {
    display: flex;
    font-family: Assistant;

    // TODO: Don't use this className, use variant `info` instead
    .hme-info-box-blue {
        background-color: #186df4;
        color: #ffffff;
        border-radius: 4px;
        padding: 15px 20px;
        margin: 0 10px 15px 10px;
        font-size: 1.5rem;
    }

    .hme-info-box-message {
        color: white;
    }

    .icon-info {
        color: white;
        font-size: 1.7rem;
        margin-top: 5px;
        padding-right: 10px;
    }

    .hme-info-box__icon--svg-info {
        width: 22px;
        height: 22px;
        margin-right: 10px;
        margin-top: 2px;
    }
}

@import './variants/info.scss';
@import './variants/warning.scss';
@import './variants/flex.scss';
