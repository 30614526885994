.hme-input-search-variant {
    input.hme-input-component {
        padding-left: 48px;

        &:focus {
            padding-left: 47px;
        }
    }

    .hme-input-component-before {
        display: none;
    }

    .hme-input-search-icon {
        position: absolute;
        left: 14px;
        top: 14px;
    }
}
