@import 'src/mixins/breakpoints.scss';

@include mobile-only {
    .hme-account-info{
        &__sections {
            flex-direction: column;
            gap: 20px;
        }

        &__section {
            &--1 {
                padding-bottom: 20px;
                border-bottom: 1px solid #e8e9ed;
            }

            &--2 {
                padding-left: 0;
                border-left: none;
            }
        }

        &__footer {
            padding: 20px;
            justify-content: center;

            .hme-button-component {
                min-width: 100px;
            }
        }
    }
}
