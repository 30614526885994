@import "../../../../assets/styles/Variables";
@import "../../../../assets/styles/Responsive";

.custom-smacktalk-page-create-new-custom-message {
  height: calc(100vh - 360px);
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  align-items: flex-start;
  & > .hme-input__char-counter {
    font-family: Assistant;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: 1.44px;
    text-align: left;
    color: $main-color;
    margin: 0 0 20px 0;

    & > span:nth-child(2) {
      margin-left: unset;
      padding-left: unset;
      border-left: unset;
      border-color: unset;
    }
    & > .hme-focused {
      display: none;
    }
    & > .hme-blured {
      display: inline-block;
      color: $main-gray;
    }
  }
  &:focus-within {
    & > .hme-input__char-counter {
      & > .hme-focused {
        display: inline-block;
      }
      & > .hme-blured {
        display: none;
      }
    }
  }
  & > .hme-save-footer {
    margin-left: -50px;
    width: calc(100% + 75px);
  }
  & > .back-link-label {
    margin: 0 0 5px 0;
  }
  & > .hme-title {
    margin:  0 0 10px 0;
    font-family: Assistant;
    font-size: 31px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.32;
    letter-spacing: -0.43px;
    text-align: left;
    color: $main-black;
  }
  & > .leaderboard-desc-textbox {
    margin: 0 0 5px 0;
    width: 735px;
    resize: none;
  }
  & > .hme-label {
    display: block;
    margin: 0 0 22px 0;
  }
  & > .hme-onoff-slider-component {
    margin: 0 0 22px 0;
  }
}

@include smaller($l-desktop) {
  .custom-smacktalk-page-create-new-custom-message {
    height: calc(100vh - 280px);
    & > .hme-title, & > .hme-label, & > .hme-onoff-slider-component {
      padding-right: 15px;
      padding-left: 15px;
    }
    & > .leaderboard-desc-textbox {
      padding-right: 15px;
      padding-left: 15px;
      width: calc(100% - 30px);
      margin: 0 0 5px 15px;
    }
    & > .hme-onoff-slider-component {
      margin: 0 0 22px 15px;
    }
    & > .hme-save-footer {
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 20px 0;
      margin: auto 0 0 0;
      & > button.hme-button-component {
        margin: 0 10px;
      }
    }
    & > .hme-title {
      margin:  0 0 10px 0;
      font-family: Assistant;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.28;
      letter-spacing: -0.25px;
      text-align: left;
      color: $main-black;
    }
  }
}