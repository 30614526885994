@import 'src/mixins/breakpoints.scss';

.hme-dfa-code {
    margin-left: auto;
    margin-right: auto;
    width: 1165px;
    display: flex;
    flex-wrap: wrap;

    .Toastify {
        min-height: 70px;
        flex: 0;
        width: 100%;
        flex-basis: 100%;

        .Toastify__toast-container--top-center.hme-dfa-code__notifications-list {
            position: static !important;
            transform: none;
            display: flex;
            justify-content: center;
            margin-top: 20px;

            .Toastify__close-button {
                display: none;
            }
        }
    }

    .hme-title {
        margin-bottom: 14px;
        flex-basis: 100%;
    }

    .hme-dfa-code__info {
        flex: 1;
        width: 545px;
        margin-right: 20px;
        padding-top: 37px;
    }

    .hme-dfa-code__form {
        flex: 0;
        width: -webkit-fit-content;
        width: fit-content;;
        padding: 37px 36px;
        border-radius: 24px;
        background-color: #f5f5f5;

        .hme-dfa-code__form__input__wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 20px;

            .hme-label {
                white-space: nowrap;
                flex: 1;
                margin-right: 20px;
            }

            .hme-input-container {
                .hme-input-wrapper {
                    width: 304px;
                    justify-self: flex-end;
                }

                .hme-tip {
                    transform: translate(0, 3px);
                    margin-left: 8px;
                }
            }
        }
        .hme-submit-button-component {
            margin-left: auto;
            display: block;
        }

        .hme-dfa-code__tooltip {
            max-width: 500px;
        }
    }

    @include before-desktop-only {
        & {
            width: 100%;
            display: block;

            .Toastify {
                min-height: 11px;
                margin-bottom: 15px;
                width: 100%;

                .Toastify__toast-container--top-center.hme-dfa-code__notifications-list {
                    margin-top: 15px;
                }
            }

            .hme-title {
                width: 100%;
                font-size: 26px;
                line-height: 1.31;
                letter-spacing: -0.36px;
                color: #000819;
                margin-bottom: 15px;
            }

            .hme-dfa-code__info {
                width: 100%;
                padding-top: 0;
                margin-right: 0;
                margin-bottom: 15px;
            }

            .hme-dfa-code__form {
                width: 100%;
                padding: 20px;
                box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0);

                .hme-dfa-code__form__input__wrapper {
                    flex-direction: column;
                    align-items: flex-start;

                    .hme-label {
                        margin-right: 0;
                        margin-bottom: 8px;
                    }

                    .hme-input-container {
                        .hme-input-wrapper {
                            width: 100%;
                        }
                    }
                }

                .hme-submit-button-component {
                    margin-left: 0;
                    width: 100%;
                    display: block;
                }

                .hme-dfa-code__tooltip {
                    max-width: 340px;
                }
            }
        }
    }
}
