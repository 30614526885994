.admin-create-apply-device-settings-task-source {
    overflow: visible;

    &.hme-section.hme-section-first {
        padding-bottom: 30px;
    }

    .source-type-toggle {
        margin-bottom: 30px;
    }

    .hme-input-container {
        width: 100%;
    }
}
