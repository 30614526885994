.hme-create-snapshot-modal-dialog.modal-dialog {
  max-width: 810px; }
  .hme-create-snapshot-modal-dialog.modal-dialog .modal-header {
    text-align: left;
    padding: 25px 40px 0 40px;
    margin-bottom: 30px; }
    .hme-create-snapshot-modal-dialog.modal-dialog .modal-header .hme-title {
      font-family: Assistant;
      font-size: 24px;
      font-weight: bold;
      line-height: 1.33;
      letter-spacing: -0.34px;
      color: #000819;
      margin: 0;
      text-transform: capitalize;
      white-space: nowrap; }
    .hme-create-snapshot-modal-dialog.modal-dialog .modal-header .btn-close {
      margin-top: -22px;
      margin-right: -25px; }
  .hme-create-snapshot-modal-dialog.modal-dialog .hme-create-snapshot-modal {
    padding-left: 40px;
    padding-right: 40px;
    height: 100%;
    max-height: 700px; }
