.hme-sso-page{
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto !important;
    &.hmeBody {
        width: 100% !important;
    }
    .hme-tab-panel-content {
        margin: 0;
        padding: 25px; 
        width: 100%;
        .hme-section{
            .hme-section-sm{
                .hme-section-without-title{
                    width:35%;
                }
            }
        }
        .sso-form-container{
            display: flex;
            justify-content: flex-start;
            .sso-form-input{
                margin-bottom: 20px;
                .hme-label{
                    margin-bottom: 8px;
                }
            }
    
        }
    }
    
}
.label-margin{
    margin-bottom: 8px;
}