@import 'src/assets/styles/Variables';

.hme-store-details-account-link {
    .hme-label {
        font-size: 14px;
        line-height: 1.29;
        color: $main-gray;
        margin-bottom: 8px;
        display: block;
    }
}
