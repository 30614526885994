.hme-tip {
    display: inline-block;

    .icon {
        color: #606672;
    }

    .icon-clock {
        content: url('~assets/icons/icon-clock.svg');
        filter: invert(41%) sepia(5%) saturate(1081%) hue-rotate(182deg) brightness(93%) contrast(89%);
    }

    .icon-alert {
        content: url('~assets/icons/alert.svg');
        width: 24px;
        height: 24px;
    }

    &.hme-tip-opened {
        .icon {
            color: #0074ef;
        }

        .icon-clock {
            filter: invert(32%) sepia(67%) saturate(4598%) hue-rotate(198deg) brightness(97%) contrast(102%);
        }
    }
}

.hme-tooltip {
    &.bs-tooltip-bottom .tooltip-arrow::before {
        border-bottom-color: #FFFFFF;
    }

    &.bs-tooltip-end .tooltip-arrow::before {
        border-right-color: #FFFFFF;
    }
}
