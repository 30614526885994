@import 'src/mixins/breakpoints.scss';

.hme-forgot-password__form {
    margin-left: auto;
    margin-right: auto;
    width: -webkit-fit-content;
    width: fit-content;
    padding: 37px 36px;
    border-radius: 24px;
    background-color: #f5f5f5;

    .hme-input-container {
        margin-bottom: 20px;

        .hme-label {
            white-space: nowrap;
            flex: 1;
        }

        .hme-input-wrapper {
            width: 340px;
            justify-self: flex-end;
        }
    }

    .hme-forgot-password__form__login-button {
        min-width: 170px;
        margin-left: auto;
        display: block;
    }

    @include before-desktop-only {
        padding: 20px;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0);
    }
}
