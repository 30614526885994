.api-management-form-page {
    height: auto;

    .api-management-form-details {
        min-height: 90vh;
        height: auto;
        padding: 0 50px;
    }

    .api-management-form-details {
        .vaio-provider-form-input:not(:first-child) {
            margin-top: 20px;
        }

        .vaio-provider-form-label {
            margin-bottom: 4px;
        }

        .hme-title {
            margin-bottom: 25px;
            font-weight: bold;
        }

        textarea {
            overflow: auto;
            font-size: 15px;
        }

        p {
            margin-bottom: 5px;
        }

        .hme-form-value-required {
            padding-right: 3px;
            color: #186df4;
        }
    }
}