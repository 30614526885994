@import '../../../../mixins/breakpoints.scss';

@include mobile-only {
    .hme-manage-hierarchy__file-manage {
        display: block;

        .hme-separator {
            display: none;
        }

        .hme-manage-hierarchy__file-manage__export {
            padding-bottom: 20px;
            margin-bottom: 20px;
        }
    }
}
