.hme-add-store-modal-dialog {
    .add-store-form {
        .hme-add-store__form {
            display: flex;
            justify-content: space-between;
            width: 730px;
        }

        &__section {
            width: 345px;   
        }

        &__box {
            margin: 0 0 20px;

            .hme-label-error {
                margin: 0 25px;
            }
        }
    }

    .add-store-generic-error{
        text-align: center;
        color: #f00;
        font-size: 14px;
        margin-bottom: 10px;
    }
}
