.account-page {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto !important;

    &.hmeBody {
        width: 100% !important;
    }

    .hme-admin-system-status {
        width: 85%;
        min-height: 40px;
        margin: 0 auto;
    }

    .hme-account-wrapper {
        flex-grow: 1;
        min-width: 1380px;
    }

    .hme-account {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .hme-account-content {
        display: flex;
        padding-bottom: 20px;

        .hme-section-sm {
            flex: 1;
        }

        .hme-section-md {
            flex: 2;
        }
    }

    .hme-account-readonly {
        span {
            height: 21px;
            margin: 8px 0 0 2px;
            font-family: Assistant;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 1.44px;
            text-align: left;
            color: #000819;
        }

        .hme-label {
            height: 15px;
            margin: 0 0 8px;
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.14;
            letter-spacing: 1.26px;
            text-align: left;
            color: #606672;
        }

        .hme-account-form-column {
            width: 50%;
        }

        .hme-info-field {
            width: 85%;
        }
    }

    .hme-section-first {
        padding: 0 20px 30px 0;
    }

    .hme-section-spacer {
        padding-left: 30px;
    }

    .hme-account-section-wrapper {
        border-top: 1px solid #e8e9ed;
        padding-top: 20px;
        margin-top: 0px;
    }

    .hme-account-form-section {
        margin-bottom: 20px;

        .hme-label {
            margin-bottom: 8px;
        }

        .hme-radio {
            .hme-label {
                font-weight: bold;
                margin-bottom: 0;
            }
        }

        .hme-onoff-slider-component {
            .hme-checkbox-slider-label-text {
                padding-left: 8px;
                text-transform: uppercase;
            }
        }

        .hme-account-form-description {
            padding-left: 27px;
            margin-bottom: 10px;

            &.disabled {
                color: #606672;
            }
        }

        &.hme-account-subscription-types__readonly {
            border: 0px ;
            display: flex;
            padding: 20px 0 0;
        }

        &.hme-account-subscription-types {
            border-top: 1px solid #e8e9ed;
            display: flex;
            padding: 20px 0 0;
        }

        &.hme-account-billing-cycle-account-status {
            padding: 0;
            margin: 0;
            .hme-section {
                padding-bottom: 0;
            }
        }

        &.hme-account-billing-cycle-account-status,
        &.hme-account-subscription-date-range-resend-email {
            display: flex;
            .hme-section {
                border-left: 0;
                padding-bottom: 0;
            }
        }
    }

    .hme-section {
        &.hme-section-without-title {
            .hme-account-form-area {
                .hme-title {
                    display: block;
                    margin-bottom: 20px;
                }
                .hme-account-billing-cycle-account-status,
                .hme-account-subscription-date-range-resend-email {
                    .hme-section {
                        overflow: visible;
                        &.hme-section-without-title {
                            .hme-title {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

.accounts-page.hme-page-component {
    padding: 27px 0 0 0;
}

body.modal-open {
    div.hme-tooltip {
        &.device-upgrade-tooltip {
            z-index: 100 !important;
        }
    }
}

@import './Mobile.scss';
