.hme-corporate-brand-sharing {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;

    &__content {
        padding: 27px 0 27px 50px;
        display: flex;
        flex-wrap: wrap;
    }

    &__field-item {
        display: flex;
        gap: 80px;
    }

    .hme-save-footer {
        position: sticky;
        bottom: 0;
        
        gap: 20px;

        .hme-button-component {
            margin: 0;
        }
    }
}

@import './Mobile.scss';
