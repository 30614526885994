@import 'mixins/breakpoints';

@include mobile-only {
    .hme-notifications-center__notification {
        &__name {
            font-family: Assistant;
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: 0.02px;
            flex: 1;
        }

        &__icon {
            align-self: flex-start;
            margin-top: 4px;
        }

        .hme-expand-icon {
            height: 23px;
            overflow: visible;

            &__icon {
                margin-top: -11px;
            }
        }

        &__title {
            height: max-content;
        }

        &__sent-date {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.71;
            letter-spacing: 1.26px;
            padding-right: 0;
        }

        &__body {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.71;
            letter-spacing: 0.27px;
        }

        &__footer {
            padding-top: 15px;
            display: flex;
        }

        &__remove {
            display: block;
            margin-left: auto;
        }

        &__expanded-content {
            margin-right: 0;
        }
    }
}