.hme-tree-input {
    width: 100%;

    .hme-label {
        margin-bottom: 8px;
    }

    .hme-tree-input__box {
        width: 100%;
        height: 270px;
        padding: 20px 7px 7px 25px;
        border: solid 1px #a7adba;
        border-radius: 24px;
        background-color: #fff;
        margin-bottom: 16px;

        .hme-tree-input__box__no-stores {
            display: flex;
            justify-content: center;
        }
    }

    .hme-tree-input__box-scheduler {
        height: 310px !important;
    }

    .hme-tree {
        height: 100%;
        overflow: auto;
        padding-right: 13px;
    }

    .hme-tree-input__selection {
        height: 18px;
        width: max-content;
        margin-left: auto;
        margin-right: auto;
    }

    .hme-tree-input__selection__deselect-all {
        margin-left: 42px;
        position: relative;

        &::before {
            content: " ";
            border-left: solid 1px #a7adba;
            height: 18px;
            position: absolute;
            left: -21px;
        }
    }

    &.hme-tree-input--focused {
        .hme-tree-input__box {
            border: solid 2px #0074ef;
            padding: 19px 6px 6px 24px;
        }
    }
}

@import './Mobile.scss';
