.hme-view-user__header {
    .hme-title {
        display: inline-block;
    }

    .hme-view-user__header__link {
        margin-left: 81px;
        border-radius: 0;
        position: relative;

        &--masquerade {
            margin-right: 5px;
        }

        &::before {
            border-left: solid 1px #e5e5e5;
            position: absolute;
            content: " ";
            height: 41px;
            left: -41px;
            top: -11px;
        }
    }

    .hme-view-user__header__tip {
        width: 410px;
    }
}

@import './Mobile.scss';
