.apply-device-settings-status-page {
  min-width: 1700px;
  padding: 45px 48px 20px 48px;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
}

.apply-device-settings-status-page .apply-device-settings-paginate {
  margin: 20px 0;
}

.apply-device-settings-status-page .apply-device-settings-paginate .hme-label {
  text-transform: none;
  font-weight: normal;
}

@media screen and (max-width: 767px) {
  .applyDeviceSettings.hme-page-content {
    overflow-y: visible !important;
  }
  .applyDeviceSettings .tasks-table-admin {
    overflow: visible;
  }
  .applyDeviceSettings .apply-device-settings-status-page.permission-type-admin, .applyDeviceSettings .apply-device-settings-status-page.permission-type-distributor {
    min-width: 0;
    padding: 21px 15px;
  }
  .applyDeviceSettings .apply-device-settings-status-page .hme-tile-list__wrapper {
    padding: 0;
  }
  .applyDeviceSettings .apply-device-settings-status-page .admin-apply-device-settings-taskbar {
    flex-direction: column;
    padding-bottom: 19px;
  }
  .applyDeviceSettings .apply-device-settings-status-page .admin-apply-device-settings-taskbar .hme-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.3;
    letter-spacing: -0.28px;
    margin: 0 0 15px;
  }
  .applyDeviceSettings .apply-device-settings-status-page .admin-apply-device-settings-taskbar .admin-apply-device-settings-taskbar-link {
    order: 1;
    justify-content: flex-start;
    margin: 0 0 15px;
  }
  .applyDeviceSettings .apply-device-settings-status-page .admin-apply-device-settings-taskbar .admin-apply-device-settings-taskbar-link a,
  .applyDeviceSettings .apply-device-settings-status-page .admin-apply-device-settings-taskbar .admin-apply-device-settings-taskbar-link .hme-button-component {
    width: 100%;
  }
  .applyDeviceSettings .apply-device-settings-status-page .admin-apply-device-settings-taskbar .hme-admin-header-date-filter,
  .applyDeviceSettings .apply-device-settings-status-page .admin-apply-device-settings-taskbar .admin-apply-device-settings-taskbar-filters-count,
  .applyDeviceSettings .apply-device-settings-status-page .admin-apply-device-settings-taskbar .admin-apply-device-settings-taskbar-search-group {
    order: 2;
    border: 0;
  }
  .applyDeviceSettings .apply-device-settings-status-page .admin-apply-device-settings-taskbar .admin-apply-device-settings-taskbar-search-group {
    padding: 0;
    height: auto;
    flex-direction: column;
    margin: 0 0 15px;
  }
  .applyDeviceSettings .apply-device-settings-status-page .admin-apply-device-settings-taskbar .admin-apply-device-settings-taskbar-search-group .hme-label {
    height: auto;
    padding: 0;
    margin: 0 0 8px;
  }
  .applyDeviceSettings .apply-device-settings-status-page .admin-apply-device-settings-taskbar .admin-apply-device-settings-taskbar-separated-content {
    margin: 0;
  }
  .applyDeviceSettings .apply-device-settings-status-page .admin-apply-device-settings-taskbar .hme-admin-header-date-filter {
    margin: 0;
    height: auto;
    flex-direction: column;
    padding: 0;
    border: 0;
  }
  .applyDeviceSettings .apply-device-settings-status-page .admin-apply-device-settings-taskbar .hme-admin-header-date-filter-content {
    margin: 0;
  }
  .applyDeviceSettings .apply-device-settings-status-page .admin-apply-device-settings-taskbar .hme-admin-header-date-filter-label {
    margin: 0 0 8px;
  }
  .applyDeviceSettings .apply-device-settings-status-page .admin-apply-device-settings-taskbar .hme-admin-header-date-filter .hme-label {
    height: auto;
    padding: 0;
  }
  .applyDeviceSettings .apply-device-settings-status-page .admin-apply-device-settings-taskbar .admin-apply-device-settings-taskbar-filters-count {
    display: none;
  }
  .applyDeviceSettings .apply-device-settings-status-page .hme-grid {
    display: none;
  }
  .applyDeviceSettings .apply-device-settings-status-page .hme-tile-list .hme-title {
    margin: 0 0 15px;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 0.02px;
    color: #606672;
    text-transform: uppercase;
  }
  .applyDeviceSettings .apply-device-settings-status-page .hme-tile-list .hme-tile-list-item__rows__column .hme-tile-list__row .hme-tile-list__row__value {
    word-break: break-all;
    white-space: normal;
  }
  .applyDeviceSettings .apply-device-settings-status-page .hme-tile-list .hme-tile-list-item__toggle-link {
    margin: 0;
  }
  .applyDeviceSettings .apply-device-settings-status-page .hme-tile-list .hme-tile-list-item__rows__wrapper {
    margin: -15px 0;
  }
  .applyDeviceSettings .apply-device-settings-status-page .hme-tile-list .hme-tile-list-item__rows__column {
    border-top: solid 1px #e5e5e5;
    padding: 15px 0;
  }
  .applyDeviceSettings .apply-device-settings-status-page .hme-tile-list .hme-tile-list-item__rows__column:first-child {
    border-top: 0;
    padding-top: 0;
  }
  .applyDeviceSettings .apply-device-settings-status-page .hme-tile-list__row__subcolumns {
    margin: 0 0 -15px;
  }
  .applyDeviceSettings .apply-device-settings-status-page .hme-tile-list__row__subcolumn {
    border-top: dashed 1px #e5e5e5;
    padding: 15px 0;
  }
  .applyDeviceSettings .apply-device-settings-status-page .hme-tile-list__row__subcolumn:first-of-type {
    border-top: 0;
    padding-top: 0;
  }
  .hme-tile-list__sort-filter-modal .hme-toggle .toggle-button-group .hme-button-component {
    padding-left: 5px;
    padding-right: 5px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1173px) {
  .applyDeviceSettings.hme-page-content {
    overflow-y: visible !important;
  }
  .applyDeviceSettings .tasks-table-admin {
    overflow: visible;
  }
  .applyDeviceSettings .apply-device-settings-status-page.permission-type-admin, .applyDeviceSettings .apply-device-settings-status-page.permission-type-distributor {
    min-width: 0;
    padding: 21px 15px;
  }
  .applyDeviceSettings .apply-device-settings-status-page .hme-tile-list__wrapper {
    padding: 0;
  }
  .applyDeviceSettings .apply-device-settings-status-page .admin-apply-device-settings-taskbar {
    flex-direction: column;
    padding-bottom: 19px;
  }
  .applyDeviceSettings .apply-device-settings-status-page .admin-apply-device-settings-taskbar .hme-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.3;
    letter-spacing: -0.28px;
    margin: 0 0 15px;
  }
  .applyDeviceSettings .apply-device-settings-status-page .admin-apply-device-settings-taskbar .admin-apply-device-settings-taskbar-link {
    order: 1;
    justify-content: flex-start;
    margin: 0 0 15px;
  }
  .applyDeviceSettings .apply-device-settings-status-page .admin-apply-device-settings-taskbar .admin-apply-device-settings-taskbar-link a,
  .applyDeviceSettings .apply-device-settings-status-page .admin-apply-device-settings-taskbar .admin-apply-device-settings-taskbar-link .hme-button-component {
    width: 100%;
  }
  .applyDeviceSettings .apply-device-settings-status-page .admin-apply-device-settings-taskbar .hme-admin-header-date-filter,
  .applyDeviceSettings .apply-device-settings-status-page .admin-apply-device-settings-taskbar .admin-apply-device-settings-taskbar-filters-count,
  .applyDeviceSettings .apply-device-settings-status-page .admin-apply-device-settings-taskbar .admin-apply-device-settings-taskbar-search-group {
    order: 2;
    border: 0;
  }
  .applyDeviceSettings .apply-device-settings-status-page .admin-apply-device-settings-taskbar .admin-apply-device-settings-taskbar-search-group {
    padding: 0;
    height: auto;
    flex-direction: column;
    margin: 0 0 15px;
  }
  .applyDeviceSettings .apply-device-settings-status-page .admin-apply-device-settings-taskbar .admin-apply-device-settings-taskbar-search-group .hme-label {
    height: auto;
    padding: 0;
    margin: 0 0 8px;
  }
  .applyDeviceSettings .apply-device-settings-status-page .admin-apply-device-settings-taskbar .admin-apply-device-settings-taskbar-separated-content {
    margin: 0;
  }
  .applyDeviceSettings .apply-device-settings-status-page .admin-apply-device-settings-taskbar .hme-admin-header-date-filter {
    margin: 0;
    height: auto;
    flex-direction: column;
    padding: 0;
    border: 0;
  }
  .applyDeviceSettings .apply-device-settings-status-page .admin-apply-device-settings-taskbar .hme-admin-header-date-filter-content {
    margin: 0;
  }
  .applyDeviceSettings .apply-device-settings-status-page .admin-apply-device-settings-taskbar .hme-admin-header-date-filter-label {
    margin: 0 0 8px;
  }
  .applyDeviceSettings .apply-device-settings-status-page .admin-apply-device-settings-taskbar .hme-admin-header-date-filter .hme-label {
    height: auto;
    padding: 0;
  }
  .applyDeviceSettings .apply-device-settings-status-page .admin-apply-device-settings-taskbar .admin-apply-device-settings-taskbar-filters-count {
    display: none;
  }
  .applyDeviceSettings .apply-device-settings-status-page .hme-grid {
    display: none;
  }
  .applyDeviceSettings .apply-device-settings-status-page .hme-tile-list .hme-title {
    margin: 0 0 15px;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 0.02px;
    color: #606672;
    text-transform: uppercase;
  }
  .applyDeviceSettings .apply-device-settings-status-page .hme-tile-list .hme-tile-list-item__rows__column .hme-tile-list__row .hme-tile-list__row__value {
    word-break: break-all;
    white-space: normal;
  }
  .applyDeviceSettings .apply-device-settings-status-page .hme-tile-list .hme-tile-list-item__toggle-link {
    margin: 0;
  }
  .applyDeviceSettings .apply-device-settings-status-page .hme-tile-list .hme-tile-list-item__rows__wrapper {
    flex-direction: column;
    margin: -15px 0;
  }
  .applyDeviceSettings .apply-device-settings-status-page .hme-tile-list .hme-tile-list-item__rows__column {
    border-top: solid 1px #e5e5e5;
    border-left: 0;
    padding: 15px 0;
  }
  .applyDeviceSettings .apply-device-settings-status-page .hme-tile-list .hme-tile-list-item__rows__column:first-child {
    border-top: 0;
    padding-top: 0;
  }
  .applyDeviceSettings .apply-device-settings-status-page .hme-tile-list__row__subcolumns {
    margin: 0 0 -15px;
  }
  .applyDeviceSettings .apply-device-settings-status-page .hme-tile-list__row__subcolumn {
    border-top: dashed 1px #e5e5e5;
    padding: 15px 0;
  }
  .applyDeviceSettings .apply-device-settings-status-page .hme-tile-list__row__subcolumn:first-of-type {
    border-top: 0;
    padding-top: 0;
  }
  .hme-tile-list__sort-filter-modal .hme-toggle .toggle-button-group .hme-button-component {
    padding-left: 5px;
    padding-right: 5px;
  }
}
