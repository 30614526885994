.hme-grid {
    .hme-grid-header {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        background: #ffffff;
        z-index: 2;

        .hme-grid-expanded-row .hme-grid-expanded-content & {
            background-color: transparent;
            z-index: 1;
        }
    }

    .hme-grid-header-row {
        &.hme-grid-row {
            .hme-grid-cell {
                display: flex;
                font-size: 14px;
                line-height: 1.71;
                text-align: left;
                color: #606672;
                padding-top: 10px;
                padding-bottom: 10px;
                text-transform: uppercase;
                font-weight: 600;
                border-top: 0;
                overflow: visible;

                .hme-grid-cell-content {
                    overflow: visible;
                    align-self: flex-end;

                    .hme-grid-cell-filter-applied {
                        color: #0074ef;
                    }
                }

                &.hme-grid-cell-checkbox .hme-grid-cell-content {
                    overflow: visible;
                }

                &.hme-grid-centered-cell {
                    text-align: center;
                }

                &.hme-grid-right-aligned-cell {
                    text-align: right;
                }

                .hme-tip {
                    margin-left: 6px;

                    .icon {
                        position: relative;
                        top: 1px;
                    }
                }
            }

            .hme-grid-expanded-row .hme-grid-expanded-content & {
                background-color: #ffffff;
            }

            .hme-grid-row-odd.hme-grid-expanded-row .hme-grid-expanded-content & {
                background-color: #f3f3f5;
            }

            .hme-grid-row-hovered.hme-grid-expanded-row .hme-grid-expanded-content & {
                background-color: #E8F0FE;
            }
        }
    }
}
