@import 'src/assets/styles/Variables';

.hme-api-management-copy-token-modal {
    &__content {
        display: grid;
        gap: 30px;
        max-width: 505px;
        padding: 40px;

        font-family: $font-family;
    }

    &__header {
        display: grid;
        gap: 20px;
        justify-items: center;
    }

    &__alert-icon {
        width: 65px;
        height: 65px;
    }

    &__title {
        margin-bottom: 0;

        font-size: 24px;
        color: $main-black;
    }

    &__subtitle {
        font-size: 18px;
        color: $main-black;
    }

    &__clipboard-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
    }

    &__clipboard-icon {
        width: 18px;
        height: 18px;
    }

    &__token-area {
        padding: 15px 25px;

        background-color: #e8e9ed;
        color: $main-gray;
        overflow-wrap: anywhere;
        text-align: left;
        border-radius: 24px;
    }

    &__done-btn {
        justify-self: center;
    }
}
