.hme-dxs-feed-modal-dialog {
  &.modal-dialog {
    &.hme-modal-component {
      .modal-content {
        padding: 20px;
        min-width: 500px;
        min-height: 300px;
      }

      .modal-header {
        .hme-title {
          margin-left: 13px;
        }

        .btn-close {
          margin-right: 0;
        }
      }

      .modal-body {
        overflow: visible;
      }

      .modal-footer {
        padding: 0;
        .hme-button-component {
          margin-left: 15px;
        }
      }
    }
  }
  .hme-feed-form-section {
    margin: 0 0 15px;
  }
}
