@import 'src/assets/styles/Variables';

.hme-page-layout {
    --gap: 30px;

    height: 100%;

    &,
    &__content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

    &__content {
        height: 100%;
        padding: var(--gap) 50px;
    }

    &__page-title {
        flex-basis: 100%;
        display: flex;

        margin-bottom: var(--gap);

        .hme-title {
            margin-bottom: 0;

            text-transform: capitalize;
        }
    }

    // SECTION START
    --sections-gap: 60px;
    --sections-width: 405px;
    --sections-fields-gap: 20px;

    &__fields-section {
        flex-grow: 1;
        max-width: var(--sections-width);
        height: unset;
        padding: 0 var(--sections-gap);
        overflow-y: unset;

        &:not(:first-of-type):not(:last-of-type) {
            max-width: calc(var(--sections-width) + var(--sections-gap));
        }

        .hme-title {
            margin-bottom: var(--sections-fields-gap);
        }
    }

    &__field {
        display: grid;
        gap: var(--sections-fields-gap);
    }

    &__field-content {
        width: 100%;
        padding-top: var(--sections-fields-gap);

        border-top: 1px solid $input-underline;

        &:first-child {
            border-top: none;
            padding-top: 0;
        }
    }

    // modifiers
    &__fields-section--borderless &__field-content {
        padding-top: 0;

        border-top: none;
    }
    // SECTION END

    &__main-content {
        flex-grow: 1;
        display: flex;
        align-content: flex-start;
        column-gap: var(--sections-gap);
        flex-wrap: wrap;
        height: 100%;
    }

    .hme-save-footer {
        position: sticky;
        bottom: 0;
    }
}
