.new-vaio-provider-page {
    // the style is added in order to remove the overlapping of the sidebar overlay by main subheader
    // todo: it would be better to fix it in the Layout component (requires regression testing)
    margin: -15px 0 0;
}

.new-vaio-provider-wrapper {
    height: 100%;
    position: relative;
}

.new-vaio-provider {
    height: 100%;
    display: flex;
    flex-direction: column;

    &__container {
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: auto;
        flex: 1;
    }

    &__content {
        overflow: auto;
        padding: 28px 50px 0;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    &__header {
        margin-bottom: 25px;
    }

    &__sections {
        display: flex;
        flex: 1;
    }

    .vaio-provider-form-label-title {
        display: block;
    }

    .hme-single-select__box__star {
        font-weight: normal;
    }

    .hme-single-select {
        margin-top: 20px;
    }

    .hme-single-select {
        .hme-single-select__box__text {
            padding-top: 15px;
        }
    }
}

@import 'Tablet.scss';
@import 'Mobile.scss';
