.hme-grid-cell-expand-icon {
    flex: 0;
    min-width: 48px;
    min-height: 64px;
    text-align: center;

    &.hme-grid-cell {
        padding-top: 11px;
        padding-bottom: 0;
    }

    &.hme-grid-cell-expand-icon-hidden {
        visibility: hidden;
        min-height: 1px;
        height: 1px;
        overflow: hidden;
        padding-top: 0;
        padding-bottom: 0;
    }

    .hme-grid-cell-content {
        padding-left: 0;
        padding-right: 0;
    }

    .hme-expand-icon {
        padding-top: 9px;

        &.hme-expand-icon-expanded {
            padding-top: 0;
        }
    }
}