@import 'src/mixins/breakpoints';

@include before-desktop-only {
    .hme-contest-view-edit-form__results__rank {
        &__ribbon {
            --height: 24px;

            width: 22px;
        }
    }
}
