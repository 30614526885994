.hme-view-edit-contest--results__header {
    --gap: 40px;

    padding: var(--gap);
    padding-bottom: 0;
    margin-bottom: 0;
    display: flex;
    gap: var(--gap);
    align-items: center;

    &__download-icon {
        width: 20px;
        height: 20px;
        transform: rotate(180deg);
    }
}

@import 'Tablet.scss';
