

.hme-settings-display {
    --header-items-gap: 40px;
    --section-field-gap: 30px;
    --border-color: #e8e9ed;

    position: relative;

    &__wrapper {
        padding: 55px 50px;
        padding-bottom: 80px;
        height: calc(100% - 80px);
        overflow-y: auto;
    }

    &__header {
        display: flex;
        gap: var(--header-items-gap);
        align-items: center;
        margin-bottom: var(--section-field-gap);

        & > *:not(:first-child):not(:last-child) {
            border-left: 1px solid var(--border-color);
            border-right: 1px solid var(--border-color);
        }
    }

    &__select-metric {
        flex-grow: 1;
        max-width: 280px;
        padding: 0 var(--header-items-gap);

        box-sizing: unset;
    }

    &__main-content {
        display: flex;

        & > * {
            width: 100%;
        }
    }

    &__section {
        height: auto;

        .hme-title {
            margin-bottom: var(--header-items-gap);
        }

        &:not(:last-of-type) {
            padding-right: var(--section-field-gap);
        }

        &:not(:first-of-type) {
            padding-left: var(--section-field-gap);
        }
    }

    &__color-fields-wrapper {
        margin-top: 8px;
    }

    &__section-content {
        display: flex;
        gap: var(--section-field-gap);
    }

    &__section-column {
        display: flex;
        flex-direction: column;
        row-gap: var(--section-field-gap);
        max-width: 345px;
        width: 100%;
    }

    &__default-view-toggle {
        .toggle-button-group {
            flex-wrap: wrap;

            .hme-button-component {
                flex-basis: 49%;

                &:nth-child(odd) {
                    border-top-left-radius: 24px;
                    border-bottom-left-radius: 24px;
                }

                &:nth-child(even) {
                    border-top-right-radius: 24px;
                    border-bottom-right-radius: 24px;
                }
            }
        }
    }

    &__save-footer {
        position: sticky;
        bottom: 0;
    }
}

@import './Tablet.scss';
@import './Mobile.scss';
