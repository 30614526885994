@import "src/assets/styles/Variables";

.hme-customer-notification {
  position: relative;

  padding: 16px 52px;

  background-color: $main-white;
  box-shadow: 0 5px 8px -2px rgba(0, 8, 25, 0.3);

  &__text {
    font-family: $font-family;
    font-weight: 600;
    font-size: 17px;
    color: $main-black;
    white-space: pre-wrap;

    ul, ol {
      margin: 0;
    }
  }

  &__info-wrapper,
  &__close-btn {
    position: absolute;
    top: 0;

    padding: 16px;

    box-sizing: content-box;
  }

  &__info-wrapper {
    left: 0;

    width: 22px;
    height: 22px;

    color: $hme-blue-color;
  }

  &__close-btn {
    right: 0;

    width: 18px;
    height: 18px;

    color: inherit;
  }

  &__close-icon,
  &__info-icon {
    width: 100%;
    height: 100%;
  }

  .hme-loader-icon {
    width: 55px;
    height: 55px;
  }
}
