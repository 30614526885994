.hme-create-task-account-form {
    height: calc(100% - 147px);

    .hme-create-task-account-form-input-row {
        display: flex;

        .hme-input-auto-complete-variant {
            flex: 1;
        }

        .hme-tip {
            flex: none;
            font-size: 16px;
            margin-top: 16px;
            margin-left: 12px;
        }
    }

    .hme-account-brand {
        margin-top: 20px;
    }
}

.hme-create-task-account-form-tip {
    width: 410px;
}
