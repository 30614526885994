.reports-menu.dropdown-menu {
  width: 220px;
  height: max-content !important;
  list-style: none;
  text-align: start;
  display: grid;
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  padding: 0;
  z-index: 100;
  top: 40px;
}

.reports-menu .dropdown-menu-item {
  width: 100%;
  font-family: Assistant;
  padding: 0px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: 1.28px;
  text-align: left;
}

.reports-menu .dropdown-menu-item a {
  padding: 10.8px 12px 10.8px 12px;
}

.reports-menu .dropdown-menu-item a:hover, .active-menu-item {
  font-weight: 600;
  color: #ffffff !important;
  background-color: #2c6df4;
}

.reports-menu.dropdown-menu.clicked {
  display: none;
}
