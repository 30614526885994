.hme-loading-modal.modal-dialog.hme-modal-component {
    .modal-content {
        width: 410px;
        min-height: 342px;
        padding-left: 110px;
        padding-right: 110px;
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .btn-close {
        display: none;
    }

    .hme-label {
        width: 100%;
        text-transform: none;
        margin-top: 20px;
        font-size: 24px;
        font-weight: normal;
        line-height: 1.33;
        letter-spacing: 0.24px;
        text-align: center;
    }
}