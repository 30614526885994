.hme-view-role {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 27px 25px 0 50px;
    min-width: max-content;
    overflow: auto;

    .hme-view-role__header {
        width: 100%;
        margin-bottom: 30px;
        flex: 0;
    }

    .hme-view-role__sections {
        display: flex;
        flex: 1;
        min-width: 1100px;
    }

    .hme-view-role__general {
        flex: 0 1 405px;
        min-width: 405px;
    }

    .hme-view-role__permissions {
        flex: 1 1;
        border-left: solid 1px #e5e5e5;
        min-height: 100%;
        height: max-content;
    }

    .hme-view-role__footer {
        flex: 0;
        position: sticky;
        bottom: 0;
        margin-left: -50px;
        margin-right: -25px;
        min-width: 1100px;
    }
}

@import './Mobile.scss';
