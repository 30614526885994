.hme-users--distributor-account {
    .hme-account-email-header {
        flex: 0;
        margin-bottom: 20px;
    }
}
.hme-users--distributor-account--confirm-button {
    margin-left: 20px;
}

@import './Mobile.scss';
