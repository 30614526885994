@import 'src/assets/styles/Variables.scss';

.hme-button-component.hme-accept-button-component {
    background-image: $gradient-green;
    color: $main-white;

    &:hover, &:focus {
        color: $main-white;
    }

    &.hme-button-disabled-component {
        opacity: 0.14;
        background-image: $gradient-green;
        color: $main-white;
    }
}