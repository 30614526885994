@import '../../../../mixins/breakpoints.scss';


@include mobile-only {
    .hme-manage-hierarchy__hierarchy {
        &__sub-header {
            gap: 4px;

            .hme-input-container {
                margin-top: 11px;
            }

            .hme-button-component {
                min-width: 49%;
                padding-left: 5px;
                padding-right: 5px;
            }
        }
    }
}
