.hme-input-color-picker.hme-input-container {

    & > .hme-label {
        margin: 0 0 8px 0 ;
    }

    & > .hme-input-wrapper {
        display: flex;
        & > .hme-input-component-before {
            display: none;
        }
        & > .hme-input-component {
            overflow: scroll;
            font-family: Assistant;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.31;
            letter-spacing: 1.44px;
            text-align: left;
            color: #000819;
        }
        & > .color-preview-wrapper {
            display: flex;
            width: 50px;
            height: 100%;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: 10px;
            border-left: 1px solid #a7adba;
            & > .color-preview {
                width: 28px;
                height: 28px;
            }
            & > .hexColorPickerWrapper {
                position: absolute;
                bottom:10px;
                right:50px;
                zIndex: 1;
            }
        }
    }
}
