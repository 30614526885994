.hme-stores-status--partner__grid {
    flex: 1;
    overflow: auto;

    .hme-stores-status--partner__grid__inner-cell {
        padding-top: 21px;
        padding-bottom: 21px;

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            padding-bottom: 0;
        }
    }

    .hme-stores-status--partner__grid__status-cell.hme-grid-cell.hme-grid-cell-last-in-block:last-of-type {
        text-align: right;

        .hme-grid-cell-content {
            padding-right: 15px;
            padding-left: 0;
        }
    }

    .hme-stores-status--partner__grid__action {
        .hme-grid-cell-content {
            text-align: center;
        }
    }

    .hme-stores-status--partner__grid__header__status-cell .hme-grid-cell-content {
        text-align: center;
    }
}

.hme-stores-status--partner__tile-list__no-records {
    text-align: center;
    font-weight: normal;
    font-size: 14px;
    text-transform: none;
    display: block;
    width: 100%;
}


.hme-stores-status--partner__paginate {
    margin-top: 40px;
    flex: 0;
}
