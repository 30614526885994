@import "../../../../../assets/styles/Variables";
@import "../../../../../assets/styles/Responsive";

.custom-smacktalk-page-history {
    & > .hme-history-container-s {
        display: none;
    }
}

.hme-history-popup-backdrop {
    display: none !important;
}
.modal.hme-history-popup-modal, .modal > .hme-history-popup-wrapper {
    display: none !important;
}

@include smaller($l-desktop) {
    .custom-smacktalk-page-history {
        & > .hme-title {
            display: flex;
            justify-content: center;
            align-items: center;
            & > :not(.hme-button-component) {
                display: none;
            }
            & > .hme-button-component {
                margin: 0;
            }
        }
        & > .hme-history-container {
            display: none;
        }
        & > .hme-history-container-s {
            display: block;
            & > .hme-history-tiles-header {

            }
            & > .hme-tiles-container {
                & > .hme-tile {
                    min-height: 74px;
                    min-width: unset;
                    & > .hme-manage-messages-tile-inner {
                        & > .category-icon {
                            & > .hme-manage-messages-category {
                                opacity: .5;
                                &.active {
                                    opacity: 1;
                                }
                                & > img {
                                    margin: 0 12px;
                                    padding: 0;
                                }
                            }
                        }
                        & > .manage-messages-data {
                            & > .manage-messages-list {
                                & > div > dd {
                                    margin: 0 12px 0 0;
                                }
                            }
                        }
                    }
                    & > .hme-manage-messages-tile-controls {
                        & > button {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            & > svg {
                                width: 16px;
                                height: 16px;
                                color: $main-color;
                                margin: 0 5px 0 0;
                            }
                        }
                    }
                }
            }
        }
    }
    .hme-history-popup-backdrop {
        display: block !important;
    }
    .modal.hme-history-popup-modal {
        display: flex !important;
        & > .hme-history-popup-wrapper {
            display: flex !important;
            width: 100%;
            & > .modal-content {
                border-radius: 8px;
                & > .hme-joined-filters-popup {
                    width: 100%;
                    max-height: calc(100vh - 100px) !important;
                    & > .hme-joined-filters-popup-header {
                        & > .hme-history-header-reset.history-reset-filters {
                            float: right;
                            font-family: Assistant;
                            font-size: 16px;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.31;
                            letter-spacing: 1.28px;
                            text-align: left;
                            color: $main-color;
                            text-decoration: underline;
                            cursor: pointer;
                            margin: 0 20px 0 0;
                        }
                    }
                    & > .hme-joined-filters-popup-body {
                        & > .hme-label.title-label {
                            display: flex;
                            margin: 0 0 15px 0;
                            font-family: Assistant;
                            font-size: 16px;
                            font-weight: 600;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.5;
                            letter-spacing: 1.44px;
                            text-align: left;
                            color: #000819;
                        }
                        & > .hme-filter-section-collapsable {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            padding: 0 30px 0 0;
                            & > .hme-label {
                                margin: 0;
                                font-family: Assistant;
                                font-size: 14px;
                                font-weight: 600;
                                font-stretch: normal;
                                font-style: normal;
                                line-height: 1.5;
                                letter-spacing: normal;
                                text-align: left;
                                color: #606672; 
                            }
                            & > svg {
                                color: $main-color;
                                &.collapsed {
                                    color: #606672; 
                                    transform: rotate(180deg);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
