.hme-view-role__footer {
    box-shadow: 0 -10px 20px 0 rgba(0, 0, 0, 0.16);
    padding: 20px 44px;
    display: flex;
    justify-content: flex-end;
    background-color: #FFF;

    .hme-view-role__footer__save {
        margin-left: 20px;
    }
}

@import './Mobile.scss';
