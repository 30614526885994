@import 'src/assets/styles/Variables';

.ltr {
    text-align: left;
}

.rtl {
    text-align: right;
}

.editor-container {
    position: relative;
    width: 100%;

    font-family: $font-family;
    line-height: 20px;
    font-weight: 400;
    color: #000;
    text-align: left;
    border-radius: 10px;
    background-color: $main-white;
    border: solid 1px #a7adba;
}

.editor-inner {
    position: relative;
}

.editor-input {
    min-height: 150px;
    resize: none;
    font-size: 15px;
    caret-color: #050505;
    position: relative;
    tab-size: 1;
    outline: 0;
    padding: 15px 10px;
    caret-color: #444;
}

.editor-placeholder {
    color: #999;
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    top: 15px;
    left: 10px;
    font-size: 15px;
    user-select: none;
    display: inline-block;
    pointer-events: none;
}

.editor-text-bold {
    font-weight: bold;
}

.editor-text-italic {
    font-style: italic;
}

.editor-text-underline {
    text-decoration: underline;
}

.editor-text-strikethrough {
    text-decoration: line-through;
}

.editor-text-underlineStrikethrough {
    text-decoration: underline line-through;
}

.editor-link {
    color: rgb(33, 111, 219);
    text-decoration: none;
}

.tree-view-output {
    display: block;
    background: #222;
    color: #fff;
    padding: 5px;
    font-size: 12px;
    white-space: pre-wrap;
    margin: 1px auto 10px auto;
    max-height: 250px;
    position: relative;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: auto;
    line-height: 14px;
}

.editor-tokenComment {
    color: slategray;
}

.editor-tokenPunctuation {
    color: #999;
}

.editor-tokenProperty {
    color: #905;
}

.editor-tokenSelector {
    color: #690;
}

.editor-tokenOperator {
    color: #9a6e3a;
}

.editor-tokenAttr {
    color: #07a;
}

.editor-tokenVariable {
    color: #e90;
}

.editor-tokenFunction {
    color: #dd4a68;
}

.editor-paragraph {
    margin: 0;
    margin-bottom: 8px;
    position: relative;
}

.editor-paragraph:last-child {
    margin-bottom: 0;
}

.editor-heading-h1 {
    font-size: 24px;
    color: rgb(5, 5, 5);
    font-weight: 400;
    margin: 0;
    margin-bottom: 12px;
    padding: 0;
}

.editor-heading-h2 {
    font-size: 15px;
    color: rgb(101, 103, 107);
    font-weight: 700;
    margin: 0;
    margin-top: 10px;
    padding: 0;
    text-transform: uppercase;
}

.editor-quote {
    margin: 0;
    margin-left: 20px;
    font-size: 15px;
    color: rgb(101, 103, 107);
    border-left-color: rgb(206, 208, 212);
    border-left-width: 4px;
    border-left-style: solid;
    padding-left: 16px;
}

.editor-list-ol {
    padding: 0;
    margin: 0;
    margin-left: 16px;
}

.editor-list-ul {
    padding: 0;
    margin: 0;
    margin-left: 16px;
}

.editor-listitem {
    margin: 8px 32px 8px 32px;
}

.editor-nested-listitem {
    list-style-type: none;
}

pre::-webkit-scrollbar {
    background: transparent;
    width: 10px;
}

pre::-webkit-scrollbar-thumb {
    background: #999;
}

.toolbar {
    display: flex;
    justify-content: space-between;
    padding: 4px;

    border-bottom: inherit;
}

.toolbar button.toolbar-item {
    border: 0;
    display: flex;
    background: none;
    border-radius: 10px;
    padding: 8px;
    cursor: pointer;
    vertical-align: middle;
}

.toolbar button.toolbar-item:disabled {
    cursor: not-allowed;
}

.toolbar button.toolbar-item.spaced {
    margin-right: 2px;
}

.toolbar button.toolbar-item .format {
    background-size: contain;
    display: inline-block;
    height: 18px;
    width: 18px;
    margin-top: 2px;
    vertical-align: -0.25em;
    display: flex;
    opacity: 0.6;
}

.toolbar button.toolbar-item:disabled .format {
    opacity: 0.2;
}

.toolbar button.toolbar-item.active {
    background-color: rgba(223, 232, 250, 0.3);
}

.toolbar button.toolbar-item.active i {
    opacity: 1;
}

.toolbar .toolbar-item:hover:not([disabled]) {
    background-color: #eee;
}

.toolbar .divider {
    width: 1px;
    background-color: #eee;
    margin: 0 4px;
}

.toolbar select.toolbar-item {
    border: 0;
    display: flex;
    background: none;
    border-radius: 10px;
    padding: 8px;
    vertical-align: middle;
    appearance: none;
    width: 70px;
    font-size: 14px;
    color: #777;
    text-overflow: ellipsis;
}

.toolbar .toolbar-item .text {
    display: flex;
    line-height: 20px;
    width: 200px;
    vertical-align: middle;
    font-size: 14px;
    color: #777;
    text-overflow: ellipsis;
    width: 70px;
    overflow: hidden;
    height: 20px;
    text-align: left;
}

.toolbar .toolbar-item .icon {
    display: flex;
    width: 20px;
    height: 20px;
    user-select: none;
    margin-right: 8px;
    line-height: 16px;
    background-size: contain;
}

.toolbar .chevron-down {
    margin-top: 3px;
    width: 16px;
    height: 16px;
    display: flex;
    user-select: none;
}

.toolbar .chevron-down.inside {
    width: 16px;
    height: 16px;
    display: flex;
    margin-left: -25px;
    margin-top: 11px;
    margin-right: 10px;
    pointer-events: none;
}

.chevron-down {
    display: inline-block;
    height: 8px;
    width: 8px;
}

#block-controls button:hover {
    background-color: #efefef;
}

#block-controls button:focus-visible {
    border-color: blue;
}

#block-controls .block-type {
    background-size: contain;
    display: block;
    width: 18px;
    height: 18px;
    margin: 2px;
}

.editor-dropdown {
    z-index: 5;
    display: block;
    position: absolute;
    box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    min-width: 100px;
    min-height: 40px;
    background-color: #fff;
}

.editor-dropdown .item {
    margin: 0 8px 0 8px;
    padding: 8px;
    color: #050505;
    cursor: pointer;
    line-height: 16px;
    font-size: 15px;
    display: flex;
    align-content: center;
    flex-direction: row;
    flex-shrink: 0;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 8px;
    border: 0;
    min-width: 268px;
}

.editor-dropdown .item .active {
    display: flex;
    width: 20px;
    height: 20px;
    background-size: contain;
}

.editor-dropdown .item:first-child {
    margin-top: 8px;
}

.editor-dropdown .item:last-child {
    margin-bottom: 8px;
}

.editor-dropdown .item:hover {
    background-color: #eee;
}

.editor-dropdown .item .text {
    display: flex;
    line-height: 20px;
    flex-grow: 1;
    width: 200px;
}

.editor-dropdown .item .icon {
    display: flex;
    width: 20px;
    height: 20px;
    user-select: none;
    margin-right: 12px;
    line-height: 16px;
    background-size: contain;
}

.link-editor {
    position: absolute;
    top: -10000px;
    left: -10000px;
    z-index: 100;
    
    width: 100%;
    margin-top: -6px;
    padding: 8px 12px;
    padding: 8px 12px;
    max-width: 300px;
    
    opacity: 0;
    background-color: #fff;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    transition: opacity 0.5s;
    
    .link-input {
        position: relative;

        display: block;
        width: 100%;
        box-sizing: border-box;
        padding: 8px 12px;

        border-radius: 15px;
        background-color: #eee;
        font-size: 15px;
        font-family: inherit;
        color: rgb(5, 5, 5);
        border: 0;
        outline: 0;
    }

    .link-input a {
        color: rgb(33, 111, 219);
        text-decoration: none;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        margin-right: 30px;
        text-overflow: ellipsis;
    }

    .link-input a:hover {
        text-decoration: underline;
    }

    .link-edit {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
    
        width: 35px;
        vertical-align: -0.25em;
        padding: 8px;
        cursor: pointer;
    }

    .button {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin: 0 2px;
        padding: 6px;

        border-radius: 8px;
        cursor: pointer;
    }

    .button.hovered,
    .button:hover {
        display: inline-block;
        width: 20px;
        height: 20px;

        background-color: #eee;
    }

    .button svg,
    .actions svg {
        display: inline-block;
        height: 20px;
        width: 20px;

        vertical-align: -0.25em;
    }

    .link-open-new-tab {
        display: flex;
        column-gap: 5px;
        padding: 5px 0;

        font: inherit;

        &__label {
            flex-grow: 1;
        }
    }
}
