@import 'src/assets/styles/Variables';

.hme-day-selector {
    --gap: 20px;

    --dark-gray: #a7adba;
    --checkbox-color: var(--dark-gray);
    --checkbox-bg: transparent;
    --checkbox-text-color: var(--checkbox-color);
    --checkbox-active-color: #{$hme-blue-color};
    --checkbox-active-text-color: #{$main-white};
    --checkbox-hover-bg: #cbddfa;

    &, &__options-wrapper {
        display: flex;
        flex-direction: column;
        gap: var(--gap);
    }

    &__title {}

    &__all-selector {
        .hme-checkbox-label {
            font-size: 16px;
            line-height: 1.5;
            color: $main-gray;
        }
    }

    &__checkbox-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
    }

    &__checkbox {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;

        border-radius: 50%;
        border: 1px solid var(--checkbox-color);
        background-color: var(--checkbox-bg);

        .hme-checkbox-component-icon {
            display: none;
        }

        .hme-checkbox-label {
            margin: 0;

            font-weight: 600;
            text-align: center;
            color: var(--checkbox-text-color);
        }

        // detects if device has a "hover" feature
        // which basically means that this is a desktop device
        @media (hover: hover) {
            &:hover,
            &.hme-checkbox-component-checked:hover,
            &.hme-checkbox-component-checked:focus-within {
                --checkbox-color: var(--checkbox-hover-bg);
                --checkbox-bg: var(--checkbox-hover-bg);
                --checkbox-text-color: var(--checkbox-active-color);
            }
        }

        &:focus-within {
            --checkbox-color: var(--checkbox-active-color);
            --checkbox-bg: var(--checkbox-hover-bg);
            --checkbox-text-color: var(--checkbox-active-color);
        }

        &.hme-checkbox-component-checked {
            --checkbox-color: var(--checkbox-active-color);
            --checkbox-bg: var(--checkbox-active-color);
            --checkbox-text-color: var(--checkbox-active-text-color);
        }

        &.hme-checkbox-component-disabled,
        &.hme-checkbox-component-disabled:hover,
        &.hme-checkbox-component-disabled:focus-within {
            --checkbox-bg: #{$input-underline};
            --checkbox-color: var(--checkbox-bg);
            --checkbox-text-color: var(--dark-gray);
        }

        &.hme-checkbox-component-disabled.hme-checkbox-component-checked,
        &.hme-checkbox-component-disabled.hme-checkbox-component-checked:hover,
        &.hme-checkbox-component-disabled.hme-checkbox-component-checked:focus-within {
            --checkbox-bg: #c7ccd6;
            --checkbox-text-color: #868c99;
        }

        .hme-checkbox-input {
            appearance: none;
            display: unset;
        }
    }
}
