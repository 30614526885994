@import 'src/assets/styles/Variables';

.hme-title {
    margin-bottom: 0;

    font-family: $font-family;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: 0.03px;
    text-align: left;
    color: $main-black;
}

@import './variants/index.scss';