.hme-center-message-root-loader {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: unset;
  & > .hme-center-message-body {
      top: unset;
  }
}