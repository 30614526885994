.admin-system-status-linkwear-page {
  height: 100%;
  overflow-y: auto;
  padding: 27px 0 0 0;
  width: 85%;
  min-height: 40px;
  margin: 0 auto;

  .hme-admin-system-status-linkwear-section-wrapper {
    display: flex;
    justify-content: space-between;
    width: 50%;

    .hme-label {
      font-weight: bold;
      color: #9d9d9d;
    }
  }
}