.hme-roles__header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    min-width: 1210px;

    .hme-account-email-header {
        flex: 0;
        width: 100%;
        min-width: 100%;
        padding-left: 0;
        margin-top: 11px;
    }
}

@import './Mobile.scss';
