.hme-view-edit-store__footer {
  position: sticky;
  bottom: 0;
  width: 100%;
  height: 80px;
  box-shadow: 0 -10px 20px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  padding-right: 50px;
  z-index: 1;

  &__button {
    min-width: 120px;
    min-height: 40px;
    
  }
}

@import './Mobile.scss';