@import '../../../../mixins/breakpoints.scss';

@include mobile-only {
    .device-settings-taskbar {
        flex-wrap: wrap;

        &__name-tip {
            margin-left: 0;
            border-left: none;
            padding-left: 0;
            order: 3;
            width: 100%;
        }
    }
}