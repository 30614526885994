.dashboard-content {
  padding: 20px 0px;
  width: 100%;
}
.dashboard-type {
  line-height: 12px;
  font-size: 12px;
  height: 50px;
  min-width: 1220px;
}
.time-measure-list, .current-time {
  padding-left: 10px;
  padding-top: 10px;
  list-style: none;
}

.current-date-time h4 {
  font-size: 12px;
  font-weight: bold;
  padding: 10px 0 0;
  text-align: right;
}
.current-date-time>h4 {
  margin: 0;
}
.current-date-time h4.auto-refresh-header {
  margin: 0 -10px 0 0;
}
.current-date-time h4.auto-refresh-header a{
  cursor: pointer;
}

.current-date-time h4 span a {
  color: #5672AA;
  font-weight: normal;
  padding: 0px 10px;
}

ul.time-measure-list li, ul.current-time li {
  display: inline;
  padding-right: 5px;
  float: left;
}

ul.time-measure-list li a, ul.current-time li a {
  color: #337ab7;
}

ul.time-measure-list li a:hover, ul.current-time  li a:hover {
  color: #337ab7;
  cursor: pointer;
  text-decoration: underline;
}

.download-section {
  margin: 0px;
  margin-bottom: 25px;
  width: 120px;
  position: relative;
  z-index: 1000;
}

.download-section ul {
  padding-left: 0px;
  position: relative;
  font-weight: 900;
  list-style:none;
}

.download-section ul li {
  position: relative;
  list-style: none;
  cursor: pointer;
}

.download-btn {
  background-color: #06b1cb;
  text-align: center;
  background-repeat: no-repeat;
  color: #ffffff;
  cursor: pointer;
  width: 100px;
  padding: 10px 10px;
  font-size: 1.12em;
  font-weight: normal;
}

.download-content-table {
  /* overflow: hidden; */
  padding: 15px 0px 1px 0px;
  min-height: 120px;
  min-width: 1220px;
}

.zoom-download {
  display:inline-block;
  margin-bottom: 35px;
}

.download-section .pdf-csv-list ul {
  display: none;
}

.download-section ul li ul {
  position: absolute;
  left: 0;
  right: 0;
}

.download-section ul {
  font-weight: 900;
  list-style: none;
}

.download-section ul li {
  position: relative;
  list-style: none;
  cursor: pointer;
}

.download-section .pdf-csv-list:hover ul  {
  display: inline;
  top: 28px;
}

.metrics-select label {
  font-weight : normal;
  padding-right: 5px;
  margin-bottom: 0.5rem;
}
.metrics-select, .download-section {
  display: inline-block;
}


.dashboard-title-header {
  background-color: #cacaca;
  border-right: 1px solid #FFFFFF;
  color: #FFFFFF;
  border-bottom: 1px solid #FFFFFF;
  font-size: 16px;
  font-weight: normal;
  padding: 3px;
}

.dashboard-title {
  background-color: #0087a1;
  color: #FFFFFF;
  height: 36px;
  border: 1px solid #FFFFFF;
  font-size: 16px;
  font-weight: normal;
  /* margin-bottom: 2px; */
}

.dashboard-content-value {
  border-bottom: 1px solid #FFFFFF;
  font-size: 16px;
  font-weight: normal;
  padding: 5px;
}

.flexBox {
  display:flex;
  width:100%;
}
.fullwidth {
 width :100% ;
}

.middle-column {
  background-color: #27a6bf;
}

.middle-header{
  background-color: #a6a6a6;
  /* padding: 5px 4px; */
}
.zoomDashboard th a span{
  text-align: center;
  color: #FFFFFF;
 background-color: #CACACA;
}
.dashboard-table-section .alignCenter {
 display:flex;
 align-items:center;
 justify-content:center
}


.distinct-table-row:nth-child(odd) {
  background-color: #ffffff;
}
.distinct-table-row:nth-child(even) {
  background-color: #ededed;
}

.active-time-measure {
  color: #000000!important;
  cursor: default;
  font-weight: bold;
  text-decoration: underline!important;
}
.capitalize {
  text-transform: capitalize
}

.StoreRankingSubHeading a.dashboardHeaders:hover {
  text-decoration: underline;
  color: #ffffff;
  cursor:pointer;
}

.StoreRankingSubHeading a img {
  height: 6px;
}

.store-link {
  color: #5672aa;
  font-weight: bold;
  cursor: pointer;
}

.disable-click {
  pointer-events: none
}

.dashboard-header {
  font-size: large;
  font-weight: bold;
  vertical-align: text-top;
  text-align: center;
}

.ranking-text-header {
    font-size: small;
    font-weight: normal;
    text-align: right;
    vertical-align: text-bottom;
}

.pullins-text {
  color: #B3B3B3;
  font-size: 11px;
}

.circle {
  width: 14px;
  height: 14px;
  -moz-border-radius: 7px;
  -webkit-border-radius: 7px;
  border-radius: 7px;
}

.dashboard-goals {
  color: #6f6f6f;
  margin-left: 5px;
    vertical-align: text-bottom;
    font-size: 12px;
}

.dashboard-goals-list {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  width: 100%;
}

.dashboard-goal {
  padding-left: 20px;
}

.dashboard-goal:first-child {
  padding-left: 0px;
}

.goal-colors-section {
  margin-top: 0px;
  border-top: 1px solid #CACACA;
  padding-top: 15px;
}
.goal-colors-section .col-lg-4 {
  padding: 0px;
}

.goal-colors-section .col-lg-4 div {
  display: flex;
}

.zoomDashboard tr th,
.zoomDashboard tr td{
  padding:5px;
  line-height: 15px;
}

.zoomDashboard td.sort_col{
  font-weight: bold;
}
.zoomDashboard td a{
  color: #5672aa;
}

.zoomDashboard .strong {
  font-weight: 700;
}

.zoomDashboard .strong  div.ranktooltip{
  font-size: 16px !important;
  font-family: Helvetica;
  padding: 10px !important;
  width: auto !important;
  font-weight: lighter
}

.sortArrows{
  padding-left: 5px
}
div.relativecontainer {
  position: relative;
}
div.forms.dash_cont .customizedash {
  position: absolute !important;
  top: -27px !important;
  left: 174px !important;
}
.whiteLine_Pullin{
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}
.customHeader{
  margin-bottom: 8px !important;
}
.blueText_Space{
  padding: 3px 3px !important;
}

.dashboardMessage{
  padding-top:15px;
  color: gray;
  font-size: 12x
}
@media (min-width: 1281px) {
  .dashboard_goals_footer{
    margin-left:335px;
  }
}

@media (min-width: 1025px) and (max-width: 1280px) {
  .dashboard_goals_footer{
    margin-left:336px;
  }

}


@media (min-width: 768px) and (max-width: 1024px) {
  .dashboard_goals_footer{
    margin-left:150px;
  }

}



@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .dashboard_goals_footer{
    margin-left:80px;
  }


}


@media (min-width: 481px) and (max-width: 767px) {
  .dashboard_goals_footer{
    margin-left:100px  !important;
  }
}


@media (min-width: 320px) and (max-width: 480px) {
  .dashboard_goals_footer{
    margin-left:0px;
  }
  .pullinXS{
    margin-top: 10px !important;
  }
}


@media (min-width: 280px) and (max-width: 320px) {
  .dashboard_goals_footer{
    margin-left:0px;
  }
  .pullinXS{
    margin-top: 10px !important;
  }
}

.dashboard_Table {
  display: flex
}

.dashboard_Tables {
  width: 100%;
  min-width: 300px
}

.include_StoreName {
  width: 120%;
  min-width: 350px
}

.no_StoreName {
  width: 90%;
  min-width: 300px
}

.dashboard-settings-container .sortableboard #drags-container {
  height: 200px;
}

@media screen and (max-width: 1075px) and (min-width: 803px) {
  .dashboard-settings-container .sortableboard #drags-container {
      height:400px
  }
}


@media screen and (max-width: 802px) and (min-width: 300px) {
  .dashboard-settings-container .sortableboard #drags-container {
      height:700px
  }

}