.hme-view-store-settings-task-downloaded-devices {
    .hme-view-store-settings-task-downloaded-devices-state-cell {
        .hme-grid-filter-cell-popup {
            left: unset;
            right: 0;
        }
    }

    .hme-view-store-settings-task-downloaded-devices-timezone-cell {
        .hme-grid-filter-cell-popup {
            left: unset;
            right: 0;
        }
    }
}
