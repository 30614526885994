@import '../../../../assets/styles/Variables';

.hme-system-status-info {
    display: flex;
    flex-direction: column;
    gap: 30px;
    min-width: 350px;
    max-width: 350px;
    padding-right: 30px;

    .hme-title {
        margin-bottom: 0;

        font-size: 18px;
        font-weight: 600;
        line-height: 1.33;
        letter-spacing: 0.02px;
        color: $light-grey;
        text-transform: uppercase;
    }

    &__fields-wrapper {}

    &__field {
        padding-bottom: 10px;

        & ~ & {
            padding-top: 10px;
            border-top: 1px solid #e8e9ed;
        }

        .hme-info-field-content {
            margin-top: 8px;
            overflow-wrap: break-word;
        }
    }

    .hme-label {
        font-size: 14px;
    }
}

@import './Tablet.scss';
@import './Mobile.scss';

