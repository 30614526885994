@import 'src/mixins/breakpoints.scss';

@include mobile-only {
    .applyDeviceSettings {
        &.hme-page-content {
            overflow-y: visible !important;
        }

        .tasks-table-public {
            overflow: visible;
            min-width: 0;
        }

        .apply-device-settings-status-page {
            &.permission-type-public {
                min-width: 0;
                padding: 21px 15px;
            }

            .hme-tile-list__wrapper {
                padding: 0;
            }

            .apply-device-settings-taskbar {
                flex-direction: column;
                margin-bottom: 17px;

                .hme-title {
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 1.3;
                    letter-spacing: -0.28px;
                    margin: 0 0 15px;
                }

                .apply-device-settings-taskbar-link {
                    flex: 1;
                    order: 1;
                    justify-content: flex-start;
                    margin: 0 0 15px;

                    a,
                    .hme-button-component {
                        width: 100%;
                    }
                }

                .hme-header-date-filter,
                .apply-device-settings-taskbar-filters-count,
                .apply-device-settings-taskbar-search-group {
                    order: 2;
                    border: 0;
                }

                .apply-device-settings-taskbar-search-group {
                    padding: 0;
                    height: auto;
                    flex-direction: column;
                    margin: 0 0 15px;

                    .hme-label {
                        height: auto;
                        padding: 0;
                        margin: 0 0 8px;
                    }
                }

                .apply-device-settings-taskbar-separated-content {
                    margin: 0;
                }

                .hme-header-date-filter {
                    margin: 0;
                    height: auto;
                    flex-direction: column;
                    padding: 0;
                    border: 0;

                    &-content {
                        margin: 0;
                    }

                    &-label {
                        margin: 0 0 8px;
                    }

                    .hme-label {
                        height: auto;
                        padding: 0;
                    }   
                }

                .apply-device-settings-taskbar-filters-count {
                    display: none;
                }

                .hme-input-search-variant {
                    width: 100%;
                    margin-left: 0;
                }
            }

            .hme-grid {
                display: none;
            }

            .hme-tile-list {
                .hme-title {
                    margin: 0 0 15px;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 1.5;
                    letter-spacing: 0.02px;
                    color: #606672;
                    text-transform: uppercase;
                }

                .hme-tile-list-item {
                    &__rows__column {
                        .hme-tile-list__row {
                            .hme-tile-list__row__value {
                                word-break: break-all;
                                white-space: normal;
                            }
                        }
                    }

                    &__toggle-link {
                        margin: 0;
                    }

                    &__rows__wrapper {
                        margin: -15px 0;
                    }

                    &__rows__column {
                        border-top: solid 1px #e5e5e5;
                        padding: 15px 0;

                        &:first-child {
                            border-top: 0;
                            padding-top: 0;
                        }
                    }
                }

                &__row__subcolumns {
                    margin: 0 0 -15px;
                }

                &__row__subcolumn {
                    border-top: dashed 1px #e5e5e5;
                    padding: 15px 0;

                    &:first-of-type {
                        border-top: 0;
                        padding-top: 0;
                    }
                }
            }
        }
    }

    .hme-tile-list__sort-filter-modal {
        .hme-toggle {
            .toggle-button-group {
                .hme-button-component {
                    padding-left: 5px;
                    padding-right: 5px;
                }
            }
        }
    }
}
