@import 'src/mixins/breakpoints.scss';

@include before-desktop-only {
    .hme-view-role__permissions-section {
        padding-left: 0;
        border-left: none;
        padding-bottom: 0;
    }
}

@include mobile-only {
    .hme-view-role__permissions-section {
        .hme-accordion {
            width: 100%;
        }
    }
}
