.Embed-container-admin {
  height: 0;
  position: relative;
  overflow: hidden;
  display: block;
}

.Embed-container-admin iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-width: 2px;
    border-style: inset;
    border-color: initial;
    border-image: initial;
}

.adminReportsPage {
  width: 110%;
  margin: 0 -5%;
}

.Embed-container-ghost-cars {
  padding-bottom: 107%;
}

.Embed-container-outliers {
  padding-bottom: 60%;
}

.Embed-container-sos {
  padding-bottom: 110%;
}