@import 'src/mixins/breakpoints.scss';

@include mobile-only {
  .hme-users--list {
    .hme-users__header__wrapper {
      .hme-users__header {
        margin: 0;
      }

      .hme-button-component {
        margin-top: 20px;
      }

      margin-bottom: 20px;
    }
  }
}

@include tablet-only {
  .hme-users--list {
    .hme-users__header__wrapper {
      margin-bottom: 10px;
    }
  }
}

@include before-desktop-only {
  .hme-users--list {
    height: 100%;
    overflow: auto;
    padding: 20px 15px;

    .hme-users__header__wrapper {
      .hme-users__header {
        .hme-users__header__filters-count {
          display: none;
        }
      }
    }

    .hme-tile-list__wrapper {
      padding: 0;

      .hme-tile-list-item__actions-link {
        .hme-users-list--action--edit--user {
          display: flex;

          .hme--users--edit-icon {
            margin-right: 5px;
          }
        }

        .hme-users--distributor-account--remove--user {
          border-left: solid 1px #d1cdcd;
          padding-left: 15px;
        }

        .hme-button-component {
          margin: 0;
        }
      }
    }

    .hme-grid {
      display: none;
    }
  }
}

@include desktop-only {
  .hme-users--list {
    .hme-users__header__wrapper {
      margin-bottom: 40px;
    }
    .hme-grid{
      .hme--users--edit-icon {
        display: none
      }
    }
  }
};
