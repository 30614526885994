.hme-partners-status {
    padding: 32px 15px 25px 50px;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;

    &__header {
        flex: 0;
        margin-bottom: 40px;
    }

    .hme-grid {
        flex: 1;
        overflow: auto;

        .hme-grid-header {
            position: sticky;
            top: 0;
        }
    }

    .hme-paginate {
        flex: 0;
        margin-top: 30px;
    }
}