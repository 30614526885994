.hme-textarea {
    display: flex;
    flex-direction: column;

    .hme-label {
        margin-bottom: 8px;
        flex: 0;
    }

    .hme-textarea__input {
        width: 100%;
        flex: 1;
        border: solid 1px #a7adba;
        border-radius: 24px;
        padding: 22px 25px 5px 25px;
        resize: none;
        font-family: Assistant;
        font-size: 16px;
        line-height: 1.31;
        letter-spacing: 1.44px;
        color: #000819;

        &:focus,
        &:focus-visible {
            border: solid 2px #0074ef;
            padding: 21px 24px 4px 24px;
            outline: none;
        }
    }

    &.hme-input-label-inside-variant {
        position: relative;

        .hme-label {
            position: absolute;
            top: 5px;
            font-size: 12px;
            line-height: 1.33;
            letter-spacing: 1.08px;
            text-align: left;
            color: #606672;
            z-index: 2;
            left: 25px;
        }

    }

    &.hme-textarea--disabled {
        .hme-textarea__input {
            background-image: linear-gradient(to bottom, #dde1e9, #c7ccd6);
        }
    }

    &.hme-textarea--required {
        .hme-label {
            &:after {
                content: " *";
                color: #0074ef;
            }
        }
    }
}

@import './Mobile.scss';
