@import 'src/assets/styles/Variables';

.hme-address {
  .hme-label {
    font-size: 14px;
    line-height: 1.29;
    color: $main-gray;
    margin-bottom: 8px;
  }

  .hme-input-container {
    .hme-input-wrapper {
      font-size: 16px;
      line-height: 1;
      color: $main-black;
    }
  }

  .address-text {
    font-size: 16px;
    line-height: 1;
    color: $main-black;
  }
}
