.hme-view-user {
    padding: 27px 7px 0 50px;
    display: flex;
    flex-direction: column;
    height: 100%;

    .hme-view-user__header {
        width: 100%;
        margin-bottom: 30px;
        flex: 0;
    }

    .hme-title-block {
        font-size: 18px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.28;
        letter-spacing: 0.18px;
        padding-bottom: 15px;
    }

    .hme-view-user__sections {
        display: flex;
        flex: 1;

        &__wrapper {
            display: flex;
            flex-direction: row;
            flex: 1;
            height: 100%;
        }
    }

    .hme-view-user__general {
        flex: 0 1 410px;
        min-width: 410px;
    }

    .hme-view-user__access {
        flex: 1 1;
        border-left: solid 1px #e5e5e5;
        padding-bottom: 71px;
        height: max-content;
        min-width: 345px;
    }

    .hme-view-user__activity-log {
        flex: 2 1;
        border-left: solid 1px #e5e5e5;
        padding: 0 40px 71px;
        height: max-content;
        min-width: 345px;
    }

    .hme-view-user__footer {
        margin-left: -50px;
        margin-right: -7px;
        flex: 0;
        position: sticky;
        bottom: 0;
    }

    &--admin {
        overflow: auto;

        .hme-view-user__general {
            flex: 0 1 385px;
            min-width: 385px;
        }

        .hme-view-user__access {
            flex: 1;
            padding: 0 40px 71px;
            height: 100%;
        }

        .hme-view-user__activity-log {
            height: 100%;

            .hme-grid {
                .hme-grid-header {
                    top: -27px;
                }
            }

            .hme-tile-list__wrapper {
                display: none;
            }
        }
    }
}

@import './Mobile.scss';
