.hme-device-status {
    font-family: Assistant;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;

    &.hme-device-status--offline,
    &.hme-device-status--preconfigured,
    &.hme-device-status--unavailable {
        color: #b9b9b9;
    }

    &.hme-device-status--preconfigured,
    &.hme-device-status--unavailable {
        color: #b9b9b9;
        padding-right: 24px;
    }

    .hme-status-icon {
        margin-left: 8px;
    }
}
