.dxs-feed-header {
  padding: 0;
  display: flex;
  vertical-align: middle;
  justify-content: space-between;
  margin: 44px 0 23px;

  .dxs-feed-header-title-wrapper {
    flex: 25;
  }

  .dxs-feed-header-actions-wrapper {
    display: flex;
    justify-content: space-between;
    flex: 75;
    padding-left: 40px;
  }

  .hme-submit-button-component {
    width: 104px;
    height: 48px;
    border: 1px;
    margin: 0;
    border-radius: 0 24px 24px 0;
  }

  .hme-title {
    display: inline-block;
    flex: none;
    line-height: 1.5;
    margin-right: 20px;
  }

  .hme-input-search-variant {
    width: 350px;
    margin-right: 20px;
  }
}
