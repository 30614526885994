@import "src/assets/styles/Variables.scss";

.hme-view-edit-contest--results__header__results {
    display: flex;
    align-items: center;

    .hme-view-edit-contest--results__header__download-icon {
        color: $main-color;
        margin-right: 8px;
    }
}