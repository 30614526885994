.hme-calendar-selector-month-title {
    font-family: Assistant;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    margin-bottom: 10px;
}

.hme-calendar-selector-month-title__week-cell {
    font-family: Assistant;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.42px;
    text-align: center;
    color: #606672;
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-transform: uppercase;
}