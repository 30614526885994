@import 'src/mixins/breakpoints.scss';

@include mobile-only {
  .hme-device-settings-snapshots__name-tooltip__info {
    &__plus {
      margin-right: auto;
      margin-left: auto;
    }
  }
}
