.hme-admin-store-details__header {
  font-family: Assistant, sans-serif;
  font-size: 31px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.32;
  letter-spacing: -0.43px;
  text-align: left;
  color: #000819;
  padding-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .hme-title {
    margin-right: 40px;
  }

  .hme-admin-store-details__header__remove-store {
    padding-left: 40px;
    border-left: solid 1px #e5e5e5;
  }
}
