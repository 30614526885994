@import 'src/assets/styles/Variables';

.hme-api-management-renew-token-modal {
    &__content {
        display: grid;
        gap: 30px;
        max-width: 505px;
        min-width: 505px;
        padding: 40px;

        font-family: $font-family;
    }

    &__header {
        display: grid;
        gap: 20px;
        justify-items: center;
    }

    &__alert-icon {
        width: 65px;
        height: 65px;
    }

    &__title {
        margin-bottom: 0;

        font-size: 24px;
        color: $main-black;
    }

    &__description {
        font-size: 18px;
        color: $main-black;
    }

    &__form {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    &__options {
        padding: 0 35px;
    }

    &__option {
        display: flex;
        flex-direction: column;
    }

    &__btns-container {
        display: flex;
        justify-content: center;
        gap: 20px;
    }

    .hme-datepicker {
        margin-top: 10px;
    }

    .hme-input-date-picker-variant .hme-calendar {
        top: -785%;
        left: 3%;
    }
}
