.embed-container {
   margin-top: 25px;
   height: 765px;
   min-width: 90em;
}

.embed-container iframe {
   border: 0;
   height: 100%;
   width: 100%;
}

 /* Unregister system POPUP */
 .popup{
    border: 1px solid #aed0ea;
    background: #F2F5F7;
    width:356px;
    text-align: left;
    padding-top: 10px;
    font-size:13px;
    border-radius: 10px;
 }
 .warningMsg{
     color:gray;
 }
 .react-confirm-alert-overlay{
    background: rgba(0,0,0,0.5) !important;
 }
 .popup_Title{
    float: left;
    white-space: nowrap;
    width: 345px;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    padding: .2em 0.3em;
    position: relative;
    border: 1px solid #aed0ea;
    font-size: 14px;
    font-weight: bold;
    border-radius: 10px;
    background: #FAFBFC;
    margin-top: -7px;
    margin-left: 2px;
    padding-left: 10px;
    font-size:13px;
 }
 .popup_message{
    text-align: left;
    padding-left: 3px;
    font-size:13px;
    padding-left: 10px;
 }
 .popup_warning{
    padding-right: 60px;
    padding-left: 10px;
 }
 .warning{
     color: red;
     font-weight: bold;
 }
 .popup_buttons{
    margin-top: 1.5em;
    padding-left: 10px;
    border-top: 1px solid #DDDDDD;
    padding-top: 10px;
    font-size: 13px;
 }
 .buttonSet{
    float: right;
    display: flex !important;
 }
 .popup_buttons{
    margin: .5em .4em .5em 0;
    cursor: pointer;
    color: #2779aa;
    text-decoration: none;
    text-align: center;
    font-weight: bold;
 }
 .buttons{
    background: #d7ebf9;
    text-align: center;
    vertical-align: top;
    border: 1px solid #aed0ea;
    border-radius: 6px;
    padding-top: 3px;
    padding-left: 10px;
    padding-right: 10px;
 }
 .buttons:hover{
    background: #FAFBFC;
 }
 .successMsg{
     color:green
 }
 .no-click {
    pointer-events: none;
    cursor: pointer;
  }
.no-click:hover {
    text-decoration: none;
    cursor: pointer;
}
.popup-button{
    margin-left: 175px;
}
.remove-system-device:hover{
    cursor: pointer;
}