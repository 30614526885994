.stores-selector {
  padding: 0;
}
.stores-selector__label {
  text-transform: capitalize;
}
.stores-selector__wrap__controllers {
  padding-bottom: 0.5em;
  border-bottom: 1px solid #bdbdbd;
}
.stores-selector__wrap__stores .rc-tree {
  max-height: initial;
  border: none;
}
.stores-selector .hme-stores-selector__fixed-height-block {
  height: 80px;
  margin-bottom: 15px;
}

.store-selector-button-height {
  top: 25rem;
  text-align: center;
}
