.device-settings-nexeo {
  margin-left: -0.6em; }
  .device-settings-nexeo tr.device-row-data td.device-serial-number {
    overflow-wrap: break-word;
    max-width: 130px; }
  .device-settings-nexeo tr.device-row-data td.device-settings-version {
    overflow-wrap: break-word;
    max-width: 65px; }
  .device-settings-nexeo tr.device-row-data td.device-status {
    white-space: nowrap; }
