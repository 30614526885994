@import 'src/mixins/breakpoints';

@include mobile-only {
    .hme-contests {
        &__content {
            gap: 20px;
        }

        &__header {
            flex-wrap: unset;
            flex-direction: column;
            align-items: unset;
        }

        &__header-title {
            order: unset;

            font-size: 20px;
        }

        &__add-contest {
            display: block;
            order: unset;
            margin-left: unset;
        }
    }
}
