@import 'src/mixins/breakpoints.scss';

@include before-desktop-only {
    .hme-change-password-form {

        &__buttons {
            width: auto;
        }

        &__submit-btn {
            justify-content: center;
        }

        // reset password widget
        .hme-reset-password {
            &__info {
                margin-right: 0;
                margin-bottom: 0;
            }
        }
    }
}
