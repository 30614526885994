.hme-notifications-center {
    padding: 30px 20px 20px 50px;
    display: flex;
    gap: 30px;
    flex-direction: column;
    height: 100%;
    overflow: auto;

    &__items {
        flex: 1;
    }
}

@import './Mobile.scss';