@import 'mixins/breakpoints';

@include mobile-only {
    .hme-notifications-center__notification--leaderboard-invite {
        --gap: 20px;

        &__options {
            display: flex;
            gap: var(--gap);
            justify-items: stretch;
            flex-direction: column;
        }

        &__option {
            &__button {
                width: 100%;
            }

            &__message {
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.14px;
            }
        }
    }
}