.hme-flex-breaker {
    flex-basis: 100%;

    &--horizontal {
        height: 0;
    }

    &--vertical {
        width: 0;

    }
}