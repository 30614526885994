@import 'src/assets/styles/Variables.scss';

.hme-notifications-center__notification--leaderboard-invite {
    --gap: 40px;

    &__options {
        display: flex;
        gap: var(--gap);
        justify-items: stretch;
        height: max-content;
    }

    &__option {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: var(--gap);

        &__message {
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.16px;
            color: $main-black;
        }

        &__button {
            margin-top: auto;
            margin-left: auto;
            width: max-content;
        }
    }

    &__separator--options {
        height: auto;
    }

    .hme-info-box {
        margin-bottom: var(--gap);

        .hme-info-box__content {
            margin: 0;
        }

        .hme-info-box__icon--svg-info {
            min-width: 22px;
        }
    }
}

@import './Mobile.scss';
