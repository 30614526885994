@import 'src/assets/styles/Variables';

.hme-notifications-icon {
    &__loader {
        width: 38px;
        height: 38px;
    }

    .dropdown-icon-badge {
        width: 46px;
    }

    .icon.icon-alert {
        color: $notiication-gray-bell;
        margin-left: 8.5px;
        margin-top: 2.5px;
    }
}