.hme-gamification-marketing {
    display: flex;
    flex-flow: row wrap;
    padding: 30px 50px;

    &__title {
        flex: 0 1 100%;
        margin-bottom: 30px;
    }

    &__info {
        max-width: 735px;
        flex: 1;
        padding-right: 80px;
        border-right: 1px solid #e5e5e5;
    }

    &__contact {
        flex: 1;
        margin-left: 80px;
        min-width: 296px;
    }

    &__paragraph {
        margin-bottom: 15px;
    }

    .hme-title-section {
        margin-bottom: 30px;
    }
}

@import './Responsive.scss';
