.admin-create-apply-device-settings-task-target {
    .hme-admin-target-device-list {
        &__target-count {
            font-size: 30px;
            font-weight: bold;
            letter-spacing: 0.03px;
            color: #606672;
            margin: 0 10px 0 0;
            line-height: 24px;
        }

        &__target-count-label {
            margin-left: 8px;
            font-family: Assistant;
            font-size: 24px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: 0.02px;
            text-align: left;
            color: #000819;
        }

        &__filters-panel {
            display: flex;
            align-items: center;

            .hme-button-component {
                margin: 0 20px 0 0;
            }
        }

        &__filters-count {
            border-left: 1px solid #e5e5e5;
            height: 100%;
            padding: 0 20px;

            .hme-grid-filters-count-count-number {
                font-size: 24px;
            }

            .hme-grid-filters-count-label {
                font-size: 24px;
                line-height: 1;
                padding-top: 0;
            }
        }

        &__hide-disabled {
            border-left: 1px solid #e5e5e5;
            height: 100%;
            padding: 15px 20px 0;
            display: flex;

            .hme-checkbox-component {
                display: flex;

                .hme-checkbox-label {
                    font-size: 16px;
                    line-height: 16px;
                    color: #000819;
                    flex-shrink: 5;
                }
            }

            .hme-tip {
                display: flex;
            }
        }

        .hme-grid {
            .hme-grid-header {
                top: 48px;
            }
        }

        &__filters-row {
            display: flex;
            justify-content: space-between;
            position: -webkit-sticky;
            position: sticky;
            top: 0;
            background-color: #ffffff;
            z-index: 1;
            margin-bottom: 12px;

            .hme-input-container {
                width: 283px;
            }
        }

        .apply-device-settings__settings__version__header {
            .hme-grid-filter-cell-popup {
                left: unset;
                right: 0;
            }
        }
    }
}

.hme-admin-target-device-list__hide-disabled__tip {
    width: 410px;
}