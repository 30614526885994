.hme-components {
    .hme-auto-complete-suggestions-loader {
        text-align: center;
        padding-top: 10px;
        padding-bottom: 10px;

        .hme-loader-icon {
            display: inline-block;
            width: 21px;
            height: 21px;
            border-width: 4px;
        }
    
        .hme-label {
            display: inline-block;
            margin: -14px 0 0 10px;
            font-family: Assistant;
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 21px;
            letter-spacing: 1.28px;
            color: #000819;
            text-transform: none;
            vertical-align: middle;
        }
    }
}
