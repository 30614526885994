@import 'src/assets/styles/Variables';

.hme-partner-token-management {
    padding: 30px 50px;

    &__wrapper,
    &__content {
        height: 100%;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 45px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__create-token-btn {
        text-transform: capitalize;
    }

    &__list {
        overflow: auto;
        .hme-grid-header .hme-grid-header-row .hme-grid-cell.hme-grid--header-text-transform--none {
            text-transform: none;
        }
    }

    &__row {
        &--expired {
            background-color: $row-red !important;
        }

        &--within-month-expired {
            background-color: $row-orange !important;
        }
    }

    &__expiration-cell {
        position: relative;

        display: flex;
        gap: 8px;
        align-items: center;
        justify-content: center;

        &--expired,
        &--within-month-expired {
            font-weight: bold;
        }

        &--expired {
            color: #cc0738;
        }
    }

    &__cell-icon {
        width: 24px;
        height: 24px;

        svg {
            position: absolute;
            right: 8px;
            width: 100%;
            height: 100%;
        }
    }

    &__actions {
        align-items: center;
    }

    &__paginate {
        margin: 20px 0;
    }
}
