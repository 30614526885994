.hme-admin-store-details__account-numbers {
  margin-bottom: 26px;

  .hme-input-container {
    margin: 8px 0;
  }

  .hme-label-error {
    font-family: Assistant, sans-serif;
    font-size: 13px;
    font-weight: 600;
    line-height: 1.31;
    letter-spacing: 1.04px;
    color: #cc0738;
    display: flex;

    svg {
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }
  }
}
