.footer__container {
    background-color: var(--white);
    border-top: 1px solid var(--gray-37);
    color: var(--gray-62);
    display: block;
    font-family: Assistant, sans-serif;
    font-size: 14px;
    line-height: 18px;
    padding: 14px 16px 32px;
    text-align: center;
    clear: both;
}
.footer__copyright {
    margin-bottom: 20px;
}
.footer__links {
    list-style: none;
    margin: 0;
    padding: 0;
}
.footer__links-item {
    border-right: 1px solid var(--gray-62);
    display: inline-block;
    margin: 0 0 4px 0;
    padding: 0 12px;
}
.footer__links-item:last-child {
    border: 0;
    padding-right: 0; 
}
.footer__links-item a {
    color: var(--blue-87);
}

@media (min-width: 780px) {
    .footer__container {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 16px 32px 32px;
    }
    .footer__copyright {
        margin-bottom: 0;
    }
}