.daypart-selection-view {
  &__section {

      &:first-child {
          border-left: none;
          padding-left: 0;
      }

      .hme-title {
          font-size: 24px;
          font-weight: 600;
          letter-spacing: 0.24px;
          margin-bottom: 24px;
      }

      &__subtitle {
          margin: 0 0 8px;

          .hme-checkbox-label {
              text-transform: none;
              font-size: 16px;
              line-height: 1.5;
              letter-spacing: 1.28px;
          }
      }

      &__version-list-wrapper {
          border: solid 1px #a7adba;
          border-radius: 24px;
          width: 100%;
          height: 225px;
          padding: 10px 5px 10px 25px;
      }

      &__version {
          padding: 14px 0 13px;
      }

      &__version-list {
          height: 100%;
          overflow: auto;

          .hme-checkbox-label {
              font-family: Assistant;
              font-size: 16px;
              line-height: 1.5;
              letter-spacing: 1.28px;
              color: #000819;
          }
      }

      &__selection {
        height: 18px;
        width: max-content;
        margin-left: auto;
        margin-right: auto;
        margin-top: 15px;

        &__deselect-all {
          margin-left: 42px;
          position: relative;
  
          &::before {
              content: " ";
              border-left: solid 1px #a7adba;
              height: 18px;
              position: absolute;
              left: -21px;
          }
        }
      }
  }
}
