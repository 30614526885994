.hme-multi-select-input-container {
    display: inline-block;
    width: 30vw;
    &.expanded {
        & > .hme-multi-select-input-wrapper {
            & > .hme-multi-select-input-bages {
                max-height: unset;
            }
        }
    }

    .hme-label {
        margin-bottom: 4px;
    }

    .hme-multi-select-input-wrapper {
        position: relative;
        overflow-y: hidden;
        min-height: 48px;
        width: 100%;
        border-radius: 24px;
        border: solid 2px #a7adba;
        background-color: #ffffff;
        display: flex;
        flex-flow: row wrap;
        font-family: Assistant;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 1.44px;
        line-height: 1.31;
        text-align: left;
        padding: 0 25px 0 40px;
        color: #000819;
        & > .hme-multi-select-input-bages {
            max-height: 135px;
            & > .hme-multi-select-input-component {
                outline: none;
                border: none;
                width: 100%;
            }
            &.show-more {
                margin: 0 0 20px 0;
            }
        }
        &:focus-within {
            outline: none;
            border: solid 2px #0074ef;
        }
        & > .hme-multi-select-input-showMore {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            bottom: 2px;
            right: 15px;
            font-family: Assistant;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.29;
            letter-spacing: 0.04px;
            text-align: right;
            color: #0074ef;
            background-image: linear-gradient(to top, #fff, rgba(255,255,255,0.5));
            border-radius: 15px;
            &.expanded {
                & > svg {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .hme-multi-select-input-component-before {
        position: absolute;
        left: 16px;
        top: 19px;
        content: '*';
        font-family: Assistant;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 1.44px;
        text-align: left;
        color: #186df4;
    }
}
