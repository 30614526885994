.dxs-accounts-section {
    width: 50%;
    margin: 10px 0;
    .hme-auto-complete-suggestions {
        width: 100%;
        max-height: 50vh;
        overflow: scroll;
    }
}

.dxs--feed-accounts-selected {
    font-size: 22px;
    text-transform: lowercase;
    margin-top: 20px;
}