.hme-layout .hme-page .public-zoom-nexeo-system-status-page {
  &.hmeBody {
    width: 100% !important; // Used in order to override styles of .hmeBody
    overflow-y: auto;
  }

  .hme-system-status-devices-grid--nexeo {
    gap: 20px;
  }
}

body.modal-open {
  div.hme-tooltip {
    &.device-upgrade-tooltip {
      z-index: 100;
    }
  }
}

@import './Tablet.scss';
