@import "src/assets/styles/Variables";

.hme-store-access {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__stores {
        min-height: 280px;
        padding: 14px 25px;

        font-family: $font-family;
        font-size: 16px;
        color: $main-black;
        border-radius: 24px;
        background-color: #e8e9ed;
    }
}

@import './Tablet.scss';
