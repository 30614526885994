.company-info-footer {
    z-index: 10;
    text-align: right;
    padding: 20px 50px 20px 20px;
    box-shadow: 0 -10px 20px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    width: 100%;

    .hme-button-component {
        margin-left: 20px;
    }
}
