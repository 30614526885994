@import 'src/mixins/breakpoints.scss';

@include before-desktop-only {
    .hme-roles__tile-list {
        .hme-tile-list-item__wrapper .hme-tile-list-item__rows__wrapper {
            .hme-tile-list-item__rows__column .hme-tile-list__row .hme-tile-list__row__value {
                text-overflow: unset;
                white-space: normal;
            }
        }
    }
}
