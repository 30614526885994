.hme-distributors-accounts__list__wrapper {
  width: 100%;
  min-width: 1279px;
  height: 100%;
  overflow: auto;

  .hme-grid-filter-cell-popup {
    height: 205px;
  }
}
