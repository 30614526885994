.create-snapshot {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .create-snapshot-sections {
        display: flex;
        flex-direction: column;
        margin: 0 0 20px;

        .hme-section {
            &.hme-section-without-title {
                .create-snapshot-device-type,
                .create-snapshot-device-filter,
                .create-snapshot-setting-info-form {
                    .hme-title {
                        display: block;
                    }
                }
            }
        }
    }

    .create-snapshot-subhead {
        padding-left: 20px;

        p {
            text-transform: uppercase;
            font-family: Assistant;
            font-size: 18px;
            font-weight: 600;
            line-height: 1.28;
            letter-spacing: 0.18px;
            color: #000819;
        }
    }

    .hme-input-container {
        display: block;
        margin-bottom: 10px;
    }

    .hme-label-error {
        display: block;
        max-width: 100%;
        margin-left: 15px;
    }

    .hme-title {
        font-family: Assistant;
        font-size: 18px;
        font-weight: 600;
        line-height: 1.28;
        letter-spacing: 0.18px;
        color: #000819;
        margin: 0 0 10px;
    }

    .create-snapshot-subhead {
        text-transform: uppercase;
    }

    .hme-input-container {
        position: relative;

        .hme-label {
            position: absolute;
            top: 3px;
            left: 25px;
            z-index: 100;
            font-size: 12px;
            color: #606672;
            letter-spacing: 1.08px;
        }

        .hme-input-component {
            padding: 22px 25px 4px;
            color: #000819;
        }

        .wrapper {
            .react-autosuggest__input {
                padding: 26px 25px 5px;
                text-indent: 0;
                color: #000819;
            }
        }
    }

    .hme-onoff-slider-component {
        .hme-checkbox-slider-label-text {
            padding-left: 8px;
            text-transform: uppercase;
        }
    }

    .hme-info-box {
        margin-bottom: 20px;

        .icon-info {
            width: 30px;
            margin: 2px 0 0;
            font-size: 17px;
            line-height: 17px;
        }

        &--info {
            .hme-info-box__content {
                width: 100%;
                margin: 0;
            }
        }

        .hme-info-box-message {
            display: flex;
        }
    }
}

.hme-create-snapshot-modal-dialog {
    &.modal-dialog {
        .modal-footer {
            .hme-button-component {
                margin: 0 0 0 20px;   
            }            
        }
    }
}

@import './Mobile.scss';
