@import "src/assets/styles/Variables";

.hme-layout .hme-page .reports-landing-page {
  position: relative;

  overflow-y: auto;
}

.reports-overview {
  display: flex;
  padding-left: 50px;

  font-family: $font-family;

  h3 {
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.03px;
    min-height: 30px;
    padding-bottom: 25px;
  }
  
  &__recently-viewed {
    width: 20%; 
    border-right: 1px solid #ccc;
    padding: 16px;
  }
  
  &__kpi-recent-activity {
    width: 25%; 
    border-right: 1px solid #ccc;
    padding: 16px;
  }

  &__info {
    width: 55%;
    padding: 16px 16px 16px 35px;
  }

  &__recent-reports {
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px;      
  }

  &__recent-templates {
    padding-top: 40px;
  }

  &__recent-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    padding-bottom: 20px;
    font-size: 15px;
  }  

  &__report-tiles {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }

  &__tile {
    box-shadow: $box-shadow;
    margin: 5px;
    padding: 16px 20px;
  }

  &__tile-title {
    font-weight: 700;
    word-break: initial;
    white-space: nowrap;
    margin: 0 0 16px;
  }

  &__view-report {
    display: flex;
    justify-content: end;
  }

  &__template-header {
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  &__template-container {
    display: flex;
    flex-wrap: wrap;
    height: 160px;
    font-size: $font-size;

    &__template-item {
        height: 25px;
        margin: 3px 5px;
        padding-bottom: 2px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
  }

  &__template-left-section, &__template-right-section {
    min-width: 50%;
    flex: 1;
    padding: 10px 0;
    box-sizing: border-box;
  }

  &__template-right-section {
    border-left: 1px solid $header-separator-grey;
    padding-left: 5px;
  }
  
  &__not-available {
    color: $main-gray;
  }
}

::-webkit-scrollbar-thumb {
  background-color: #a7adba;
  border-radius: 4px;
}
::-webkit-scrollbar {
  width: 6px;
  height: 15px;
}

@import "./Mobile.scss"