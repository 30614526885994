@import "src/assets/styles/Variables.scss";
.modal-content {
    &__confirm-alert {
        background-color: $main-white;
        padding: 5px;
        border-radius: 5px;
    }
}

.modal-body .icon-info {
    width: 40px;
    display: inline-block;
    vertical-align: top;
    font-size: 30px;
}

.modal-body .text{
    width: 385px;
    display: inline-block;
    vertical-align: top;
}

.modal-footer {
    &__confirm-alert {
        display: flex;
        justify-content: end;
        
        &__buttons {
            width: 80px;
            display: flex;
            justify-content: space-between;
        }
    }
    
}

.title-with-icon h3{
    font-size: 30px;
}
.title-with-icon {
    height:70px;
    margin: 15px 0 15px 0;
    display: flex;
    
    & .icon-info {
        font-size: 21px;
        line-height: 40px;
        cursor: pointer;
    }

    & h3 {
        margin-right: 10px;
    }
}

.btn-panel .search_pos.systemsSearch {
    margin-top: 8px;
}
.search_pos .search .searchBox.no-options{
    width: 174px;
    padding: 5px;
}

.autocomplete-box {
    width: 240px;
    float: right;
    margin-top: 3px;
}

.search-bar-description {
    font-weight: initial;
    color: #757575;
    margin-left: 15px;
    margin-top: 10px;
    font-size: 12px;
}

.autocomplete-box input{
    height: 35px;
}
.autocomplete-box div[role="listbox"] {
    margin-top: -7px;
}