@import '../../../../../../mixins/breakpoints.scss';

@include mobile-only {
    .hme-manage-hierarchy__hierarchy__item--add-item {
        display: block;
        width: 100%;

        .hme-input-component {
            height: 40px;
            border-radius: 20px;
            width: 100%;
        }

        .hme-button-component  {
            font-size: 18px;
            margin-top: 8px;
        }

        &__save {
            margin-right: 15px;
        }
    }
}
