.hme-titled-row {
    display: flex;
    flex-direction: column;
    padding: 11px 0 10px 0;
    margin: 0;
    max-height: 60px;
    border-top: solid 1px #e8e9ed;
    & > .hme-titled-row-group {
        display: flex;
        justify-content: space-between;
    }
}