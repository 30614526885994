@import "../../../../assets/styles/Variables";
@import "../../../../assets/styles/Responsive";

.send-smacktalk-message-sections {
    & > .send-smacktalk-message-2-section {
        padding: 0 20px 30px 20px;
        overflow: unset;
        max-width: 33%;
        & > .hme-label {
            margin: 0 0 8px 0;
        }
        & > .hme-multi-select-input-search-variant {
            display: flex;
            width: 100%;
            & > .hme-multi-select-input-wrapper {
                & > .hme-multi-select-input-bages {
                    position: relative;
                    overflow-y: hidden;
                    min-height: 48px;
                    width: 100%;
                    background-color: $main-white;
                    display: flex;
                    flex-flow: row wrap;
                    & > .hme-badge-pill {
                        display: flex;
                        flex-direction: row;
                        & > .bage-pill-label {
                            padding: 0 10px 0 0;
                        }
                        & > .close-cross {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 0 0;
                            width: 18px;
                            height: 18px;
                            background-color: $main-white;
                            color: $hme-blue-color;
                            border-radius: 50%;
                            cursor: pointer;
                            flex-shrink: 0;
                            position: relative;
                            &::after {
                                content: "×";
                                font-size: 23px;
                                font-weight: bold;
                                position: absolute;
                                top: -5px;
                                left: 3px;
                            }
                        }
                    }
                }
                & > .hme-multi-select-input-component-before {
                    display: none;
                }
                & > .hme-multi-select-input-search-icon {
                    position: absolute;
                    left: 14px;
                    top: 14px;
                }
            }
        }
        & > .hme-filter-multi-popup {
            left: 0;
            top: 0;
            position: relative;
            max-height: 418px;
            overflow-y: scroll;
            width: 100%;
            margin: 3px 0;
            border: unset;
            min-width: unset;
            box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
            & > .hme-filter-multi-popup-body {
                & > .hme-filter-multi-popup-row {
                    & > .hme-checkbox-component {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        overflow: hidden;
                        & > svg {
                            flex-shrink: 0;
                        }
                        & > .hme-checkbox-label {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }
        }
    }
    
}
@include smaller($l-desktop) {
    .send-smacktalk-message-sections {
        & > .send-smacktalk-message-2-section {
            max-width: unset;
            & > .hme-multi-select-input-search-variant {
                & > .hme-multi-select-input-wrapper {
                    & > .hme-multi-select-input-bages.show-more {
                        margin: 0 0 25px 0;
                    }
                }
            }
            & > .hme-filter-multi-popup {
                & > .hme-filter-multi-popup-body {
                    & > .hme-filter-multi-popup-row {
                        padding: 10px 0 10px 15px;
                        &.hme-filter-multi-popup-item {
                            padding: 10px 0 10px 40px;
                        }
                        &.hme-filter-multi-popup-checked {
                            background-color: $hme-blue-color;
                            & > .hme-checkbox-component {
                                & > svg > g > path {
                                    &:first-child {
                                        fill: $main-white;
                                    }
                                    &:last-child {
                                        fill: $hme-blue-color;
                                    }
                                }
                                & > .hme-checkbox-label {
                                    color: $main-white;
                                }
                            }
                        }
                        & > .hme-filter-multi-popup-only-label {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

