@import "src/mixins/breakpoints.scss";

@include mobile-only {
  .hme-stores-status--partner {
    .hme-stores-status--partner__header {
      flex-direction: column;

      .hme-search-optionable {
        .hme-select {
          flex-basis: 100%;
          margin-right: 0;
        }
      }

      .hme-stores-status--partner__header__sort-btn {
        margin-top: 15px;
      }
    }

    .hme-stores-status--partner__tile-list {
      .hme-stores-mobile--partner__tile__main__info {
        flex-direction: column;

        .hme-stores-mobile--partner__tile__device_info_section {
          border: 0;
          padding: 0;
        }

        .hme-stores-mobile--partner__tile__store_info_section {
          width: 100%;
        }

        .hme-stores-mobile--partner__tile__device_info_section {
          border-left: 0;
          padding-left: 0;
        }
      }
    }
  }
}
