.list-input {
  border: 1px solid #bdbdbd;
  margin: 1em auto;
  padding: 0;
}
.list-input h5 {
  font-weight: bold;
  padding: 0.5em;
  border-bottom: 1px solid #bdbdbd;
  margin-bottom: 0;
}
.list-input__list ul {
  display: inline-block;
  position: relative;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.list-input__list ul li {
  display: inline-block;
  width: 100%;
  padding: 0.5em;
  cursor: pointer;
  white-space: nowrap;
  border-right: 2px solid #bdbdbd;
  border-color: transparent;
}
.list-input__list ul li.list-input__list__option--selected {
  border-color: #0000ee;
  color: #0000ee;
}
.list-input--scroll .list-input__list {
  overflow: auto;
  max-height: 9.71428em;
}
.list-input--zebra ul li:nth-child(odd) {
  background-color: #e0e0e0;
}
@media (max-width: 420px) {
  .list-input {
    max-width: 100%;
  }
}
