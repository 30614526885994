@import 'src/mixins/breakpoints.scss';

@include before-desktop-only {
    .hme-account-info {
        .hme-input-container .hme-label {
            margin-bottom: 0;
        }

        &__main-content {
            padding-top: 20px;
            padding-left: 15px;
        }

        &__page-title {
            padding-bottom: 20px;

            font-size: 20px;
        }

        &__sections {
            gap: 25px;
        }

        &__section {
            flex-basis: 50%;
            flex-direction: column;
            gap: 20px;

            &--2 {
                padding-left: 25px;
                border-left: 1px solid #e5e5e5;
            }
        }
    }
}
