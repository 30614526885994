$main-color: #0074ef;

.hme-joined-filters-popup {
    background-color: #ffffff;
    padding: 15px 4px 15px 15px;
    border-radius: 8px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
    border: solid 1px $main-color;
    min-width: calc(100% - 10px);
    max-height: calc(100vh - 15px) !important;
    width: unset !important;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;

    .hme-checkbox-component {
        margin: 0 auto 0 0;
        white-space: nowrap;
        .hme-checkbox-label {
            font-size: 16px;
            line-height: 1.31;
            letter-spacing: 1.28px;
            display: inline-block;
            white-space: nowrap;
        }

        .hme-checkbox-component-icon {
            margin-top: -3px;
        }
    }
    & > .hme-joined-filters-popup-body {
        flex: 1;
        overflow-y: scroll;
        &::-webkit-scrollbar-thumb {
            background-color: $main-color;
            border-radius: 4px;
        }
        &::-webkit-scrollbar {
           width: 6px;
           height: 15px;
        }
        & > .hme-label {
            margin: 0;
            font-family: Assistant;
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: left;
            color: #606672; 
        }
        & > .hme-joined-filters-popup-section {
            margin: 0 0 15px 0;
        }
    }
    & > .hme-joined-filters-popup-header {
        font-family: Assistant;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.31;
        letter-spacing: 1.28px;
        text-align: left;
        color: #606672;
        padding-bottom: 18px;
        flex: none;

        & > .hme-joined-filters-popup-close {
            float: right;
            font-size: 26px;
            line-height: 15px;
            color: #5c6670;
            font-weight: 600;
            cursor: pointer;
            margin: 0 15px 0 0;
        }
    }
}
