@import 'src/assets/styles/Variables';

.report-header {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: space-between;
    gap: 35px;
    margin: 30px 0 20px;

    &__title-wrapper {
        display: flex;
        align-items: center;
    }

    .hme-title {
        line-height: 1;
    }

    &__menu-opener {
        --line-height: 4px;

        padding: 0 20px;

        color: $main-color;
    }

    &__title-admin-view {
        padding-left: 48px;
    }

    &__additional-links {
        display: flex;
        align-items: center;
        list-style-type: none;
        text-wrap: nowrap;
        margin:0;
        padding: 0;
    }

    &__additional-links-item:last-child {
        border-left: 1px solid $header-separator-grey;
    }

    &__additional-link {
        padding: 10px 25px;
    }
}

.report-side-menu {
    position: fixed;
    top: 65px;
    left: 0;
    bottom: 72px;
    z-index: 2;

    width: 300px;
    padding-top: 20px;
    overflow-y: auto;

    background-color: $main-white;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);

    &__header {
        display: flex;
        margin-bottom: 20px;
    }

    &__menu-btn {
        --line-height: 3px;
        --line-width: 20px;

        padding: 20px;

        color: currentColor;
    }

    // menu items
    &__links-list {}

    &__links-item {}

    &__links-link {
        display: block;
        padding: 10px 15px;

        font-family: $font-family;
        font-size: 16px;
        color: $main-black;
        letter-spacing: 1.3px;
        text-transform: uppercase;

        &:hover,
        &:focus,
        &:active {
            color: $main-gray;
            font-size: 16px;
        }
    }

    &__links-link--active {
        color: $main-white;
        background-color: $main-color;

        &:hover,
        &:focus {
            color: $main-white;
        }
    }

}

// reset default list styles
ul.reset-list,
ul.reset-list li,
ul.reset-list ul li {
   margin:0;
   padding: 0;
   text-indent: 0;
   list-style-type: none;
   width:100%;
}
@import 'Tablet.scss';
