.hme-checkbox-component {
    display: inline-block;
    cursor: pointer;

    &.hme-checkbox-component-disabled {
        cursor: default;

        .hme-checkbox-component-icon {
            background-color: #c8ccd6;
        }
    }

    .hme-checkbox-component-icon {
        width: 16px;
        height: 16px;
        background-color: #ffffff;
    }

    .hme-checkbox-input {
        width: 0;
        height: 0;
        display: none;
    }

    .hme-checkbox-label {
        margin-left: 10px;
        font-family: Assistant;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: 1.12px;
        text-align: left;
        color: #000819;
    }
}
