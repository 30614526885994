@import 'src/assets/styles/Variables';

.hme-password-change-field {
    position: relative;

    display: flex;
    justify-content: space-between;

    &__btn.hme-button-component {
        order: 1;
        align-self: flex-start;

        line-height: 1.5;
    }

    &__fake-input {
        &.hme-input-container .hme-input-wrapper {
            position: static;
        }
    }

    &__modal .hme-modal-component {
        .modal-content {
            padding: 40px;
            padding-bottom: 110px;

            border-radius: 5px;
        }

        .modal-header {
            display: none;
        }
    }

    &__modal-dialog {
        min-height: auto;
    }
}

@import './Tablet.scss';
