.hme-main-header {
    .hme-language-select {
        margin-top: 11px;
        margin-bottom: 11px;
        margin-right: 15px;
    }

    .headerMenu:active {
        font-size: 14px;
    }
}
