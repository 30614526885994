.hme-account-info-section {
    flex-grow: 1;
    max-width: 405px;
    height: unset;
    padding: 0 60px;
    overflow-y: unset;

    &:not(:first-of-type):not(:last-child) {
        max-width: 465px;
    }

    .hme-title {
        margin-bottom: 20px;
    }

    &__content {
        display: grid;
        gap: 20px;
    }

    &__content-item {
        width: 100%;
        padding-top: 15px;
        
        border-top: 1px solid #e8e9ed;

        &:first-child {
            border-top: none;
            padding-top: 0;
        }
    }
}

@import './Tablet.scss';
