@import 'src/mixins/breakpoints.scss';

@include mobile-only {
    .hme-system-status-header {
        &__container {
            flex-direction: column;
            align-items: unset;
            gap: 10px;
        }
    
        &__buttons-container {
            gap: 10px;
            margin-left: unset;

            .hme-button-component {
                font-size: 12px;
            }
        }

        &__modal.modal-dialog.hme-modal-component {
            .modal-header .hme-title {
                font-size: 18px;
            }

            &.confirm-popup .modal-content .modal-body {
                font-size: 16px;
                padding: 20px;
                padding-top: 0;
            }

            .modal-content {
                padding: 20px;
            }

            .hme-button-component {
                min-height: 40px;
        
                font-size: 14px;
            }
        }
    }
}
