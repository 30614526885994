@import "src/mixins/breakpoints.scss";

@include mobile-only {
  .new-vaio-provider {
    .new-vaio-provider__content {
      padding: 28px 0 0 20px;

      .hme-title {
        height: 26px;
        font-size: 20px;
        font-weight: 600;
      }

      .new-vaio-provider__header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 0;

        .new-vaio-provider__header__links {
          width: 100px;
          .new-vaio-provider__header__links__box {
            border: 0;
            .hme-button-component {
              font-size: 0;
            }
          }
        }
      }

      .hme-section {
        height: auto;
      }

      .new-vaio-provider__sections {
        flex-direction: column;

        .new-vaio-provider-general {
          width: 100%;
        }

        .new-vaio-provider-details {
          width: 100%;
          border: 0;
          padding-left: 0;
        }

        .vaio-provider-form-short-input {
          max-width: 100%;
        }
      }
    }
    .new-vaio-provider__footer {
      display: flex;
      padding-right: 20px;
      justify-content: center;
    }

    .hme-sidebar-overlay__sidebar--active {
      width: 100%;
    }
  }
}
