.hme-loader-icon {
    --color: #0074ef;
    --size: 66px;

    margin: auto;
    border: 5px solid transparent;
    border-radius: 50%;
    position: relative;
    border-top: 5px solid var(--color);
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    width: var(--size);
    height: var(--size);

    &.hme-loader-invert-icon {
        border: solid 5px var(--color);
        border-top: 5px solid transparent;
    }
}

/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}