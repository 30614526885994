.hme-users--list {
    height: 100%;
    padding: 25px 50px;
    display: flex;
    flex-direction: column;

    .hme-account-email-header {
      padding: 10px 0;
    }

    .hme-users__header {
      margin-bottom: 10px;
    }

    .hme-grid {
      height: 100%;
      overflow-y: auto;
    }

    .hme-users--distributor-account--actions-cell {
      .hme-grid-cell-content {
        display: flex;
        justify-content: center;
      }
    }

    .hme-paginate {
      margin-top: 20px;
    }
}

@import './Mobile.scss';
