.zoom-device-settings {
    .icon-info {
        cursor: pointer;
    }

    .icon-info {
        color: #606672;
        margin-left: 4px;
        font-size: 16px;
    }

    .zoom-device-settings-checkboxes {
        padding-left: 10px;

        .hme-checkbox-component {
            margin-top: 24px;
        }

        .hme-checkbox-component:nth-child(2n + 1) {
            min-width: 173px;
        }

        .hme-checkbox-component:nth-child(2n) {
            padding-left: 15px;
        }
    }
}