.admin-create-apply-device-settings-task-wrapper {
    height: 100%;
}

.admin-create-apply-device-settings-task {
    height: 100%;
    display: flex;
    flex-direction: column;

    .admin-create-apply-device-settings-task-content {
        overflow: auto;
        height: 100%;
        padding: 0 48px;
        display: flex;
        flex-direction: column;
    }

    .admin-create-apply-device-settings-task-header {
        margin: 12px 0 39px;
    }

    .admin-create-apply-device-settings-task-sections {
        display: flex;
        flex: 1 1 100%;
        height: 100%;
        min-height: 620px;
    }

    .admin-create-apply-device-settings-task-source-area {
        display: flex;

        .hme-section {
            &:last-of-type {
                padding-right: 20px;
            }
        }
    }

    .admin-create-apply-device-settings-task-source {
        width: 354px;
        padding-right: 20px;
        flex: none;
    }

    .admin-create-apply-device-settings-task-filters {
        width: 428px;
        flex: none;
    }

    .admin-create-apply-device-settings-task-target {
        flex: 1;
        min-width: 1000px;
    }

    .zoom-device-settings {
        margin-top: 30px;
    }
}

.public-create-apply-device-settings-task-page {
    .admin-create-apply-device-settings-task-content {
        padding-top: 57px;
    }
}

.applyDeviceSettings {
    min-height: 40px;
    margin: 0;
}

@import './Mobile.scss';
@import './Tablet.scss';
