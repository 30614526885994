@media screen and (max-width: 1200px) and (min-width: 992px) {
    .navbar-nav {
        margin-top: 0!important;
        flex-direction: initial;
    }

    .user-info-section span {
        margin-right: 0;
    }
}

@media screen and (max-width: 1150px) and (min-width:768px) {
    .navbar-nav {
        margin-top: -52px;
        flex-direction: initial;
    }

    .user-info-section span {
        margin-right: 5px;
    }

    .collapse:not(.show) {
        display: flex;
    }

    .navbar-toggler {
        display: none;
    }

    .more-M .dropdown {
        position: absolute;
        z-index: 1000;
    }

    .user-main-header .more-M {
        margin-top: 10px;
        margin-right: 70px;
    }

    .admin-main-header .more-M {
        margin-right: 50px;
        margin-top: 2px;
    }

    .profile-username span{
        /* margin-right: 2px; */
    }

    .masquerade .profile-username {
        width: 110px;
    }
}

/* #33257 Gamification - Hamburger Menu */
@media screen and (max-width: 767px){
    .navbar.navbar-light div.container-fluid:first-child {
        display: block;
    }
    .navbar-collapse.collapse.in {
        height: 100vh; /* for browsers that do not support Custom Properties */
        height: calc((var(--vh, 1vh) * 100) - 50px); /* Calculate the available viewport height using the var --vh sended from the HmeHeader.js file */
    }

    /* Hamburger-menu animation */
    .navbar-toggler span.icon-bar {
        transition: all ease-out .15s;
    }
    .navbar-toggler span:nth-child(2) {
        transform: rotate(45deg);
        transform-origin: 10% 10%;
    }

    .noification-bell {
        position: absolute;
        right: 39px;
        top: 10px;
    }

    .navbar-toggler span:nth-child(3) {
        opacity: 0;
    }

    .navbar-toggler span:nth-child(4) {
        transform: rotate(-45deg);
        transform-origin: 10% 90%;
    }

    .navbar-toggler.collapsed span:nth-child(2),
    .navbar-toggler.collapsed span:nth-child(4) {
        transform: rotate(0);
    }

    .navbar-toggler.collapsed span:nth-child(3) {
        opacity: 1;
    }
    /* Hamburguer menu */
    .dropdown.open.btn-group,
    .account-nav-dropdown,
    .account-nav-dropdown .dropdown-menu {
        width: 100% !important;
    }
    .account-nav-dropdown span span .user-info-section.navbar-text {
        display: flex !important;
        flex-direction: row;
    }
    .account-nav-dropdown span span .user-info-section.navbar-text a {
        margin-left: 5px;
    }
    .user-info-section.masquerade {
        margin-top: 0;
    }
    .account-nav-dropdown span span .user-info-section.navbar-text.masquerade {
        display: block !important;
    }
    .navbar-collapse.collapse .masquerade div:first-child {
        display: block;
    }
    .account-nav-dropdown .masquerade .view-as span {
        display: inline;
    }
    .account-nav-dropdown span i {
        margin-right: 12px;
    }
    .account-nav-dropdown:not(.notification-nav-dropdown) .dropdown-menu li:first-child {
        margin-top: 1.5rem;
    }
    .navbar-collapse.collapse div:first-child {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .navbar-collapse.collapse div:first-child .navbar-nav:first-child {
        flex: 2 0 auto;
    }
    .navbar-toggler, .navbar-toggler:focus {
        padding: 0px;
        outline: none;
        border: none;
    }
    .navbar-toggler span, .navbar-toggler span::before, .navbar-toggler span::after {
        /* background: var(--gray-62); */
        border: none;
    }
    .headerMenu a {
        cursor: pointer;
    }
    .headerMenu.active_tab a::after {
        display: none;
    }
    .navbar-collapse {
        padding: 0;
    }
    .nav {
        width: 100%;
        display: block;
    }
    .navbar-collapse div .navbar-nav:first-child {
        list-style: none;
        padding: 0px;
        margin: 0px;
        width: 100%;
        height: 76vh;
    }
    .navbar-collapse div .navbar-nav .nav-item {
        height: 40px;
    }
    .navbar-collapse .navbar-nav .nav-item:last-child {
        margin-bottom: 12px;
    }
    /* .navbar-collapse ul, a span {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    } */

    #admin-main-header-mobile-log-out-user-dropdown-item, #user-main-header-mobile-log-out-user-dropdown-item {
        color: #777;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding-top: 10px;
        padding-bottom: 10px;
        line-height: 20px;
        text-transform: uppercase;
        font-size: 14px;
        font-family: Assistant,sans-serif;
        font-weight: 600;
    }

    .profile-username a {
        display: inline-block!important;
    }

    .profile-username {
        width: 200px;
        float: left;
        margin-right: 7px;
        max-width: 100%;
        display: flex;
    }

    #admin-main-header-mobile-log-out-user-dropdown-item i,
    #user-main-header-mobile-log-out-user-dropdown-item i {
        font-size: 30px;
        margin-right: 10px;
    }

    #admin-main-header-mobile-log-out-user-dropdown-item:hover,
    #user-main-header-mobile-log-out-user-dropdown-item i:hover {
        color: var(--blue-87)!important;
        cursor: pointer;
        text-decoration: none;
    }

    .navbar-collapse a {
        text-align: left;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .navbar-collapse div .navbar-nav .nav-item a > img {
        width: 28px;
        height: auto;
        margin-right: 12px;
    }
    .navbar-collapse div .navbar-nav .nav-item a i {
        width: 28px;
        height: 28px;
        font-size: 150%;
        margin-right: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .navbar-collapse, .navbar-toggle, .navbar-toggle:hover {
        border: transparent;
    }
    .navbar-header {
        display: flex;
        align-items: center;
    }
    .navbar-header a {
        flex: 1 1 auto;
    }
    .navbar-toggler, .navbar-toggler:hover {
        z-index: 10;
        background: transparent !important;
        margin: 0 12px;
        padding: 0%;
    }
    .responsiveHeader {
        z-index: 9;
        background: var(--white);
        width: 100%;
        height: calc(100vh - 50px);
        position: fixed;
        top: 0;
    }
    .showOnMobile {
        list-style: none;
        display: block !important;
    }
    .showOnDesktop {
        display: none !important;
    }
    li + .user-info-section {
        display: none;
    }
    .user-info-section {
        width: 100%;
        margin: 0px;
        text-align: left;
    }

    .navbar-nav {
        margin-left: 0;
    }

    .user-main-header.navbar-nav .headerMenu > a {
        padding-top: 10px!important;
        padding-bottom: 10px!important;
    }

    .smacktalk_icon {
        background-position-x: 15px;
        background-size: 29px auto;
    }

    .user-main-header.navbar-nav .smacktalk_icon > a {
        padding-left: 55px;
        padding-top: 10px;
        padding-bottom: 10px;;
        line-height: 1.2;
    }

    /* Alert DropDown menu horizontal aligment fix */
    .open > .dropdown-menu {
        right: 0;
        left: auto;
    }
    /* Sub Menus */
    .subHeaders {
        justify-content: center;
        padding: 0;
    }
    .subMenu {
        height: 32px;
    }
    .menuBar ul {
        flex: 1 1 auto;
        height: 32px;
    }
    .menuBar ul li {
        height: 32px;
        min-width: 64px;
        padding-top: 8.8px;
    }
    .subMenu li a {
        color: var(--gray-62);
    }
    .H4_Left {
        margin: 0px;
    }

    .menuBar ul li a.headerMenu{
        transition: all .2s ease-out;
    }
    .menuBar a, .menuBar a.active_tab_sub {
        font-size: 10px;
    }
    /* End Sub menus */
    /* Notifications DropDown */
    .notification-nav-dropdown.dropdown.open {
        position: static;
    }
    .notification-nav-dropdown .dropdown-menu {
        padding: 0px;
        margin: -13px 0 0 0;
        box-shadow: none !important;
        border-radius: 0;
        width: 100%;
        height: 100vh;
        -webkit-backdrop-filter: blur(1px);
        backdrop-filter: blur(1px);
        background-color: rgba(134, 140, 153, 0.1);
    }

    .dropdown-menu {
        box-shadow: none!important;
    }

    .dropdown-menu .dropdown-divider {
        display: none;
    }

    .notification-nav-dropdown .headerMenu {
        background: var(--white);
    }
    .notification-nav-dropdown .dropdown-menu .headerMenu:first-child {
        box-shadow: inset 0px 8px 10px -2px rgba(134, 140, 153, 0.2);
        padding-top: 10px;
    }
    .notification-nav-dropdown .dropdown-menu .headerMenu:last-child {
        box-shadow: 0px 8px 10px -2px rgba(134, 140, 153, 0.2);
        padding-top: 10px;
    }
    .notification-nav-dropdown .dropdown-menu .headerMenu:only-child {
        box-shadow: inset 0px 8px 10px -2px rgba(134, 140, 153, 0.2), 0px 8px 10px -2px rgba(134, 140, 153, 0.2);
        padding: 10px 0;
    }
    .notification-nav-dropdown .dropdown-menu::before {
        display: none;
    }
    .notification-flexbox {
        width: auto;
    }
    /* End Notifications DropDown */
}
