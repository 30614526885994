.hme-calendar-selector {
    background-color: #FFF;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
    position: relative;
    display: inline-block;
    width: max-content;

    .hme-calendar-selector__nav-button {
        position: absolute;
        top: 8px;
        width: 40px;
        height:40px;

        &.hme-button-disabled-component {
            display: none;
        }

        &.hme-calendar-selector__nav-button--prev {
            left: 8px;
        }

        &.hme-calendar-selector__nav-button--next {
            right: 8px;
        }
    }

    .hme-calendar-selector__nav-button__icon--prev {
        transform: rotate(90deg);
    }

    .hme-calendar-selector__nav-button__icon--next {
        transform: rotate(-90deg);
    }

    .hme-calendar-selector-month {
        display: inline-block;
        vertical-align: top;
    }

    .hme-calendar-selector__actions {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        justify-content: flex-end;

        .hme-calendar-selector__actions__reset {
            margin-right: 8px;
            font-size: 16px;
            font-weight: bold;
            line-height: 1.31;
            letter-spacing: 0.32px;
            color: #186df4;
            padding: 10px 25px;
        }

        .hme-calendar-selector__actions--apply {
            margin-right: 8px;
        }
    }
}
