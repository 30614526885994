@import 'src/mixins/breakpoints.scss';

.hme-users {
    padding: 32px 48px 58px 50px;
    height: 100%;
    display: flex;
    flex-direction: column;

    .hme-grid {
        flex: 1;
        overflow: auto;
    }
}

@include before-desktop-only {
    .hme-users {
        .hme-grid{
            display: none;
        }
    }
}
