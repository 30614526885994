@import 'src/mixins/breakpoints.scss';

@include tablet-only {
    .hme-view-user {
        padding: 22px 23px 0 15px;

        .hme-view-user__header {
            margin-bottom: 20px;
        }

        .hme-view-user__sections {
            flex-direction: column;
        }

        .hme-view-user__general {
            min-width: 365px;
            flex: 0 1 365px;
        }

        .hme-view-user__footer {
            margin-left: -15px;
            margin-right: -23px;
        }

        &--admin {
            overflow: auto;

            .hme-view-user__access {
                flex: 1;
                padding: 0 0 71px 20px;
            }

            .hme-view-user__access {
                height: 100%;
            }

            .hme-view-user__activity-log {
                height: 100%;
                border-left: 0;
                padding: 0;

                .hme-grid {
                    .hme-grid-header {
                        top: -27px;
                    }
                }
            }
        }
    }
}

@include mobile-only {
    .hme-view-user {
        padding: 15px 22px 0 15px;

        .hme-view-user__header {
            margin-bottom: 20px;
        }

        .hme-view-user__sections {
            display: block;

            &__wrapper {
                display: block;
                height: auto;
            }
        }

        .hme-view-user__general {
           width: 100%;
           min-width: unset;
           width: 100%;
        }

        .hme-view-user__access {
            width: 100%;
            border-left: none;
            min-width: unset;
            width: 100%;
        }

        .hme-view-user__footer {
            margin-left: -15px;
            margin-right: -22px;
        }

        &--admin {
            overflow: auto;

            .hme-view-user__access {
                flex: 1;
                padding: 0;
                height: auto;
            }

            .hme-view-user__activity-log {
                display: block;
                width: 100%;
                height: auto;
                min-width: unset;
                border-left: 0;
                padding: 0 0 40px;

                .hme-tile-list__wrapper {
                    display: block;
                    padding: 0;
                }

                .hme-grid {
                    display: none;
                }
            }
        }
    }
}
