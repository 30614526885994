@import 'src/assets/styles/Variables';

.nitro-announcement-settings {
    --items-gap: 30px;

    display: flex;
    flex-direction: column;
    gap: var(--items-gap);
    padding-top: var(--items-gap);
    height: 100%;

    font-family: $font-family;
    overflow-y: auto;

    &__page-title {
        margin: 0;
        padding: 0 50px;

        line-height: 1.3;
    }

    &__form {}

    &__fields-wrapper {
        display: flex;
        gap: 40px;

        padding: 30px 50px;
    }

    &__footer {
        position: sticky;
        left: 0;
        bottom: 0;
        z-index: 2;

        display: flex;
        justify-content: flex-end;
        min-height: 80px;
        width: 100%;
        margin: auto 0 0 0;

        text-align: right;
        padding: 20px 50px 20px 0;
        box-shadow: 0 -10px 20px 0 rgba(0, 0, 0, 0.16);
        background-color: $main-white;
    
        .hme-button-component {
            margin: 0 0 0 20px;
        }
    }

    &__section {
        display: flex;
        flex-direction: column;
        gap: var(--items-gap);
        max-width: 475px;
    }

    &__section-title {
        margin: 0;

        font-size: 24px;
        font-weight: 600;
        line-height: 1.3;
    }

    &__section-separator {
        &:last-of-type {
            display: none;
        }
    }

    &__fieldset,
    &__fieldset-wrapper {
        --fieldset-gap: 20px;

        display: flex;
        flex-direction: column;
        gap: var(--fieldset-gap);
    }

    &__fieldset-separator {
        &:last-of-type {
            display: none;
        }
    }

    &__fieldset-legend {
        margin-bottom: 0;

        font-size: 20px;
        font-weight: 600;
        line-height: 1.3;
    }

    &__fieldset-description {
        margin-bottom: var(--fieldset-gap);

        font-size: 16px;
        color: $main-black;
    }

    &__fieldset-wrapper {}

    &__field {
        display: flex;
        flex-direction: column;
    }

    &__field-label {
        margin-bottom: 8px;

        color: $main-black;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.5;
        text-transform: uppercase;
    }

    &__field-inputs {
        display: flex;
        gap: 20px;
        align-items: center;
    }

    &__field-input {
        --border-color: #a7adba;
        appearance: none;
        max-width: 105px;
        padding: 12px;
        padding-left: 25px;

        color: $main-black;
        font-size: 16px;
        border: 1px solid var(--border-color);
        border-radius: 24px;

        &--bordered {
            --border-color: #{$error-field};
        }
    }

    &__field-select {
        max-width: 165px;
    }

    &__field-sublabel {
        font-size: 16px;
        color: $main-black;
    }

    &__field-error {
        margin-top: 10px;

        font-weight: 700;
        color: $error-field;
    }
}
