.hme-admin-systems-email-link {
    display: flex;
    font-size: 16px;
    line-height: 18px;

    strong {
        font-weight: normal;
        margin: 0 27px 0 0;
        color: #606672;
    }

    .hme-button-component {
        text-transform: capitalize;

        &.hme-transparent-button-component {
            background: url('~images/icon-envelope.svg') no-repeat 0 50%;
            background-size: 20px 20px;
            padding: 0 0 0 26px;
            border-radius: 0;
            text-align: left;
        }
    }
}
