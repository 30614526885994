.deviceStatusText {
  color: black;
  white-space: nowrap;
  width: 64px;
}
.deviceStatusText>span {
  padding: 0 0 0 5px
}

.storeTable.ctable {
  margin: 0 auto;
}
.stores .forms h3 {
  font-size: 30px;
  margin-bottom: 17px;
}
.systemsHeader {
  display: flex;
  justify-content: space-between;
}
.stores div.additem {
  background-color: #D41B3E;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 13px !important;
  padding: 10px 20px;
  margin-right:10px;
}

.stores .settings_search {

  padding: 0 0 10px;
}


span.redFontStores {
  color: #D41B3E;
  font-size: 18px;
  line-height: 0;
}


span.corpText {
  color: #757575;
  font-weight: lighter;
}

.corpTextSection{
  padding-top: 25px
}

.stores .forms {
  margin-bottom: 25px;
  margin-top: 47px;
}
.innerTable {
  width: 500px
}
#innerTable1 {
  width: 100px
}
#innerTable2 {
  width: 150px
}
#innerTable3 {
  width: 180px
}
.innerTable td {
  width: 100px
}
.innerTable td#long {
  width: 150px !important
}
.stores .theader, .stores .cheader {
  border: 1px solid #b9b9b9;
  color: #757575;
}

.stores #brand_space {
  width: 115px;
}

.storesHeaderSections {
  width: 50%;
  height: 25px;
  float: left;
  margin-bottom: 2em;
}

.stores tr.theader th {
  font-size: 16px !important;
}

.stores .ctable th {
  padding: 3px 2px;
}
.stores .forms th {
  font-size: 14px !important;
  text-align: left;
}

.opener.view_details{
  cursor: pointer;
}

.stores img.cstat {
  margin-left: auto;
  margin-right: auto;
  padding: 0 5px 0 0;
}

.statusImage{
  padding-left: 5px
}

.statusText {
  padding-left: 5px;
}

.stores .ctable td.store_checkbox {
  line-height: 40px;
}

.stores .tdata:hover {
  background-color: #c4e9f1;
  border-bottom: 1px solid #709da2;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
}

.stores .tdata, .stores .cdata {
  background-color: #ffffff;
  border-bottom: 1px solid #b9b9b9;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  color: #000000;
}

.masterSettings {
  display:flex;
  width: 100%;
}
.masterSettings .versions {
  font-size: 30px !important;
  margin: 25px 0px 17px 0px !important;
  border: none !important;
}

.masterSettings .form_cbox {
  border: 1px solid #B9B9B9;
  height: 125px;
  margin: 5px 0 0 5px;
  overflow: auto;
  padding: 5px;
  width: 250px;
}

.masterSettings .forms input[type=submit] {
  background-color: #d41b3e;
  border: none;
  float: right;
  color: #ffffff;
  cursor: pointer;
  padding: 10px 25px;
  margin-top: 18px !important;
}

.sys-settings.forms {
  margin-bottom: 25px;
  margin-top: 47px;
  color: rgb(0, 0, 0);
}
.sys-settings h3.clear {
  font-size: 30px;
  margin-bottom: 17px;
}
.sys-settings .system_header {
  float: left;
}
.sys-settings .device_header {
  float: left;
  margin-left:8em;
}
.sys-settings .settab {
  width: 430px;
  float: left;
}

.sys-settings .settab tbody, .sys-settings .settab tr, .sys-settings span , .sys-settings th, .sys-settings ul, .sys-settings h3 {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  font-family: Arial;
  vertical-align: baseline;
}

.sys-settings td {
  padding-left: 10px;
  padding: 10px;
  font-size: 16px;
  vertical-align: top;
}

.sys-settings .settab tbody th {
  width: 150px;
  font-size: 14px;
  padding: 10px;
  text-align: left;
}

.sys-settings a {
  color: #D41B3E;
  font-size: 16px;
  text-decoration: underline;
}

.sys-settings ul {
  list-style: none;
}


.storeTable.ctable {
  margin: 0 auto;
}

.stores .forms h3 {
  font-size: 30px;
  margin-bottom: 17px;
}

.stores button.additem {
  background-color: #D41B3E;
  padding: 10px 20px;
  margin-right:10px;
  border: none;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 13px !important;
}
.stores button.additem a{
  color: #FFFFFF;
  font-weight: bold;
  font-size: 13px !important;
  text-decoration: none
}

.stores .settings_search {

  padding: 0 0 10px;
}

.stores .add_store {
  background-color: #D41B3E;
  border: none;
  text-transform: capitalize;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-size: 13px;
  font-weight: bold;
  margin: 1em;
  padding: 10px 20px;
}

.stores .forms {
  margin-bottom: 25px;
  margin-top: 10px;
}

.stores .theader, .stores .cheader {
  border: 1px solid #b9b9b9;
  color: #757575;
}

.stores #brand_space {
  width: 115px;
}

.stores tr.theader th {
  font-size: 16px !important;
  user-select: none;
}

.stores tr.theader th:hover,
.stores tr.theader th:focus,
.stores tr.theader th:active {
  cursor: pointer;
}

.stores .ctable th {
  padding: 10px;
}
.stores .forms th {
  font-size: 14px !important;
  text-align: left;
}

.storesWidth {
  width: 100%;
}

.stores img.cstat {
  margin-left: auto;
  margin-right: auto;
  padding: 0 5px 0 0;
}

.stores .ctable td.store_checkbox {
  line-height: 40px;
}

.stores .tdata:hover {
  background-color: #c4e9f1;
  border-bottom: 1px solid #709da2;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
}

.stores .tdata, .stores .cdata {
  background-color: #ffffff;
  border-bottom: 1px solid #b9b9b9;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  color: #000000;
}

.masterSettings {
  display:flex;
  width: 100%;
}
.masterSettings .versions {
  font-size: 30px !important;
  margin: 25px 0px 17px 0px !important;
  border: none !important;
}

.masterSettings .form_cbox {
  border: 1px solid #B9B9B9;
  height: 125px;
  margin: 5px 0 0 5px;
  overflow: auto;
  padding: 5px;
  width: 250px;
}

.masterSettings .forms input[type=submit] {
  background-color: #d41b3e;
  border: none;
  float: right;
  color: #ffffff;
  cursor: pointer;
  padding: 10px 25px;
  margin-top: 18px !important;
}

.sys-settings.forms {
  margin-bottom: 25px;
  margin-top: 47px;
  color: rgb(0, 0, 0);
}
.sys-settings h3.clear {
  font-size: 30px;
  margin-bottom: 17px;
}
.sys-settings .system_header {
  float: left;
}
.sys-settings .device_header {
  float: left;
  margin-left:8em;
}
.sys-settings .settab {
  width: 430px;
  float: left;
}

.sys-settings .settab tbody, .sys-settings .settab tr, .sys-settings span , .sys-settings th, .sys-settings ul, .sys-settings h3 {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  font-family: Arial;
  vertical-align: baseline;
}

.sys-settings td {
  padding-left: 10px;
  padding: 10px;
  font-size: 16px;
  vertical-align: top;
}

.sys-settings .settab tbody th {
  width: 150px;
  font-size: 14px;
  padding: 10px;
  text-align: left;
}

.sys-settings a {
  color: #D41B3E;
  font-size: 16px;
  text-decoration: underline;
}

.sys-settings ul {
  list-style: none;
}
/* Remote System Actions */

.RemoteSystemActions h3 {
  border-radius: 0 0 0 0;
  color: #000;
  font-size: 20px;
  margin: 5px;
  padding: 5px;
}

.RemoteSystemActions .upgrade-message {
  margin: 5px;
  padding: 5px;
}

.RemoteSystemActions input {
  border: 1px solid #b9b9b9;
  margin: 5px;
  padding: 5px;
}

.RemoteSystemActions .ui-corner-all,.RemoteSystemActions .ui-corner-bottom,.RemoteSystemActions .ui-corner-right,.RemoteSystemActions .ui-corner-br {
  border-bottom-right-radius: 6px;
}

.RemoteSystemActions a.ui-button,.RemoteSystemActions a:link.ui-button,.RemoteSystemActions a:visited.ui-button,.RemoteSystemActions .ui-button {
  color: #2779aa;
  text-decoration: none;
}

.RemoteSystemActions .ui-button .ui-button:active {
  border: 1px solid #aed0ea;
  background: #d7ebf9;
  font-weight: bold;
  color: #2779aa;
}

.RemoteSystemActions a {
  width:15em;
  font-size:12px;
  text-align:left;
  text-decoration: none;
}

.ActionButtons {
  width: 200px;
  background: #e4f2fb;
  padding: 5px;
  margin: 20px 10px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  border: 1px solid #b2b2d8;
  font-size: 12px;
  text-align: left;
  text-decoration: none;
  color: #337ab7;
}

.ActionButtons:hover {
  background: #eff7fd;
}

#react-paginate ul {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
}

#react-paginate li {
  display: inline-block;
}

#react-paginate .break a {
  cursor: default;
}

.search { position: relative;  display: inline-block; }
.search input { text-indent: 30px;}
.search .fa-search {
  position: absolute;
  right: 17px;
  top: -23px;
}

.search_pos {
  border: 1px solid #E8E8E8;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  width: 200px;
  height: 27px;
  left: 0;
  top: 0px;
  margin: 25px 0px 8px 2px;
  padding: 1px 1px 1px 1px;
  float: right;
  border-radius: 4px;
}

.search_pos.left {
  float: left;
}

.search_pos.right {
  float: right;
}

.search_pos .search .searchBox {
  border: 0px solid #b9b9b9;
  width: 134px;
  height: 23px;
  outline: none;
}
/*Added this for removing the Clear field X icon in IE11 on the search text used in my admin account*/
.search_pos .search .searchBox::-ms-clear {
  display: none;
  width:0;
  height:0;
}
.settings.forms div.storeHeight {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.search_pos .dropdown {
  width: 35px;
  /* display: inline-block; */
}
.search_pos .search {
  width : 162px;
  display:flex;
  align-items: center;
}
stores .searchImage {
  margin-left: -12px;
  height: 15px;
}
.search input {
  text-indent: 0px;
}
button#dropdown-no-caret {
  color: blue;
}
#dropdown-no-caret {
  line-height: 9px;
  width: 33px;
  background: #e4f2fb;
  border-radius: 3px;
}
.settings_list {
  margin-top: 10px;
}

/********************************Scrollable Snapshot Table (Preconfigure Device Modal)************************************************/

 .snapshot-table tbody {
  display:block;
  height:300px;
  overflow:auto;
}
.snapshot-table thead, .snapshot-table tbody tr {
  display:table;
  width:100%;
  table-layout:fixed;
}
.snapshot-table thead {
  width: calc( 100% - 1em )
}
.snapshot-table table {
  width:400px;
}

.snapshot-table th:first-child, .snapshot-table tbody td:first-child {
  width: 35px;
}

.snapshot-table th:nth-child(4), .snapshot-table tbody td:nth-child(4) {
  width: 129px;
}

.snapshot-table th:nth-child(6), .snapshot-table tbody td:nth-child(6) {
  width: 150px;
}

.snapshot-table th:nth-child(7), .snapshot-table tbody td:nth-child(7) {
  width: 167px;
}

.snapshot-table .clear::after {
  display: none!important;
}

#currentDeviceName {
  width: 140px;
}

.add-device-container .add-device-title {
  padding-left: 0;
}

.snapshot-table .add-device-title {
  padding-left: 15px;
}

/********************************************************************************/

#systemStatusColumn {
  width:150px
}

.custom-ui h1 {
  border: 2px solid red;
  padding: 10px;
  border-radius: 25px;
  font-size: 16px;
}

/*********** System settings and system status css begins *******************/
.merge-modal-popup {
  position: absolute;
  top: 298.5px;
  left: 170.5px;
  display: block;
  height: auto;
  width: 1000px;
  z-index: 102;
}

.system-status-table {
  width: 420px;
  float: left;
}

.syatem-status-table tr td {
  padding-left: 10px;
  vertical-align: top;
}

table.system-status-table th {
  width: 150px;
}
.system-settings-status th {
  padding: 10px;
  font-size: 14px;
  font-weight : normal;
  text-align: left;
}

.list-style-none {
  list-style: none;
}

.system-settings-container {
  /* float: right; */
  margin-bottom: 20px;
  width: 590px;
}

.system-settings-header, .system_header {
  border-radius: 0 0 0 0;
  color: #000;
  padding: 5px;
  font-size: 30px !important;
  margin: 25px 0px 17px 0px !important;
  border: none !important;
}

.settings-accordion-item div div[aria-expanded='true'] {
  background: #3baae3;
  font-weight: bold;
}


.adminAccordion div div[aria-expanded='true'] {
  background: #3baae3;
  font-weight: bold;
  font-size: 12px
}

.adminAccordion div div[aria-expanded='false'] {
  font-size: 12px
}

.adminAccordion .settings-accordion-content tr.settings-table-header th {
  font-size: 12px;
}
.adminAccordion .settings-accordion-content tr.system-settings-row-data {
  font-size: 12px;
}

.system-settings-status{
  /* display:inline-block; */
  /* float: left; */
}
.system-settings-accordion {
  width: 100%;
}
.registered-store-list {
  padding-left: 0px;
}
.settings-accordion-header {
  display: block;
  cursor: pointer;
  position: relative;
  margin: 2px 0 0 0;
  padding: .5em .5em .5em .7em;
  font-size: 12px;
  background:#e8f3fc;
  border: 1px solid #B9B9B9;
  border-radius: 0 0 0 0;
  color: #000;
  font-weight: bold;
}

.settings-accordion-content {
  padding: 1em 0px;
  border-top: 0;
  overflow: auto;
  margin: 0 auto;
  height: 170px;
  width: 90%;
}

tr.settings-table-header th {
  min-width: 250px;
  font-size: 17.6px
}

tr.settings-table-header {
  border: 1px solid #b9b9b9;
  color: #757575;
}

tr.system-settings-row-data {
  background-color: #ffffff;
  border-bottom: 1px solid #b9b9b9;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  color: #000000;
  font-size:17.6px;
}

.settings-table-header .text-end {
  text-align: left !important;
  padding-left: 125px !important;
  padding-right: 240px !important;
}

tr.system-settings-row-data:hover {
  background-color: #c4e9f1;
  border-bottom: 1px solid #709da2;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
}

.store-link {
  color: #D41B3E;
  text-decoration: underline;
}

/*********** System settings and system status css ends here *******************/
/*********** System settings and system status css ends here *******************/
/******* store merge css starts here **************/
.store-merge-popup {
  position: relative;
  top: 65px;
  height: auto;
  width: 995x;
  max-width: 995px!important;
  display: block;
  z-index: 102;
  overflow:none;
  padding: 0 0 2em .4em;
}

.store-merge-popup .modal-content {
  width: auto;
  min-height: 0px;
  max-height: none;
  height: 446px;
  overflow: hidden;
  padding-left: 35px;
  position: relative;
  border: 0;
  background: none;
  border: 1px solid #aed0ea;
  border-bottom-right-radius: 6px;
  background: #f2f5f7;
  color: #362b36;
}

.merge-store-header {
  padding-bottom: 20px;
  padding-left: 0px;
}
.merge-store-header h2 {
  font-size: 28px;
  margin: 0;
  padding-bottom: 0px;
}
.merge-store-wrapper {
  width: 900px;
  font-size: 12px;
}

.store-merge-body {
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
  padding-top: 20px;
  padding-bottom: 20px;
}

.merge-store-row {
  display: table;
  width: 100%;
}

.select-sys-col {
  box-sizing: border-box;
  width: 39%;
  float: left;
  position: relative;
  min-height: 1px;
}

.select-system-content {
  border: 1px solid #ccc;
  padding: 0px;
  height: 156px;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 323px;
  font-size: 12px;
}

.target-results-content table table tr th:nth-child(1) {
  width: 1%;
}
.target-results-content table table tr th:nth-child(2) {
  width: 27%;
}
.target-results-content table table tr th:nth-child(3) {
  width: 47%;
}
.target-results-content table table tr th:nth-child(4) {
  width: 27%;
}

.select-system-content table , .target-results-content table {
  width: 100%;
  border-bottom: 1px solid #f5f5f5;
}

.select-system-content table tr th:nth-child(1) {
  width: 10%;
}
.select-system-content table tr th:nth-child(2) {
  width: 43%;
}
.select-system-content table tr th:nth-child(3) {
  width: 47%;
}

.select-system-content table tr th , .target-results-content table tr th {
  background-color: #ddd;
  color: #555;
  padding: 5px 10px;
  border-right: 1px solid #f5f5f5;
  padding-top: 7px;
  padding-bottom: 6px;
  font-size: 12px;
  text-align: center;
  font-family: sans-serif;
}

.select-system-content table tr td , .target-results-content table tr td {
  padding: 5px 10px;
  border-right: 1px solid #f5f5f5;
  padding-top: 7px;
  padding-bottom: 6px;
}

.select-system-content table tr:nth-child(even) , .target-results-content table tr:nth-child(even) {
  background: #f5f5f5;
}

.select-system-content table tr:nth-child(odd) , .target-results-content table tr:nth-child(odd) {
  background: #ffffff;
}

.target-store-col {
  border-left: 1px solid #ddd;
  padding-left: 28px;
  width: 57%;
  float: left;
  position: relative;
  min-height: 1px;
}


.select-system-col {
  width: 39%;
  float: left;
  position: relative;
  min-height: 1px;
  box-sizing: border-box
}
.select-system-col h3 {
  font-size: 16px;
  margin: 15px 0;
  font-weight: normal;
}
.target-store-col h3 {
  font-size: 16px;
  margin: 15px 0;
  font-weight: normal;
}

.form-group.target-store-group {
  padding: 10px 0;
  padding-top: 0px;
}

.merge-search-btn {
  display: inline-block;
  padding: 2px 12px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  color: #555;
  background-color: #ccc;
  font-family: Lucida Grande,Lucida Sans,Arial,sans-serif;
  border-radius: 0px;
}

.target-store-group .store-number-input {
  height: 25px;
  padding-left: 5px;
  margin-right: 7px;
  border-radius: 0px;
  font-size: 12px;
  font-family: Lucida Grande,Lucida Sans,Arial,sans-serif;
}

.target-store-search {
  padding: 15px 0;
  padding-top: 0px;
}

.select-system-col .store-brand-info {
  height: 36px;
  padding-top: 0px;
}

.store-brand-info div {
  margin-top: 5px;
}

.merge-storenobrand-label {
  color: green;
}

.target-results-content {
  border: 1px solid #ccc;
  padding: 0px;
  height: 156px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.merger-popup-close {
  font-size: 20px;
  color: #d41b3e;
  float: left;
  margin-left: 25px;
  margin-top: 13px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 25px;
}

.store-merge-footer {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 0px;
}

.merger-error-block {
  width: 39%;
  float: left;
  position: relative;
  min-height: 1px;
  box-sizing: border-box;
}

.merger-error-message {
  font-size: 12px;
  color: red;
}
.merger-success-message {
  font-size: 12px;
  color: green;
}
.save-merge-block {
  padding-right: 54px;
  float: right;
  width: 57%;
  position: relative;
  min-height: 1px;
}

.merge-cancel {
  font-size: 14px!important;
  font-weight: 700;
  vertical-align: middle;
  line-height: 2.7;
  color: #D41B3E !important;
  text-decoration: none;
  cursor: pointer;
}
.merge-cancel a:hover{
  text-decoration: underline;
}

.merge-submit-btn {
  background-color: #d41b3e;
  border: none;
  float: right;
  color: #fff;
  cursor: pointer;
  padding: 8px 25px;
  font-size: 1em;
  margin-top: 0px !important;
}

.alignItems {
  display: flex;
  align-items: center;
}
.alignItems label {
  padding-left: 5px;
  margin-bottom: 2px;
  font-weight: normal
}

.alignItems.selectAllHeader {
  margin-left: 10px !important
}

.settingsSection {
  margin: 0px 100px 50px 0px !important;
}

.settingsSection.destinationdeviceSection {
  margin: 0px 50px 50px 50px !important;
}

#settings-content {
  margin-right: 50px;
  position: absolute;
  bottom: 50px;
  right: 0px;
}
.destinationSectionRight {
  position: relative
}


#settings-btn {
  background: #D41B3E;
  border: none;
  padding: 5px 15px;
  color: white;
}

.cancel_btn {
  color: #D41B3E !important;
  font-weight: bold;
}

tr.storeTable th {
  font-size:16px !important
}

.RemoteSystemActions {
 padding-top: 10px;
 padding-left:10px
}

/************* merge confirm css starts here ***************/
.react-confirm-alert-svg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 117;
  background:none
}

.react-confirm-alert-overlay {
  background:none !important;
  z-index: 1051 !important;
}
.custom-ui {
  /* position: absolute; */
  height: auto;
  width: 400px;
  top: 150px;
  left: 470px;
  display: block;
  z-index: 117;
  background: #f2f5f7;
  color: #362b36;
  font-size: 12px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  padding: .2em;
  outline: 0;
}
.custom-ui h1 {
  margin-top: 0px;
  border: 1px solid #aed0ea;
  color: #222222;
  font-weight: bold;
  padding: .4em 1em;
  position: relative;
  background: none;
  border-top-right-radius: 6px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top-left-radius: 6px;
  font-size:12px;
  margin-bottom: 0px;
}

.custom ui .custom-ui-header h1 {
  float: left;
  margin: .1em 0;
  white-space: nowrap;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: move;
}

.custom-ui-header h1 {
  cursor: move;
}

.merge-dialog-content {
  width: auto;
  min-height: 50px;
  max-height: none;
  height: auto;
  font-size: 14px;
  font-weight: bold;
  position: relative;
  border: 0;
  padding: .5em 1em;
  background: none;
  overflow: auto;
}

.store-info-section {
  font-size: 14px;
  font-weight: normal;
  color: gray;
  margin: 10px 0px;
  padding: 10px;
  border: 1px solid #e5e5e5;
}

.greenText {
  color: green;
}

.store-merge-footer {
  text-align: left;
  border-width: 1px 0 0 0;
  background-image: none;
  margin-top: .5em;
  padding: .3em 1em .5em .4em;
  /* border: 1px solid #dddddd; */
  background: #f2f5f7;
  color: #362b36;
  height : 40px;
  padding-left: 0px;
}

.store-merge-footer:before {
  content: "";
  display: table;
  border-collapse: collapse;
}

.merge-footer-buttonset {
  display: flex !important;
  float: right;
}

.merge-footer-buttonset button {
  margin: .5em .4em .5em 0;
  cursor: pointer;
  font-size: 1em;
  border: 1px solid #aed0ea;
  background: #d7ebf9;
  font-weight: bold;
  color: #2779aa;
  padding: .4em 1em;
  display: block;
  position: relative;
  line-height: normal;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: visible;
}

.merge-footer-buttonset button:hover {
  border: 1px solid #74b2e2;
  background: #e4f1fb;
  font-weight: bold;
  color: #0070a3;
}
.rounded-corners {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.confirmation-text {
  margin-bottom: 0px;
}

/************* merge confirm css starts here ***************/
a.view_details {
  color: #D41B3E !important;
  font-size: inherit;
  text-decoration: none;
}
a.view_details:hover {
  text-decoration: underline;
}
a.view_details span, a.view_details,
a.view_details:active{
  font-size: 14px;
  font-weight:400
}
.merge-statement {
  font-weight: normal;
  font-size: 12px;
  position: relative;
  bottom: 0px;
}
.merge-statement-masquerade {
  font-weight: normal;
  font-size: 12px;
  position: relative;
  top: 80px !important;
  bottom: 0px;
}

.storesTable.ctable tr.tdata td {
  font-size: 14px;
  padding-left: 10px;
}

.tab-content .storesTable {
  min-width: 550px;
}

.unavailable{
 padding-left: 0px !important
}

.storeListviewEditDetails {
  text-align: center;
}
.storePopupForm {
  margin-top: 15px;
}
.storePopupForm .saveStoreDetailsErrors{
  font-size: 12px;
  text-align: left;
  color:  red;
}
.storePopupForm .saveStoreErrors{
  color: red;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
}
.storePopupForm .saveStoreDetailsSuccess {
  font-size: 12px;
  text-align: left;
  color: green;
}

.storePopupForm-Loader {
  margin-top: 80px;
}

.StoreListtimezone{
  color: black
}

.stores_Details {
  margin-top: 30px;
  width: 50%;
}

.Store_savebtn_bottom{
  margin-bottom: 30px;
}

.merge-error-list {
  padding-left: 0px;
}

.merger-error-block ul.merge-error-list,
.merger-error-block ul.merge-error-list li.merge-error-message {
  font-size: 12px;
  color: red;
  list-style: none;
}
.merge-success-message {
  font-size: 12px;
  color: green;
}

.store_manage_buttons a {
  text-decoration:none;
}
.clientStoreList .settings_search {
  padding: 0 0 10px;
}

.bulkAction .downArrow {
  margin-left: 2px;
  margin-top: -1px;
}

/*
  Upgrade dropdown list should be hidded unless bulkAction-container is hovered.
  Position the block relative to the container element.
*/
.bulkGroup {
  visibility: hidden;
  display: none;
  position: absolute;
  font-size: 16px;
  min-width: 20em;
  margin-top: 7px;
  margin-left: -13px;
}
.storesTitle {
  min-width: 22%;
}

.bulkAction-container {
  width: 20em;
  z-index: 5;
  margin: 20px 0 0 0;
  width: 260px;
  border: 1px solid #ccc;
  color: #000000;
  background-color: transparent;
  width: 246px;
}

.bulkAction-container .bulkGroup {
  color: #000000;
}

/*
  Make dropdown list appear once action container is hovered.
*/
.bulkAction-container:hover .bulkGroup {
  visibility: visible !important;
  display:block !important;
  z-index: 10;
  min-height: 10em;
  max-height:10em;
  background: white;
}

/*
  Put blue highlight on the component when it is hovered.
*/
.bulkAction:hover {
  background-color:skyblue;
}

.store-loading {
  text-align: center;
  margin-bottom: 70px;
}

.store-loading .commonLoaderSection {
  padding: 0 !important;
}

.store-loading h3, .deviceBulkListing.users .forms h3 {
  font-size: 30px;
}

.no-device-error {
  font-size: 16px;
  margin-top: 15px;
}

.no-device-error ul {
  margin-bottom: 20px;
}
.no-device-error  a {
  color: #757575;
  cursor: pointer;
}

.deviceBulkListing.users .forms h3, .deviceBulkListing.users .forms p {
  margin-bottom: 17px
}
.deviceBulkListing.users .forms p {
  font-size: 14px;
}

.deviceBulkListing.users .forms {
  padding-bottom: 60px;
}

.deviceBulkListing.users .forms .yellow {
  color: #dcba00;
  font-weight: bold;
}

.deviceBulkListing.users .forms .success {
  color: green;
  font-weight: bold;
}

.deviceBulkListing.users .forms .failed {
  color: red;
  font-weight: bold;
}

.storesTableHeader td#innertable1,
.storesTableHeader th#innertable1,
.storesTableHeader td#innertable2,
.storesTableHeader th#innertable2 {
  width: 160px;
}

/* .storesTableHeader th#innertable2 {
  padding-left: 0px;
} */


.systemStatus_online_normal {
  color: #d41b3e !important;
  cursor: pointer;
}
.systemStatus_online_normal a:hover{
  color: #d41b3e !important;
  text-decoration: underline;
  cursor: pointer;
}
.systemStatus_online {
  color: #d41b3e !important;
  font-weight: bold;
  cursor: pointer;
}
.systemStatus_online a:hover{
  color: #d41b3e !important;
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
}

.client_action{
  margin-left: 10px;
  margin-top: -20px;
}
.client_remote_actionBtn{
  background-color: #D41B3E;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 13px;
  width: 370px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
  cursor: pointer;
}
.client_remote_action_btn_space{
  padding-bottom: 25px;
}
.client_action_text{
  color: white;
  padding: 100px;
  text-align: center;
}
.systemStatus_offline {
  color:black !important;
  pointer-events: none;
}
.systemStatus_offline a:hover{
  color: black !important;
  text-decoration: underline;
  pointer-events: none;
}
span.cstat.deviceStatus span a {
  font-size: 14px;
}
span.cstat.deviceStatus a {
  font-size: 14px;
}

span.cstat.deviceStatus span {
  font-size: 14px;
}
.store-details table tr th label {
  text-align: left;
}

.store-details h4.cib_header {
  font-size: 30px;
  margin-bottom: 10px;
}

table.user_form .Required {
  padding-left: 4px;
  color: #D41B3E;
}

div.store-details table.storedetails  tr td  select,
div.store-details table.storedetails  tr td  input[type=text] {
  width: 115px;
}
.systemStatusText {
  font-size: 16px;
}
div.store-details table.user_form tr td {
  padding-top:0px;
}

div.store-details table.user_form tr td input[type=text],
div.store-details table.user_form tr td select  {
  margin:5px;
  padding: 5px;
  font-size: 12px;
  border: 1px solid rgb(185, 185, 185);
  height: 26px;
  text-indent: 0px;
  color:#757575;
}
div.store-details table.user_form tr td select {
  width: 154px;
  background-color: #ebebeb;
  color:#757575;
}
div.store-details table.user_form tr th label {
  width:120px;
  font-size: 14px;
}
.store-details table tr th label {
  text-align: left;
  color:black !important;
}
.brandName {
  background-color: #ebebeb;
}

div.store-details table.user_form tr th label.zip {
  width:122px;
}

div.store-details table.user_form tr th label {
  vertical-align: middle;
}

div.store-details table.user_form tr th {
  padding-top:0px;
}

div.store-details .device_details {
  margin-top: 23px;
}
div.store-details .deviceDetails_table{
  width: 504px;
}

.search_pos .dropdown-menu a {
  font-size: 11px !important;
  display: flex;
  align-items: center;
  text-transform: initial;
}

.search_pos .dropdown-menu a input{
 margin:-1px 3px 0px 0px !important
}
.storesButtons{
  display: flex;
 }
.stores .removeStoreButton {
  margin:10px 100px 0px;
}
#store-remove-btn {
  margin-top: 26px;
}
.remove-store-dialog {
  height: 60px;
  padding: .4em 1em;
}
.remove-store-dialog .remove-store-dialog-content {
  padding-bottom: 0px;
  padding: .4em 1em;
  font-size:12px;
}
.remove-store-dialog .warning-store-dialog-content {
  color:gray;
  font-size:12px;
  padding-top: 0px;
}

.store-remove-button {
  float: left;
  background-color: #d41b3e;
  border: none;
  color: #FFFFFF;
  cursor: pointer;
  padding: 10px 25px;
  text-indent: 4px;
  font-weight: bold;
  margin-top: 15px;
  font-size: 13px;
}

.stores .settings_table_noStores h3 {
  border: 1px solid #B9B9B9;
  border-radius: 0 0 0 0;
  color: #000;
  font-size: 20px;
  margin: 5px;
  padding: 5px;
}

.stores .scrollLoadingText h4 {
  color: #b5b5b5;
  font-size: 22px;
  margin-bottom: 22px;
  font-weight: bold;
  text-align: center;
}

.settingsErrors{padding-top: 30px;}
.settingsErrors div {
 font-size: 16px;
 padding-bottom: 15px;
 font-weight: bold;
 color: red
}

.settingsErrors ul li {
  font-weight: bold;
  color: red
 }

 .storesTable .__react_component_tooltip.show,
 .target-results-content .__react_component_tooltip.show {
    background: white !important;
    border: 1px solid skyblue;
    box-shadow: 1px 1px 2px 0px lightgray
 }

 .tooltipText span {
   font-size: 14px;
 }
@media screen and (min-width: 100px) and (max-width: 1366px) {
  div.store-details .deviceDetails_table {
    width: 100%;
  }
  .system-settings-container {
    width: 100%;
  }
  .settings-accordion-content {
    padding: 1em 2.2em;
    margin-left: 0px;
    width: 100%;
  }

  .snapshot-table th:not(:first-child), .snapshot-table tbody td:not(:first-child) {
    width: auto!important;
  }
}


@media screen and (max-width: 899px) {
  .stores .theader.clear span {
    font-size: 11px;
  }
  .stores .ctable tr.tdata td {
    font-size: 10px;
  }
  .stores .ctable tr.tdata td .tdBefore a span{
    font-size: 11px;
  }
  .stores a.view_details span {
    font-size: 11px;
  }
  .storesButtons{
    display: flex;
    flex-direction: column;
  }
  .storesButtons div{
    margin-top:5px
  }
  .settings.forms div.storeHeight {
    display: flex;
    align-items: left !important;
  }

}

@media screen and (max-width: 1199px) {
  .stores .theader.clear span {
    font-size: 11px;
  }
  .stores .ctable tr.tdata td {
    font-size: 10px;
  }
  .stores .ctable tr.tdata td .tdBefore a span{
    font-size: 11px;
  }
  .stores a.view_details span {
    font-size: 11px;
  }
  .storesColumns{
    padding: 0px 0px 15px 0px;
    font-size: 11px
  }
  .cstat.deviceStatus span{
    font-size: 11px
   }
   .cstat.deviceStatus{
     font-size: 11px;
     padding-left: 0px
    }
    tr.theader.clear.storesTableHeader th a span {
      font-size: 11px;
      cursor:pointer;
    }
}

@media screen and (min-width: 1200px) {
  .stores .theader.clear span {
      font-size: 16px;
  }
  .stores .ctable tr.tdata td {
      font-size: 14px;
  }
  .stores a.view_details span {
      font-size: 14px;
  }
  .storesColumns{
    padding: 0px 0px 15px 0px;
    font-size: 14px
  }
  .cstat.deviceStatus span{
    font-size: 14px
   }
   .cstat.deviceStatus{
     font-size: 14px;
     padding-left: 0px
    }
    tr.theader.clear.storesTableHeader th a span {
      font-size: 16px;
      cursor:pointer;
    }
}
@media screen and (max-width: 640px) {
  .tab-content .storesTable {
    min-width: 250px;
  }
}
@media screen and (max-width: 600px) {
  .stores .forms h3 {
    margin-bottom: 0;
  }
  .settings {
    margin-bottom: 0px;
}
.storesTitle {
  min-width: 100%;
  }
  .bulkAction-container {
    margin: 0;
  }
  .storesHeaderSections {
    width: 100%;
    height: auto;
    margin-bottom: 0em;
  }
  .search_pos {
    float:  left !important;
  }
}
.popup{
  border: 1px solid #aed0ea;
  background: #F2F5F7;
  width: 485px !important;
  text-align: left;
  padding-top: 10px;
  font-size:13px;
  border-radius: 10px;
}
.warningMsg{
   color:gray;
}
.react-confirm-alert-overlay{
  background: rgba(0,0,0,0.5) !important;
}
.popup_Title {
  float: left;
  white-space: nowrap;
  width: 472px !important;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  padding: .2em 0.3em;
  position: relative;
  border: 1px solid #aed0ea;
  font-size: 14px;
  font-weight: bold;
  border-radius: 10px;
  background: #FAFBFC;
  margin-top: -7px;
  margin-left: 2px;
  padding-left: 10px;
  font-size: 13px;
}
.popup_message{
  text-align: left;
  padding-left: 3px;
  font-size:13px;
  padding-left: 10px;
}
.warning{
   color: red;
   font-weight: bold;
}
.Popup_message{
  padding-left: 10px;
    width: 99%;
}
.popup_buttons{
  margin-top: 1.5em;
  padding-left: 10px;
  border-top: 1px solid #DDDDDD;
  padding-top: 10px;
  font-size: 13px;
}
.buttonSet{
  float: right;
  display: flex !important;
}
.popup_buttons{
  margin: .5em .4em .5em 0;
  cursor: pointer;
  color: #2779aa;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
}
.buttons{
  background: #d7ebf9;
  text-align: center;
  vertical-align: top;
  border: 1px solid #aed0ea;
  border-radius: 6px;
  padding-top: 3px;
  padding-left: 10px;
  padding-right: 10px;
}
.buttons:hover{
  background: #FAFBFC;
}
.successMsg{
   color:green
}
.no-click {
  pointer-events: none;
  cursor: pointer;
}
.no-click:hover {
  text-decoration: none;
  cursor: pointer;
}
.popup-button {
  margin-left: 235px  !important;
}

div.adminPopUp #store-tabs table.user_form {
  display: flex;
  justify-content: center;
}

div.adminPopUp #store-tabs table.user_form th>label {
  display: flex;
  justify-content: flex-end;
  width: auto;
}

.red-font-text {
  color: #ff0000;
}

div.store-details table.user_form tr th label.rightPadding {
  padding-right: 10px;
}

div.adminPopUp #store-tabs table.user_form th>label.rightPadding {
  padding-right: 10px;
}
