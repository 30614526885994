.hme-dxs-feed-modal-dialog {
  .hme-label-error {
    margin-left: 26px;
  }

  .hme-input-auto-complete-variant {
    .hme-auto-complete-suggestions {
      min-width: 456px;
      max-height: 300px;
      overflow: auto;

      .hme-auto-complete-suggestions-loader {
        display: flex;
        flex-direction: column;

        .hme-loader-icon {
          margin: 20px auto;
        }

        .hme-label {
          text-align: center;
        }
      }
    }
  }
}
