@import "src/mixins/breakpoints.scss";

@include before-desktop-only {
  .new-vaio-provider {
    .new-vaio-provider__content {
      padding-left: 25px;
      .hme-title {
        height: 26px;
        font-size: 20px;
        font-weight: 600;
      }

      .new-vaio-provider-general {
        width: 50%;
      }

      .new-vaio-provider-details {
        width: 50%;
        padding: 0 0 0 24px;

        .port-section {
          margin-bottom: 20px;
        }

        .bot-section {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 195px;
        }

        .vaio-provider-form-add-margin-top {
          margin-top: 20px;
        }

        .vaio-provider-form-short-input {
          width: 100%;
        }

        .vaio-provider-form-short-input-second-element {
          margin-left: 0;
        }
      }
    }
    .new-vaio-provider__footer {
      display: flex;
      padding-right: 20px;
      justify-content: flex-end;
    }
    .hme-sidebar-overlay__sidebar--active {
      width: 50%;
    }
  }
}
