.input {
  width: 100%;
  height: 45px;
  padding: 0 1rem;
  margin-top: 1rem;
  box-sizing: border-box;
  font: inherit;
  border-radius: 0.2rem;
  border: 2px solid #d4d5d6;
  color: #565656;
  -webkit-appearance: none;
}

.input:focus {
  border-color: cornflowerblue;
  outline: none;
}

.error {
  margin: 0;
  padding: 0;
  margin-left: 10px;
  margin-top: 5px;
}

.tag-item {
  background-color: rgba(98, 142, 237, 0.651);
  display: inline-block;
  font-size: 14px;
  border-radius: 30px;
  padding: 4px 15px;
  display: inline-flex;
  align-items: center;
  margin: 0 0.3rem 0.3rem 0;
}

.tag-item > .button {
  background-color: white;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font: inherit;
  margin-left: 10px;
  font-weight: bold;
  padding: 7px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrap-text{
  text-wrap: wrap;
  word-break: break-all;
}
.clearance{
  margin-top: 5px;
}