/* Customize the label*/
.hme-checkbox {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  top: 3px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/* Hide the browser's default checkbox */
.hme-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0; }

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  border: 1px solid #a7adba;
  background-color: #ffffff; }

/* On mouse-over, add a grey background color */
.hme-checkbox:hover input ~ .checkmark {
  background-color: #e8e9ed; }

/* When the checkbox is checked, add a blue background */
.hme-checkbox input:checked ~ .checkmark {
  background-color: #186df4;
  border: 0; }

.hme-checkbox input:checked ~ .dash {
  display: none; }

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none; }

/* Show the checkmark when checked */
.hme-checkbox input:checked ~ .checkmark:after {
  display: block; }

/* Style the checkmark/indicator */
.hme-checkbox .checkmark:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }

.hme-checkbox .dash {
  display: none; }

th .hme-checkbox {
  top: -1px; }

.hme-checkbox.partially-selected .dash {
  display: block; }

.dash:after {
  content: "";
  position: absolute;
  display: none; }

.hme-checkbox.partially-selected .dash:after {
  left: 2px;
  top: 6px;
  width: 11px;
  height: 10px;
  border: 2px solid #186df4;
  border-width: 2px 0 0 0;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  display: block; }
