.view-device-upgrade-header {
    .hme-back-link {
        margin-bottom: 11px;
    }

    &-row {
        display: flex;
        justify-content: space-between;
    }
}
