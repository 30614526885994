.hme-input-auto-complete-variant {
    position: relative;

    .hme-auto-complete-suggestions {
        position: absolute;
        top: 58px;
        left: 0;
        z-index: 10;
        padding: 8px 0;
        border-radius: 8px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
        background-color: #ffffff;
    }
}