@import "../../../../../../assets/styles/Variables";

.hme-history-tiles-header { 
    & > .hme-label {
        margin: 10px 15px;
    }
    & > .hme-history-header-container {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        & > .hme-tile-rounded-button-component {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border-radius: 20px;
            border: solid 1px $main-color;
            background-color: $main-white;
            background-image: unset;

            font-family: Assistant;
            font-size: 12px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: right;
            color: $main-gray;
            padding: 7px 15px;
            cursor: pointer;
            user-select: none;
            margin: 0 15px;

            &.hme-submit-button-component {
                background-color: $hme-blue-color;
                color: white;
                & > svg {
                    color: white;
                }
            }
        }
    }
    & > .hme-history-header-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 15px;
    min-width: 340px;
    color: $main-black;
        & > .hme-history-header-filters-counter {
            & > strong {
                color: $main-color;
            }
        }
        & > .hme-history-header-reset {
            font-family: Assistant;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.31;
            letter-spacing: 1.28px;
            text-align: left;
            color: $main-color;
            text-decoration: underline;
            cursor: pointer;
            &.history-reset-filters {
                margin: 0 7px 0 0;
            }
            &.history-reset-sorts {
                margin: 0 0 0 7px;
            }
        }
    }
}