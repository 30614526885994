.hme-view-store-settings-task-info {
    display: flex;
    width: 100%;

    .hme-view-store-settings-task-info-block {
        width: 370px;
        flex: none;
    }

    .hme-view-store-settings-task-info-devices {
        flex: 1 1 100%;
    }
}