.firmware-management {
    &__footer {
        z-index: 1;
        position: relative;
        text-align: right;
        padding: 35px 25px 35px 50px;
        background-color: #ffffff;
        margin-left: -50px;
        margin-right: -25px;
        border-top: 1px solid var(--gray-37);

        @media screen and (max-width: 1173px) {
            padding-left: 65px;
            padding-right: 40px;
            margin-left: -65px;
            margin-right: -40px;
        }
    }
}
