.radio-input {
  display: flex;
  align-items: center;
}
.radio-input input {
  min-width: 15px;
}
.radio-input label {
  margin: 0;
  margin-left: 0.5em;
}
