@import 'src/assets/styles/Variables';

.hme-dateselector {
    &--with-reset {
        display: flex;

        .hme-button-component {
            margin: 0 15px 0 25px;
        }
    }

    &__readonly-value {
        font-family: $font-family;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1.44px;
        color: $main-black;
    }
}

.hme-input-date-picker-variant {
    position: relative;

    .hme-calendar-selector {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 10;
    }

    .input-dateselector-icon {
        position: absolute;
        top: 14px;
        left: 14px;
        width: 20px;
        height: 20px;
        color: #606672;
    }

    &.hme-input-container {
        .hme-label {
            left: 47px;
        }

        .hme-input-component {
            padding-left: 44px;

            &:focus {
                padding-left: 43px;
            }
        }
    }
}