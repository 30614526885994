.smacktalk-categories-carousel-slide {
    user-select: none;
    cursor: pointer;
    flex: 1;
    min-width: 25%;
    & > img {
        margin: 0 0 6px 0;
    }
    & > .smacktalk-categories-carousel-slide-title {
        width: calc(100% - 4px);
        height: 40px;
        margin: 5.6px 2px 0 2px;
        padding: 12px 1px 13px 1px;
        border: solid 1px #c7ccd6;
        background-image: linear-gradient(to bottom, #ffffff, #ccd3e2);
        font-family: Assistant;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: 0.24px;
        text-align: center;
        color: #000819;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    &--active > .smacktalk-categories-carousel-slide-title  {
        background-image: linear-gradient(to bottom, #2c6cf3, #1840ba);
        color: white;
    }

    &:first-child > .smacktalk-categories-carousel-slide-title {
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
    }
    &:last-child > .smacktalk-categories-carousel-slide-title {
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
    }
}