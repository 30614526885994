.hme-toggle {
    .hme-label {
        width: 100%;
        margin-bottom: 8px;
    }

    .toggle-button-group {
        width: 100%;
        display: flex;
        
        .hme-button-component {
            flex: 1;
            font-size: 14px;
            line-height: 1.29;
            letter-spacing: 0.28px;
            margin-right: 2px;
            border-radius: 0;
            padding-left: 0;
            padding-right: 0;

            &:last-child {
                border-top-right-radius: 24px;
                border-bottom-right-radius: 24px;
            }

            &:first-child {
                border-top-left-radius: 24px;
                border-bottom-left-radius: 24px;
            }
        }
    }
}

@import './variants/rounded.scss';
@import './variants/white.scss';
@import './variants/inline-label.scss';
