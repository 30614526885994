@import 'src/mixins/breakpoints.scss';

.hme-tile-list-item__wrapper {
  display: flex;
  flex-direction: column;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(to bottom, #fff, #fff 52%, #f2f2f2);

  &:last-child {
    margin-bottom: 0;
  }

  .hme-button-component {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 15px;
  }

  .hme-tile-list-item__rows__wrapper {
    display: flex;
    flex-direction: column;

    .hme-tile-list-item__rows__column {
      flex: 1 1;
      min-width: 0;

      .hme-tile-list__row {
        display: flex;
        font-family: Assistant, sans-serif;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        text-align: left;

        .hme-tile-list__row__name {
          font-size: 12px;
          line-height: 2;
          letter-spacing: 1.08px;
          color: #606672;
          text-transform: uppercase;
          flex: 0.4;
          margin-right: 10px;
        }

        .hme-tile-list__row__value {
          font-size: 14px;
          line-height: 1.71;
          letter-spacing: 0.27px;
          color: #000819;
          flex: 0.6;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    &.hme-tile-item-disabled {
        cursor: default;

        .hme-tile-list-item__rows__column {
            .hme-tile-list__row {
                .hme-tile-list__row__name,
                .hme-tile-list__row__value {
                    color: #a7adba;
                }
            }
        }        
    }
  }

  .hme-tile-list-item__toggle-head {
    display: flex;
    justify-content: flex-end;

    .hme-tile-list--expand-icon {
        &.up {
          transform: rotate(180deg);
        }
      }
  }

  &.hme-tile-item-selected {
    border: 2px solid #0074ef;
  }
}

@include tablet-only {
  .hme-tile-list-item__wrapper {
    .hme-tile-list-item__rows__wrapper {
      flex-direction: row;

      .hme-tile-list-item__rows__column {
        display: flex;
        flex-direction: column;
        border-left: solid 1px #e5e5e5;
        padding: 0 15px;

        &:first-child {
          border-left: 0;
          padding-left: 0;
        }
      }
    }
  }
}
