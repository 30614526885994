@import 'src/assets/styles/Variables';

.hme-contest-view-edit-form {
    --gap: 40px;
    --fields-gap: 25px;

    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: var(--gap);
    gap: var(--gap);

    &__content {
        display: flex;
        gap: var(--gap);
    }

    &__section {
        display: flex;
        flex-direction: column;
        gap: var(--fields-gap);
        flex-grow: 1;
        height: auto;
        min-height: 950px;
        padding-right: 0;

        &:not(:first-of-type) {
            padding-left: var(--gap);
        }

        .hme-title {
            margin-bottom: 0;
        }
    }

    .hme-label-error {
        font-family: $font-family;
        font-size: 14px;
    }

    &__section--limited-width {
        max-width: 345px;
        width: 100%;
        box-sizing: content-box;
    }

    .hme-input-container .hme-remaining-count {
        position: absolute;
        left: 0;
        bottom: -20px;
    }

    .hme-timezone-select .hme-single-select__box__text-desktop {
        margin-top: 3px;

        font-size: 14px;
    }

    &__tree-wrapper {
        --border-styles: 1px solid #a7adba;
        --border-radius: 24px;

        position: relative;
    }

    &__tree-header-block {
        position: absolute;
        top: 0;
        z-index: 1;

        width: 100%;
        padding: 20px 48px;

        border: var(--border-styles);
        border-radius: var(--border-radius);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        .hme-checkbox-component .hme-checkbox-label {
            font-size: 16px;
        }
    }

    &__loader-backdrop {
        position: absolute;
        inset: 0;

        display: flex;
        justify-content: center;
        align-items: center;

        background-color: rgba(0, 0, 0, 0.3);
    }

    &__loader-wrapper {
        padding: 40px;

        background-color: $main-white;
    }

    .hme-tree-input .hme-tree-input__box {
        min-height: 425px;
    }

    .hme-tree-input .hme-tree,
    .hme-tree-input__box__no-stores {
        position: relative;
        top: 72px;
    }

    .hme-tree-input .hme-tree {
        max-height: 330px;
    }

    .hme-info-box.hme-info-box--info .hme-info-box__content {
        flex-grow: 1;
        margin: 0;

        font-size: 18px;
    }

    .hme-info-box-message {
        display: flex;        
    }

    .hme-input-container .input-component-from-date-picker {
        left: 47px;
        top: 7px;

        margin-left: 0;
    }

    .hme-input-date-picker-variant.hme-input-container .hme-label {
        left: 56px;
    }
    
    &__footer {
        &--readonly .hme-submit-button-component {
            display: none;
        }
    }
}

@import 'Tablet.scss';
@import 'Mobile.scss';
