.new-vaio-provider {
    .new-vaio-provider-general {
        padding: 0 3vw 40px 0;
        overflow-y: visible;
    }


    .new-vaio-provider-general {
        .hme-input-container, .vaio-provider-form-label {
            margin-top: 20px;
        }

        .hme-input-container.vaio-partner-selector {
            margin-top: 0;
        }

        .new-vaio-provider-email-autocomplete {
            margin-top: 0;
        }

        .hme-api-management-input {
            margin-top: 20px;
        }

        .vaio-provider-form-label, .hme-label {
            margin-bottom: 4px;
            display: block;
        }

        .hme-title {
            margin-bottom: 0px;
            font-weight: bold;
        }

        .hme-select-without-label {
            margin-left: 16px;

            .hme-select-required {
                margin-right: 3px;
                font-weight: normal;
            }
        }

        .new-vaio-provider-date-picker {
            .hme-input-component {
                padding-left: 52px;
            }
        }
    }
    
    .hme-onoff-slider-component {
        .hme-checkbox-slider-label-text {
            padding-left: 8px;
            text-transform: uppercase;
        }
    }
}