.hme-input-success-variant {
    .hme-input-component {
        border: solid 2px #5db431;
        padding: 0 47px 3px 24px;

        &:focus {
            border: solid 2px #5db431;
            padding: 0 47px 3px 24px;
        }
    }

    .success-input-icon {
        position: absolute;
        top: 11px;
        right: 22px;
        width: 25px;
        height: 25px;
        color: #5db431;
    }
}
