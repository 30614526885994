.apply-settings-public-wrapper {
    height: 100%;
}

.apply-settings-public-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;

    .apply-settings-panel {
        overflow: auto;
        height: 100%;
        padding: 0 48px;
        display: flex;
        flex-direction: column;

        .create-apply-device-settings-task-sections {    
            display: flex;
            flex: 1 1 100%;
            height: 100%;
            min-height: 620px;
        }
    }

    .cancel-and-go-back-to-all-jobs-label {
        width: 206px;
        height: 21px;
        font-family: Assistant;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.13;
        letter-spacing: normal;
        text-align: left;
        color: #0074ef;
        margin-bottom: 25px;
    }

    .apply-settings-public-left-section {
        position: relative;
        padding-right: 20px;
        padding-left: 0;
        overflow-y: visible;
        flex: none;
        width: 350px;

        .hme-input-auto-complete-variant {
            width: 330px;
        }
    }

    .apply-settings-public-right-section {
        min-width: 600px;
        flex: 1;
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        .hme-paginate {
            margin-top: 20px;
        }
    }

    .source-type-toggle {
        margin-bottom: 30px;
    }

    .hme-section-title {
        margin-top: 25px;
    }

    .target-device-table {
        overflow-y: scroll;
        overflow-x: scroll;
        height: 400px;
    }
}

@import './Mobile.scss';
@import './Tablet.scss';