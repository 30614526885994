.hme-create-contest {
    .hme-page-content {
        width: 100% !important;
    }
}

.create-contest {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;

    .hme-save-footer {
        position: sticky;
        bottom: 0;
        z-index: 2;
    }
}

@import 'Tablet.scss';
