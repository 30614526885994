.hme-target-device-list {
    overflow: auto;
    height: 100%;
    display: flex;
    flex-direction: column;

    &__target-count {
        font-size: 30px;
        font-weight: bold;
        letter-spacing: 0.03px;
        color: #606672;
        margin: 6px 54px 0 0;
    }

    &__target-count-label {
        margin-left: 8px;
        font-family: Assistant;
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 0.02px;
        text-align: left;
        color: #000819;
    }

    .hme-grid {
        margin-top: 20px;
        overflow: auto;
        flex: 1 1;
    }

    &__filters-row {
        display: flex;
        justify-content: space-between;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        background-color: #ffffff;
        z-index: 1;

        .hme-input-container {
            width: 283px;
        }
    }

    .apply-device-settings__settings__version__header {
        .hme-grid-filter-cell-popup {
            left: unset;
            right: 0;
        }
    }
}
