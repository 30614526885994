@import 'src/mixins/breakpoints.scss';

.hme-language-select {
    .hme-label {
        margin-right: 8px;
        margin-top: 13px;

        &:before {
            display: none;
        }
    }

    .hme-language-select__label__icon {
        width: 19px;
        height: 19px;
        color: #606672;
        margin-right: 10px;
        margin-top: -1px;
    }

    .hme-select {
        max-width: 280px;
        min-width: max-content;
        padding-left: 25px;
        padding-top: 14px;
        padding-bottom: 11px;

        .hme-select-text {
            margin: 0;
        }

        &.hme-select-opened {
            padding-top: 13px;
            padding-left: 24px;
        }
    }

    @include before-desktop-only {
        & {
            display: flex;

            .hme-label {
                flex: 0;
                margin-right: 8px;
            }

            .hme-language-select__label__text {
                display: none;
            }

            .hme-language-select__label__icon {
                margin-right: 0;
            }

            .hme-select {
                flex: 1;
                width: auto;
                max-width: unset;
                display: inline-block;
            }
        }
    }
}
