.dxs-feeds-header {
  padding: 0 0 23px 0;
  display: flex;
  vertical-align: middle;
  justify-content: space-between;
  margin: 60px 0 40px;

  .dxs-feeds-header-title-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
  }

  .dxs-feeds-header-link {
    display: flex;
    align-items: center;
  }
  

  .hme-submit-button-component {
    width: 104px;
    height: 48px;
    border: 1px;
    margin: 0;
    border-radius: 0 24px 24px 0;
  }

  .hme-title {
    display: inline-block;
    flex: none;
    line-height: 1.5;
    margin-right: 20px;
  }

  .hme-input-search-variant {
    width: 350px;
    margin-right: 20px;
  }

  .dxs_feeds__account-search {
    position: relative;
  }

  .hme-auto-complete-suggestions {
    position: absolute;
    top: 58px;
    left: 0;
    z-index: 10;
    border-radius: 8px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    width: 350px;
    max-height: 200px;
    overflow-y: scroll;

    .hme-grid-header {
        display: none;
    }
  }
  .hme-auto-complete-suggestions-label {
    margin-top: 8px;
  }

  .hme-merge-devices-device-grid-no-stores {
    .hme-grid-header {
      display: none;
    }
  }
}
