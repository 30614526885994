.hme-header-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 51px 0 25px 0;
    user-select: none;
    & > .hme-header.hme-header--normal {
        margin: 0;
        font-family: Assistant;
        font-size: 31px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.32;
        letter-spacing: -0.43px;
        text-align: left;
        color: #000819;
    }
    & > .awards-resettable-tabs {
        display: flex;
        flex-direction: row;
        margin: 0;
        padding-left: 40px;
        & > li {
            display: flex;
            cursor: pointer;
            height: 21px;
            padding: 0 27px 0 0;
            border-right: solid 1px #d1cdcd;
            &:last-child {
                border: none;
            }
            &:first-child {
                & > .awards-resettable-icon {
                    margin: 0 8px 0 0;
                }
            }
            &:nth-child(1) > .headerMenu {
                min-width: 125px;
            }
            &:nth-child(2) > .headerMenu {
                min-width: 100px;
            }
            &:nth-child(3) > .headerMenu {
                min-width: 100px;
            }
            &:nth-child(4) > .headerMenu {
                min-width: 90px;
            }
            &:nth-child(5) > .headerMenu {
                min-width: 137px;
            }
            & > .awards-resettable-icon, & > span > .hme-enhanced-filter-icon {
                width: 20px;
                height: 20px;
                color: #0074ef;
                margin: 0 8px 0 26px;
            }

            & > .headerMenu {
                height: 21px;
                margin: 0;
                font-family: Assistant;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.13;
                letter-spacing: normal;
                text-align: left;
                color: #0074ef;
                &:hover {
                    font-weight: 600;
                }

            }
            &.loading {
                cursor: progress;
                & > .headerMenu {
                    &:hover {
                        font-weight: normal;
                    }
                }
            }
        }

    }

}
