.hme-row-title {
    font-family: Assistant;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 1.08px;
    text-align: left;
    color: #606672;
}
