.add-store-device__header {
    padding-bottom: 15px;
    display: flex;
    vertical-align: middle;
    justify-content: space-between;
    
    .add-store-device__header-title-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
    }

    .hme-submit-button-component {
        width: 104px;
        height: 48px;
        border: 1px;
        margin: 0;
        border-radius: 0 24px 24px 0;
      }

    .hme-search-optionable {
        padding: 12px 30px 20px 30px;
        margin: 25px 30px 0 30px;
        border-left: 1px solid #e5e5e5;
        border-right: 1px solid #e5e5e5;
        height: 75%;

        .hme-select {
            width: 224px;
            min-width: 224px;
        }

        .hme-input-container {
            width: 300px;
        }
    }   
    .add-store-device__header-filters-count {
        padding-top: 12px;
    }
}
