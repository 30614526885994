.systemStatus_online_normal {
  color: #d41b3e !important;
}
.device_details {
    float: right;
    margin-top:-9px;
    margin-bottom: 10px;
}
.deviceDetails_table{
    width: 50%;
}
.img_padding {
    padding-right: 4px;
  }
.viewDetials a:hover {
    color: #d41b3e !important;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}
.viewDetials {
    color: #D41B3E !important;
    font-size: 14px !important;
    font-weight: bold;
    cursor: pointer;
}
.systemStatus_online {
    color: #d41b3e !important;
    font-weight: bold;
    cursor: pointer;
}
.systemStatus_online a:hover{
    color: #d41b3e !important;
    text-decoration: underline;
    cursor: pointer;
    font-weight: bold;
}
.systemStatus_offline {
    color:black !important;
    pointer-events: none;
  }
  .systemStatus_offline a:hover{
    color: black !important;
    text-decoration: underline;
    pointer-events: none;
  }

  .viewDetailsSection{
    display: flex;
    flex-direction: column;
  }

  div.store-details .device_details
  {
      float: left;
  }
  .systemstatus_table .tdata:hover{
    background-color:white;
    border: none;
  }
  .systemstatus_table .tdata td {
    border-bottom-width: 0;
  }
  .systemstatus_table {
    width: 680px;
  }
  .device_details .RegisterSystemTableHeader th{
      font-size: 16px;
      max-width: 99px;
      overflow-wrap: break-word;
  }
  .viewDetailsHeader{
      max-width: 64px;
  }

  .systemStatusHeader {
    min-width: 88px;
  }

  .settings-accordion-item div[aria-selected='true'] h3 {
      background: #3baae3 url(../images/ui-bg_glass_50_3baae3_1x400.png) 50% 50% repeat-x;
      font-weight: bold;
  }

  .select-upgrade {
      border: 0px;
      width:100%;
      background-color: transparent;
      outline: 0px;
      color: #337ab7;
  }

.systemStatusBlack {
  color: black;
}
.no-access-error h4 {
  color: black !important;
}
.no-access-error-link {
  font-size: unset!important;
  text-decoration: underline;
}
