@import 'src/mixins/breakpoints.scss';

@include before-desktop-only {
  .hme-device-settings-snapshots__name-tooltip {
    &__tip {
      max-width: 760px;
      overflow: scroll;
      height: 100%;
    }
  }
}
