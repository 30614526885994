@import 'src/mixins/breakpoints.scss';

@include before-desktop-only {
    .hme-account-info-section {
        max-width: none;
        padding: 0;
        border: none;

        &:first-of-type {
            padding: 0;
        }

        .hme-title {
            margin-bottom: 15px;

            font-size: 18px;
        }
    }
}
