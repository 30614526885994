.hme-grid-row {
    width: 100%;
    display: flex;
    background-color: #ffffff;
    padding-left: 20px;
    padding-right: 15px;

    .hme-grid-expanded-content & {
        background-color: transparent;
    }

    &.hme-grid-row-odd {
        .hme-grid-expanded-content & {
            background-color: transparent;
        }

        background-color: #f3f3f5;
    }

    &.hme-grid-row-hovered {
        background-color: #E8F0FE;
    }

    &.hme-grid-row-clickable {
        cursor: pointer;
    }

    &.hme-grid-row-message {
        &.hme-grid-row-hovered {
            background-color: transparent;
        }

        .hme-grid-cell {
            text-align: center;
        }
    }

    &.hme-grid-expanded-row {
        .hme-grid-expanded-content .hme-grid .hme-grid-row:not(.hme-grid-header-row) .hme-grid-cell {
            border-top: solid 1px #c7ccd6;

            &.hme-grid-empty-cell {
                border-top: none;
            }
        }
    }

    &.hme-grid-row-disabled {
        opacity: 0.5;
        cursor: default;
    }

    &.hme-grid-row-red-background {
        background-color: rgb(255, 227, 227);
        border-bottom: 1px solid rgb(211, 211, 211);
    
    }

    &.hme-grid-row-yellow-background {
        background-color: rgb(255, 255, 243);
        border-bottom: 1px solid rgb(211, 211, 211);
    }
}




