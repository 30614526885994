@import 'src/mixins/breakpoints.scss';

.hme-company-info {
    .company-info {
        .company-info-form-hme-details-section {
            width: 405px;

            // TODO: Remove after single select will support it
            @include before-desktop-only {
                & .hme-single-select {
                    .hme-label {
                        position: static;
                    }
                }
            }
        }
    }
}
