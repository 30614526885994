@import '../../../../mixins/breakpoints.scss';

@include before-desktop-only {
    .hme-manage-hierarchy__file-manage {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 24px;

        .hme-manage-hierarchy__file-manage__export {
            flex: 1;
        }

        .hme-separator {
            height: 100px;
            display: block;
        }

        .hme-manage-hierarchy__file-manage__import {
            flex: 1;
        }
    }
}

@include tablet-only {
    .hme-manage-hierarchy__file-manage {
        .hme-manage-hierarchy__file-manage__export {
            padding-bottom: 0;
            border-bottom: none;
            margin-bottom: 0;
        }
    }
}
