@import 'mixins/breakpoints.scss';

@include mobile-only {
    .hme-notifications-center__header {
        .hme-title {
            font-size: 20px;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.28px;
        }
    }
}