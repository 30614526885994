.merge-devices__store-devices-results .hme-grid-header-row {
  padding-left: 8px; }

.merge-devices__store-devices-results .hme-merge-devices-device-grid-no-stores .hme-grid-header {
  display: none; }

.merge-devices__store-devices-results .hme-grid-header-row .hme-checkbox-component {
  display: none; }

.merge-devices__store-devices-results .hme-merge-devices-device-grid-select-header {
  padding-right: 12px; }

.merge-devices__store-devices-results .hme-grid-cell .hme-grid-cell-content {
  word-break: break-all; }

.merge-devices__store-devices-results .hme-grid .hme-radio .hme-label {
  display: none; }
