@import 'src/mixins/breakpoints.scss';

@include before-desktop-only {
    .hme-search-optionable {
        width: 100%;
        flex-wrap: wrap;

        .hme-select {
            flex-basis: 100%;
            margin-right: 0;
            margin-bottom: 15px;
        }

        div.hme-input-container {
            width: unset;
            flex: 1;
        }
    }
}
