@import 'src/mixins/breakpoints.scss';
@import 'src/assets/styles/_Variables.scss';

.hme-select {
    width: 100%;
    height: 48px;
    border-radius: 24px;
    border: solid 1px #a7adba;
    background-color: #ffffff;
    position: relative;
    padding-right: 52px;
    cursor: pointer;

  &.hme-select--required {
    div.hme-label {
      &:before {
        position: absolute;
        left: -9px;
        top: 2px;
        content: '*';
        font-family: Assistant;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 1.44px;
        text-align: left;
        color: #186df4;
      }
    }
  }
  div.hme-label {
        margin-left: 25px;
        margin-top: 3px;
        font-size: 12px;
        line-height: 1.33;
        letter-spacing: 1.08px;
        color: #606672;
        position: relative;
        text-transform: none;
    }

    .hme-select-text {
        font-family: Assistant;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.31;
        letter-spacing: 1.44px;
        text-align: left;
        color: #000819;
        margin-left: 25px;
        margin-top: 2px;
    }

    .hme-select-required {
        margin: 22px 2px 0 0;
        font-family: Assistant;
        font-size: 16px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 1.44px;
        text-align: left;
        color: #186df4;
        height: 15px;
        margin-top: 30px;
    }

    .hme-select-without-label {
        font-family: Assistant;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.31;
        letter-spacing: 1.44px;
        text-align: left;
        color: #000819;
        margin-left: 25px;
        margin-top: 14px;
    }

    .hme-select-icon {
        position: absolute;
        right: 6px;
        top: 10px;
        width: 42px;
        height: 24px;
        color: #606672;
    }

    .hme-select-dropdown {
        overflow-y: scroll;
        &::-webkit-scrollbar-thumb {
            background-color: #a7adba;
            border-radius: 4px;
        }
        &::-webkit-scrollbar {
            width: 6px;
            height: 15px;
        }
        list-style-type: none;
        position: absolute;
        top: 100%;
        left: 10px;
        right: 10px;
        margin-top: 8px;
        border-radius: 8px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
        background-color: #ffffff;
        padding: 8px 0;
        display: none;
        overflow: auto;
        max-height: 200px;
        z-index: 100;

        .hme-select-dropdown-item {
            width: 100%;
            min-height: 40px;
            font-family: Assistant;
            padding: 10px 15px;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.31;
            letter-spacing: 1.28px;
            text-align: left;
            color: #000819;
            cursor: pointer;

            &:hover {
                background-color: #2c6df4;
                font-weight: 600;
                color: #ffffff;
            }
        }
    }

    &.hme-select-opened {
        border: 2px solid #0074ef;

        div.hme-label {
            margin-left: 24px;
            margin-top: 2px;
        }

        .hme-select-text {
            margin-left: 24px;
        }

        .hme-select-dropdown {
            display: block;
        }

        .hme-select-icon {
            transform: rotate(180deg);
            color: #0074ef;
        }
    }

    .hme-select__mobile {
        display: none;
    }

    &.hme-select-no-label-variant {
        .hme-select-text {
            margin-left: 25px;
            margin-top: 14px;
        }

        &.hme-select-opened {
            .hme-select-text {
                margin-top: 13px;
                margin-left: 24px;
            }
        }
    }

  &.hme-select--disabled {
    background: $input-disabled-bg;
    cursor: default;
  }

    @include before-desktop-only {
        & {
            display: flex;
            overflow: hidden;
            flex-direction: column;

            .hme-select__mobile {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                opacity: 0;
                width: 100%;
            }

            .hme-select-dropdown-item {
                padding: 10px;
            }

            .hme-label {
                flex: 0;
            }

            .hme-select-text {
                flex: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
}
