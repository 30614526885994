.hme-header {
  margin: 0.5em 0;
  padding: 0;
}
.hme-header--huge {
  margin-bottom: 1em;
}
.hme-header--secondary {
  background-color: #06B1CB;
  padding: 0.5em;
}
.hme-header--centered {
  text-align: center;
}
.hme-header--bold {
  font-weight: bold;
}
.hme-header--faded {
  color: #757575;
}
.hme-header--uppercase {
  text-transform: uppercase;
}
