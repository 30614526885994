.firmware-management-wrapper {
    height: 100%;
    min-width: 1380px;

    &.loading {
        display: flex;
    }
}

.firmware-management {
    height: 100%;
    display: flex;
    flex-direction: column;

    &__list {
        display: flex;
        flex-direction: column;
        overflow: auto;
        height: 100%;

        &__paginate {
            margin: 0;
            min-width: auto;
            padding: 0 50px;

            &.hme-paginate--hidden {
                display: none;
            }

            .hme-label {
                color: #606672;
                text-transform: none;
                font-weight: normal;
            }

            @media screen and (max-width: 1604px) {
                padding: 0;
            }
        }
    }
}
