.hme-admin-zoom-system-status-device-status {
    display: flex;
    width: 100%;

    .hme-admin-zoom-system-status-info {
        width: 370px;
        flex: none;
        border-right: 1px solid #e8e9ed;
        border-left: 0;
    }

    .hme-admin-zoom-system-status-devices {
        flex: 1 1 100%;
        border-left: 0;
    }

    .hme-admin-zoom-system-status-info > .hme-title,
    .hme-admin-zoom-system-status-devices > .hme-title {
        font-family: Assistant;
        font-size: 18px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: 0.02px;
        color: #606672;
        text-transform: uppercase;
    }

    .hme-section {
        overflow: visible;
    }
}
