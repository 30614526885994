.hme-roles__actions--remove {
    margin-left: 40px;
    position: relative;

    &::before {
        content: " ";
        position: absolute;
        border-left: solid 1px #d1cdcd;
        height: 21px;
        left: -21px;
    }
}

.hme-roles__edit-icon {
    margin-right: 5px;
}

@import './Mobile.scss';
