.hme-tab-panel {
    display: flex;
    flex-direction: column;

    .hme-tab-panel-tabs {
        box-shadow: 0 2px 17px 0 rgba(134, 140, 153, 0.5);
        &.hme-subheader-tab-panel {
            height: 40px;
            background-color: #FFFFFF;
            padding-left: 20px;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .hme-button-component.hme-tab-button-component {
                min-width: 106px;
                display: flex;
                align-items: baseline;
                justify-content: center;
                font-size: 14px;
                padding: 12.5px 12px 0 12px;
                letter-spacing: 1px;
                text-transform: uppercase;

                &:hover {
                    color: #0074ef;
                    border-bottom: #0074ef solid 2px;
                }
            }
        }
    }
}

.hme-button-component.hme-tab-button-component {
    height: 100%;
    border: 0 none;
    border-bottom: transparent solid 2px;
    border-radius: 0;
    background: transparent;
    font-family: Assistant;
    font-size: 15px;
    font-weight: 600;
    line-height: 1.27;
    letter-spacing: 0.35px;
    text-align: left;
    color: #606672;
    text-transform: uppercase;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 6px;
    padding-top: 0;

    &.hme-tab-active-button-component {
        text-align: left;
        color: #0074ef;
        border-bottom: #0074ef solid 2px;
    }
}

@import './variants/transparent.scss';
