@import 'src/assets/styles/Variables';
@import '../../Radio/Radio.scss';

.hme-radio-group-item {
    display: flex;
    align-items: flex-start;
    gap: 10px;

    &__container {
        position: relative;

        width: 16px;
        height: 16px;

        border-radius: 50%;
        vertical-align: middle;
        color: $slider-off-color;
        border: 2px solid currentColor;
        appearance: none;
        background-color: transparent;
    }

    &__container[data-state="checked"] {
        color: $hme-blue-color;
        background-color: currentColor;
    }

    &__container:disabled {
        color: $header-separator-grey;
        opacity: 0.5;
    }

    &__indicator {
        position: absolute;
        top: 50%;
        left: 50%;

        width: 5px;
        height: 5px;

        background-color: $main-white;
        transform: translate(-50%, -50%);
        border-radius: 50%;
    }

    &__label {
        flex-grow: 1;

        font-size: 16px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 1.44px;
        text-align: left;
        color: $main-black;
        cursor: pointer;
    }

    &__container:disabled ~ &__label {
        color: #a7adba;
    }
}
