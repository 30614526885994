.hme-grid-cell {
    flex: 1;
    font-family: Assistant;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #000819;
    padding-top: 21px;
    padding-bottom: 20px;
    overflow: hidden;
    position: relative;

    &.hme-grid-expanded-content {
        padding-bottom: 0;
        padding-top: 0;
        overflow: visible;

        .hme-grid-cell-content {
            padding-left: 0;
            padding-right: 0;
            overflow: visible;

            .hme-grid .hme-grid-cell-content {
                padding-left: 20px;
            }
        }

        .hme-grid-row {
            padding-left: 0;
            padding-right: 0;
        }

        .hme-grid-header-titles-row {
            padding-left: 0;
            padding-right: 0;

            .hme-grid-cell-content {
                padding-top: 15px;
            }
        }

        .hme-grid-header-row:first-child .hme-grid-cell-content {
            padding-top: 15px;
        }
    }

    &:first-of-type {
        .hme-grid-cell-content {
            padding-left: 0;
        }
    }

    &:last-of-type {
        .hme-grid-cell-content {
            padding-right: 0;
        }
    }

    &.hme-grid-cell-last-in-block {
        border-right: solid 1px #e5e5e5;

        &:last-of-type {
            border-right: none;

            .hme-grid-cell-content {
                padding-right: 0;
            }
        }

        .hme-grid-cell-content {
            padding-right: 15px;
        }
    }

    &.hme-grid-cell-show-all-content {
        .hme-grid-cell-content {
            word-break: break-all;
        }
    }

    &.hme-grid-cell-with-outside-content {
        overflow: visible;
        .hme-grid-cell-content {
            overflow: visible;
        }
    }

    .hme-grid-cell-content {
        width: 100%;
        padding-left: 20px;
        box-sizing: border-box;
        white-space: pre-wrap;
        overflow: hidden;
        word-break: normal;
        text-overflow: ellipsis;
    }

    &.hme-grid-action-cell {
        font-family: Assistant;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.13;
        letter-spacing: normal;
        text-align: center;
        color: #0074ef;

        .hme-grid-cell-content {
            cursor: pointer;
        }
    }

    &.hme-grid-centered-cell {
        text-align: center;
    }

    &.hme-grid-cell--visible {
        overflow: visible;

        .hme-grid-cell-content {
            overflow: visible;
        }
    }

    &.hme-grid-right-aligned-cell {
        text-align: right;
    }

    &.hme-grid-filter-align-right {
        .hme-grid-filter-cell-popup {
            left: unset;
            right: 0;
        }
    }
}
.show-overflow {
    overflow: visible !important;
}

.lbgroups-grid-wrapper{

    .hme-grid-cell-content {
        color: black;
        font-size: 16px;
        font-weight: 500;
    }
}