.hme-zoom-settings-info.modal-dialog.hme-modal-component {
    max-width: 467px;

    .modal-header {
        text-align: left;
        padding-left: 20px;
        margin-bottom: 0;

        .hme-title {
            .hme-zoom-settings-info-title-label {
                font-size: 16px;
                font-weight: 600;
                letter-spacing: normal;
                margin-top: -17px;
                display: inline-block;
                vertical-align: middle;
                line-height: 30px;
            }

            .icon-info {
                font-size: 30px;
                margin-right: 10px;
            }
        }
    }

    .modal-body {
        padding: 0 45px 0 60px;
    }

    .hme-list {
        margin-bottom: 20px;
    }
}
