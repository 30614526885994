.hme-schedule-days {
  display: flex;
  justify-content: space-between; }
  .hme-schedule-days .schedule-day {
    border: 1px solid #ccc;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    position: relative; }
    .hme-schedule-days .schedule-day .hme-checkbox-component-icon {
      visibility: hidden; }
    .hme-schedule-days .schedule-day .schedule-day-text {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      text-align: center;
      line-height: 38px;
      margin: 0;
      color: #a7adba;
      text-transform: uppercase; }
    .hme-schedule-days .schedule-day.schedule-day--checked {
      background: #c7ccd6;
      border-color: #c7ccd6; }
      .hme-schedule-days .schedule-day.schedule-day--checked .schedule-day-text {
        color: #888b92; }

.hme-layout-device-upgrade-list-page .hme-schedule-days {
  margin-top: 2px;
  justify-content: flex-start; }
  .hme-layout-device-upgrade-list-page .hme-schedule-days .schedule-day {
    width: 23px;
    height: 23px;
    background: #fff;
    font-weight: 600;
    margin-right: 2px; }
    .hme-layout-device-upgrade-list-page .hme-schedule-days .schedule-day .schedule-day-text {
      font-size: 13px;
      line-height: 21px; }
    .hme-layout-device-upgrade-list-page .hme-schedule-days .schedule-day.schedule-day--checked {
      opacity: 0.75;
      background: #0074ef;
      border-color: #0074ef; }
      .hme-layout-device-upgrade-list-page .hme-schedule-days .schedule-day.schedule-day--checked .schedule-day-text {
        color: #fff; }
