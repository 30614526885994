.hme-accordion {
    .hme-accordion__title {
        display: flex;
    }

    .hme-accordion__title__text {
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: 1.26px;
        color: #000819;
        flex: 1;
    }

    .hme-accordion__title__expand {
        color: #0074ef;
    }

    .hme-expand-icon {
        width: 20px;
        height: 11px;
        overflow: hidden;
        margin-top: 8px;

        .hme-expand-icon__icon {
            transform: none;
            margin-left: -12px;
            margin-top: -14px;
            color: #0074ef;
        }

        &.hme-expand-icon-expanded {
            .hme-expand-icon__icon {
                transform: rotate(180deg);
                margin-left: -3px;
                margin-top: -15px;
            }
        }
    }

    .hme-accordion__content {
        display: none;
    }

    &.hme-accordion--expanded {
        .hme-accordion__title {
            margin-bottom: 14px;
        }

        .hme-accordion__content {
            display: block;
        }
    }
}