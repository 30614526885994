.hme-manage-hierarchy__hierarchy__item {
    padding: 3px 5px;
    margin-top: -3px;
    cursor: pointer;

    &__icon {
        margin-right: 7px;
        width: 21px;
        color: #606672;
        margin-top: -3px;
    }

    .hme-label {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 1.28px;
        color: #000819;
        text-transform: none;
    }

    .hme-manage-hierarchy__hierarchy__item__folder {
        &--opened {
            color: #0074ef;
            margin-right: 6.5px;
        }

        &--closed {
            margin-right: 10px;
        }

        .hme-tree-item--expanded > .hme-tree-item__header > &--closed {
            display: none;
        }

        .hme-tree-item:not(.hme-tree-item--expanded) > .hme-tree-item__header > &--opened {
            display: none;
        }
    }

    &--selected {
        background-color: #0074ef;

        .hme-manage-hierarchy__hierarchy__item__icon {
            color: #ffffff;
        }

        .hme-label {
            color: #ffffff;
        }

        .hme-manage-hierarchy__hierarchy__item__folder--closed {
            color: #0074ef;
        }
    }

    &__search-matched {
        text-decoration: underline;
    }
}
