@import 'src/assets/styles/Variables';

.hme-admin-store-details__wrapper {
  width: 100%;
  height: calc(100% - 80px);

  .hme-admin-store-details {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 50px 50px 0 50px;

    .hme-admin-store-details__main {
      display: flex;

      .hme-admin-store-details__general-info {
        flex: 1;
        padding: 0 32px 30px 0;
        border-right: solid 1px #e8e9ed;
        font-family: $font-family;
        text-align: left;
        min-width: 270px;

        .hme-address {
          margin: 5px 0;
          padding-bottom: 10px;
        }

        .hme-store-details-account-link {
          padding-top: 5px;
          padding-bottom: 5px;
          margin: 5px 0;
          border-top: solid 1px $input-underline;
        }

        .hme-admin-store-details__account-numbers {
          border-top: solid 1px $input-underline;
          margin: 5px 0;
          padding-top: 5px;
        }
      }

      .hme-admin-store-details__central-block {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 0 32px;
        border-right: solid 1px #e8e9ed;
        min-width: 240px;
      }

      .hme-admin-store-details__device-info__block {
        flex: 3;
        padding-left: 30px;

        .hme-view-edit-store__devices-info__grid {
          .hme-grid-row {
            .hme-device-status--preconfigured,
            .hme-device-status--unavailable {
                padding-right: 0;
            }

            .hme-grid-centered-cell-actions {
              .hme-grid-cell-content {
                display: flex;
                justify-content: center;

                .hme-remove-device-button {
                  border-left: solid 1px #d1cdcd;
                }
              }
            }
          }
        }
      }
    }
  }
}

.hme-view-edit-store__add-device-modal {
  min-width: 1150px;
  width: 90%;

  &.hme-modal-component {
    .modal-header {
      text-align: left;
      margin-bottom: 30px;

      .hme-title {
        margin-top: 5px;
      }

      .btn-close {
        margin-right: -10px;
      }
    }
  }

  .modal-content {
    padding: 20px 40px;
  }
}
