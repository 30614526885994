.hme-stores-status--public__grid__inner-cell--serial-number {
    height: 66px;
    line-height: 24px;

    &:first-child {
        height: 45px;
    }

    &:last-child {
        height: 45px;
    }
}