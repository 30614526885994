@import '../../mixins/breakpoints.scss';

.hme-gamification-marketing {
    @include before-desktop-only {
        &__title {
            font-size: 20px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.28px;
            color: #000819;
        }
    }

    @include tablet-only {
        & {
            padding: 32px 15px;
        }
    }

    @include mobile-only {
        & {
            display: block;
            padding: 20px 15px;

            &__info {
                padding-right: 0;
                border-right: none;
                margin-bottom: 30px;
            }

            &__contact {
                width: 100%;
                margin-left: 0;
            }
        }
    }
}
