.firmware-management-view {
    &__section {
        border-left: 1px solid #e5e5e5;
        padding: 0 40px;

        &:first-child {
            border-left: none;
            padding-left: 0;
        }

        .hme-title {
            font-size: 24px;
            font-weight: 600;
            letter-spacing: 0.24px;
            margin-bottom: 24px;
        }

        &__subtitle {
            font-family: Assistant;
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: 1.44px;
            color: #000819;
            margin: 0 0 8px;
            text-transform: uppercase;

            .hme-checkbox-label {
                text-transform: none;
                font-size: 16px;
                line-height: 1.5;
                letter-spacing: 1.28px;
            }
        }

        &__version-list-wrapper {
            border: solid 1px #a7adba;
            border-radius: 24px;
            width: 346px;
            height: 525px;
            padding: 25px 5px 25px 25px;
        }

        &__version {
            padding: 14px 0 13px;
        }

        &__version-list {
            height: 100%;
            overflow: auto;

            .hme-checkbox-label {
                font-family: Assistant;
                font-size: 16px;
                line-height: 1.5;
                letter-spacing: 1.28px;
                color: #000819;
            }
        }
    }
}
