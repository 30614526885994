.hme-grid-filters-count {
    display: flex;
    align-items: center;

    .hme-grid-filters-count-count-number {
        flex: none;
        height: 100%;
        padding-right: 8px;
        font-family: Assistant;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 0.02px;
        color: #60adff;
    }

    .hme-grid-filters-count-label {
        font-family: Assistant;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: 0.02px;
        color: #000819;
        padding-top: 3px;
        text-transform: lowercase;
    }

    .hme-grid-filters-count-content {
        flex: 1;
    }

    &.hme-grid-filters-count-no-filters {
        .hme-grid-filters-count-reset-button {
            display: none;
        }

        .hme-grid-filters-count-count-number {
            color: #a7adba;
        }

        .hme-grid-filters-count-label {
            color: #a7adba;
        }
    }

    &.hme-grid-filters-count-single-line-variant {
        display: inline-block;

        .hme-grid-filters-count-count-number {
            display: inline-block;
            padding-right: 1rem;
            font-size: 30px;
            letter-spacing: 0.03px;
        }

        .hme-grid-filters-count-content {
            display: inline-block;
        }

        .hme-grid-filters-count-label {
            display: inline-block;
            font-size: 24px;
            line-height: 1;
            padding-top: 3px;
            margin-right: 5px;
        }

        .hme-grid-filters-count-reset-button {
            display: inline-block;
        }

        &.hme-grid-filters-count-no-filters {
            .hme-grid-filters-count-reset-button {
                display: none;
            }
        }
    }
}
