.hme-layout-create-device-upgrade-page {
    .deviceUpgrades {
        margin: 0;
    }

    .create-device-upgrade-wrapper {
        height: 100%;
        overflow-y: auto;
        padding-bottom: 82px;
    }

    .create-device-upgrade {
        padding: 12px 48px 0 48px;
        display: flex;
        flex-direction: column;
        overflow: auto;
        height: 100%;
        align-items: stretch;

        .create-device-upgrade-header {
            margin-bottom: 39px;
        }

        .create-device-upgrade-sections {
            display: flex;
            flex: 1;

            .create-device-upgrade-source {
                width: 354px;
                flex: none;
            }

            .create-device-upgrade-filters {
                width: 430px;
                flex: none;
            }

            .create-device-upgrade-target {
                flex: 1;
                min-width: 1000px;
                .hme-loader-icon {
                    margin-top: 100px;
                }
            }
        }
    }

    .Toastify {
        .Toastify__toast--error {
            &.hme-info-message {
                background-color: #186df4 !important;
            }
        }
    }
}

.create-device-upgrade {
    &__install-schedule-message {
        padding-top: 20px;
        font-size: 16px;

        strong {
            font-size: 20px;
        }
    }
}

#create-device-upgrade-deviceCount {
    font-weight: bold;
}