.hme-save-footer {
    display: flex;
    justify-content: flex-end;
    background-color: #ffffff;
    min-height: 80px;
    width: 100%;
    margin: auto 0 0 0;
    padding: 20px 50px 20px 0;
    box-shadow: 0 -10px 20px 0 rgba(0, 0, 0, 0.16);
    text-align: right;

    .hme-button-component {
        margin: 0 0 0 20px;
    }
}
