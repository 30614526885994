@import 'src/assets/styles/Variables';

.create-edit-scheduler {
    display: flex;
    flex-direction: column;

    --sections-gap: 30px;
    --field-gap: 20px;

    --border: 1px solid #{$header-separator-grey};

    & > section {
        overflow-y: auto;
    }

    & > section,
    &__wrapper,
    &__body {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

    &__wrapper {
        position: relative;
    }

    &__header {
        flex-grow: unset;
        margin: 30px 15px;

        .hme-title {
            margin-bottom: 0;
    
            line-height: 1.2;
            text-transform: capitalize;
        }
    }

    &__body {
        gap: 30px;
        padding: 0 30px 80px;
    }

    &__form-wrapper {
        display: flex;
        gap: var(--sections-gap);
    }

    &__form-fieldset {
        display: flex;
        flex-direction: column;
        gap: 20px;

        &:not(:first-of-type) {
            border-left: var(--border);
            padding-left: var(--sections-gap);
        }

        &--1 {
            flex-basis: 20%;
            min-width: 400px;
        }
        &--2 {
            flex-basis: 15%;
            min-width: 300px;
        }
        &--3 {
            flex-grow: 1;
            min-width: 500px;
        }
    }

    &__fieldset-title {
        margin: 0;
        padding: 0;
    }

    &__fields-wrapper {
        display: flex;
        flex-direction: column;
        gap: var(--field-gap);
    }

    &__text-input {
        .hme-remaining-count {
            display: none;
            margin-top: 5px;
        }

        &:focus-within .hme-remaining-count {
            display: block;
        }
    }

    &__field-label,
    &__text-input .hme-label {
        margin-bottom: 8px;
    }

    &__tree-selector-wrapper {
        margin: 0;
    }

    &__select {
        &--top {
            .hme-single-select__dropdown {
                bottom: 100%;
                top: auto;
            }
        }
    }

    &__stores-hierarchy-level {
        .hme-single-select .hme-label {
            display: none;
        }
    }

    &__footer {
        position: sticky;
        bottom: 0;
        z-index: 1;
    }
}

@import './Tablet.scss';
@import './Mobile.scss';
