.hme-layout .public-zoom-nitro-system-status-page {
  &.hmeBody {
    width: 100% !important; // Used in order to override styles of .hmeBody
    overflow-y: auto;
  }
}

body.modal-open {
  div.hme-tooltip {
    &.device-upgrade-tooltip {
      z-index: 100;
    }
  }
}

@import './Tablet.scss';
