.systems-page {
    .systems-list {
        display: flex;
        flex-direction: column;
        overflow: auto;
        height: 100%;
        min-height: 530px;
    }

    .override-country-button {
        color: #0d6efd;

        &:hover {
            cursor: pointer;
        }
    }

    @media screen and (max-height: 960px) {
        .systems-list {
            min-height: 330px;
        }

        .hme-grid-filter-cell-popup {
            .hme-grid-filter-cell-popup-body {
                max-height: 150px;
            }
        }
    }
}

.systems-list-override-country-modal {
    .modal-dialog {
        &.hme-modal-component {
            &.confirm-popup {
                .modal-content{
                    min-height: 200px;
                    &::before  {
                        content: '';
                        padding: 0;
                    }

                    .modal-body {
                        padding: 0 70px 30px 70px;
                        overflow: visible;
                    }

                    .modal-footer {
                        .hme-button-component {
                            min-width: 147px;
                            margin: 0 10px;
                        }
                    }
                }
            }

            .modal-header {
                .hme-title {
                    font-family: Assistant;
                    font-size: 24px;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.33;
                    letter-spacing: 0.24px;
                    text-align: center;
                    color: #000819;
                }
            }
        }
    }

    .hme-systems-override-country {
        width: 347px;
    }
}
