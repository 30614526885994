.hme-manage-hierarchy {
    padding: 35px 35px 35px 50px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    height: 100%;
    overflow: auto;

    .hme-title {
        width: 100%;
        margin-bottom: 17px;
        line-height: 1;
    }

    &__hierarchy {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 20px;
        max-width: 1168px;
        margin-right: 40px;
    }

    &__file-manage {
        margin-top: 23px;
    }
}

@import './Tablet.scss';
