.create-device-upgrade-target {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .upgrade-devices-list {
        flex: 1;
        position: relative;
        padding-bottom: 160px;
    }

    .hme-paginate {
        position: absolute;
        bottom: 0;
        left: 0;

        flex-direction: column;
        align-items: flex-start;
        background-color: #fff;
        margin: 0;
        padding: 15px;
        border-top: solid 1px #e8e9ed;

        .hme-paginate__results {
        order: 1;
        font-size: 13px;

        .hme-label {
            font-family: Assistant, sans-serif;
            font-size: 13px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.31;
            letter-spacing: 1.17px;
            text-align: right;
            color: #606672;
            margin-bottom: 12px;
        }
        }

        .hme-paginate__buttons {
        width: 100%;
        order: 3;

        .paginate-list {
            flex-wrap: wrap;
            flex: 1;
            justify-content: space-between;

            li {
            margin: 5px 0;

            a {
                width: 62px;
                margin: 0 5px 0 0;
            }
            }

            .fa-arrow-right {
            a {
                margin: 0;
            }
            }

            &.hme-paginate__buttons__centred {
            justify-content: center;
            }
        }

        .paginate-list-hidden {
            display: none;
        }
        }

        .hme-paginate__dropdown {
        order: 2;
        margin-bottom: 12px;
        font-family: Assistant, sans-serif;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: 1.26px;
        text-align: left;
        color: #606672;
        }
    }
}