.tasks-table-admin {
    flex: 1;
    overflow: auto;

    .hme-grid-expanded-row .hme-grid-expanded-content .hme-grid .hme-grid-header {
        top: 76px;
        display:block;
    }

    .apply-job-serial-number.hme-grid-cell {
        .hme-grid-cell-content {
            word-break: break-all;
        }
    }

    .apply-job-store-number {
        .hme-grid-cell-content {
            word-break: break-all;
        }
    }

    .apply-job-applied-settings {
        .apply-device-settings__applied-settings__title {
            font-weight: bold;
        }
    }

    .apply-completed-job-cell {
        .hme-grid-cell-content {
            color: #5c6670;
        }
    }

    .apply-job-device-count {
        text-align: center;
    }
    
    .apply-job-device-row-completed {
        .hme-grid-cell .hme-grid-cell-content {
            color: #5c6670;
        }
    }

    .apply-job-applied-by-header {
        .hme-grid-filter-cell-popup {
            left: unset;
            right: 0;
            width: 370px !important;
            max-height: 450px !important;

            .hme-grid-filter-cell-popup-body {
                max-height: unset;
            }
        }

        .apply-device-settings-status-page-applied-by-filter-distributors-item {
            padding: 0 15px;
        }
    }

    .apply-job-status-header {
        .hme-grid-filter-cell-popup {
            left: unset;
            right: 0;
        }
    }

    .tasks-table-admin-status-filter-failed {
        color: #ff0000;
    }
}
