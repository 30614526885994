.hme-admin-system-status-devices {
    .hme-grid-expanded-row {
        .hme-grid-expanded-content {
            .hme-grid {
                .hme-grid-header {
                    display: none;
                }
            }
        }
    }

    .hme-grid-cell {
        &.hme-grid-model-name {
            .hme-grid-cell-content {
                word-break: break-word;
            }
        }
    }

    .hme-grid-cell {
        .hme-grid-cell-content {
            padding-left: 7px;
        }

        &.hme-grid-cell-expand-icon {
            .hme-grid-cell-content {
                padding-left: 0;
            }
        }

        &.hme-grid-expanded-content {
            >.hme-grid-cell-content {
                padding-left: 0;
            }

            .hme-grid {
                .hme-grid-cell-content {
                    padding-left: 7px;
                }
            }
        }
    }
}