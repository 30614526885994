.hme-upload-component.modal-dialog.hme-modal-component {
    .modal-content {
        padding-top: 60px;
        padding-bottom: 40px;
        padding-left: 40px;
        padding-right: 40px;
        width: 585px;
    }

    .btn-close {
        display: none;
    }

    .modal-header {
        margin-bottom: 27px;

        .hme-title .hme-upload-modal-icon {
            display: block;
            color: #0074ef;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 20px;
        }
    }

    .modal-footer {
        padding-right: 0;
        padding-top: 38px;

        .hme-button-component:first-child {
            margin-left: auto;
        }

        .hme-button-component:last-child {
            margin-left: 20px;
            margin-right: auto;
        }
    }
}