.hme-button-component {
    padding: 10px 25px;
    border-radius: 20px;
    border: solid 1px #c7ccd6;
    background-image: linear-gradient(to bottom, #ffffff, #ccd3e2);
    font-family: Assistant;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: 0.32px;
    text-align: center;
    color: #000819;

    &:hover,
    &:focus {
        color: inherit;
    }

    &:focus {
        outline: none;
    }

    &:active {
        font-size: 16px;
        color: #000819;
    }

    &.hme-transparent-button-component {
        border: none;
        background: none;
        font-weight: normal;
        line-height: 1.13;
        letter-spacing: normal;
        text-align: center;
        color: #0074ef;
        padding: 0;
    }

    &.hme-submit-button-component {
        background-image: linear-gradient(to bottom, #186df4, #0040ba);
        color: #ffffff;

        &.hme-button-disabled-component {
            opacity: 0.50;
            background-image: linear-gradient(to bottom, #186df4, #0040ba);
            color: #ffffff;
        }
        &.hme-button-preview-component {
            opacity: unset;
        }
    }

    &.hme-button-disabled-component {
        background-image: linear-gradient(to bottom, #dde1e9, #c7ccd6);
        color: #a7adba;
        cursor: default;
    }

    &.hme-button-disabled-link-component {
        color: #a7adba;
        cursor: default;
    }

    &.hme-mobile-button {
        width: 90%;
    }
}

@import './variants/accept.scss';
@import './variants/decline.scss';
