.hme-awards-reset-history-tab {
  width: 575px;

  .hme-grid-expanded-row .hme-grid-expanded-content .hme-grid .hme-grid-header {
    display: block;
  }

  & > .hme-grid {
    & > .hme-grid-header > .hme-grid-row, .hme-grid-row {
      padding-right: 0;
      & > .hme-grid-cell {
        &:last-child {
          text-align: right;
          & > .hme-grid-cell-content {
            padding: 0 30px 0 0;
          }
        }
        &:first-child, &:nth-child(2) {
          text-align: left;
          & > .hme-grid-cell-content {
            padding-left: 0;
            padding: 0; 
          }
        }
      }
    }
  }
}
