.hme-layout-view-device-upgrade-page {
    .deviceUpgrades {
        margin: 0;
    }

    .view-device-upgrade-wrapper {
        height: 100%;
        min-width: 1550px;
        display: flex;
        flex-direction: column;
    }

    .view-device-upgrade {
        padding: 12px 48px 0 48px;
        display: flex;
        flex-direction: column;
        overflow: auto;
        height: 100%;

        .view-device-upgrade-header {
            margin-bottom: 39px;
        }

        .view-device-upgrade-sections {
            display: flex;
            padding-bottom: 150px;

            .view-device-upgrade-info {
                width: 396px;
                flex: none;
                overflow: visible;
            }

            .view-device-upgrade-device-list {
                flex: 1;
                border: 0;
            }
        }
    }
}

.hme-device-upgrades-reviewer-warning-notification {
    .Toastify &.Toastify__toast--error {
        background-color: #186df4 !important;
    }
}

.view-device-upgrade__confirm-popup {
    &--approve,
    &--decline {
        .hme-submit-button-component {
            margin-left: 20px;
            color: #fff;
        }
    }

    &--approve {
        .hme-submit-button-component {
            background-image: linear-gradient(to bottom, #5db431, #267201);
        }
    }

    &--decline {
        .hme-submit-button-component {
            background-image: linear-gradient(to bottom, #ff8399, #cc1838);
        }
    }
}
