.hme-tiles-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
  &.no-records-message {
    font-family: Assistant;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: 1.28px;
    text-align: center;
    color: #000819;
    padding: 25px 0;
  }
}