.hme-manage-hierarchy__file-manage__export {
    display: flex;
    flex-wrap: wrap;

    .hme-single-select {
        margin-right: 10px;
        flex: 1;

        &--opened {
            margin-right: 8.4px;
        }
    }

    .hme-button-component {
        height: 40px;
        width: max-content;
        margin-top: auto;
        margin-bottom: 4px;
    }
}

@import './Tablet.scss';
