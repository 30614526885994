.hme-contest-view-edit-form__results {
    .hme-tab-panel-content {
        padding-top: 20px;
    }

    &__row--top {
        .hme-grid-cell,
        .hme-tile-list__row__value {
            font-weight: bold;
        }
    }
}