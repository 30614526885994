.view-device-upgrade-device-list {
  padding-top: 11px; }
  .view-device-upgrade-device-list .view-device-upgrade-filters-row {
    display: flex;
    flex-direction: column; }
    .view-device-upgrade-device-list .view-device-upgrade-filters-row .view-device-upgrade-filters-panel {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
    .view-device-upgrade-device-list .view-device-upgrade-filters-row .hme-input-search-variant {
      width: 330px;
      align-self: flex-start; }
  .view-device-upgrade-device-list .view-device-upgrade-count {
    font-size: 20px;
    font-weight: normal;
    letter-spacing: 0.03px; }
    .view-device-upgrade-device-list .view-device-upgrade-count .view-device-upgrade-count-label {
      font-weight: 900;
      font-size: 30px; }
  .view-device-upgrade-device-list .view-device-upgrade-count {
    margin-right: 20px; }
  .view-device-upgrade-device-list .view-device-upgradetarget-label {
    font-family: Assistant;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.02px;
    text-align: left;
    color: #000819;
    vertical-align: text-bottom; }
  .view-device-upgrade-device-list .hme-grid {
    flex: 1;
    overflow: auto;
    margin-top: 10px; }
  .view-device-upgrade-device-list .view-device-upgrade-version-cell .hme-grid-filter-cell-popup {
    left: unset;
    right: 0; }
