.hme-info-box.hme-info-box--info {
    .hme-info-box__content {
        background-color: #186df4;
        color: #ffffff;
        border-radius: 4px;
        padding: 15px 20px;
        margin: 0 10px 15px 10px;
        font-size: 1.5rem;
    }
}
