@import 'src/mixins/breakpoints.scss';

@include before-desktop-only {
  .hme-view-edit-store {
    &__wrapper {
      padding: 30px 15px;
      padding-bottom: 0;
    }

    &__content {
      margin-bottom: 50px;
    }

    &__info {
      flex-basis: 50%;
      flex-direction: column;
      gap: 15px;

      border-right: 1px solid #e8e9ed;
    }

    &__info-item {
      flex: 1;
      padding-right: 24px;

      border-right: none;

      .hme-address {
        border-bottom: 1px solid #e8e9ed;

        .hme-input-wrapper {
          line-height: 1.5;
        }
      }

      & ~ & {
        padding-left: 0;
      }
    }

    &__devices-info {
      flex-basis: 50%;
      padding-left: 20px;
    }

    .hme-title-block {
      padding-bottom: 0;
      font-size: 16px;
    }

    &__info-item > .hme-input-container,
    &__info-item > .hme-address {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin: 0;
      padding: 15px 0;

      .hme-label {
        margin: 0;
      }

      &.hme-input-label-inside-variant .hme-label {
        top: 20px;
      }
    }

    &__devices-tile {
      margin-top: 10px;
      padding: 0;

      .hme-tile-list {
        display: flex;
        flex-direction: column;
        gap: 15px;
      }
    }

    &__devices-tile-item {
      display: flex;
      flex-direction: column;
      gap: 15px;
      margin-bottom: 0;

      .hme-tile-list-item__rows__column {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      .hme-device-status {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .hme-tile-list-item__wrapper .hme-button-component {
      margin-bottom: 0;
    }
  }
}
