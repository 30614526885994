.hme-partner-api-management-token-modal {
    text-align: center;
    min-height: auto;

    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);

    &.hme-modal-component .modal-content {
        border: none;
        padding: 0;
    }

    &.hme-modal-component .modal-header {
        display: none;
    }
}
