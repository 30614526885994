.hme-system-status {
    padding: 30px;
    
    &__content {
        display: flex;
        width: 100%;
    }

    &__section-item {
        height: auto;

        &:first-child {
            border-left: none;
        }
    }

    &__device-grid {
        gap: 3px;
    }

    .hme-section {
        overflow: visible;
    }
}

@import './Tablet.scss';
@import './Mobile.scss';
