@import 'src/mixins/breakpoints.scss';

.hme-layout {
    .hme-page {
        display: flex;
        flex-direction: column;
        height: 100vh;

        .hme-page-content {
            flex: 1;
            overflow-y: hidden;
            overflow-x: auto;

            @include before-desktop-only {
                &.hmeBody {
                    padding: 0 15px;
                    width: 100% !important;
                    margin: 0;
                }
            }
        }
    }

    &.hme-layout-without-footer .footer__container {
        display: none;
    }

    &.hme-layout-extended {
        .hme-page {
            height: calc(100vh + 60px);
            .hme-page-content {
                min-height: 770px;
            }
        }
    }
}

.hme-auto-logout-modal {
    &.modal-dialog.hme-modal-component.confirm-popup {
        .modal-content {
            .modal-header {
                button.btn-close {
                    display: block;
                    position: absolute;
                    top: 20px;
                    right: 0;
                }
            }
        }
    }
}
