.hme-admin-store-details__device-info__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;

  .hme-title-block {
    padding-bottom: 0;
  }

  .hme-admin-store-details__device-info__buttons {
   .hme-button-component {
     margin-left: 20px;
   }
  }
}

.hme-view-edit-store__devices-info__grid {
  .hme-grid-header {
    position: static;
  }

  .hme-grid-cell {
    .hme-grid-cell-content {
      a,button {
        padding: 0 10px;
      }
      .hme-admin-store-details__device-info__status-link {
        a,
        button {
          padding: 0;
        }
      }
    }
  }
}
