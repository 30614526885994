.smacktalk-categories-carousel {
    width: 95%;
    & > .carousel-indicators {
        display: none; /* uncomment when we need a carousel 1/3*/
        & > li {
            width: 13px;
            height: 13px;
            border: 1px solid #186df4;
            border-radius: 50%;
            opacity: 1;
            background-color: white;
            &.active {
                background-color: #186df4;
            }

        }
    }
    & > .carousel-inner {
        width: 100%;
        & > .carousel-item {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            visibility: hidden;
            &.active {
                visibility: visible;
            }
            & > .smacktalk-categories-carousel-item {
                display: flex;
                justify-content: center;
                width: 100%;
                & > .smacktalk-categories-carousel-slide {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: center;
                    & > img {
                        object-fit: cover;
                        width: 100%;
                    }
                    & > .smacktalk-categories-carousel-slide-title {
                        margin: 0 0 50px 0;
                    }
                }
            }
        }
    }
    $carousel-control-color: #186df4;
    $carousel-indicator-active-bg: #186df4;
    & >.carousel-control-prev {
        display: none; /* uncomment when we need a carousel 2/3*/
        width: 5%;
        & > svg {
            transform: scale(2);
            & > g > use {
                fill: $carousel-control-color;
            }    
        }
    }
    & > .carousel-control-next {
        display: none; /* uncomment when we need a carousel 3/3*/
        width: 5%;
        transform: rotate(180deg);
        & > svg {
            transform: scale(2);
            & > g > use {
                fill: $carousel-control-color;
            }
        }
    }
}