.admin-system-status-cell {
  &.complete {
    background: url('~assets/icons/icon-complete.svg') no-repeat;
    padding: 0 0 0 28px;
  }

  &.failed {
    color: red;
    background: url('~assets/icons/icon-failed.svg') no-repeat;
    padding: 0 0 0 28px;
  }
}
