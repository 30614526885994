.source-device-settings-public {
  margin-top: 30px; }
  .source-device-settings-public .source-device-settings .source-device-settings-container {
    width: 100%; }
  .source-device-settings-public .source-device-settings-radio {
    max-width: 171px;
    min-width: 166px;
    height: 40px;
    border-radius: 24px;
    border: solid 1px #c7ccd6;
    font-family: Assistant;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.28px;
    color: #000819;
    background-image: linear-gradient(to bottom, #ffffff, #ccd3e2); }
  .source-device-settings-public .source-device-settings-radio-all {
    margin-right: 2px; }
  .source-device-settings-public .source-device-settings-radio label {
    font-family: Assistant;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.28px;
    color: #ffffff;
    margin-left: -20px;
    margin-top: 5px; }
  .source-device-settings-public .source-device-settings-container .active {
    background-image: linear-gradient(to bottom, #186df4, #0040ba);
    color: #ffffff; }
  .source-device-settings-public .source-device-settings-radio [type="radio"] {
    opacity: 0; }
  .source-device-settings-public .group-setting-inputs {
    position: relative;
    max-width: 332px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 24px; }
    .source-device-settings-public .group-setting-inputs .hme-checkbox-component {
      width: 160px;
      margin: 0 0 24px 4px;
      display: flex; }
      .source-device-settings-public .group-setting-inputs .hme-checkbox-component .hme-checkbox-component-icon {
        margin-top: 4px; }
      .source-device-settings-public .group-setting-inputs .hme-checkbox-component .hme-checkbox-label {
        flex-shrink: 5; }
  .source-device-settings-public .all-settings-disclaimer {
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #186df4;
    color: #ffffff;
    margin-top: 24px; }
  .source-device-settings-public .all-settings-disclaimer .icon-info {
    height: 22px;
    width: 22px;
    color: #ffffff;
    font-size: 22px;
    position: relative;
    top: 5px;
    left: 5px; }
  .source-device-settings-public .source-device-settings .subheader-info-icon {
    font-size: 16px; }
  .source-device-settings-public .all-settings-disclaimer .card-text {
    position: relative;
    left: -15px;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.54px;
    text-align: left;
    color: #ffffff; }
  .source-device-settings-public .all-settings-disclaimer .card-body {
    position: relative; }
  .source-device-settings-public .all-settings-disclaimer .exclusion-list {
    font-size: 18px;
    cursor: pointer;
    text-decoration: underline;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.54px;
    text-align: left;
    color: #ffffff; }
