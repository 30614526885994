.hme-onoff-slider-component {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 24px;
  cursor: pointer;
  user-select: none;
  margin: 0 35px 0 0;

  & >input {
    opacity: 0;
  }
  /* The slider */
  & > .hme-checkbox-slider-label {
    padding: 0 0 0 45px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;

    font-family: Assistant;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 1.08px;
    text-align: left;

    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #a9aebb;
    color: #a9aebb;
    -webkit-transition: .4s;
    transition: .4s;
  }

  & > .hme-checkbox-slider-label:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  & > input:checked + .hme-checkbox-slider-label {
    background-color: #186df4;
    color: #186df4;
  }

  & > input:checked + .hme-checkbox-slider-label:before {
    -webkit-transform: translateX(22px);
    -ms-transform: translateX(22px);
    transform: translateX(22px);
  }

  /* Rounded sliders */
  & > .hme-checkbox-slider-label {
    border-radius: 24px;
  }

  & > .hme-checkbox-slider-label:before {
    border-radius: 50%;
  }

  &.hme-onoff-slider-component--disabled {
    .hme-checkbox-slider-label {
      opacity: 0.5;
      cursor: default;
    }
  }
}
