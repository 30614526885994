.hme-view-user__access {
    padding-left: 62px;

    .hme-single-select {
        width: 345px;
        margin-bottom: 20px;
    }

    .hme-tree-input {
        max-width: 815px;
        width: 100%;
        min-width: 345px;
    }
}

@import './Mobile.scss';
