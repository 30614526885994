/* Color palette */
:root {
    --blue-100: #186df4;
    --blue-25: #001744;
    --blue-75: #0040ba;
    --blue-12: #001131;
    --blue-62: #003291;
    --blue-87: #004de0;
    --black: #000819;
    --gray-87: #212837;
    --blue-37: #001e56;
    --gray-75: #424855;
    --gray-62: #606672;
    --blue-50: #002469;
    --gray-50: #868c99;
    --gray-37: #a7adba;
    --gray-12: #e8e9ed;
    --gray-25: #c7ccd6;
    --white: #ffffff;
    --success-red: #cc0738;
    --warning-yellow: #ffce28;
    --success-green: #5db431;
    --bronze: #8d6d00;
    --dark-green: #2a7d01;
    --light-blue: #3b82f3;
}

/* Text styles */
.H1_Left {
    font-family: Assistant;
    font-size: 48px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: 0.2px;
    color: var(--white);
}

.H1_Right {
    font-family: Assistant;
    font-size: 48px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: 0.2px;
    text-align: right;
    color: var(--black);
}

.H1_Center {
    font-family: Assistant;
    font-size: 48px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: 0.2px;
    text-align: center;
}

.H2_Center {
    font-family: Assistant;
    font-size: 32px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: 0.2px;
    text-align: center;
}

.H2_Left {
    font-family: Assistant;
    font-size: 32px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: 0.2px;
}

.H2_Right {
    font-family: Assistant;
    font-size: 32px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: 0.2px;
    text-align: right;
}

.H3_Center {
    font-family: Assistant;
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: 0.1px;
    text-align: center;
}

.H3_Left {
    font-family: Assistant;
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: 0.1px;
}

.H3_Right {
    font-family: Assistant;
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: 0.1px;
    text-align: right;
}

.P1_Left {
    font-family: Assistant;
    font-size: 18px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--black);
}

.H4_Center {
    font-family: Assistant;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center;
}

.H4_Left {
    color: var(--black);
    font-family: Assistant;
    font-size: 17px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.88;
    letter-spacing: normal;
}

.H4_Right {
    font-family: Assistant;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: right;
}

.P2_Left {
    font-family: Assistant;
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
}

.Label_GrayDark_Large {
    font-family: Assistant;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: 1px;
    color: var(--gray-62);
}

.P3_Left {
    font-family: Assistant;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: var(--black);
}

.Label_GrayLight_Small {
    font-family: Assistant;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.08;
    letter-spacing: 0.8px;
    color: var(--gray-50);
}

.Caption_Left {
    font-family: Assistant;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--black);
}


/* Buttons */

.Button-Secondary-Large-Default {
    width: 146px;
    height: 48px;
    border-radius: 24px;
    background-image: -webkit-gradient(linear, left top, left bottom, from(var(--white)), color-stop(97%, #ced5e4));background-image: -webkit-linear-gradient(top, var(--white), #ced5e4 97%);background-image: -o-linear-gradient(top, var(--white), #ced5e4 97%);background-image: linear-gradient(to bottom, var(--white), #ced5e4 97%);
}

.Button-Secondary-Large-Default:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, from(var(--white)), color-stop(97%, #ced5e4)), -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.4)), to(rgba(255, 255, 255, 0.4)));background-image: -webkit-linear-gradient(top, var(--white), #ced5e4 97%), -webkit-linear-gradient(top, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4));background-image: -o-linear-gradient(top, var(--white), #ced5e4 97%), -o-linear-gradient(top, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4));background-image: linear-gradient(to bottom, var(--white), #ced5e4 97%), linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4))
}

.Button-Secondary-Large-Default:focus {
    background-image: -webkit-gradient(linear, left top, left bottom, from(var(--white)), color-stop(97%, #ced5e4)), -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.4)), to(rgba(255, 255, 255, 0.4)));background-image: -webkit-linear-gradient(top, var(--white), #ced5e4 97%), -webkit-linear-gradient(top, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4));background-image: -o-linear-gradient(top, var(--white), #ced5e4 97%), -o-linear-gradient(top, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4));background-image: linear-gradient(to bottom, var(--white), #ced5e4 97%), linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4));
}

.Button-Secondary-Large-Default:active {
    background-image: -webkit-gradient(linear, left top, left bottom, from(var(--white)), color-stop(97%, #ced5e4)), -webkit-gradient(linear, left top, left bottom, from(rgba(0, 36, 105, 0.1)), to(rgba(0, 36, 105, 0.1)));background-image: -webkit-linear-gradient(top, var(--white), #ced5e4 97%), -webkit-linear-gradient(top, rgba(0, 36, 105, 0.1), rgba(0, 36, 105, 0.1));background-image: -o-linear-gradient(top, var(--white), #ced5e4 97%), -o-linear-gradient(top, rgba(0, 36, 105, 0.1), rgba(0, 36, 105, 0.1));background-image: linear-gradient(to bottom, var(--white), #ced5e4 97%), linear-gradient(to bottom, rgba(0, 36, 105, 0.1), rgba(0, 36, 105, 0.1));
}

.ButtonText_Secondary-Large-Default {
    font-family: Assistant;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: 0.2px;
    text-align: center;
    color: var(--black);
}

.Button-Primary-Large-LeftIcon-Default {
  width: 146px;
  height: 48px;
  border-radius: 24px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(var(--blue-100)), to(var(--blue-75)));
  background-image: -webkit-linear-gradient(top, var(--blue-100), var(--blue-75));
  background-image: -o-linear-gradient(top, var(--blue-100), var(--blue-75));
  background-image: linear-gradient(to bottom, var(--blue-100), var(--blue-75)); }

.Button-Primary-Large-LeftIcon-Default:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(var(--blue-100)), to(var(--blue-75))), -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.15)), to(rgba(255, 255, 255, 0.15)));
  background-image: -webkit-linear-gradient(top, var(--blue-100), var(--blue-75)), -webkit-linear-gradient(top, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15));
  background-image: -o-linear-gradient(top, var(--blue-100), var(--blue-75)), -o-linear-gradient(top, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15));
  background-image: linear-gradient(to bottom, var(--blue-100), var(--blue-75)), linear-gradient(to bottom, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15)); }

.Button-Primary-Large-LeftIcon-Default:focus {
  background-image: -webkit-gradient(linear, left top, left bottom, from(var(--blue-100)), to(var(--blue-75))), -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.15)), to(rgba(255, 255, 255, 0.15)));
  background-image: -webkit-linear-gradient(top, var(--blue-100), var(--blue-75)), -webkit-linear-gradient(top, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15));
  background-image: -o-linear-gradient(top, var(--blue-100), var(--blue-75)), -o-linear-gradient(top, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15));
  background-image: linear-gradient(to bottom, var(--blue-100), var(--blue-75)), linear-gradient(to bottom, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15)); }

.Button-Primary-Large-LeftIcon-Default:active {
  background-image: -webkit-gradient(linear, left top, left bottom, from(var(--blue-100)), to(var(--blue-75))), -webkit-gradient(linear, left top, left bottom, from(rgba(0, 30, 86, 0.15)), to(rgba(0, 30, 86, 0.15)));
  background-image: -webkit-linear-gradient(top, var(--blue-100), var(--blue-75)), -webkit-linear-gradient(top, rgba(0, 30, 86, 0.15), rgba(0, 30, 86, 0.15));
  background-image: -o-linear-gradient(top, var(--blue-100), var(--blue-75)), -o-linear-gradient(top, rgba(0, 30, 86, 0.15), rgba(0, 30, 86, 0.15));
  background-image: linear-gradient(to bottom, var(--blue-100), var(--blue-75)), linear-gradient(to bottom, rgba(0, 30, 86, 0.15), rgba(0, 30, 86, 0.15)); }

.ButtonText_Primary_Large_LeftIcon_Default {
    font-family: Assistant;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: 0.2px;
    color: var(--white);
  }

 div::-webkit-scrollbar,
 section::-webkit-scrollbar,
 span::-webkit-scrollbar,
 article::-webkit-scrollbar,
 table::-webkit-scrollbar,
 tbody::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

div::-webkit-scrollbar-track,
span::-webkit-scrollbar-track,
section::-webkit-scrollbar-track,
article::-webkit-scrollbar-track,
table::-webkit-scrollbar-track,
tbody::-webkit-scrollbar-track{
    background: transparent;
}

div::-webkit-scrollbar-thumb,
section::-webkit-scrollbar-thumb,
span::-webkit-scrollbar-thumb,
article::-webkit-scrollbar-thumb,
table::-webkit-scrollbar-thumb,
tbody::-webkit-scrollbar-thumb {
    background-color: #a7adba;
    border-radius: 4px;
}