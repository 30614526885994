@import '../../assets/styles/Variables';

.hme-on-off {
    width: max-content;
    display: flex;

    &__slider {
        cursor: pointer;
        width: 45px;
        border-radius: 24px;
        height: 24px;
        background-color: $slider-off-color;
        position: relative;
        display: inline-block;
        transition: background-color 0.2s linear;

        &::before {
            position: absolute;
            content: " ";
            top: 2px;
            left: 2px;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            background-color: $main-white;
            transition: left 0.1s linear;
        }
    }

    .hme-label {
        display: inline-block;
        margin-left: 10px;
        font-size: 12px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: 1.08px;
        color: $slider-off-color;
        transition: color 0.2s linear;
    }

    &.hme-on-off--checked {
        .hme-on-off__slider {
            background-color: $hme-blue-color;

            &::before {
                left: calc(100% - 22px)
            }
        }

        .hme-label {
            color: $hme-blue-color;
        }
    }
    
    &.hme-on-off--disabled {
        .hme-on-off__slider {
            cursor: not-allowed;
            opacity: 0.5;
        }
    }
}