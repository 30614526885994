.hme-contest-page-title {
    --gap: 40px;

    padding: var(--gap);
    padding-bottom: 0;
    margin-bottom: 0;

    font-weight: bold;
}

@import 'Tablet.scss';
@import 'Mobile.scss';
