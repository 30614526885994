.create-device-upgrade-target .create-device-upgrade-target-filters-row {
  display: flex;
  flex: 0; }
  .create-device-upgrade-target .create-device-upgrade-target-filters-row .create-device-upgrade-target-filters-panel {
    flex: 1; }
  .create-device-upgrade-target .create-device-upgrade-target-filters-row .hme-input-search-variant {
    width: 283px;
    align-self: flex-end; }

.create-device-upgrade-target .create-device-upgrade-target-count {
  font-size: 20px;
  letter-spacing: 0.03px;
  color: #606672; }
  .create-device-upgrade-target .create-device-upgrade-target-count .create-device-upgrade-target-count-label {
    font-weight: bold;
    font-size: 30px; }

.create-device-upgrade-target .create-device-upgrade-target-count {
  margin-right: 20px; }

.create-device-upgrade-target .create-device-upgradetarget-count-label {
  font-family: Assistant;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.02px;
  text-align: left;
  color: #000819;
  vertical-align: text-bottom; }

.create-device-upgrade-target .hme-grid {
  flex: 1;
  overflow: auto;
  margin-top: 10px; }

.create-device-upgrade-target .create-device-upgrade-target-version-cell .hme-grid-filter-cell-popup {
  left: unset;
  right: 0; }
