.apply-store-settings-status-upload-error-rows-modal-grid {
    .apply-store-settings-status-upload-error-rows-modal-grid-error-icon {
        width: 22px;
        height: 22px;
        color: #cc0738;
    }

    .apply-store-settings-status-upload-error-rows-modal-grid-error-icon-header {
        width: 47px;
    }

    .hme-grid-header {
        margin-bottom: 14px;

        .hme-grid-header-row.hme-grid-row .hme-grid-cell {
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.31;
            letter-spacing: normal;
            text-align: left;
            color: #606672;
        }
    }

    .hme-grid-cell .hme-grid-cell-content {
        padding-left: 0;
        padding-right: 25px;
    }
}