@import 'src/assets/styles/Variables';

.text-connect {
    &__sections {
        &__section {
            &--message {
                .hme-textarea {
                    height: 226px;
                    margin: 0 0 5px;
                }

                & > .hme-input__char-counter {
                    display: block;
                    font-family: Assistant;
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.31;
                    letter-spacing: 1.44px;
                    text-align: left;
                    color: $main-color;

                    & > span:nth-child(2) {
                        margin-left: unset;
                        padding-left: unset;
                        border-left: unset;
                        border-color: unset;
                    }
                    & > .hme-focused {
                        display: none;
                    }
                    & > .hme-blured {
                        display: block;
                        color: $main-gray;
                    }
                }

                &:focus-within {
                    & > .hme-input__char-counter {
                        & > .hme-focused {
                            display: block;
                        }
                        & > .hme-blured {
                            display: none;
                        }
                    }
                }

                .hme-label-error {
                    padding-left: 1em;
                    font-family: Assistant;
                    line-height: 1.31;
                    letter-spacing: 1.44px;
                }
            }
        }
    }
}
