@import 'src/assets/styles/Variables';

.hme-contests {
    .hme-page &,
    .hme-page-content {
        width: 100% !important;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 20px;
        height: 100%;
        padding: 30px 50px;
    }

    &__header,
    &__filters-count-wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 40px;
    }

    &__header {
        margin-bottom: 10px !important;

        box-shadow: unset;
    }

    &__list {
        flex-grow: 1;
        overflow-y: auto;
    }

    &__header-separator {
        align-self: stretch;
    }

    &__add-contest {
        margin-left: auto;
    }

    & &__row {
        &--in-progress {
            background: none;
            background-color:#fef9e6;
        }
    }

    .hme-grid-cell:last-of-type .hme-grid-cell-content {
        padding-left: 0;
    }
}

@media screen and (max-width: 1270px) {
    .hme-contests {
        .hme-page &,
        .hme-page-content {
            &__header,
            &__filters-count-wrapper {
                gap: 15px;
            }
        }
    }
}

@import 'Tablet.scss';
@import 'Mobile.scss';
