.view-device-upgrade-footer {
    z-index: 10;
    text-align: right;
    padding: 20px 44px;
    box-shadow: 0 -10px 20px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    width: 100%;

    .hme-button-component {
        margin-left: 20px;
    }

    .hme-decline-button-component {
        background-image: linear-gradient(to bottom, #ff8399, #cc1838);
        color: #fff;
    }

    .hme-approve-button-component {
        background-image: linear-gradient(to bottom, #5db431, #267201);
        color: #fff;
    }
}
