@import 'src/assets/styles/Variables';

.hme-exapdable-sorting-item {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__title {
        font-size: 14px;
        color: $main-gray;
        text-transform: uppercase;
    }
}