@import 'src/mixins/breakpoints.scss';
@import 'src/assets/styles/Variables';

@include before-desktop-only {
    .hme-stores-status--public__header {
        display: block;

        .hme-title {
            display: none;
        }

        .hme-search-optionable {
            width: 100%;
            margin-left: 0;
            margin-bottom: 15px;
        }

        &__actions-wrapper {
            margin: 0 0 5px;
        }

        &__actions-wrapper,
        &__actions {
            gap: 25px;

            & > * {
                min-height: 30px;
                margin-left: 0;
                padding: 0;
                
                border-left: none;
            }
            
            & > *:not(:first-of-type) {
                padding-left: 25px;

                border-left: solid 1px #e5e5e5;
            }
        }

        &__actions {
            padding-left: 25px;

            border-left: solid 1px #e5e5e5;

            &--borderless {
                padding-left: 0;

                border-left: none;
            }
        }

        &__sort-btn {
            display: block;
            padding: 8px 15px;

            text-transform: uppercase;
            font-weight: 400;
            border: 1px solid $main-color;
            background: none;
            background-color: transparent;

            &,
            &:hover,
            &:focus {
                color: $main-gray;
                font-size: 16px;
            }
        }

        .hme-stores-status--public__header__manage-hierarchy {
            .hme-button-component {
                font-size: 14px;
                line-height: 1.29;
                letter-spacing: normal;
            }

            .hme-stores-status--public__header__manage-hierarchy__icon {
                display: inline-block;
                margin-top: -1px;
                margin-right: 8px;
                width: 17.5px;
                height: 10px;
                color: #0074ef;
            }
        }

        &__download-stores-btn {
            font-size: 14px;
            line-height: 1.29;
        }
    }


}
