@import '../../mixins/breakpoints.scss';

.hme-contact {
    @include desktop-only {
        &__value.hme-s-size {
            display: none;
        }
    }

    @include before-desktop-only {
        &__labels {
            margin-bottom: 8px;
            margin-right: 0;

            .hme-label {
                margin-bottom: 8px;
            }
        }

        &__value {
            margin-bottom: 20px;
        }
    }
}