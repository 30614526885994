.hme-view-role__permissions-section {
    border-left: solid 1px #e5e5e5;
    padding-left: 62px;
    padding-bottom: 100px;

    &.hme-view-role__permissions-section--first {
        border-left: none;
        padding-left: 0;
    }

    .hme-label {
        display: block;
        margin-bottom: 13px;
    }

    .hme-checkbox-component {
        display: flex;
        width: 100%;
        margin-bottom: 27px;
    }

    .hme-checkbox-label {
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: 1.28px;
    }

    .hme-checkbox-component-icon {
        margin-top: 4px;
    }

    .hme-accordion {
        width: 345px;

        .hme-accordion__title .hme-label {
            margin-bottom: 0;
        }
    }
}

@import './Mobile.scss';
