.hme-admin-system-status-header {
    margin-bottom: 30px;

    .hme-back-link {
        margin-bottom: 11px;
    }

    .hme-admin-system-status-header-title-buttons {
        display: flex;

        .hme-admin-system-status-header-title {
            flex: 1;
            height: 100%;
            margin-left: 8px;
        }

        .hme-admin-system-status-header-buttons {
            width: 600px;
            flex: none;
            margin-left: auto;
            text-align: right;

            .hme-button-component {
                margin-left: 20px;
            }

            .hme-button-component:first-child {
                margin-left: 0;
            }
        }
    }
}
