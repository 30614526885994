@import 'src/mixins/breakpoints.scss';

@include before-desktop-only {
    .leaderboard-templates-3-section {
        flex-basis: 100%;

        .hme-leaderboard-subsection-wrapper {
            gap: 60px;
        }

        .hme-leaderboard-subsection-1,
        .hme-leaderboard-subsection-2 {
            flex: 1 1 50%;
            min-width: auto;
            width: auto;
        }
    }
}
