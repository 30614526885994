.dxs-account-wrapper {
  height: 100%;
  min-width: 1380px;

  &.loading {
    display: flex;
  }

  .dxs-account {
    height: 100%;
    display: flex;
    flex-direction: column;

    .dxs-account-content {
      overflow: auto;
      height: 100%;
      padding: 0 48px;
    }

    .dxs-account-sections {
      display: flex;

      .dxs-account-info-section {
        flex: 25;
        padding: 42px 0 0;

        .hme-info-field {
          margin: 5px 0;
          border-bottom: solid 1px #e8e9ed;
          min-height: 60px;

          .hme-label {
            font-size: 14px;
            line-height: 1.29;
            color: #606672;
          }

          .hme-info-field-content {
            font-size: 16px;
            line-height: 1.5;
            color: #000819;
          }
        }
      }

      .dxs-account-feeds-section {
        flex: 75;
        margin: 0 0 0 40px;
      }
    }
  }
}
