@import 'src/mixins/breakpoints.scss';

@include mobile-only {
    .hme-stores-status--public__header {
        &__actions-wrapper {
            align-items: flex-start;
            flex-direction: column;
            gap: 15px;
        }

        &__actions {
            padding-left: 0;

            border-left: none;
        }
    }
}
