@import '../../../../mixins/breakpoints.scss';

@include mobile-only {
    .hme-manage-hierarchy__move-popup {
        &.modal-dialog.hme-modal-component {
            .modal-content {
                min-width: 310px;
                min-height: unset;
            }

            .modal-header {
                padding-left: 24px;
            }

            .modal-body {
                display: block;
                padding-left: 24px;
                padding-right: 24px;
            }
        }

        &__selected {
            width: 264px;
            margin-bottom: 15px;
        }

        &__to {
            width: 264px;
            min-height: 280px;
        }
    }
}
