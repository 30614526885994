.hme-tooltip {
    &.leaderboard-tooltip {
        min-width: 200px;

        &.bs-tooltip-bottom {
            padding: 0.8rem 0;
        }
        .tooltip-inner {
            border-radius: 10px;
            border: 1px solid #0074ef;
            font-size: 16px;

            .hme-tooltip-content {
                padding: 21px 16px 8px;
            }
        }

        .field-row {
            margin: 0 0 15px;
            line-height: 16px;
        }

        .subheader {
            font-size: 14px;
            color: #606672;
            font-weight: 600;
            margin: 0 0 6px;
        }

        .tooltip-headline {
            .title {
                strong {
                    font-size: 16px;
                    font-weight: 600;
                }
            }
        }

        .arrow {
            overflow: hidden;
            width: 2rem;
            height: calc(0.8rem + 1px);
            top: 0;

            &::before {
                border-top-color: #ffffff;
                border-bottom-color: #ffffff;
                border-width: 0 1rem 0.8rem;
            }

            &::after {
                content: '';
                position: absolute;
                bottom: 1px;
                border: solid transparent;
                border-width: 0 1rem 0.8rem;
                border-bottom-color: #0074ef;

                margin-left: 0;
                transform: none;
                box-shadow: none;
            }
        }
    }
}
