.sortableboard #containment-wrapper {
    height: 300px;
    width: 1130px;
    box-sizing: border-box;
}

.sortableboard #drags-container {
    clear: left;
    padding: 0;
    margin: 0 0 1em 0;
  /*  height: 200px; */
}

.sortableboard #containment-wrapper *,
.sortableboard #containment-wrapper *:before,
.sortableboard #containment-wrapper *:after {
    box-sizing: inherit!important;
    font-size:14px;
}

.sortableboard .table-slots {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
    position: absolute;
}

.sortableboard .table-slots > li {
    box-sizing: border-box;
    margin: 3px 2px 0px 0;
    padding: 1px;
    float: left;
    width: 280px;
    height: 160px;
    text-align: center;
    position: relative;
    z-index: 200;
}

.sortableboard .ui-empty-slot {
    box-sizing: border-box;
    border: 3px dotted #dedede;
    background-color: #f1f1f1;
}

.sortableboard .ui-empty-slot.ui-empty-slot-active-dropdown {
    z-index: 500 !important;
}

#slot-1 {
    z-index: 175;
}
#slot-2 {
    z-index: 150;
}

#slot-3 {
    z-index: 125;
}

.sortableboard .table-slots .dropdown {
    top: 50%;
    transform: translateY(-50%);
    width: 20%;
    margin: auto;
}
.sortableboard .dropdown {
    position: relative;
}


.sortableboard .table-slots .dropdown-menu {
    left: 50%;
    transform: translateX(-50%);
    /* background: url(images/ic-plus.png) no-repeat center -20px; */
}
.chrome_fontBlurRemove{
    -webkit-filter: blur(0);
    -webkit-font-smoothing: antialiased;
    -webkit-transform: translateZ(0);
}
.sortableboard .dropdown-menu {
    position: absolute;
    top: 100%;
    left: -25.3px !important;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 65px;
    -webkit-filter: blur(0);
    -webkit-font-smoothing: antialiased;
    /* -webkit-transform: translateZ(0); */
    padding: 5px 2px;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
}

.sortableboard .cont-table,
li.ui-draggable .cont-table {
    padding: 0;
    margin: 0;
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
}

.customize-dashboard-container.sortableboard .table-slots > li {
    width: 339px;
}

.customize-dashboard-container.sortableboard .sortable-tables > li {
    width: 339px !important;
}

.sortableboard .sortable-tables > li {
    width: 281px!important;
}

.sortableboard .sortable-tables > li:last-child {
    margin-right: 0;
}
.sortableboard .sortable-tables > li {
    margin: 3px 2px 0 0;
    padding: 0;
    float: left;
    width: 280px;
    height: 160px;
    font-size: 1em;
    text-align: center;
    position: relative;
}

.customize-dashboard-container.sortableboard .sortable-cols > li,
.customize-dashboard-container li[data-colname='StoreRank'].column {
    width: 110px;
}

.customize-dashboard-container.sortableboard .sortable-cols > li:last-child,
.customize-dashboard-container li.ui-draggable .sortable-cols > li:last-child {
    width: 110px;
}

.sortableboard .sortable-cols > li,
li[data-colname='StoreRank'].column
{
    margin: 0 1px 0 0;
    padding: 0;
    float: left;
    width: 91px;
    height: 127px;
    text-align: center;
    border: none;
    display: inline-block;
    vertical-align: top;
}

.dash-table, li.ui-state-default, li.ui-draggabledisabled {
    z-index: 300 !important;
}
.sortableboard .sortable-cols > li:last-child,
li.ui-draggable .sortable-cols > li:last-child  {
    width: 90px;
    margin: 0;
    float: left!important;
}

.sortableboard .ui-state-default,
li.ui-draggable {
    border-top: none;
    border-right: 1px solid #ddd;
    border-bottom: 2px solid #d3d3d3;
    border-left: 1px solid #ddd;
    font-weight: normal;
    color: #aaa;
}
.sortableboard .ui-state-default,
.sortableboard .ui-widget-content .ui-state-default,
.sortableboard .ui-widget-header .ui-state-default,
li.ui-draggable {
    border: 1px solid #c5dbec;
    /* background: #dfeffc url(./images/ui-bg_glass_85_dfeffc_1x400.png) 50% 50% repeat-x; */
    font-weight: bold;
    /* color: #2e6e9e; */
}

.sortableboard .col-cont,
li.ui-draggable .col-cont {
    position: relative;
    height: 127px;
    /* background-color: yellow; */
}

.sortableboard .sortable-cols,
li.ui-draggable .sortable-cols {
    position: absolute;
    text-align: center;
    margin: 0 auto;
    top: 0;
    background-color: #fff;
    /* background-color: orange; */
}
.sortableboard .sortable-tables, .sortable-cols,
li.ui-draggable  {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.sortableboard .hd th,
li.ui-draggable .hd th {
    /* background: url(../images/th_background_up.gif) repeat-x scroll 0 0 #0087a1; */
    background-color: #0087a1;
    font-weight: normal;
    color: #fff;
    border-bottom: 1px solid #fff;
    font-size: 18px;
    height: 30px;
    text-align: center;
}

.sortableboard .subrank,
li.ui-draggable .subrank,
li[data-colname='StoreRank'].column .subrank{
    margin: 0;
    padding: 0!important;
    background: none;
    border: none;
    border-collapse: collapse;
    border-spacing: 0;
    background-color: #ffffff;
    height: 100%;
}
.sortableboard .ranking,
.sortableboard .subrank,
li.ui-draggable .subrank,
li[data-colname='StoreRank'].column .subrank {
    width: 100%;
}
.sortableboard .subrank th,
li.ui-draggable .subrank th,
li[data-colname='StoreRank'].column .subrank th {
    color: #fff;
    padding: 0!important;
}
.sortableboard .subhd th,
li[data-colname='StoreRank'].column .subhd th{
    color: #fff;
    background-color: #cacaca;
    font-size: 14px;
    font-weight: normal;
    height: 27px;
    position: relative;
    text-align: center!important;
    cursor: pointer;
}

.sortableboard .ui-state-default a,
.sortableboard .ui-state-default a:link,
.sortableboard .ui-state-default a:visited,
li.ui-draggable a,
li.ui-draggable a:link,
li.ui-draggable a:visited {
    color: #2e6e9e;
    text-decoration: none;
}
.sortableboard .subhd th > a,
li.ui-draggable .subhd th > a,
li[data-colname='StoreRank'].column .subhd th > a {
    color: #fff!important;
}
.sortableboard .subrank th a,
li.ui-draggable .subrank th a,
li[data-colname='StoreRank'].column .subrank th a{
    color: #ffffff;
    font-weight: normal;
}
.sortableboard table.subrank a,
li.ui-draggable table.subrank a,
li[data-colname='StoreRank'].column table.subrank a {
    position: relative;
}

.sortableboard .subrank td,
li.ui-draggable .subrank td,
li[data-colname='StoreRank'].column .subrank td {
    text-align: center;
    font-size: 1em;
    padding: 5px;
    width: 80px;
}

.sortableboard .ui-state-default,
li.ui-draggable {
    border-top: none;
    border-right: 1px solid #ddd;
    border-bottom: 2px solid #d3d3d3;
    border-left: 1px solid #ddd;
    font-weight: normal;
    color: #aaa;
}

.sortableboard .table-toolbar,
li.ui-draggable .table-toolbar{
    /* float: right; */
    /* background-color: orange; */
    position: absolute;
    /* text-align: right; */
    right: 0;
    height: 25px;
    margin: 0;
}

.sortableboard .ui-state-default a,
.sortableboard .ui-state-default a:link,
.sortableboard .ui-state-default a:visited {
    color: #2e6e9e;
    text-decoration: none;
}

.sortableboard .remove-widget,
li.ui-draggable .remove-widget {
    width: 34px;
    height: 29px;
    background: url(../../images/btn-close-up.png);
    transition: background 0.3s ease;
    border: none;
}

.sortableboard  .remove-widget:hover,
li.ui-draggable .remove-widget:hover {
    background: url(../../images/btn-close-ov.png);
    border: none;
}

.sortableboard .col-settings,
li[data-colname='StoreRank'].column .col-settings{
    width: 30px;
    display: inline-table;
    position: relative;
    right: -14px;
    top: -1px;
    float: right;
}

.sortableboard .col-settings-fa,
li[data-colname='StoreRank'].column .col-settings-fa{
    width: 19px;
    display: inline-table;
    position: relative;
    right: -4px;
    top: -1px;
    float: right;
}

.sortableboard .col-settings .fa,
li[data-colname='StoreRank'].column .col-settings .fa,
.sortableboard .col-settings-fa .fa,
li[data-colname='StoreRank'].column .col-settings-fa .fa  {
    font-size: .9em;
    color: #777;
}

.sortableboard .col-settings .dropdown-toggle,
li[data-colname='StoreRank'].column .col-settings .dropdown-toggle,
.sortableboard .col-settings-fa .dropdown-toggle,
li[data-colname='StoreRank'].column .col-settings-fa .dropdown-toggle {
    margin: 0 !important;
    padding: 0 !important;
    background: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sortableboard  table.subrank .subhd th,
li[data-colname='StoreRank'].column  table.subrank .subhd th{
    display: flex;
    align-items: center;
    justify-content: center;
}

.sortableboard .ui-state-default button,
li.ui-draggable button {
    /* color: #2e6e9e; */
    text-decoration: none;
}

li.ui-draggable a.theadspan {
    color: #fff;
}
.sortableboard .subrank th button,
li[data-colname='StoreRank'].column .subrank th button{
    color: #ffffff;
    font-weight: normal;
}
.sortableboard  table.subrank button,
li[data-colname='StoreRank'].column table.subrank button {
    position: relative;
}

.sortableboard .dropdown-menu>li>a,
li[data-colname='StoreRank'].column .dropdown-menu>li>a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333!important;
    white-space: nowrap;
    font-size:14px;
}

.sortableboard  span.sort-desc,
li[data-colname='StoreRank'].column span.sort-desc {
    background: url(./images/sort-dn.png) no-repeat left;
    width: 20px;
    height: 20px;
}

.sortableboard  span.sort-asc,

li[data-colname='StoreRank'].column  span.sort-asc {
    background: url(./images/sort-up.png) no-repeat left;
    width: 20px;
    height: 20px;
}

/* .sortableboard .sortable-cols.ui-sortable .sort-link,
.sortableboard .sortable-tables.ui-sortable .theadspan {
    cursor: move;

} */

.sortableboard .sortable-tables.ui-sortable .theadspan {
    font-size: 18px !important  ;
    color: #FFF;
}

.sortableboard  .default-sort,
li[data-colname='StoreRank'].column .default-sort {
    background: #c8eff6;
}

.sortableboard .default-sort th,
li[data-colname='StoreRank'].column .default-sort th {
    color: #fff;
    background-color: #aaa;
}

.sortableboard .rshade,
li[data-colname='StoreRank'].column .rshade{
    background-color: rgba(0, 0, 0, .08);
}

.sortableboard .col-slots-cont {
    height: 100%;
    top: 0;
    padding: 0;
    list-style-type: none;
    margin-left: 1px;
}

.sortableboard .col-slots-cont > li {
    border: 1px dashed #bbb;
    height: 100%;
    width: 90px;
    float: left;
    margin-right: 1px;
    background-color: #eee;
    /* text-align: center; */
    box-sizing: border-box;
}

.sortableboard .col-slots-cont .dropdown {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.sortableboard .dropdown {
    position: relative;
}

.sortableboard table.subrank a,
.sortableboard table.subrank td,
li[data-colname='StoreRank'].column table.subrank a,
li[data-colname='StoreRank'].column table.subrank td{
    font-size:14px;
}

h5.customizeleaderboard div.leadertips{
    width:500px !important;
    padding-top:10px !important;
    padding-bottom:10px !important;
}

h5.customizeleaderboard div.customizeleaderboard {
    position: absolute !important;
    top: 15px !important;
    left: 187px !important;
}


.sweet-overlay {
    background-color: black;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: none;
    z-index: 10000;
}

.showSweetAlert[data-animation=none] {
    -webkit-animation: none;
    animation: none;
}
.sweet-alert {
    background-color: white;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    width: 478px;
    padding: 17px;
    border-radius: 5px;
    text-align: center;
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -256px;
    margin-top: -200px;
    overflow: hidden;
    display: none;
    z-index: 99999;
}

.sweet-alert h2 {
    color: #575757;
    font-size: 30px;
    text-align: center;
    font-weight: 600;
    text-transform: none;
    position: relative;
    margin: 25px 0;
    padding: 0;
    line-height: 40px;
    display: block;
}

.sweet-alert p {
    color: #797979;
    font-size: 16px;
    text-align: center;
    font-weight: 300;
    position: relative;
    text-align: inherit;
    float: none;
    margin: 0;
    padding: 0;
    line-height: normal;
}

.sweet-alert fieldset {
    border: none;
    position: relative;
}

.sweet-alert input {
    width: 100%;
    box-sizing: border-box;
    border-radius: 3px;
    border: 1px solid #d7d7d7;
    height: 43px;
    margin-top: 10px;
    margin-bottom: 17px;
    font-size: 18px;
    box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.06);
    padding: 0 12px;
    display: none;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.sweet-alert .sa-input-error {
    position: absolute;
    top: 29px;
    right: 26px;
    width: 20px;
    height: 20px;
    opacity: 0;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transition: all 0.1s;
    transition: all 0.1s;
}

.sweet-alert .sa-input-error::before {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.sweet-alert .sa-input-error::before, .sweet-alert .sa-input-error::after {
    content: "";
    width: 20px;
    height: 6px;
    background-color: #f06e57;
    border-radius: 3px;
    position: absolute;
    top: 50%;
    margin-top: -4px;
    left: 50%;
    margin-left: -9px;
}

.sweet-alert .sa-input-error::before {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.sweet-alert .sa-input-error::before, .sweet-alert .sa-input-error::after {
    content: "";
    width: 20px;
    height: 6px;
    background-color: #f06e57;
    border-radius: 3px;
    position: absolute;
    top: 50%;
    margin-top: -4px;
    left: 50%;
    margin-left: -9px;
}

.sweet-alert .sa-error-container {
    background-color: #f1f1f1;
    margin-left: -17px;
    margin-right: -17px;
    overflow: hidden;
    padding: 0 10px;
    max-height: 0;
    webkit-transition: padding 0.15s, max-height 0.15s;
    transition: padding 0.15s, max-height 0.15s;
}

.sweet-alert .sa-error-container .icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #ea7d7d;
    color: white;
    line-height: 24px;
    text-align: center;
    margin-right: 3px;
}

.sweet-alert[data-has-cancel-button=false] button {
    box-shadow: none !important;
}
.sweet-alert button.cancel {
    background-color: #C1C1C1;
}
.sweet-alert[data-has-cancel-button=false] button {
    box-shadow: none !important;
}

.sweet-alert button {
    background-color: #8CD4F5;
    color: white;
    border: none;
    box-shadow: none;
    font-size: 17px;
    font-weight: 500;
    -webkit-border-radius: 4px;
    border-radius: 5px;
    padding: 10px 32px;
    margin: 26px 5px 0 5px;
    cursor: pointer;
}
/* #leaderboard-container {
    width:1200px;
} */

.sortableboard .col-slots-cont .dropdown-menu.show {
    position: absolute;
    will-change: inherit !important;
    top: 100%  !important;
    left: 0px !important;
    transform: none !important;
}
.sortableboard .dropdown-menu>li>a,
li[data-colname='StoreRank'].column .dropdown-menu>li>a
{
    cursor: pointer;
}

.toolTip_darker{
    left: 299px;
    top: 14px;
    opacity: 1 !important;
    filter: alpha(opacity=100) !important;
}
.customeHeading{
    margin: 0px !important;
}
.whiteLine_Pullin{
    margin-top: 8px !important;
}
.WhiteText_Bottom{
    margin-top: 8px !important;
}


@media screen and (max-width: 1003px) and (min-width: 671px) {
    .sortableboard #drags-container {
        height:300px
    }
}


@media screen and (max-width: 670px)  {
    .sortableboard #drags-container {
        height:450px
    }
}


@media screen and (max-width: 1604px) {
    div.customizedashboard-button {
        margin-top: 146px;
        position: relative;
    }
}

.leaderBoardSettings{
    margin-bottom: 4em;
    margin-left: 1em;
    text-decoration: underline;
}

.storeAssignment {
    flex: 0 0 350px;
    height: 270px;
}

.border-right{
    margin-left: 38%;
}

.greyed-row{
    color:#888;
}

.paddingOnRight{
    margin-right:.5em;
}

.memberTableHeader{
    font-size:18px !important;
    min-width:20em;
    font-size:200%;
    padding-top:.4em;
    width: 797px;
}

.invite-group-btn{
    background-color: #d41b3e !important;
    color: #ffffff !important;
    cursor: pointer;
    border-color: #D41B42 !important;
    float: left;
    padding: 0em .2em 0em .2em !important;
    margin-top:.3em;
    margin-bottom:.3em;
    margin-left:1em;
    vertical-align:middle !important;
}

.taller-header{
    min-width:20em;
    vertical-align:middle !important;
}

.red-text{
    color: red;
}
#invite-success{
    margin-top: 25px;
}

.textAlign{
    text-align: center;
}

.leaderboardMessage{
    color: red;
    font-weight: bold;
}

.add-table.dropdown .btn-secondary {
    background: #ffffff;
}

.add-table.dropdown .dropdown-toggle::after,
.col-settings.dropdown .dropdown-toggle::after {
    content: none;
}

.input-group-text {
    background: transparent;
    border: none;
    font-size: 14px!important;
    vertical-align: middle;
}

.form-control.colorpickerinput {
    font-size: 12px!important;
}

.input-group-text strong{
    margin-right: 3px;
}

.dash-table.dash-table-active-dropdown, 
.active-dropdown li.ui-state-default, 
.active-dropdown li.ui-draggabledisabled {
    z-index: 500 !important;
}
