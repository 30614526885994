@import 'src/assets/styles/Variables';

.hme-certificate-container {
    &.hme-modal-component {
        min-height: unset;
        max-width: 505px;

        font-family: $font-family;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
    }

    &.hme-modal-component .modal-header {
        margin-bottom: 10px;
    }

    .btn-close {
        display: none;
    }

    &.hme-modal-component &__content {
        padding: 40px;

        background-color: $main-white;
    }

    &__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    &__header-icon {
        width: 60px;
        height: 68px;
    }

    &__header-title {
        margin-bottom: 0;

        font-size: 24px;
        font-weight: 600;
    }

    &__header-description {
        margin-bottom: 0;

        font-size: 18px;
        font-weight: 400;
        text-align: center;
    }

    &.modal-dialog .modal-body {
        display: flex;
        flex-direction: column;
        gap: 20px;
        overflow: unset;
    }

    .hme-center-message-loader {
        display: grid;
        align-content: center;
        min-height: 200px;

        .hme-center-message-body {
            position: static;
        }
    }

    &__contest-name {
        margin-bottom: 0;

        font-family: inherit;
        font-size: 24px;
        text-align: center;
    }

    &__stores-group {
        max-height: 300px;
        padding: 25px;
        padding-right: 0;
        padding-bottom: 15px;
        overflow-y: auto;

        border-radius: 25px;
        border: 1px solid #a7adba;
    }

    &__stores-group-actions {
        display: flex;
        justify-content: center;
        gap: 20px;
    }
    
    &__actions {
        display: flex;
        justify-content: center;
        gap: 20px;
    }
}

@import 'Tablet.scss';
@import 'Mobile.scss';
