.hme-account-email-header {
    padding-left: 37px;

    .hme-label {
        font-size: 12px;
        font-weight: normal;
        line-height: 2;
        letter-spacing: 1.08px;
        color: #606672;

        &.hme-account-email-header__email {
            font-size: 14px;
            line-height: 1.71;
            letter-spacing: 0.27px;
            color: #000819;
            text-transform: none;
            margin-left: 8px;
        }
    }

    &.hme-account-email-header-old-variant {
        .hme-label {
            font-family: inherit;
            font-size: 14px;
            text-transform: none;
            font-weight: bold;
            letter-spacing: normal;
            color: #000;

            &.hme-account-email-header__email {
                font-family: inherit;
                font-size: 14px;
                text-transform: none;
                font-weight: bold;
                letter-spacing: normal;
                color: #000;
            }
        }
    }
}
