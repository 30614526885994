.admin-system-status-page {
  height: 100%;
  overflow-y: auto !important;

  &.hmeBody {
    width: 100% !important;
  }

  .hme-admin-system-status {
    width: 85%;
    min-height: 40px;
    margin: 0 auto;
  }
}

.hme-admin-system-status.hme-page-component {
  padding: 27px 0 0 0;
}

body.modal-open {
  div.hme-tooltip {
    &.device-upgrade-tooltip {
      z-index: 100 !important;
    }
  }
}
