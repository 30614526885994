@import 'src/mixins/breakpoints.scss';

.hme-forgot-password {
    width: 1030px;
    padding-top: 70px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-wrap: wrap;

    .hme-title {
        margin-bottom: 14px;
        flex-basis: 100%;
    }

    .hme-forgot-password__info {
        flex: 1;
        margin-right: 32px;
        padding-top: 37px;

        .hme-forgot-password__info__line {
            margin-bottom: 0;
            line-height: 1.5;
        }
    }

    .hme-forgot-password__form {
        flex: 0;
    }

    @include before-desktop-only {
        & {
            padding-top: 26px;
            display: block;
            width: 100%;

            .hme-title {
                width: 100%;
                margin-bottom: 15px;
                font-size: 26px;
                line-height: 1.31;
                letter-spacing: -0.36px;
            }

            .hme-forgot-password__info {
                width: 100%;
                margin-bottom: 15px;
                padding-top: 0;
            }

            .hme-forgot-password__form {
                width: 100%;

                .hme-input-container {
                    margin-bottom: 20px;
                    display: block;

                    .hme-label {
                        margin-right: 0;
                        margin-bottom: 8px;
                    }

                    .hme-input-wrapper {
                        width: 100%;
                    }
                }

                .hme-submit-button-component {
                    margin-left: 0;
                    width: 100%;
                    display: block;
                }
            }
        }
    }
}
