 /* .hmeBody {
  width:100% !important;
}  */
.summaryReportsSection {
  /* margin:0 -10%; */ /* newly added */
}

.show-table-cell{
  display : table-cell;
}
.hide-table-cell{
  display : none;
}
.disable{
  pointer-events: none;
}

.total-cars{
  width: 125px !important;
}

.summary-header{
  background-color : #029fb9;
  width: 100%;
  margin-right: 25px;
}
.summary-header-text{
  color: white;
  font-size: 26px;
  font-weight: bold;
  padding-top: 1px;
  padding-left: 10px;
  margin-top: 3px;
}

.download-btn-section{
  margin: 0 0 18px;
}
.download-summaryreport-btn{
  background-color: #06b1cb;
  text-align: center;
  bottom-border: none;
  color: #ffffff;
  cursor: pointer;
  padding: 10px 10px;
  font-size: 1.12em!important;
  font-weight: normal;
  width: 105px;
  border-radius: 0;
  border-color: #ccc;
}

.download-summaryreport-btn:focus, .download-summaryreport-btn:active{
  color: #333!important;
  background-color: #e6e6e6!important;
  border-color: #8c8c8c!important;
}

.summaryreport-table{
  font-size: 12px;
}
.summaryreport-table tr th.blankHeader{
  background: none;
  border: 0px;
  height: 23px;
  font-size: 12px;
}

.summaryreport-table tr th.tableHeading{
  background: #959595;
  border-top: 1px solid #FFFFFF;
  border-left: 1px solid #FFFFFF;
  border-right: 1px solid #FFFFFF;
  border-bottom: 0px solid #959595;
  height: 23px;
  font-size: 12px;
}

.groupsColHeader,.storesColHeader{
    background-color: #0087a1;
    border-top: 1px solid #FFFFFF;
    border-left: 1px solid #FFFFFF;
    color: white;
    height: 36px;
    vertical-align: middle;
}

.summaryreport-table th{
  background-color: #0087a1;
  border-top: 1px solid #FFFFFF;
  border-left: 1px solid #FFFFFF;
  color: white;
  height: 36px;
  vertical-align: middle;
}
.summaryreport-table th.groupsColHeader,.summaryreport-table th.storesColHeader{
      width: 125px;
}
/* .summaryreport-table th.reportTableAttributesHeading{
      width: 175px;
} */


.paddingRightZero{
  padding-right: 0px
}

.summaryreport-table th.reporttable-attributes-heading-dynamic,
.goalstatistics-table  th.reportTableAttributesHeading{
  width: 125px;
}
.summaryreport-table th.reportTableAttributesHeading10,
.goalstatistics-table tr th.reportTableAttributesHeading10,.reportTableAttributesHeading10{
  width: 85px;
  font-size : 11px !important;
}
.summaryreport-table th.reportTableAttributesHeading11,
.goalstatistics-table  th.reportTableAttributesHeading11,.reportTableAttributesHeading11{
  width: 75px;
  font-size : 11px !important;
}
.summaryreport-table th.reportTableAttributesHeading12,
.goalstatistics-table tr  th.reportTableAttributesHeading12,.reportTableAttributesHeading12{
  width: 70px;
  font-size : 11px !important;
}
.summaryreport-table th.reportTableAttributesHeading13,
.goalstatistics-table  th.reportTableAttributesHeading13,.reportTableAttributesHeading13{
  width: 65px;
  font-size : 11px !important;
}
.summaryreport-table th.reportTableAttributesHeading9,
.goalstatistics-table  th.reportTableAttributesHeading9,.reportTableAttributesHeading9{
  width : 95px;
  font-size : 11px !important;
}
.summaryreport-table th.reportTableAttributesHeading8,
.goalstatistics-table  th.reportTableAttributesHeading8,.reportTableAttributesHeading8{
  width: 105px;
}
.summaryreport-table th.reportTableAttributesHeading7,
.goalstatistics-table  th.reportTableAttributesHeading7,.reportTableAttributesHeading7{
  width: 120px;
}
.summaryreport-table th.reportTableAttributesHeading6,
.goalstatistics-table  th.reportTableAttributesHeading6,.reportTableAttributesHeading6{
  width: 145px;
}

.summaryreport-table th.reportTableAttributesHeading5,
.goalstatistics-table  th.reportTableAttributesHeading5,.reportTableAttributesHeading5{
  width : 175px;
}

.summaryreport-table th.reportTableAttributesHeading5{
  width : 175px;
}

.summaryreport-table th.reporttable-attributes-heading-dynamic{
  width: 125px;
}

.summaryreport-table td{
  border-top: 1px solid #FFFFFF;
  border-left: 1px solid #FFFFFF;
  text-align: center;
  vertical-align: middle;
  height: 42px;
}
.summaryreport-table tr th{
  text-align : center;
}

.asterics{
  color: #D41B3E;
  font-size: 18px;
  line-height: 0;
}

.templateRow {
  display: flex;
}

.reports-terms{
  font-size: 12px;
  color: #777777;
  margin-top: 16px;
  padding-left: 15px;
}
.show-page-toggle{
  font-size: 12px;
  margin-top: 14px;
  font-weight: bold;
}
.show-label{
  font-weight: bold;
  color: black;
  margin-right: 5px;
}

.goalstatistics-table-section, .systemstatistics-table-section{
      margin-top: 10px;
}
.reportTableAttributesHeading.blank-heading{
  background: none;
  width : 125px;
}


/* .goalStatistics-cover{
  position:absolute;
  left: 15px;
  right: 15px;
  height:1px;
  color: #777777;
} */

.currentMeasure{
  font-size: 10px;
  font-weight: 500;
  color: #D41B3E;
}
.timeSpan{
  color: #D41B3E;
  font-weight: bold;
}

.timeMeasureColumn:hover{
  cursor: pointer;
  text-decoration: underline;
}

/* .goalstatistics-table td{
  width: 175px;
} */

.goalStatistics-row{
  margin-bottom: 5px;
}

h2 .report-start-time, h2 .report-end-time{
  font-size: 14px;
  color: #008898;
  line-height: 20px;
  font-weight: 800;
}
h2 .report-start-time-value,h2 .report-end-time-value{
  color: #008898;
  font-size: 14px;
  line-height: 20px;
  font-weight: 100;
  margin-left: 5px;
  text-transform: uppercase;
}
.store-error{
  font-size: 9px;
  position: absolute;
  color:#d41b3e;
}

.report-start-time-header, .report-end-time-header{
  margin-top:0px;
  padding-right: 14.5px;
}
.report-print-time-header{
    color: #959595;
    margin-top : 0px;
}

.report-print-time, .report-print-time-value{
  font-weight: 100;
  font-size: 14px;
  margin-left: 5px;
  line-height: 20px;
}
.from-to-detail{
  color:#008898;
  /* margin-bottom: 5px; */
  font-size: 12px;
  margin-bottom: 10px;
  font-weight: bold;
  text-transform: uppercase;
}
.show-all-pagination-toggle{
  text-align : right;
  font-size: 12px;
  font-weight: bold;
}

.show-all-pagination-toggle, .pagination-single {
  flex: initial;
  max-width: initial;
}

.left-padding-none{
  padding-left : 0px;
}

.pagination-right {
  width: 100%;
  flex: initial;
  max-width: initial;
}

.flex {
  display: flex;
}

.right-padding-none{
  padding-right : 0px !important;
}
#page-navigations{
  color: #D41B3E;
  margin-top:6px;
  align-items: center;
  display: flex;
  justify-content: flex-end;
}
.page-navs{
  display : inline-block;
  float:left;
  margin-right: 2px;
}

.previous-link, .next-link{
  float: left;
  border: 1px solid white;
  background-color: red;
  width: 20px;
  height: 20px;
  display : inline-block;
  cursor : pointer;
}
.previous-page{
  font-size: 18px !important;
  color: white;
  margin-left: 5px;
}
.next-page{
  font-size: 18px !important;
  color: white;
  margin-left: 5px;
}
.start-page{
  border: 1px solid #D41B3E;
  padding: 0px 4px 0px 3px;
}
.report-data-unit, .goalstatistics-data-unit{
  margin-top:19px;
}
.active-link{
  color: rgb(0, 136, 152);
  text-decoration: underline;
  cursor: pointer;
}
.inactive-link{
  cursor: pointer;
  color: #D41B3E;
}

.show-page-toggle.left{
  padding-left: 0px
}

/* Goal Statistics css starts here*/
.goalstatistics-table tr th.blankHeader{
  background: none;
  border: 0px;
  height: 23px;
  font-size: 12px;
  min-width:150px;
  max-width:150px;
}
.show-table-cell.headers{
  min-width :120px
}

.goalstatistics-table tr.goalstatistics-row-heading th.blank-heading{
  background: none;
}

.goalstats-title{
  width : 125px;
}
.summaryReportHeaders{
  display:flex;
  width: 100%;
  padding-bottom: 15px;
}
.goalstatistics-table tr th.tableHeading{
  background: #959595;
  border-top: 1px solid #FFFFFF;
  border-left: 1px solid #FFFFFF;
  border-right: 1px solid #FFFFFF;
  border-bottom: 0px solid #959595;
  height: 23px;
  font-size: 12px;
}

.goalstatistics-table th{
  background-color: #0087a1;
  border-top: 1px solid #FFFFFF;
  border-left: 1px solid #FFFFFF;
  color: white;
  height: 36px;
  vertical-align: middle;
}
.goalstatistics-table th.groupsColHeader,.goalstatistics-table th.storesColHeader{
      width: 125px;
}
.goalstatistics-table th.reportTableAttributesHeading{
  font-size: 10px;
  font-weight: normal;
      width: 175px;
}
.goalstatistics-table th.reportTableAttributesHeading:first-child{
  width: 125px;
}

.goalstatistics-table td{
  border-top: 1px solid #FFFFFF;
  /* border-left: 1px solid #FFFFFF; */
  text-align: center;
  vertical-align: middle;
  height: 21px;
  min-width:120px;
}

.goalstatistics-table tr:first-child td:first-child {
  border-right: 1px solid #FFFFFF;
  min-width: 150px;
  max-width: 150px;
}
.goalstatistics-table td:first-child{width: 125px;
}

.goalstatistics-table tr th{
  text-align : center;
}
.goalstatistics-table{
  /* border-bottom: 1px solid grey; */
  border-bottom: 1px solid #aeaeae;
  margin-bottom: 5px;
}

.goalstatistics-table.goalstatistics-table-content{
  font-size: 12px;
  font-weight: normal;
  border-bottom: 1px solid #aeaeae;
   width: 1005px;/* newly added */
}

.goalstatistics-table.goalstatistics-table-header{
  border-bottom: none;
  width: 1005px;/* newly added */
}
.goalstatistics-header-text{
  color: #008898;
  font-size: 17px;
  line-height: 20px;
  font-weight: 800;
  text-transform: uppercase;
}

/* Goal Statistics css ends here*/




/* Raw Car Data CSS*/

.emailCSV {
    padding: 10px;
    margin: 10px 15px;
    background-color: #d41b3e !important;
    border: none;
    color: #ffffff;
    cursor: pointer;
}


.rawcar-data-section{
  /* margin: 20px 68px 0px 68px; */
}

tr.raw-car-subheaders th:nth-child(1),tr.raw-car-subheaders th:nth-child(2){
  width: 226px;
}
tr.raw-car-subheaders th{
  width: 113px;
}


.clear:after {
  clear: both;
  content: ".";
  display: block;
  font-size: 0;
  height: 0;
  visibility: hidden;
}
table .raw-car-header-labels {
  border-bottom: 0px solid #d1d1d1;
}
table .th {
  align-items: left;
}
.rawcar-header-labels {
  font-size: 12px;
  margin-left: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: left;
  width: 850px;
}
.thin-header {
  width: 115px;
}
.rawcar-header {
  border: 1px solid #ffffff;
  display: table;
  height: 36px;
  width:100%;
}
.rawcar-h1 {
  margin-top: 10px;
}
.rawcar-h2 {
  color: #0087a1;
  /* margin-left: 7.5em; */
  font-weight: bolder;
  font-size: 18px;
  margin-top: 10px;
}
.display-records {
  border-collapse: collapse;
  /* border-spacing: 0px; */
  font-size: 12px;
  float: left;
  padding-top: 0px;
  width: 1130px;
}
.table-layout {
  margin-top: 0px;
  margin-bottom: 25px;
  /* width: 1125px; */
}
.table-layout th {
  /* font-weight: bold; */
  font-size:12px;
  height: 30px;
}
.table-layout th span{
  /* font-weight: bold; */
  font-size:10px;
  height: 30px;
}

.table-layout th{
	/* background:url(/images/th_background_up.png); */
	background-color: #0087a1;
	border-top:1px solid #FFFFFF;
	border-left:1px solid #FFFFFF;
	color:white;
    vertical-align:middle;
    text-align: center;
}
.display-result{
    background-color: #ffffff;
    text-align: center
}

.display-records  tr:nth-child(odd){
    background-color: #ffffff;
}
.display-records  tr:nth-child(even){
    background-color: #ededed;;
}



/* systemStatiscs CSS */
.lane1 {
  text-align: left;
  padding-left: 15px;
}

.table-layout-System th {
  background-color: #0087a1;
  border-top: 1px solid #FFFFFF;
  border-left: 1px solid #FFFFFF;
  color: white;
  height: 36px;
  vertical-align: middle;
}
.col-width-ss5 {
  width: 300px;
}
h2.rep-head {
  margin-bottom: 3px;
}

#page-container h2 {
  color: #008898;
  font-size: 17px;
  line-height: 20px;
  font-weight: 800;
}

#ss-col-width {
  height: 21px;
}
.table-layout-System.ssBord td {
  border-left: 0px solid white;
  padding-left: 25px;
  text-align: left;
}
.rnshade {
  background-color: #ffffff;
}
#ss-col-width-sm {
  padding-right: 35px;
}
.rshade {
  background-color: #ededed;
}
#page-container table {
  border-collapse: separate;
  border-bottom: 1px solid #aeaeae;
}





.lastTd {
    border-left: 1px solid #aeaeae;
    border-right: 1px solid white;
    border-top: 1px solid #aeaeae;
    width: 125px;
}
.headerColumn {
    width: 125px !important;
    border-right: 0px solid gray;
}
.longtime-timeSpan {
    font-size: 10px;
    font-weight: 500;
}

.table-layout td {
    border-top: 1px solid #FFFFFF;
    border-left: 1px solid #FFFFFF;
    text-align: center;
    vertical-align: middle;
}
.rshade {
    background-color: #ededed;
}
.boldin {
    font-weight: bold;
}
.daypart td {
    height: 42px;
}
table.longest td {
    line-height: .9;
}

.colWidth5 {
    width: 175px;
}

.rshade {
    background-color: #ededed;
}
.rnshade {
    background-color: #ffffff;
}

.table-layoutRawCar {
    margin-left: 0px;
}
.rawCarContainer{
    width:100%;
}

span.redFont {
    color: #D41B3E;
    font-size: 18px;
    line-height: 0;
}
.goalNote {
    width: 1005px;
    color: #777;
    font-size: 12px;
    margin-right: 125px;
    margin-bottom: 3px;
    text-align: center;
}
.downloadOptionsSection {
    position: relative;
    display:flex;

}
.downloadOptions {
    position: absolute;
    left: 0;
    right: 0;
}

.downloadOptions {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    display: block;
    cursor: pointer;
    background: #ededed;
    color: #06b1cb;
    font-weight: bold;
    font-size: 12px;
    text-decoration: none;
    width: 130px;
    z-index: 1;
}
    .downloadOptions div {
        border: 1px solid #06b1cb !important;
        padding: 5px;
        text-align:center;
    }
        .downloadOptions div:hover {
            background-color: #06b1cb;
            color:white;
        }
        .reportsummary-container .download-btn-section {
            width: 200px;
            position: relative;
        }

.download-summaryreport-btn {
    background-color: #06b1cb;
    text-align: center;
    border-bottom: none;
    color: #ffffff;
    cursor: pointer;
    padding: 10px 10px;
    font-size: 1.12em;
    font-weight: normal;
    width: 130px;
    border-radius: 0;
}

.download-summaryreport-btn:hover {
  background-color: #06b1cb;
  color: #ffffff;
  border-bottom: none;
  cursor: pointer;
  border-color: #adadad;
}

.label-heading{
    display: inline-block;
    max-width: 100%;
    margin-bottom: 0 !important;
    font-weight: normal;
    margin-left: 7px;
}


span.StoreTitile {
  display: inline-block;
  width: 400px;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

span.StoreBrand {
    width: 50px;
    text-align: left;
    width: 136%;
    display: inline-block;
}


.saved-reports .rc-tree-node-content-wrapper {
    width: 220px !important;
}

.storeTree {
    display: inline-flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.copyright .black_link {
    padding: 0px 5px;
    color: #d41b3e !important;
    font-weight: bold;
    text-decoration: underline;
}

.background-NA {
    background-color: grey !important;
}

.total-row-css{
  border: 2px solid #06B1CB;
}

.time-span-total{
  font-weight: bold !important;
  color:black;
}

.time-measure-total{
  font-size: 10px !important;
  font-weight: 500 !important;
  color:black;
}

.summaryreport-table tr:last-child {
    border: 2px solid #06B1CB !important;
}

.summaryreport-table tr:last-child > td:first-child > span {
    color: #000;
}
.summaryreport-table tr:last-child > td > span {
  color: white;
}

.summaryreport-table tr:last-child > td > span.last-multi-column {
color: #000 !important;
}
.summaryreport-table tr:last-child > td{
  pointer-events: none;
}

.summaryreport-table tr:last-child > td:last-child > span {
  color: black;
}

.storesTooltip {
  position: absolute;
  z-index: 10000;
  background-color: white;
  color: black !important;
  width: 100%;
  top: -62px;
  padding: 10px 15px;
  font-size: 16px;
  border: 1px solid #a6c9e2;
  border-radius: 3px;
  box-shadow: 2px 2px 9px 1px;
  }

  .storesTooltip span{
    color: black !important;
  }
.storesDots:hover{
  color:rgb(212, 27, 62) !important;
  text-decoration:underline !important;
}
.selectAStore {
    color:rgb(212, 27, 62) !important;
}
.storeWrap span:not(:first-child) {
    color: #696969;
}
.average-time-label{
  font-size: 10px;
  font-weight: 500;
}
tr.summary-row-data:nth-child(even){
  background-color: #ededed;
}
tr.summary-row-data:nth-child(odd){
  background-color: #ffffff;
}
.store-name-number{
  color: #D41B3E;
  text-decoration: none;
  font-weight:bold;
}
.store-name-number:hover{
  cursor: pointer;
}
.rawcar-no-data{
  font-family: Arial;
  font-size: 30px;
  margin-bottom: 17px;
  text-align: center;
}

.font-black{
  color: #000 !important;
}

.greyBox{
  background-color: #959595 !important;
}

.storesDots{
color : red !important;
padding-left: 3px !important;
}

.templateSelected{
    background-color: #c4e9f1 !important;
}

.rc-time-picker-input::placeholder{
  font-size: 11px
}
.rc-time-picker-input::-webkit-input-placeholder {
  font-size: 11px
}
.rc-time-picker-input::-moz-placeholder {
  font-size: 11px
}

.rc-time-picker-panel-input::placeholder{
  font-size: 11px
}
.rc-time-picker-panel-input::-moz-placeholder {
  font-size: 11px
}
.rc-time-picker-panel-input::-webkit-input-placeholder {
  font-size: 11px
}
.timings span.rc-time-picker{
  margin-right: 8px;
}

.clearTimer{
  position: absolute;
  bottom: 5px;
  right: 40px;
  cursor: pointer;
  color: #fff;
  background-color: #d41b3e;
  border: none;
  border-radius: 2px;
  box-shadow: 1px 1px 1px 0px black;
}


.setTimer{
  position: absolute;
  bottom: 5px;
  right: 5px;
  cursor: pointer;
  color: #fff;
  background-color: #d41b3e;
  border: none;
  border-radius: 2px;
  box-shadow: 1px 1px 1px 0px black;
}

.rc-time-picker-panel-inner{
  padding-bottom: 28px
}

.rc-time-picker-panel-clear-btn{
  display: none
}

.noStores {
  font-size: 22px;
  margin-left: 15px;
  padding: 20px 0px;
  border-bottom: 1px solid #dabdbd;
  margin-bottom: 20px;
}

.reportBasicUser h3.up_head {
  border-bottom: 1px solid #898989;
  padding-bottom: 17px;
  margin-bottom: 17px;
}

.reportBasicUser .up_sub {
  font-weight: bold;
  padding: 0 0 15px;
}

.reportBasicUser .contactList {
  margin-bottom: 15px;
}

.reportBasicUser .contactList .contact-d {
  padding: 10px 15px 10px 0px;
}
.reportLogos {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
@media screen and (max-width: 767px) {
  .reportBasicUser h3.up_head {
    font-size: 18px;
  }
  .reportBasicUser .up_sub {
    font-size: 14px;
  }
  .reportBasicUser .contactList {
    font-size: 12px;
  }
}
@media screen and (min-width: 768px) {
  .reportBasicUser h3.up_head {
    font-size: 30px;
  }
  .reportBasicUser .up_sub {
    font-size: 18px;
  }
  .reportBasicUser .contactList {
    font-size: 16px;
  }
  .table-layout {
     width: 1125px;
  }
}

.newReports {
  height: 50vh;
}

.summary-reports-section {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.summary-reports-section .pagination-wrapper.padding-top {
  margin-top: 3.5rem;
}

 .reportsummary-container {
   margin: 20px 0 0 -8px;
 }

 .report-data-unit .from-to-detail {
   padding-left: 15px;
 }
 .show-all-multi {
   margin-top: 15px;
 }
 #summaryReportMultiPaginationTopFixedWidth {
   margin-top: 15px;
 }
 #summaryReportPaginationBottomFixedWidth{
   display: flex;
   align-items: center;
   justify-content: space-between;
   margin-bottom: 10px;
 }
 #summaryReportPaginationBottomFixedWidth #page-navigations{
   margin-top: 11px;
 }

 .margin-right-25-percent {
     margin-right: 25%;
 }
