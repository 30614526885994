.hme-store-input-view {
    &.hme-store-input-view-no-stores {
        .hme-grid .hme-grid-header {
            display: none;
        }
    }

    .hme-auto-complete-suggestions .hme-grid .hme-grid-row {
        padding-left: 0;
        padding-right: 0;

        .hme-grid-cell .hme-grid-cell-content {
            white-space: nowrap;
            padding-right: 15px;
            padding-left: 15px;
        }
    }

    &.hme-input-search-variant {
        .hme-input-component {
            font-size: 14px;
            padding-top: 1px;

            &:focus {
                padding-top: 0;
            }
        }
    }

    .hme-auto-complete-suggestions {
        min-width: 790px;
    }

    .hme-store-input-view-brand-cell {
        width: 135px;
    }

    .hme-store-input-view-store-name-cell {
        width: 161px;
    }

    .hme-store-input-view-account-cell {
        width: 275px;
    }

    .hme-store-input-view-store-number-cell {
        width: 133px;
    }

    .hme-store-input-view-device-serial-number-cell {
        width: 134px;
    }
}