.device-upgrades-list-grid {
  min-width: 1400px; }
  .device-upgrades-list-grid .hme-grid-row--completed,
  .device-upgrades-list-grid .hme-grid-row--cancelled {
    opacity: 0.8; }
    .device-upgrades-list-grid .hme-grid-row--completed .hme-grid-cell,
    .device-upgrades-list-grid .hme-grid-row--cancelled .hme-grid-cell {
      color: #606672; }
  .device-upgrades-list-grid .hme-grid-row--in-progress .hme-grid-cell.hme-grid-cell-device-counters, .device-upgrades-list-grid .hme-grid-row--in-progress .hme-grid-cell.hme-grid-cell-status {
    font-weight: bold; }
