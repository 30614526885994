@import "src/assets/styles/Variables";

.hme-info-box.hme-info-box--warning {
    .hme-info-box__content {
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        background-color: $warning-yellow;
        border-radius: 5px;
        padding: 20px;
    }

    .hme-info-box-message {
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: 0.54px;
        color: $main-black;
        position: relative;
        padding-left: 32px;
    }

    .icon-info {
        color: $main-black;
        font-size: 22px;
        margin-top: 0;
        width: 32px;
        height: 22px;
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
    }
}
