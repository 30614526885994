@import 'src/mixins/breakpoints.scss';

.hme-client-login-page {
    .hme-client-login-page__forgot-link {
        display: block;
        width: fit-content;
        margin-left: auto;
        margin-right: 36px;
        text-align: right;
        margin-top: 20px;
        font-family: Assistant;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.13;
        letter-spacing: normal;
        color: #0074ef;
    }

    @include before-desktop-only {
        & {
            .hme-client-login-page__forgot-link {
                padding-right: 0;
            }
        }
    }
}
