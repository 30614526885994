.admin-apply-device-settings-taskbar {
    padding: 0 0 23px 0;
    display: flex;
    line-height: 48px;
    vertical-align: middle;

    .hme-title {
        display: inline-block;
        flex: none;
        line-height: 1.5;
    }

    .hme-grid-filters-count {
        align-items: flex-start;
    }

    .admin-apply-device-settings-taskbar-separated {
        height: 94px;
        border-right: solid 1px #e5e5e5;
        display: flex;

        .admin-apply-device-settings-taskbar-separated-label {
            flex: none;
            height: 100%;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.31;
            letter-spacing: 1.44px;
            color: #606672;
            text-transform: unset;
            padding-top: 13px;
        }

        .admin-apply-device-settings-taskbar-separated-content {
            flex: 1;
            height: 100%;
            margin-left: 8px;
        }

        .hme-toggle {
            margin-bottom: 6px;
        }
    }

    .admin-apply-device-settings-taskbar-search-group {
        flex: 40;
        padding-left: 37px;
        padding-right: 37px;
    }

    .hme-admin-header-date-filter {
        flex: 39;
        padding-left: 27px;
        padding-right: 27px;
    }

    .admin-apply-device-settings-taskbar-filters-count {
        flex: 21;
        padding-left: 30px;
        padding-right: 30px;

        &.admin-apply-device-settings-taskbar-separated {
            border-right: none;
        }
    }

    .admin-apply-device-settings-taskbar-link {
        text-align: right;
        flex: none;
        display: flex;
        justify-content: flex-end;

        a {
            display: block;
            height: 40px;
            font-size: 16px;
        }

        .hme-button-component {
            flex: none;
            display: block;
            height: 40px;
        }
    }
}
