.hme-messages-landing-container-s {
  & > .hme-tiles-container {
    & > .hme-tile {
      display: flex;
      flex-direction: column;
      padding: 8px 0 8px 8px;
      & > .hme-manage-messages-tile-inner {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        min-width: 340px;
        min-height: 100%;
        position: relative;
        & > .category-icon {
          & > .hme-manage-messages-category {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-start;
            & > img {
                width: 56px;
                height: 56px;
                margin: 0 8px 25px 0;
                padding: 0 0 4.2px;
                border: solid 2px #0074ef;
                border-radius: 50%;
                object-fit: contain;
            }
            & > .smacktalk-manage-messages-categories-title {
                display: none;
            }
          }
        }
        & > .manage-messages-data {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          height: 100%;
          margin: 0 8px 0 0;
          & > .manage-messages-info {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            & > .category-label {
              font-family: Assistant;
              font-size: 16px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.31;
              letter-spacing: 0.3px;
              text-align: left;
              color: #000819;
            }
            & > .manage-messages-date {
              margin: 0 0 11px 5px;
              font-family: Assistant;
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.29;
              letter-spacing: normal;
              text-align: right;
              color: #606672;
            }
          }
          & > .manage-messages-list {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin: 0;
            & > div {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: flex-start;
              width: 100%;
              height: max-content;
              & > dt {
                width: 100px;
                font-family: Assistant;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 2;
                letter-spacing: 1.08px;
                text-align: left;
                color: #606672;
              }
              & > dd {
                font-family: Assistant;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.71;
                letter-spacing: 0.27px;
                text-align: left;
                color: #000819;
                margin: 0 12px 0 0;
                word-break: break-word;
              }
      
            }
          }
        }
      }
      & > .hme-createdBy {
        display: flex;
        align-items: center;
        padding-left: 80px;
        justify-content: left;
        width:100%;

        & > .hme-creatdBy-title {
          padding-right: 8px;
          color: #a3a8b1;
          font-family: Assistant;
          font-size: 12px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: 1.08px;
          text-transform: uppercase;
          // width: 40%;
        }
        & > .hme-createdBy-email {
          width: 60%;
          white-space: nowrap;
          overflow:hidden;
          text-overflow: ellipsis;
          color: #000819;
          font-size: 14px;
          font-family: Assistant;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.71;
          letter-spacing: 0.27px;
          
        }

        & > .hme-createdOn-email {
          width: 60%;
          white-space: nowrap;
          overflow:hidden;
          text-overflow: ellipsis;
          color: #000819;
          font-size: 14px;
          font-family: Assistant;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.29;
          letter-spacing: 0.42px;
          
        }
      }
      & > .hme-manage-messages-tile-controls {
        width: 100%;
        display: flex;
        justify-content: space-around;
        padding: 15px 0 8px 0;

        & > .hme-button-component {
          width: 49%;
        }
      }
    }
  }
}
