.hme-select-stacked {
    width: 100%;
    height: 48px;
    border-radius: 24px;
    border: solid 1px #a7adba;
    background-color: #ffffff;
    position: relative;
    padding-right: 52px;

    div.hme-label {
        margin-left: 25px;
        margin-top: 3px;
        font-size: 12px;
        line-height: 1.33;
        letter-spacing: 1.08px;
        color: #606672;
        position: relative;
        text-transform: none;

        &:before {
            position: absolute;
            left: -9px;
            top: 2px;
            content: '*';
            font-family: Assistant;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: 1.44px;
            text-align: left;
            color: #186df4;
        }
    }

    .hme-select-stacked-text {
        font-family: Assistant;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.31;
        letter-spacing: 1.44px;
        text-align: left;
        color: #000819;
        margin-left: 25px;
        margin-top: 2px;
    }

    .hme-select-stacked-required {
        margin: 22px 2px 0 0;
        font-family: Assistant;
        font-size: 16px;
        font-weight: bolder;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 1.44px;
        text-align: left;
        color: #186df4;
        height: 15px;
        margin-top: 30px;
    }

    .hme-select-stacked-without-label {
        font-family: Assistant;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.31;
        letter-spacing: 1.44px;
        text-align: left;
        color: #000819;
        margin-left: 25px;
        margin-top: 14px;
    }

    .hme-select-stacked-icon {
        position: absolute;
        right: 6px;
        top: 10px;
        width: 42px;
        height: 24px;
        color: #606672;
    }

    &.hme-select-stacked-opened {
        border: 2px solid #0074ef;

        div.hme-label {
            margin-left: 24px;
            margin-top: 2px;
        }

        .hme-select-stacked-text {
            margin-left: 24px;
        }

        .hme-select-stacked-icon {
            transform: rotate(180deg);
            color: #0074ef;
        }
    }
}
.hme-select-stacked-dropdown {
    list-style-type: none;
    position: relative;;
    top: 0;
    left: 0;
    right: 0;
    margin-top: 8px;
    border-radius: 8px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    padding: 8px 0;
    display: block;
    overflow: auto;
    max-height: 200px;
    z-index: 100;

    .hme-select-stacked-dropdown-item {
        width: 100%;
        height: 40px;
        font-family: Assistant;
        padding: 10px 15px;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.31;
        letter-spacing: 1.28px;
        text-align: left;
        color: #000819;

        &:hover {
            background-color: #2c6df4;
            font-weight: 600;
            color: #ffffff;
        }
    }
}
