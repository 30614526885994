.hme-awards-reset-details-tab {
  & > .hme-grid {
    & > .hme-grid-header > .hme-grid-row, .hme-grid-row {
      & > .hme-grid-cell {
        &:last-child {
          text-align: right;
          & > .hme-grid-cell-content {
            padding: 0 30px 0 0;
          }
        }
        &:first-child {
          text-align: left;
          & > .hme-grid-cell-content {
            padding: 0;
          }
        }
      }
    }
  }
}