@import 'src/assets/styles/Variables';

.hme-error-list {
    padding: 0;
    margin: 0;
    list-style-type: none;

    font-family: $font-family;
    font-size: 12px;

    &--error {
        color: $error-field;
    }
}