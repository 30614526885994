@import 'src/mixins/breakpoints.scss';

.hme-distributor-accounts__header__wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 40px;

  .hme-title {
    padding-right: 30px;
  }

  .hme-search-optionable {
    width: 615px;
    padding-right: 30px;

    .hme-select {
      width: 226px;

      .hme-label {
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
      }

      .hme-select-text {
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
      }
    }
  }

  .hme-distributors-accounts-filters-count {
    display: none;
  }
}

@include desktop-only {
  .hme-distributor-accounts__header__wrapper {
    .hme-distributors-accounts-filters-count {
      display: flex;
      align-items: center;
      height: 49px;
      padding-left: 30px;
      border-left: solid 1px #e5e5e5;
    }
  }
}
