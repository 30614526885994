@import 'src/mixins/breakpoints.scss';

@include before-desktop-only {
    .hme-settings-display {
        --section-field-gap: 20px;
        --header-items-gap: 20px;

        &__wrapper {
            padding: var(--section-field-gap);
            padding-bottom: 80px;
        }

        &__page-title {
            font-size: 20px;
        }

        &__main-content {
            flex-direction: column;
            gap: var(--section-field-gap);
        }

        &__section {
            border-left: none;

            .hme-title {
                font-size: 18px;
            }

            &:not(:last-of-type),
            &:not(:first-of-type) {
                padding: 0;
            }
        }

        &__section-column {
            max-width: 50%;
        }

        &__section:not(:last-of-type) &__section-column:first-of-type {
            padding-bottom: var(--section-field-gap);
            border-bottom: 1px solid var(--border-color);
        }
    }
}