@import 'src/mixins/breakpoints.scss';
@import 'src/assets/styles/Variables';

@include before-desktop-only {
    .hme-password-change-field {
        &__btn.hme-button-component {
            top: -2px;
            padding: 20px;
            padding-right: 0;

            font-size: 14px;
        }

        &__fake-input {
            .hme-label {
                font-size: 14px;
                color: $main-gray;
            }
            .hme-account-input {
                min-height: 55px;
            }
        }

        &__modal .hme-modal-component {
            .modal-content {
                margin-top: 0;
                padding-bottom: 60px;
            }
        }
    }
}
