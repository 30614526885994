.hme-api-management-section {
    flex-grow: 1;
    max-width: 405px;
    height: unset;
    min-height: 500px;
    padding: 0 40px;
    overflow-y: unset;

    &:first-child {
        border-left: none;
        padding-left: 0;
    }
    &:last-child {
        border-right: none;
        padding-right: 0;
    }

    &:not(:first-of-type):not(:last-child) {
        max-width: 465px;
    }

    &--stretch {
        max-width: none;
    }

    .hme-title {
        margin-bottom: 20px;
    }

    &__content {
        display: grid;
        gap: 20px;

        & > * {
            .hme-label {
                margin-bottom: 4px;
            }
        }
    }
}
