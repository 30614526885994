.hme-admin-account-header {
    padding: 0 0 23px 0;
    display: flex;
    vertical-align: middle;
    justify-content: space-between;

    .hme-admin-account-header-title-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
    }

    .hme-title {
        display: inline-block;
        flex: none;
        line-height: 1.5;
    }
}
