.hme-view-role__general {
    .hme-input-container {
        width: 345px;
        margin-bottom: 20px;
    }

    .hme-textarea {
        width: 345px;
        height: 140px;
    }
}

@import './Monile.scss';
