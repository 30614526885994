/* More button */
.more-M .caret, #leaderboardMore .caret, #settingsMore .caret, #accountsettingMore .caret {
  display: none; }

#accountsettingMore {
  display: none;
}

.more-M .dropdown-menu,
#leaderboardMore .dropdown-menu,
#settingsMore .dropdown-menu,
#accountsettingMore .dropdown-menu {
  top: 130%; 
  left: 0 !important;
  right: auto;
}
#accountsettingMore .dropdown-menu a:hover::after,
#accountsettingMore .dropdown-menu a.active_tab::after {
  content: none;
}
#accountsettingMore span .fa {
  transform-origin: 50% 25%;
  transition: all ease-out .2s; }
#accountsettingMore:focus span .fa {
  transform: rotateZ(180deg); }
#mainMore {
  color: var(--gray-62);
  font-family: Assistant, sans-serif;
  font-size: 14px;
  font-weight: 600 !important;
  font-style: normal;
  font-stretch: normal !important;
  line-height: 1.29 !important;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  padding-top: 15px;
  color: var(--gray-62) }
  #mainMore:hover, .dropdown-menu .dropdown-item:hover {
    color: var(--blue-100); }
  .dropdown.show #mainMore span .fa {
    transform: rotateZ(180deg); }
  #mainMore span {
    display: flex;
    align-items: center; }
  
  .more-M .dropdown-toggle::after {
    content:none;
  }

  #mainMore span .fa {
    transform-origin: 35% 50%;
    transition: all ease-out .2s; }

.more-M a.dropdown-item,
#leaderboardMore a.dropdown-item,
#settingsMore a.dropdown-item {
  display: flex;
  align-items: center; }
  .more-M a.dropdown-item .fa,
  #leaderboardMore span span.fa,
  #settingsMore span span.fa {
    top: 4px;
    align-self: flex-end;
    transform-origin: 40% 30%;
    transition: all ease-out .2s; }
  .more-M a.dropdown-item:focus span,
  #leaderboardMore:focus span span,
  #settingsMore:focus span span {
    transform: rotateZ(180deg); }
  #settingsMore:hover {
    color: var(--blue-100);}
#leaderboardMore li a .fa, #settingsMore li a .fa {
  font-size: 100%; }

#leaderboardMore .fa, #settingsMore .fa {
  font-size: 100%; }

#leaderboardMore li a:hover::after, #settingsMore a.dropdown-item:hover::after {
  content: none; }

.more-M .fa, #leaderboardMore .fa, #settingsMore .fa, #accountsettingMore .fa {
  right: 0px;
  margin-left: 9px;
  width: 16px;
  height: 16px;
  font-size: 80%;
  object-fit: contain; }

#accountsettingMore .fa {
  top: 4px;
}

#leaderboardMore a.dropdown-item:hover::after,
#settingsMore a.dropdown-item:hover::after {
  content: none; }

#leaderboardMore a.dropdown-item:hover,
#settingsMore a.dropdown-item:hover {
  color: var(--blue-100); }

.more-l, .more-s, .more-M {
  display: none; }
.more-l a.dropdown-item, .more-s a.dropdown-item, .more-M a.dropdown-item {
  outline: none !important; }

#leaderboardMore .dropdown-menu .dropdown-item,
#settingsMore .dropdown-menu .dropdown-item,
.more-M .dropdown-menu .dropdown-item {
  display: none; }

.more-l #leaderboardMore {
  padding: 0; }

.more-l:hover a.dropdown-item {
  color: var(--gray-62) !important; }

.more-l a.dropdown-item:hover {
  color: var(--blue-100) !important; }

@media screen and (max-width: 372px) {
  .subHeaders {
    flex-wrap: nowrap; }
  .more-l {
    display: flex;
    align-items: center;
    padding-top: 11px; }
  #leaderboard .nav-item:nth-child(4) {
    display: none; }
  #leaderboardMore .dropdown-menu {
    height: 45px; }
  #leaderboardMore .dropdown-menu.show .headerMenu:nth-child(4) {
    display: block; } }

@media screen and (max-width: 333px) {
  #leaderboard .nav-item:nth-child(3) {
    display: none; }
  #leaderboardMore .dropdown-menu {
    height: 90px; }
  #leaderboardMore .dropdown-menu.show .headerMenu:nth-child(3) {
    display: block; } }

@media screen and (max-width: 265px) {
  #leaderboard .nav-item:nth-child(2) {
    display: none; }
  #leaderboardMore .dropdown-menu {
    height: 110px; }
  #leaderboardMore .dropdown-menu a.shows:nth-child(2) {
    display: block; } }

@media screen and (max-width: 200px) {
  #leaderboard .nav-item:nth-child(1) {
    display: none; }
  #leaderboardMore .dropdown-menu {
    height: 150px;
    left: -32px; }
  #leaderboardMore .dropdown-menu a.shows:nth-child(1) {
    display: block; } }

.more-s #settingsMore {
  padding: 0; }

@media screen and (max-width: 300px) {
  .more-s {
    display: flex;
    align-items: center;
    padding-bottom: 2px !important; }
  #settings .nav-item:nth-child(3) {
    display: none; }
  #settingsMore .dropdown-menu {
    height: 50px; }
  #settingsMore .dropdown-menu a.dropdown-item:nth-child(3) {
    display: block; } }

@media screen and (max-width: 250px) {
  #settings .nav-item:nth-child(2) {
    display: none; }
  #settingsMore .dropdown-menu {
    height: 80px; }
  #settingsMore .dropdown-menu a.dropdown-item:nth-child(2) {
    display: block; } }

@media screen and (max-width: 200px) {
  .more-s {
    display: flex; }
  #settings .nav-item:nth-child(1) {
    display: none; }
  #settingsMore .dropdown-menu {
    height: 110px;
    left: -32px;
    max-width: 50vw; }
  #settingsMore .dropdown-menu a.dropdown-item:nth-child(1) {
    display: block; }
  #settingsMore .dropdown-menu a.active_tab_sub::after {
    content: none; } }

.more-M .dropdown-menu {
  min-width: 190px;
  right: auto; }
.more-M .dropdown-menu a.dropdown-item i.icon {
  font-size: 150%; }
.more-M .dropdown-menu a.dropdown-item::before, .more-M .dropdown-menu a.dropdown-item::after {
  content: none; }

/* user main menu more button */
@media screen and (max-width: 1170px) and (min-width: 768px) {
  .user-main-header .more-M {
    display: block; }
  .user-main-header .nav-item:nth-child(6) {
    display: none; }
  .user-main-header .more-M .dropdown-menu {
    height: 60px; }
  .user-main-header .more-M .dropdown-menu a.dropdown-item:nth-child(6) {
    height: 50px;
    display: flex;
    margin-left: 9px; } }

@media screen and (max-width: 1140px) and (min-width: 768px) {
  .user-main-header .nav-item:nth-child(5) {
    display: none; }
  .user-main-header .more-M .dropdown-menu {
    height: 110px; }
  .user-main-header .more-M .dropdown-menu a.dropdown-item:nth-child(5) {
    height: 50px;
    display: flex; } }

@media screen and (max-width: 1045px) and (min-width: 768px) {
  .user-main-header .nav-item:nth-child(4) {
    display: none; }
  .user-main-header .more-M .dropdown-menu {
    height: 160px; }
  .user-main-header .more-M .dropdown-menu a.dropdown-item:nth-child(4) {
    height: 50px;
    display: flex; } }

@media screen and (max-width: 916px) and (min-width: 768px) {
  .user-main-header .nav-item:nth-child(3) {
    display: none; }
  .user-main-header .more-M .dropdown-menu {
    height: 210px; }
  .user-main-header .more-M .dropdown-menu a.dropdown-item:nth-child(3) {
    height: 50px;
    display: flex; } }

@media screen and (max-width: 821px) and (min-width: 768px) {
  .user-main-header .nav-item:nth-child(2) {
    display: none; }
  .user-main-header .more-M .dropdown-menu {
    height: 270px; }
  .user-main-header .more-M .dropdown-menu .dropdown-item:nth-child(2) {
    height: 50px;
    display: flex; } }

/* admin main menu more button */
@media screen and (max-width: 1165px) and (min-width: 768px) {
  .admin-main-header .more-M {
    display: block; }
  .admin-main-header .nav-item:nth-child(7) {
    display: none; }
  .admin-main-header .more-M .dropdown-menu {
    height: 60px; }
  .admin-main-header .more-M .dropdown-menu .dropdown-item:nth-child(7) {
    height: 50px;
    display: flex; } }

@media screen and (max-width: 1155px) and (min-width: 768px) {
  .admin-main-header .nav-item:nth-child(6) {
    display: none; }
  .admin-main-header .more-M  .dropdown-menu {
    height: 110px; }
  .admin-main-header .more-M .dropdown-menu .dropdown-item:nth-child(6) {
    height: 50px;
    display: flex; } }

@media screen and (max-width: 1027px) and (min-width: 768px) {
  .admin-main-header .nav-item:nth-child(5) {
    display: none; }
  .admin-main-header .more-M .dropdown-menu {
    height: 160px; }
  .admin-main-header .more-M .dropdown-menu a.dropdown-item:nth-child(5) {
    height: 50px;
    display: flex; } }

@media screen and (max-width: 930px) and (min-width: 768px) {
  .admin-main-header .nav-item:nth-child(4) {
    display: none; }
  .admin-main-header .more-M .dropdown-menu {
    height: 210px; }
  .admin-main-header .more-M .dropdown-menu a.dropdown-item:nth-child(4) {
    height: 50px;
    display: flex; } }

@media screen and (max-width: 830px) and (min-width: 768px) {
  .admin-main-header .nav-item:nth-child(3) {
    display: none; }
  .admin-main-header .more-M .dropdown-menu {
    height: 270px; }
  .admin-main-header .more-M .dropdown-menu a.dropdown-item:nth-child(3) {
    height: 50px;
    display: flex; } }
