.create-device-upgrade-page {
  height: 100%;
  overflow-y: auto !important; }
  .create-device-upgrade-page.hmeBody {
    width: 100% !important; }
  .create-device-upgrade-page .hme-admin-system-status {
    width: 85%;
    min-height: 40px;
    margin: 0 auto; }
  .create-device-upgrade-page .hme-account-wrapper {
    height: 100%;
    min-width: 1380px; }
  .create-device-upgrade-page .hme-account {
    height: 100%;
    display: flex;
    flex-direction: column; }
  .create-device-upgrade-page .hme-account-content {
    display: flex;
    padding-bottom: 20px; }
    .create-device-upgrade-page .hme-account-content .hme-section-sm {
      flex: 1; }
    .create-device-upgrade-page .hme-account-content .hme-section-md {
      flex: 2; }
  .create-device-upgrade-page .hme-section-first {
    padding: 0 20px 30px 0; }
  .create-device-upgrade-page .hme-account-form-section {
    margin-bottom: 20px; }
    .create-device-upgrade-page .hme-account-form-section .hme-label {
      margin-bottom: 8px; }
    .create-device-upgrade-page .hme-account-form-section .hme-radio .hme-label {
      font-weight: bold;
      margin-bottom: 0; }
    .create-device-upgrade-page .hme-account-form-section .hme-onoff-slider-component .hme-checkbox-slider-label-text {
      padding-left: 8px;
      text-transform: uppercase; }
    .create-device-upgrade-page .hme-account-form-section .hme-account-form-description {
      padding-left: 27px;
      margin-bottom: 10px; }
      .create-device-upgrade-page .hme-account-form-section .hme-account-form-description.disabled {
        color: #606672; }
    .create-device-upgrade-page .hme-account-form-section.hme-account-subscription-types {
      border-top: 1px solid #e8e9ed;
      display: flex;
      padding: 20px 0 0; }
    .create-device-upgrade-page .hme-account-form-section.hme-account-billing-cycle-account-status {
      padding: 0;
      margin: 0; }
      .create-device-upgrade-page .hme-account-form-section.hme-account-billing-cycle-account-status .hme-section {
        padding-bottom: 0; }
    .create-device-upgrade-page .hme-account-form-section.hme-account-billing-cycle-account-status, .create-device-upgrade-page .hme-account-form-section.hme-account-subscription-date-range-resend-email {
      display: flex; }
      .create-device-upgrade-page .hme-account-form-section.hme-account-billing-cycle-account-status .hme-section, .create-device-upgrade-page .hme-account-form-section.hme-account-subscription-date-range-resend-email .hme-section {
        border-left: 0;
        padding-bottom: 0; }
  .create-device-upgrade-page .hme-section.hme-section-without-title .hme-account-form-area .hme-title {
    display: block;
    margin-bottom: 20px; }
  .create-device-upgrade-page .hme-section.hme-section-without-title .hme-account-form-area .hme-account-billing-cycle-account-status .hme-section,
  .create-device-upgrade-page .hme-section.hme-section-without-title .hme-account-form-area .hme-account-subscription-date-range-resend-email .hme-section {
    overflow: visible; }
    .create-device-upgrade-page .hme-section.hme-section-without-title .hme-account-form-area .hme-account-billing-cycle-account-status .hme-section.hme-section-without-title .hme-title,
    .create-device-upgrade-page .hme-section.hme-section-without-title .hme-account-form-area .hme-account-subscription-date-range-resend-email .hme-section.hme-section-without-title .hme-title {
      display: none; }

.accounts-page.hme-page-component {
  padding: 27px 0 0 0; }

body.modal-open div.hme-tooltip.device-upgrade-tooltip {
  z-index: 100 !important; }
