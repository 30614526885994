.hme-manage-hierarchy__hierarchy__item--add-item {
    display: flex;
    gap: 10px;

    .hme-input-component {
        height: 30px;
        border-radius: 15px;
        padding: 0 10px;
        width: 325px;

        &:focus {
            padding: 0 9px;
        }
    }
}

@import './Mobile.scss';
