.hme-tree {
    &__empty-message {
        display: block;
        text-transform: none;
        width: 100%;
        text-align: center;
    }
}

@import './variants/bordered.scss';
