@import '../../../mixins/breakpoints.scss';


@include before-desktop-only {
    .hme-manage-hierarchy {
        padding: 20px 15px 40px 15px;
        display: block;

        .hme-title {
            font-size: 20px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.28px;
        }

        &__hierarchy {
            margin-right: 0;
        }
    }
}
