.multiple-checkbox-input {
  width: max-content;
}
.multiple-checkbox-input__inputs {
  display: flex;
  margin-left: -0.5em;
}
.multiple-checkbox-input__inputs .hme-input {
  padding: 0 0.5em;
}
.multiple-checkbox-input__inputs--vertical {
  flex-direction: column;
}
