.create-snapshot-setting-info-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 4px 0 0;

    .create-snapshot-setting-info-form-box {
        margin: 0 0 10px;

        .hme-input-container {
            margin: 0;
        }

        &.create-snapshot-setting-info-form-settings-version {
            width: 300px;
        }
    }

    .create-snapshot-setting-info-form-section {
        display: flex;
        justify-content: space-between;

        .create-snapshot-setting-info-form-sliders {
            display: flex;
            justify-content: space-between;
            min-width: 320px;
            margin-left: 40px;

            .create-snapshot-setting-info-form-box {
                display: flex;
                flex-direction: column;
                margin: 0;

                .hme-label {
                    margin: 0 0 8px;
                    font-family: Assistant;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 1.5;
                    letter-spacing: 1.44px;
                    color: #000819;
                }
            }
        }
    }
}
