@import "../../assets/styles/Variables";
@import "../../assets/styles/Responsive";

.hme-login-page-container {
    .hme-login-page {
        width: 100%;
        height: 100%;
        width: fit-content;
        width: fit-content;
        margin: 0 auto;
        min-height: calc(100vh - 255px);
        padding-bottom: 40px;
    }

    .Toastify {
        min-height: 110px;

        .Toastify__toast-container--top-center.hme-login-page-notifications-list {
            position: static !important;
            transform: none;
            display: flex;
            justify-content: center;
            margin-top: 20px;

            .Toastify__close-button {
                display: none;
            }
        }
    }

    @include smaller($l-desktop) {
        & {
            .hme-login-page {
                width: 100%;
                height: 100%;
                width: fit-content;
                width: fit-content;
                margin: 0 auto 20px auto;
                min-height: calc(100vh - 385px);
            }

            .Toastify {
                min-height: 11px;
                margin-bottom: 15px;
                width: 100%;
            }
        }
    }
}
