@import 'src/mixins/breakpoints.scss';

@include mobile-only {
  .hme-partner-api-management-renew-token-modal {
    max-width: 90%;

    &__content  {
      gap: 30px;
      max-width: 325px;
      min-width: 325px;
      padding: 15px;
    }

    &__title {
      font-size: 21px;
    }

    &__description {
      font-size: 16px;
    }

    .hme-radio-group-item__label {
      font-size: 16px;
    }

    .hme-partner-api-management-renew-token-modal__options {
      padding: 0 0 0 15px;
    }

    .hme-partner-api-management-renew-token-modal__btns-container {
      flex-direction: column-reverse;
    }

    .hme-partner-api-management-renew-token-modal__form .email-container {
      padding: 0 5px 0 5px;
    }

    .hme-button-component {
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.32px;
    }

    .hme-input-date-picker-variant .hme-calendar-selector {
      left: -45px;
      padding: 0;
    }

  }
}
