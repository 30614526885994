.hme-stores-status--partner {
    overflow: auto;
    min-width: max-content;
    height: 100%;
    padding: 44px 57px 39px 37px;
    display: flex;
    flex-direction: column;

    .hme-stores-status--partner__header {
        margin-bottom: 31px;
        .hme-stores-status--partner__header__sort-btn {
            display: none;
        }
    }

    .hme-stores-status--partner__grid {
        flex: 1;
        min-width: 1823px;
    }

    .hme-stores-status--partner__paginate {
        margin-top: 40px;
        flex: 0;
    }
}

@import './Tablet.scss';
@import './Mobile.scss';

