.view-device-upgrade-info-status {
  margin: 0 0 20px; }
  .view-device-upgrade-info-status .hme-info-field {
    padding: 10px 0;
    border-bottom: 1px solid #e8e9ed; }
    .view-device-upgrade-info-status .hme-info-field.view-device-upgrade-info-status-field .hme-info-field-content {
      font-weight: 900; }
    .view-device-upgrade-info-status .hme-info-field.view-device-upgrade-info-status-field .view-device-upgrade-info-status-icon {
      margin-top: -11px; }
    .view-device-upgrade-info-status .hme-info-field .hme-label {
      font-size: 12px;
      line-height: 1.33;
      letter-spacing: 1.08px;
      text-align: left;
      color: #606672; }
    .view-device-upgrade-info-status .hme-info-field .hme-info-field-content {
      font-size: 15px;
      font-weight: 600;
      display: flex;
      overflow-wrap: break-word;
      justify-content: space-between; }
