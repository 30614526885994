.tree-select-input--strong-root-nodes > div.rc-tree span.rc-tree-switcher:not(.rc-tree-switcher-noop) ~ span.rc-tree-node-content-wrapper {
  font-weight: bold;
}
.tree-select-input--insensitive-text-selection {
  user-select: none;
}
.tree-select-input > div.rc-tree-show-line {
  border: 1px solid #bdbdbd;
}
.tree-select-input.tree-select-input--insensitive-text-selection {
  height: calc(100% - 53px);
  overflow-y: auto;
}

.rc-tree .rc-tree-treenode {
  margin: 0;
  padding: 0;
  line-height: 17px !important;
  white-space: nowrap;
  list-style: none;
  outline: 0;
}
.rc-tree .rc-tree-treenode span.rc-tree-node-content-wrapper {
  display: inline-block;
  padding: 1px 3px 0 0;
  margin: 0;
  cursor: pointer;
  height: 17px;
  text-decoration: none;
  vertical-align: top;
}
.rc-tree .rc-tree-treenode span.rc-tree-node-content-wrapper > .rc-tree-title {
  width: 100%;
}
