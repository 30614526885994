@import 'src/mixins/breakpoints.scss';

@include mobile-only {
    .hme-view-user__general {
        .hme-input-container {
            width: 100%;
            margin-bottom: 15px;
        }
    
        .hme-toggle {
            width: 100%;
            margin-bottom: 15px;

            .hme-label {
                font-size: 18px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.28;
                letter-spacing: 0.18px;
                color: #000819;
            }
        }
    }
}

@include tablet-only {
    .hme-view-user__general {
        .hme-toggle {
            margin-bottom: 15px;
            margin-right: 20px;

            .hme-components & .hme-label {
                font-size: 18px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.28;
                letter-spacing: 0.18px;
                color: #000819;
            }
        }
    }
}
