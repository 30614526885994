@import 'src/assets/styles/Variables';

.hme-stores-status--partner__header {
    display: flex;
    width: 100%;
    align-items: center;

    .hme-search-optionable {
        width: 615px;
        margin-left: 40px;
    }
}
