.hme-admin-accounts-header {
    padding: 0 0 23px 0;
    display: flex;
    vertical-align: middle;
    justify-content: space-between;
    
    .hme-admin-accounts-header-title-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
    }

    .hme-admin-accounts-header-link {
        display: flex;
        align-items: center;
    }

    .hme-submit-button-component {
        width: 104px;
        height: 48px;
        border: 1px;
        margin: 0;
        border-radius: 0 24px 24px 0;
      }

    .hme-search-optionable {
        padding: 0 40px;

        .hme-select {
            width: 224px;
            min-width: 224px;
        }

        .hme-input-container {
            width: 271px;
        }
    }   

    .hme-title {
        display: inline-block;
        flex: none;
        line-height: 1.5;
    }
}
