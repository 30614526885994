.hme-system-status {
    padding: 50px;
    
    &__content {
        display: flex;
        width: 100%;
    }

    &__section-item {
        height: auto;

        &:first-child {
            border-left: none;
        }
    }

    .hme-section {
        overflow: visible;
    }
}
  