.image-input {
  display: flex;
  align-items: flex-end; }
  .image-input__toggle-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    font-weight: bold; }
  .image-input__preview {
    max-width: 100px;
    min-height: 100%; }
    .image-input__preview img {
      width: 100%; }
  .image-input__change-image {
    width: 155px;
    font-size: 14px; }
  .image-input__change-image input {
    display: none; }
