.api-management-form-page {
    height: 100%;
    overflow-y: auto;
    padding-bottom: 82px;
    padding-top: 20px;

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
    }

    &-sections {
        display: flex;
        margin-bottom: 50px;
    }

    .hme-input-auto-complete-variant.token-manager-email-input .hme-auto-complete-suggestions {
        width: 280px;

        text-align: center;

        .hme-grid-header {
            display: none;
        }
    }

    .hme-radio-group {
        .hme-label {
            margin-left: 10px;
        }
    }
}
