.hme-header--client {
    .hme-header--mobile {
        .hme-menu-item--with-home-icon .hme-menu-item__icon {
            margin-top: -10px;
        }

        .hme-menu-item--with-dashboard-icon .hme-menu-item__icon {
            margin-top: -7px;
        }

        .hme-menu-item--with-reports-icon .hme-menu-item__icon {
            margin-top: -5px;
        }

        .hme-menu-item--with-settings-icon .hme-menu-item__icon {
            margin-top: -7px;
        }
    }
}

.main-header.relative {
    position: relative;
    z-index: 100;
}
