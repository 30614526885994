@import 'src/assets/styles/Variables';

.hme-form-button {
    --padding-inline: 0;

    appearance: none;
    margin: 0;

    padding: 8px var(--padding-inline);
    font-family: $font-family;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: $letter-spacing-032;

    border-radius: 20px;
    border: none;

    // variant
    &--default {
        background-image: linear-gradient(to bottom, $main-white, $gradient-gray);
        color: $main-black;
        outline: 1px solid $gray-25;
        outline-offset: -1px;
    }

    &--primary {
        background-image: linear-gradient(to bottom, $hme-blue-color, $hme-dark-blue-color);
        color: $main-white;

        &[disabled] {
            opacity: 0.14;
        }

        .hme-form-button__icon {
            --color: #{$main-white};
        }
    }

    &--link {
        padding: 0;

        color: $main-color;
        font-weight: 400;
        letter-spacing: normal;
        line-height: 1.13;
        text-align: center;
    }

    // size
    &--size-default {
        --padding-inline: 25px;

        min-width: 140px;
    }

    &--size-lg {
        --padding-inline: 25px;

        min-width: 180px;
    }
}
