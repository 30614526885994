$main-color: #0074ef;

.hme-joined-filters-popup-section {
    flex: 1;
    &::-webkit-scrollbar-thumb {
        background-color: $main-color;
        border-radius: 4px;
    }
    &::-webkit-scrollbar {
        width: 6px;
        height: 15px;
    }
    & > .hme-label.hme-joined-filters-popup-no-items-found {
        display: inline-block;
        width: 100%;
        text-align: center;
        text-transform: unset;
    }

    & > .hme-joined-filters-popup-row {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        padding-top: 14px;
        padding-bottom: 14px;

        &:last-of-type {
            padding-bottom: 0;
        }

        .hme-checkbox-component {
            flex: 1;
            display: flex;
            overflow: hidden;

            .hme-checkbox-component-icon {
                flex: none;
                margin-top: 2px;
            }

            .hme-checkbox-label {
                flex: 1;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .hme-joined-filters-popup-only-label {
            font-family: Assistant;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.31;
            letter-spacing: 1.28px;
            text-align: left;
            color: $main-color;
            text-decoration: underline;
            padding-left: 15px;
            display: inline-block;
            margin: 0 40px 0 auto;
            flex: none;

            span {
                cursor: pointer;
            }
        }

        &.hme-joined-filters-popup-item {
            padding-left: 15px;
        }
    }
}