@import 'src/mixins/breakpoints.scss';

@include tablet-only {
    .hme-view-user__access {
        padding-left: 20px;

        .hme-single-select {
            width: 345px;
            margin-bottom: 15px;
        }
    }
}

@include mobile-only {
    .hme-view-user__access {
        padding-left: 0;

        .hme-single-select {
            width: 100%;
            margin-bottom: 15px;
        }
    }
}
