.leaderboard-templates-1-section {
    & > .hme-title {
        margin: 0 0 20px 0;
    }
    & > .hme-toggle {
        margin: 0 0 40px 0;
    }
    & > .hme-label {
        margin: 0 0 8px 0;
    }
    & > .hme-titled-row > .hme-titled-row-group {
        display: flex;

        & > .col-left, & > .col-right {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        & > .col-left {
            text-align: left;
            flex: 1;
        }
        & > .col-right {
            width: 135px;
            text-align: right;
            width: max-content;
        }
    }

    & > .hme-input-container {
        margin: 0 0 20px 0;
        & > .hme-label {
            margin: 0 0 8px 0;
        }
    }

    .leaderboard-templates {
        &__row--used-by .hme-select-dropdown {
            height: 205px;
        }
    }
}
body.modal-open > div.fade.hme-tooltip{
    z-index: 1070!important;
}