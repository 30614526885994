@import 'src/mixins/breakpoints.scss';

@include before-desktop-only {
    .hme-roles {
        padding: 22px 15px;
        min-width: unset;
        overflow: auto;

        .hme-grid {
            width: 0;
            min-width: unset;
            display: none;
        }

        .hme-roles__tile-list {
            margin-top: 20px;
        }

        .hme-title {
            font-size: 20px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.3;
            letter-spacing: -0.28px;
        }

        .hme-grid {
            padding-bottom: 0;
        }

        .hme-tile-list__wrapper {
            padding: 0;
        }
    }
}