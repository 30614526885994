.hme-view-store-settings-task-info-block.hme-section {
    padding-right: 50px;

    .hme-info-field {
        margin-bottom: 20px;

        &.hme-view-store-settings-task-info-block-status {
            .hme-info-field-content {
                font-weight: 600;
            }
        }

        &.hme-view-store-settings-task-info-block-creted {
            .hme-info-field-content {
                display: flex;

                .hme-view-store-settings-task-info-block-creted-by {
                    flex: 1;
                    display: block;
                    padding-right: 10px;
                }

                .hme-view-store-settings-task-info-block-creted-date {
                    flex: none;
                    text-align: right;
                    display: block;
                }
            }
        }
    }
}