.hme-view-store-settings-task-uploaded-devices-header {
    width: 100%;
    display: flex;

    .hme-view-store-settings-task-uploaded-devices-header-count {
        flex: none;
        font-family: Assistant;
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 0.02px;
        color: #000819;
        margin-top: auto;
        margin-bottom: 8px;

        .hme-view-store-settings-task-uploaded-devices-header-count-value {
            font-size: 30px;
            font-weight: bold;
            letter-spacing: 0.03px;
            color: #606672;
        }
    }

    .hme-view-store-settings-task-uploaded-devices-reset-filters {
        margin-top: auto;
        margin-bottom: 8px;
        padding-left: 24px;
        margin-left: 24px;
        border-left: solid 1px #e5e5e5;
    }

    .hme-view-store-settings-task-uploaded-devices-header-search {
        width: 283px;
        flex: none;
        margin-left: auto;
    }
}
