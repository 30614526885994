.hme-grid-sort-component {
    display: inline;
    user-select: none;
    cursor: pointer;
    &>.hme-grid-sort-cell-icon {
        color: #0074ef;
        margin-top: -4px;
        cursor: pointer;
    }
    &>.hme-grid-sort {
        &-hidden {
            display: none;
        }
        &-asc {
            display: inline-block;
            transform: rotate(180deg);
        }
        &-desc {
            display: inline-block;
        }
    }

}