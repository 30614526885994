.hme-badge-pill {
    min-height: 18px;
    padding: 6px 10px 6px 14px;
    margin: 7px 4px;
    font-family: Assistant;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 1.26px;
    text-align: left;
    color: #ffffff;
    display: inline-block;
    border-radius: 15px;
    background-color: #186df4;
}
