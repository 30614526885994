.leaderboard-templates-2-section {
    & > .hme-title {
        margin: 0 0 20px 0;
    }
    & > .hme-label {
        margin: 0;
    }
    & .template-preview-cancel-button {
        float: right;
        margin-top: 8em;
    }
    & .pointer-events-none {
        pointer-events: none!important;
    }
    & .leaderboard-sections-subtitle {
        display: flex;
        justify-content: space-between;
        width:100%;
        &__highlighted-text {
            background-color: #1840ba;
            color: #fff;
            padding: 3px 5px;
            border-radius: 5px;
            text-transform: uppercase;
            letter-spacing: -0.5px;
            font-size: small;
            text-align: center;
        }
        & .leaderboard-sections-labelWithTooltip {
            width: 60%;
            display: flex;
            flex: 1 1 100%;
        }
    }
}

@import './Tablet.scss';
