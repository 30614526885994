@import 'src/assets/styles/Variables';

.hme-checkbox-group {
    .hme-label {
        display: block;
        margin-bottom: 8px;
    }

    &__asterix {
        color: $hme-blue-color;
    }

    .hme-checkbox-component {
        display: block;
        margin-left: 10px;
        margin-bottom: 10px;
    }
}
