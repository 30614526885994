@import 'src/assets/styles/Variables';
@import "src/assets/styles/Responsive";
@import 'src/mixins/breakpoints.scss';

.hme-login-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 505px;

    &__container {
        padding: 35px;
        border-radius: 24px;
        background-color: $backgroung-grey;
    }

    .hme-title {
        line-height: 1.3;
    }

    &__buttons {
        display: flex;
        justify-content: flex-end;
        gap: 20px;
    }

    .hme-login-form__attempts {
        display: flex;
        align-items: center;
        width: 100%;
        margin-left: auto;
        margin: 0 0 20px 0;
        padding: 0 0 0 8px;
    }

    .hme-login-form__attempts__icon {
        width: 20px;
        height: 20px;
        margin: -6px 6px 0 0;
    }

    .hme-login-form__attempts-exceeded {
        width: 100%;
    }

    .hme-input-container {
        margin: 0 0 20px 0;

        .hme-label {
            padding: 0 7px 0 0;
            white-space: nowrap;
            flex: 1;
        }

        .hme-input-wrapper {
            width: 340px;
            justify-self: flex-end;
        }
    }
}

@include smaller($l-desktop) {
    .hme-login-form {
        .hme-login-form__attempts {
            width: 100%;
            align-items: flex-start;
            justify-items: flex-start;
        }
        .hme-login-form__attempts-exceeded {
            width: 100%;
            align-items: flex-start;
            justify-items: flex-start;
        }

        .hme-login-form__attempts__icon {
            margin: 0 6px 0 0;
            flex-shrink: 0;
        }

        .hme-input-container {
            align-items: flex-start;
            flex-direction: column;
            margin: 0 0 15px 0;
            &:last-of-type {
                margin: 0 0 20px 0;
            }

    
            .hme-label {
                white-space: nowrap;
                flex: 1;
                padding: 0 0 8px 0;
            }
    
            .hme-input-wrapper {
                width: 100%;
                justify-self: unset;
            }
        }
        .hme-login-form__login-button {
            width: 100%;
            margin-left: auto;
            display: block;
        }
    }
}

@include mobile-only {
    .hme-login-form {
        max-width: 340px;

        .hme-title {
            font-size: 20px;
        }

        &__container {
            padding: 20px;
            box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0);
        }

        &__buttons {
            flex-direction: column;
            justify-content: unset;
            gap: 15px;
        }

        .hme-form-button[type="submit"] {
            order: -1;
        }
    }
}
