.accounts-page {
  height: 100%;
  overflow-y: auto !important; }
  .accounts-page.hmeBody {
    width: 100% !important; }
  .accounts-page .hme-admin-system-status {
    width: 85%;
    min-height: 40px;
    margin: 0 auto; }
  .accounts-page .hme-accounts-wrapper {
    height: 100%;
    min-width: 1380px; }
  .accounts-page .hme-accounts {
    height: 100%;
    display: flex;
    flex-direction: column; }

.accounts-page.hme-page-component {
  padding: 27px 0 0 0; }

body.modal-open div.hme-tooltip.device-upgrade-tooltip {
  z-index: 100 !important; }
