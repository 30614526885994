.admin-create-apply-device-settings-task-filters {
    &.hme-section {
        overflow-y: visible;
        overflow-x: visible;
    }

    .hme-toggle {
        margin-bottom: 8px;
    }

    .hme-single-select__box {
        margin-top: 15px;
    }
}

.admin-create-apply-device-settings-task-filters-tip {
    width: 410px;
}

.admin-create-apply-device-settings-task-filters-confirm-line {
    margin-bottom: 18px;
}