.users-list-footer {
    z-index: 1;
    position: relative;
    text-align: right;
    padding: 35px 25px 35px 50px;
    background-color: #ffffff;
    margin-left: -50px;
    margin-right: -25px;
    border-top: 1px solid var(--gray-37);

    @media screen and (max-width: 1173px) {
        padding-left: 65px;
        padding-right: 40px;
        margin-left: -65px;
        margin-right: -40px;
    }

    .hme-users-list__paginate {
        margin: 0;
        min-width: auto;
        padding: 0 50px;

        &.hme-paginate--hidden {
            display: none;
        }

        .hme-label {
            color: #606672;
            text-transform: none;
            font-weight: normal;
        }

        @media screen and (max-width: 1604px) {
            padding: 0;
        }
    }
}
