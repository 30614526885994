@import 'src/mixins/breakpoints.scss';

@include before-desktop-only {
  .hme-system-status {
    padding: 30px 20px;
    padding-left: 15px;

    &__device-grid {
      gap: 15px;
    }
  }
}
