@import "../../../../assets/styles/Variables";
@import "../../../../assets/styles/Responsive";

.custom-smacktalk-page-messages-landing {
    &.progress-cursor {
        cursor: progress;
    }
    & > .hme-messages-landing-container {
        height: calc(100vh - 350px);
        overflow: unset;
        & > .hme-messages-landing-header {
            margin: 0 0 30px 0;
            & > .hme-button-component {
                margin: 0 0 0 auto;
            }
        }
        & > .hme-messages-landing-grid-wrapper {
            overflow: scroll;
            height: calc(100% - 80px);
            & > .hme-grid {
                & > .hme-grid-header > .hme-grid-row {
                    & > .hme-grid-cell {
                        &:last-child {
                            // text-align: center;
                        }
                        &:first-child, &:nth-child(2) {
                            text-align: left;
                            & > .hme-grid-cell-content > span {
                                display: flex;
                                justify-content: flex-start;
                            }
                        }
                    }
                }
                & > .hme-grid-row {
                    & > .hme-grid-cell {
                        text-align: left;
                        &:last-child {
                            display: flex;
                            justify-content: center;
                            & > .hme-grid-cell-content {
                                display: flex;
                                justify-content: start;
                                width: 100%;
                                & button.hme-button-component.hme-button-disabled-component.hme-transparent-button-component {
                                    background-image: none;
                                }
                                & > button.hme-button-component {
                                    height: 24px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@include smaller($l-desktop) {
    .custom-smacktalk-page-messages-landing-popup {
        align-items: flex-start;
        padding: 40px 0 0 0;
        & > .modal-content  {
            & >.modal-header {
                & > .hme-title {
                    padding: 0 10px;
                }
            }
        }
    }
}
