.hme-partner-form {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    &__header {
        margin-bottom: 30px;
        flex: 0;
        min-width: 100%;
    }

    &__sections {
        display: flex;
        flex-wrap: wrap;
        height: auto;
        flex: 1;
        overflow: auto;
        padding: 32px 25px 15px 50px;
        height: max-content;
        align-items: stretch;
    }

    .hme-label-error {
        margin-top: -15px;
    }

    .hme-section {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 20px;
        max-width: 375px;
        padding-left: 40px;
        padding-right: 40px;
        height: auto;

        &.hme-partner-form__section--info {
            padding-left: 0;
            border-left: none;
        }

        &.hme-partner-form__section--owner {
            padding-right: 0;
        }

        .hme-title {
            margin-bottom: 0;
        }
    }

    .hme-single-select {
        .hme-single-select__box__text-desktop {
            margin-top: 0;
        }
    }
}