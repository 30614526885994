.hme-help-page {
  height: 100%;
  overflow: auto;

  ul {
    margin-top: 8px;
    margin-left: -15px;
    list-style-type: none
  }

  .hme-contact__labels {
    font-family: Assistant;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 1.44px;
    text-align: left;
  }

  .hme-contact__value {
    mix-blend-mode: darken;
    font-family: Assistant;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
  }

  li {
    margin-bottom: 5px;
  }

  .hme-help__container{
    padding: 51px;

    .hme-help__header-text {
      width:7%;
      height:30px;
      font-size: 31px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.77;
      font-family: Assistant;
      letter-spacing: -0.43px;
      color: #000819;
      border-right: solid 2px #e8e9ed;
    }

    .hme-help__column-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 29px;

      .hme-help__faq-column {
        width: 45%;
        height:100%;

        padding-right: 32px;
        border-right: solid 1px #e8e9ed;

        .hme-help__faq-header-text {
          font-family: Assistant;
          font-size: 26px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 0.92;
          letter-spacing: -0.36px;
          color: #000819;
        }

        .hme-help__faq-category-text {
          margin-top: 29px;
          margin-bottom: 19px;
          font-family: Assistant;
          font-size: 24px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.24px;
          text-align: left;
          color: #000819;
        }

        .hme-accordion-wide {
          width: 100%;
          padding-bottom: 30px;
          border-bottom: 1px solid #d1cdcd;

          &.hme-accordion-wide-top {
            border-top: 1px solid #d1cdcd;
          }

          .hme-accordion__title {
            padding-top: 29px;
          }

          .hme-accordion__title__text {
            mix-blend-mode: darken;
            font-family: Assistant;
            font-size: 18px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            text-align: left;
            color: #0074ef;
            text-transform: none;
          }

          .hme-accordion__content {
            width: 99%;
            padding: 13px 15px 15px;
            margin: 2px;
            border-radius: 8px;
            box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
            background-image: linear-gradient(to bottom, #fff, #fff 52%, #f2f2f2);

            .hme-help-faq-answer-text {
              font-family: Assistant;
              font-size: 16px !important;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.33;
              letter-spacing: 0.34px;
              text-align: left;
              color: #000819;
            }
          }
        }
      }
      .hme-help__contact-column {
        width: 51%;

        .hme-help__contact-support-text {
          font-size: 26px;
          font-weight: bold;
          font-family: Assistant;
          font-stretch: normal;
          font-style: normal;
          line-height: 0.92;
          letter-spacing: -0.36px;
          color: #000819;
          margin-bottom: 30px;
        }

        .hme-help__documentation-link {
          mix-blend-mode: darken;
          font-family: Assistant;
          font-size: 18px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: normal;
          color: #0074ef;
        }

        .hme-help__training-portal-container {
          margin-top: 40px;
          width: 40%;
          display: flex;
          flex-direction: column;

          .training-portal-header {
            mix-blend-mode: darken;
            font-family: Assistant;
            font-size: 26px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.92;
            letter-spacing: -0.36px;
            text-align: left;
            color: #000819;
          }
          .training-portal-text {
            margin: 30px 0 20px;
            font-family: Assistant;
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: 0.34px;
            text-align: justify;
            color: #000819;
          }
          .training-portal-link {
            mix-blend-mode: darken;
            font-family: Assistant;
            font-size: 18px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            text-align: right;
            color: #0074ef;
          }


        }
      }
    }
  }
}
@import 'Tablet.scss';
@import 'Mobile.scss';
