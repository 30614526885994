.view-device-upgrade__confirm-popup {
    &__message {
        margin-bottom: 20px;
        line-height: normal;
        letter-spacing: 0.18px;
    }

    &__textarea {
        margin-bottom: 16px;

        textarea::placeholder {
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 1.08px;
            color: #606672;
        }
    }

    &__warning {
        line-height: normal;
        letter-spacing: 0.18px;
    }
}
