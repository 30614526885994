.hme-menu-item {
    display: flex;
    font-family: Assistant;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    justify-content: center;
    color: #606672;

    &.hme-menu-item--active {
        color: #186df4
    }

    .hme-menu-item__icon {
        flex: 0;
        margin-right: 25px;
        margin-left: 15px;
        font-size: 20px;
        height: 20px;
    }

    .hme-menu-item__text {
        flex: 1;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.31;
        letter-spacing: 0.53px;
        text-align: left;
        text-transform: uppercase;
    }
}
