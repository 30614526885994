.hme-section.hme-section-sm.hme-section-without-title{
    padding-left:0;
    .hme-textarea .hme-textarea__input {
        padding: 22px 0 5px 25px;
        min-width: 350px;
    }
    .hme-title{
        display:block;
        margin-bottom: 20px;
    }
    .copy-btn{
        display:inline;
        margin: 0 0 5px 5px;
        cursor: pointer;
    }
    .hme-settings{
        textarea[disabled]{
            background-image: none;
            background-color: #00b5ff47;
            border-radius: 0;
            border: none;
            font-size: 14px;
        }
    }
}
