.hme-remaining-count {
    font-family: Assistant;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: 1.44px;
    color: #0074ef;
    padding-left: 1rem;
}