@import 'src/mixins/breakpoints';

@include before-desktop-only {
    .hme-view-edit-contest--results__header {
        --gap: 20px;

        padding-left: 15px;
        padding-right: 15px;
    }
}
