
.pb-2, .py-2 {
    padding-bottom: .5rem!important;
}
.mb-0, .my-0 {
    margin-bottom: 0!important;
}
.border-bottom {
    border-bottom: 1px solid #dee2e6!important;
    width: 8rem;
    word-break: keep-all;
}



.avatar-box {
    min-height: 500px;
    max-width: 520px;
    height: 400px;
    overflow: auto;
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 1px 1px 1px rgba(0,0,0,.05);
}

.avatar-box .panel-body {
    overflow-x: hidden;
    padding: 15px;
}

.avatar-box h3.no-records-message {
    margin: auto;
    width: 100%;
    line-height: 15;
    text-align: center;
}

.show-line {
    width: 5rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
    border-bottom: 1px solid #004de0;
  }

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }

    .avatar-box {
        min-height: 400px;
    }
}

@media (min-width: 768px) {

    .store-group  {
        margin-bottom: -20px;
        margin-left: 10px;
    }

    .container {
        max-width: 720px;
    }

    .avatar-box {
        min-height: 450px;
        max-width: 520px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 960px;
    }
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.store-name {
    display: flex;
    align-items: center;
    width: 40%;
}

.avatar-img {
    width: 35%;
}

.center-dots {
    display: flex;
    justify-content: center;
    width: 25%;
}

@media (max-width: 768px) {
    .hidden-xs {
        display: none;
    }
    .store-name {
        text-align: center;
        line-height: initial;
    }

    .avatar-image {
       margin-top: -15px;
    }

    .show-line {
        width: 8rem;
      }
}

@media (max-width: 576px) {
    .avatar-box {
        max-height: 50px;
        overflow: auto;
    }
}

.edit-avatar-page {
    font-family: Assistant;
    font-size: 1.7rem;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    color: var(--black);
    letter-spacing: normal;
    padding-left: 7.5px;
}

.edit-avatar-page .store-group {
    margin-top: 0;
    margin-bottom: -20px;
}

.edit-avatar-page h3 {
    font-size: 25px;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 10px
}

.edit-avatar-page h5 {
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.list-images {
    width: 90%;
    height: auto;
}

/* image loader animation */
.swing {
    animation: swing ease-in-out 1s infinite alternate;
    transform-origin: center -20px;
    float:left;
    /* box-shadow: 5px 5px 10px rgba(0,0,0,0.5); */
}

.swing:after{
    content: '';
    position: absolute;
    top: -10px; left: 50%;
    z-index: 0;
    border-bottom: none;
    border-right: none;
    transform: rotate(45deg);
}
/* nail */
.swing:before{
    content: '';
    position: absolute;
    width: 5px; height: 5px;
    top: -14px;left: 54%;
    z-index: 5;
    border-radius: 50% 50%;
    background: #000;
}

@keyframes swing {
    0% { transform: rotate(3deg); }
    100% { transform: rotate(-3deg); }
}

.ellipsis {
    text-overflow: ellipsis;
}

.item::after {
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
}

.item.green.blue.purple::after {
    content: "●●●●●●";
    background-image: linear-gradient(
        to right, #2ECC40 33%, rgb(89, 164, 230) 33%, rgb(238, 28, 0) 67%, rgb(226, 138, 37) 67%
    );
}

.avatar-alert {
    position: absolute;
    z-index: 1000;
    opacity: 0.95;
}

.avatar-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0.2rem 0 0 4.25rem;
}

.hme-avatarbox__content {
    width: 100%;
    display: flex;
    height: 100%;
    overflow: auto;
    flex-wrap: wrap;
}

.hme-avatarbox__item {
    flex: 1 1 25%;
    max-width: 25%;
}
