// theme
// font
$font-family: Assistant, Arial, Helvetica, sans-serif;
$font-size: 14px;
// text colors
$primary-text: #000000;
$secondary-text: #F5F5F5;
$disabled-text: #F5F5F5;
$secondary-2-text: #757575;
$accent-text: rgb(212, 27, 62);
// theme color
$primary-color: #ffffff;
$secondary-color: #06B1CB;
$accent-color: #104C6B;
$accent-2-color: #0000ee;
$disabled-color: #bdbdbd;
$secondary-disabled-color: #e0e0e0;
// service
$error: #96281B;
$error-field: #d41b3e;
// decorations
$decoration: #bdbdbd;
$gradient: linear-gradient(#000, #262A38);
$border-radius: 2px;
$border: 1px solid $decoration;
$border-thick: 2px solid $decoration;
$box-shadow: 0 0 10px 0 rgba(0, 8, 25, 0.3);
// breakpoint media queries
$mobile: 420px;
$tablet: 768px;
$desktop: 1224px;
$l-desktop: 1174px; // by CE-1956
// spacing
$padding: 0.5em;
$margin: 1em;
$gap: 2em;
$inline-spacing: 0.5em;

//new-design
$main-color: #0074ef;
$main-black: #000819; // .black
$main-gray: #606672; // .grey62
$gray-border: #a7adba;
$light-grey: #5c6670;
$main-white: #ffffff; // .white
$backgroung-grey: #f5f5f5;
$hme-blue-color: #186df4; //.blue-100
$hme-dark-blue-color: #0040ba; //.blue-100
$gradient-gray: #ccd3e2;
$gray-25: #c7ccd6; //.grey-25
$warning-yellow: #ffce28;

$input-disabled-bg: linear-gradient(to bottom, #dde1e9, #c7ccd6);
$separator-color: #d1cdcd;
$slider-off-color: #a9aebb;
$input-underline: #e8e9ed;

$notification-gradient: linear-gradient(to bottom, $main-white, #f2f2f2);
$notification-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
$notification-read-icon-color: #9b9b9b;
$notiication-gray-bell: #707070;

// grid-row-variation
$row-red: #ffe3e3;
$row-orange: #fff9e6;

$tooltip-info-bg: #e8e9ed;
$header-separator-grey: #e5e5e5;

// sidebar overlay styles
$sidebar-overlay-width: 385px;

$gradient-green: linear-gradient(to top, #267201, #5db431);
$gradient-red: linear-gradient(to bottom, #ff8399, #cc1838);

$letter-spacing-144: 1.44px;
$letter-spacing-032: 0.32px;
