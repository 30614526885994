.hme-enhanced-filter-info {
    padding: 25px 0;
    font-family: Assistant;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: 0.53px;
    text-align: left;
    color: #606672;
    user-select: none;
    & > span {
        color: #0074ef;
        cursor: pointer;
        padding: 0 5px;
    }
}