@import 'src/mixins/breakpoints.scss';

.modal-dialog.hme-modal-component.hme-change-language-modal {
    .modal-content {
        min-width: 506px;
        padding: 55px 102px 39px;

        .modal-body {
            overflow: visible;
            padding-bottom: 50px;
        }
    }

    .modal-header {
        display: none;
    }

    .btn-close {
        display: none;
    }

    .hme-language-select {
        display: flex;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;

        .hme-label {
            margin-right: 0;
            margin-top: 0;
            text-align: center;
            font-size: 24px;
            line-height: 1.33;
            letter-spacing: 0.24px;
            margin-bottom: 30px;
            text-transform: none;
        }

        .hme-language-select__label__icon {
            width: 66px;
            height: 66px;
            color: #0074ef;
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 10px;
        }

        .hme-select {
            max-width: unset;
        }
    }

    .modal-footer {
        padding-top: 0;
        padding-right: 0;

        .hme-button-component:first-child {
            margin-left: auto;
        }

        .hme-button-component:last-child {
            margin-left: 20px;
            margin-right: auto;
        }
    }

    @include before-desktop-only {
        & {
            .modal-content {
                min-width: 345px;
                padding: 55px 22px 39px;
            }

            .hme-language-select__label__text {
                display: block;
            }

            .hme-language-select__label__icon {
                width: 66px;
                height: 66px;
                color: #0074ef;
                display: block;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 10px;
            }

            .modal-footer {
                .hme-button-component {
                    max-width: 175px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    &:last-child {
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}
