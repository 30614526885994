.hme-dropdown {
    width: 100%;
    height: 48px;
    position: relative;
    padding-right: 28px;

    .hme-dropdown-text {
        font-family: Assistant;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.31;
        letter-spacing: 1.44px;
        text-align: left;
        color: #2c6df4;
        margin-top: 2px;
        cursor: pointer;

        &.disabled {
            color: #b9b9b9;
        }
    }

    .hme-dropdown-icon {
        position: absolute;
        right: 0;
        top: 6px;
        width: 24px;
        height: 24px;
        filter: invert(27%) sepia(69%) saturate(2103%) hue-rotate(195deg) brightness(108%) contrast(108%);
        cursor: pointer;

        &.disabled {
            filter: invert(91%) sepia(0%) saturate(0%) hue-rotate(207deg) brightness(82%) contrast(91%);
        }
    }

    .hme-dropdown-list {
        list-style-type: none;
        position: absolute;
        top: 100%;
        right: 10px;
        margin-top: 8px;
        border-radius: 8px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
        background-color: #ffffff;
        padding: 8px 0;
        display: none;
        overflow: auto;
        max-height: 200px;
        z-index: 100;

        .hme-dropdown-list-item {
            width: 100%;
            height: 40px;
            font-family: Assistant;
            padding: 10px 15px;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.31;
            letter-spacing: 1.28px;
            text-align: left;
            color: #000819;
            white-space: nowrap;
            cursor: pointer;

            &:hover {
                background-color: #2c6df4;
                color: #ffffff;
            }
        }
    }

    &.hme-dropdown-opened {
        div.hme-label {
            margin-left: 24px;
            margin-top: 2px;
        }

        .hme-dropdown-list {
            display: block;
        }
    }
}
