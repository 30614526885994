@import 'src/assets/styles/Variables';

.hme-user-access {
    &__role {
        position: relative;

        &.hme-input-container .hme-input-wrapper {
            position: static;
        }
    }

    &__role-name {
        font-size: 16px;
        color: $main-black;
    }

    &__role-link {
        position: absolute;
        top: 4px;
        right: 0;
    }
}

@import './Tablet.scss';
