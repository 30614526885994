
tr.tdata.even-row:hover{
    background-color: #c4e9f1;
    border-right:1px solid black;
    border-left:1px solid black;
    border-bottom: 1px solid #709da2;
}
tr.tdata.even-row{
    border-right:1px solid #d3d3d3;
    border-left:1px solid #d3d3d3;
    border-bottom: 1px solid #d3d3d3;


}
tr.tdata.odd-row:hover{
    background-color: #c4e9f1;
    border-right:1px solid black;
    border-left:1px solid black;
}
tr.tdata.odd-row{
    background-color:#ededed;
    border-right:1px solid #d3d3d3;
    border-left:1px solid #d3d3d3;
}
tr.tdata.selectedGroup{
    background-color: #d4eff5;
    border-right:1px solid black;
    border-left:1px solid black;
    border-bottom: 1px solid #709da2;
}
span.no-groups{
    margin-top:4em;
    font-size:200% !important;
}
.cursor-pointer{
    cursor:pointer;
}
ul.dropdown-menu-customization {
    min-width: 80px;
    left: .3em;
    top: .3em;
    right: 10em;
}
img.cogWheel {
    height: 1.3em;
    width: 1.3em;
}
.systems .forms h3 {
    font-size: 30px;
    margin-bottom: 17px;
}
.systems span.additem {
    background-color: #D41B3E;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 13px;
    padding: 10px 20px;
}
.systems  .forms * {
    font-size: 12px;
}

.systems .ctable .theader, .systems .ctable .cheader {
    border: 1px solid #b9b9b9;
    color: #757575;
}

.systems .forms {
    margin-bottom: 25px;
    margin-top: 47px;
}

.systems .ctable {
    margin: 0 auto;
    padding-right:10em;
}

.systems .settings_search {
    height: 90px;
    padding: 0 0 10px;
}

.systems tr.theader th {
    font-size: 16px;
}

.systems .ctable th {
    padding: 10px;
}

.systems tr.theader th a{
    font-size: 16px !important;
}

.systems td.cdet {
    /* float: left; */
    margin-left: 4px;
    text-align: center;
}

.systems .ctable td {
    padding: 10px;
    vertical-align: top;
}

.systems .ctable tr.tdata td {
    font-size: 14px;
}
.systems .clear:after {
    clear: both;
    content: ".";
    display: block;
    font-size: 0;
    height: 0;
    visibility: hidden;
}
.systems .tdata, .systems .cdata {
    background-color: #ffffff;
    border-bottom: 1px solid #b9b9b9;
    color: #000000;
}
.systems .tdata:hover {
    background-color: #c4e9f1;
    border-bottom: 1px solid #709da2;
    border-right:1px solid black;
    border-left:1px solid black;
}

.systems .theader.clear span {
    font-size: 16px;
}

.blue-button {
    background-color:#06b1cb;
    color: #FFFFFF;
}

.red-button {
    background-color: #D41B3E;
    color: #FFFFFF;
}

.green-button {
    background-color: #06cb1d;
    color: #FFFFFF;
}

.button{
    font-weight: bold;
    font-size: 13px;
    padding: 10px 20px;
    margin: 5px;
    cursor: pointer;
}

.blue-link{
    color:blue;
    text-decoration:underline;
    cursor:pointer;
}

.membersTablePositioning{
    padding-top: 4em;
    padding-bottom: 8em;
}

.membersofgroup{
    position:absolute;
}

.paddingOnRight{
    margin-right:.5em;
}

.paddingOnLeft{
    margin-left:12em;
}

.paddingOnTop{
    margin-top:2em;
}

.formattingConfirmCancelButtons{
    margin-left:28em;
    margin-top:6em;
}

.fitTableToPage{
    min-width:100em;
}

.moveButtonHigher{
    margin-top:2em;
}

.forceIconLeft{
    margin-left:-2em;
}
.upgrade-to-nitro-message{
    margin-top: 5em;
    padding: 10px 25px;
    border-radius: 6px;
    border: solid 1px #c7ccd6;
    background-image: linear-gradient(to bottom, #186df4, #0040ba);
    font-family: Assistant;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: 0.32px;
    text-align: left;
    color: white;
    max-width: 20em;
}
.icon-column{
    height: 15em;
    margin-left:-1em;
}
.membersUpdating{
    opacity: 0.5;
}
.storesModalHeight{
    height:9em;
}
#invteResponse{
    color: #D41B3E;
}
#errMsgDuplicateUser{
    color: #D41B3E;
}

/* Popover styling */
.member-stores-popover {
    cursor:default;
}

.member-stores-popover .panel-heading {
    display: flex;
    padding-bottom: 0;
    justify-content: space-between;
    background-color: #FFFFFF;
    border-color: #ffffff;
}
.member-stores-popover .panel-heading .participants-popover-close {
    font-size: 3rem;
}

.member-stores-popover .panel-body .store-name {
    padding-top: 0;
}

.member-stores-popover .panel-body .store-list {
    padding-left: 15px;
}


.disabled {
    pointer-events: none;
    text-decoration: none;
}

/***************************************************** lbGroupTable styles */
.lbGroup--new-groups {
    padding-top: 1em;
}
.lbGroup--new-groups--controllers {
    display: flex;
    border-bottom: 1px solid #bdbdbd;
    padding-bottom: .5em;
}
.lbGroupTable > table td {
    border: hidden;
}

.SelectStores-BoldGroupName {
    font-weight: bold;
}
/* rc-tree-checkbox rc-tree-checkbox-checked */
.myCls {
    font-weight: 100;
}

.lbGroupTable .store_checkbox {
    padding-right: 3px;
}

.lbGroupTable td.cdet {
    text-align: right;
    padding-left: 28px;
}

.lbGroupTable .list-body tr {
    padding-top: 15px;
}

.lbGroupTable td {
    padding-left: 5px ;
}

.participant-stores-checkbox input {
  display: none;
}

.participant-stores-checkbox span {
  border: 1px solid  rgba(2, 124, 151, 0.4);
  outline: 1px solid rgb(0, 0, 0, 0.8);
  float: right;
  height: 11px;
  width: 11px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.participant-stores-checkbox input:checked + span:before {
  content: '\2713';
  color: green;
}

label.participant-stores-checkbox {
    margin-bottom: 0px !important;
}

.lbGroupTable td{
    padding-top:7px;
}

.lbGroupTable table tbody tr:hover td {
    background-color: #ffffff;
}
.ctable table .tbl-header{
    width: 25%;
}
.lbactions-textAlign{
    text-align: left;
    padding-left: 4em
}
.theader .lbactions-textAlign{
    text-align: left;
}
.paddingOnLeftInherit{
    padding-left: inherit;
}
.redCancel {
    font-size: 16px;
}

.leaderboards .settings .leaderboard-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 40px;
}

.leaderboards .settings .leaderboard-controls a {
    font-size: 14px;
}

.leaderboards .leaderboard-name .hme-link {
    color: #337ab7;
    background-color: transparent;
}

.leaderboards .settings_list .hme-button {
    background-color: transparent;
}

.leaderboards .settings_list .remove-leaderboard .hme-button {
    font-weight: bold;
    text-decoration: none;
}

.leaderboards .settings_list .hme-button--disabled {
    color: #afafaf;
}

.bg-light-gray{
    background-color: lightgray !important;
  }

.hme-create-edit-leaderboard-template-selection > .hme-select > .hme-select-text,
.hme-create-edit-leaderboard-template-selection > .hme-select > .hme-select-without-label,
.hme-create-edit-leaderboard-template-selection > .hme-select > .hme-select-dropdown > .hme-select-dropdown-item
{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.lbGroupTable table {
    width: 100%;
}
