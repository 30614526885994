.hme-input-container.hme-input-snapshot-variant {
    cursor: pointer;

    .hme-input-component-before {
        display: none;
    }

    .hme-input-component {
        cursor: pointer;
    }

    .hme-select-snapshot {
        display: inline-block;
        width: 100%;
        margin-top: 8px;
        font-family: Assistant;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.31;
        letter-spacing: 0.48px;
        text-align: right;
        color: #186df4;
    }
}
