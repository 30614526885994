.hme-customer-notification-status {
    padding: 59px 48px 40px 48px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;

    .Toastify {
        .Toastify__toast-container--top-center {
            // use important because prev styles use it
            top: 59px !important;
        }
    }

    &__header {
        margin-bottom: 10px;
    }

    .hme-grid {
        flex: 1;
        overflow: auto;
    }

    .hme-grid-cell {
        overflow: hidden;
    }

    .hme-grid-cell-content {
        max-height: 50px;
    }

    &__type {
        text-transform: capitalize;
    }

    .hme-customer-notification-status__actions {
        display: flex;
        align-items: center;
    }

    .hme-checkbox-slider-label-text {
        margin-left: 10px;
        text-transform: uppercase;
    }

    .hme-onoff-slider-component {
        padding-right: 25px;
    }

    .hme-customer-notification-paginate {
        margin: 20px 0;
    }
}