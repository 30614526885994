.hme-view-edit-store {
  width: 100%;
  height: calc(100% - 80px);

  &__wrapper {
    width: 100%;
    height: 100%;
    padding: 50px;
    padding-bottom: 0;

    overflow: auto;
  }

  &__content,
  &__info {
    display: flex;
  }

  &__info-item {
    flex: 1;
    padding-right: 32px;
    min-width: 240px;
    
    font-family: Assistant, sans-serif;
    border-right: 1px solid #e8e9ed;

    & ~ & {
      padding-left: 32px;
    }
  }

  .hme-address {
    margin: 5px 0;
    min-height: 60px;
  }

  &__devices-info {
    flex: 2;
    padding-left: 32px;
  }

  &__devices-grid {
    margin-top: -6px;
    min-width: 760px;

    .hme-grid-header {
      position: static;
    }

    .hme-grid-centered-cell {
      .hme-grid-cell-content {
        padding-left: 0;
      }
    }

    .hme-grid-right-aligned-cell{
      .hme-grid-cell-content {
        padding-right: 25px;
      }
    }

    .hme-device-status {
      &.hme-device-status--preconfigured,
      &.hme-device-status--unavailable {
        padding-right: 0;
      }
    }
  }

  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

@import './Tablet.scss';
@import './Mobile.scss';
