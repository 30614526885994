.hme-brand-sharing {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;

    &__content {
        padding: 27px 0 27px 50px;
    }

    &__header {
        margin-bottom: 30px;
    }

    &__brand {
        margin-bottom: 20px;

        &:last-child {
            border-bottom: none;
        }
    }

    .hme-save-footer {
        position: sticky;
        bottom: 0;
    }
}

@import './Mobile.scss';
