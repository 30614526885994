.hme-layout-device-upgrade-list-page .hme-grid-cell.hme-grid-cell-actions .hme-grid-cell-content {
  padding-left: 0; }

.hme-layout-device-upgrade-list-page .hme-grid-actions {
  display: flex;
  gap: 0; }
  .hme-layout-device-upgrade-list-page .hme-grid-actions .hme-grid-action {
    position: relative;
    padding: 0 8px 0 13px; }
    .hme-layout-device-upgrade-list-page .hme-grid-actions .hme-grid-action::before {
      content: '|';
      position: absolute;
      top: calc(50% - 9px);
      left: 0;
      line-height: 16px;
      color: #c7ccd6; }
    .hme-layout-device-upgrade-list-page .hme-grid-actions .hme-grid-action:first-child {
      padding-left: 0; }
      .hme-layout-device-upgrade-list-page .hme-grid-actions .hme-grid-action:first-child::before {
        display: none; }
