/**
    CSS Style for the Welcome Tile Component
*/

.fleft {
    float: left;
}


.welcome {
    padding-bottom: 60px;
}

.welcome--hidden {
    display: none;
}

.trainings h3{
    color: white;
    font-weight: bold;
    padding: 0px !important;
    margin-bottom: 5px;
    margin-top: 0px;
}

.trainings a{
    color: white;
    font-size: 16px;
    font-weight: bold;
    padding: 0px !important;
    margin-bottom: 5px;
}


.trainings a.training{
    font-weight: lighter !important;   
    font-size: 15px;
}

.trainings{
    color: white;
    display: -ms-flexbox;
    display: flex;
    margin: 0 auto;
}

.training-tile-content {
 padding-top:20px ;
}

/*Undergo Maintainance*/
.notice {
    background-color: white;
}
.notice .newFeat_banner {
    padding-top: 10px;
    padding-bottom: 15px;
    width: 80%;
    margin: 0px auto;
}
.notice .newFeature {
    font-size: 16px;
    font-weight: bold;
    color: #969696;
    padding-left: 8px;
    width: 100%;
}
.notice .list-notice{
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    font-family: Arial;
    vertical-align: baseline;
    padding-left: 30px;
}
.notice .newClose {
    cursor: pointer;
}
.notice .fright {
    float: right;
}
.notice .fleft {
    float: left;
}
