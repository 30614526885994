.hme-contest-actions {
    display: flex;
    gap: 20px;

    &__view-link,
    &__view-certificate,
    &__cancel,
    &__download-results {
        flex: 1;
    }

    & > *:first-child {
        text-align: right;
    }

    & > *:last-child {
        text-align: left;
    }

    & > *:first-child:last-child {
        text-align: center;
    }
}

@import 'Tablet.scss';
