.hme-input {
  padding: 0.5em;
  position: relative;
}
.hme-input input[type=text],
.hme-input input[type=number],
.hme-input input[type=search],
.hme-input select,
.hme-input textarea {
  width: 100%;
  padding: 3px 6px;
}
.hme-input input[type=search] {
  margin: 0;
  border: 1px solid #bdbdbd;
}
.hme-input input[type=search]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.hme-input input[type=search]::-ms-clear {
  display: none;
}
.hme-input input[type=search] + .hme-input__search-clear {
  position: absolute;
  bottom: 0;
  right: 16px;
  height: 16px;
  width: 16px;
  margin: auto;
  font-size: 16px;
  cursor: pointer;
  color: #bdbdbd;
}
.hme-input input[type=search] + .hme-input__search-clear:hover {
  color: rgb(212, 27, 62);
}
