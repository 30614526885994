@import 'src/assets/styles/Variables';

$gap: 30px;

.hme-customer-notification-form {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: auto;

    &__content {
        flex: 1;
        max-width: 735px;
        display: flex;
        flex-direction: column;
        gap: $gap;
        padding: 60px;
        padding-right: 0;
    }

    &__content-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: $gap;

        & > *:not(:last-child) {
            padding-bottom: $gap;
            border-bottom: 1px solid $separator-color;
        }
    }

    &__header {
        display: flex;
        gap: 45px;
    }

    &__header-item {
        position: relative;

        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    &__type-switch {
        width: 100%;

        .hme-button-component {
            text-transform: capitalize;
        }
    }

    &__activation-switcher {
        margin: auto 0;
    }

    // editor
    &__editor-wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;
    }

    &__editor-language-selector {
        margin-bottom: 20px;
    }

    &__language-title {
        margin-top: 12px;
    }

    &__editor {}

    .hme-save-footer {
        position: sticky;
        bottom: 0;

        margin-top: 40px;
        width: auto;
        padding-right: 50px;
    }

    .hme-onoff-slider-component {
        .hme-checkbox-slider-label-text {
            text-transform: uppercase;
            margin-left: 10px;
        }
    }

    &__info-box {
        position: absolute;
        top: 0;
        left: calc(100% + 50px);

        display: flex;
        width: 525px;

        &--hidden {
            visibility: hidden;
        }
    }

    .Toastify {
        .hme-notifications-list {
            // TODO: Remove this style after we added sub-headers
            top: 60px !important;
        }
    }
}
