.hme-layout-device-upgrade-list-page .hme-page-content {
  height: 100%;
  overflow-y: auto !important; }
  .hme-layout-device-upgrade-list-page .hme-page-content .device-upgrade-list-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-width: 1380px; }
  .hme-layout-device-upgrade-list-page .hme-page-content .hme-accounts {
    height: 100%;
    display: flex;
    flex-direction: column; }
