.hme-manage-hierarchy__move-popup {

    &.modal-dialog.hme-modal-component {
        .modal-content {
            max-width: 810px;
            min-height: 680px;
        }

        .modal-header {
            text-align: left;
            padding-left: 40px;
        }

        .modal-body {
            width: 100%;
            padding-left: 40px;
            padding-right: 40px;
            display: flex;
            gap: 40px;
        }
    }

    &__label {
        text-transform: none;
        flex: 0;
        margin-bottom: 20px;
        font-size: 18px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.18px;
        color: #000819;
    }

    &__to {
        flex: 1;

        .hme-input-auto-complete-variant {
            margin-bottom: 5px;
        }

        .hme-checkbox-component {
            margin-top: 10px;
        }

        .hme-manage-hierarchy__hierarchy__item {
            &__folder--opened {
                display: none;
            }
        }

        .hme-manage-hierarchy__move-popup__to__selected {
            margin-bottom: 20px;
            margin-left: -5px;
        }

        .hme-grid-row-hovered .hme-manage-hierarchy__hierarchy__item {
            &__folder--closed {
                color: #0074ef
            }

            .hme-label {
                color: #FFFFFF;
            }
        }
    }

    &__selected {
        width: 345px;
        flex: 1;
        display: flex;
        flex-direction: column;

        .hme-manage-hierarchy__hierarchy__item {
            margin-bottom: 20px;
            cursor: default;
        }

        &__items {
            flex: 1;
            border: solid 1px #a7adba;
            border-radius: 24px;
            overflow: hidden;
            padding: 30px 10px;

            &__wrapper {
                padding: 0 20px;
                height: 100%;
                overflow: auto;
            }

            .hme-manage-hierarchy__hierarchy__item__folder--opened {
                display: none;
            }
        }
    }
}

@import './Tablet.scss';
@import './Mobile.scss';
