@import 'src/assets/styles/Variables';

.hme-partner-providers {
    &__page {
        display: flex;
        flex-direction: column;
    }

    &__content {
        flex-direction: column;
        flex-wrap: nowrap;
    }

    &__header {
        flex-basis: unset;
        align-items: center;
        gap: 40px;
    }

    &__header-separator {
        align-self: stretch;
    }
    
    &__header-create-provider {
        margin-left: auto;
    }

    &__main-content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: var(--gap);
        margin-bottom: var(--gap);
    }

    &__main-content,
    &__list {
        width: 100%;
        height: 100%;
    }

    &__list {
        flex-grow: 1;
        overflow-y: auto;
    }

    &__paginate {
        position: sticky;
        bottom: 0;
        padding: 20px 0;
        background-color: $main-white;
    }
}
