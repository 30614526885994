.hme-roles {
    padding: 32px 48px 62px 50px;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-width: 1308px;

    .hme-roles__header {
        flex: 0;
    }

    .hme-grid {
        flex: 1;
        overflow: auto;
        padding-bottom: 15px;
        min-width: 1210px;
        margin-top: 19px;
    }

    .hme-paginate {
        margin-top: 20px;
    }
}

@import './Mobile.scss';
