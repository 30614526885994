.hme-grid-cell-checkbox {
    min-width: 16px;
    min-height: 16px;
    flex: 0;

    .hme-grid-cell-content {
        padding-left: 30px;
        padding-right: 0;
        overflow: visible;
    }
}