.merge-devices__search-store-form {
  position: relative;
  max-width: 530px;
  margin-bottom: 20px; }
  .merge-devices__search-store-form .hme-auto-complete-suggestions {
    position: absolute;
    top: 58px;
    left: 0;
    z-index: 10;
    border-radius: 8px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    min-width: 956px;
    max-height: 200px;
    overflow-y: scroll; }
  .merge-devices__search-store-form .hme-auto-complete-suggestions-label {
    margin-top: 8px; }
  .merge-devices__search-store-form .hme-merge-devices-device-grid-no-stores .hme-grid-header {
    display: none; }

.merge-devices-destination-device-section .merge-devices__search-store-form .hme-auto-complete-suggestions {
  left: auto;
  right: 0; }
