.vaio-provider-page__header {
    display: flex;
    width: 100%;
    min-width: 1400px;
    align-items: center;
    margin-bottom: 30px;

    .vaio-provider-page__header__create-provider {
        margin-left: auto;
    }

    .vaio-provider-page__filters-count{
        margin-left: 5vw;
    }
}
