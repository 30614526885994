.vaio-providers {
    padding: 20px 30px 10px 30px;
    flex: 1 1;
    display: flex;
    height: 100%;
    flex-direction: column;

    .vaio-providers-page {
        flex: 1 1;
        display: flex;
        height: 100%;
        flex-direction: column;

        .vaio-providers__grid {
            overflow: auto;
            margin-bottom: 40px;
            flex: 1;
        }

        .disabled-link {
            pointer-events: none;
            color: black;
        }

        .hme-grid-cell .hme-grid-cell-content {
            padding: 0px;
        }

        .hme-grid-row-red-background,
        .hme-grid-row-yellow-background {
            .hme-grid-cell {
                &.hme-grid-bold-font {
                    font-weight: bold;
                }
            }
        }

        .hme-grid-row-red-background {
            .hme-grid-cell {
                &.hme-grid-bold-font {
                    font-weight: bold;
                    color: #d41b3e;
                }
            }
        }

        .vaio-providers-alert-icon {
            height: 22px;
            margin-left: 10px;
        }

        .vaio-providers__paginate {
            padding-bottom: 30px;
            overflow: visible;
        }

        .react-tooltip {
            z-index: 100;
        }
        .__react_component_tooltip {
            background: rgba(255, 255, 255, .50) !important;
            text-align: center !important;
            border: 1px solid skyblue;
            box-shadow: 1px 1px 2px 0px lightgray;
            font-size: 14px !important;
            margin: -3px 0 0 6px;
            width: 200px !important;
        }
        .__react_component_tooltip::before {
            background: none !important;
        }
        
    }
}

.vaio-providers-popup-confirm-line-bold {
    font-weight: bold;
}