.hme-stores-status--admin__grid {
    .hme-admin-stores-status__list__account-email {
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #0074ef;
    }

    .hme-stores-status__list__inner-cell {
        padding-top: 21px;
        padding-bottom: 21px;

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            padding-bottom: 0;
        }
    }

    .hme-stores-status__list__status.hme-grid-cell.hme-grid-cell-last-in-block:last-of-type {
        text-align: right;

        .hme-grid-cell-content {
            padding-right: 15px;
            padding-left: 0;
        }
    }

    .hme-stores-status--admin__grid__cell--store-actions {
        .hme-grid-cell-content {
            padding-left: 0;
            padding-right: 0;
            text-align: center;
        }
    }

    .hme-stores-status--admin__grid__header__status {
        .hme-grid-cell-content {
            padding-left: 0;
            padding-right: 0;
            text-align: center;
        }
    }
}
