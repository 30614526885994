$main-color: #0074ef;
$link-color: #60adff;

.hme-messages-landing-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    & > .hme-messages-landing-filters {
        margin: 0 25px 0 0;
        & > .hme-messages-landing-header-filter-counter {
            font-family: Assistant;
            font-size: 24px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: 0.02px;
            text-align: right;
            color: #a7adba;
            & > strong {
                font-size: 30px;
                font-weight: bold;
                letter-spacing: 0.03px;
            }
            &.applied {
                font-family: Assistant;
                font-size: 24px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: 0.02px;
                text-align: right;
                color: #000819;
                & > strong {
                    font-size: 30px;
                    font-weight: bold;
                    letter-spacing: 0.03px;
                    color: $link-color;
                }
            }
        }
        & > .hme-messages-landing-header-reset {
            cursor: pointer;
            margin: 0 0 0 15px;
            font-family: Assistant;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.13;
            letter-spacing: normal;
            text-align: left;
            color: $main-color;
        }
    }
    & > .hme-messages-landing-header-divider {
        display: inline-block;
        width: 0;
        height: 40px;
        margin: 4.5px 0 3.5px;
        border: solid 1px #e5e5e5;
    }
    & > .hme-checkbox-component {
        margin: 0 0 0 25px;
    }
}
