.hme-section-sm {
    padding: 0 20px 30px 20px;
    .hme-section-separation{
        border-right: 1px solid #e8e9ed;
        padding-right: 20px;
        .hme-label{
            margin-bottom: 8px;
        }
        .hme-title{
            display:block;
            margin-bottom: 20px;
        }
    }
}
.hme-section{
    border:none;
}