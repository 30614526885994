$main-color: #0074ef;

.hme-grid-filter-cell-popup {
    background-color: #ffffff;
    padding: 15px 4px 15px 15px;
    border-radius: 8px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
    border: solid 1px $main-color;
    min-width: 272px;
    max-height: 370px !important;
    width: unset !important;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;

    .hme-grid-filter-cell-popup-search {
        margin-bottom: 8px;
        flex: none;
    }

    .hme-checkbox-component {
        margin: 0 auto 0 0;
        white-space: nowrap;
        .hme-checkbox-label {
            font-size: 16px;
            line-height: 1.31;
            letter-spacing: 1.28px;
            display: inline-block;
            white-space: nowrap;
        }

        .hme-checkbox-component-icon {
            margin-top: -3px;
        }
    }
    .hme-grid-filter-cell-popup-body {
        flex: 1;
        overflow-x: hidden;
        overflow-y: scroll;
        max-height: 300px;
        &::-webkit-scrollbar-thumb {
            background-color: $main-color;
            border-radius: 4px;
        }
        &::-webkit-scrollbar {
           width: 6px;
           height: 15px;
        }
    }
    .hme-grid-filter-cell-popup-header {
        font-family: Assistant;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.31;
        letter-spacing: 1.28px;
        text-align: left;
        color: #606672;
        padding-bottom: 18px;
        flex: none;

        .hme-grid-filter-cell-popup-close {
            float: right;
            font-size: 26px;
            line-height: 15px;
            color: #5c6670;
            font-weight: 600;
            cursor: pointer;
            margin: 0 15px 0 0;
        }
    }

    .hme-label.hme-grid-filter-cell-popup-no-items-found {
        display: inline-block;
        width: 100%;
        text-align: center;
        text-transform: unset;
    }

    .hme-grid-filter-cell-popup-row {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        padding-top: 14px;
        padding-bottom: 14px;

        &:last-of-type {
            padding-bottom: 0;
        }

        .hme-checkbox-component {
            flex: 1;
            display: flex;
            overflow: hidden;

            .hme-checkbox-component-icon {
                flex: none;
                margin-top: 2px;
            }

            .hme-checkbox-label {
                flex: 1;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .hme-grid-filter-cell-popup-only-label {
            font-family: Assistant;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.31;
            letter-spacing: 1.28px;
            text-align: left;
            color: $main-color;
            text-decoration: underline;
            padding-left: 15px;
            display: inline-block;
            margin: 0 10px 0 auto;
            flex: none;

            span {
                cursor: pointer;
            }
        }

        &.hme-grid-filter-cell-popup-item {
            padding-left: 15px;
        }
    }
}
