.device-upgrade-list__header {
    display: flex;
    width: 100%;
    min-width: 1400px;
    align-items: center;
    margin-bottom: 20px;

    .hme-button-component:hover {
        color: #000819;
    }

    .hme-input-container.hme-input-search-variant {
        width: 300px;
        margin-left: 20px;
    }

    .hme-stores-status--public__header__manage-hierarchy {
        padding-left: 40px;
        border-left: solid 1px #e5e5e5;
        height: 48px;
        padding-top: 14px;
        margin-left: 40px;
    }

    .device-upgrade-list__header__create-device-upgrade {
        margin-left: auto;
    }

    .device-upgrade-list__filters-count {
        padding-left: 20px;
    }
}
