@import 'src/mixins/breakpoints.scss';

@include mobile-only {
    .hme-tree-input {
        position: relative;

        .hme-tree-input__box {
            padding: 34px 7px 7px 25px;
        }

        .hme-label {
            position: absolute;
            top: 5px;
            left: 25px;
            font-size: 12px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: 1.08px;
            color: #606672;
        }

        .hme-tree-input__selection__select-all {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.29;
            letter-spacing: normal;
        }

        .hme-tree-input__selection__deselect-all {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.29;
            letter-spacing: normal;
        }

        &.hme-tree-input--focused {
            .hme-tree-input__box {
                border: solid 2px #0074ef;
                padding: 33px 6px 6px 24px;
            }
        }
    }
}