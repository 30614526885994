.hme-manage-hierarchy__move-popup__to {
    &__review {
        text-transform: none;
        margin-top: 15px;
    }

    &__error {
        color: red;
        text-transform: none;
        font-size: 12px;
        font-weight: 600;
    }

    &__selected {
        cursor: default;
    }
}
