.hme-tooltip {
    z-index: 2;
    border-radius: 0;

    &.tooltip.show {
        opacity: 1;
    }

    .tooltip-inner {
        background-color: #ffffff;
        border-radius: 0;
        font-family: Assistant;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 1.28px;
        text-align: left;
        color: #000819;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.25);
        max-width: unset;
        padding: 0;

        .hme-tooltip-content {
            padding: 10px 15px;
        }
    }

    .tooltip-headline {
        display: flex;
        justify-content: space-between;
        margin: 0 0 24px;


        .icon {
            color: #0074ef;
            margin: 0 9px 0 0;
            vertical-align: top;
        }

        .icon-clock {
            content: url('~assets/icons/icon-clock.svg');
            filter: invert(38%) sepia(99%) saturate(4008%) hue-rotate(198deg) brightness(96%) contrast(103%);
        }

        .title {
            line-height: 15px;

            strong {
                font-size: 13px;
                font-weight: normal;
                color: #606672;
            }
        }

        .btn-close {
            color: #606672;
        }
    }

    .tooltip-arrow {
        overflow: hidden;

        &::before {
            border-top-color: #ffffff;
        }

        &::after {
            content: "";
            position: absolute;
            bottom: 100%;
            z-index: -3;
            border-color: #fff;
            border-style: solid;
            border-width: .4rem .4rem 0;
            margin-left: 1px;
            transform-origin: 0 0;
            transform: rotate(45deg);
            box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.25);
        }
    }
}

.z-index-300 {
    z-index: 301 !important;
    max-width: 40em;
    text-align: left !important;
}
