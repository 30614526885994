@import "src/mixins/breakpoints.scss";

@include before-desktop-only {
  .hme-stores-status--partner {
    width: 100%;
    min-width: unset;
    padding: 0;
    overflow: auto;
    display: block;

    .hme-stores-status--partner__header {
      width: 100%;
      padding: 15px 15px 15px 15px;
      margin: 0;
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      .hme-stores-status--partner__header__sort-btn {
        display: block;
      }

      .hme-search-optionable {
        width: 100%;
        align-items: flex-start;
        margin: 0px;
        .hme-select {
          flex-basis: 35%;
          margin-right: 15px;
        }
      }

      .hme-title {
        display: flex;
        align-items: baseline;
        justify-content: flex-start;
        font-family: Assistant, sans-serif;
        font-size: 20px;
        font-weight: 600;
        line-height: 1.3;
        letter-spacing: -0.28px;
        color: #000819;
        margin-bottom: 15px;
      }
    }

    .hme-stores-status--partner__grid {
      display: none;
      min-width: unset;
    }

    .hme-stores-status--partner__tile-list {
      display: flex;
      flex-wrap: wrap;
      padding: 0 15px 14px 15px;
      overflow-x: hidden;
      overflow-y: auto;

      .hme-tile {
        align-items: flex-start;
        margin: 7.5px 0;
        padding: 15px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
        background-image: linear-gradient(to bottom, #fff, #fff 52%, #f2f2f2);
        position: relative;
      }

      .hme-stores-mobile--partner__tile__main__info {
        font-family: Assistant, sans-serif;
        display: flex;
        flex-direction: row;
        flex: 1 1 100%;

        .hme-stores-mobile--partner__store__info__item {
          display: flex;
        }

        .hme-stores-mobile--partner__tile__title {
          font-size: 16px;
          font-weight: 600;
          line-height: 1.5;
          letter-spacing: 0.02px;
          color: #606672;
          margin-bottom: 15px;
          text-transform: uppercase;
        }

        .hme-stores-mobile--partner__tile__store_info_section {
          width: 50%;
        }

        .hme-stores-mobile--partner__tile__device_info_section {
          border-left: 1px solid #e8e9ed;
          padding-left: 15px;
        }

        .hme-stores-mobile--partner__tile__sub__title {
          min-width: 125px;
          font-size: 12px;
          line-height: 2;
          letter-spacing: 1.08px;
          color: #606672;
          margin-bottom: 8px;
          text-transform: uppercase;
        }
        .hme-stores-mobile--partner__tile__sub__value {
          display: flex;
          flex: 0 0 auto;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          font-size: 14px;
          line-height: 1.71;
          letter-spacing: 0.27px;
          color: #000819;

          .hme-device-status {
            span {
              font-size: 14px;
              text-align: left;
            }
          }
        }
      }
    }
  }
}
