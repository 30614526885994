.editUser .forms {
  margin-bottom: 25px;
  margin-top: 47px;
}

.editUser .successMessage  {
  margin-top: 30px;
  margin-bottom: 30px;
}
@media screen and (max-width: 767px) {
  .editUser .forms h3 {
    font-size: 14px;
    margin-bottom: 17px;
    font-weight: bold;
    margin-top: 20px;
  }
  .block {
    display: block;
  }

  .editUser .forms select {
    background-color: #ebebeb;
    width: 200px;
  }
}
@media screen and (max-width: 991px) {
  .remove-end {
    justify-content: start !important;
  }
}
@media screen and (min-width: 768px) {
  .editUser .forms h3 {
    font-size: 30px;
    margin-bottom: 17px;
    margin-top: 20px;
  }

  .editUser .forms select {
    background-color: #ebebeb;
    width: 100%;
  }
}

.userDetails table.user_form tr th label {
  text-align: left;
  color: black !important;
  width: 100%;
}
.userDetails table.user_form tr td {
  padding-top: 15px
}
.userDetails table.user_form tr th {
  padding-top: 15px
}
.statusText{
 padding-left: 5px
}
.editUser .remove_button {
  clear: both;
  float: left;
  margin-right: 150px;
  margin-top: 0px;
  margin-left: 27px;
  margin-bottom: 15px;
}

.editUser table {
  border-collapse: collapse;
  border-spacing: 0;
}
table.user_form {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  font-family: Arial;
  vertical-align: baseline;
}

.editUser .forms th {
  font-size: 14px;
  text-align: left;
}
.editUser .cancel_butt {
  color: #D41B3E !important;
  font-size: 14px !important;
  font-weight: bold;
  vertical-align: middle;
  cursor: pointer;
}
.editUser a:link, a:active {
  color: #D41B3E;
  font-size: inherit;
  text-decoration: none;
}
.editUser .forms input[type=submit] {
  background-color: #d41b3e;
  border: none;
  float: right;
  color: #ffffff;
  cursor: pointer;
  padding: 10px 25px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
}

.req:after {
  color: #ff0000;
  content: " * ";
  display: inline;
  font-weight: bold;
  margin-right: 5px;
}
/*
.editUser th.req {
    padding: 10px;
} */

.editUser label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: lighter;
}

.editUser #Content {
  width: 100%;
  min-width: 1126px;
}


.editUser .form_cbox {
  width: 290px;
  border: 1px solid gray;
  height: 169px;
  overflow-y: auto;
  margin:0px !important;
}
.editUser .forms input[type=button] {
  background-color: #d41b3e;
  border: none;
  float: right;
  color: #ffffff;
  cursor: pointer;
  padding: 10px 25px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
}
.editUser span.select_all {
  color: #B9B9B9;
  font-style: italic;
  font-weight:lighter
}

.editUser #select, .editUser #select-none, .editUser #remove {
  color: #D41B3E !important;
}

.ctable {
  margin: 0 auto;
}

.ctable table {
  width: 100%;
}

.ctable .theader,.ctable .cheader {
  border: 1px solid #b9b9b9;
  color: #757575;
}

.ctable .theader th.dateTime {
  width: 200px;
  user-select: none;
}
.ctable .theader th.page {
  width: 125px;
  user-select: none;
}
.ctable .theader th.action {
  width: 100px;
  user-select: none;
}
.ctable .theader th.record {
  width: 300px;
  user-select: none;
}

.ctable tr.theader th {
  font-size: 16px;
  vertical-align: baseline;
  /* padding-top: 3px */
}

.ctable .ctable th {
  padding: 10px;
}
.ctable tr.tdata td {
  font-size: 14px;
}

.ctable td {
  padding: 10px;
  vertical-align: top;
}
.ctable caption {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: black;
}

.viewing_as {
  background: #ececec;
  display: table-cell;
  font-size: 12px;
  padding: 5px 7px 5px 5px;
  border-radius: 0px 0px 8px 0px;
}
.view-as-user {
  background: #E4F2FB;
  border-radius: 5px 5px 5px 5px;
  /* float: right; */
  border: 2px groove #D4E7F4;
  padding: 5px;
  text-align: center;
  line-height: 17px;
}
.view-as-user a{
  cursor: pointer;
  color: #337ab7 !important;
  text-decoration: none;
}

.view-as-user a:hover, .view-as-user a:focus {
  text-decoration: underline;
  color: #23527c !important;
}

.view-as-user span b {
 font-size: 16px;
}


.ctable .theader a, .cheader a {
  color: #757575;
  font-weight:lighter
}

.user_form span.StoreTitile{
  width:260px !important;
}

.content-middle {
  display: flex;
  align-items: center
}

.content-end {
  display: flex;
  justify-content: flex-end
}

td.fixedHeader,
th.fixedHeader {
  width: 180px;
}

div.rc-tree div.rc-tree-treenode span.rc-tree-checkbox-indeterminate {
  width: 13px !important;
  height: 13px !important;
  background-image:  url('../../images/minus-214-1184219-1.png');
  background-size: 11px 11px !important;
  background-position: 0 0 !important;
  border: 1px solid #1c5180;
}

.userDetails table.user_form .hme-user-form__resend__activation__email {
  display: flex;
  justify-content: flex-end;
}

.userDetails table.user_form .hme-user-form__resend__activation__email .hme-user-form__resend__activation__email__button {
  color: #D41B3E;
  font-size: 12px;
  font-weight: 700;
}
