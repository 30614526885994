.hme-account-dropdown--mobile {
    .hme-account-dropdown--mobile__menu-item {
        padding-top: 26px;
        padding-bottom: 26px;
        transition: padding-bottom .35s ease;

        &.hme-account-dropdown--mobile__menu-item--open {
            padding-bottom: 6px;
        }

        .hme-menu-item__icon {
            font-size: 32px;
            margin-top: -18px;
            margin-right: 13px;
        }

        .hme-menu-item__text {
            text-transform: none;
            font-family: Assistant;
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.25;
            letter-spacing: 0.4px;
            text-align: left;
            color: #000819;
        }
    }

    .hme-account-dropdown--mobile__items {
        overflow: hidden;
        transition: height .35s ease;

        .hme-menu-item {
            margin-left: 82px;
        }
    }
}
