@import 'mixins/breakpoints.scss';

.hme-components {
    .hme-page-title {
        font-family: Assistant;
        font-size: 32px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.75;
        letter-spacing: 0.03px;
        text-align: left;
        color: #000819;
    }

    // TODO: Remove this
    .hme-label {
        font-family: Assistant;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 1.44px;
        text-align: left;
        color: #000819;
        text-transform: uppercase;
    }
}

.hme-default-page {
    min-height: 40px;
    margin: 0;
}

.hme-page-component {
    padding-top: 60px;
    padding-right: 25px;
    padding-left: 50px;
}

@include before-desktop-only {
    .hme-l-size {
        display: none;
    }
}

@include desktop-only {
    .hme-s-size {
        display: none;
    }
}

.hme-smacktalk_icon {
    // This is default mobile size. In the fututre we should add default size for desktop
    display: inline-block;
    background: url('~images/SmackTalkMonster.png') no-repeat 0 center;
    background-size: 35px 31px;
    background-position: center center;
    width: 35px;
    height: 31px;
}

.hme-text-connect_icon {
    display: inline-block;
    background: url('~assets/icons/icon-headphones.svg') no-repeat center center;
    background-size: 32px 30px;
    width: 32px;
    height: 30px;
}

.btn-info {
    background-color: #17a2b8;
    border-color: #17a2b8;
    color: #fff;

    &:hover,
    &:focus,
    &:active {
        color: #fff;
        background-color: #138496;
        border-color: #117a8b;
    }

    &:focus {
        box-shadow: none;
    }
}

.btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;

    &:hover,
    &:focus,
    &:active {
        color: #fff;
        background-color: #bd2130;
        border-color: #b21f2d;
    }

    &:focus {
        box-shadow: none;
    }
}
