.hme-layout .hme-page div.hme-page-content.leader-board-templates-body {
    overflow-x: unset;
    overflow-y: unset;
}

.leaderboard-templates {
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;

    & > .Toastify {
        & > .Toastify__toast-container {
            user-select: none;
            width: max-content;
            height: 60px;
            z-index: 1;
            &--top-center {
                top: 33px !important;
            }
            & > .Toastify__toast.Toastify__toast--warning {
                cursor: unset;
                width: max-content;
                height: 60px;
                font-family: Assistant;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.36;
                letter-spacing: 0.42px;
                text-align: left;
                color: #000819;
                padding: 19px 15px 18px 20px;
                border-radius: 5px;
                box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
                background-color: #ffce28;
                & > .Toastify__close-button {
                    display: none;
                }
                & > .Toastify__toast-body .info-icon-svg {
                    width: 22px;
                    height: 22px;
                    margin: 0 9px 0 0;
                    color: #000819;
                }
            }
        }
    }

    &-header {
        margin-bottom: 30px;
    }

    &-sections {
        display: flex;
        flex: 1 1 100%;
        height: 100%;
        min-height: 860px;
        overflow-x: scroll;
        overflow-y: auto;
        margin: 0;

        &__modal-close-btn {
            display: none;
        }

        &--modal {
            min-height: 660px;

            & span.icon-info {
                width: unset;
                display: unset;
                vertical-align: unset;
                font-size: unset;
            }

            & div.hme-section {
                height: unset;
            }
        }
    }

    .hme-section {
        flex: 1;
        padding-right: 40px;
    }

    &-2-section,
    &-3-section {
        padding-left: 35px;
    }

    &-1-section, .hme-section.hme-section-first {
        min-width: 400px;
        max-width: 400px;
        padding-left: 50px;
    }

    &-2-section {
        min-width: 500px;
        max-width: 500px;
        overflow: visible;
    }

    &-3-section,
    .hme-section:last-of-type {
        min-width: 800px;
        max-width: 800px;
    }
}

.icon-white-check-svg {
    width: 18px;
    height: 18px;
    margin: 0 9px 0 0;
    color: #000819;
}

@import './Tablet.scss';
@import './Mobile.scss';
