.hme-center-message {
    min-height: 500px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    position: relative;
    justify-content: center;

    .hme-center-message-body {
        position: relative;
        top: 150px;
    }

    .hme-center-message-icon-large {
        width: 66px;
        height: 66px;
        object-fit: contain;
    }
}
