@import 'src/mixins/breakpoints';

@include before-desktop-only {
    .hme-create-contest {
        .hme-page .hme-page-content.hmeBody {
            padding: 0;
            overflow-y: auto;
        }
    }

    .create-contest {
        overflow-y: auto;

        &__page-title {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}
