.gchart div.cframe {
    width: 678px;
    height: 370px;
}

.cbar {
    color: #6f6f6f;
    font-size: 12px;
    margin: -1px 0;
    padding: 10px 0px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center
}
.blabel {
    float: left;
    margin: 0 10px;
}
.blabel span.dash_goal_pad {
    padding-top: 2px;
}

.blabel span {
    float: left;
}
.blabel span {
    margin-right: 5px;
}

.dp_label{
    color: #a6a6a6;
    font-size: 12px;
    position: relative;
    padding-left: 46%;
    margin-bottom: 10px;
    margin-top: 5px;
}

.chartHeading{
    background: url('../../images/th_background_up.png');
    /* background-color: #0087a1;*/
    color: #FFFFFF; 
    font-weight: normal;
    height: 36px;
    margin-top: 20px;
    line-height: 36px;
    text-align: center;
    vertical-align: middle; 
}


.chartSection {
    border: 1px solid #CACACA;
}

.dualGauge{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    /* margin: 0% 7%; */
    width: 590px;
    margin: 0 24px;
}

.singleGauge{
    display: flex;
    justify-content: center;
}


.lane-cont-top {
    background-image: url('../../images/yLaneSM.png');
    background-repeat: repeat-y;
    /* min-height: 430px; */
    border-top: 20px solid #ffffff;
    border-bottom: 20px solid #ffffff;  
    width: 636px;
    margin: 0 68px;
    margin-bottom: -80px;
}
/* .lane-cont-bot {
    background-image: url('../../images/yLaneBottom.png');
    background-repeat: repeat-y;
    border-top: 20px solid #ffffff;
    border-bottom: 20px solid #ffffff;
    width: 636px;
    margin: 0 68px;
    margin-bottom: -80px;
} */

.lane-cont-bot {
    background-image: url('../../images/yLaneBottom.png'); 
    background-repeat: repeat-y;
    /* min-height: 430px; */
    /* border-top: 20px solid #ffffff; */
    /* border-bottom: 20px solid #ffffff; */
    /* width: 636px; */
    /* margin: 0 68px; */
    margin-bottom: -100px;
    margin-left: 67px;
}



.lane-cont-mid {
    background-image: url('../../images/yLaneV2.png');
    background-repeat: no-repeat; 
    margin-left: auto;
    margin-right: auto;
    margin-top: 65px !important;
    height: 200px;
    width: 636px;
    margin: 0 68px;
    margin-bottom: -80px;
}


.lane_top_size {
    height: 300px;
    width: 502px;
    margin-top: -50px;
}
.lane_bot_size {
    height: 300px;
    width: 502px;
    margin-bottom: -40px;
}
.percent33{
width: 33.33%
}
.chart h3 {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
}

.chart .dbar {
    line-height: 12px;
    font-size: 12px;
}

/* .chart ul.rep_list {
    padding-left: 10px;
    padding-top: 10px;
} */

.chart ol, .chart ul {
    list-style: none;
}

.chart ul.rep_list li {
    display: inline;
    padding-right: 5px;
    float: left;
}

.chart .fright{
    float:right
}

.chart .fleft{
    float:left
}

.storeListingHeading{
width: 100%;
display: flex;
align-items: center;
justify-content: space-between;
padding-top: 10px;
}

.storeListingHeading a{
    color: #337ab7;
    cursor: pointer !important;
}

.chart ul {padding-left: 0px}

/* .chart {margin: 0% 5% 150px 5%} */

.StoreRankingHeading{
    background: url('../../images/th_background_up.png');
    /* background-color: #0087a1; */
    color: #FFFFFF;
    font-weight: normal;
    height: 36px;
    line-height: 36px;
    text-align: center;
    vertical-align: middle;
    font-size: 16px;
    border-bottom: 1px solid #FFFFFF;
}
.StoreRankingBody {
    width: 100%;
}
.StoreRankingSubHeading{
    background: url('../../images/th_background_up.png');
    color: #FFFFFF !important;
    font-weight: normal;
    line-height: 30px;
    text-align: center;
    vertical-align: middle;
    background: #cacaca;
    font-size: 16px;
    font-weight: normal;    
    border-bottom: 1px solid #FFFFFF;
}
.StoreRankingSubHeader{
    background: url('../../images/th_background_up.png');
    color: #FFFFFF;
    font-weight: normal;
    line-height: 30px;
    text-align: center;
    vertical-align: middle;
    font-size: 16px;
    font-weight: normal;
    border-bottom: 1px solid #FFFFFF;
    /* text-transform: capitalize !important; */
}

.currentSelected th,
.currentSelected th a span {
  background-color: #a6a6a6 !important;
  /* padding: 5px 4px; */
}


.currentSelected div.StoreRankingSubHeader {
    background: url('../../images/th_background_dn.png');
    background-color: #27a6bf;
    color: #FFFFFF;
    font-weight: normal;
    line-height: 30px;
    text-align: center;
    vertical-align: middle;
    font-size: 16px;
    font-weight: normal;
    border-bottom: 1px solid #FFFFFF;
}

.StoreRankingHeading.border{
    border : 1px solid white
}
.chart .row {
    /* margin-right: -15px; */
    /* margin-left: -15px; */
    margin: 0 px !important
}
.zeroMargin{
    margin: 0px !important;
    border-collapse: separate;
    border-left: 1px solid #CACACA;
    border-right: 1px solid #CACACA;
}

.chartTable{
    width: 100%
}

.chartTable a {
    color: #5672aa;
}

.chartTable td{
    text-align: center;
    color: black;
    height: 30px;
}
.chartTable th{
    text-align: center;
    color:black;    
   background-color: #CACACA;
 }

 .zeroPadding{
 padding: 0px !important
 }



.chartTable  .rshade {
    background-color: #ededed;
}

.chartTable  .rnhade {
    background-color: #FFFFFF;
}

.gaugeSide{
    margin-top: 46px;
    border-left: 1px solid black;
}

.chartTable .tabular {
    margin-top:30px
}


.dash_circle {
    width: 14px;
    height: 14px;
    background: red;
    -moz-border-radius: 7px;
    -webkit-border-radius: 7px;
    border-radius: 7px;
}


.storeRankingName {
    color: #5672aa !important;
    font-weight: bold !important;
    position: relative
}
.storeRankingNumber {
    width:100px;
    color: #5672aa !important;
    font-weight: bold !important;
   }



/* .storeDashboard .rshade {
    background-color: #ededed;
}

.storeDashboard .rshade {
    background-color: #ededed;
} */



.chartTable tr:nth-child(even) {background: #ededed}
.chartTable tr:nth-child(odd) {background: #FFFFFF}


.chartTable th, .chartTable td {
    border-bottom: 1px solid #FFFFFF;
    font-size: 16px;
    font-weight: normal;
    border-right: 1px solid #FFFFFF;
}

.storeRankingHeaders{
    margin-bottom: 15px;
    display: block!important;
}

.chartTable .SelectedTimeMeasure a{  color: #000000;
    cursor: default;
    font-weight: bold;
    text-decoration: underline;
}
 
.chartTable ul.rep_list li a.nolink {
    color: #000000;
    cursor: default;
    font-weight: bold;
    text-decoration: underline;
}


.SelectedTimeMeasure a span{
    display: inline;
    padding-right: 5px;
    float: left;
    color: #000000;
    font-weight: bold;
    text-decoration: underline;
}

.storeRankingDate{
    display: inline;
    padding-right: 5px;
    float: left;
    color: #000000;
    font-weight: bold;
}

/***** Bootstrap Tooltip styles *****/
.dashboard-tooltip .tooltip-inner, .ranking-tooltip .tooltip-inner {
    background-color: #ffffff;
    color: #000000;
}

.dashboard-tooltip .tooltip-inner{
    background-color: #ffffff;
    color: #000000;
    font-size: 16px!important;
    font-family: Helvetica;
    font-weight: lighter;
}

.dashboard-tooltip {
    margin-bottom: 10px;
    width: auto;
    max-width: 300px;
}

.dashboard-tooltip .arrow, .ranking-tooltip .arrow {
    display: none;
}

.ranking-tooltip {
    font-weight: bold;
}

.chartTable .__react_component_tooltip, .ranking-tooltip {
    width: 125px !important;
}

.chartTable .__react_component_tooltip, .dashboard-tooltip, .ranking-tooltip {
    font-size: 12px !important;
    border-radius: 5px !important;
    padding: 5px !important;
    text-align: center !important;
    background-color: #FFFFFF !important;
    border: 2px solid skyblue;
}
/***************************************************/
.chartTable .__react_component_tooltip:after  {
    content: none !important;
}
.chartTable .__react_component_tooltip::before  {
    content: none !important;
}
.rankingTableBorder{
    border-right: 1px solid white;
}
.tabular {
    border: 1px solid #dac6c6;
}

.tableColors{    padding: 10px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tableColors div {
    display: flex;
    align-items: center
}

.tableColors .dash_goal_pad{
    font-size: 12px !important;
    font: inherit;
    font-family: Arial;
    vertical-align: baseline;
    color: #867777;
}

.selectedRankLeft{
    border-top: 2px solid #06B1CB;
    border-left: 2px solid #06B1CB;
    border-bottom: 2px solid #06B1CB;
 }
 .selectedRankCenter{
    border-top: 2px solid #06B1CB;
    border-bottom: 2px solid #06B1CB;
 }
 .selectedRankRight{
    border-top: 2px solid #06B1CB;
    border-bottom: 2px solid #06B1CB;
 }
 .selectedRankLast{
    border-top: 2px solid #06B1CB;
    border-right: 2px solid #06B1CB;
    border-bottom: 2px solid #06B1CB;
 }
 .selectedRank{
    border: 2px solid #06B1CB;
 }


 .singledualGauge {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    width: 225px;
    margin: 0 24px;
}


.lane1{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    /* margin: 0% 7%; */
    width: 225px;
    margin: 0 24px;
}
.lane-cont-bot-lane1 {
    background-image: url('../../images/yLaneBottom.png'); 
    background-repeat: repeat-y;
    margin-bottom: -100px;
    margin-left: -106px;
}

col.thisSortCol {
    border: 2px solid #06B1CB;
}

col.notSortCol {
    border-top: 2px solid #ffffff;
}

.dialValue{
    position: absolute;
    top: 105px;
    z-index: 100;
    color: #FFFFFF;
    font-size: 28px;
    width: 100%;
    display: flex;
    justify-content: center;
 }

.dialGauge{
position: relative
}

.dialTitle {
    position: absolute;
    top: 185px;
    margin: 0 auto;
    text-align: center;
    width: 100%;
    font-size: 20px;
    color:black !important;
}

.google-visualization-tooltip-item {
    white-space: nowrap;
  }

.highlight {
  background: url('../../images/th_background_dn.png');
  background-color:  #3eb9d2;
  color: #FFFFFF;
}

h3.dailGaugeHeadingSingle {
    text-align: left;
    margin-left: 32px;
}

h3.dailGaugeHeadingDual {
    text-align: center;
    margin-left: 184px;
}

.lanetotalavgtimeChart{
    margin-bottom: 60px
}
.grayedHeaders{
    background-color: #a6a6a6 !important;
}

.fourColumns{
    padding: 0px 8px;
    width: 1px;
}

.mobileLaneSection{
    margin-bottom: -100px;
    margin-left: 80px;
}
.mobileLane{
    padding-right: 85px;
    margin-bottom: 5px
}

div.google-visualization-tooltip { pointer-events: none }

.google-visualization-tooltip-square {
    width: 12px !important;
    height: 12px !important;
    border-radius: 50%;
}
.google-visualization-tooltip-item {
    display: flex;
}

.chart h3.loaderTxt {
    font-size: 30px !important;     
    font-weight: normal !important;
    margin-bottom: 20px !important;
} 
/* 
LaneTotalAvgTime.google-visualization-tooltip-square {
    width: 12px !important;
    height: 12px !important;
} */

#LaneTotalAvgTime ul.google-visualization-tooltip-item-list li:last-child .google-visualization-tooltip-square {    width: 12px !important;
    margin-top: 5px;
    height: 6px !important;
    border-radius: 0% !important; }

    .singleLaneIE{
    margin-left: -60px !important
    }
    .dualLaneIE {
    margin-left: 30px
    }
ul.google-visualization-tooltip-item-list li:first-child {display: none}
@media (max-width: 1700px)
{
    .storeFlexBox {
        width: 100% !important;
    }
}

.customizedGauges svg g g circle {
    stroke-width: 45;
    fill: #0099b3 !important;
    stroke: #0099b3;
}

.customizedGauges svg g g text {
display: none
}