.apply-device-settings-taskbar {
    margin-bottom: 30px;
    width: 100%;
    display: flex;

    .hme-title {
        display: inline-block;
        flex: none;
        line-height: 48px;
    }

    .apply-device-settings-taskbar-link {
        align-items: center;
        justify-content: flex-end;
        display: flex;
    }

    .hme-input-search-variant {
        width: 318px;
        margin-left: 30px;
    }

    .apply-device-settings-taskbar-filters-count {
        flex: 21;
        padding-left: 30px;
        padding-right: 30px;
        margin-left: 30px;

        &.apply-device-settings-taskbar-separated {
            border-left: 1px solid #e5e5e5;
        }
    }
}
