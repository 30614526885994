@import 'src/mixins/breakpoints.scss';

@include before-desktop-only {
    .hme-system-status-devices-grid {
        padding-left: 15px;
        gap: 15px;

        .hme-grid {
            display: none;
        }

        &.hme-section-without-title .hme-title:not(:empty) {
            font-size: 16px;
            line-height: 24px;
        }
    }
}
