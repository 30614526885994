@import 'src/mixins/breakpoints.scss';

@include before-desktop-only {
  .account-page {
    .hme-account-wrapper {
      min-width: 100%;
      padding: 50px 16px;

      .hme-admin-account-header {
        padding: 0;

        .hme-title {
          font-weight: 600;
          font-size: 20px;
          line-height: 1.3;
          letter-spacing: -0.28px;
          color: #000819;
          padding: 0;
        }

        .hme-label {
          font-size: 14px;
          line-height: 1.43;
          letter-spacing: 1.26px;
          color: #000819;
        }
      }

      .hme-account-content {
        flex-direction: column;

        .hme-section {
          overflow-y: visible;
          border: none;
          padding: 0;

          .hme-account-form-area {
            padding-top: 20px;
            border-bottom: 1px solid #e5e5e5;

            .hme-title {
              font-size: 18px;
              line-height: 1.28;
              letter-spacing: 0.18px;
              color: #000819;
              margin-bottom: 15px;
            }

            .hme-account-subscription-date-range-resend-email {
              flex-direction: column;
            }

            .hme-account-billing-cycle-account-status {
              flex-direction: column;
            }

            .hme-account-subscription-types {
              display: flex;
              flex-direction: column;
              padding: 0;
              margin: 0;

              .hme-section-first {
                .hme-account-subscription-type {
                  margin: 0;

                  .hme-account-form-box {
                    min-height: 40px;
                  }
                }
              }
            }

            .hme-account-jtech-subscription-type {
              .hme-label {
                margin-bottom: 20px;
              }
            }
          }

          .hme-account-form-account-settings {
            .hme-account-form-section {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 20px;

              .hme-label {
                font-size: 14px;
                line-height: 1.43;
                letter-spacing: 1.26px;
                color: #000819;
                margin: 0;
                width: 50%;
              }
            }
          }

          &:last-child {
            .hme-account-form-area {
              &:last-child {
                border: none;
              }
            }
          }
        }
      }
    }
  }
}
