@import 'src/mixins/breakpoints.scss';

@include mobile-only {
    .hme-corporate-brand-sharing {
        &__content {
            padding: 20px 15px;
        }

        &__field-item {
            justify-content: space-between;
            width: 100%;

            gap: 40px;
        }

        .hme-save-footer {
            padding: 20px;
            justify-content: center;

            .hme-button-component {
                margin: 0;
            }
        }
    }
}
