.api-management-form-page {
    .api-management-form-page-footer {
        z-index: 3;
        text-align: right;
        padding: 20px 252px 20px 0;
        box-shadow: 0 -10px 20px 0 rgba(0, 0, 0, 0.16);
        background-color: #ffffff;
        margin-left: -45px;
        margin-right: -45px;

        position: fixed;
        bottom: 67px;
        width: 100%;

        .hme-button-component {
            margin-left: 20px;
            min-width: 102px;
        }
    }
}
