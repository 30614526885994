.hme-create_edit-sso-management-page {
    .sso-footer {
        position: sticky;
        bottom: 0;
        z-index: 1;

        width: 100%;
        padding: 20px 44px;

        text-align: right;
        box-shadow: 0 -10px 20px 0 rgba(0, 0, 0, 0.16);
        background-color: #ffffff;


        .hme-button-component {
            margin-left: 20px;
            min-width: 120px;
        }
    }
}