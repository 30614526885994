.new-vaio-provider {
    .new-vaio-provider-details {
        max-width: 850px;
        padding: 0 40px;
        margin-bottom: 200px;

        .hme-input-container, .vaio-provider-form-label {
            margin-top: 20px;
        }

        .new-vaio-provider-details-url-input {
            display: inline-flex;
            width: 100%;
            
            .hme-input-component {
                width: 100%;
            }
        }

        .no-top-margin {
            margin-top: 0;
        }

        .vaio-provider-form-label {
            margin-bottom: 4px;
        }

        .vaio-provider-form-short-input, .vaio-provider-form-port-error {
            width: 45%;
            max-width: 350px; // Roughly 50% of max-width from .vaio-provider-form-input above
            display: inline-flex;

            .hme-input-container {
                margin-top: 0;
            }
        }

        .vaio-provider-form-add-margin-top {
            margin-top: 20px;
        }
        
        .vaio-provider-form-short-input-second-element, .vaio-provider-form-port-error-second-element {
            margin-left: 10%; // 100 - vaio-provider-form-short-input * 2
        }

        .input-clock-icon {
            position: absolute;
            top: 14px;
            left: 14px;
            width: 22px;
            height: 22px;
            color: #606672;
        }

        .hme-title {
            margin-bottom: 0;
            font-weight: bold;
        }

        .hme-vaio-label {
            display: block;
        }

        textarea {
            overflow: auto;
            font-size: 15px;
        }

        p {
            margin-bottom: 5px;
        }
    }
}