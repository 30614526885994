.hme-account-info {
    width: 100%;

    .hme-input-container .hme-label {
        margin-bottom: 8px;
    }

    &__main-content {
        flex-grow: 1;
        height: calc(100% - 80px);
        padding: 30px 10px 0 50px;
        overflow-y: auto;
    }

    &__page-title {
        padding-bottom: 30px;
    }
    
    &__sections {
        display: flex;
        max-width: 1275px;
        gap: 60px;
        padding-bottom: 100px;
    }

    &__section {
        display: flex;
        
        &--1 {
            flex-basis: 805px;
        }

        &--2 {
            flex-basis: 405px;
        }
    }

    &__footer {
        position: relative;

        width: unset;
    }
}

@import './Tablet.scss';
@import './Mobile.scss';
