.hme-admin-header-date-filter {
    height: 94px;
    border-right: solid 1px #e5e5e5;
    display: flex;

    .hme-label.hme-admin-header-date-filter-label {
        flex: none;
        height: 100%;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.31;
        letter-spacing: 1.44px;
        color: #606672;
        text-transform: unset;
        padding-top: 13px;
    }

    .hme-admin-header-date-filter-content {
        flex: 1;
        height: 100%;
        margin-left: 8px;
    }

    .hme-toggle {
        margin-bottom: 6px;
    }
}