@import '../../../assets/styles/Variables';
@import '../../../assets/styles/Responsive';

.public-text-connect-page {
    // the style is added in order to remove the overlapping of the sidebar overlay by main subheader
    // todo: it would be better to fix it in the Layout component (requires regression testing)
    &.hmeBody {
        margin: -15px 0 0;
    }
}

.text-connect-wrapper {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}

.text-connect {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;

    &__container {
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: auto;
        flex: 1;
        height: 100%;
    }

    &__content {
        overflow: auto;
        padding: 28px 50px 0;
        display: flex;
        flex-direction: column;
        flex: 1;
        height: 100%;

        .hme-tab-panel,
        .hme-tab-panel-content {
            display: flex;
            flex: 1;
            height: 100%;
            flex-direction: column;
        }

        .hme-tab-panel-content {
            overflow: hidden;
        }
    }

    &__header {
        margin-bottom: 25px;
    }

    &__sections {
        display: flex;
        flex: 1;

        .hme-section {
            overflow: visible;
            flex: 1;

            & > .hme-title {
                min-height: 62px;
                margin-bottom: 20px;
            }
        }

        &__section {
            &--stores {
                max-width: 545px;
                margin-right: 27px;
                margin: 0 27px 25px 0;

                .hme-multi-select__select-dropdown {
                    padding-right: 10px;

                    .hme-checkbox-component {
                        align-items: flex-start;

                        .hme-checkbox-component-icon {
                            margin-top: 3px;
                        }

                        .hme-checkbox-label {
                            text-overflow: initial;
                            white-space: initial;
                        }
                    }
                }

                .hme-tree-item--disabled {
                    .hme-checkbox-component {
                        cursor: default;
                    }

                    .hme-checkbox-component {
                        opacity: 0.5;
                    }

                    .hme-checkbox-component-icon {
                        background: #c8ccd6;
                    }

                    .text-connect__stores__tree-text {
                        color: #000819;

                        &__store-name {
                            &--details {
                                opacity: 0.5;
                            }

                            &--error {
                                color: $accent-text;
                                opacity: 1;
                            }
                        }
                    }
                }
            }

            &--message {
                max-width: 690px;
                margin: 0 0 25px;
            }

            &__search-stores {
                position: relative;

                &__labels {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    margin-bottom: 5px;

                    .hme-label {
                        span {
                            color: $main-color;
                        }
                    }
                }

                &__input {
                    display: flex;
                    flex-direction: row;
                    margin: 0 0 20px;
                    position: relative;
                }

                &__tree-wrapper {
                    --border-styles: 1px solid #a7adba;
                    --border-radius: 24px;

                    position: relative;

                    .hme-tree-input {
                        &__box {
                            min-height: 425px;

                            &__no-stores,
                            .hme-tree {
                                position: relative;
                                top: 72px;

                                max-height: 310px;
                            }
                        }
                    }
                }

                &__selected-shown {
                    .hme-tree-item {
                        display: none;

                        &--checked {
                            display: block;
                        }
                    }
                }

                &__tree-header-block {
                    position: absolute;
                    top: 0;
                    z-index: 1;

                    width: 100%;
                    padding: 20px 48px;

                    border: var(--border-styles);
                    border-radius: var(--border-radius);
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;

                    .hme-checkbox-component .hme-checkbox-label {
                        font-size: 16px;
                    }
                }
            }

            &__info-icon {
                margin: 15px 0 0 8px;
                filter: invert(41%) sepia(98%) saturate(5283%) hue-rotate(199deg) brightness(100%) contrast(101%);
            }
        }
    }

    .hme-tab-panel {
        overflow: hidden;

        .hme-tab-panel-tabs {
            box-shadow: none;
            margin-bottom: 35px;
        }

        .hme-button-component {
            &.hme-tab-button-component {
                margin-right: 40px;
            }
        }
    }

    &__send_message {
        &.hme-tab-panel,
        .hme-tab-panel-content {
            overflow: visible;
        }
    }

    .hme-sidebar-overlay {
        &__sidebar {
            &__header-icon {
                svg {
                    filter: invert(38%) sepia(6%) saturate(948%) hue-rotate(182deg) brightness(98%) contrast(82%);
                }
            }
        }
    }

    .hme-section.text-connect__sections__section--stores.hme-section-first {
        .icon-info {
            margin: 0 0 0 5px;
            font-size: 16px;
        }
    }
}

.text-connect__info-icon__tooltip {
    width: 500px;
    .tooltip-headline {
        margin-bottom: 0px;

        .icon-info {
            display: none;
        }

        .title strong {
            font-weight: normal;
            font-size: 16px;
            color: #000819;
        }
    }
}

@import './Mobile.scss';
@import './Tablet.scss';
