.emoji-input {
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  width: 100px;
}
.emoji-input__icon {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25em;
}
.emoji-input__icon img {
  cursor: pointer;
  max-width: 100%;
  max-height: 100%;
}
.emoji-input__icon--active {
  border: 1px solid #bdbdbd;
  border-color: #06B1CB;
}

@media (max-width: 420px) {
  .emoji-input {
    width: 180px;
  }
  .emoji-input__icon {
    width: 36px;
    height: 36px;
  }
}
