/* -- container -- */
.custom-modal,
.custom-modal-mask {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.custom-modal {
    position: fixed;
}


.history-modal-popup {
    position: absolute !important;
    margin-top: 3em;
    margin-left: -2em;
    min-height: 21em;
    overflow-y: scroll;
    z-index: 109; 
    scroll-behavior: auto;
}

.blue-border{
    border: 1px solid #0074ef !important
}

/* -- mask -- */
.custom-modal-mask {
    position: absolute;
}

/* -- dialog -- */
.custom-modal-dialog {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 101;
    /* padding: 15px; */
    /* background: #fff; */
    /* border-radius: 3px; */
    /* box-shadow: 0 1px 3px rgba(0, 0, 0, .2); */
    border: 1px solid #ccc;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
    overflow: auto;
    background: #ffffff;
}

.custom-modal-dialog:focus {
    outline: none;
}

/* -- close button -- */
.custom-modal-close {
    position: absolute;
    cursor: pointer;
    top: 0.5rem;
    font-size: 3rem !important;
    right: 2rem;
    width: 2rem;
    height: 2rem;
    color: red;
}

/* .custom-modal-close:before,
.custom-modal-close:after {
    position: absolute;
    content: '';
    color: red;
    height: 1px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #999;
    border-radius: 100%;
    -webkit-transition: background .2s;
    transition: background .2s;
} */

.custom-modal-close:before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.custom-modal-close:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.custom-modal-close:hover:before,
.custom-modal-close:hover:after {
    background: #333;
}

/* -- fade -- */
@-webkit-keyframes custom-modal-fade-enter {
    from {
        opacity: 0;
    }
}

@keyframes custom-modal-fade-enter {
    from {
        opacity: 0;
    }
}

.custom-modal-fade-enter {
    -webkit-animation: custom-modal-fade-enter both ease-in;
    animation: custom-modal-fade-enter both ease-in;
}

@-webkit-keyframes custom-modal-fade-leave {
    to {
        opacity: 0
    }
}

@keyframes custom-modal-fade-leave {
    to {
        opacity: 0
    }
}

.custom-modal-fade-leave {
    -webkit-animation: custom-modal-fade-leave both ease-out;
    animation: custom-modal-fade-leave both ease-out;
}

/* -- zoom -- */
@-webkit-keyframes custom-modal-zoom-enter {
    from {
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3);
    }
}

@keyframes custom-modal-zoom-enter {
    from {
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3);
    }
}

.custom-modal-zoom-enter {
    /*
    -webkit-animation: custom-modal-zoom-enter both cubic-bezier(0.4, 0, 0, 1.5);
    animation: custom-modal-zoom-enter both cubic-bezier(0.4, 0, 0, 1.5);
    */
    -webkit-animation: custom-modal-zoom-enter both cubic-bezier(.34,1.61,.7,1.5);
    animation: custom-modal-zoom-enter both cubic-bezier(.34,1.61,.7,1.5);
}

@-webkit-keyframes custom-modal-zoom-leave {
    to {
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3);
    }
}

@keyframes custom-modal-zoom-leave {
    to {
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3);
    }
}

.custom-modal-zoom-leave {
    -webkit-animation: custom-modal-zoom-leave both;
    animation: custom-modal-zoom-leave both;
}

.custom-modal ::-webkit-scrollbar {
    width: 0.2rem;
}

.applyNexeoDeviceSettings .custom-modal ::-webkit-scrollbar {
    width: 0.8rem;
}