.hme-tree-item {
    .hme-expand-icon {
        width: 7px;
        margin-right: 16px;

        .hme-expand-icon__icon {
            margin-left: -19px;
        }

        &.hme-expand-icon-expanded {
            width: 13px;
            height: 11px;
            transform: translate(0, 8px);
            margin-right: 10px;

            .hme-expand-icon__icon {
                margin-left: -6px;
                margin-top: -17px;
            }
        }
    }

    .hme-checkbox-component {
        margin-right: 10px;
    }

    .hme-tree-item__header {
        margin-bottom: 27px;
        font-family: Assistant;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: 1.28px;
        color: #000819;
        display: flex;
    }

    .hme-tree-item__expand-content {
        padding-left: 25px;
    }

    &.hme-tree-item--final-node {
        .hme-tree-item__header {
            padding-left: 23px;
        }
    }
}