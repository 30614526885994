.leaderboard-desc-textbox {
  width: 100%;
  font-family: Assistant;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: 1.44px;
  text-align: left;
  color: #000819;
  border-radius: 24px;
  border: solid 1px #a7adba;
  background-color: #ffffff;
  padding: 14px 25px 16px;
  position: relative;
}

.leaderboard-desc-textbox:focus {
  outline: none;
  border: solid 2px #0074ef;
  background-color: #ffffff;
}

.textarea-hide-resize {
  resize: none;
}

.hme-textarea-component-before {
  position: absolute;
  top: 10px;
  left: 10px;
  font-family: Assistant;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 1.44px;
  text-align: left;
  color: #186df4;
}

.textarea-container {
  position: relative;
}

.textarea-button {
  position: absolute;
  top: 5px;
  right: 10px;
  background-color: transparent;
  border: none;
  color: #0074ef;
  text-decoration: underline;
  cursor: pointer;
  font-family: Assistant;
  font-size: 16px;
}

.textarea-container .leaderboard-desc-textbox.textarea-add-top-padding {
  padding-top: 30px;
}