.merge-devices__search-store-form {
    .hme-search {
        display: flex;

        .hme-input-container {
            .hme-input-wrapper {
                .hme-input__clear-button{
                    right: 2.5rem;
                }
            }
        }
    }

    .hme-submit-button-component {
        display: none;
    }
}

@import './Mobile.scss';
