.leaderboard-create-apply-device-settings-task-footer {
    z-index: 1;
    text-align: right;
    padding: 20px 252px 20px 0;
    box-shadow: 0 -10px 20px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    

    .hme-button-component {
        margin-left: 20px;
    }
}

