@import 'src/assets/styles/Variables';

.hme-login-details {
    padding-left: 0;
    border-left: none;

    &__password-field {
        position: relative;

        margin-top: -15px;
    }

    &__password-btn {
        position: absolute;
        top: 19px;
        right: 0;
    }

    &__password {
        &.hme-input-container .hme-input-wrapper {
            position: static;
        }
    }

    &__password-modal .hme-modal-component {
        .modal-content {
            padding: 40px;
            padding-bottom: 110px;

            border-radius: 5px;
        }

        .modal-header {
            display: none;
        }
    }

    &__password-modal-dialog {
        min-height: auto;
    }
}

@import './Tablet.scss';
