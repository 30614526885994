@import 'src/mixins/breakpoints.scss';

@include before-desktop-only {
    .hme-roles__actions--remove {
        margin-left: 30px;

        &::before {
            left: -16px;
        }
    }

    .hme-roles__edit-action {
        display: flex;
    }
}

@include tablet-only {
    .hme-roles {
        .hme-tile-list-item__wrapper .hme-button-component {
            margin-bottom: 8px;
        }
    }
}

@include mobile-only {
    .hme-roles {
        .hme-tile-list-item__wrapper .hme-button-component {
            margin-bottom: 6.5px;
        }
    }
}

@include desktop-only {
    .hme-roles__edit-icon {
        display: none;
    }
}
