@import 'src/mixins/breakpoints.scss';
@import 'src/assets/styles/Variables';

@include before-desktop-only {
    .hme-user-access {
        max-width: none;

        &__role {
            .hme-label {
                font-size: 14px;
                color: $main-gray;
            }
        }

        &__role-link.hme-button-component {
            top: -16px;
            padding: 20px;
            padding-right: 0;

            font-size: 14px;
        }
    }
}
