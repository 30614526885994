@import 'src/mixins/breakpoints.scss';

@include mobile-only {
    .hme-layout-device-settings {
        &.hme-layout {
            .hme-page {
                height: 100vh;

                .hme-page-content {
                    min-height: auto;
                    overflow-y: auto;
                    overflow-x: hidden;
                }
            }
        }

        .hme-notifications-list {
            .hme-notification.Toastify__toast {
                max-width: 500px;
            }
        }
    }

    .device-settings {
        &.hme-page-component {
            padding: 0;
            height: auto;
        }

        .manage-device-settings-footer {
            margin-left: -50px;
            margin-right: -50px;
            padding-left: 50px;
            padding-right: 50px;
            border: 0;

            .hme-manage-device-settings__paginate {
                padding: 15px;
            }
        }

        .hme-tile-list-item__wrapper {
            &.hme-tile-list-item--set-to-expire {
                background: #fff9e6;

                .hme-snapshot-settings-status--alert {
                    .icon-alert {
                        margin-left: 8px;
                    }
                }
            }

            .hme-tile-list-item__rows__wrapper {
                .hme-tile-list-item__rows__column {
                    .hme-tile-list__row {
                        .hme-tile-list__row__value {
                            text-overflow: clip;
                            white-space: normal;
                            word-break: break-all; 
                        }
                    }
                }
            }
        }
    }

    .device-settings-taskbar {
        padding: 15px 15px 0 15px;
        margin: 0;
        flex-direction: column;

        .hme-title {
            display: flex;
            align-items: baseline;
            justify-content: flex-start;
            font-family: Assistant, sans-serif;
            font-size: 20px;
            font-weight: 600;
            line-height: 1.3;
            letter-spacing: -0.28px;
            color: #000819;
            margin-bottom: 15px;
        }

        .device-settings-taskbar-filters-count {
            display: none;
        }

        .device-settings-taskbar-link {
            justify-content: flex-start;
            margin: 0 0 17px;

            .hme-button-component {
                width: 100%;
            }
        }
    }

    .device-settings-snapshots {
        width: 100%;
        padding: 0;
        overflow: auto;
        display: block;

        .hme-snapshots--public__tile-list {
            display: flex;
            flex-wrap: wrap;
            padding: 0 15px 14px 15px;
            overflow-x: hidden;
            overflow-y: auto;

            .hme-tile {
                align-items: flex-start;
                margin: 7.5px 0;
                padding: 15px;
                box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
                background-image: linear-gradient(to bottom, #fff, #fff 52%, #f2f2f2);
                position: relative;
            }

            .hme-snapshots-mobile--public__tile__main__info {
                font-family: Assistant, sans-serif;
                display: flex;
                flex-direction: column;
                flex: 1 1 100%;

                .hme-snapshots-mobile--public__snapshot__info__item {
                    display: flex;
                }

                .hme-snapshots-mobile--public__snapshot__underline__block {
                    border-bottom: solid 1px #e5e5e5;
                    margin-bottom: 7px;
                }

                .hme-snapshots-mobile--public__tile__title {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 1.5;
                    letter-spacing: 0.02px;
                    color: #606672;
                    margin-bottom: 15px;
                    text-transform: uppercase;
                }

                .hme-snapshots-mobile--public__tile__sub__title {
                    width: 115px;
                    font-size: 12px;
                    line-height: 2;
                    letter-spacing: 1.08px;
                    color: #606672;
                    margin: 0 10px 8px 0;
                    text-transform: uppercase;
                }
                .hme-snapshots-mobile--public__tile__sub__value {
                    display: flex;
                    flex: 0 0 auto;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    font-size: 14px;
                    line-height: 1.71;
                    letter-spacing: 0.27px;
                    color: #000819;

                    .hme-device-status {
                        span {
                            font-size: 14px;
                            text-align: left;
                        }
                    }
                }

                .hme-snapshots-mobile--public__details__button {
                    .hme-snapshots-mobile--public__expand__icon {
                        width: 16px;
                        height: 16px;

                        &.up {
                            transform: rotate(180deg);
                        }
                    }
                }

                .hme-snapshots-mobile--public__details__button__block {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    padding-top: 8px;
                }
            }

            .hme-snapshots-mobile--public__edit__snapshot__link {
                font-family: Assistant, sans-serif;
                min-width: 76px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 14px;
                line-height: 1.29;
                color: #0074ef;
                position: absolute;
                top: 15px;
                right: 15px;
            }
        }

        .hme-snapshot-settings-status--alert {
            max-width: none;
        }
    }

    .hme-create-snapshot-modal-dialog {
        &.modal-dialog {
            max-width: 90%;

            .modal-content {
                margin: 0 auto;
                width: 96%;
                padding-bottom: 0;

                .create-snapshot {
                    overflow: visible;

                    .create-snapshot-sections {
                        margin-bottom: 0;
                    }

                    .create-snapshot-source {
                        flex-direction: column;

                        .create-snapshot-device-type {
                            width: 100%;
                            padding-right: 0;
                            margin-bottom: 10px;
                        }

                        .create-snapshot-device-filter {
                            width: 100%;
                            padding-left: 0;

                            .create-snapshot-subhead {
                                padding-left: 0;
                            }
                        }
                    }

                    .create-snapshot-info {
                        .create-snapshot-setting-info-form {
                            .create-snapshot-setting-info-form-section {
                                flex-direction: column;

                                .create-snapshot-setting-info-form-box {
                                    &.create-snapshot-setting-info-form-settings-version {
                                        width: 100%;
                                        margin-bottom: 20px;
                                    }
                                }

                                .create-snapshot-setting-info-form-sliders {
                                    flex-direction: column;
                                    min-width: auto;
                                    margin: 0;

                                    .create-snapshot-setting-info-form-box {
                                        flex-direction: row;
                                        justify-content: space-between;
                                        margin: 0 0 10px;
                                    }
                                }
                            }
                        }
                    }

                    .hme-info-box {
                        margin-bottom: 40px;
                    }
                }
            }

            .modal-footer {
                flex-direction: column;
                padding: 20px 40px;

                box-shadow: 0 -10px 20px 0 rgba(0, 0, 0, 0.16);
                z-index: 100;

                .hme-button-component {
                    order: 1;
                    margin: 0;
                    width: 296px;
                }

                .hme-submit-button-component {
                    &.hme-button-component {
                        order: 0;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}

@include tablet-only {
    .device-settings-taskbar {
        flex-direction: row;

        .device-settings-taskbar-filters-count {
            display: block;
            visibility: hidden;
        }
    }

    .device-settings {
        .hme-paginate {
            border-top: 0;
        }

        .manage-device-settings-footer {
            padding-top: 25px;
            padding-bottom: 10px;
        }

        .hme-tile-list-item__wrapper {
            &.hme-tile-list-item--set-to-expire {
                background: #fff9e6;

                .hme-snapshot-settings-status--alert {
                    .icon-alert {
                        margin-left: 8px;
                    }
                }
            }

            .hme-tile-list-item__rows__wrapper {
                .hme-tile-list-item__rows__column {
                    .hme-tile-list__row {
                        .hme-tile-list__row__value {
                            text-overflow: clip;
                            white-space: normal;
                            word-break: break-all; 
                        }
                    }
                }
            }
        }

        .hme-snapshot-settings-status--alert {
            max-width: none;
        }
    }

    .hme-create-snapshot-modal-dialog {
        &.modal-dialog {
            .modal-content {
                margin: 0;
                padding-bottom: 0;
                width: 100%;

                .create-snapshot {
                    overflow: visible;

                    .create-snapshot-sections {
                        margin-bottom: 20px;
                    }

                    .create-snapshot-source {
                        flex-direction: row;

                        .create-snapshot-device-type {
                            width: 50%;
                            padding-right: 10px;
                            margin-bottom: 0;
                        }

                        .create-snapshot-device-filter {
                            width: 50%;
                            padding-left: 10px;

                            .create-snapshot-subhead {
                                padding-left: 10px;
                            }
                        }
                    }

                    .create-snapshot-info {
                        .create-snapshot-setting-info-form {
                            .create-snapshot-setting-info-form-section {
                                flex-direction: row;

                                .create-snapshot-setting-info-form-box {
                                    &.create-snapshot-setting-info-form-settings-version {
                                        width: 300px;
                                        margin-bottom: 10px;
                                    }
                                }

                                .create-snapshot-setting-info-form-sliders {
                                    flex-direction: row;
                                    min-width: 320px;
                                    margin-left: 40px;
                                    justify-content: space-between;

                                    .create-snapshot-setting-info-form-box {
                                        flex-direction: column;
                                        justify-content: flex-start;
                                        margin: 0;
                                    }
                                }
                            }
                        }
                    }

                    .hme-info-box {
                        margin-bottom: 20px;
                    }
                }
            }

            .modal-footer {
                flex-direction: row;
                padding: 20px 40px;

                box-shadow: none;

                .hme-button-component {
                    order: 0;
                    margin: 0 0 0 20px;
                    width: auto;
                }

                .hme-submit-button-component {
                    &.hme-button-component {
                        order: 1;
                        margin: 0 0 0 20px;
                    }
                }
            }
        }
    }
}
