.hme-tile-list__sort-filter-modal__filters {
  display: flex;
  flex-direction: column;

  .hme-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 1.44px;
    color: #000819;
    text-transform: uppercase;
    padding-bottom: 8px;
  }

  .hme-joined-filters-popup-section {
    padding-bottom: 12px;

    .hme-checkbox-component {
        .hme-checkbox-label  {
            font-size: 16px;
        }
    }

    &:last-child {
      padding: 0;
    }
  }
}
