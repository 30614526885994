.admin-users-page {
    height: 100%;
    overflow-y: auto !important;

    &.hmeBody {
        width: 100% !important;
    }

    .hme-users {
        &--wrapper {
            height: 100%;
            min-width: 1380px;
        }

        &--list {
            height: 100%;
            display: flex;
            flex-direction: column;
            padding: 0;

            .hme-search-optionable {
                min-width: 600px;
            }
        }
    }
}

.admin-users-list__confirm-popup {
    .modal-footer {
        .hme-button-component {
            &.hme-submit-button-component {
                margin-left: 20px;
            }
        }
    }
}

@import './Mobile.scss';
