@import 'src/mixins/breakpoints.scss';

@include before-desktop-only {
    .hme-view-role {
        padding: 22px 15px 0 15px;
        min-width: unset;
        width: 100%;
        height: 100%;
        overflow: hidden;

        .hme-view-role__general {
            flex: 0 1 360px;
            min-width: 360px;
        }

        .hme-view-role__sections {
            overflow: auto;
        }

        .hme-title-block {
            font-size: 18px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.28;
            letter-spacing: 0.18px;
            padding-bottom: 15px;
        }

        .hme-view-role__footer {
            margin-left: -15px;
            margin-right: -15px;
            min-width: unset;
        }
    }
}

@include mobile-only {
    .hme-view-role {
        overflow: auto;
        height: 100%;

        .hme-view-role__sections {
            display: block;
            width: 100%;
            min-width: unset;
            overflow: unset;
        }

        .hme-view-role__general {
            width: 100%;
            min-width: unset;
        }

        .hme-view-role__permissions {
            width: 100%;
            border-left: none;
            margin-top: 20px;
            min-height: unset;
        }
    }
}