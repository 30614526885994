@import 'src/assets/styles/Variables';

.hme-manage-hierarchy__file-manage {
    width: 345px;

    .hme-manage-hierarchy__file-manage__export {
        padding-bottom: 40px;
        border-bottom: 1px solid $separator-color;
        margin-bottom: 40px;
    }

    .hme-separator {
        display: none;
    }
}

@import './Tablet.scss';
@import './Mobile.scss';
