.admin-create-apply-device-settings-task-target {
    display: flex;
    flex-direction: column;

    .admin-create-apply-device-settings-task-target-filters-row {
        display: flex;
        flex: 0;

        .admin-create-apply-device-settings-task-target-filters-panel {
            flex: 1;
        }

        .hme-input-search-variant {
            width: 283px;
            align-self: flex-end;
        }
    }

    .admin-create-apply-device-settings-task-target-count {
        font-size: 30px;
        font-weight: bold;
        letter-spacing: 0.03px;
        color: #606672;
    }

    .admin-create-apply-device-settings-task-target-count {
        margin-right: 54px;
    }

    .admin-create-apply-device-settings-task-target-count-label {
        font-family: Assistant;
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 0.02px;
        text-align: left;
        color: #000819;
        vertical-align: text-bottom;
    }

    .hme-grid {
        flex: 1;
        overflow: auto;
        margin-top: 10px;
    }

    .hme-grid-filter-cell-popup {
        height: 210px;
    }

    .apply-device-settings__settings__version__header,
    .admin-create-apply-device-settings-task-target-version-cell {
        .hme-grid-filter-cell-popup {
            left: unset;
            right: 0;
        }
    }
}