.hme-profile {
    width: 100%;
    overflow: hidden;

    &.hme-tab-panel {
        height: 100%;
        display: flex;
        flex-direction: column;

        .hme-tab-panel-content {
            flex: 1;
            display: flex;
            overflow: hidden;
        }
    }
}

@import './Mobile.scss';
