@import 'src/mixins/breakpoints.scss';

@include mobile-only {
  .hme-device-settings-snapshots__name-tooltip {
    &__tip {
      width: 360px;
    }
    &__grid {
      flex-direction: column;
    }
  }
}
