@import 'src/mixins/breakpoints.scss';

@include mobile-only {
  .hme-system-status {
    padding-right: 7px;

    &__content {
        flex-direction: column;
        gap: 20px;
    }

    &__section-item {
        &:not(:first-of-type) {
            border-left: none;
        }
    }
  }
}
