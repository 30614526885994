.hme-history-receivers {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.hme-scrollable-tooltip {
  & > .tooltip-inner {
    max-height: 80vh;
    max-width: 40vh;
    overflow: scroll;
    & > div:first-child {
      margin: 0 0 7px 0;
      font-family: Assistant;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: #606672;
    }
  }
}