.hme-enhanced-filter {
    position: relative;
    &.hme-enhanced-filter-empty {
        display: none;
    }

    &.hme-enhanced-filter-opened {
        color: #0074ef;
    }

    .hme-enhanced-filter-icon {
        color: #0074ef;
        margin-top: -4px;
        cursor: pointer;
    }
    & > div.hme-filter-multi-popup {
        overflow-y: auto;
        scrollbar-width: thin;
        max-height: 430px;
        & > .hme-label {
            margin: 0 0 20px 0;
        }
        & > .hme-enhanced-filter-additional-header {
            display: flex;
            margin: 0 0 20px 0;
            & > div {
                margin: 0 10px 0 0;
                & > .hme-label {
                    margin: 0 0 8px 0;
                }
                &:first-child > .hme-label {
                    width: 150px;
                }
                &:last-child > .hme-label {
                    width: 133px;
                }
                & > .hme-select, .hme-select.hme-select-opened {
                    position: relative;
                    & > .hme-select-text {
                        margin: 0 0 0 20px;
                        // TODO: Move to Select component if we need it in other places
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                    }
                    display: flex;
                    align-items: center;
                    padding: 0 42px 0 0;
                    & > .hme-select-dropdown {
                        top: 40px;
                        left: 0;
                        right: 0;
                        max-height: 280px;
                    }
                }
            }
        }
        & > .hme-filter-multi-popup-body {
            overflow-y: auto;

            & > .hme-filter-multi-popup-row {
                padding: 0 0 27px 0;
                &:not(:first-child) {
                    padding: 0 0 27px 16px;
                }
                & > .hme-checkbox-component {
                    display: flex;
                    align-items: center;
                    & > .hme-checkbox-label {
                        width: 210px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }
    &.hme-enhanced-filter-loading {
        & * {
            cursor: progress !important;
        }
        & > .hme-filter-multi-popup > .hme-enhanced-filter-additional-header {
            pointer-events: none;
        }
    }
}