@import 'src/mixins/breakpoints.scss';

@include before-desktop-only {
    .hme-view-role__permissions {
        padding-left: 19px;
        padding-bottom: 30px;

        .hme-title.hme-title-block {
            padding-bottom: 2px;
        }

        .hme-view-role__permissions-section__sections {
            display: block;
        }

        .hme-view-role__permissions-section {
            margin-bottom: 20px;
            width: 345px;
        }

        .hme-view-role__permissions__select-links {
            .hme-button-component {
                font-size: 14px;
            }
        }

        .hme-view-role__permissions__select-links__deselect-all {
            margin-left: 30px;

            &::before {
                left: -15px;
                height: 18px;
                top: 0;
            }
        }
    }
}

@include mobile-only {
    .hme-view-role__permissions {
        padding-left: 0;

        .hme-view-role__permissions-section {
            width: 100%;
        }
    }
}
