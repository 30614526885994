.device-upgrade-status-message {
    margin-top: 10px;
    padding: 18px 20px 19px;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #cc0738;
    color: #fff;
    display: flex;
    width: 100%;
    align-content: flex-start;

    &__icon {
        width: 20px;

        svg {
            display: block;
        }
    }

    &__text {
        margin-left: 12px;
        letter-spacing: 0.48px;
    }
}
