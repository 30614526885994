.hme-section {
    border-left: 1px solid #e8e9ed;
    overflow-y: scroll;
    height: 100%;
    padding: 0 20px 30px 20px;
    overflow-y: hidden;

    &.hme-section-first {
        border: none;
        padding: 0;
    }

    &:last-of-type {
        padding-right: 0;
    }

    .hme-title {
        font-size: 24px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: 0.24px;
        text-align: left;
        color: #000819;
        margin-bottom: 35px;
    }

    &.hme-section-without-title {
        .hme-title {
            display: none;
        }
    }

    &.hme-section-disabled {
        .hme-title {
            color: #d1cdcd;
        }
    }
}