html { font-size: 10px; }
body { font-size: 14px; }

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.dropdown-toggle::after {
  border-top: .4em solid!important;
  border-right: .4em solid transparent!important;
  border-left: .4em solid transparent!important;
}

.App-title {
  font-size: 1.5em;
}
.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
.hmeBody{
    /*float:left;*/
	/*This change below is breaking the alignment everywer hence reverting it back*/
    width:85% !important;
    /* min-width: 1126px; */
    /* Workaround to keep with at 85%, please do not modify */
    min-height: 40px;
    margin: 0 auto;
}
.accountsettings {
  display: flex;
  flex-direction: column;
  align-content: center;
}
.myaccount,
section.leaderboards,
section.roles,
section.users,
div.contests,
div.awards,
section.groupDetailsPage,
div.dashboard-content,
/* div.forms.dash_cont.show, */
section.stores {
  padding-top: 2rem;
}

.hmeBody h1{
  font-weight: 400;
  font-size: 30px;
}

#root{
  display: block;
  margin-left: auto;
  margin-right:auto;
 /* width: 1130px;*/
}
.forms *{
  font-size: 12px;
}
.forms input, .forms select {
	border: 1px solid #b9b9b9;
	margin: 5px;
    padding: 5px;
}
.forms input[type=text], .forms input[type=password] {
	width:200px;
}
.form_cbox {
	border: 1px solid #B9B9B9;
	height: 125px;
	margin: 5px 0 0 5px;
	overflow: auto;
	padding: 5px;
	width: 250px;
}

select.wide_select {
	width:260px;
}

.store-modal-content select:disabled {
  color: #808080
}
.store-modal-content input[type="text"] {
  color: #545454;
  font-family: Lucida Grande,Lucida Sans,Arial,sans-serif;
}

.store-modal-content select {
  font-family: Lucida Grande,Lucida Sans,Arial,sans-serif;
}
/* Modal popup css */
.store-modal-popup{
  position: relative !important;
  /* top: 159px; */
  top: 20px;
  /* left: 353.5px; */
  height: auto;
  width: 550px !important;
  display: block;
  z-index: 101;
}

.store-modal-popup .store-modal-content{
  height:auto !important; 
}

div.tab-content #store-tabs-tabpane-1, 
div.tab-content #store-tabs-tabpane-2, 
div.tab-content #store-tabs-tabpane-3, 
#store-tabs-tabpane-4,
div.tab-content .tab-pane {
  border-right: 2px solid #ccc;
  border-bottom: 2px solid #ccc;
  border-left: 2px solid #ccc;
  outline: none;
  background-color: rgb(255, 255, 255);
  padding: 1em 1.4em;
}

.tab-pane {
  margin-top: -1px;
}

#store-tabs .tab-pane {
  min-height: 450px;
}

.tab-pane h4 {
  margin-top: 20px;
}

.public div.tab-content #store-tabs-tabpane-1 , .public div.tab-content #store-tabs-tabpane-2, .public div.tab-content #store-tabs-tabpane-3,.public div.tab-content #store-tabs-tabpane-4 {
  height: 505px;
  border-right: 2px solid #ccc;
  border-bottom: 2px solid #ccc;
  border-left: 2px solid #ccc;
  outline: none;
  background-color: rgb(255, 255, 255);
  padding: 1em 1.4em;
}

.store-modal-popup .store-modal-content{
  overflow: hidden;
  width: auto;
  height: 592px;
  min-height: 586px;
  position: relative;
  border: 0;
  padding: .5em 1em;
  background: none;
}
.store-modal-popup.add-store-popup .store-modal-content{
  height: auto;
  min-height: auto;
}

.store-modal-content {
  float: left;
  margin-left: 30px;
  width: 460px;
  border: 0px solid #b9b9b9;
}

#store-tabs{
  border: none;
}
.store-modal-content .nav-tabs .nav-item .active {
  color: #000000;
  font-size: 16px;
  font-weight: normal !important;
}

.store-modal-content .nav-tabs button:hover {
  text-decoration: none;
  cursor: pointer;
}

a, a:hover { text-decoration: none; }
a:active { text-decoration: none; }

a:focus {
  outline: none;
}

.store-modal-content .nav-tabs button {
  font-size : 16px;
  color: #06b1cb;
  font-weight: normal !important;
}
 
.nav-tabs button {
  margin-bottom: -1px;
  line-height: .8em !important;
  padding: 10px 15px;
}

.nav-tabs {
  justify-content: initial;
}

.nav-tabs .nav-item .active, .nav-tabs .nav-item .active:hover  {
  border: 2px solid #ccc !important;
  border-bottom-color: transparent !important;
}
.store-modal-popup.clientPopUp .modal-content {
  background: #f2f5f7;
}

.store-modal-popup.adminPopUp .modal-content {
  background: #f2f5f7;
}

.store-modal-popup.adminPopUp.add-store-popup .modal-content {
  height: auto;
}

.show-table-row{
  display : table-row;
}
.hide-table-row{
  display : none;
}

.adminPopUp .nav-tabs, .clientPopUp .nav-tabs {
  border-bottom: 2px solid #ddd !important;
  /* display: block !important; */
}

table.user_form tr th label {
  /* margin-top: 12px; */
   width: 125px;
  font-weight: normal;
  margin-bottom: 0px;


    color: #757575;
    font-size: 14px;
    text-align: right;
    /* margin-top: 12px; */
    padding-right: 5px;
}


table.user_form tr td input[type="text"],table.user_form tr td select{
  width: 200px;
  border: 1px solid #757575;
  border-radius: .35em;
  padding: 0;
  margin: 0;
  height: 22px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-indent: 4px;
  font-size: 12px;
}


table.user_form tr td{
  padding-top: 4px;
}

table.user_form tr th{
   padding-top: 8px;
}


.merge-statement {
  font-weight: normal;
  font-size: 12px;
  position: relative;
  bottom: 0px;
}

/* ION Components */
 .header{
  margin-top: 35px;
  margin-bottom: 7px;
  font-size: 26px;
}
.cib_header{
  margin-bottom: 5px;
  font-size: 26px;
}
.sys_table_widget{
  margin: 0 auto;
  outline: none;
}
.sys_table{
    text-align: left;
}
.theader{
  border: 1px solid #b9b9b9;
  color: #757575;
}
.sys_table_widget th {
  padding: 10px;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
 tr{
  display: table-row;
}
.sys-table tr.theader th {
  font-size: 10px;
}



.sys-ctr {
  vertical-align: middle;
  text-align: left;
  padding-right: 10px;  
}

.sys_table_widget td.sys-ctr {
    text-align: left;
    padding-right: 10px;
}
.sys-ion-check {
  padding: 5px;
}
.sys_table_widget.sys-table tr td {
  vertical-align: middle;
}
.sys_table_widget td {
  padding: 10px;
}
/* td a{
  color: #D41B3E;
  font-weight: 600px; 
} */
.remove-sys{
  margin-top: 20px;
}
.remove-button{
    right: 10px;
    padding: 10px;
    background-color: #D41B3E;
    color:white;
    text-align: center;
    border: none;
    vertical-align: middle;
    margin: auto;
} 

.remove-system-device{
  color: #D41B3E;
  font-size: 12px;
  text-decoration: none
}

a.remove-system-device:hover {
  text-decoration:none;
}

span.remove-device-item {
    background-color: #D41B3E;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 13px;
    padding: 10px 20px;
}

span.remove-device-item.disable-remove-device {
  opacity: .6;
  cursor: not-allowed;
}

.show {
  display: block;
}
.hide { 
  display: none!important;
}

.store-info-save {
  background-color: #d41b3e;
  border: none;
  float: right;
  color: #ffffff;
  cursor: pointer;
  padding: 10px 25px;
  text-indent: 4px;
  font-size: 12px;
  /* margin-top: 15px;  */
}

.btn-custom-pbi, .btn-custom-pbi:hover {
  text-decoration: none;
}
.btn-custom-pbi {
  color: rgb(43, 108, 231);
  width: 100%;
}
.container-btn-custom-pbi {
  padding: 0;
  box-shadow: 2px 1px 5px rgba(52, 52, 52, 0.141);
  margin-bottom: 1.7rem !important;
}

.redText{
  color: #D41B3E;
}

.merge-link {
  color: #D41B3E !important;
  text-decoration: none;
  cursor: pointer;
}
.merge-link a:hover {
  text-decoration: underline;
}
.merge-store-text {
  font-weight: normal;
  font-size: 12px;
  position: relative;
  /* top: 20px; */
  top: 35px;
  bottom: 0px;
}

.store-details-save {
  margin-top: 1em;
  /* margin-left: 6.5em; */
  margin-left: 8em;
  position: relative !important;
}

.add-store-save {
  display: flex;
  justify-content: flex-end;
  padding: 2em 0 0 0;
}

.add-store-save .submit-public {
  margin: 0 1em 0 0;
}
.add-store-save .submit-public:disabled {
  margin: 0 1em 0 0;
  cursor: wait;
}

.submit-public {
  display: inline-block;
  background-color: #d41b3e!important;
  border: none;
  color: #ffffff;
  cursor: pointer;
  padding: 10px 25px;
  font-size: 12px;
}

.cancel-public {
  color: #D41B3E!important;
  font-size: 14px !important;
  font-weight: bold;
  vertical-align: middle;
}

.cancel-public:hover {
  text-decoration: underline!important;
  cursor: pointer!important;
}

.public .nav {
/*  border-bottom: 1px solid #000000;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  */
  display: block !important;
}

.popover {
  padding: 9px 14px;
  font-size: 14px;
}

.public .nav-item button.active {
  border: 2px solid #ccc !important;
  border-bottom-color: transparent !important;
  border-radius: 5px 5px 0px 0px;
}

.clientPopUp .nav-tabs .nav-item .active {
  border: 2px solid #ddd !important;
  border-bottom: 0 solid #ddd;
}

.custom-margin .nav-tabs button {
  margin-right: 0px !important;
}

.custom-margin{
  margin-top: 25px;
}

/**********device list table starts here ***************/
.device-list-table {
  width: 435px;
  text-align: left;
  margin: 0 auto;
}

.device-list-table tr th {
  font-size: 12px;
  padding: 10px;
  outline: none;
  word-break: break-word;
}

.device-list-table tr td {
  vertical-align: middle;
  font-size :14px;
  padding: 10px;
}

/*.tdata{ */
  .device-row-data {
    background-color: #ffffff;
    border-bottom: 1px solid #b9b9b9;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    color: #000000;
  }

  .device-row-data:hover {
    background-color: #c4e9f1;
    border-bottom: 1px solid #709da2;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
  }

  td.device-checkbox {
    text-align: left;
    padding-right: 10px;
  }

  input.device-check-input {
    text-indent: 4px;
    font-size: 12px;
  }

  .tab-content .device-view {
    width: 50px;
    padding: 0;
  }

  .device-view-link {
    color: #D41B3E!important;
    font-size: 14px !important;
    font-weight: bold;
    vertical-align: middle;
    cursor: pointer;
  }

  .device-view-link:hover {
    color: #D41B3E!important;
    text-decoration: underline!important;
  }

.table-scroll {
  height : 200px;
  overflow:scroll;
}

.forms {
  margin-bottom: 25px;
  margin-top: 47px;
}
/**********device list table ends here ***************/

.modal{
  top : calc(50% - 340px) !important;
}


/* END ION Components */
.users .tdata {
  background-color: #ffffff;
  border-bottom: 1px solid #b9b9b9;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  color: #000000;
}

.users td.accounts_table_noUsers h3.noUsers {
  border: 1px solid #B9B9B9;
  border-radius: 0 0 0 0;
  color: #000;
  font-size: 20px;
  margin: 5px;
  padding: 5px;
}

/* Misc styling classes */
.text-overflow-ellipsis {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.modal-dialog {
  pointer-events: all;
}

@media (min-width: 576px) {
  .modal-dialog {
      max-width: 550px;
      margin: 1.75rem auto;
  }
}

.btn {
  font-size: 1.3rem;
}

@media all and (max-height: 700px) {
  .modal{
    top: 50%!important;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
  }
}
