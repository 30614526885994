@import "../../../../../../../assets/styles/Variables";

.hme-mobile-select {
  user-select: none;
  margin: 0 7px;
  position: relative;

  & > .hme-radio-group > .hme-radio-group-list {
    & > .hme-radio {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 0 0 13px 0;
      white-space: nowrap;
      & > .hme-label {
        font-family: Assistant;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 1.28px;
        color: $main-black;
        padding: 0 0 0 10px;
        text-align: left;
        text-transform: unset;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  & > .hme-select-icon {
    position: absolute;
    bottom: 13px;
    right: 20px;
  }

  & > .hme-select-opened {
    border: 2px solid #0074ef;
  }

  & > .hme-select-icon-opened {
    transform: rotate(180deg);
    color: #0074ef;
  }
}