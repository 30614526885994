@import 'src/mixins/breakpoints';

@include before-desktop-only {
    .hme-contest-actions {
        gap: 15px;
        justify-content: flex-end;

        & > *,
        .hme-button-component {
            flex: unset;
            margin-bottom: 0;
        }
    }
}
