@import 'src/mixins/breakpoints';

@include mobile-only {
    .hme-contest-view-edit-form {
        --gap: 30px;

        &__content {
            flex-direction: column;
            flex-wrap: nowrap;
        }

        &__section {
            &--limited-width {
                flex-basis: unset;
            }

            &:not(:first-child) {
                padding-top: var(--gap);
                padding-left: 0;

                border-left: none;
                border-top: 1px solid $input-underline;
            }

            &--stores {
                flex-basis: unset;
            }
        }
    }
}
