.hme-admin-system-status-linkewear-left-column {
    padding-right: 50px;
    width: 25%;
    flex: 1;

    .hme-info-field ~ .hme-info-field {
        border-top: 1px solid #e8e9ed;
        margin-top: 10px;
        padding-top: 10px;
    }
}

.hme-admin-system-status-linkewear-right-column {
    padding-right: 50px;
    width: 25%;
    flex: 1;

    .hme-info-field ~ .hme-info-field {
        border-top: 1px solid #e8e9ed;
        margin-top: 10px;
        padding-top: 10px;
    }
}