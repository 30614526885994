.text-connect {
    &__header {
        display: flex;
        align-items: center;

        .hme-title {
            margin-right: 20px;
        }
    }
}
