@import 'src/mixins/breakpoints';
@import 'src/assets/styles/Variables';

@include before-desktop-only {
    .hme-contest-page-title {
        --gap: 20px;

        padding-left: 0;
        padding-right: 0;
    }
}
