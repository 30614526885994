.hme-admin-store-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.688rem;

  .hme-admin-store-header-title-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1;

    .hme-title {
      margin: 0;
    }

    .hme-search-optionable {
      padding: 0 40px;

      .hme-select {
        width: 224px;
      }

      .hme-input-container {
          width: 271px;
      }
    }
  }

    .hme-submit-button-component {
      width: 104px;
      height: 48px;
      border: 1px;
      margin: 0;
      border-radius: 0 24px 24px 0;
    }

}
