.hme-hamburger-menu {
    --line-width: 25px;
    --line-height: 2px;

    position: relative;
    order: -1;

    width: 65px;
    height: 20px;
    margin: 0;
    padding: 0;
    
    appearance: none;
    cursor: pointer;
    background-color: transparent;
    outline: none;

    &::before,
    span::before,
    span::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;

        width: var(--line-width);
        height: var(--line-height);

        background-color: currentColor;
        transform: translateX(-50%) rotateZ(0);
        transition: transform .3s;
    }

    &::before {
        transform: translate(-50%, -50%) rotateZ(0);
        transition: opacity .1s, transform .3s;
    }

    span {
        position: absolute;
        top: 50%;
        left: 50%;

        width: var(--line-width);
        height: 100%;

        transform: translate(-50%, -50%) rotateZ(0);

        &::before {
            top: 0px;
        }

        &::after {
            top: unset;
            bottom: 0px;
        }
    }

    // Opened state
    &--opened {
        &::before {
            opacity: 0;
            transform: translate(-250%, -50%) rotateZ(0);
        }

        span::before {
            top: 50%;

            transform: translate(-50%, -50%) rotateZ(135deg);
        }

        span::after {
            bottom: 50%;

            transform: translate(-50%, 50%) rotateZ(-135deg);
        }
    }
}
