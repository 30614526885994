@import '../../../../../mixins/breakpoints.scss';

@include before-desktop-only {
    .hme-manage-hierarchy__file-manage__export {
        .hme-single-select {
            margin-right: 4px;
        }

        .hme-button-component {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}
