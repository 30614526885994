@import 'src/mixins/breakpoints.scss';

.hme-reset-password {
    margin-left: auto;
    margin-right: auto;
    width: -webkit-fit-content;
    width: fit-content;;

    .Toastify {
        min-height: 70px;
        flex: 0;
        width: auto;
        flex-basis: 100%;

        .Toastify__toast-container--top-center.hme-reset-password__notifications-list {
            position: static !important;
            transform: none;
            display: flex;
            justify-content: center;
            margin-top: 20px;

            .Toastify__close-button {
                display: none;
            }
        }
    }

    .hme-title {
        margin-bottom: 14px;
    }

    .hme-reset-password__info {
        width: -webkit-fit-content;
        width: fit-content;
        margin-right: 20px;
        display: inline-block;
        vertical-align: top;

        .hme-reset-password__info__title {
            font-family: Assistant;
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            color: #000819;
            margin-bottom: 12px;
        }

        .hme-reset-password__rules {
            width: -webkit-max-content;
            width: max-content;
        }

        .hme-reset-password__rule {
            margin-bottom: 8px;
        }
    }

    .hme-reset-password__form {
        width: -webkit-fit-content;
        width: fit-content;
        display: inline-block;
        padding: 37px 36px;
        border-radius: 24px;
        background-color: #f5f5f5;

        .hme-input-container {
            margin-bottom: 20px;

            .hme-label {
                white-space: nowrap;
                flex: 1;
                margin-right: 20px;
            }

            .hme-input-wrapper {
                width: 340px;
                justify-self: flex-end;
            }
        }

        .hme-submit-button-component {
            margin-left: auto;
            display: block;
        }
    }

    @include before-desktop-only {
        & {
            width: 100%;

            .Toastify {
                min-height: 11px;
                margin-bottom: 15px;
                width: 100%;

                .Toastify__toast-container--top-center.hme-dfa-code__notifications-list {
                    margin-top: 15px;
                }
            }

            .hme-title {
                font-size: 26px;
                line-height: 1.31;
                letter-spacing: -0.36px;
            }

            .hme-reset-password__info {
                display: block;
                width: 100%;
                margin-bottom: 20px;;

                .hme-reset-password__rules {
                    width: 100%;
                }
            }

            .hme-reset-password__form {
                display: block;
                width: 100%;
                padding: 20px;
                box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0);

                .hme-input-container {
                    margin-bottom: 20px;
                    display: block;

                    .hme-label {
                        margin-right: 0;
                        margin-bottom: 8px;
                    }

                    .hme-input-wrapper {
                        width: 100%;
                    }
                }

                .hme-submit-button-component {
                    margin-left: 0;
                    width: 100%;
                    display: block;
                }

            }
        }
    }
}