@import 'src/mixins/breakpoints.scss';

@include tablet-only {
    .hme-view-user__footer {
        padding-right: 23px;
    }
}

@include mobile-only {
    .hme-view-user__footer {
        padding-left: 0;
        padding-right: 0;

        display: flex;
        justify-content: center;
    }
}
