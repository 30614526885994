.hme-history-tile-inner {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  min-width: 340px;
  min-height: 100%;
  position: relative;
  & > .category-icon {
    & > .hme-history-category {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      & > img {
          width: 56px;
          height: 56px;
          margin: 0 8px 25px 0;
          padding: 0 0 4.2px;
          border: solid 2px #0074ef;
          border-radius: 50%;
          object-fit: contain;
      }
      & > .smacktalk-history-categories-title {
          display: none;
      }
    }
  }
  & > .history-data {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    & > .history-info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      & > .category-label {
        font-family: Assistant;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.31;
        letter-spacing: 0.3px;
        text-align: left;
        color: #000819;
      }
      & > .history-date {
        margin: 0 0 11px 5px;
        font-family: Assistant;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: right;
        color: #606672;
      }
    }
    & > .history-list {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin: 0;
      & > div {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        & > dt {
          width: 100px;
          font-family: Assistant;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 2;
          letter-spacing: 1.08px;
          text-align: left;
          color: #606672;
        }
        & > dd {
          width: 100%;
          font-family: Assistant;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.71;
          letter-spacing: 0.27px;
          text-align: left;
          color: #000819;
          word-break: break-word;
        }

      }
    }
  }
}
