.new-vaio-provider {
    &__header {
        display: flex;
        align-items: center;

        .hme-title {
            margin-right: 40px;
        }

        &__links {
            &__box {
                border-left: 1px solid #e6e6e6;
                padding: 10px 40px;
            }
        }

        &__link {
            color: #0074ef;
            text-transform: capitalize;
            &__icon {
                width: 30px;
                height: 30px;
                filter: invert(26%) sepia(99%) saturate(2225%) hue-rotate(211deg) brightness(99%) contrast(93%);
                margin-right: 10px;
            }

            &.hme-button-disabled-link-component {
                .new-vaio-provider__header__link__icon {
                    filter: invert(71%) sepia(14%) saturate(217%) hue-rotate(183deg) brightness(96%) contrast(89%);
                }
            }
        }
    }
}
