@import '../../../../assets/styles/_Variables.scss';

.device-settings-taskbar {
    padding: 0 0 23px 0;
    display: flex;
    line-height: 48px;
    vertical-align: middle;

    .hme-title {
        display: inline-block;
        flex: none;
        line-height: 1.5;
    }

    .device-settings-taskbar-link {
        text-align: right;
        flex: none;
        display: flex;
        justify-content: flex-end;

        .hme-button-component {
            flex: none;
            height: 40px;
        }
    }

    .device-settings-taskbar-filters-count {
        flex: 21;
        padding-left: 30px;
        padding-right: 30px;

        &.device-settings-taskbar-separated {
            border-right: none;
        }
    }

    &__name-tip {
        margin-left: 40px;
        border-left: 1px solid $header-separator-grey;
        padding-left: 40px;
    }
}

@import './Tablet.scss';
@import './Mobile.scss';
