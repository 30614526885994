.hme-view-store-settings-task {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-width: 1910px;
    overflow-y: auto;

    &.hme-page-component {
        padding-top: 27px;
    }

    .hme-view-store-settings-task-header {
        flex: none;
        margin-bottom: 40px;
    }

    .hme-view-store-settings-task-info {
        flex: 1 1 100%;
        overflow: hidden;
        min-height: 371px;
    }

    .hme-view-store-settings-task-footer {
        flex: none;
    }
}