.hme-view-store-settings-task-footer {
    height: 80px;
    box-shadow: 0 -10px 20px 0 rgba(0, 0, 0, 0.16);
    text-align: right;
    padding: 19px 66px 18px 0;
    margin-left: -48px;
    margin-right: -25px;

    .hme-button-component {
        margin-top: auto;
        margin-bottom: auto;
        display: inline-block;
        margin-left: 20px;
    }

    .hme-view-store-settings-task-footer-close {
        display: inline-block;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;
    }
}