.apply-settings-public-container {
    .hme-target-device-list__filters-row {
        margin-bottom: 12px;

        .hme-target-device-list__filters-panel {
            display: flex;

            .hme-target-device-list__target-count {
                line-height: 24px;
            }

            .hme-target-device-list__filters-count {
                border-left: 1px solid #e5e5e5;
                height: 100%;
                padding: 8px 40px 0;

                .hme-grid-filters-count-count-number {
                    font-size: 24px;
                }

                .hme-grid-filters-count-label {
                    font-size: 24px;
                    line-height: 1;
                    padding-top: 0;
                }
            }

            .hme-target-device-list__hide-disabled {
                border-left: 1px solid #e5e5e5;
                height: 100%;
                padding: 14px 40px 0;
                display: flex;

                .hme-checkbox-component {
                    display: flex;

                    .hme-checkbox-label {
                        font-size: 16px;
                        line-height: 16px;
                        color: #000819;
                        flex-shrink: 5;
                    }
                }

                .hme-tip {
                    display: flex;
                }
            }
        }
    }
}

.hme-target-device-list__hide-disabled__tip {
    width: 410px;
}
