.hme-reset-password__rule {
    display: flex;

    .hme-reset-password__rule__icon--neutral {
        display: block;
        width: 6px;
        height: 6px;
        border-radius: 3px;
        margin-top: 8px;
        background-color: #606672;
    }

    .hme-reset-password__rule__label {
        flex: 1;
        font-family: Assistant;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #000819;
        display: block;
        margin-left: 24px;
    }

    .hme-reset-password__rule__icon--valid {
        display: none;
    }

    .hme-reset-password__rule__icon--invalid {
        display: none;
    }

    &.hme-reset-password__rule--valid {
        .hme-reset-password__rule__icon--neutral {
            display: none;
        }

        .hme-reset-password__rule__icon--valid {
            display: inline-block;
            margin-top: 5px;
            width: 16px;
            height: 16px;
            color: #32820a;
            vertical-align: top;
        }

        .hme-reset-password__rule__label {
            margin-left: 14px;
            color: #32820a;
        }
    }

    &.hme-reset-password__rule--invalid {
        .hme-reset-password__rule__icon--neutral {
            display: none;
        }

        .hme-reset-password__rule__icon--invalid {
            display: inline-block;
            margin-top: 5px;
            width: 16px;
            height: 16px;
            color: #cc0738;
            vertical-align: top;
        }

        .hme-reset-password__rule__label {
            margin-left: 14px;
            color: #cc0738;
        }
    }
}