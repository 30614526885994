@import 'src/mixins/breakpoints.scss';

@include mobile-only {
    .create-snapshot {
        .create-snapshot-device-filter {
            .device-filter-form-input,
            .device-filter-form-input {
                position: relative;

                .hme-auto-complete-suggestions {
                    width: 100%;
                    left: 0;

                    .hme-grid {
                        display: none;
                    }

                    .hme-tile-list {
                        display: block;
                        padding: 0;
                    }

                    .hme-tile-list__wrapper {
                        padding: 0;
                    }

                    .hme-tile-list-item__rows__wrapper {
                        flex-direction: row;

                        .hme-tile-list-item__rows__column {
                            &:first-child {
                                .hme-tile-list__row__name,
                                .hme-tile-list__row__value {
                                    position: relative;
                                    padding-left: 0;
                                    white-space: normal;

                                    &::before {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }

                    .hme-tile-list-item__wrapper {
                        padding: 0 15px 15px;
                        border-radius: 0;
                        box-shadow: none;
                        background-image: none;
                        margin: 0 0 10px;
                        border-bottom: 1px solid #e5e5e5;

                        .hme-tile-list__row {
                            flex-direction: column;
                            margin: 0 8px 9px 0;

                            .hme-tile-list__row__name {
                                color: #5c6670;
                                font-size: 14px;
                                line-height: 1.29;
                                margin: 0 0 6px;
                            }

                            .hme-tile-list__row__value {
                                color: #000819;
                                font-size: 16px;
                                line-height: 1.13;
                                white-space: normal;
                                word-break: break-all;
                            }

                            .hme-tile-list__row__name,
                            .hme-tile-list__row__value {
                                padding-left: 15px;
                                position: relative;

                                &::before {
                                    content: '|';
                                    display: block;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                }
                            }
                        }

                        &.hme-tile-item-disabled {
                            cursor: default;

                            .hme-tile-list-item__rows__column {
                                .hme-tile-list__row {
                                    .hme-tile-list__row__name,
                                    .hme-tile-list__row__value {
                                        color: #a7adba;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
