@import 'src/mixins/breakpoints.scss';

@include before-desktop-only {
  .hme-distributors-accounts {
    padding: 15px 0;

    .hme-distributors-accounts__list__wrapper {
      display: none;
    }

    .hme-distributors-accounts--paginate {
      min-width: 100%;
    }

    .hme-distributor-accounts__header__wrapper {
      flex-direction: column;
      align-items: flex-start;
      padding: 0 15px 15px 15px;

      .hme-title {
        font-size: 20px;
        font-weight: 600;
        line-height: 1.3;
        letter-spacing: -0.28px;
        color: #000819;
      }

      .hme-search-optionable {
        width: 100%;
        padding: 15px 0;

        .hme-select {
          display: grid;
        }
      }
    }
  }
}

@include tablet-only {
  .hme-distributors-accounts {
    .hme-distributor-accounts__header__wrapper {
      .hme-search-optionable {
        flex-wrap: nowrap;
        flex: 1 1;

        .hme-select {
          flex: 1;
        }

        .hme-input-container {
          flex: 1;
          margin-left: 15px;
        }
      }
    }
  }
}
