@import 'src/assets/styles/Variables.scss';

.hme-notifications-center__notification {
    max-width: 1024px;
    width: 100%;
    padding: 14px 15px 15.5px;
    border-radius: 10px;
    box-shadow: $notification-shadow;
    border: solid 1px $main-color;
    background-image: $notification-gradient;
    font-family: $font-family;
    --icon-size: 40px;

    &__title {
        display: flex;
        height: 23px;
        align-items: center;
        width: 100%;
        margin-bottom: 18px;
    }

    &__icon {
        color: $main-color;
        margin-right: 9px;
    }

    &__name {
        mix-blend-mode: darken;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: 0.02px;
        color: $main-black;
        margin-right: auto;
    }

    &__remove.hme-button-component.hme-transparent-button-component {
        &,
        &:active {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.29;
            letter-spacing: normal;
        }
    }

    &__separator--remove {
        margin-left: 20px;
        margin-right: 20px;
    }

    &__sent-date {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: 1.26px;
        color: $main-gray;
        padding-right: 40px;
    }

    &__body {
        display: flex;
        flex-wrap: wrap;
    }

    &__message {
        flex: 1;
    }

    &__break {
        height: 0;
        flex: 1 1 100%;
    }

    &__body {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.3px;
        color: $main-black;
        display: flex;
    }

    .hme-expand-icon {
        margin-left: auto;
        color: $main-color;

        svg {
            transform: none;
            width: var(--icon-size);
            height: var(--icon-size);
            margin-top: -9px;
        }

        &.hme-expand-icon-expanded {
            svg {
                transform: rotate(180deg);
                width: var(--icon-size);
                height: var(--icon-size);
            }
        }
    }

    &__expanded-content {
        margin-top: 15px;
        margin-right: var(--icon-size);
        width: 100%;
    }

    &--read {
        .hme-notifications-center__notification {
            &__icon {
                color: $notification-read-icon-color;
            }

            &__name,
            &__body {
                color: $main-gray;
            }
        }
    }
}

@import './Mobile.scss';
