@import 'src/mixins/breakpoints.scss';

@include mobile-only {
    .hme-view-role__general {
        width: 100%;

        .hme-input-container {
            width: 100%;
        }
    
        .hme-textarea {
            width: 100%;
        }
    }
}