.merge-devices-wrapper {
    height: 100%;
    min-width: 1600px;

    &.loading {
        display: flex;
    }

    .merge-devices {
        height: 100%;
        display: flex;
        flex-direction: column;

        .merge-devices-content {
            overflow: auto;
            height: 100%;
            padding: 0 48px;
        }

        .admin-create-apply-device-settings-task-header {
            margin-bottom: 39px;
        }

        .merge-devices-sections {
            display: flex;
            flex: 1 1 100%;
            min-height: 620px;

            .merge-devices-source-device-section,
            .merge-devices-destination-device-section {
                width: 50%;
                box-sizing: border-box;
            }

            .merge-devices-source-device-section {
                padding-right: 15px;
            }
        }
    }
}

@import './Mobile.scss';
@import './Tablet.scss';