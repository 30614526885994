@import 'src/assets/styles/Variables';

.hme-toggle.hme-toggle--white {
    .toggle-button-group {
        .hme-button-component {
            border: solid 2px $main-color;
            background: $main-white;
            color: $main-black;
        }

        .hme-submit-button-component {
            background: $main-color;
            color: $main-white;
        }
    }
}
