.hme-row-text {
    display: inline-block;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: 1.44px;
    text-align: left;
    color: #000819;
}
