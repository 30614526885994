@import 'src/mixins/breakpoints.scss';

@include tablet-only {
    .hme-layout {
        .hme-page {
            .admin-merge-devices-page,
            .public-merge-devices-page {
                &.hmeBody {
                    padding: 0;
                }
            }
        }
    }

    .merge-devices-wrapper {
        min-width: auto;
        padding-bottom: 0;

        .hme-title {
            font-size: 20px;
            font-weight: 600;
            line-height: 1.3;
            letter-spacing: -0.28px;
        }

        .merge-devices {
            .merge-devices-content {
                overflow: auto;
                padding: 0 15px;
                display: flex;
                flex-direction: column;
            }

            .merge-devices-header {
                margin: 21px 0 25px;
            }   
            
            .merge-devices-sections {
                flex-direction: row;
                min-height: auto;

                .hme-title {
                    margin-bottom: 15px;
                }
                
                .hme-section {
                    padding: 0;
                    border: 0;
                }
    
                .merge-devices-source-device-section,
                .merge-devices-destination-device-section {
                    width: 100%;
                    padding: 0;
                }
                .merge-devices-source-device-section {
                    padding-right: 19px;
                    border-right: solid 1px #e5e5e5;
                }
                .merge-devices-destination-device-section {
                    padding-left: 20px;
                }

                .merge-devices__search-store-form {
                    margin-bottom: 15px;
                    max-width: 100%;

                    .hme-input-search-variant {
                        .hme-input-component {
                            border-top-right-radius: 0;
                            border-bottom-right-radius: 0;
                            border-right: none;

                            &::placeholder {
                                font-size: 16px;
                            }
                        }
                    }
                
                    .hme-submit-button-component {
                        display: block;
                        width: 104px;
                        height: 48px;
                        border: 1px;
                        margin: 0;
                        border-radius: 0 24px 24px 0;
                    }
                }

                .hme-result-count {
                    display: none;
                }
    
                .merge-devices__store-devices-results {
                    margin-bottom: 25px;

                    .hme-grid{
                        display: none;
                    }

                    .hme-tile-list__wrapper {
                        padding: 0;

                        .hme-tile-list__row {
                            .hme-tile-list__row__value {
                                white-space: normal;
                                word-break: break-all;
                            }
                        }
                    }
                }
    
                .hme-auto-complete-suggestions {
                    min-width: 100%;
                    max-height: 480px;
    
                    .hme-grid{
                        display: none;
                    }

                    .hme-tile-list {
                        padding: 0;
                    }

                    .hme-tile-list__wrapper {
                        padding: 0;
                    }
    
                    .hme-tile-list-item__rows__wrapper {
                        flex-direction: row;
    
                        .hme-tile-list-item__rows__column {
                            &:first-child {
                                .hme-tile-list__row__name,
                                .hme-tile-list__row__value {
                                    position: relative;
                                    padding-left: 0;
                                    white-space: normal;

                                    &::before {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
    
                    .hme-tile-list-item__wrapper {
                        padding: 0 15px 15px;
                        border-radius: 0;
                        box-shadow: none;
                        background-image: none;
                        margin: 0 0 10px;
                        border-bottom: 1px solid #e5e5e5;
    
                        .hme-tile-list__row {
                            flex-direction: column;
                            margin: 0 8px 9px 0;
    
                            .hme-tile-list__row__name {
                                color: #5c6670;
                                font-size: 14px;
                                line-height: 1.29;
                                margin: 0 0 6px;
                            }
                            .hme-tile-list__row__value {
                                color: #000819;
                                font-size: 16px;
                                line-height: 1.13;
                                white-space: normal;
                                word-break: break-all;
                            }

                            .hme-tile-list__row__name,
                            .hme-tile-list__row__value {
                                padding-left: 15px;
                                position: relative;

                                &::before {
                                    content: '|';
                                    display: block;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                }
                            }
                        }

                        &.hme-tile-item-disabled {
                            cursor: default;
                    
                            .hme-tile-list-item__rows__column {
                                .hme-tile-list__row {
                                    .hme-tile-list__row__name,
                                    .hme-tile-list__row__value {
                                        color: #a7adba;
                                    }
                                }
                            }        
                        }
                    }
                }

                .hme-info-box {
                    .hme-info-box-message {
                        position: relative;
                        padding-left: 27px;

                        .icon {
                            position: absolute;
                            top: 0;
                            left: 0;
                            margin-top: 2px;
                        }
                    }
                }
            }

            .merge-devices-footer {
                text-align: center;
                padding: 20px 0;
    
                .hme-button-component {
                    margin-left: 0;
                }
            }
        }
    }
}
