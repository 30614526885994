.accounts-page {
    .accounts-list {
        display: flex;
        flex-direction: column;
        overflow: auto;
        height: 100%;
        min-height: 530px;
    }

    @media screen and (max-height: 960px) {
        .accounts-list {
            min-height: 330px;
        }

        .hme-grid-filter-cell-popup {
            .hme-grid-filter-cell-popup-body {
                max-height: 150px;
            }
        }
    }
}
