.users .forms h3 {
  font-size: 35px;
  margin-bottom: 17px;
}
.users span.additem {
  background-color: #D41B3E;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 13px;
  padding: 10px 20px;
  cursor: pointer;
}

.users .ctable .theader, .systems .ctable .cheader {
  border: 1px solid #b9b9b9;
  color: #757575;
}

.users .forms {
  margin-bottom: 25px;
  margin-top: 47px;
}

.users .ctable {
  margin: 0 auto;
}

.users .settings_search {
  padding: 0;
}

.users tr.theader th {
  font-size: 16px;
  cursor: pointer;
}

.users .ctable th {
  padding: 10px;
}

.users tr.theader th a {
  font-size: 16px !important;
}

.users td.cdet {
  /* float: left; */
  margin-left: 4px;
  text-align: center;
}

.users .ctable td {
  padding: 10px;
  vertical-align: top;
}

.users .clear:after {
  clear: both;
  content: ".";
  display: block;
  font-size: 0;
  height: 0;
  visibility: hidden;
}
.users .tdata, .users .cdata {
  background-color: #ffffff;
  border-bottom: 1px solid #b9b9b9;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  color: #000000;
}
.users .tdata:hover {
  background-color: #c4e9f1;
  border-bottom: 1px solid #709da2;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
}

.users .whiteplus {
  padding-right: 5px;
}

.users .usersSearch {
  display: flex;
  justify-content: flex-end;
  margin-top:4px;
  width: 100%;
}

.users .addUserButton {
  min-width: 200px;
  margin-bottom: 15px;
}

.users .view_details {
  align-items: center;
  text-align: center;
  font-size: 14px !important;
}


.users .NoRecordsFound {
  text-align: center;
  font-size: 16px;
}

.systemsHeader.usersSection{
  margin-bottom:5px;
  word-break: keep-all;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

.activityLog{
 font-weight: bold;
 display: flex;
 justify-content: center;
 padding: 5px 0px;
 font-size: 14px
}
.userStatus{
  display: flex;
  align-items: center
}
.activityHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
img.leaderboardView {
  height: 80%;
  width: 80%;
}

@media screen and (max-width: 899px) {
  .users .theader.clear span {
    font-size: 11px;
  }
  .users .ctable tr.tdata td {
    font-size: 10px;
  }
  .users .ctable tr.tdata td .tdBefore a span{
    font-size: 11px;
  }
  .users a.view_details span {
    font-size: 11px;
  }
}
@media screen and (min-width: 900px) {
  .users .theader.clear span {
      font-size: 16px;
  }
  .users .ctable tr.tdata td {
      font-size: 14px;
  }
  .users a.view_details span {
      font-size: 14px;
  }
}