.admin-create-apply-store-settings-task-devices {
    display: flex;
    flex-direction: column;

    .admin-create-apply-store-settings-task-devices-header {
        display: flex;
        flex: none;

        .hme-input-search-variant {
            width: 283px;
            margin-left: auto;
        }

        .admin-create-apply-store-settings-task-filters-count {
            padding-left: 22px;
            padding-right: 20px;

            border-left: solid 1px #e5e5e5;
            border-right: solid 1px #e5e5e5;

            font-size: 30px;

            .hme-grid-filters-count-label {
                font-size: 24px;
            }
        }
    }

    .admin-create-apply-store-settings-task-devices-count {
        font-size: 30px;
        font-weight: bold;
        letter-spacing: 0.03px;
        color: #606672;
        margin-left: 23px;
        margin-right: 23px;

        &.admin-create-apply-store-settings-task-total-devices-count {
            color: #606672;

            .admin-create-apply-store-settings-task-devices-count-label {
                color: #000819;
            }
        }

        &.admin-create-apply-store-settings-task-selected-devices-count {
            color: #60adff;

            .admin-create-apply-store-settings-task-devices-count-label {
                color: #000819;
            }

            &.admin-create-apply-store-settings-task-no-selected-devices-count {
                color: #a7adba;

                .admin-create-apply-store-settings-task-devices-count-label {
                    color: #a7adba;
                }
            }
        }

        .admin-create-apply-store-settings-task-devices-count-label {
            font-family: Assistant;
            font-size: 24px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: 0.02px;
            text-align: left;
        }
    }

    .hme-grid {
        flex: 1;
        overflow: auto;
        margin-top: 10px;
    }

    .admin-create-apply-store-settings-task-timezone-cell {
        .hme-grid-filter-cell-popup {
            left: unset;
            right: 0;
        }
    }

    .admin-create-apply-store-settings-task-state-cell {
        .hme-grid-filter-cell-popup {
            left: unset;
            right: 0;
        }
    }
}
