.hme-api-management-provider-form {
    .hme-grid-header {
        position: static;
    }

    .hme-grid .hme-grid-header-row.hme-grid-row .hme-grid-cell-checkbox {
        display: none;
    }

    .hme-provider-name-cell {
        width: 250px;
    }
}
