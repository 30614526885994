.hme-toggle.hme-toggle--inline-label {
    display: flex;
    align-items: center;

    .hme-label {
        width: auto;
        margin-bottom: 0;
        margin-right: 20px;
    }
}
