@import url("../../base.css");

.Add {
    width: 24px;
    height: 24px;
    object-fit: contain;
}

.Swatch {
    width: 4px;
    height: 18px;
    background-color: var(--blue-100);
}

.tile-box {
    font-family: Assistant, Arial, Helvetica, sans-serif;
    background-color: var(--white);
    box-shadow: 0 0 10px 0 rgba(0, 8, 25, 0.3);
    margin: 5px;
    padding: 0 5px;
}

.tile-box h3 {
    font-size: 24px;
}

.quicklink {
    color: var(--blue-87);
    display: block;
    font-family: Assistant;
    font-weight: normal;
    font-size: 16px;
    font-style: normal;
    font-stretch: normal;
    height: 24px;
    line-height: 1.5;
    letter-spacing: normal;
    margin: 0 0 2px 5px;
}

.quicklink:hover {
    color: var(--blue-87);
}

.tile-box-header {
    display: flex;
}

.tile-box-header-button {
    border: none;
    padding: 0;
    color: #3B82F3;
    cursor: pointer;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.tile-box-header-title {
    color: var(--black);
    margin: 20px 24px 20px 8px;
}

.tile-box-header-button:active,
.tile-box-header-button:hover,
.tile-box-header-button:focus,
.tile-box-header-button:active:hover {
    color: #3B82F3;
    background-color: #fff;
    border-color: #fff;
    box-shadow: none;
    outline: none;
}

.tile__box-supporting-container {
    padding: 16px 20px;
}

.tile__box-modal {
    width: 100%;
}

.tile__box-modal.hme-modal-component .modal-header .hme-title {
    font-size: 20px;
    font-weight: 600;
}

.tile__title{
    font-weight: 700;
    margin: 0 0 16px;
    word-break: initial;
    white-space: nowrap;
}
.tile__training{
    font-weight: 700;
    margin: 0 0 16px;
    word-break: initial;
    white-space: nowrap;
    text-align: center;
}

.tile__paragraph,
.tile-box.announcement p,
.tile-box.announcement ul,
.tile-box.announcement ol {
    font-weight: 300;
    line-height: 1.33;
    font-size: 16px;
}

.tile-box.announcement p,
.tile__paragraph {
    margin: 0;
    padding: 0 0 10px 0;
}

.tile-box.announcement ul,
.tile-box.announcement ol {
    margin-left: 0;
}

.tile-box.announcement b {
    color: #0D6EFD;
}

.tile-box-footer-button-left {
    border-width: 0.5px;
    margin: 16px 8px 24px 14px;
    outline: 0;
}

.tile-box-footer-icon {
    margin: 12px 8px 12px 4px;
}

.tile-box-footer-button-right {
    border-width: 0.5px;
    outline: 0;
    margin: 16px 8px 24px 0px;
}

.tile-box-footer-button-right > span {
    margin-right: 8px;
}


.black {
    color: var(--black);
}

.gray-62 {
    color: var(--gray-62);
}

@media screen and (max-width: 1173px) {    
    .tile__box-modal .modal-content {
        margin-top: 0;
        padding: 30px 10px;
    }

    .tile__box-modal.hme-modal-component .modal-header {
        margin-bottom: 0;
        padding: 0 15px;

        text-align: left;
    }

    .tile__box-modal .hme-grid {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 20px 15px;
    }

    .tile__box-modal .hme-grid-header {
        display: none;
    }

    .tile__box-modal .hme-grid-row {
        flex-direction: column;
        gap: 15px;
        padding: 15px;

        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
        border-radius: 0;
    }
    
    .tile__box-modal .hme-grid-row .hme-grid-row-hovered {
        border-radius: 0;
    }

    .tile__box-modal .hme-grid-cell {
        padding: 0;

        font-size: 14px;
    }

    .tile__box-modal .hme-grid-cell .hme-grid-cell-content {
        padding: 0;
    }
}
