@import '../../../../mixins/breakpoints.scss';

@include before-desktop-only {
    .hme-manage-hierarchy__hierarchy {
        &__sub-header {
            flex-wrap: wrap;
            gap: 10px;

            .hme-input-container {
                margin-right: 0;
                margin-top: 5px;
                width: 100%;
                order: 6;
            }

            .hme-button-component {
                width: auto;
                margin-left: 0;
                flex: 1;
            }
        }

        &__tree-input__wrapper {
            height: max-content;
        }

        .hme-tree {
            &--bordered {
                max-height: unset;
                border: none;
            }
        }
    }
}
