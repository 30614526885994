.hme-page-component.manage-blocked-emails-page {
  & > .search-block {
    width: 550px;
    padding: 38px 0 0 18px;
    & > .hme-label {
      display: block;
    }
    & > .hme-input-container {
      width: 317px;
      margin: 8px 20px 0 0;
    }
  }
  & > .results-block {
    width: 1063px;
    padding: 38px 0 0 18px;
    & > .hme-label {
      display: block;
      margin: 0 0 20px 0;
    }
    & > .hme-grid {
      & > .hme-grid-row {
        & > .hme-grid-cell:last-child {
          padding: 10px 0;
        }
      }
    }
    & > .not-found-message {
      font-family: Assistant;
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.31;
      letter-spacing: 1.28px;
      text-align: left;
      color: #000819;
    }
  }   
}
.manage-blocked-emails-page-modal {
  width: 405px;
  height: 342px;
  .hme-center-message-body > h2 {
    width: 210px;
    margin: 20px auto;
  }
  & > .modal-content {
    height: 342px;
    & > .modal-header {
      display: none;
    }
    & > .modal-body {
      overflow: hidden;
      padding: 40px 0 0 0;
      & > .hme-center-message {
        min-height: unset;
        & > .hme-center-message-body {
          top: unset;
        }
      }
    }
  }
}