@import 'src/mixins/breakpoints.scss';

@include before-desktop-only {
    .hme-stores-status--public {
        width: 100%;
        min-width: unset;
        padding: 0;
        overflow: auto;
        display: block;

        .hme-stores-status--public__header {
            padding: 15px 15px 0 15px;
            margin: 0;

          .hme-title {
            display: flex;
            align-items: baseline;
            justify-content: flex-start;
            font-family: Assistant, sans-serif;
            font-size: 20px;
            font-weight: 600;
            line-height: 1.3;
            letter-spacing: -0.28px;
            color: #000819;
            margin-bottom: 15px;
          }

          .hme-stores-status--public__header__manage-hierarchy {
            .hme-button-component {
              font-size: 14px;
              line-height: 1.29;
              color: #0074ef;
            }
          }
        }

      .hme-stores-status--public__grid {
        display: none;
        min-width: unset;
      }

      .hme-stores-status--public__tile-list {
        display: flex;
        flex-wrap: wrap;
        padding: 0 15px 14px 15px;
        overflow-x: hidden;
        overflow-y: auto;

        .hme-tile {
          align-items: flex-start;
          margin: 7.5px 0;
          padding: 15px;
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
          background-image: linear-gradient(to bottom, #fff, #fff 52%, #f2f2f2);
          position: relative;
        }

        .hme-stores-mobile--public__tile__main__info {
          font-family: Assistant, sans-serif;
          display: flex;
          flex-direction: column;
          flex: 1 1 100%;

          .hme-stores-mobile--public__store__info__item {
            display: flex;
          }

          .hme-stores-mobile--public__store__underline__block {
            border-bottom: solid 1px #e5e5e5;
            margin-bottom: 7px;
          }

          .hme-stores-mobile--public__tile__title {
            font-size: 16px;
            font-weight: 600;
            line-height: 1.5;
            letter-spacing: 0.02px;
            color: #606672;
            margin-bottom: 15px;
            text-transform: uppercase;
          }

          .hme-stores-mobile--public__tile__sub__title {
            min-width: 125px;
            font-size: 12px;
            line-height: 2;
            letter-spacing: 1.08px;
            color: #606672;
            margin-bottom: 8px;
            text-transform: uppercase;
          }
          .hme-stores-mobile--public__tile__sub__value {
            display: flex;
            flex: 0 0 auto;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            font-size: 14px;
            line-height: 1.71;
            letter-spacing: 0.27px;
            color: #000819;

           .hme-device-status {
             span {
               font-size: 14px;
               text-align: left;
             }
            }
          }

          .hme-stores-mobile--public__details__button {
            .hme-stores-mobile--public__expand__icon {
              width: 16px;
              height: 16px;

              &.up {
                transform: rotate(180deg);
              }
            }
          }

          .hme-stores-mobile--public__details__button__block {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-top: 8px;
          }
        }

        .hme-stores-mobile--public__edit__store__link {
          font-family: Assistant, sans-serif;
          min-width: 76px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 14px;
          line-height: 1.29;
          color: #0074ef;
          position: absolute;
          top: 15px;
          right: 15px;
        }
      }
    }

  .modal-content {
    margin-top: 20%;

    #store-tabs {
      width: 87.5%;

      .store-details-save {
        margin-left: 0;
        display: flex!important;
        align-items: center;
        justify-content: center;
      }
    }

    .merger-popup-close {
      right: 35px;
    }

    .user_form {
      label {
        width: auto!important;
        display: flex;
        justify-content: flex-end;
      }

      input {
        width: auto;
      }
    }
  }

    .hme-add-store-modal-dialog {
        &.modal-dialog {
            max-width: 700px;
            min-width: 400px;
            width: 100%;

            .modal-body {
                overflow: auto;
            }
        }

        .add-store-form {
            .hme-add-store__form {
                display: flex;
                justify-content: space-between;
                width: 100%;
            }
    
            &__section {
                width: 48%;   
            }
    
            &__box {
                margin: 0 0 20px;
            }
        }
    }
}

@include mobile-only {
    .hme-add-store-modal-dialog {
        &.modal-dialog {
            min-width: 350px;
            width: auto;

            .hme-add-store-modal {
                padding: 0 20px;
            }

            .modal-header {
                padding: 25px 40px 0 20px;
            }

            .modal-content {
                padding-bottom: 0;
            }

            .modal-footer {
                flex-direction: column;
                padding: 20px;
    
                box-shadow: 0 -10px 20px 0 rgba(0, 0, 0, 0.16);
                z-index: 100;
    
                .hme-button-component {
                    order: 1;
                    margin: 0;
                    width: 100%;
                }
    
                .hme-submit-button-component {
                    &.hme-button-component {
                        order: 0;
                        margin-bottom: 10px;
                    }
                }
            }
        }

        .add-store-form {
            .hme-add-store__form {
                flex-direction: column;
                justify-content: flex-start;
                width: 100%;
            }

            &__section {
                width: 100%;
            }

            &__box {
                margin: 0 0 20px;
            }
        }
    }
}