.create-apply-store-settings-task {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    &.hme-page-component {
        padding-top: 27px;
    }

    .admin-create-apply-store-settings-task-header {
        margin-bottom: 40px;
    }

    .create-apply-store-settings-task-sections {
        display: flex;
        flex: 1 1 100%;
        height: 100%;
        min-height: 560px;
        overflow-y: hidden;
    }

    .admin-create-apply-store-settings-task-search {
        flex: none;
        width: 370px;
        padding-right: 30px;
    }

    .admin-create-apply-store-settings-task-devices {
        flex: 1;
        min-width: 1205px;
        height: 100%;
    }
}
