@import 'src/mixins/breakpoints.scss';

@include before-desktop-only {
    .hme-new-user {
        &__content {
            margin: 25px 0;
        }

        &__form {
            .hme-reset-password {
                &__info {
                    margin-right: 0;
                    margin-bottom: 0;
                }
            }
        }

        &__checkbox {
            margin-left: 0;
            margin-top: 0;
            margin-bottom: 20px;
        }

        .hme-reset-password .hme-reset-password__form &__submit-btn.hme-submit-button-component {
            display: block;
        }
    }
}
