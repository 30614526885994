@import 'src/mixins/breakpoints.scss';

@include mobile-only {
  .hme-users__header__wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .hme-users__header {
        margin-bottom: 20px;
        .hme-label {
          margin-right: 8px;
        }
      }

      .hme-button-component {
        width: 100%;
      }
    }
  }

@include before-desktop-only {
  .hme-users--distributor-account {
    padding: 0;

    .hme-users__header__wrapper {
      padding: 22px 15px;
    }
    .hme-title {
      font-size: 20px;
      font-weight: 600;
      line-height: 1.3;
      letter-spacing: -0.28px;
      color: #000819;
      margin-bottom: 11px;
    }
    .hme-tile-list {
      .hme-tile-list-item__wrapper {
        .hme-tile-list-item__actions-link {
          .hme-users-list--action--edit--user {
            display: flex;

            .hme--users--edit-icon {
              margin-right: 5px;
            }
          }

          .hme-users--distributor-account--remove--user {
            border-left: solid 1px #d1cdcd;
            padding-left: 15px;
          }

          .hme-button-component {
            margin: 0;
          }
        }
      }
    }
  }
}

@include desktop-only {
  .hme-distributors-account {
    &.hme-distributors-account--users {
      .hme--users--edit-icon {
        display: none
      }
    }
  }
};
