@import 'src/mixins/breakpoints.scss';

.hme-notifications-list {
    &.Toastify__toast-container {
        width: auto;
    }

    .hme-notification {
        &.Toastify__toast {
            width: 500px;
            height: auto;
            padding: 20px;
            font-family: Assistant;
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: 0.54px;
            text-align: left;
            color: #ffffff;

            .Toastify__close-button {
                opacity: 1;

                & > svg {
                    width: 25px;
                    height: 25px;
                    color: #ffffff;
                }
            }
        }

        &.Toastify__toast--success {
            // TODO: Please remove !important after it will be fixed in DeviceUpgrades.css file
            border-radius: 5px  !important;
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
            background: #5db431  !important;
        }

        .Toastify__toast-icon {
            display: none;
        }
    }
}

@include mobile-only {
    .Toastify {
        .Toastify__toast-container--top-center {
            max-width: 500px;
            width: 95%;
            margin: 0 2.5%;
        }
        .hme-notifications-list {
            .hme-notification {
                &.Toastify__toast {
                    width: 100%;
                }
            }
        }
    }
}
