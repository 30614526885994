.hme-info-field {
    width: 100%;

    .hme-label {
        font-size: 12px;
        line-height: 1.33;
        letter-spacing: 1.08px;
        color: #606672;
        display: block;
        margin-bottom: 1px;
    }

    .hme-info-field-content {
        font-family: Assistant;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.31;
        letter-spacing: 1.44px;
        text-align: left;
        color: #000819;
        display: block;
    }
}
