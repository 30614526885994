@import "../../assets/styles/Variables";

.LeaderBoardGroup.groupDetailsPage {
    span.rc-tree-switcher:not(.rc-tree-switcher-noop) ~ span.rc-tree-node-content-wrapper {
        font-weight: bold;
    }
    .new-groups {
        div.rc-tree-show-line {
            border: none;
        }
        div.rc-tree {
            overflow-y:unset;
        }
    }

  .history-modal-wrapper {
      position: relative;
  }
  .btn-grey {
      min-width: 12rem;
      min-height: 3rem;
      background-image: linear-gradient(to bottom, $main-white, $gradient-gray);
      font-family: Assistant;
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.71;
      letter-spacing: 0.2px;
      outline: none;
      color: $main-black;
      border-bottom-left-radius: 999px;
      border-top-left-radius: 999px;
      border-bottom-right-radius: 999px;
      border-top-right-radius: 999px;
    }
}

  .pick-me-up{
    margin-top:-4em;
  }
  .history-modal-subheader{
    font-size: 14px;
    text-align: left;
    color: $main-gray;
    padding-top: .5em;
    font-weight: 600;
    border-top: 0;
    overflow: visible;
  }
  .history-modal-header{
    font-size: 16px !important;
    color: $main-gray;
    padding-top: .8em;
    padding-bottom: .8em;
    font-weight: 600;
    border-top: 0;
    overflow: visible;
  }
  .history-modal-close-button{
    margin-left: 4rem;
    font-size: 1.4em !important;
    line-height: .5em;
    color: #5c6670;
    font-weight: 600;
    cursor: pointer;
  }
  .lbgroups-grid-wrapper{
    font-size: large !important;
    color: $main-black;
  }
  .extra-padding-on-bottom{
    margin-bottom: 12em;
  }
  .bigger-link-text{
    font-size: medium !important;
    font-weight: 500;
  }.list-view-btn-grey {
    min-width: 12rem;
    min-height: 3rem;
    background-image: linear-gradient(to bottom, $main-white, $gradient-gray);
    font-family: Assistant;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: 0.2px;
    outline: none;
    color: $main-black;
    border-bottom-left-radius: 999px;
    border-top-left-radius: 999px;
    border-bottom-right-radius: 999px;
    border-top-right-radius: 999px; }
  .customize-lb-link {
    font-size: x-large !important;
    margin-left: .4em;
    color: lightgray;
  }

  .leaderboards-grid-new {
    .customize-lb-link {
      margin-left: 30px;
    }

    .hme-leaderboard__customize-link {
      margin-left: 15px;
    }
  }

  .leaderboards-grid-new, .lbtemplates-grid-new{
    &.forms * {
      font-size: 16px;
    }
    & > .settings_plug > .hme-button {
      border-radius: 20px;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.08);
      border: solid 1px $gray-25;
      background-image: linear-gradient(to bottom, $main-white, $gradient-gray);
      &:hover {
        color: $main-black
      }
    }
    & > .lbgroups-grid-wrapper {
      & > .hme-grid {
        & > .hme-grid-header > .hme-grid-header-row > .hme-grid-cell > .hme-grid-cell-content > span {
          font-family: Assistant;
          font-size: 14px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.21;
          letter-spacing: normal;
          text-align: left;
          color: $main-gray;
        }
      }
    }
  }

  .leaderboards-new-improved {
    & > .hme-grid {
      & > .hme-grid-row > .hme-grid-cell:last-child, & > .hme-grid-header > .hme-grid-row > .hme-grid-cell:last-child {
        flex: 2 1;
        text-align: center;
        & > .hme-grid-cell-content > span {
          padding: 0;
        }
      }
      & > .hme-grid-row > .hme-grid-cell > div.hme-grid-cell-content {
        & > .actions-leaderboard {
          margin: 0 !important;
          text-align: center;
        }
        & > div > div.lbgroups-grid-wrapper {
          & > .history-modal-popup {
            scrollbar-width: none;
            overflow: unset;
            & > div.panel {
              height: max-content !important;
              width: max-content !important;
              min-width: 130px;
              max-width: 250px;
              overflow-y: hidden;
              padding: 0 0 16px 0;
              text-align: left;
              & > div {
                padding: 0 16px 0 0;
              }
              & > .history-modal-header {
                display: flex;
                flex-direction: row;
                justify-items: flex-start;
                padding: 16px 16px 16px 0;
                & > span:last-child {
                  margin: 0 0 0 auto;
                }
              }
            }
          }
        }
      }

    }
  }
.lbtemplates-new-improved {
    & > .hme-grid {
      & > .hme-grid-row > .hme-grid-cell, & > .hme-grid-header > .hme-grid-row > .hme-grid-cell {
        text-align: center;
        &:last-child, &:nth-child(4) {
          flex: 1.5 1;
        }
        &:nth-child(1), &:nth-child(3), &:nth-child(4) {
          text-align: left;
        }
        & > div.hme-grid-cell-content > .lbactions-textAlign {
          padding: 0;
        }
      }
      & > .hme-grid-row > .hme-grid-cell > div.hme-grid-cell-content {
        & > .actions-leaderboard {
          margin: 0 !important;
          text-align: center;
        }
        & > div > div.lbgroups-grid-wrapper {
          & > .history-modal-popup {
            scrollbar-width: none;
            overflow: unset;
            & > div.panel {
              height: max-content !important;
              width: max-content !important;
              min-width: 130px;
              max-width: 250px;
              overflow-y: hidden;
              padding: 0 0 16px 0;
              text-align: left;
              & > div {
                padding: 0 16px 0 0;
              }
              & > .history-modal-header {
                display: flex;
                flex-direction: row;
                justify-items: flex-start;
                padding: 16px 16px 16px 0;
                & > span:last-child {
                  margin: 0 0 0 auto;
                }
              }
            }
          }
        }
      }

    }
  }