.hme-components {
    .hme-radio-group {
        vertical-align: middle;

        .hme-label-radio-group {
            font-weight: normal;
            line-height: 1.31;
            color: #606672;
            text-transform: none;
        }

        .hme-radio-group-list {
            display: inline-block;

            .hme-radio {
                border-left: solid 1px #e5e5e5;
                padding-left: 16px;
                margin-left: 16px;
    
                &:first-child {
                    margin-left: 22px;
                    border-left: none;
                    padding-left: 0;
                }
            }
        }

        &.hme-radio-group-disabled {
            .hme-label-radio-group {
                color: #e5e5e5;
            }
        }
    }
}
