.Embed-container {
  height: 0;
  position: relative;
  overflow: hidden;
}
.Embed-container-multi {
  padding-bottom: 45%;
}
.Embed-container-self-service {
  padding-bottom: 45%;
}
.Embed-container-single {
  padding-bottom: 85%;
}
.Embed-container-trends-dashboard {
  padding-bottom: 62%;
}
.Embed-container-trends {
  padding-bottom: 110%;
}
.Embed-container-rcd {
  padding-bottom: 100%;
}
.Embed-container-overview {
  padding-bottom: 300%;
}
.Embed-container-overview iframe {
  border-width: 0 !important;
}

.Embed-container iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-width: 2px;
    border-style: inset;
    border-color: initial;
    border-image: initial;
}

.modal-layout {
  width: 70%;
  max-height: 80vh;
  min-height: 40vh;
}


.checkBox {
  margin: 7px 0;
}

.modal-body {
  overflow-x: hidden;
  padding: 2rem;
}

.report-hide {
  display: none
}

.report-show {
  display: block;
}
.custom-color-btn {
  background-color: #032b79;
  border-color: #032b79;
}

.custom-color-btn-disabled, .custom-color-btn-disabled:hover {
  background-color: #b9b4b4 !important;
  border-color: #b9b4b4 !important;
  cursor: not-allowed;
}

.top-container {
  display: flex;
  justify-content: space-between;
  padding-right: 48px;
}

.top-container__buttons {
  display: flex;
  align-items: center;
  margin: 30px 0 20px;
}

.template-actions {
  display: flex;
  justify-content: space-between;
  width: 210px;
}

.modal-save-template {
  padding-top: 5px;
  width: 400px;
  max-height: 280px;
  overflow-y: scroll;
}

.modal-save-template-buttons {
  display: flex;
  justify-content: end;
  padding: 10px 0 2px;
}

.modal-save-template-buttons > * {
  margin-left: 15px;
}

.report-apply-template-header {
  display: flex;
  justify-content: space-between;
  margin: 0 10px;
}

.report-template-list {
  margin-right: 25px;
  margin-left: 5px;
  margin-bottom: 10px;
}


.report-template-item {
  display: flex;
  justify-content: space-between;
  color: blue;
  min-height: 40px;
  align-items: center;
  background-color: rgb(238, 238, 238);
  padding: 0 20px 0 10px;
}

.report-template-item:nth-child(even){
  background-color: white;
}

.report-template-name {
  cursor: pointer;
  padding-right: 5px;
}
.report-template-invalid-name {
  color:rgb(255, 0, 0)
}

.report_template-warning_container {
  position: relative;
  display: inline-block;
}

.report_template-warning_message {
  position: absolute;
  bottom: 100%;
  padding: 10px;
  background-color: #b9b4b4;
  color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 100;
  transform: translateY(93px);
}

.hme-layout .hme-page .pbi-report-screen {
  position: relative;
  overflow-y: auto;
}

.pbi-report-screen__owner-email-wrapper {
  margin: 0px 100px;
}

.reportsPage {
  width: 100%;
  padding: 0 48px;
}
