.hme-manage-hierarchy__hierarchy {
    &__sub-header {
        width: 100%;
        display: flex;

        .hme-input-container {
            margin-right: 20px;
        }

        .hme-button-component {
            width: max-content;
            white-space: nowrap;
            height: 40px;
            padding-top: 8px;
            margin-top: auto;
            margin-left: 20px;
        }
    }

    &__tree-label {
        width: max-content;
        white-space: nowrap;
        margin-top: auto;
    }

    &__tree {
        &-input {
            &__wrapper {
                height: 530px;
                padding-top: 20px;
                padding-right: 7px;
                
                border-radius: 24px;
                border: solid 1px #a7adba;
            }
        }
    }

    .hme-tree {
        &--bordered {
            padding-top: 5px;
            max-height: 485px;
    
            border: none;
        }
    }
}

@import './Tablet.scss';
@import './Mobile.scss';
