.device-settings {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 25px;
    overflow: hidden;
}

.hme-modal-device-settings__loading {
    .hme-create-snapshot-modal-dialog {
        .modal-header {
            .btn-close {
                display: none;
            }
        }
    }
}
