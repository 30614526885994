@import 'src/assets/styles/Variables.scss';

.hme-notifications-center__list {
    display: flex;
    flex-direction: column;
    gap: 17px;

    &__empty {
        font-family: $font-family;
        font-size: 16px;
        color: $main-black;
        width: 100%;
        max-width: 1024px;
    }
}