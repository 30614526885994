@import 'mixins/breakpoints.scss';

.hme-hamburger {
    padding: 0 17px 0 14px;

    .hme-hamburger__icon--closed {
        .hme-hamburger__icon__line {
            width: 16px;
            height: 2px;
            background-color: #7e7e7e;
            margin-bottom: 3px;
        }
    }

    .hme-hamburger__icon--open {
        width: 16px;
        height: 16px;
        color: #186df4;
        margin-top: -4px;
    }
}

@include tablet-only {
    .hme-hamburger {
        padding: 0 20px;
    }
}
