.select-snapshot-taskbar {
    padding: 0 0 23px 0;
    display: flex;
    line-height: 48px;
    vertical-align: middle;

    .hme-title {
        font-size: 22px;
        display: inline-block;
        flex: none;
        line-height: 1.3;
    }

    .select-snapshot-taskbar-link {
        text-align: right;
        flex: none;
        display: flex;
        justify-content: flex-end;

        .hme-button-component {
            flex: none;
            height: 40px;
        }
    }

    .select-snapshot-taskbar-filters-count {
        flex: 1;
        padding-left: 30px;
        padding-right: 30px;

        &.select-snapshot-taskbar-separated {
            border-right: none;
        }
    }

    .hme-grid-filters-count-content {
        display: flex;

        .hme-grid-filters-count-label {
            margin-right: 20px;
        }

        .hme-button-component {
            line-height: 1.33;
            margin-top: 4px;
        }
    }
}
