@import '../../../assets/styles/Variables';
@import '../../../assets/styles/Responsive';

.hme-multi-select {
    &__select-dropdown {
        background-color: #ffffff;
        padding: 15px 40px;
        border-radius: 8px;
        min-width: 272px;

        left: 0;
        top: 100%;
        position: absolute;
        max-height: 200px;
        overflow-y: scroll;
        width: 100%;
        margin: 3px 0;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
        z-index: 100;

        &--with-select-all {
            padding: 15px 20px;

            .hme-multi-select__select-dropdown__items {
                padding-left: 20px;
            }
        }

        .hme-checkbox-component {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            overflow: hidden;

            margin: 0 auto 12px;
            white-space: nowrap;

            .hme-checkbox-label {
                font-size: 16px;
                line-height: 1.31;
                letter-spacing: 1.28px;
                display: inline-block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            &-disabled {
                opacity: 0.5;
            }

            .hme-checkbox-component-icon {
                flex-shrink: 0;
            }
        }

        .hme-components {
            .hme-auto-complete-suggestions-loader {
                text-align: center;
                padding-top: 10px;
                padding-bottom: 10px;

                .hme-loader-icon {
                    display: inline-block;
                    width: 21px;
                    height: 21px;
                    border-width: 4px;
                }

                .hme-label {
                    display: inline-block;
                    margin: -14px 0 0 10px;
                    font-family: Assistant;
                    font-size: 16px;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 21px;
                    letter-spacing: 1.28px;
                    color: #000819;
                    text-transform: none;
                    vertical-align: middle;
                }
            }
        }
    }
}
