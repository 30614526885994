.hme-view-user__general {
    .input-container__with-error {
        margin-bottom: 20px;

        .hme-input-container {
            margin: 0;
        }
    }

    .hme-input-container {
        width: 345px;
        margin-bottom: 20px;
    }

    .hme-toggle {
        width: 345px;
        margin-bottom: 20px;
    }

    .hme-toggle-container{
        .hme-label {
            margin-bottom: 4px;
        }
    }
}

@import './Mobile.scss';
