.hme-status-icon {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    display: inline-block;

    margin-bottom: -2px;
    margin-left: 7px;

    &.hme-status-icon--online {
        background-color: #00b107;
    }

    &.hme-status-icon--offline {
        background-color: #ff0000;
    }
}