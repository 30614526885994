.modalHeader {
    background-color: #87B6DB;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    border-radius: 2px;
}

h4 a:not([href]) {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

h4 a:not([href]):hover {
    text-decoration: underline;
}

.ReactModal__Content
{
    padding: 2px !important;
    /* width: 255px !important; */
    height: 139px;
}

.continueButton {
    width: 155px;
    height: 39px;
    font-size: 11px;
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: #E9F5FD !important;
    color: #1b96c8;
    border-radius: 8px;
    box-shadow: 2px 2px 2px -2px black;
    border: none;
}
.autoSignOutContent {
    height: 50px;
}

.ReactModal__Overlay {
    background-color: rgba(18, 14, 14, 0.75) !important;
}

.contactText{
    font-size: 16px
}
.operatingInstruction{
    padding-top: 15px;
}

/* pagination css starts */
#react-paginate ul {
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
}

.form-control {
    font-size: 14px;
    padding: 6px 12px;
    color: #555!important;
}

#react-paginate li {
    display: inline-block;
}

#react-paginate .break a {
    cursor: default;
}
.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover,
 .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover{

    background-color:#fff;
    color:#000;
    border-color:#000 !important;
    
}

.paginationSection{
    margin-top: 20px;
    width: 100%;
}
.pagination {  
    font-size: 10px;
    display: flex;
    justify-content: center;
    margin: 0 !important
}
.pagination>li>a, .pagination>li>span{
    position: relative;
    float: left;
    padding: 3px 4px;
    margin-left: 5px;
    line-height: 1.42857143;
    color:#D41B3E;
    text-decoration: none;
      border: 1px solid #ddd;
}
.pagination>li>a:hover{
    background-color: #D41B3E;
    border-color: #D41B3E;
    color:#fff;
}
.pagination .break-me a, .pagination .break-me a:hover{
   border-top:none !important;
   border-bottom:none !important;
   cursor:disabled;
   color:#D41B3E;
   background-color:#fff
}
.pagination>.disabled>a {
    color: #000;
}
.paginationSection select#per_show {
 cursor: pointer;
 background-color:#ebebeb;
 padding: 0px;
 font-size: 10px;
 margin:5px;
}

.dropDown_show{
font-size: 10px;
display: flex;
align-items: center;
}
.results{
     font-size: 10px;
    float: right;
    }

    /* pagination css over */


.paginationSection .showing{
    padding: 8px 0px
}
.paginationSection .pageNumbers{
    padding: 6px 0px
}

.cogWheelSection .dropdown-menu{
    top: 12px !important;
    right: 12px !important;
}

.logoutSectionIcons{
    display: flex; 
    align-items: center;
}

.subHeaderSection{
    padding: 0px 0px 15px 0px
  }
.mainMenu {
    padding: 0px 0px 15px 0px
  } 

.rightAlign{
  display: flex;
  justify-content: flex-end;
}

.leftAlign{
    display: flex;
    justify-content: flex-start;
  }
  
.centerAlign{
    display: flex;
    justify-content:center;
}

.helpSection{
    padding:0px !important;
    background: #fff
}

.checkboxSection{
 display: flex;
 align-items: center;
}
.checkboxText{
    padding-left: 5px
}
.leaderBoardGroupName{
    cursor: pointer;
    color: #D41B3E;
}
.leaderBoardGroupName:hover{
 text-decoration: underline
}
.checkboxPool{
    padding-left: 5px
}

.redSpan {
 color: red
}

@media only screen and (max-width: 992px) {
    .paginationSection .dropDown_show{
        font-size: 10px;
        display: flex;
        align-items: center;
        display: flex;
        justify-content: center;
        }
    
    .paginationSection .showing{
        padding: 8px 0px;
        display: flex;
        justify-content: center;    
    }
    .paginationSection .pageNumbers{
        padding: 6px 0px;
        display: flex;
        justify-content: center;
    }
    .user-info-section {
        flex-direction: column-reverse
    }
    .responsiveHeader{
     display: flex;
     flex-direction: column 
    }

    .homePageImages {
        width: 100% !important;
    }

    .subHeaders{
     display: flex;
     flex-direction: column;
    }

    .logoutSectionIcons {
        display: -ms-flexbox;
        display: flex;
       -ms-flex-align: center;
        align-items: center;
        width: 100%;
        justify-content: flex-end;
    }

    .move-group-store{
        display: flex;
        -ms-flex-pack: center;
        justify-content: center;
        top: 0px;
        padding: 20px 0px 0px 0px;
        flex-direction: column;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
    }
    
    .save-group-btn{
        padding: 7px 17px !important;
    }
    .reportgroup-delete{
        padding: 7px 17px !important;
    }
    .group-btns {
        align-items: center;
        justify-content: flex-start;
        flex-direction: row-reverse;
    }

    .trainings {
        flex-direction: column !important;
        justify-content: center;
    }

    .sectionDescription{
        padding: 0px 0px 20px 0px !important 
    }
 
    .wel_teaser {
        font-size: 18px !important;
       }

       .welcome{
        padding:0px !important
    }
    .trainings {
        flex-direction: column;
        align-items: center }
  
}

@media only screen and (max-width: 1400px) {
    .searchImage {
        margin-left: 0px !important;
        height: 15px;
    }
    .homePageImages {
        width: 95%;
    }
}


@media only screen and (max-width: 600px) {
    .hmeBody {
        width: 90%;
    }

    #Content {
        width: 100% !important;
    }

    .distributor_search{
     display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    flex-direction: column;
    }

    .adminImage {
        width: 100%;
    }

    .homePageImages {
        width: 90% !important;
    }

    .searchImage {
       margin-left: -10px !important;
       height: 15px;
    }

    .trainings .trainingImage {
        margin:10px 0px !important;
    }
     .trainings {
    flex-direction: column;
    align-items: center }

.trainings a {
    font-size: 12px !important;
}
.trainings span{
    font-size: 10px !important;
}

   .wel_teaser {
    font-size: 16px !important;
    font-weight: bold;
   }
   .sectionDescription p {
    font-size: 14px !important;
   }
   .sectionDescription{
    padding: 0px 0px 20px 0px !important 
   }
   .welcome{
    padding:0px !important
   }

   .search_pos.systemsSearch{
     margin-top : 10px;
   }
   .systemsHeader{
       flex-direction:column
   }
}

@media screen and (max-width: 600px) {
    .trainings{
        width: 70%;
    }
    .trainingImage img {
        width: 320px;
        margin: 0px ;
     }
     .sectionDescription p {
        width: 100%;
       }
       .welcomeLogos {
        display: flex;
        flex-direction:column;
        align-items:center
       }
}

@media screen and (max-width: 992px) and (min-width: 600px) {
    .trainings{
        width: 70%;
    }
    .trainingImage img {
        width: 430px;
        margin: 10px 0px;
    }
    .sectionDescription p {
        width: 100%;
       }
       .welcomeLogos {
        display: flex;
        flex-direction:column;
        align-items:center
       }
       .col-lg-3-5{
        width: 100%;
        float:left
    }
  }

  @media screen and (max-width: 1200px) and (min-width: 992px) {
    .trainings{
        width: 90%;
    }
    .trainingContent{
        width: 100%
     }
     .trainingImage img {
        width: 500px;
        margin-top: -36px;
        margin-bottom: -36px;
    }
    .sectionDescription p {
        width: 266px ;
       }
       .welcomeLogos {
        display: flex;
        flex-direction:column;
        align-items:center
       }
       .col-lg-3-5{
        width: 29.16%;
        float:left
    }
}


@media screen and (max-width: 1400px) and (min-width: 1200px) {
    .trainings{
        width: 78%;
    }
    .trainingContent{
        width: 100%
     }
     .trainingImage img {
        width: 500px;
        margin-top: -36px;
        margin-bottom: -36px;
    }
    .sectionDescription p {
        width: 270px;
    }
    .welcomeLogos {
        display: flex;
        flex-direction:column;
        align-items:flex-start
       }
       .col-lg-3-5{
        width: 29.16%;
        float:left
    }
}


@media screen and (min-width: 1400px) {
    .trainings{
        width: 70%;
    }
    .trainingContent{
     width: 100%
    }
    .trainingImage img{
        width: 530px;
        margin-top: -38px;
        margin-bottom: -44px;
    }
    .sectionDescription p {
        width: 310px;
    }
    .welcomeLogos {
        display: flex;
        flex-direction:column;
        align-items:flex-start
       }
       .col-lg-3-5{
        width: 29.16%;
        float:left
    }

}



