.hme-error-message {
    width: 100%;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #cc0738;
    color: #ffffff;
    display: flex;

    .hme-error-icon {
        width: 22px;
        height: 22px;
        flex: none;
        margin-top: 4px;
    }

    .hme-error-message-info {
        margin-left: 10px;
        flex: 1;
        font-family: Assistant;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: 0.54px;
        text-align: left;
    }
}