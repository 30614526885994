@import '../../../mixins/breakpoints.scss';

.hme-title {
    &-section {
        font-family: Assistant;
        font-size: 26px;
        line-height: 0.92;
        letter-spacing: -0.36px;
        color: #000819;

        @include before-desktop-only {
            & {
                mix-blend-mode: darken;
                font-size: 18px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.33;
                letter-spacing: -0.25px;
                color: #000819;
            }
        }
    }
}
