@import 'src/mixins/breakpoints.scss';

@include mobile-only {
    .modal-dialog.template-preview-box {
        .modal-header {
            padding-right: 15px;
            display: flex;
            align-items: baseline;

            .hme-title {
                flex: 1;
                text-align: center;
                word-break: break-word;

            }

            .btn-close {
                margin-right: 0;
            }
        }
    }
}
