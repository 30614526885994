@import "../../../../assets/styles/Variables";
@import "../../../../assets/styles/Responsive";

.custom-smacktalk-page-history {
    &.progress-cursor {
        cursor: progress;
    }
    & > .hme-title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 0 25px 0;
        user-select: none;

        & > span {
            padding: 0 20px 0 0;
        }
        & > .hme-button-component {
            margin: 0 0 0 auto;
        }
    }
    .hme-history-container-s {
        display: none;
    }
    & > .hme-history-container {
        height: calc(100vh - 420px);
        overflow: scroll;

        & > .hme-messages-landing-header {
            margin: 0 0 30px 0;
        }
        
        & > .hme-grid {
            & > .hme-grid-header > .hme-grid-row {
                & > .hme-grid-cell {
                    text-align: left;
                    & > .hme-grid-cell-content > span {
                        display: flex;
                        justify-content: flex-start;
                    }
                }
            }
            & > .hme-grid-row {
                & > .hme-grid-cell {
                    text-align: left;
                    display: flex;
                    align-items: center;
                    &:first-child {
                        display: flex;
                        justify-content: center;
                        & > .hme-grid-cell-content {
                            display: flex;
                            justify-content: space-between;
                            & > .hme-history-category {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                & > img {
                                    width: 50px;
                                    height: 50px;
                                    border: solid 2px $main-color;
                                    border-radius: 50%;
                                    object-fit: contain;
                                }
                            }
                        }
                    }
                    &.ellipsis-cell > .hme-grid-cell-content {
                        font-weight: 800;
                        color: #186df4;
                        cursor: default;
                        & > .hme-history-receivers > .history-to-column {
                            font-weight: normal;
                            color: $main-black;
                        }
                    }
                    & > .hme-grid-cell-content {
                        & > .hme-history-receivers > .history-to-column {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }
        }
    }
}

@include smaller($l-desktop) {
    .custom-smacktalk-page-history {
        & > .hme-title {
            display: none;
        }
        & > .hme-history-container {
            display: none;
        }
        .hme-history-container-s {
            display: flex;
            flex-flow: row wrap;
            & > .hme-tiles-container {
                & > .hme-tile {
                    & > .hme-history-tile-inner {
                        & > .history-data {
                            & > .history-list > div > dd > .hme-history-receivers {
                                white-space: unset;
                                overflow: unset;
                                text-overflow: unset;
                            }
                        }
                    }
                }
            }
        }
    }
}