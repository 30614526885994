.systems-page {
  height: 100%;
  overflow-y: auto !important; }
  .systems-page.hmeBody {
    width: 100% !important; }
  .systems-page .hme-admin-system-status {
    width: 85%;
    min-height: 40px;
    margin: 0 auto; }
  .systems-page .hme-systems-wrapper {
    height: 100%;
    min-width: 1380px; }
  .systems-page .hme-systems {
    height: 100%;
    display: flex;
    flex-direction: column; }
  .systems-page .hme-systems-override-country-warning-notification.Toastify__toast--error {
    background-color: #ffce28 !important;
    color: #000819; }
    .systems-page .hme-systems-override-country-warning-notification.Toastify__toast--error .hme-notification-warning-icon-block .hme-notification-warning-icon {
      filter: brightness(0.5); }
    .systems-page .hme-systems-override-country-warning-notification.Toastify__toast--error .Toastify__close-button {
      color: #000819; }

.systems-page.hme-page-component {
  padding: 27px 0 0 0; }

body.modal-open div.hme-tooltip.device-upgrade-tooltip {
  z-index: 100 !important; }
