@import 'src/mixins/breakpoints.scss';

.hme-input-container {
    display: inline-block;
    width: 100%;

    .hme-label {
        margin-bottom: 4px;
    }

    .hme-input-wrapper {
        position: relative;
        width: 100%;

      .hme-input__clear-button {
        position: absolute;
        width: 1rem;
        height: 1rem;
        top: calc(50% - .5rem);
        right: 1.5rem;
        cursor: pointer;
      }

      .typing-disabled {
        pointer-events: none;
    }
    }

    .hme-input-component {
        height: 48px;
        width: 100%;
        border-radius: 24px;
        border: solid 1px #a7adba;
        background-color: #ffffff;
        display: inline-block;
        font-family: Assistant;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 1.44px;
        line-height: 1.31;
        text-align: left;
        padding: 0 25px 4px 25px;
        color: #000819;

        &:focus {
            outline: none;
            border: solid 2px #0074ef;
            padding: 0 24px 3px 24px;
        }

        &[type="password"]:not(:placeholder-shown) {
            font-family: Verdana;
            font-weight: bolder;
            letter-spacing: 4px;
        }
    }

    .hme-input-component-before {
        position: absolute;
        left: 16px;
        top: 19px;
        content: '*';
        font-family: Assistant;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 1.44px;
        text-align: left;
        color: #186df4;
    }

    .input-component-from-date-picker {
        margin-left: 25px;
    }

    .hme-remaining-count {
        visibility: hidden;
    }

    .hme-input-component:focus ~ .hme-remaining-count {
        visibility: visible;
    }

    &.hme-input-label-inside-variant {
        position: relative;

        .hme-label {
            position: absolute;
            top: 5px;
            font-size: 12px;
            line-height: 1.33;
            letter-spacing: 1.08px;
            text-align: left;
            color: #606672;
            z-index: 2;
            left: 25px;
        }

        .hme-input-component {
            padding-top: 22px;

            &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: #d1cdcd;
            }

            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: #d1cdcd;
            }

            &::-ms-input-placeholder { /* Microsoft Edge */
                color: #d1cdcd;
            }
        }
    }

    &.hme-input-disabled-container {
        .hme-input-component {
            background-image: linear-gradient(to bottom, #dde1e9, #c7ccd6);
            color: #606672;
        }
    }

    &.hme-input--with-clear-button {
        .hme-input-component {
          padding-right: 3rem;
        }
    }

    &.hme-input-label-inline-variant {
        width: 100%;
        display: flex;
        align-items: center;

        .hme-label {
            flex: 0;
            margin-right: 7px;
            margin-bottom: 0;
        }

        .hme-input-component {
            flex: 1;
        }
    }

    &.hme-input-valid-variant {
        .hme-input-component {
            border: solid 2px #32820a;
            padding: 0 24px 3px 24px;

            &:focus {
                outline: none;
                border: solid 2px #32820a;
                padding: 0 24px 3px 24px;
            }
        }
    }

    &.hme-input-invalid-variant {
        .hme-input-component {
            border: solid 2px #cc0738;
            padding: 0 24px 3px 24px;

            &:focus {
                outline: none;
                border: solid 2px #cc0738;
                padding: 0 24px 3px 24px;
            }
        }
    }

    &.hme-input-underlined-variant {
        margin: 5px 0;
        border-bottom: solid 1px #e8e9ed;
        min-height: 60px;

        &:last-child {
            border: none;
        }

        .hme-label {
            font-size: 14px;
            line-height: 1.29;
            color: #606672;
        }

        .hme-input-wrapper {
            font-size: 16px;
            line-height: 1.5;
            color: #000819;
        }
    }

    &.hme-input-clock-variant {
        .hme-label {
            padding-left: 30px;
        }

        .hme-input-component-before {
            padding-left: 25px;
        }

        .hme-input-component {
            padding-left: 55px;
            padding-top: 18px;
        }
    }
}

@include before-desktop-only {
    .hme-input-container {
        &.hme-input-label-inside-mobile-only-variant {
            position: relative;

            .hme-label {
                position: absolute;
                top: 5px;
                font-size: 12px;
                line-height: 1.33;
                letter-spacing: 1.08px;
                text-align: left;
                color: #606672;
                z-index: 2;
                left: 25px;
            }

            .hme-input-component-before {
                left: 16px;
                top: 6px;
                font-weight: bolder;
            }

            .hme-input-component {
                padding-top: 22px;

                &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: #d1cdcd;
                }

                &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                    color: #d1cdcd;
                }

                &::-ms-input-placeholder { /* Microsoft Edge */
                    color: #d1cdcd;
                }
            }
        }
    }
}
