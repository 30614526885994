.hme-customer-notification-language-select {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;

    &--bottom {
        margin-top: 12px;
        flex-direction: row;
        justify-content: flex-end;
    }

    .hme-label {
        margin: 0;
    }

    &__content {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        
        text-transform: capitalize;
    }

    &__selector {
        max-width: 345px;
        padding: 0;
        padding-top: 14px;
        padding-bottom: 11px;
    }

    &__selector,
    &__delete-btn {
        text-transform: inherit;
    }
}
