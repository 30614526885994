@import 'src/mixins/breakpoints.scss';

@include before-desktop-only {
    .hme-textarea {
        position: relative;

        .hme-label {
            position: absolute;
            top: 5px;
            left: 25px;
            font-size: 12px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: 1.08px;
            color: #606672;
        }
    }
}
