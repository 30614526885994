@import 'src/mixins/breakpoints.scss';
@import 'src/assets/styles/Variables';

.hme-single-select {
    position: relative;

    .hme-label {
        margin-bottom: 8px;
    }

    .hme-single-select__box {
        width: 100%;
        height: 48px;
        border-radius: 24px;
        border: solid 1px #a7adba;
        background-color: #ffffff;
        position: relative;
        padding-right: 52px;
        cursor: pointer;
    }

    .hme-single-select__read-only-label {
        width: 100%;
    }

    .hme-single-select__box__star {
        display: none;
        position: absolute;
        top: 18px;
        left: 14px;
        font-family: $font-family;
        font-size: 16px;
        font-weight: bolder;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 1.44px;
        color: #186df4;
    }

    &__icon-wrapper {
        position: absolute;
        left: 13px;
        top: 13px;
    }

    .hme-single-select__box__text {
        font-family: $font-family;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.31;
        letter-spacing: 1.44px;
        text-align: left;
        color: #000819;
        margin-left: 25px;
        padding-top: 22px;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        
        &--with-filter {
            margin-left: 22px;
            padding-top: 10px;
        }
    }

    .hme-single-select__box__text-desktop {
        border-color: rgba(0,0,0,0);
        outline: none;
        width: 95%;
        margin-top: 9px;
        font-size: 16px;
        letter-spacing: 1.44px;
        padding: 0;
        background-color: transparent;
    }

    .hme-single-select__box__icon {
        position: absolute;
        right: 6px;
        top: 11px;
        width: 42px;
        height: 24px;
        color: #606672;
    }

    .hme-single-select__dropdown {
        overflow-y: scroll;
        list-style-type: none;
        position: absolute;
        top: 100%;
        left: 10px;
        right: 10px;
        margin-top: 8px;
        border-radius: 8px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
        background-color: #ffffff;
        padding: 8px 0;
        display: none;
        overflow: auto;
        max-height: 200px;
        z-index: 100;

        &__list {
            margin: 0;
            padding: 0;
            list-style: none;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #a7adba;
            border-radius: 4px;
        }
        &::-webkit-scrollbar {
            width: 6px;
            height: 15px;
        }
    }

    .hme-single-select__dropdown__item {
        width: 100%;
        min-height: 40px;
        font-family: $font-family;
        padding: 10px 15px;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.31;
        letter-spacing: 1.28px;
        text-align: left;
        color: #000819;
        cursor: pointer;

        &:hover {
            background-color: #2c6df4;
            font-weight: 600;
            color: #ffffff;
        }
    }

    .hme-single-select__dropdown__item--disabled {
        opacity: 0.5;
        cursor: default;

        &:hover {
            background-color: white;
            color: #000819;
            font-weight: normal;
        }
    }

    .hme-single-select__no-results {
        font-style: italic;
        font-size: 14px;
        text-align: center;
        cursor: default;
    }

    .hme-single-select__mobile {
        display: none;
    }

    .hme-single-select__box__text-mobile {
        display: none;
    }

    &.hme-single-select--no-label-variant {
        .hme-single-select__box__text-desktop {
            margin-top: 0;
        }

        .hme-single-select__box__text {
            &--with-filter {
                padding-top: 10px;
            }
        }        
    }

    &.hme-single-select--opened {
        .hme-single-select__box {
            border: 2px solid #0074ef;
        }

        .hme-single-select__box__text {
            transform: translate(-1px, -1px);
        }

        .hme-single-select__box__star {
            transform: translate(-1px, -1px);
        }

        .hme-single-select__dropdown {
            display: block;
        }

        .hme-single-select__box__icon {
            transform: rotate(180deg);
            color: #0074ef;
        }
    }

    &.hme-single-select--required {
        .hme-single-select__box__star {
            display: block;
        }
    }

    &.hme-single-select--disabled {
        .hme-single-select__box {
            background-image: linear-gradient(to bottom, #dde1e9, #c7ccd6);
            border: none;
            cursor: default;
        }

        &.hme-single-select--no-label-variant, 
        &.hme-single-select--label-inside-variant{
            .hme-single-select__box__text-desktop {
                background: none;
            }
        }

        .hme-single-select__box__text {
            color: #606672;
        }

        &.hme-single-select--required {
            .hme-single-select__box__star {
                display: none;
            }
        }
    }

    &.hme-single-select--label-inside-variant {
        .hme-label {
            position: absolute;
            margin-bottom: 0;
            top: 5px;
            left: 25px;
            font-size: 12px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: 1.08px;
            z-index: 1;
            color: #606672;
            pointer-events: none;
        }

        .hme-single-select__box__star {
            font-weight: normal;
            top: 7px;
            left: 16px;
        }

        &.hme-single-select--with-icon-variant {
            .hme-label {
                left: 56px;
            }

            .hme-single-select__box__star {
                left: 47px;
            }

            .hme-single-select__box__text {
                margin-left: 47px;
            }
        }
    }

    &.hme-single-select--small-dropdown-variant {
        .hme-single-select__dropdown {
            max-height: 165px;
            padding: 0;
        }
    }

    &.hme-single-select--mobile-label-inside-variant {
        .hme-single-select__box__text-desktop {
            margin-top: 2px;
        }
    }

    @include before-desktop-only {
        & {
            .hme-single-select__mobile {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                opacity: 0;
                width: 100%;
                z-index: 2;
            }

            .hme-single-select__box__star {
                top: 6px;
                left: 14px;
            }

            .hme-components & .hme-label {
                position: absolute;
                margin-bottom: 0;
                top: 5px;
                left: 25px;
                font-size: 12px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.33;
                letter-spacing: 1.08px;
                color: #606672;
                z-index: 1;
            }

            .hme-single-select__box__text-mobile {
                display: block;
                margin-top: 11px;
            }

            .hme-single-select__box__text-desktop {
                display: none;  
            }
            
            .hme-single-select__dropdown__item {
                padding: 10px;
            }
        }

        &.hme-single-select--mobile-label-inside-variant {
            .hme-label {
                position: absolute;
                margin-bottom: 0;
                top: 5px;
                left: 25px;
                font-size: 12px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.33;
                letter-spacing: 1.08px;
                z-index: 1;
                color: #606672;
                pointer-events: none;
            }

            .hme-single-select__box__star {
                font-weight: normal;
                top: 7px;
                left: 16px;
            }

            .hme-single-select__box__text {
                padding-top: 10px;
                padding-left: 2px;
            }
        }
    }
}
