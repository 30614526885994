.new-vaio-provider {
    &__footer {
        z-index: 100;
        text-align: right;
        padding: 20px 252px 20px 20px;
        box-shadow: 0 -10px 20px 0 rgba(0, 0, 0, 0.16);
        background-color: #ffffff;
        width: 100%;

        .hme-button-component {
            margin-left: 20px;
            min-width: 102px;
        }
    }
}
