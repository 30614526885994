@import 'src/mixins/breakpoints.scss';

@include tablet-only {
    .hme-layout {
        .hme-page {
            .public-text-connect-page {
                &.hmeBody {
                    padding: 0;
                    margin: 0;
                }
            }
        }
    }

    .text-connect {
        .hme-tab-panel {
            overflow: visible;

            .hme-tab-panel-tabs {
                margin-bottom: 30px;
            }

            .hme-tab-panel-content {
                overflow: visible;
            }
        }

        &__history {
            &__list {
                overflow-y: visible;
                height: auto;
            }

            .hme-grid {
                display: none;
            }

            .hme-tile-list__wrapper {
                padding: 0;
            }

            .hme-tile-list-item__actions-link {
                display: none;
            }

            .hme-paginate {
                margin: 0;
            }
        }
    }

    .hme-textarea .hme-label {
        position: relative;
        top: 0;
        left: 0;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 1.44px;
        color: #000819;
    }
}
