.api-management-form-page {
    .api-management-form-general {
        padding-right: 2vw;
        min-height: 90vh;
        height: auto;
    }

    .api-management-form-general {
        .vaio-provider-form-input:not(:first-child) {
            margin-top: 20px;
        }

        .vaio-provider-form-label {
            margin-bottom: 4px;
        }

        .hme-title {
            margin-bottom: 25px;
            font-weight: bold;
        }

        .hme-select-without-label {
            margin-left: 16px;

            .hme-select-required {
                margin-right: 3px;
                font-weight: normal;
            }
        }

    }
    
}