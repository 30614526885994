@import '../../../assets/styles/Variables';
@import '../../../assets/styles/Responsive';

.hme-multi-select {
    &__select-input {
        &__container {
            .hme-multi-select__select-input__wrapper--with-show-more {
                .hme-multi-select__select-input__wrapper__bages {
                    overflow: hidden;
                }

                .hme-multi-select__select-input__wrapper__icon {
                    padding-top: 0;
                }
            }

            &.expanded {
                .hme-multi-select__select-input__wrapper--with-show-more {
                    .hme-multi-select__select-input__wrapper__bages__list {
                        max-height: none;
                    }
                }
            }

            .hme-multi-select__select-input__wrapper__bages__list {
                max-height: 132px;
                overflow: auto;

                &--3-rows {
                    max-height: 132px;
                }

                &--4-rows {
                    max-height: 176px;
                }

                &--5-rows {
                    max-height: 220px;
                }

                &--6-rows {
                    max-height: 264px;
                }
            }

            .hme-label {
                margin-bottom: 4px;
            }
        }

        &__wrapper {
            position: relative;
            overflow-y: hidden;
            min-height: 48px;
            width: 100%;
            border-radius: 24px;
            border: solid 1px #a7adba;
            padding: 1px;
            background-color: $main-white;
            display: flex;
            flex-flow: row wrap;
            font-family: Assistant;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: 1.44px;
            line-height: 1.31;
            text-align: left;
            color: $main-black;

            &__icon {
                position: sticky;
                left: 12px;
                top: 0;
                display: none;
                padding-top: 10px;
            }

            &__bages {
                display: flex;
                flex-direction: row;
                padding: 0 0 36px;
                width: 100%;
                position: relative;
                background-color: $main-white;

                &__list {
                    width: 100%;
                    max-height: 156px;
                    min-height: 48px;
                    padding: 0 16px;
                    overflow-y: hidden;
                    display: flex;
                    flex-flow: row wrap;
                    align-items: flex-start;
                }

                .hme-badge-pill {
                    display: flex;
                    flex-direction: row;
                    height: 30px;
                }

                &__bage-pill-label {
                    padding: 0 10px 0 0;
                }

                &__close-cross {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0;
                    width: 18px;
                    height: 18px;
                    background-color: $main-white;
                    color: $hme-blue-color;
                    border-radius: 50%;
                    cursor: pointer;
                    flex-shrink: 0;
                    position: relative;

                    &::after {
                        content: '×';
                        font-size: 23px;
                        font-weight: bold;
                        position: absolute;
                        top: -6px;
                        left: 3px;
                    }
                }
            }

            &--disabled {
                padding: 1px 1px 5px;

                &.hme-multi-select__select-input__wrapper--empty {
                    padding: 1px;
                }

                .hme-badge-pill {
                    background: #c7ccd6;
                    color: #888b92;
                }

                .hme-multi-select__select-input__wrapper__bages__close-cross {
                    color: #c7ccd6;
                }

                .hme-multi-select__select-input__wrapper__bages {
                    padding: 0;
                }

                .hme-multi-select__select-input__wrapper__footer {
                    display: none;
                }
            }

            &--readonly {
                padding: 1px 1px 5px;

                &.hme-multi-select__select-input__wrapper--empty {
                    padding: 1px;
                }

                .hme-multi-select__select-input__wrapper__bages {
                    padding: 0;
                }

                .hme-multi-select__select-input__wrapper__footer {
                    display: none;
                }
            }

            &--with-show-more {
                .hme-multi-select__select-input__wrapper__bages {
                    display: block;
                    padding: 0 0 36px;
                }

                .hme-multi-select__select-input__wrapper__bages__list {
                    max-height: 132px;
                    overflow: hidden;
                    padding: 0 16px;

                    &--3-rows {
                        max-height: 132px;
                    }

                    &--4-rows {
                        max-height: 176px;
                    }

                    &--5-rows {
                        max-height: 220px;
                    }

                    &--6-rows {
                        max-height: 264px;
                    }
                }

                .hme-multi-select__select-input__wrapper__icon {
                    position: absolute;
                    left: 12px;
                    top: 10px;
                    display: none;
                }

                &.hme-multi-select__select-input__wrapper--disabled,
                &.hme-multi-select__select-input__wrapper--readonly {
                    &.hme-multi-select__select-input__wrapper--show-more-shown {
                        .hme-multi-select__select-input__wrapper__footer {
                            display: flex;
                        }
                    }
                }
            }

            &__footer {
                position: absolute;
                bottom: 0;
                left: 0;
                height: 36px;
                overflow-y: hidden;
                width: calc(100% - 40px);
                margin: 0 20px;
                background-color: $main-white;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                &__input {
                    outline: none;
                    border: none;
                    width: auto;
                    flex: 1;
                }

                &__show-more {
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-family: Assistant;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.29;
                    letter-spacing: 0.04px;
                    text-align: right;
                    color: $main-color;
                    background-image: linear-gradient(to top, $main-white, rgba(255, 255, 255, 0.5));
                    border-radius: 15px;
                    margin-left: 10px;

                    &.expanded {
                        & > svg {
                            transform: rotate(180deg);
                        }
                    }
                }
            }

            &:focus-within {
                outline: none;
                border: solid 2px $main-color;
                padding: 0;

                .hme-multi-select__select-input__wrapper__footer {
                    width: calc(100% - 38px);
                    margin: 0 19px;
                    height: 34px;
                }
            }

            &--empty {
                padding: 1px;

                .hme-multi-select__select-input__wrapper__bages {
                    padding: 0;
                }

                .hme-multi-select__select-input__wrapper__bages__list {
                    display: none;
                }

                .hme-multi-select__select-input__wrapper__footer {
                    bottom: 6px;
                }

                .hme-multi-select__select-input__wrapper__icon {
                    padding-top: 10px;
                }

                &:focus-within {
                    padding: 0;
                }
            }
        }
    }

    &__search-variant {
        .hme-multi-select__select-input__wrapper {
            &__icon {
                display: block;
            }

            &__bages {
                &__list {
                    padding: 0 20px 0 24px;
                }
            }

            &--with-show-more {
                .hme-multi-select__select-input__wrapper__bages__list {
                    padding: 0 20px 0 42px;
                }
            }

            &--empty {
                .hme-multi-select__select-input__wrapper__bages {
                    padding: 0;
                }

                .hme-multi-select__select-input__wrapper__bages__list {
                    padding: 0 16px;
                }
            }

            &__footer {
                width: calc(100% - 72px);
                margin: 0 26px 0 46px;
            }

            &:focus-within {
                .hme-multi-select__select-input__wrapper__footer {
                    width: calc(100% - 70px);
                    margin: 0 25px 0 45px;
                    height: 34px;
                }
            }
        }

        .hme-multi-select__select-input {
            display: flex;
            width: 100%;
        }
    }

    &__label-inside-variant {
        .hme-multi-select__select-input__wrapper {
            &__bages {
                padding-top: 27px;
            }

            &__icon {
                padding-top: 0;
                margin-top: -17px;
            }

            &--with-show-more {
                .hme-multi-select__select-input__wrapper__icon {
                    margin-top: 0;
                }
            }

            &--empty {
                .hme-multi-select__select-input__wrapper__icon {
                    padding-top: 10px;
                    margin-top: 0;
                }

                .hme-multi-select__select-input__wrapper__footer {
                    height: 25px;
                    bottom: 0;
                }

                &:focus-within {
                    .hme-multi-select__select-input__wrapper__footer {
                        height: 23px;
                        bottom: 0;
                    }
                }
            }
        }
    }
}
