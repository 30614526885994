@import 'src/mixins/breakpoints.scss';

@include mobile-only {
    .hme-layout {
        .hme-page {
            .public-text-connect-page {
                &.hmeBody {
                    padding: 0;
                    margin: 0;
                }
            }
        }
    }

    .text-connect-wrapper {
        min-width: auto;
        padding-bottom: 0;

        .hme-title {
            font-size: 20px;
            font-weight: 600;
            line-height: 1.3;
            letter-spacing: -0.28px;
        }

        .text-connect {
            .hme-tab-panel {
                overflow: visible;

                .hme-tab-panel-tabs {
                    margin-bottom: 30px;
                }

                .hme-tab-panel-content {
                    overflow: visible;
                }

                .hme-button-component {
                    margin-right: 24px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

            &__content {
                overflow: auto;
                height: 100%;
                padding: 0 15px;
            }

            &__header {
                margin: 21px 0 25px;
            }

            &__sections {
                flex-direction: column;
                min-height: auto;
                height: auto;

                .hme-section {
                    padding: 25px 0 0;
                    margin: 0 0 25px;
                    border-top: 1px solid #e8e9ed;
                    border-left: 0;
                    height: auto;
                    max-width: none;

                    & > .hme-title {
                        min-height: 0;
                        margin-bottom: 15‰px;
                    }

                    &-first {
                        border-top: 0;
                        padding-top: 0;
                    }
                }

                &__section {
                    &--stores {
                        width: 100%;
                    }

                    &--message {
                        width: 100%;
                    }

                    &__search-stores {
                        &__input {
                            .hme-input-component::placeholder  {
                                font-size: 14px;
                            }
                        }
                    }
                }

                .hme-title {
                    margin-bottom: 15px;
                }
            }

            &__history {
                &__list {
                    overflow-y: visible;
                    height: auto;
                }

                .hme-grid{
                    display: none;
                }

                .hme-tile-list__wrapper {
                    padding: 0;
                }

                .hme-tile-list-item__actions-link {
                    display: none;
                }

                .hme-paginate {
                    margin: 0;
                }
            }

            &__footer {
                text-align: center;
                padding: 20px 0;
                display: flex;
                justify-content: center;

                .hme-button-component {
                    margin: 0 10px;
                }
            }

            .hme-sidebar-overlay {
                &__sidebar {
                    width: 100%;
                }
            }
        }
    }

    .text-connect__info-icon__tooltip {
        width: 90%;
    }
}
