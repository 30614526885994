.hme-admin-store-details__subscription-block {
    .hme-view-edit-store__subscription-value {
      display: flex;
      flex-direction: column;
      margin: 5px 0 20px;
      min-height: 60px;
  
      &:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
        border: none;
      }
  
      .hme-view-edit-store__subscription-block__value {
        font-family: Assistant, sans-serif;
        font-size: 16px;
        line-height: 1.5;
        color: #000819;
      }
    }
    
    .hme-view-edit-store__subscription-value-gig-delivery {
      display: flex;
      justify-content: space-between;
      margin: 5px 0 20px;
      min-height: 60px;
    }
  
    .hme-view-edit-store__subscription-value__label {
      font-family: Assistant, sans-serif;
      font-size: 14px;
      font-weight: 600;
      line-height: 1.5;
      text-align: left;
      color: #606672;
      margin-bottom: 3px;
    }
     
    .hme-title-block {
      padding-bottom: 20px;
    }

    .hme-view-edit-store__subscription-value__label {
      padding: 0 0 10px 0;
    }
    .hme-select {
      .hme-label {
        white-space: nowrap;
      }
      .hme-select-text {
        overflow: hidden;
        white-space: nowrap;
      }
    }
}