.hme-manage-hierarchy__header {
    width: 100%;

    &__content-container {
        display: flex;
        width: 100%;
        justify-content: space-between;
        max-width: 1168px;
    }

    .hme-title {
        padding-top: 5px;
    }

    &__user-guide.hme-button-component.hme-transparent-button-component {
        padding-top: 12px;
        width: 450px;
        text-align: right;
        display: inline-block;
    }

    &__info-box.hme-manage-hierarchy__header__info-box.hme-info-box__content {
        margin: 10px 0 20px;
        max-width: 475px;

        font-size: 18px;

        .hme-info-box-message {
            display: flex;
            gap: 10px;
        }

        svg {
            flex-shrink: 0;
            width: 22px;
            height: 22px;
        }
    }
}

@import './Mobile.scss';
