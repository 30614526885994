.hme-grid-header-titles-row {
    display: flex;
    padding: 0 15px 0 20px;

    .hme-grid-cell {
        font-family: Assistant;
        font-size: 18px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: 0.02px;
        text-align: left;
        color: #606672;
        padding-top: 0;
        padding-bottom: 10px;
        text-transform: uppercase;
    }

    .hme-grid-expanded-row .hme-grid-expanded-content & {
        background-color: #ffffff;
    }

    .hme-grid-row-odd.hme-grid-expanded-row .hme-grid-expanded-content & {
        background-color: #f3f3f5;
    }

    .hme-grid-row-hovered.hme-grid-expanded-row .hme-grid-expanded-content & {
        background-color: #E8F0FE;
    }
}