header {
    box-shadow: 0 2px 17px 0 rgba(134, 140, 153, 0.5);
	margin: 0 0 1px 0 !important;
}

.user-info-section {
    color: black !important;
    display: block;
    font-size: 10px;
    justify-content: flex-end;
    font-weight: bold;
    min-width: 168px;
    text-align: right;
    padding: 0;
    margin-right: 7px;
}

.admin-main-header .user-info-section {
    min-width: 165px;
    margin-left: 0;
}

.user-info-section a {
    color: black !important;
}

.user-info-section span {
    display: inline;
    text-align: left;
}

.profile-username {
    margin-bottom: 0;
    max-width: 130px;
    width: 115px;
    float: right;
}

.masquerade .profile-username {
    width: auto;
    max-width: 200px;
}

.profile-username a {
    display: inline;
    margin-left: -5px;
    padding-right: 0!important;
}

.user-info-section.masquerade {
    margin-top:12px;
    margin-left: 55px;
}

.navbar.navbar-light {
    background-color: var(--white);
    border: 0;
    margin-bottom: 0;
    width: 100%;
    padding: 0!important;
}

.navbar.navbar-light div.container-fluid:first-child {
    display: flex;
    align-items: center;
}

.navbar.navbar-light div.container-fluid:first-child .navbar-brand {
    flex: 1;
}

.navbar-brand {
    padding: 15px;
    margin-right: 4.2rem;
}

.nav-link {
    padding: 0;
}

.nav-link:hover {
    text-decoration: underline;
}

/* Sub Menus */
.subMenu.menuBar, .subHeaders {
    margin: 0px;
    width: 100%;
    background: var(--white);
}

.subMenu .subnav    {
    margin: 0 auto;
    display: flex;
    width: 100%;
    float: none !important;
    height: 40px;
}

.subHeaders {
    display: flex;
    flex-direction: row !important;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-left: 20px;
    align-items: center;
    box-shadow: 0 2px 17px 0 rgba(134, 140, 153, 0.5);
}

.menuBar ul {
    flex: 1 1 auto;
    height: 40px;
}

.menuBar ul li {
    font-size: 14px;
    min-width: 106px;
    padding: 10.8px 12px 0 12px;
    text-align: center;
    text-transform: uppercase;
    height: 40px;
    cursor: pointer;
    list-style: none;
    position: relative;
}

.dropdown-menu a {
    color: var(--gray-62);
    font-family: Assistant,sans-serif;
    font-size: 14px;
    font-weight: 600!important;
    font-style: normal;
    font-stretch: normal!important;
    line-height: 1.29!important;
    letter-spacing: 1px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
}

.headerMenu .fa {
    right: 5px;
}

.navbar .headerMenu a {
    position: relative;
    display: block;
    padding: 10px 15px;
}

.user-main-header.navbar-nav .headerMenu > a, .headerMenu > a {
    padding-top: 22.7px!important;
    padding-bottom: 22.7px!important;
    height: 100%;
}

.menuBar a.active_tab_sub::after, .menuBar a:hover::after {
    background: var(--blue-100);
    content: '';
    bottom: 0%;
    height: 2px;
    position: absolute;
    right: 0%;
    width: 100%;
}

.headerMenu a, .menuBar a, .Label_Large_GrayDark_Left,
.Label_Large_GrayDark_Left h5 {
    color: var(--gray-62);
    font-family: Assistant, sans-serif;
    font-size: 14px;
    font-weight: 600 !important;
    font-style: normal;
    font-stretch: normal !important;
    letter-spacing: 1px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
}

.headerMenu.active_tab a, .headerMenu:hover a{
    color: var(--blue-87) !important;
    cursor: pointer;
    text-decoration: none;
}

.headerMenu.active_tab a::after {
    background: var(--blue-100);
    content: '';
    bottom: 0;
    height: 2px;
    position: absolute;
    right: 0%;
    width: 100%;
}

a.headerMenu.active_tab_sub {
    color: #06b1cb;
    font-size: inherit;
    font-weight: bold;
}
a.headerMenu.active_tab_sub, ul.subHeaders li a.headerMenu:hover {
    color: var(--blue-87);
}

.subMenu li a {
    color: var(--gray-62);
}

.subMenu .active_tab {
    color: #06b1cb;
    font-weight: bold;
}

/* End Sub Menus */
.headerLink {
    display: flex;
    align-items: center;
    text-decoration: none !important;
}

.headerLink span.username:hover{
    text-decoration: underline !important;
}

.loginInfo {
    text-align:right
}

div.view-as {
    float: right;
    max-width: 170px;
}

div.view-as button {
    border-radius: 36px;
    color: white !important;
    height: 15px;
    border: none;
    background: red;
    padding: 1px 5px;
    margin: 0px;
}

div.view-as button span {
    color: white !important;
    font-size: 8px;
    padding: 0px;
}

div.view-as span {
    color: #D41B3E !important;
    text-decoration: none;
}

.loggedInUser {
    display:flex;
    justify-content:flex-end;
}

.view-as {
    display: flex;
    align-items: center;
    display: -webkit-flex;
}

.viewingAsSection{
    width:80%;
    margin:0 auto;
}

.green_id {
    color: #009933;
    text-decoration: none;
    cursor: pointer;
}

.blackEye{
    padding-right:3px
}

.logOutHMEIcon {
    margin-left: 10px;
}

.csect h3 {
    font-size: 30px;
    margin-bottom: 17px;
}

.faq h5 {
    font-size: 18px;
    font-weight: bold;
}

.faq ul.ques {
    list-style: disc outside none;
    padding-left: 40px;
}

.faq ul li.answer_list {
    list-style: none;
}

.faq ul li {
    font-size: 12px;
    padding-bottom: 5px;
}

.faq ul.answer {
    list-style: none;
    color: #989898;
    padding-left: 0px
}

.faq {
    padding-top: 10px;
    padding-bottom: 20px;
}

.fteaser .email {
    color: #D41B3E;
    text-decoration: none;
}

.csect {
    padding-bottom: 50px;
    padding-left:30px;
    padding-top: 20px;
    width: 80%;
    margin: 0 auto;
}

.fteaser {
    padding-bottom: 80px;
}

.faq ul.ques {
    list-style: disc outside none;
    padding-left: 40px;
}

.instruction_link{
    color: #d41b3e;
    font-size: 15.5px;
}

.instruction_link:hover {
    color: #D41B3E;
    text-decoration: underline;
    cursor:pointer;
    font-size: 15.5px;
}

.helpHeaders {
    display:flex !important;
    align-items:center !important;
    justify-content:space-between !important;
}

.helpLink {
    padding-top: 13px;
}

.invite-reject {
    margin-left: 20px;
}

.invite-modal{
    padding-top: 28px;
    padding-right: 10px;
    padding-left: 10px;
}

.popover-close {
    margin-top: 4px;
    margin-right: 4px;
    font-size: 3rem;
    cursor: pointer;
}

.noification-bell {
    margin-top: 5px;
    margin-left: 3px;
}

.notification-dropdown {
	max-width: 150px;
}

.notification-dropdown-box {
	width: 320px;
	height: 303px;
	border-radius: 8px;
	box-shadow: 0 2px 17px 0 rgba(134, 140, 153, 0.5);
	background-color: var(--white);
}

/* header user icon dropdown*/
.account-nav-dropdown {
    font-size: 150%;
    margin-right: 10px;
    padding: 10px 15px!important;
}

.admin-main-header .account-nav-dropdown {
    margin-right: 0;
}

.account-nav-dropdown .dropdown-item {
    padding: .5rem 2.5rem;
}

.account-nav-dropdown.dropdown.nav-item .dropdown-toggle.nav-link,
.navbar.navbar-expand.navbar-light,
.account-nav-dropdown.dropdown.nav-item {
    padding-top: 0;
    padding-bottom: 0;
}

.account-nav-dropdown a:after {
    content: none;
}

.account-nav-dropdown a {
    background-color: transparent;
}

.account-nav-dropdown .caret{
	display: none;
}

.account-nav-dropdown > .dropdown-menu::before,
.account-nav-dropdown::before {
	content: '';
	width: 0;
	height: 0;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-bottom: 8px solid var(--white);
	position: absolute;
	top: -8px;
	right: 18px;

}

.account-nav-dropdown > .dropdown-menu {
	border: 0;
	border-radius: 8px !important;
	box-shadow: 0 2px 17px 0 rgba(134, 140, 153, 0.5);
	top: 114%;
}

.account-nav-dropdown i:nth-child(1) {
    font-size: 150%;
}

.navbar-light .navbar-nav>.open>a,
.navbar-light .navbar-nav>.open>a:hover {
	background: var(--white) !important;
}

.account-icon {
	background: url('../../images/hmecloud-account.svg') no-repeat center;
	background-size: 20px 20px;
	width: 20px;
	height: 20px;
}

.navbar-light .navbar-nav>.open>a .account-icon,
.account-icon:hover {
	background: url('../../images/hmecloud-account-blue.svg') no-repeat center;
	background-size: 20px 20px;
}

.dropdown-menu a {
    display: flex;
    align-items: center;
}

.dropdown-menu a > i {
    margin-right: 12px;
}

.headerMenu i {
    float: left;
}

.dropdown-menu> a:focus,
.dropdown-menu> a:hover {
	background: none;
}

.showOnMobile {
    display: none !important;
}

.navbar-brand .H4_Left {
    text-transform: capitalize;
    margin: 5px;
    line-height: 1 !important;
}

.navbar-collapse.collapse > div:first-child {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.navbar-collapse {
    padding-left: 15px;
}

.nav {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.showOnDesktop, .showOnDesktop li {
    height: 100%;
    display: flex;
    align-items: center;
}

.smacktalk_icon {
    background: url('../../images/SmackTalkMonster.png') no-repeat 0 center;
    background-size: auto 30px;
}

.text-connect_icon {
    background: url('../../assets/icons/icon-headphones.svg') no-repeat center left;
}

.user-main-header.navbar-nav .smacktalk_icon > a,
.user-main-header.navbar-nav .text-connect_icon > a {
    padding-left: 43px;
    width: 130px;
    padding-top: 15px!important;
    padding-bottom: 10px!important;
    line-height: 1.3em;
}

.user-main-header.navbar-nav .text-connect_icon > a {
    text-align: left;
}

.more-M .dropdown-menu > .smacktalk_icon {
    padding-left: 45px;
}

.dropdown-toggle {
    color: var(--gray-62);
    height: 100%;
    display: flex;
    align-items: center;
    padding: 15px;
}

.dropdown-toggle:hover, .dropdown-toggle:focus, .dropdown-toggle:active {
    text-decoration: none;
    color: var(--gray-87);
    outline: none !important;
    background: transparent !important;
}

/* remove space below icons */
i {
    line-height: 1;
}

/* Nav Notification dropdown menu */
.notification-nav-dropdown ul {
    border-radius: 8px;
    border: none;
    top: 120%;
}

.notification-nav-dropdown ul li a {
    padding: 8px 16px;
}

.notification-nav-dropdown ul li:not(:last-child) {
    border-bottom: 1px solid var(--gray-12);
}

.notification-flexbox {
    width: 320px;
    display: grid;
    grid-template-columns: 24px 1fr;
    grid-template-rows: 24px 1fr;
    grid-gap: 8px;
}

.notification-flexbox * {
    margin: 0px;
}

.notification-header {
    margin: 0px !important;
    grid-column: 2 / -1;
    grid-row: 1;
    color: var(--gray-62);
}

.notification-icon {
    grid-column: 1;
    grid-row: 1;
}

.notification-icon i {
    line-height: .6;
}

.icon-error-2 {
    color: var(--success-red);
}

.icon-warning-fill {
    color: var(--warning-yellow);
}

.icon-info {
    color: var(--blue-100);
}

.notification-header, .notification-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.notification-text {
    text-transform: none;
    white-space: normal;
    text-align: left;
    grid-column: 2 / -1;
    grid-row: 2 / -1;
}

.dropdown-icon-badge {
    position: relative;
    width: 28px;
    height: 28px;
}

.dropdown-icon-badge:hover {
    color: var(--blue-100);
}

.dropdown-icon-badge .badge, .dropdown-icon-badge i {
    position: absolute;
}

.dropdown-icon-badge .badge {
    top: 0;
    right: 0;
    z-index: 10;
    margin: -1px -2px;
    font-size: 7px;
    border: 1px solid var(--white);
    background-color: var(--blue-100);
    padding: 5px 6px;
}

.dropdown-icon-badge i {
    z-index: 9;
    font-size: 165%;
}

div.dropdown.open.btn-group {
    padding: 0;
    margin: 0;
}

.account-nav-dropdown,
.btn-group.open,
.dropdown-toggle {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    padding: 10px 15px;
    border-radius: 0;
}

.account-nav-dropdown span {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.account-nav-dropdown > .dropdown-menu {
    position: absolute !important;
    top: 130%;
}

.account-nav-dropdown > .dropdown-menu a {
    display: flex;
}

.account-nav-dropdown > .dropdown-menu a i {
    font-size: 150%;
}

.navbar-nav > .headerMenu > .nav-link {
    padding: 10px 15px;
    color: rgb(119, 119, 119);;
}

.user-main-header .showOnDesktop:empty + .showOnDesktop .user-info-section{
    min-width: 130px;
}

@media (min-width: 768px) {
    .headerMenu .nav-link {
        padding-top: 15px!important;
        padding-bottom: 15px!important;
    }

    .navbar .dropdown-menu {
        right: 0;
        left: auto;
    }
}
