.modal-dialog.hme-select-snapshot-modal-dialog {
    .modal-header {
        position: relative;
        z-index: 10;
        margin: 0;
    }
    .hme-select-snapshot-modal {
        padding-left: 50px;
        padding-right: 50px;
        min-width: 1720px;
    }
    .modal-content {
        min-height: 700px;
    }

    .hme-grid-filter-cell-popup-body {
        .hme-grid-filter-cell-popup-row {
            .hme-checkbox-label {
                text-transform: capitalize;
            }

            &.hme-grid-filter-cell-popup-item {
                .hme-checkbox-label {
                    text-transform: none;
                }
            }
        }
    }

    .hme-grid-cell-show-all-content {
        .hme-grid-cell-content {
            word-break: break-word;
        }

        .device-settings-snapshots-account-cell .hme-grid-cell-content {
            white-space: nowrap;
        }

        .device-settings-snapshots-created-by-cell .hme-grid-cell-content {
            white-space: nowrap;
        }

        .device-settings-snapshots-applied-by-cell .hme-grid-cell-content {
            white-space: nowrap;
        }
    }
}
