.hme-admin-login-page {
    .hme-admin-login-page__notification {
        width: 500px;
        text-align: center;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;
    }
    .hme-admin-login-page__submit {
        width: 300px;
        text-align: center;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;
    }
}