@import '../../assets/styles/Variables';
@import '../../assets/styles/Responsive';

$advanced-settings-max-width: 420px;
$table-max-width: 520px;
$table-max-height: 300px;
$category-image-height: 140px;

.smacktalk {
  position: relative;
  margin-bottom: $margin;

  &__previously-sent {
    .hme-modal__opener {
      padding-left: 0;
    }
  }

  .smacktalk-form__body {
    section {
      margin: $margin 0;
    }

    &__message {
      &__categories {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        flex-wrap: nowrap;
      }

      &__category {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        padding: $padding / 4;
        margin: $margin / 4;
        border-bottom: $border-thick;
        border-color: transparent;

        &__title {
          display: block;
          padding-top: $padding;
          font-weight: bold;
          font-size: 300%;
          text-transform: capitalize;
          text-align: center;
          color: var(--gray-62);
        }

        &--active {
          border-color: $accent-2-color;
        }
      }

      .list-input {
        margin: $margin 0;
      }

      @include smaller($tablet) {
        &__category {
          img {
            height: $category-image-height / 1.5;
          }

          &__title {
            font-size: 200%;
          }
        }
      }

      @include smaller($mobile) {
        &__categories {
          justify-content: space-between;
        }

        &>p {
          font-size: $font-size * 0.9;
        }

        &__category {
          img {
            height: $category-image-height / 2;
          }

          &__title {
            font-size: 100%;
          }
        }
      }
    }

    &__stores {
      &__controls {
        .select-input {
          padding-left: 0;
        }

        label {
          display: inline-block;
          margin-right: $margin;
          font-weight: normal;
        }

        &__to {
          margin-bottom: $margin;

          &>span {
            margin-right: $margin;
          }
        }

        .hme-input select {
            width: auto;
        }
      }

      @include smaller($mobile) {
        &__controls {
          label {
            margin-right: $margin / 2;
          }

          &__to {
            margin-bottom: 0;

            .hme-modal__opener {
              padding-left: 0;
            }
          }
        }
      }
    }

    &__advanced {
      .hme-header {
        text-align: center;
      }

      &__container {
        padding: $gap;
        max-width: $advanced-settings-max-width;

        .hme-header {
          padding-bottom: $padding;
        }
      }

      &__fields {
        display: flex;
        align-items: center;

        .image-input {
          flex-direction: column;
        }

        .select-input {
          min-width: $advanced-settings-max-width / 3;
        }

        .number-input {
          max-width: $advanced-settings-max-width / 6;
        }
      }

      @include smaller($mobile) {
        &__container {
          padding: 0 $padding * 2;
        }

        &__fields {
          flex-direction: column;

          .emoji-input {
            padding-bottom: 0;
            padding-top: 0;
            justify-content: center;
          }

          .image-input {
            align-items: center;
          }

          &--time {
            align-items: flex-start;

            .form-field {
              width: 100%;

              .number-input {
                max-width: 100%;
              }

              .hme-input {
                padding-right: 0;
                padding-left: 0;
              }
            }
          }
        }
      }
    }
  }

  .smacktalk__previously-sent {
    .hme-modal__closer {
      @include smaller($mobile) {
        margin-top: 0.7 * $gap ;
      }
    }

    &__table {
      overflow: auto;
      background: $primary-color;
      max-width: $table-max-width;
      max-height: $table-max-height;
      font-size: $font-size * 0.85;

      & tr, & td, & th {
        border: $border-thick;
      }

      tr.hme-table__header th:last-child {
        min-width: 130px;
      }
    }

    @include smaller($mobile) {
      &__table {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  @include smaller($tablet) {
    margin-left: -$margin;
    margin-right: -$margin;
  }

  @include larger($tablet) {
    max-width: $tablet;
  }
}

@include smaller($tablet) {
  .react-confirm-alert-body {
    width: auto;
  }
}
