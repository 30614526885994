.vaio-providers__actions {
  .vaio-providers__actions-separator {
    color: #d1cdcd;
    margin: 0 15px;
  }

  .vaio-providers__action-remove-user {
    display: inline;
  }
}
