.deviceUpgrades {
    min-height: 40px;
    margin: 0 45px; 
}
.deviceUpgrades .form-control {
  width: 100%;
  appearance: auto;
  height: calc(1.5em + 0.75rem + 2px);
}
.deviceUpgrades div.dblock {
  display: block;
}
.deviceUpgrades small,
.deviceUpgrades .small {
  font-size: 80%;
  font-weight: 400;
}
.deviceUpgrades label {
  margin-bottom: 0.5rem;
}
.deviceUpgrades .ml-2 {
  margin-left: 0.5rem;
}
.deviceUpgrades .multiple-checkbox-input label {
  margin-bottom: 0;
}
.deviceUpgrades div a.span-link {
  color: #007bff;
  display: flex;
}
.deviceUpgrades div a.removeall {
  color: #007bff;
  max-width:5.5em;
}
.deviceUpgrades div a.cancelgoback {
  max-width:17em;
}
.deviceUpgrades div.super-tall {
    height: 640px;
    padding-left: 1em;
    border-left: solid 2px #e8e9ed;
}
.deviceUpgrades div.invisible-hat {
    top: 42em;
    position: relative;
}
.deviceUpgrades div.min-height-large{
  min-height: 44em;
}
.deviceUpgrades span.labelStyling {
    font-size:75%;
}
.deviceUpgrades .fleft {
  float: left;
}
.deviceUpgrades .fright {
  float: right;
  white-space: nowrap;
}
.deviceUpgrades .btn-grey {
  min-width: 12rem;
  min-height: 4rem;
  background-image: linear-gradient(to bottom, #ffffff, #ccd3e2);
  font-family: Assistant;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: 0.2px;
  outline: none;
}
.deviceUpgrades .btn-blue {
  min-width: 16rem;
  min-height: 4rem;
  background-image: linear-gradient(to bottom, #186df4, #0040ba);
  font-family: Assistant;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: 0.2px;
  color: #ffffff;
  outline: none;
}
.minwidthfor-find-devices-by-buttons{
  min-width: 40rem;
}
.minwidthfor-save-cancel-buttons{
  min-width: 32rem;
}
.maxwidthfor-save-cancel-buttons{
  max-width: 32rem;
}
.minwidthfor-selectedDevicesTable{
  min-width: 60rem;
}
.odd-table-row{
  background-color: #f3f3f5;
}

.deviceUpgrades .btn-left-rounded {
  border-bottom-left-radius: 999px;
  border-top-left-radius: 999px;
}
.deviceUpgrades .btn-right-rounded {
  border-bottom-right-radius: 999px;
  border-top-right-radius: 999px;
}
.deviceUpgrades .whiteborder {
  border: solid 1px #a7adba;
  background-color: #ffffff;
}
.deviceUpgrades .shape {
  padding-top: 0em;
  padding-bottom: 0em;
  min-height: 3rem;
  border-radius: 24px;
  border: solid 1px #a7adba;
  background-color: #ffffff;
}
.deviceUpgrades .button-shape {
  width: 150px;
  height: 40px;
  border-radius: 24px;
  border: solid 1px #c7ccd6;
}
.deviceUpgrades .h3-bold {
  font-family: Assistant;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.1px;
}
.deviceUpgrades .h4-subheader {
  font-family: Assistant;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
}
.deviceUpgrades .vertically-align {
  align-items: center;
}
.deviceUpgrades .padding-on-top {
  padding-top: .5em; 
}


.deviceUpgrades .daysOfTheWeek{
  color: #606672
}
.deviceUpgrades .asterisk{
  color: blue
}
.centered {
  float: none;
  margin-left: 155px;
  padding-bottom: 0em;
}
.flexbox-parent-table{
  display: flex;
}

.min-width-120{
  min-width: 120%;
}
.devices-table {
  display: flex;
  flex-direction: column;
  max-height: 35em;
  min-height: 35em;
  overflow-y: scroll;
}

.deviceUpgrades-table-header-row {
  height: 3em;
  display: flex;
  flex-wrap: nowrap;
  border-bottom: 1px solid #ccc;
}

.deviceUpgrades-table-row {
  height: 6em;
  display: flex;
  flex-wrap: nowrap;
  border-bottom: 1px solid #ccc;
}

.devices-table-header {
  flex-shrink: 0;
  flex-wrap: nowrap;
  font-family: Assistant;
  font-weight: 600;
  align-items: left;
  justify-content: left;
  text-align: left;
  line-height: 1.71;
  color: #606672;
}

.submission-message{
  padding-top: 5em;
}

.disabled-submit-opacity{
  opacity: .5;
}

.brands-selected{
  margin-top:.5em;
  font-family: Assistant;
  font-size: 1.1em;
  line-height: 1.5;
  text-align: left;
  color: #000819;
}
.brands-selected-counters{
  font-size: 1.6rem;
  font-weight: bold;
  letter-spacing: 0.02px;
  color: #606672;
}
.brands-selected-table{
  max-height: 3em;
  vertical-align: middle;
  text-align: center;
}
.brands-selected-checkbox{
  max-height: 3em;
  margin-top: .8em;
  min-width: 2.5em;
  height: 60%;
}


.deviceUpgrades-table-cell {
  margin-top: .6em;
  min-width: 3em;
  height: 100%;
  align-items: left;
  justify-content: left;
  text-align: left;
  margin-left:1em;
  font-family: Assistant;
  font-size: 16px;
  line-height: 1.5;
  color: #000819;
  word-wrap: break-word !important;
}

.deviceUpgrades-table-cell-wide {
  min-width: 6em;
  max-width: 6em !important;

}
.deviceUpgrades-table-cell-wider {
  max-width: 11em !important;
  min-width: 11em;
}
.deviceUpgrades-table-cell-widest {
  max-width: 15em !important;
  min-width: 15em;
}

.brand-column {
  max-width: 4em !important;
  min-width: 4em;
}
.tz-column {
  max-width: 5em !important;
  min-width: 5em;
}
.account-column {
  max-width: 8em !important;
  min-width: 8em;
}
.storenumber-column {
  max-width: 5.5em !important;
  min-width: 5.5em;
}
.storename-column {
  max-width: 5em !important;
  min-width: 5em;
}
.serialnumber-column {
  max-width: 4em !important;
  min-width: 4em;
}
.actions-column {
  max-width: 4em !important;
  min-width: 4em !important;
}
.min-height-5 {
  min-height: 5em;
}

.devicesSelectedSpan{
  font-family: Assistant;
  font-size: 24px;
  text-align: left;
  color: #000819;
}
.devicesSelectedCounter{
  font-size: 30px;
  font-weight: bold;
  color: #606672;
}
.magnifyingGlassLabel-one{
  z-index:2;
  position: relative; 
  margin-bottom: -4.1em;
  margin-left: .8em;
  opacity: .7;
}
.magnifyingGlassLabel-two{
  z-index:2;
  position: relative; 
  margin-bottom: -3.5em;
  margin-left: .8em;
  opacity: .7;
}
.text-indent-large{
  text-indent: 3em !important;
}
.text-indent-medium{
  text-indent: 2em !important;
}
.deviceSearchWrapper{
  position: relative; 
  margin-top: -1.5em;
}

.dont-break-words-with-wrapping{
  margin-right: 0em !important;
  margin-left: 0em !important;
  text-overflow: clip;
}



.drilldown-info-label{
    width: 13.5rem;
    height: 0.938rem;
    font-family: Assistant;
    font-size: 1rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 1.08px;
    text-align: left;
    color: #606672;
}
.drilldown-info-text{
  width: 10.125rem;
  height: 1.313rem;
  font-family: Assistant;
  font-size: 1.5rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: 1.2px;
  text-align: left;
  color: #000819 ;
}
.drilldown-info-container{
  padding-bottom: 1rem;
  border-bottom: 1px solid #e8e9ed;
}

.drilldown-info-section{
  padding-top: 1.33em;
}

.daysOfTheWeek-selector {
  padding: 0%;
}

.round {
  position: relative;
  padding-left: 2.9em;
  padding-bottom: 3em;
  font-family: "Assistant-SemiBold", Arial, "Helvetica Neue", Helvetica, sans-serif;
}
.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 40px;
  left: 0;
  position: absolute;
  top: 0;
  width: 40px;
  padding-left: .7em;
  padding-top: .7em;
  color: #a7adba;
}

.round-extra-padding-for-centering-single-chars label {
  padding-left: .92em !important ;
}

.calendar-css {
  position: absolute;
  right: 18em;
  z-index: 100;
}

.minwidth-date{
  min-width: 20em;
}

.left-pad-for-createdeviceupgrades-button{
  margin-left: 10em;
}

.max-throughput-toast-message{
  color: white !important;
  text-emphasis: 200% !important;
  background-image: linear-gradient(to bottom, #186df4, #0040ba) !important;
  margin-left: -10em;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  margin-left: 1em;
  height: 12px;
  opacity: 0;
  position: absolute;
  top: 16px;
  width: 24px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #186df4;
  border-color: #186df4;
  color: #ffffff;
}

.round input[type="checkbox"]:disabled:checked + label {
  background-color: #c7ccd6;
  border-color: #c7ccd6;
  color: #888b92;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.move-left-1 {
  margin-left: -1em;
}

.mb-5em{
  margin-bottom: 5em;
}
.mt-5em{
  margin-top: 5em;
}
.assistant-font{
  font-family: "assistant";
}
.force-single-line{
  overflow: auto;
  white-space: nowrap;
  align-items: left;
}
.skinny-link{
  max-width: 5em;
}
.blue-header{
  color: var(--blue-87) !important
}
.gray-out-input {
  background-color: #e8e9ed !important;
}
.margin-for-info-popup{
  margin-top: -14em;
  margin-left: 2em;
}
.search-info-button{
  position: relative;
  left:25em;
  bottom: 3em;
}

.minwidth-12{
  min-width: 12em !important;
}

/** Used to define container behavior: width, position: fixed etc... **/

.Toastify .Toastify__toast {
  min-height: 4rem !important;
  font-family: Assistant;
  font-size: 1.5rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: #FFF;
}

.Toastify .Toastify__toast-container--top-center {
  top: 7.5em !important;
  position: absolute !important;
}

.Toastify .Toastify__toast--success {
  width: 40rem;
  height: 7rem;
  border-radius: 5px !important;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
  background-color: #5db431 !important;
}

.Toastify .Toastify__toast--error {
  width: 40rem;
  height: 7rem;
  background: #cc0738 !important;
  border-radius: 5px !important;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
}


/** Classes for the close button. Better use your own closeButton **/
.Toastify .Toastify__close-button {
  color: #FFF;
  opacity: 1;
}

.Toastify .Toastify__toast-icon {
  display: none;
}

.red-font-text {
  color: #ff0000;
}
.medium-light-font-text {
  font-size: 2rem;
  font-weight: 550;
}
.medium-font-text {
  font-weight: 600;
  font-size: 3rem;
}