.account-page {
    .hme-account-subscription-types {
        .hme-section {
            &.hme-section-without-title {
                .hme-title {
                    display: none;
                }
                .hme-account-engineering-settings {
                    .hme-title {
                        display: block;
                        margin-bottom: 8px;
                    }
                }
            }
        }
    }
}
