.hme-grid-filter-cell {
    &.hme-grid-filter-cell-empty {
        display: none;
    }

    &.hme-grid-filter-cell-opened {
        color: #0074ef;
    }

    .hme-grid-filter-cell-icon {
        color: #0074ef;
        margin-top: -4px;
        cursor: pointer;
    }

    .hme-grid-filter-cell-popup {
        position: absolute;
        top: 100%;
        margin-top: 14px;
        left: 4px;
        z-index: 10;
        text-transform: none;
        width: 272px;
        max-height: 225px;
    }
}