.hme-company-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    &.loading {
        display: flex;
    }

    .company-info {
        height: 100%;
        display: flex;
        flex-direction: column;

        &-content {
            overflow: auto;
            height: 100%;
            padding: 30px 48px;
            display: flex;
            flex-direction: column;
        }

        .company-info-sections {
            display: flex;

            .company-info-form-contact-info-section,
            .company-info-form-hme-details-section,
            .company-info-view-contact-info-section,
            .company-info-view-hme-details-section {
                overflow: visible;
                height: 100%;
            }

            .hme-title {
                margin-bottom: 20px;
            }

            .company-info-form-contact-info-section,
            .company-info-view-contact-info-section {
                border-left: 0;
                padding-left: 0;
            }

            .hme-company-info-form-section {
                margin-bottom: 20px;

                .hme-label {
                    margin: 0 0 8px;
                }
            }
        }
    }
}

@import './Mobile.scss';
@import './Tablet.scss';
