@import 'src/mixins/breakpoints.scss';
@import 'src/assets/styles/Variables';

.hme-reset-password {
    width: -webkit-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;

    &__header {
        margin-bottom: 15px;
    }

    &__content {
        display: inline-flex;
        gap: 20px;
        justify-content: center;
    }

    &__info {}

    &__info-title {
        margin-bottom: 10px;

        font-family: $font-family;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: $main-black;
    }

    &__rules {}

    &__form {
        padding: 35px;

        border-radius: 24px;
        background-color: $backgroung-grey;
    }
}

@import './Tablet.scss';
