.hme-sso-management-page{
    padding: 20px 50px;
    &__header{
        display: flex;
        justify-content: space-between;
    }

    &__wrapper,
    &__content {
        height: 100%;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__paginate {
        margin: 20px 0;
    }
    &__list {
        overflow: auto;
        .hme-grid-header .hme-grid-header-row .hme-grid-cell.hme-grid--header-text-transform--none {
            text-transform: none;
        }
    }
}