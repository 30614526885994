.tasks-table-public {
    overflow: auto;
    min-width: 1184px;

    & > .hme-grid {
        min-height: 515px;

        .hme-grid-expanded-row .hme-grid-expanded-content .hme-grid .hme-grid-header {
            top: 76px;
        }
    }

    .apply-job-serial-number.hme-grid-cell {
        .hme-grid-cell-content {
            word-break: break-all;
        }
    }

    .apply-job-store-number {
        .hme-grid-cell-content {
            word-break: break-all;
        }
    }

    .apply-completed-job-cell {
        .hme-grid-cell-content {
            color: #5c6670;
        }
    }

    .apply-job-device-count {
        text-align: center;
    }
    
    .apply-job-device-row-completed {
        .hme-grid-cell .hme-grid-cell-content {
            color: #5c6670;
        }
    }

    .public-apply-device-settings-status-page-target-status-cell {
        .hme-grid-filter-cell-popup {
            left: unset;
            right: 0;
        }
    }

    .tasks-table-public-status-filter-failed {
        color: #FF0000;
    }

    .job-list-paginate {
        margin: 20px 0;

        .hme-label {
            text-transform: none;
            font-weight: normal;
        }
    }
}
