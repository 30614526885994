@import 'src/mixins/breakpoints.scss';

@include before-desktop-only {
    .create-edit-scheduler {
        --sections-gap: 20px;
        --field-gap: 15px;

        &__body {
            padding: 0 15px 50px;
        }

        &__fieldset-title {
            font-size: 18px;
        }

        &__form-wrapper {
            flex-wrap: wrap;
        }

        &__form-fieldset {
            flex-basis: calc(50% - 10px);
            flex-grow: 1;
            min-width: unset;
            max-width: calc(50% - 10px);
            padding-bottom: 0;
            gap: 30px;

            &--3:not(:first-of-type) {
                flex-basis: 100%;
                max-width: 100%;
                padding-left: 0;
                padding-top: var(--sections-gap);

                border-left: none;
                border-top: var(--border);
            }
        }

        &__stores-hierarchy-level {
            order: -1;
            display: flex;
            gap: 8px;

            .hme-single-select .hme-label {
                display: block;
            }

            .hme-tip {
                align-self: center;
                order: 1;

                font-size: 22px;
            }
        }

        &__stores-hierarchy-level &__field-label {
            display: none;
        }

        &__stores-hierarchy-level &__select {
            flex-basis: calc(100% - 30px);
        }

        &__tooltip {
            margin-right: 20px;
        }
    }
}
