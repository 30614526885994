@import 'src/mixins/breakpoints.scss';

@include tablet-only {
    .apply-settings-public-container {
        flex-direction: column;
        height: 100%;
        overflow: hidden;

        .create-apply-device-settings-task-header-row {
            .hme-title {
                font-size: 20px;
                font-weight: 600;
                letter-spacing: -.28px;
                line-height: 1.3;
                margin: 0 0 15px;
            }
        }

        .apply-settings-panel {
            display: flex;
            flex: 1;
            flex-direction: column;
            height: 100%;
            padding: 21px 15px 0;
            overflow: auto;

            .create-apply-device-settings-task-sections {   
                .hme-title {
                    font-size: 18px;
                    line-height: 1.28;
                    letter-spacing: 0.18px;
                    margin: 0 0 15px;
                }
            }

            .btn-group {
                width: 100%;

                .source-device-settings-radio {
                    flex: 1;
                    max-width: 100%;
                }
            }

            .group-setting-inputs {
                max-width: 100%;

                .hme-checkbox-component {
                    width: 49%;
                    margin-left: 1%;
                }
            }

            .hme-tile-list-item__wrapper {
                .hme-tile-list-item__rows__wrapper {
                    .hme-tile-list-item__rows__column {
                        .hme-tile-list__row {
                            .hme-tile-list__row__value {
                                text-overflow: clip;
                                white-space: normal;
                                word-break: break-all; 
                            }
                        }
                    }
                }
            }
        }

        .apply-settings-public-left-section {
            width: 345px;
            padding-bottom: 30px;
            height: auto;

            .hme-input-auto-complete-variant {
                width: 100%;
            }
        }

        .apply-settings-public-right-section {
            width: 100%;
            height: auto;
            overflow: visible;
            border-left: 1px solid #e8e9ed;
            border-top: 0;
            margin-left: 20px;
            min-width: auto;
            padding: 20px 0 0 20px;

            .hme-grid {
                display: none;
            }

            .hme-target-device-list {
                height: 100%;
                overflow: visible;
            }

            .hme-target-device-list__filters-row {
                flex-direction: column;
                margin: 0 0 17px;

                .hme-target-device-list__filters-panel {
                    display: flex;
                    margin: 0 0 17px;

                    .hme-target-device-list__filters-count {
                        display: none;
                    }

                    .hme-target-device-list__hide-disabled {
                        padding: 7px 25px;
                    }
                }

                .hme-input-search-variant {
                    width: 100%;
                }
            }

            .hme-target-device-list__target-count {
                font-size: 24px;
                margin: 1px 25px 0 0;

                .hme-target-device-list__target-count-label {
                    font-size: 18px;
                }
            }

            .hme-grid {
                display: none;
            }

            .hme-tile-list__wrapper {
                padding: 0;

                .hme-grid-filters-count-reset-button {
                    display: none;
                }

                .hme-checkbox-component-disabled {
                    .hme-checkbox-label {
                        color: #a7adba;
                    }
                }
                .hme-tile-list-item__wrapper {
                    &.hme-tile-item-disabled {
                        cursor: default;

                        .hme-tile-list-item__rows__column {
                            .hme-tile-list__row {
                                .hme-tile-list__row__name,
                                .hme-tile-list__row__value {
                                    color: #a7adba;
                                }
                            }
                        }
                    }
                }
            }

            .hme-paginate {
                margin-top: 20px;
            }
        }

        .hme-auto-complete-suggestions {
            min-width: 100%;
            max-height: 480px;
            top: 49px;

            .hme-grid{
                display: none;
            }

            .hme-tile-list {
                padding: 0;
            }

            .hme-tile-list__wrapper {
                padding: 0;
            }

            .hme-tile-list-item__rows__wrapper {
                flex-direction: row;

                .hme-tile-list-item__rows__column {
                    &:first-child {
                        .hme-tile-list__row__name,
                        .hme-tile-list__row__value {
                            position: relative;
                            padding-left: 0;
                            white-space: normal;

                            &::before {
                                display: none;
                            }
                        }
                    }
                }
            }

            .hme-tile-list-item__wrapper {
                padding: 0 15px 15px;
                border-radius: 0;
                box-shadow: none;
                background-image: none;
                margin: 0 0 10px;
                border-bottom: 1px solid #e5e5e5;

                .hme-tile-list__row {
                    flex-direction: column;
                    margin: 0 8px 9px 0;

                    .hme-tile-list__row__name {
                        color: #5c6670;
                        font-size: 14px;
                        line-height: 1.29;
                        margin: 0 0 6px;
                    }
                    .hme-tile-list__row__value {
                        color: #000819;
                        font-size: 16px;
                        line-height: 1.13;
                        white-space: normal;
                        word-break: break-all;
                    }

                    .hme-tile-list__row__name,
                    .hme-tile-list__row__value {
                        padding-left: 15px;
                        position: relative;

                        &::before {
                            content: '|';
                            display: block;
                            position: absolute;
                            top: 0;
                            left: 0;
                        }
                    }
                }

                &.hme-tile-item-disabled {
                    cursor: default;
            
                    .hme-tile-list-item__rows__column {
                        .hme-tile-list__row {
                            .hme-tile-list__row__name,
                            .hme-tile-list__row__value {
                                color: #a7adba;
                            }
                        }
                    }        
                }
            }
        }
        
        .public-apply-settings-footer {
            padding: 20px 23px;
        }
    }  
    
    .hme-select-snapshot-modal-dialog {
        &.modal-dialog {
            .modal-content {
                margin: 0;
                padding: 0;
            }

            .modal-header {
                width: 75px;
                position: absolute;
                top: 14px;
                right: 0;
                z-index: 1;
            }

            .select-snapshot-taskbar {
                display: block;
                margin: 0 75px 22px 0;
                line-height: 1.3;
                padding: 0;

                .hme-title {
                    font-family: Assistant;
                    font-size: 20px;
                    font-weight: 600;
                    letter-spacing: -0.28px;
                }

                .select-snapshot-taskbar-filters-count {
                    display: none;
                }
            }
            .hme-select-snapshot-modal {
                padding: 20px 15px 0 25px;
                min-width: auto;
            }
    
            .device-settings-snapshot-list {
                .hme-grid {
                    display: none;
                }

                .hme-tile-list__wrapper {
                    padding: 0;

                    .hme-tile-list__row {
                        .hme-tile-list__row__value {
                            white-space: normal;
                            word-break: break-all;
                        }
                    }
                }
            }

            .modal-footer {
                z-index: 10;
                box-shadow: 0 -10px 20px 0 rgba(0, 0, 0, 0.16);
                background-color: #ffffff;
                padding: 20px 30px;

                .hme-button-component {
                    width: 100%;
                }
            }
        }
    }

    .hme-tile-list__sort-filter-modal {
        .hme-single-select {
            &__box__text {
                padding-top: 13px;
                font-size: 14px;
                letter-spacing: 1.26px;
                color: #000819;
            }
        }
    }
}
