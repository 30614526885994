.device-settings-snapshots {
    overflow: auto;
    flex: 1;

    .hme-grid {
        min-width: 1970px;

        .hme-grid-row {
            &--set-to-expire {
                background-color: #fff9e6;
                border-bottom: 1px solid lightgray;
            }
        }
    }

    .hme-grid-cell-show-all-content {
        .hme-grid-cell-content {
            word-break: break-word;
        }
    }

    .hme-grid-action-cell {
        .hme-grid-cell-content {
            text-align: center;
        }

        &.hme-grid-empty-cell {
            .hme-grid-cell-content {
                cursor: default;
            }
        }
    }

    .device-settings-snapshots-action-header .hme-grid-cell-content {
        text-align: center;
    }

    .device-settings-snapshots-account-cell .hme-grid-cell-content {
        white-space: nowrap;
    }

    .device-settings-snapshots-created-by-cell .hme-grid-cell-content {
        white-space: nowrap;
    }
}

.hme-snapshot-settings__status__tip {
    max-width: 314px;

    .hme-tooltip-content {
        display: flex;
    }

    .icon-alert {
        content: url('~assets/icons/alert.svg');
        width: 24px;
        height: 24px;
        margin: 2px 8px 0 0;
    }
}

@import './Mobile.scss';
