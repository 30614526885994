@import '../../assets/styles/Variables';

.hme-separator {
    --border-styles: 1px solid #{$separator-color};

    display: inline-block;
    height: 24px;
    border-left: var(--border-styles);

    &--pure {
        height: auto;
    }

    &--horizontal {
        height: auto;
        border-left: none;
        border-bottom: var(--border-styles);
    }
}

@import './variants/title.scss';