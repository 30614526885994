.hme-users__header__wrapper {
    display: flex;
    justify-content: space-between;

    .hme-users__header {
        flex: 1 1;

        .hme-users__header__title-with-filters-count {
            display: flex;
            align-items: center;

                .hme-title {
                    padding-right: 40px;
                }

                .hme-users__header__filters-count {
                    border-left: solid 1px #e5e5e5;;
                    padding-left: 40px;
                }
        }

        .hme-account-email-header {
            margin: 11px 0 0 0;
        }
    }
}
