.token-manager-email-input {
    .hme-auto-complete-suggestions {
        min-width: 280px;
        text-align: center;

        .hme-grid-header {
            display: none;
        }

        &-loader {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            padding-top: 10px;
            padding-bottom: 10px;
    
            text-align: center;
    
            .hme-label {
                text-align: center;
            }
    
            .hme-loader-icon {
                width: 21px;
                height: 21px;
            }
        }
    }
}
