@import 'src/mixins/breakpoints.scss';

@include mobile-only {
  .hme-view-edit-store {
    &__content {
        flex-direction: column;
        gap: 15px;
    }

    &__info {
        border-right: none;
    }

    &__info-item {
        padding-right: 0;

        & > .hme-input-container:last-child {
          border-bottom: 1px #e8e9ed solid;
        }
    }

    &__devices-info {
        padding-left: 0px;
    }
  }
}