.hme-system-status-header {
    margin-bottom: 30px;

    &__container {
        display: flex;
        align-items: center;
        gap: 20px;
    }

    &__title {
        flex: 1;
        height: 100%;
    }

    &__buttons-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        gap: 20px;
        margin-left: auto;

        .hme-button-component {
            font-size: 16px;
        }
    }

    &__modal.modal-dialog.hme-modal-component {
        padding: 20px;

        .modal-header .hme-title {
            text-align: center;
        }
    }
}

@import './Tablet.scss';
@import './Mobile.scss';
