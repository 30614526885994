@import 'src/mixins/breakpoints.scss';

@include before-desktop-only {
    .hme-system-status-header {
        margin-bottom: 20px;
    
        &__container {
            gap: 40px;
        }
    
        &__title {
            font-size: 20px;
            line-height: 26px;
            flex-basis: 50%;
        }
    
        &__buttons-container {
            flex-basis: 50%;
            gap: 15px;

            .hme-button-component {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                min-height: 38px;
                
                text-align: center;
                font-size: 14px;
                line-height: 14px;
                padding: 4px 16px;
            }
        }
    }
}

