@import "../../../assets/styles/Variables";
@import "../../../assets/styles/Responsive";

.modal-dialog.hme-modal-component.confirm-popup.custom-smacktalk-page-confirm-popup {
  & > .modal-content {
    &::before {
        content: url('~assets/icons/successLarge.svg');
        display: flex;
        justify-content: center;
        padding: 0 0 20px 0;
     }
  }
}

.send-smacktalk-message {
  & > .send-smacktalk-message-sections {
    min-height: calc(100vh - 360px);
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    align-items: flex-start;
  }
  & > .hme-save-footer {
    margin-left: -50px;
    width: calc(100% + 75px);
  }
}
@include smaller($l-desktop) {
  .send-smacktalk-message {
    & > .send-smacktalk-message-sections {
      flex-direction: column;
      & > [class*="-section"] {
        padding: 0 15px 30px 15px;
      }
      & > .send-smacktalk-message-1-section, .send-smacktalk-message-2-section, .send-smacktalk-message-3-section {
        width: 100%;
        border-left: unset;
        height: fit-content;
        overflow-y: unset;
        &.hme-section-disabled {
          display: none;
        }
        & > .hme-title {
          display: none;
        }
        & > .hme-multi-select-input-container {
          width: 100%;
        }
      }
    }
    & > .hme-save-footer {
      display: flex;
      justify-content: center;
      margin: 0;
      width: 100%;
      padding: 20px 0;
      & > button.hme-button-component {
        margin: 0 10px;
      }
    }
  }
}
