.hme-modal__filter-component__section-title {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .hme-label {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
    color: #606672;
  }

  .hme-modal__filter-component__section__expand__icon {
    width: 16px;
    height: 16px;
    transform: rotate(180deg);
    color: #606672;

    &.down {
      transform: rotate(0deg);
      color: #186df4;
    }
  }
}
