.hme-calendar-day {
    width: 40px;
    height: 40px;
    font-family: Assistant;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: 0.48px;
    text-align: center;
    color: #000819;
    margin-bottom: 6px;
    padding-top: 11px;
    display: inline-block;
    cursor: pointer;

    &.hme-calendar-day-other-month {
        visibility: hidden;
    }

    &.hme-calendar-day-today {
        background-color: #d9ecff;
        border-radius: 50%;

        &.hme-calendar-day-in-range {
            border-radius: 0;
            background-color: #2c6df4;

            &.hme-calendar-day-start-date {
                border-top-left-radius: 50%;
                border-bottom-left-radius: 50%;
            }

            &.hme-calendar-day-end-date {
                border-top-right-radius: 50%;
                border-bottom-right-radius: 50%;
            }
        }
    }

    &.hme-calendar-day-start-date {
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
    }

    &.hme-calendar-day-end-date {
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
    }

    &.hme-calendar-day-in-range {
        background-color: #2c6df4;
        color: #ffffff;            
    }

    &.hme-calendar-day-disabled {
        color: #a7adba;
        cursor: default;
    }
}
