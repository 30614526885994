.firmware-management {
    &__header {
        margin: 0 0 23px;
        display: flex;
        vertical-align: middle;
        justify-content: space-between;

        &__title-wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex: 1;
        }

        .hme-title {
            display: inline-block;
            flex: none;
            line-height: 1.5;
            margin-right: 20px;
        }

        .hme-input-search-variant {
            width: 350px;
            margin-right: 40px;
        }

        &__filters-count {
            border-left: solid 1px #e5e5e5;;
            padding-left:40px;

            .hme-grid-filters-count-no-filters {
                padding: 10px 0 11px;
            }
        }
    }
}
