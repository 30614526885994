.hme-create-device-upgrade-account-form {
  height: calc(100% - 147px); }
  .hme-create-device-upgrade-account-form .hme-create-device-upgrade-form-section {
    margin: 0 0 15px; }
  .hme-create-device-upgrade-account-form .hme-create-device-upgrade-account-form-input-row {
    display: flex; }
    .hme-create-device-upgrade-account-form .hme-create-device-upgrade-account-form-input-row .hme-input-auto-complete-variant {
      flex: 1; }
    .hme-create-device-upgrade-account-form .hme-create-device-upgrade-account-form-input-row .hme-account-input {
      min-height: auto; }
      .hme-create-device-upgrade-account-form .hme-create-device-upgrade-account-form-input-row .hme-account-input .hme-auto-complete-suggestions {
        min-width: 490px; }
        .hme-create-device-upgrade-account-form .hme-create-device-upgrade-account-form-input-row .hme-account-input .hme-auto-complete-suggestions .hme-grid-header {
          display: none; }
    .hme-create-device-upgrade-account-form .hme-create-device-upgrade-account-form-input-row .hme-tip {
      flex: none;
      font-size: 16px;
      margin-top: 16px;
      margin-left: 12px; }
  .hme-create-device-upgrade-account-form .hme-account-brand {
    margin-top: 20px; }

.hme-create-device-upgrade-account-form-tip {
  width: 410px; }
