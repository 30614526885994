@import 'src/assets/styles/Variables';

.hme-stores-status--public__header {
    display: flex;
    width: 100%;
    align-items: center;

    .hme-search-optionable {
        width: 615px;
        margin-left: 40px;
    }

    &__actions-wrapper,
    &__actions {
        display: flex;
        align-items: center;
    }

    &__actions {
        gap: 40px;
        margin-left: 40px;

        & > * {
            display: flex;
            align-items: center;
            min-height: 48px;
            padding-left: 40px;
        
            border-left: solid 1px #e5e5e5;
        }
    }

    .hme-stores-status--public__header__add-store-btn {
        margin-left: auto;
    }

    .hme-stores-status--public__corporate-stores {
        text-transform: none;
        margin-left: 20px;
    }

    &__sort-btn {
        display: none;
    }

    &__download-stores-btn {
        &.hme-button-disabled-component {
            background: none
        }
    }
}

@import "./Tablet.scss";
@import "./Mobile.scss";
