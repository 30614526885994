@import 'src/mixins/breakpoints.scss';

@include before-desktop-only {
  .hme-tile-list__wrapper {
    padding: 0 15px;

    .hme-tile-list__filters-sort {
      display: flex;
      align-items: baseline;
      justify-content: flex-start;
      margin-bottom: 16px;

      .hme-grid-filters-count {
        align-items: center;
        padding-left: 15px;
        border-left: solid 1px #e5e5e5;

        .hme-grid-filters-count-content {
          display: flex;
          align-items: baseline;

          .hme-grid-filters-count-label {
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: 0.02px;
            color: #a7adba;
          }

          .hme-grid-filters-count-reset-button {
            font-size: 14px;
            line-height: 1.29;
            color: #0074ef;
            margin-left: 15px;
          }
        }
      }

      .hme-tile-list__filters-sort__button {
        min-width: 104px;
        width: auto;
        display: flex;
        justify-content: center;
        padding: 8px 15px;
        border-radius: 20px;
        border: solid 1px #0074ef;
        margin-right: 15px;
        background-color: #fff;
        color: #606672;
        text-transform: uppercase;
      }
    }

    .hme-tile-list__select-all {
        margin: 0 5px 8px;
    }

    .hme-tile-list {
      display: flex;
      flex-direction: column;
      flex: 1 1;
      overflow-y: auto;
      padding: 5px;

      .hme-tile-list__no-records {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Assistant, sans-serif;
        font-size: 16px;
        line-height: 1.5;
        color: #000819;
      }
    }
  }
};

@include tablet-only {
  .hme-tile-list__wrapper {
    .hme-tile-list__filters-sort {
      .hme-tile-list__filters-sort__button {
        margin-right: 25px;
      }

      .hme-grid-filters-count {
        padding-left: 25px;

        .hme-grid-filters-count-content {
          .hme-grid-filters-count-reset-button {
            margin-left: 35px;
          }
        }
      }
    }
  }
}


@include desktop-only {
  .hme-tile-list__wrapper {
    display: none;
  }
}
