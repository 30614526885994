.hme-components {
    .hme-radio {
        cursor: pointer;
        display: inline-block;

        .hme-radio-input {
            width: 1px;
            height: 1px;
            visibility: hidden;
        }

        .hme-radio-icon {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            border: 2px solid #a9aebb;
            display: inline-block;
            vertical-align: middle;
        }

        &.hme-radio-checked .hme-radio-icon {
            border: 5px solid #186df4;
        }

        .hme-label {
            margin-left: 10px;
            font-weight: normal;
            letter-spacing: 1.28px;
            text-transform: none;
            vertical-align: middle;
        }

        &.hme-radio-disabled {
            cursor: default;

            .hme-radio-icon {
                background-color: #e5e5e5;
                opacity: 0.5;
            }

            .hme-label {
                color: #a7adba;
            }
        }
    }
}
