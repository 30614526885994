@import 'src/mixins/breakpoints';

@include mobile-only {
    .hme-certificate-container {
        &.hme-modal-component &__content {
            padding: 40px 20px;
        }

        &__actions {
            flex-direction: column;

            .hme-submit-button-component {
                order: -1;
            }
        }
    }
}
