/*
  CSS Styling for the Get training Component
*/

.get-training-box {
  padding: 0;
}

.get-training-video-box {
  align-items: center;
  background-image: radial-gradient(circle at 50% 50%, var(--white), #fbfbfc 40%, #c7d3d6);
  display: flex;
  height: 176px;
  justify-content: center
}
.get-training {
  width: 100%;
  height: 100%;
}
.get-training-content {
  padding: 17px;
}
.get-training-video-box-titilebox {
  width: 108px;
  height: 54px;
  background-color: #003976;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.get-training-video-box-titlebox-title {
  font-size: 35px;
  color:white;
  font-weight: bolder;
}
.get-training-video-box-titlebox-playicon {
  color: white;
  font-size: 14px;
}
.get-training-content-p {
  font-family: Assistant,Arial,Helvetica,sans-serif;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  color: var(--black);
  letter-spacing: normal;
}
.get-training-link {
  display: block;
  font-family: Assistant,Arial,Helvetica,sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.2px;
  text-align: right;
  color: var(--blue-100);
}
.get-training-link:focus,
.get-training-link:hover,
.get-training-link:active {
  color: var(--blue-100);
  font-size: 16px;
}
.get-training-video-box-titlebox-button {
  position: absolute;
  z-index: 100;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-image: linear-gradient(to bottom, var(--blue-100), var(--blue-75));
}
/*

*/