.dxs-account-header {
  padding: 0 0 23px 0;
  display: flex;
  vertical-align: middle;
  justify-content: space-between;
  margin: 60px 0 40px;

  .dxs-account-header-title-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
  }

  .dxs-account-header-link {
    display: flex;
    align-items: center;

    .hme-button-component {
        margin: 0 0 0 30px;
    }
  }

  .hme-submit-button-component {
    width: 104px;
    height: 48px;
    border: 1px;
    margin: 0;
    border-radius: 0 24px 24px 0;
  }

  .hme-title {
    display: inline-block;
    flex: none;
    line-height: 1.5;
    margin-right: 20px;
  }

  .hme-input-search-variant {
    width: 350px;
    margin-right: 20px;
  }
}
