.text-connect {
    &__sections {
        &__section {
            &__info-message {
                padding: 10px 0;
                font-family: Assistant;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: 1.28px;
                color: #000819;

                &__contact {
                    padding: 0 20px;
                }

                p {
                    margin: 0 0 10px;
                }

                ul {
                    padding: 0 0 0 20px;
                    margin: 0 0 25px;

                    li {
                        margin: 0 0 10px;
                    }

                    strong {
                        display: block;
                        margin: 0 0 3px;
                    }
                }
            }
        }
    }
}
