@import 'src/mixins/breakpoints.scss';

@include before-desktop-only {
    .merge-devices__search-store-form {
        .hme-search {
            width: 100%;
            flex-wrap: wrap;

            div.hme-input-container {
                width: unset;
                flex: 1;
            }
        }
    }
}
