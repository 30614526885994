@import 'src/mixins/breakpoints.scss';

.text-connect {
    &__history {
        &__message {
            display: flex;
            font-family: Assistant, sans-serif;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            text-align: left;

            &__label {
                font-size: 12px;
                line-height: 2;
                letter-spacing: 1.08px;
                color: #828792;
                text-transform: uppercase;
                flex: 0.24;
                margin-right: 10px;
                font-family: Assistant, sans-serif;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                text-align: left;
            }

            &__value {
                font-size: 14px;
                line-height: 1.71;
                letter-spacing: 0.27px;
                color: #000819;
                flex: 1;
                overflow: hidden;
                padding-right: 30px;
            }
        }
    }
}

@include mobile-only {
    .text-connect {
        &__history {
            &__message {
                &__label {
                    flex: 0.4;
                }

                &__value {
                    flex: 0.6;
                    padding-right: 0;
                }
            }
        }
    }
}
