.hme-label {
    font-family: Assistant;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 1.44px;
    text-align: left;
    color: #000819;
    text-transform: uppercase;
    display: inline-block;
}
