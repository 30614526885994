@mixin smaller($breakpoint) {
    @media (max-width: #{$breakpoint}) {
        @content;
    }
}

@mixin larger($breakpoint) {
    @media (min-width: #{$breakpoint}) {
        @content;
    }
}