.partner-api-management-form-page {
    margin-top: 20px;

    .partner-api-management-form-page-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
    }

    .partner-api-management-form-page-sections{
        display: flex;

        .hme-partner-api-management-section__content {
            display: flex;
            flex-direction: column;
        }

        .partner-api-management-form-page-vaio-providers-section {
            max-width: none;
        }
    }

    .hme-title {
        font-family: Assistant;
        font-size: 24px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.24px;
        text-align: left;
        color: #000819;
    }

    .hme-label {
        margin: 0 0 8px;
        font-family: Assistant;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: 1.26px;
        text-align: left;
        color: #606672;
    }

    .url-tooltip {

        display: flex;
        align-items: flex-start;
        width: 345px;
        height: 109px;
        margin: 20px 39.5px 587px 0px;
        padding: 18px 40px 20px 20px;
        border-radius: 5px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        background-color: #186df4;

        .info-icon-svg {
            stroke: white;
            stroke-width: 0%;
            width: 13%; height: auto;
            filter: brightness(5);

        }

        .tooltip-text {
            color: #ffffff;
            margin: 0px;
            margin-left: 8px;
            font-family: Assistant;
            font-size: 15px;
            font-weight: 400;
            font-stretch: normal;
            font-style: normal;
            line-height: 22px;
            letter-spacing: 1.44px;
            text-align: left;
        }

    }

    .partner-api-management-form-page-input {
        margin: 8px 0 0 2px;
        font-family: Assistant;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1.44px;
        text-align: left;
    }
}
.hme-partner-api-management-no-content-found {
    font-family: Assistant;
    font-size: 17px;
    text-align: center;
    color: #000819;
    margin-top: 30px;
}

@import './Tablet.scss';
@import './Mobile.scss';
