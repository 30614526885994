@import 'src/mixins/breakpoints.scss';

@include mobile-only {
    .hme-brand-sharing {
        &__content {
            padding: 20px 15px;
        }
    }

    .hme-save-footer {
        justify-content: center;
        padding: 20px;
    }
}
