#containment-wrapper.sortableboard.hme-new-sortable-board {

    & > #drags-container {
        clear: left;
        padding: 0;
        margin: 0 0 1em 0;
        position: relative;
        & > .table-slots {
            list-style-type: none;
            margin: 0;
            padding: 0;
            width: max-content;
            position: absolute;
            display: flex;
            flex-direction: column;
            & > li:first-child {
                display: none;
            }

            & > .section-title {
                padding: 8px 0px 2px;
                color: #d1cdcd;
                font-family: Assistant;
                font-size: 16px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: 1.44px;
                text-align: left;
                text-transform: uppercase;
            }
        }
        & > .sortable-tables.ui-sortable {
            width: max-content;
            height: max-content;
            & > .sortable-tables.ui-sortable {
                list-style-type: none;
                margin: 0;
                padding: 0;
                width: max-content;
                display: flex;
                flex-direction: column;
                box-sizing: border-box;


            }
            & > li.disabled:first-child {
                display: none;
            }
        }

    }


    .table-slots > li {
        box-sizing: border-box;
        margin: 3px 2px 0px 0;
        padding: 1px;
        width: max-content;
        height: max-content;
        text-align: center;
        position: relative;
        z-index: 200;
    }

    .ui-empty-slot {
        width: 426px !important;
        height: 160px!important;
        margin: 0;
        border-radius: 20px;
        border: none;

        & > .dropdown {
            width: 426px !important;
            height: 160px!important;
            top: 50%;
            transform: translateY(-50%);
            display: flex !important;
            justify-content: center;
            align-items: center;
            border-radius: 20px;
            border: dashed 2px #a7adba;
            background-color: #ffffff;
            & > .btn-secondary.dropdown-toggle {
                font-family: Assistant;
                font-size: 16px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.31;
                letter-spacing: 0.32px;
                text-align: left;
                color: #5c6670;
               & > span {
                   margin: 0 10px 0 0;
               }
            }
            &.show {
                border-radius: 20px;
                border: dashed 2px #0074ef;
                background-color: #ffffff;
                & > .btn-secondary.dropdown-toggle {
                    color: #0074ef;
                }
            }
        }
    }

    .dropdown {
        position: relative;
    }


    .table-slots .dropdown-menu {
        left: 50%;
        transform: translateX(-50%);
    }


    .dropdown-menu {
        position: absolute;
        top: 100% !important;
        left: -84px !important;
        z-index: 1000;
        display: none;
        width: 425px;
        height: max-content;
        margin: 4px 82.5px 15px;
        padding: 8px 0 12px;
        border-radius: 8px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
        background-color: #ffffff;

        &.show {
            position: absolute;
            display: block;
            will-change: inherit !important;
            transform: unset !important;
        }
        & > li.show {
            height: 40px;
            padding: 10px 15px 9px;
            &:hover {
                background-color: #0074ef;
                & > a {
                    font-family: Assistant;
                    font-size: 16px;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.31;
                    letter-spacing: 1.28px;
                    text-align: center;
                    color: #ffffff !important;
                }
            }
        }
    }
}


#containment-wrapper.sortableboard.hme-new-sortable-board > #drags-container > .sortable-tables.ui-sortable> .sortable-tables.ui-sortable > li,
body > li#leaderboard-templates-draggable.dash-table.ui-state-default.ui-draggable {
    margin: 3px 0 0 0;
    padding: 1px;
    width: 430px !important;
    height: 160px !important;
    border-top: none;
    border-right: none;
    border-bottom: none;
    border-left: none;
    font-weight: normal;
    color: #000819;
    &:first-child {
        z-index: 301 !important;
    }
    &:last-child:not(:first-child){
        z-index: 300 !important;
    }
    & > .cont-table {
        border-color: unset;
        color: #000819;
        border: none;
        & > thead {
            background-color: unset;

            & > .hd > th {
                width: 430px;
                height: 40px;
                background-color: #0074ef;
                border-bottom: unset;
                font-family: Assistant;
                font-size: 14px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: center;
                color: #ffffff;
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
            }
        }
        & > tbody {
            background-color: #ffffff;
        }
        & > tbody > tr > td > .col-cont {
            position: relative;
            height: 121px;
            background-color: unset;
            & > .col-slots-cont {
                width: max-content;
                height: max-content;
                top: unset;
                padding: 0;
                list-style-type: none;
                margin-left: unset;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: row;
                & > li {
                    width: 142px;
                    height: 119px;
                    margin: 0;
                    border: 2px dashed #d1cdcd;
                    background-color: #ffffff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    &:first-child {
                        border-bottom-left-radius: 20px;
                        border-right: 1px dashed #d1cdcd;
                        & .dropdown.add-column.show,  & .dropdown.add-column.show > .dropdown-toggle {
                            border-bottom-left-radius: 20px;
                        }
                    }
                    &:last-child {
                        border-bottom-right-radius: 20px;
                        border-left: 1px dashed #d1cdcd;
                        & .dropdown.add-column.show,  & .dropdown.add-column.show > .dropdown-toggle {
                            border-bottom-right-radius: 20px;
                        }
                    }
                    & > .dropdown.add-column {
                        top: unset;
                        left: unset;
                        transform: unset;
                        & > .dropdown-toggle {
                            background-color: #ffffff;
                            &.disabled {
                                color: #d1cdcd;
                            }
                            &::after {
                                display: none;
                            }
                            & > span {
                                font-size: 16px;
                                font-weight: bold;
                                font-stretch: normal;
                                font-style: normal;
                                line-height: 1.31;
                                letter-spacing: 0.32px;
                                text-align: left;
                                color: #5c6670;
                                white-space:normal !important;

                                &:not(.fa) {
                                    font-family: Assistant;
                                    padding: 0 0 0 9px;
                                }
                            }
                        }
                        &.show {
                            width: 142px;
                            height: 119px;
                            border: 2px dashed #0074ef;
                            & > .dropdown-toggle {
                                & > span {
                                    color: #0074ef;
                                }
                            }
                            & > .dropdown-menu.show {
                                top: 116px !important;
                                left: -87px !important;
                                width: 144px;
                            }
                        }
                    }
                }
            }
            & > .sortable-cols.ui-sortable {
                width: max-content;
                height: 119px;
                position: absolute;
                text-align: center;
                margin: 0 auto;
                top: 0;
                background-color: unset;
                & > li {
                    width: 142px;
                    height: 119px;
                    margin: 0;
                    float: none !important;
                    & > table > tbody > tr {
                        & > td {
                            border: 2px solid #efefef;
                        }
                    }
                    &:first-child {
                        & > table {
                            border-collapse: separate;
                            border-bottom-left-radius: 20px;
                        }
                        & > table > tbody > tr {
                            & > td {
                                border: 2px solid #efefef;
                            }
                            &:first-child {
                                & > td {
                                    border-bottom: none;
                                }
                            }
                            &:last-child {
                                & > td {
                                    border-bottom-left-radius: 20px;
                                    border-top: none;
                                }
                            }
                        }
                    }
                    &:nth-child(3) {
                        & > table {
                            border-collapse: separate;
                            border-bottom-right-radius: 20px;
                        }
                        & > table > tbody > tr {
                            & > td {
                                border: 2px solid #efefef;
                            }
                            &:last-child {
                                & > td {
                                    border-bottom-right-radius: 20px;
                                }
                            }
                        }
                    }
                    & > table {
                        & > thead > tr > th {
                            background-color: #c7ccd6;
                            color: #000819 !important;
                            & > .sort-desc {
                                background: none;
                                width: 20px;
                                height: 20px;
                                padding: 0 7px 0 0;
                                & > svg > g {
                                    fill: #606672;
                                }
                            }
                            & > .sort-asc {
                                background: none;
                                width: 20px;
                                height: 20px;
                                padding: 0 7px 0 0;
                                & > svg {
                                    transform: rotate(180deg);
                                }
                                & > svg > g {
                                    fill: #606672;
                                }
                            }

                            & > a {
                                font-family: Assistant;
                                font-size: 14px;
                                font-weight: bold;
                                font-stretch: normal;
                                font-style: normal;
                                line-height: 1.5;
                                letter-spacing: normal;
                                color: #000819 !important;
                            }
                            & > .dropdown.col-settings {
                                width: 27px;
                                padding: 0 0 0 7px;
                                display: block;
                                position: relative;
                                right: 0;
                                top: 1px;
                                float: none;
                                &.show {
                                    display: inline-table;
                                }
                                & > .dropdown-toggle > span::before {
                                    font-size: 16px;
                                    color: #606672;
                                }
                                & > .dropdown-menu.show {
                                    margin: 0;
                                    width: max-content;
                                    & > li {
                                        padding: 9px 0;
                                        & > a {
                                            padding: 3px 15px;
                                            font-size: 15px;
                                            font-weight: normal;
                                            font-stretch: normal;
                                            font-style: normal;
                                            line-height: 1.31;
                                            letter-spacing: 1.28px;
                                        }
                                    }
                                }
                            }
                        }
                        & > tbody > tr {
                            & > td {
                                height: 30px;
                                padding: 0;
                                width: max-content;
                                font-family: Assistant;
                                font-size: 14px;
                                font-weight: normal;
                                font-stretch: normal;
                                font-style: normal;
                                line-height: 1.5;
                                letter-spacing: normal;
                                text-align: center;
                                color: #000819;
                            }
                            &.rshade {
                                background-color: #efefef;
                            }
                        }
                    }
                    & > table.default-sort {
                        background-color: #ffffff;
                        & > tbody > tr >td {
                            background-color: #cedff0;
                            border: 2px solid #ffffff;
                        }
                        & > thead > tr > th {
                            background-color: #1840ba;
                            & > .sort-asc, & > .sort-desc {
                                & > svg > g {
                                    fill: #ffffff;
                                }
                            }
                            & > a {
                                color: #ffffff !important;
                            }
                            & > .dropdown.col-settings {
                                & > .dropdown-toggle > span::before {
                                    color: #ffffff;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .table-toolbar > .remove-widget{
        width: 34px;
        height: 29px;
        background-image: url('~assets/icons/IconCloseSection.svg');
        background-repeat: no-repeat;
        background-position: center;
        transition: none;
        border: none;
        margin: 7px;
    }
}

li[data-colname='StoreRank'].column.hme-leaderboard-draggable-columns {
    margin: 0 1px 0 0;
    padding: 0;
    width: 91px;
    height: 127px;
    text-align: center;
    border: none;
    display: inline-block;
    vertical-align: top;
    z-index: 99999999 !important;
}
