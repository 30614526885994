.hme-company-info .company-info .company-info-form-contact-info-section {
  width: 804px;
  padding-right: 60px; }

.hme-company-info .company-info .company-info-form-contact-info-area {
  display: flex;
  justify-content: space-between; }
  .hme-company-info .company-info .company-info-form-contact-info-area .company-info-form-contact-info-column {
    width: 347px; }
  .hme-company-info .company-info .company-info-form-contact-info-area .hme-company-info-form-area {
    display: flex;
    justify-content: space-between; }
    .hme-company-info .company-info .company-info-form-contact-info-area .hme-company-info-form-area .hme-company-info-form-section {
      width: 48%; }
    .hme-company-info .company-info .company-info-form-contact-info-area .hme-company-info-form-area .hme-company-info-form-section--region {
      flex: 7;
      margin-right: 20px; }
    .hme-company-info .company-info .company-info-form-contact-info-area .hme-company-info-form-area .hme-company-info-form-section--zip {
      flex: 3; }
