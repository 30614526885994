
  .react-autosuggest__container {
    position: relative;
  }
  
  .react-autosuggest__input {
    /* display: block; */
    width: 100%;
    height: 48px;
    border-radius: 24px;
    border: solid 1px #a7adba;
    background-color: #ffffff;
    padding: 1em 1.5em;
    transition: border-radius 250ms ease;
    text-indent: .7em;
  }
  .react-autosuggest__input:focus {
    outline: none;
  }
  .react-autosuggest__container--open .react-autosuggest__input {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 0;
  }
  
  .react-autosuggest__suggestions-container {
    width: 100%;
    visibility: hidden;
    will-change: opacity;
    position: relative;
    overflow: hidden;
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    -webkit-transform: scale(1, 0);
            transform: scale(1, 0);
    opacity: 0;
    transition: all 250ms ease;
  }
  .react-autosuggest__container--open .react-autosuggest__suggestions-container {
    display: block;
    overflow-x: hidden;
    overflow-y: scroll;
    scroll-margin-block-end: 0px;
    max-height: 250px;
    position: absolute;
    top: 3em;
    width: 100%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    visibility: visible;
    opacity: 1;
    box-sizing: border-box;
    border: 1px solid#66afe9;
    border-top: 0;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    border-radius: 2em;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    z-index: 2;
  }
  .react-autosuggest__container--open .react-autosuggest__suggestions-container .suggestion {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .react-autosuggest__container--open .react-autosuggest__suggestions-container .name {
    color: #ccc;
  }
  .react-autosuggest__container--open .react-autosuggest__suggestions-container .username {
    font-size: 0.7em;
    padding: 0.25em 0.5em;
    box-sizing: border-box;
    border-radius: 20px; 
    background: #ccc;
    color: white;
    margin-right: 1em;
  }
  
  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  
  .react-autosuggest__suggestion {
    cursor: default;
    box-sizing: border-box;
    padding: 1em 1.5em;
  }
  .react-autosuggest__suggestion .name b {
    font-weight: bold;
    color: #888;
  }

  .react-autosuggest__suggestion--highlighted .suggestion {
    color: #186df4;
  }
  
  .react-autosuggest__suggestion--highlighted .suggestion b {
    color: #186df4;
  }

  .react-autosuggest__container--open .react-autosuggest__suggestions-container::-webkit-scrollbar {
    display: none;
  }

  .type-ahead-custom-message {
    font-size: 14px;
    font-weight: 100;
  }