.removeAlert {
  color: rgb(212, 27, 62);
}

.leaderboard-group-column-3 .leaderboard-store-selection-border {
  margin-right: 0;
}

.actions-leaderboard {
  min-width: 11.5em;
  margin-left: -1.5em !important;
  padding-left: 0em !important;
}

.leaderboard-paginate {
  margin: 20px 0;
}

.stores-selector-input-input {
  display: flex;
}
.stores-selector-input-input .storeAssignment {
  flex: 0 0 350px;
}

.stores-selector-input__controllers {
  flex: 0 0 20%;
}

.leaderboard-store-selection-border {
  padding: 0 15px;
  margin-top: 1rem;
  overflow: hidden;
}
.leaderboard-store-selection-border .lbGroupTable {
  height: calc(100% - 53px);
  overflow-y: auto;
}

.leaderboard-participating-stores {
  margin-top: 95px;
  display: flex;
  flex-direction: column;
  flex: 0 0 350px;
}
.leaderboard-participating-stores .leaderboard-input-label {
  margin: 0;
}
.leaderboard-participating-stores.hme-participating-stores__without__available__stores {
  margin-top: 14px;
}

.lbGroup--new-groups--controllers {
  padding: 8px 0;
}
