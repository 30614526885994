.hme-snapshot-settings-status-black {
    font-weight: bold;
    color: #000819;
}

.hme-snapshot-settings-status-grey {
    color: #5c6670;
}

.hme-snapshot-settings-status-red {
    color: #ff0000;
}

.hme-snapshot-settings-status--alert {
    display: flex;
    max-width: 80px;
}
