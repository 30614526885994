.create-snapshot {
    .create-snapshot-source {
        display: flex;
        overflow: visible;

        .create-snapshot-device-type,
        .create-snapshot-device-filter {
            width: 50%;
        }
    }
}
