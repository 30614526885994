.hme-awards-resettable-configure {
    width: 960px;
    height: 100%;
    & > .modal-content {
        height: 100%;
        padding: 20px;
        & > .modal-header {
          margin: 0 0 25px 0;
          & > .hme-title {
            font-family: Assistant;
            font-size: 20px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.3;
            letter-spacing: -0.28px;
            text-align: center;
            color: #000819;
          }
        }
        & > .modal-body {
          padding: 0 23px;
          & > .hme-grid {
            & > .hme-grid-header {
              & > .hme-grid-row {
                & > .hme-grid-cell {
                  &.hme-grid-cell-checkbox {
                    order: 2;
                    flex: 1 1;
                  }
                  &:nth-child(2) {
                    flex: 2 1;
                  }
                  &:nth-child(3) {
                    flex: 5 1;
                  }
                  & .hme-grid-cell-content {
                    padding: 0 20px 0 0;
                    .hme-checkbox-component-icon {
                      display: none;
                    }
                  }
                }
              }
            }
            & > .hme-grid-row {
              & > .hme-grid-cell {
                padding: 10px 0;
                font-family: Assistant;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: left;
                color: #000819;
                &.hme-grid-cell-checkbox {
                  order: 2;
                  flex: 1 1;
                  user-select: none;
                }
                & > .hme-grid-cell-content {
                  padding: 0 20px 0 0;
                  & > .hme-checkbox-component {
                    position: relative;
                    display: inline-block;
                    width: 45px;
                    height: 24px;
                    & >input {
                      opacity: 0;
                      width: 0;
                      height: 0;
                    }
                    & > .hme-checkbox-component-icon {
                      display: none;
                    }
/* The slider */
                    & > .hme-checkbox-label {
                      padding: 0 0 0 45px;
                      display: flex;
                      justify-content: flex-start;
                      align-items: center;
                      position: absolute;

                      font-family: Assistant;
                      font-size: 12px;
                      font-weight: bold;
                      font-stretch: normal;
                      font-style: normal;
                      line-height: 2;
                      letter-spacing: 1.08px;
                      text-align: left;

                      cursor: pointer;
                      top: 0;
                      left: 0;
                      right: 0;
                      bottom: 0;
                      background-color: #a9aebb;
                      color: #a9aebb;
                      -webkit-transition: .4s;
                      transition: .4s;
                    }
                    
                    & > .hme-checkbox-label:before {
                      position: absolute;
                      content: "";
                      height: 20px;
                      width: 20px;
                      left: 2px;
                      bottom: 2px;
                      background-color: white;
                      -webkit-transition: .4s;
                      transition: .4s;
                    }
                    
                    & > input:checked + .hme-checkbox-label {
                      background-color: #186df4;
                      color: #186df4;
                    }
                    
                    & > input:focus + .hme-checkbox-label {
                      box-shadow: 0 0 1px #186df4;
                      color: #186df4;
                    }
                    
                    & > input:checked + .hme-checkbox-label:before {
                      -webkit-transform: translateX(22px);
                      -ms-transform: translateX(22px);
                      transform: translateX(22px);
                    }
                    
                    /* Rounded sliders */
                    & > .hme-checkbox-label {
                      border-radius: 24px;
                    }
                    
                    & > .hme-checkbox-label:before {
                      border-radius: 50%;
                    }
                  }
                }
                &:nth-child(2) {
                  flex: 2 1;
                }
                &:nth-child(3) {
                  flex: 5 1;
                }
              }
            }
          }
        }
        & > .modal-footer {
          justify-content: center;
          & > button:first-child {
            margin: 0 20px 0 0;
          }
          & > button:last-child {
            padding: 10px 32px;
          }
        }
    }
}
