.hme-company-info {
    .company-info {
        .company-info-view-contact-info-section {
            width: 345px;
            padding-right: 60px;
        }

        .company-info-view-hme-details-section {
            padding-left: 60px;
        }

        .hme-company-info-view-section {
            border-top: 1px solid #e8e9ed;
            padding: 14px 0;
            margin: 0;

            &:first-child {
                border-top: none;
                padding-top: 0;
            }

            .hme-label {
                font-size: 14px;
                margin-bottom: 8px;
            }
        }
    }
}