.dynamic-home__columns-layout {
    padding: 16px 4px; 
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}
.dynamic-home__columns-layout > div {
    margin: 8px;
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: auto;   
}

@media (min-width: 620px) {
    .dynamic-home__columns-layout{

    }
    .dynamic-home__columns-layout > div {
        margin-bottom: 12px;
        flex: 1;
    }
}
@media (min-width: 840px) {
    .dynamic-home__columns-layout{
        padding: 16px;
    }
    .dynamic-home__columns-layout > div {
        flex: 1 1 300px;
        max-width: 33.3%;
    }

    .dynamic-home__columns-layout--stretched > div {
        max-width: none;
    }
}

@media (min-width: 1170px) {
    .dynamic-home__columns-layout{
        padding: 32px; 
    }
    .dynamic-home__columns-layout > div {
        margin-bottom: 20px;
        flex: 1;
    }
}

