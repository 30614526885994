.hme-brand-sharing__header {
    display: flex;
    width: 100%;
    gap: 40px;

    .hme-title {
        padding-top: 5px;
    }

    &__agreement.hme-button-component.hme-transparent-button-component {
        padding-top: 12px;
        display: inline-block;
    }
}

@import './Mobile.scss';
