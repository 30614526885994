@import 'src/assets/styles/Variables';

.tile-expander {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    padding: 0;
    margin: 0;
    
    background-image: none;
    background-color: transparent;
    border: none;
    
    &__title {
        margin: 0;

        color: $main-black;
        font-family: Assistant, sans-serif;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.5;
    }
    
    &__icon {
        width: 30px;
        height: 30px;
        color: $main-gray;
        
        transition: transform .25s;
        transform: rotateZ(0);
        will-change: transform;

        svg {
            width: inherit;
            height: inherit;
        }
    }

    &--expanded &__icon {
        transform: rotateZ(90deg);
    }      
}
