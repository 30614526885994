.hme-view-role__permissions {
    padding-left: 62px;
    width: max-content;

    .hme-title {
        padding-bottom: 2px;
    }

    .hme-view-role__permissions__select-links {
        margin-bottom: 1px;
    }

    .hme-view-role__permissions__select-links__deselect-all {
        margin-left: 60px;
        position: relative;

        &::before {
            height: 22px;
            position: absolute;
            left: -30px;
            content: " ";
            width: 1px;
            background-color: #e5e5e5;
            top: -2px;
            pointer-events: none;
        }
    }

    .hme-view-role__permissions-section__sections {
        display: flex;
    }

    .hme-view-role__permissions-section {
        width: 405px;

        &:first-of-type {
            border-left: none;
        }
    }
}

@import './Mobile.scss';
