.hme-view-edit-store__add-device-modal {
  .add-store-device {
    display: flex;
    flex-direction: column;
    min-height: 400px;

    .hme-title {
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -0.34px;
      margin: 0 0 30px;
    }

    .hme-section {
      overflow-y: inherit;

      .hme-title {
        font-size: 18px;
        line-height: 23px;
        letter-spacing: 0.18px;
        margin: 0 0 15px;
      }
    }

    .add-store-device__device-type {
      // padding-right: 40px;
      .hme-single-select {
        width: 320px;
      }
    }

    .add-store-device__snapshot-list {
      border: 0;
      padding: 0;
      height: auto;
      overflow: auto;

      .hme-title {
        margin: 0 0 10px;
      }

      .hme-grid {
        height: 368px;
        overflow: auto;

        .hme-grid-cell-content {
            white-space: normal;
            word-break: break-all;
            text-overflow: inherit;
        }
      }

      .hme-paginate {
        padding: 20px 0 5px;
      }

      .hme-grid-filter-cell-popup {
        .hme-grid-filter-cell-popup-body {
          max-height: 150px;
        }
      }
    }
  }

  &.hme-modal-component {
    .modal-footer {
      padding-right: 0;

      .hme-button-component {
        margin-left: 20px;
      }
    }
  }
}
