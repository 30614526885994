.hme-distributors-account {
  width: 100%;
  height: 100%;

  .hme-tab-panel {
    width: 100%;
    height: 100%;
  }

  .hme-tab-panel-content {
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: auto;

    .hme-account-email-header {
      padding: 0;
    }
  }

  &.account-page {
    overflow: hidden!important;
    padding-bottom: 30px;

    .hme-tab-panel-content {
      padding: 0;
    }

    .hme-section {
      padding-bottom: 0;
    }

    .hme-section-md {
      .hme-account-form-area {
        .hme-account-subscription-types {
          margin: 0;
        }

        .hme-section-sm {
          height: initial;
        }
      }
    }
  }

  &.hme-distributors-account--users {
    overflow: hidden;

    .hme-users--distributor-account--actions-cell {
      .hme-grid-cell-content{
        display: flex;
        justify-content: center;
      }

      .hme-users--distributor-account--edit--user {
        padding-right: 20px;
      }

      .hme-users--distributor-account--remove--user {
        padding-left: 20px;
        border-left: solid 1px #d1cdcd;
      }
    }

    .hme-tab-panel-content {
      padding: 0;
    }

    .editUser {
      margin-left: 7.5%;
      margin-right: 7.5%;
    }
  }
}
