@import 'src/mixins/breakpoints';

@include before-desktop-only {
    .hme-contest-view-edit-form__results__rounds {
        .hme-tile-list__wrapper {
            margin-top: 16px;
            padding: 0;
        }
    }
}
