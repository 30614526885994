.action-btn {
    background-color: transparent;
    text-decoration: none;
    color: hsl(214, 86%, 55%);
    border: none;
    font-weight: 500;
    padding: 0;
}

.scheduled-actions {
    display: flex;
    justify-content: center;

    &__edit-icon {
        display: none;
    }
}

.separator {
    color: #d1cece;
    padding-left: 2px;
    padding-right: 2px;
}

.clean-padding {
    padding: 0 !important;
}

.scheduler-page {
    position: relative;    
}

.hme-layout .hme-page .scheduler-page {
    overflow-y: auto;
}

.scheduler-list-page__owner-email-wrapper {
    margin: 0px 40px;
}

.scheduler-page__owner-email-wrapper {
    margin: 0px 30px;
}

.scheduler-container {
    width: 100%;

    &--indented {
        padding: 0 40px;
    }
}


.title {
    font-size: 31px;
    font-weight: bold;
    font-stretch: normal;
    font-family: Assistant;
}

.header-align {
    position: relative;
    left: 30%;
    transform: translateX(-50%);
}

.show {
    display: block
}

.hide {
    display: none;
}

.create-btn {
    border: solid 1px #c7ccd6;
    border-radius: 20px;
    background-image: linear-gradient(to bottom, #ffffff, #ccd3e2);
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.08);
    font-size: 16px;
    margin: 30px 0 20px;
}

.create-btn:hover {
    color: black;
}

.btn-disabled {
    border: solid 1px #c7ccd6;
    border-radius: 20px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.08);
    color: grey;
    cursor: not-allowed;
    font-size: 16px;
    margin: 30px 0 20px;
}

.modal-message {
    line-height: 50px;
}

.leaderboard-participating-stores .leaderboard-input-label {
    margin: 0;
}

.scheduler-input-label-text {
    color: #000819;
}

.leaderboard-group-display {
    margin: 1% 0 0 3rem;
}

.leaderboard-group-columns {
    margin-top: 3rem;
}

.leaderboard-group-column-3 .leaderboard-store-selection {
    margin: 0;
}

.leaderboard-group-column-3 {
    min-width: 500px !important;
}

@import './Tablet.scss';
@import './Mobile.scss';
