.hme-tile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: 340px;
  min-height: 123px;
  margin: 10px 15px;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(to bottom, #ffffff, #ffffff 52%, #f2f2f2);

}