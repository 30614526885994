@import 'src/mixins/breakpoints.scss';

@include mobile-only {
    .hme-manage-hierarchy__header {
        flex-direction: column;

        .hme-title {
            padding-top: 0;

            font-size: 20px;
        }

        &__content-container {
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        &-separator {
            display: none;
        }

        &__user-guide.hme-button-component.hme-transparent-button-component {
            padding: 0;

            font-size: 14px;
            text-align: left;

            margin-bottom: 20px;
        }
    }
}
