@import 'src/mixins/breakpoints.scss';

$button-width: 6rem;
$button-height: 3.5rem;
$font-size: 1.5rem;
$font-family: Assistant;
$button-margin: 1rem;
$button-radius: 20px;
$description-text-color: #606672;

.hme-paginate {
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;
    width: 100%;
    overflow: visible;

    &.hme-paginate--hidden {
        display: none;
    }

    .hme-paginate__dropdown {
        display: flex;
        align-items: center;

        .hme-select {
            height: 40px;
            width: max-content;
            display: inline-block;
            margin: 0 10px;

            &.hme-select-opened {
                .hme-select-text {
                    margin-top: 8px;
                    margin-left: 15px;
                }
            }

            .hme-select-text {
                margin-top: 9px;
                margin-left: 16px;
            }

            .hme-select-icon {
                top: 6px;
            }

            .hme-select-dropdown {
                top: unset;
                bottom: 100%;
            }
        }
    }

    .hme-label {
        font-weight: normal;
        text-transform: none;
        line-height: 1.31;
        letter-spacing: 1.44px;
        color: $description-text-color;
        display: inline-block;
    }

    .hme-paginate__buttons {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    ul.paginate-list {
        display: flex;
        justify-content: center;
        padding: 0;
        margin: 0;

        li {
            display: flex;
            justify-content: center;
            align-items: center;

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                width: $button-width;
                height: $button-height;
                color:  black;
                font-size: $font-size;
                font-family: $font-family;
                font-weight: bold;
                margin: 0 $button-margin 0 0;
                border-radius: $button-radius;
                border: solid 1px #c7ccd6;
                background-image: linear-gradient(to bottom, #ffffff, #ccd3e2);
                user-select: none;
            }

            &.disabled > a {
                width: $button-width;
                height: $button-height;
                color:  #a8aebb;
                border: solid 1px #c7ccd6;
                background-image: linear-gradient(to bottom, #dde1e9, #c7ccd6);
            }

            &.paginate-previous, &.paginate-next {
                position: relative;

                &:before {
                    pointer-events: none;
                    position: absolute;
                    left: 2.4rem;
                }
            }

            &.paginate-active > a {
                width: $button-width;
                height: $button-height;
                color:  black;
                cursor: auto;
                border: solid 1px #c7ccd6;
                background-image: linear-gradient(to bottom, #dde1e9, #c7ccd6);
            }
        }
    }

    .paginate-list-hidden {
        visibility: hidden;
    }

    // for applying mobile styles on desktop view (inside sections limited in size)
    &__mobile {
        flex-direction: column;
        align-items: flex-start;
        background-color: #fff;
        margin: 0;
        padding: 15px;
        border-top: solid 1px #e8e9ed;
        
        .hme-paginate__results {
          order: 1;
          font-size: 13px;
  
          .hme-label {
            font-family: Assistant, sans-serif;
            font-size: 13px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.31;
            letter-spacing: 1.17px;
            text-align: right;
            color: #606672;
            margin-bottom: 12px;
          }
        }
  
        .hme-paginate__buttons {
          width: 100%;
          order: 3;
  
          .paginate-list {
            flex-wrap: wrap;
            flex: 1;
            justify-content: center;
  
            li {
              margin: 5px 0;
  
              a {
                width: 62px;
                margin: 0 5px 0 0;
              }
            }
  
            .fa-arrow-right {
              a {
                margin: 0;
              }
            }
  
            &.hme-paginate__buttons__centred {
              justify-content: center;
            }
          }
  
          .paginate-list-hidden {
            display: none;
          }
        }
  
        .hme-paginate__dropdown {
          order: 2;
          margin-bottom: 12px;
          font-family: Assistant, sans-serif;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.29;
          letter-spacing: 1.26px;
          text-align: left;
          color: #606672;
        }
      }
}

@include before-desktop-only {
    .hme-paginate {
      flex-direction: column;
      align-items: flex-start;
      background-color: #fff;
      margin: 0;
      padding: 15px;
      border-top: solid 1px #e8e9ed;

      .hme-paginate__results {
        order: 1;
        font-size: 13px;

        .hme-label {
          font-family: Assistant, sans-serif;
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.31;
          letter-spacing: 1.17px;
          text-align: right;
          color: #606672;
          margin-bottom: 12px;
        }
      }

      .hme-paginate__buttons {
        width: 100%;
        order: 3;

        .paginate-list {
          flex-wrap: wrap;
          flex: 1;
          justify-content: space-between;

          li {
            margin: 5px 0;

            a {
              width: 62px;
              margin: 0 5px 0 0;
            }
          }

          .fa-arrow-right {
            a {
              margin: 0;
            }
          }

          &.hme-paginate__buttons__centred {
            justify-content: center;
          }
        }

        .paginate-list-hidden {
          display: none;
        }
      }

      .hme-paginate__dropdown {
        order: 2;
        margin-bottom: 12px;
        font-family: Assistant, sans-serif;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: 1.26px;
        text-align: left;
        color: #606672;
      }
    }
}
