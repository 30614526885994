.awards-resettable {
    margin-left: 7em!important;

    & > .Toastify {
        & > .Toastify__toast-container {
            user-select: none;
            width: max-content;
            & > .Toastify__toast > .Toastify__close-button {
                align-self: center;
                align-items: center;
            }
        }
    }
    & > .trophy-case {
        user-select: none;
        & > .hme-tabs-container {
            & > .hme-tabs-container__tabs {
                padding: 0 0 30px 0;
                border: none;
                margin: 0;
                & > .hme-button {
                    height: 30px;
                    margin: 0;
                    padding: 2px 17px;
                    font-family: Assistant;
                    font-size: 15px;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.27;
                    letter-spacing: 0.5px;
                    text-align: center;
                    color: #606672;
                    text-decoration: none;

                    &--bold {
                        height: 30px;
                        margin: 0;
                        padding: 2px 17px;
                        font-family: Assistant;
                        font-size: 15px;
                        font-weight: 600;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.27;
                        letter-spacing: 0.5px;
                        text-align: center;
                        color: #0074ef;
                        border-bottom: 2px solid #0074ef;
                        text-decoration: none;

                    }
                }
            }
            & > .hme-tabs-container__active-tab-content {
                overflow-y: scroll;
                height: calc(100vh - 430px);
                & > .trophy-case__store-cases {
                    & > .trophy-case__store-case {
                        & > .trophy-case__store-case__results {
                            & > .trophy-case__store-case__results__result--summary {
                                font-family: Assistant;
                                font-size: 15px;
                                font-weight: 600;
                                font-stretch: normal;
                                font-style: normal;
                                line-height: 1.27;
                                letter-spacing: 0.5px;
                                text-align: left;
                                color: #606672;
                                margin-bottom: 35px;

                                &:first-child {
                                    position: relative;
                                    & > .contests-substrate {
                                        position: absolute;
                                        display: flex;
                                        justify-content: center;
                                        width: 90px;
                                        top: 110px;
                                        left: 0;
                                    }
                                }
                                &:last-child {
                                    position: relative;
                                    & > .awards-substrate {
                                        position: absolute;
                                        display: flex;
                                        justify-content: center;
                                        width: 90px;
                                        top: 110px;
                                        left: 6px;
                                    }

                                }
                            }
                        }
                    }

                }
            }

        }
    }
}

