#react-confirm-alert>div.react-confirm-alert-overlay.confirm-popup-overlay {
    z-index: 9999!important;
}

div.modal-dialog.hme-modal-component.confirm-popup {
    max-width: 505px;
    min-width: 345px;

    .modal-content {
        padding: 30px 15px;

        &::before {
            content: url('~assets/icons/alert.svg');
            display: flex;
            justify-content: center;
            padding: 0 0 20px 0;
         }

        .modal-header {
            margin: 0;
            padding: 0 0 20px 0;
            button.btn-close {
                display: none;
            }
        }

        .modal-body {
            font-size: 18px;
            font-family: Assistant;
            text-align: center;
            color: #000819;
            padding: 0 30px 30px 30px;
        }

        .modal-footer {
            justify-content: center;
            padding: 0;
        }
    }
}
