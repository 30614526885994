.hme-expand-icon {
    color:#606672;
    display: inline-block;
    cursor: pointer;

    svg {
        transform: rotate(-90deg);
        width: 42px;
        height: 24px;
    }

    &.hme-expand-icon-expanded {
        color: #0074ef;

        svg {
            transform: none;
            width: 24px;
            height: 42px;
        }
    }
}