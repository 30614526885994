.hme-stores-status--admin {
    overflow: auto;
    min-width: max-content;
    height: 100%;
    padding: 44px 57px 39px 37px;
    display: flex;
    flex-direction: column;

    .hme-title {
        margin-bottom: 31px;
    }

    .hme-stores-status--admin__grid {
        flex: 1;
        min-width: 1880px;
        overflow: auto;
    }

    .hme-stores-status--admin__paginate {
        margin-top: 40px;
        flex: 0;
    }

    @media screen and (max-height: 960px) {
        .hme-grid-filter-cell-popup {
            .hme-grid-filter-cell-popup-body {
                max-height: 120px;
            }
        }
    }
}
