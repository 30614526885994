.hme-view-store-settings-task-downloaded-devices {
    display: flex;
    flex-direction: column;
    height: 100%;

    .hme-view-store-settings-task-downloaded-devices-header {
        margin-bottom: 4px;
        flex: none;
    }

    .hme-grid {
        flex: 1;
        overflow-y: auto;
    }
}
