.apply-store-settings-status-upload-error.hme-error-modal.modal-dialog.hme-modal-component {
    .modal-footer {
        .hme-button-component:first-child {
            margin-right: 0;
        }

        .hme-button-component:last-child {
            margin-left: 20px;
        }
    }
}