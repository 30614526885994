@import 'src/mixins/breakpoints.scss';

@include mobile-only {
    .scheduler-page {
        .top-container {
            flex-wrap: wrap;
        }

        &__create-btn {
            flex-basis: 100%;
            margin: 0;

            text-align: center;
        }
    }
}
