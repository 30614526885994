@import url('https://fonts.googleapis.com/css?family=Assistant:400,600,700');
@import url("./IconFont.css");

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.theader th.actcold:after {
  content: " \2193";
}

.theader th.actcol::after {
  content: " ↑";
}
.search_pos .search img {
  cursor:pointer
}

.rc-tree div span.rc-tree-checkbox{
  width: 14px !important;
  height: 14px !important;
}

.rc-tree-list-holder-inner {
  padding: 5px;
}

.AdvancedIE{
 margin: 0px -72px
}

.hme-input label {
  text-transform: capitalize;
  display: block;
}

@media screen and (min-width: 421px) {
  .hidden-xxs-down {
    display: none !important;
  }
}

@media screen and (max-width: 420px) {
  .hidden-xxs-up {
    display: none !important;
  }
}
