@import 'src/mixins/breakpoints.scss';

@include tablet-only {
    .hme-company-info {
        .company-info {
            .company-info-header {
                margin-bottom: 20px;

                .company-info-header-row {
                    margin: 0;
                }

                .hme-title {
                    font-size: 20px;
                    font-weight: 600;
                    letter-spacing: -0.28px;
                }
            }

            .company-info-content {
                padding: 30px 15px;
            }

            .company-info-form-contact-info-section {
                width: 370px;
                padding-right: 22px;
            }

            .company-info-form-contact-info-area {
                flex-direction: column;
            }

            .company-info-sections {
                .hme-title {
                    font-size: 18px;
                    margin: 0 0 15px;
                }
            }
        }
    }
}
