@import 'src/mixins/breakpoints.scss';
@import "src/assets/styles/Variables";

@include tablet-only {
  .hme-layout .hme-page .reports-landing-page {
    padding: 0 20px;
  }

  .reports-landing-page {
    .hme-title {
      font-size: 24px;
    }
    
    .reports-overview {
      flex-direction: column;
      padding-left: 0px;
      
      h3 {
        font-size: 20px;
      }
  
      &__recently-viewed {
        display: flex;
        width: 100%;
        border: 0;
        border-bottom: 1px solid #ccc;
        padding: 16px 0;
      }
      
      &__kpi-recent-activity {
        width: 100%;
        border: 0;
        border-bottom: 1px solid #ccc;
        padding: 16px 0;
      }
    
      &__info {
        width: 100%;
        padding: 16px 0;
      }
    
      &__recent-reports {
        width: 50%;
        border: 0;
        border-right: 1px solid #ccc;
        padding: 0 20px 0 0;      
      }
    
      &__recent-templates {
        width: 50%;
        padding: 0 0 0 30px;
      }
    
      &__report-tiles {
        grid-template-columns: repeat(2, 1fr);
      }
  
      &__tile-title {
        font-size: 20px;
      }
    }
  }
}

@include mobile-only {
  .hme-layout .hme-page .reports-landing-page {
    padding: 0 10px;
  }

  .reports-landing-page {
    .hme-title {
      font-size: 24px;
    }

    .reports-overview {
      flex-direction: column;
      padding-left: 0px;

      h3 {
        font-size: 18px;
      }

      &__recently-viewed {
        width: 100%;
        border: 0;
        border-bottom: 1px solid #ccc;
        padding: 0;
      }
   
      &__kpi-recent-activity {
        width: 100%; 
        border-bottom: 1px solid #ccc;
        padding: 16px;
      }

      &__info {
        width: 100%;
        padding: 20px 0;
      }
    
      &__recent-reports {
        padding: 0;      
      }
    
      &__recent-templates {
        padding: 20px 0 0 0;
      }
    
      &__report-tiles {
        grid-template-columns: repeat(1, 1fr);
      }

      &__template-header {
        font-size: inherit;
      }
    }
  }
}
