@import 'src/assets/styles/Variables';

.hme-change-password-form {
    .hme-input-container {
        margin-bottom: 20px;

        .hme-label {
            white-space: nowrap;
            flex: 1;
            margin-right: 20px;
        }

        .hme-input-wrapper {
            width: 340px;
        }
    }

    &__buttons {
        display: flex;
        justify-content: flex-end;
        gap: 20px;
    }

    // we need to override specificity in pages/ResetPassword.js styles
    .hme-reset-password .hme-reset-password__form &__submit-btn.hme-submit-button-component {
        display: flex;
        margin-left: 0;
    }

    &__loader-wrapper {
        min-width: 115px;
    }

    &__btn-loader {
        width: 23px;
        height: 23px;
        border-top-color: $main-white;
        border-left-color: $main-white;
    }

    &__rule {
        &:not(:last-of-type) {
            margin-bottom: 8px;
        }
    }
}

@import './Tablet.scss';
