@import "../../../../../assets/styles/Variables";
@import "../../../../../assets/styles/Responsive";

.custom-smacktalk-page-messages-landing {
    & > .hme-loader-icon {
        display: none;
    }
    
  & > .hme-messages-landing-container-s {
    display: none;

    & > .hme-create-message-button {
        display: flex;
        padding-bottom: 5px;
        justify-content: center;
    }
  }
}

.hme-messages-landing-popup-backdrop {
  display: none !important;
}
.modal.hme-messages-landing-popup-modal, .modal > .hme-messages-landing-popup-wrapper {
  display: none !important;
}

@include smaller($l-desktop) {
  .custom-smacktalk-page-messages-landing {
      & > .hme-title {
          display: flex;
          justify-content: center;
          align-items: center;
          & > :not(.hme-button-component) {
              display: none;
          }
          & > .hme-button-component {
              margin: 0;
          }
      }
      & > .hme-messages-landing-container {
          display: none;
      }
      & > .hme-loader-icon {
          display: block;
      }
      & > .hme-messages-landing-container-s {
          display: block;
          & > .hme-messages-landing-header-container {
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              & > .hme-tile-rounded-button-component {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                border-radius: 20px;
                border: solid 1px $main-color;
                background-color: $main-white;
                background-image: unset;
                font-family: Assistant;
                font-size: 12px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.75;
                letter-spacing: normal;
                text-align: right;
                color: $main-gray;
                padding: 7px 15px;
                cursor: pointer;
                user-select: none;
                & > svg {
                  color: $main-color;
                }
                &.hme-submit-button-component {
                    background-color: $hme-blue-color;
                    color: $main-white;
                    & > svg {
                        color: $main-white;
                      }
                }
              }
              & > .hme-messages-landing-header-reset {
                font-family: Assistant;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.31;
                letter-spacing: 1.28px;
                text-align: left;
                color: $main-color;
                text-decoration: underline;
                cursor: pointer;
                margin: 0 15px 0 0;
              }
            }
          & > .hme-tiles-container {
              & > .hme-tile {
                  min-height: 74px;
                  & > .hme-manage-messages-tile-inner {
                      & > .category-icon {
                          & > .hme-manage-messages-category {
                              opacity: .5;
                              &.active {
                                  opacity: 1;
                              }
                              & > img {
                                  margin: 0 12px;
                                  padding: 0;
                              }
                          }
                      }
                      & > .manage-messages-data {
                          & > .manage-messages-list {
                              & > div > dd {
                                  margin: 0 12px 0 0;
                              }
                          }
                      }
                  }
                  & > .hme-manage-messages-tile-controls {
                      & > button {
                          display: flex;
                          justify-content: center;
                          align-items: center;
                          & > svg {
                              width: 16px;
                              height: 16px;
                              color: $main-color;
                              margin: 0 5px 0 0;
                          }
                      }
                  }
              }
          }
      }
  }
  .hme-messages-landing-popup-backdrop {
      display: block !important;
  }
  .modal.hme-messages-landing-popup-modal {
      display: flex !important;
      & > .hme-messages-landing-popup-wrapper {
          display: flex !important;
          width: 100%;
          & > .modal-content {
              border-radius: 8px;
              & > .hme-joined-filters-popup {
                  width: 100%;
              }
          }
      }
  }
}
