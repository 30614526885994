.view-device-upgrade-info-details {
    .hme-info-field {
        margin: 0 0 22px;

        &.view-device-upgrade-info-details--device-type {
            text-transform: uppercase;
        }

        &.view-device-upgrade-info-details--upgrade-ran {
            .hme-label {
                margin: 0 0 10px;
            }
            .hme-info-field-content {
                display: flex;
            }
        }

        &.view-device-upgrade-info-details--starting-on {
            padding: 0 0 0 10px;
            margin: 0;

            .hme-label {
                margin: 0;
            }
        }

        &.view-device-upgrade-info-details--time {
            padding: 0 0 0 32px;
            background: url('~images/icon-clock.svg') no-repeat 0 8px;
        }

        .input-datepicker-icon {
            color: #606672;
        }

        .hme-label {
            font-size: 12px;
            line-height: 1.33;
            letter-spacing: 1.08px;
            color: #606672;
        }

        .hme-info-field-content {
            font-size: 15px;
            font-weight: 600;

            .hme-badge-pill {
                background-color: #c7ccd6;
                color: #888b92;
                margin: 7px 8px 7px 0;
            }
        }
    }
}
