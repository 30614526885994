@import 'src/mixins/breakpoints.scss';

@include before-desktop-only {

  .report-header {
    gap: 20px;

    .hme-title {
      font-size: 18px;
    }

    &__additional-link {
      padding: 10px;
    }

  }
  
}
