@import 'src/mixins/breakpoints.scss';

@include before-desktop-only {
    .hme-reset-password {
        width: auto;

        &__header {
            .hme-title {
                font-size: 26px;
                line-height: 1.31;
                letter-spacing: -0.36px;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
        }

        &__info {}
    
        &__rules {}

        &__form {
            display: block;
            width: 100%;
            padding: 20px;
            box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0);
        }
    }

}
