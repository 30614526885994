.hme-view-role__header {
    .hme-title {
        display: inline-block;
    }

    .hme-view-role__header__remove-button {
        margin-left: 80px;
        position: relative;

        &:before {
            border-left: solid 1px #e5e5e5;
            position: absolute;
            left: -41px;
            content: " ";
            height: 41px;
            top: -11px;
        }
    }
}

@import './Mobile.scss';
