.trophy-case .hme-tabs-container__tabs button:first-child {
  margin-left: -0.5em;
}
.trophy-case__store-cases {
  display: flex;
  flex-wrap: wrap;
}
.trophy-case__store-cases--detailed {
  flex-direction: column;
}
.trophy-case .hme-header--tiny {
  max-width: 165px;
  word-break: break-word;
}
.trophy-case__store-case {
  border: 1px solid #bdbdbd;
  padding: 0.5em 1em;
  margin-right: 1em;
  margin-bottom: 1em;
}
.trophy-case__store-case--clickable {
  cursor: pointer;
}
.trophy-case__store-case__results {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1em;
}
.trophy-case__store-case__results__result {
  display: flex;
  align-items: center;
}
.trophy-case__store-case__results__result__info {
  width: inherit;
}
.trophy-case__store-case__results__result--detailed {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 150px;
}
.trophy-case__store-case__results__result--detailed:not(:last-child) {
  margin-right: 2em;
}
.trophy-case__store-case__results__result--detailed img {
  max-width: 60px;
}
.trophy-case__store-case__results__result--summary {
  display: flex;
  align-items: center;
}
.trophy-case__store-case__results__result--summary:not(:last-child) {
  margin-right: 2em;
}
.trophy-case__store-case__results__result--summary img {
  max-width: 90px;
  margin-right: 0.5em;
}
