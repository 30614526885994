.hme-tile-list__sort-filter-modal {
  overflow: hidden;

  .modal-dialog {
    width: calc(100% - 20px);
    height: 100%;

    .modal-content {
      background-color: #ffffff;
      border-radius: 8px;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0,2);
      border: solid 1px #0074ef;
      margin-top: 10px;
      max-height: calc(100% - 20px);

      .modal-header {
        padding: 16px 16px 10px;
        border: none;
        position: absolute;
        top: 0;
        right: 12px;
        background: #fff;
        z-index: 1;

        .btn-close {
          margin: 0;
        }
      }

      .modal-body {
        padding-top: 20px;
        overflow-y: auto;
      }

      .modal-footer {
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        box-shadow: 0 -10px 20px 0 rgba(0, 0, 0, 0.16);
        background-color: #fff;

        .hme-button-component {
          min-width: 100px;
          height: 40px;
          margin: 0 10px;
          overflow: hidden;
        }
      }
    }
  }
}
