.hme-admin-evd-system-status-devices {
  .hme-grid {
    min-width: 1000px;
  }

  .hme-on-latest-version {
    text-transform: uppercase;
  }

  .hme-grid-row {
    padding-left: 0;
    border-bottom: 1px solid #c7ccd6;

    &:last-child {
      border-bottom: 0;
    }

    .hme-upgrade-actions {
      max-width: 180px;

      .hme-grid-cell-content {
        display: flex;
        justify-content: center;
  
        .hme-dropdown {
          width: auto;
  
          .hme-dropdown-text {
            margin-top: 0;
          }
        }
      }
    }
  }

  .hme-grid-header-row {
    .hme-grid-model-name {
      .hme-grid-cell-content {
        padding-left: 0;
      }
    }
  }

  .hme-grid-expanded-content {
    .hme-grid-row {
      border-bottom: 0;

      .hme-grid-cell {
        border-top: 1px solid #c7ccd6;
      }

      .hme-grid-model-name {
        border-top: 0 !important;
        .hme-grid-cell-content {
          padding-left: 0;
          font-weight: normal;
        }
      }
    }
  }

  .hme-grid-expanded-row {
    background-color: #e8f0fe;
  }

  .hme-grid-expanded-parent-row {
    border-bottom: 0;
    background-color: #e8f0fe;
  }

  .hme-grid-model-name {
    font-weight: 600;

    .hme-grid-cell-content {
      padding-left: 0;
    }
  }

  .hme-admin-evd-system-status-devices-headline {
    display: flex;

    .hme-title {
      display: block;
      margin: 0 20px 0 0;
      font-family: Assistant;
      font-size: 18px;
      line-height: 21px;
      font-weight: 600;
      letter-spacing: 0.02px;
      color: #5c6670;
      text-transform: uppercase;
    }

    .hme-admin-system-status-devices-expand-all {
      color: #0074ef;
      font-family: Assistant;
      font-size: 16px;
      line-height: 21px;
      cursor: pointer;

      &::before {
        content: '';
        padding: 0 0 0 20px;
      }
    }
  }

  .hme-dropdown {
    height: auto;
  }

  .hme-button-component.hme-button-disabled-component {
    background-image: none;
  }
}
