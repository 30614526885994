@import '../../../../../assets/styles/_Variables.scss';

.hme-device-settings-snapshots__name-tooltip {
    position: relative;

    .title .icon {
        display: none;
    }

    .hme-tip {
        .icon {
            cursor: pointer;
        }
    }

    &__icon {
        margin-right: 16px;
        mix-blend-mode: darken;
        font-family: $font-family;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.13;
        letter-spacing: normal;
        color: $main-color;
    }

    &__tip {
        max-width: 1110px;
        margin-top: 10px;

        .tooltip-headline {
            margin-bottom: 10px;

            .title {
                strong {
                    font-family: $font-family;
                    font-size: 18px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.33;
                    letter-spacing: normal;
                    color: $main-black;
                }

                .icon {
                    display: none;
                }
            }
        }
    }

    &__grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap:10px;
    }
    &__text {
        margin-bottom: 20px;

        b {
            display: block;
        }
    }
}
@import './Tablet.scss';
@import './Mobile.scss';
