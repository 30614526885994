.hme-admin-system-status-devices {
  .hme-grid {
    min-width: 1000px;
  }

  .hme-on-latest-version {
    text-transform: uppercase;
  }

  .hme-dropdown-list {
    overflow-y: auto;
    overflow-x: hidden;

    .hme-dropdown-list-item {
      padding: 10px 15px;
    }
  }

  .hme-grid-row {
    padding-left: 0;
    border-bottom: 1px solid #c7ccd6;

    &:last-child {
      border-bottom: 0;
    }

    .hme-grid-cell {
      &.hme-grid-serial-col {
        @media screen and (max-width: 1920px) {
          flex: 1.6 1 0% !important;
        }
      }
  }

    .hme-upgrade-actions {

      .hme-grid-cell-content {
        display: flex;
        justify-content: center;

        .hme-dropdown {
          width: auto;

          .hme-dropdown-text {
            margin-top: 0;
          }
        }
      }
    }

    .hme-grid-serial-number {
      position: relative;

      .hme-tip {
        margin-left: 5px;
        position: absolute;
        top: 60%;
        transform: translateY(-50%);
      }

      .hme-tip {
        @media screen and (max-width:1920px) {
          margin-left: 3px;
        }
      }
    }
  }

  .hme-grid-header-row {
    .hme-grid-model-name {
      .hme-grid-cell-content {
        padding-left: 0;
      }
    }
  }

  .hme-grid-expanded-content {
    .hme-grid-row {
      border-bottom: 0;

      .hme-grid-cell {
        border-top: 1px solid #c7ccd6;
      }

      .hme-grid-model-name {
        .hme-grid-cell-content {
          padding-left: 0;
          font-weight: normal;
        }
      }
    }
  }

  .hme-grid-expanded-row {
    background-color: #e8f0fe;
  }

  .hme-grid-expanded-parent-row {
    border-bottom: 0;
    background-color: #e8f0fe;
  }

  .hme-grid-model-name {
    font-weight: 600;

    .hme-grid-cell-content {
      padding-left: 0;
    }
  }

  .hme-admin-system-status-devices-headline {
    display: flex;

    .hme-title {
      display: block;
      margin: 0 20px 0 0;
      font-family: Assistant;
      font-size: 18px;
      line-height: 21px;
      font-weight: 600;
      letter-spacing: 0.02px;
      color: #5c6670;
      text-transform: uppercase;
    }

    .hme-admin-system-status-devices-expand-all {
      color: #0074ef;
      font-family: Assistant;
      font-size: 16px;
      line-height: 21px;
      cursor: pointer;

      &::before {
        content: '';
        padding: 0 0 0 20px;
        border-left: 1px solid #e8e9ed;
      }
    }
  }

  .hme-dropdown {
    height: auto;
  }
}

.hme-admin-system-status-tooltip__tip {
  .tooltip-headline {
    margin: 0;

    .title {
      .icon {
        display: none;
      }
    }
  }
}