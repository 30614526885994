.hme-dxs-feed-modal-dialog {
    .hme-label-error {
        margin-left: 26px;
    }

    .hme-input-auto-complete-variant {
        .hme-auto-complete-suggestions {
            min-width: 456px;
            max-height: 300px;
            overflow: auto;
        }
    }
}
