.hme-search-optionable {
    display: flex;

    .hme-select {
        margin-right: 15px;
        width: max-content;
        display: flex;
        flex-direction: column;
        flex: 0;
        padding-left: 25px;

        &.hme-select-opened {
            padding-left: 24px;
            margin-right: 14px;

            .hme-label {
                margin-left: 0;
                margin-top: 2px;
            }
        }

        .hme-label {
            margin-left: 0;
            white-space: nowrap;
            text-transform: uppercase;
        }

        .hme-select-text {
            margin-left: 0;
            white-space: nowrap;
            width: max-content;
            min-width: 147px;
        }
    }

    .hme-input-search-variant {
        .hme-input-component {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: none;
        }
    }

    .hme-button-component {
        border-radius: 0 24px 24px 0;
        border-left: none;
        height: 50px;
        margin-top: -1px;
        flex: 0;
        word-break: keep-all;
    }
}

@import './Mobile.scss';
