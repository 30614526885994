@import 'src/mixins/breakpoints.scss';

@include before-desktop-only {
    .hme-view-user__header {
        display: flex;

        .hme-title {
            font-size: 20px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.3;
            letter-spacing: -0.28px;
            max-width: max-content;
            flex: 1;
        }

        .hme-view-user__header__remove {
            width: max-content;
            flex: 0;
            height: 26px;
            margin-left: 20px;
            white-space: nowrap;

            &::before {
                display: none;
            }
        }
    }
}
