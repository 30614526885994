.account-page .hme-account-engineering-settings .hme-info-field {
  background: #186df4;
  padding: 18px 20px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  margin-bottom: 20px;
  border-radius: 5px; }
  .account-page .hme-account-engineering-settings .hme-info-field .hme-info-field-content {
    display: flex;
    color: #fff; }
  .account-page .hme-account-engineering-settings .hme-info-field .hme-account-engineering-settings-info-icon {
    margin-right: 20px; }
    .account-page .hme-account-engineering-settings .hme-info-field .hme-account-engineering-settings-info-icon .cls-info-icon {
      fill: #fff; }

.account-page .hme-account-engineering-settings .hme-account-form-columns {
  display: flex;
  justify-content: space-between; }
  .account-page .hme-account-engineering-settings .hme-account-form-columns .hme-label {
    display: block;
    margin-bottom: 18px; }
