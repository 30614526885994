.leaderboard-templates-3-section {
    .hme-leaderboard-subsection-wrapper {
        display: flex;
        gap: 30px;
    }

    .hme-title,
    .hme-toggle,
    .hme-select,
    .hme-input-container {
        margin-bottom: 20px;
    }

    .hme-label,
    .hme-input-container.hme-input-color-picker {
        margin-bottom: 8px;
    }

    .hme-input-container.hme-input-color-picker.disabled-color-picker input {
        background-color: #e5e6ea;
    }

    .hme-leaderboard-subsection-1,
    .hme-leaderboard-subsection-2 {
        min-width: 345px;
        width: 345px;
    }

    & > .hme-input-container {
        & > .hme-input-wrapper {
            display: flex;
            & > .hme-input-component-before {
                display: none;
            }
            & > .hme-input-component {
                font-family: Assistant;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.31;
                letter-spacing: 1.44px;
                text-align: left;
                color: #000819;
            }
            & > .rc-color-picker-wrap {
                position: absolute;
                right: 0;
                top: 0;
                width: 58px;
                height: 47px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-left: 1px solid #a7adba;
                & > .rc-color-picker-trigger {
                    background-color: rgb(0, 176, 76);
                    width: 25px;
                    height: 25px;
                    margin: 0 8px 0 0;
                }
            }
        }
    }
}

@import './Tablet.scss';
@import './Mobile.scss';
