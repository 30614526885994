@import 'src/mixins/breakpoints.scss';

@include before-desktop-only {
    .hme-system-status-info {
        padding: 0;
        padding-right: 20px;
        gap: 15px;
        max-width: 50%;
        min-width: 50%; 

        .hme-title {
            font-size: 16px;
            line-height: 24px;
        }
    }
}