// cc0738
.hme-error-modal.modal-dialog.hme-modal-component {
    .modal-content {
        padding-top: 53px;
        padding-bottom: 43px;
        padding-left: 40px;
        padding-right: 16px;
        width: 500px;
        overflow: hidden;

        .modal-body {
            overflow: hidden;
            display: flex;
            flex-direction: column;
        }

        .hme-error-modal-list {
            padding-right: 17px;
            max-height: 370px;
            overflow: auto;
            flex: 1;

            .hme-error-message {
                margin-bottom: 10px;
            }
        }

        .hme-error-modal-message {
            font-family: Assistant;
            text-align: center;
            font-size: 24px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: 0.24px;
            text-align: center;
            color: #000819;
            padding-right: 24px;
            margin-bottom: 35px;
            flex: none;
        }
    }

    .btn-close {
        display: none;
    }

    .modal-header {
        margin-bottom: 0;
        padding-right: 24px;

        .hme-title {
            .hme-label {
                font-size: 24px;
                line-height: 1.33;
                letter-spacing: 0.24px;
                text-align: center;
                color: #000819;
                text-transform: none;
                width: 100%;
                text-align: center;
            }

            .hme-error-modal-icon {
                display: block;
                color: #cc0738;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 20px;
            }
        }
    }

    .modal-footer {
        padding-right: 0;
        padding-top: 38px;

        .hme-button-component {
            margin-left: auto;
            margin-right: auto;
        }
    }
}