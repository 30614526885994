.hme-button {
  border: none;
  text-transform: capitalize;
  background-color: #ffffff;
  color: #0000ee;
  outline: none;
}
.hme-button:hover, .hme-button:focus, .hme-button:active {
  color: #0000ee;
  text-decoration: underline;
}
.hme-button--primary {
  background-color: rgb(212, 27, 62);
  padding: 0.5em 2em;
  color: #ffffff;
}
.hme-button--secondary {
  background-color: #06B1CB;
  padding: 0.5em 2em;
  color: #F5F5F5;
}
.hme-button--secondary:hover, .hme-button--secondary:focus, .hme-button--secondary:active, .hme-button--primary:hover, .hme-button--primary:focus, .hme-button--primary:active {
  color: #ffffff;
  text-decoration: none;
}
.hme-button__icon {
  margin-right: 0.5em;
  width: 14px;
  height: 14px;
}
.hme-button--disabled, .hme-button:disabled {
  background-color: #bdbdbd;
  color: #F5F5F5;
  pointer-events: none;
}
.hme-button--red {
  background-color: #D91044;
  color: #ffffff;
}
.hme-button--red:hover, .hme-button--red:focus, .hme-button--red:active {
  color: #ffffff;
  text-decoration: none;
  background-color: #F53858;
}
.hme-button--gray {
  background-color: var(--gray-25);
  color: var(--black);
}
.hme-button--gray:focus, .hme-button--gray:hover, .hme-button--gray:active {
  color: var(--black);
  text-decoration: none;
}
.hme-button--gray:hover {
  background-color: var(--gray-12);
  color: var(--black);
}
.hme-button--gray:active {
  background-color: var(--gray-37);
}
.hme-button--dark-blue {
  background-color: var(--blue-87);
  color: #ffffff;
}
.hme-button--dark-blue:focus, .hme-button--dark-blue:hover, .hme-button--dark-blue:active {
  color: #ffffff;
  text-decoration: none;
}
.hme-button--dark-blue:hover {
  background-color: var(--blue-100);
}
.hme-button--dark-blue:active {
  background-color: var(--blue-75);
}
.hme-button--circular {
  margin: 5px;
  min-width: 120px;
  height: 40px;
  border-radius: 35px;
  font-weight: bold;
  padding: 0 30px 0 30px;
}
.hme-button--circular:focus, .hme-button--circular:active {
  outline: none;
}
.hme-button--bold {
  font-weight: bold;
}
