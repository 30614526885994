.source-device-settings-info {
  display: flex;
  padding: 25px 15px;
  background-color: #ffffff; }
  .source-device-settings-info .source-device-settings-info-icon {
    width: 26px;
    margin-right: 15px; }
    .source-device-settings-info .source-device-settings-info-icon .icon-info {
      font-size: 26px; }
  .source-device-settings-info .source-device-settings-info-content {
    flex: 1; }
    .source-device-settings-info .source-device-settings-info-content .source-device-settings-info-list {
      padding-inline-start: 15px;
      margin-bottom: 20px; }
      .source-device-settings-info .source-device-settings-info-content .source-device-settings-info-list:last-of-type {
        margin-bottom: 0; }
    .source-device-settings-info .source-device-settings-info-content .source-device-settings-info-title {
      font-weight: bold; }
