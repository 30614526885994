.hme-file-input {
    border-radius: 20px;
    border: dashed 1px #a7baae;
    padding: 35px 25px 25px 25px;
    text-align: center;
    min-width: 335px;
    width: 100%;

    .hme-label {
        display: block;
        font-size: 24px;
        font-weight: normal;
        line-height: 0.96;
        letter-spacing: 0.24px;
        color: #a7adba;
        text-transform: none;
        text-align: center;
        margin-bottom: 24px;
    }

    .hme-button-component {
        margin-bottom: 36px;
    }

    .hme-file-input-element {
        display: none;
    }
}
