#Content {
    width: 80%;
    margin: 0 auto;
    /* min-width: 1126px; */
}

#Content div.appr h4 {
   padding-left:15px;
}
    #Content .forms {
        margin-bottom: 25px;
        margin-top: 47px;
    }
        #Content .forms div.appr h4 {
            color: #009933;
        }

        #Content .forms h4 {
            color: #b5b5b5;
            font-size: 20px;
            margin-bottom: 22px;
        }
.loaderSection {
    margin:0 auto;
}

.commonLoaderSection{
    margin:0 auto;
    padding:50px;
}

.actionLoaderSection{
    margin:0 auto;
    padding:50px 0px;
}

.actionLoaderSection .loaderTxt {
text-align: left
}

.loaderTxt {
    font-size: 30px;
    margin-bottom: 20px;
}
.loaderNote {
    font-family: Arial;
    font-size: 12px;
    text-align: center;
    margin-top: 23px;
    color: #808080;
}

.errors{   
    background: #f1e1e4 !important;
    border: 1px solid #D41B3E !important;
    font-weight: 300 !important;
    margin-top:  15px;
    /* margin-bottom:  -30px;
    padding-bottom:  0px; */
    font-size: 16px !important;
}

.errors ul li{
    font-size:16px !important;     
 }

 #ErrorContent .errorMessages {
    padding-top: 10px;
} 
#ErrorContent .errorMessages {
    width:60%;
}
#ErrorContent .errorMessages {
    color: #D41B3E;
    font-weight: bold;
    margin-bottom: 0;
    padding-bottom: 10px;
    font-family: Arial;
    font-size: 16px;
}
#ErrorContent .errorMessages h4 {
    font-weight: bold;
    font-size: 16px;
}

#ErrorContent .errorMessages ul {
    list-style: disc inside none;
}
ul > li.error-info-alert {
    text-align: left;
    color: #ffffff;
}
ul > li.error-info-alert::marker {
    content: url("../../assets/icons/infoIcon.svg");
 }
 .info-icon-white-svg {
     stroke: white;
     stroke-width: 0%;
     width: 6%; height: auto;
     filter: brightness(5);
 }

