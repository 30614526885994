.admin-apply-store-settings-taskbar {
    padding: 0 0 23px 0;
    display: flex;
    line-height: 48px;
    vertical-align: middle;

    .hme-title {
        display: inline-block;
        flex: none;
        line-height: 1.5;
    }

    .hme-admin-header-date-filter {
        width: 485px;
        padding-left: 40px;
        padding-right: 30px;
    }

    .admin-apply-store-settings-taskbar-link {
        text-align: right;
        flex: none;
        display: flex;
        justify-content: flex-end;

        .hme-button-component {
            flex: none;
            height: 40px;
        }
    }

    .admin-apply-store-settings-taskbar-filters-count {
        flex: 21;
        padding-left: 30px;
        padding-right: 30px;

        &.admin-apply-store-settings-taskbar-separated {
            border-right: none;
        }
    }
}

