@import 'src/mixins/breakpoints.scss';

@include mobile-only {
  .partner-api-management-form-page {
    height: 100%;
    overflow: scroll;
    padding: 0 0 40px 15px;

    .hme-title {
      font-size: 18px;
    }

    .hme-label {
      font-size: 14px;
    }

    .hme-button-component {
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.32px;
      text-align: center;
    }

    .partner-api-management-form-page-header {
      flex-direction: column;
      align-items: flex-start;

      .hme-button-component {
        width: 95%;
        margin-top: 20px;
      }
    }

    .partner-api-management-form-page-sections{
      flex-wrap: wrap;
      overflow: scroll;

      .partner-api-management-form-page-general-section {
        min-width: 100%;
        border-bottom: 1px solid #e8e9ed;
      }
      .partner-api-management-form-page-send-to-section {
        min-width: 100%;
        border-left: none;
        border-bottom: 1px solid #e8e9ed;
        padding-left: 0;
        margin-bottom: 40px;
      }
      .partner-api-management-form-page-vaio-providers-section {
        max-width: 100%;
      }

      .partner-api-management-form-page-input-value {
        display: block;
        word-wrap: break-word;
      }

    }

    .url-tooltip {
      margin: 0 0 5px 0;
    }

  }
}
