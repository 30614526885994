@import 'src/mixins/breakpoints.scss';

@include mobile-only {
    .leaderboard-templates {
        &-sections--modal {
            flex-wrap: nowrap;
            flex-direction: column;

            .hme-section {
                padding-left: 20px;
                padding-right: 20px;
            }

            .leaderboard-templates-2-section {
                padding-top: 20px;
            }

            .leaderboard-sections-subtitle {
                align-items: baseline;
                margin-bottom: 8px;

                &__highlighted-text {
                    margin-bottom: 0;
                    padding: 1px 2px;
                }
            }

            #containment-wrapper.sortableboard.hme-new-sortable-board > #drags-container > .sortable-tables.ui-sortable > .sortable-tables.ui-sortable > li > .cont-table > tbody > tr > td > .col-cont > .col-slots-cont,
            li#leaderboard-templates-draggable.dash-table.ui-state-default.ui-draggable > .cont-table > tbody > tr > td > .col-cont > .col-slots-cont,
            #containment-wrapper.sortableboard.hme-new-sortable-board > #drags-container > .sortable-tables.ui-sortable > .sortable-tables.ui-sortable {
                width: 100%;
            }

            #containment-wrapper.sortableboard.hme-new-sortable-board > #drags-container > .sortable-tables.ui-sortable > .sortable-tables.ui-sortable > li > .cont-table > tbody > tr > td > .col-cont > .sortable-cols.ui-sortable {
                display: flex;
                width: 100%;
            }

            #containment-wrapper.sortableboard.hme-new-sortable-board > #drags-container > .sortable-tables.ui-sortable > .sortable-tables.ui-sortable > li > .cont-table > tbody > tr > td > .col-cont > .col-slots-cont > li,
            #containment-wrapper.sortableboard.hme-new-sortable-board > #drags-container > .sortable-tables.ui-sortable > .sortable-tables.ui-sortable > li > .cont-table > tbody > tr > td > .col-cont > .sortable-cols.ui-sortable > li {
                width: 100%;
                flex-grow: 1;
            }
        }

        .column.hme-leaderboard-draggable-columns .sort-link {
            width: max-content;
        }

        .leaderboard-templates-draggable__label {
            word-break: break-all;
        }

        .hme-tooltip {
            max-width: calc(100% - 20px);
        }
    }
}
