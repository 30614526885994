@import 'src/mixins/breakpoints.scss';

.hme-distributors-accounts {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 50px 50px 40px 50px;

  .hme-distributors-accounts--paginate {
    margin-top: 40px;
    flex: 0;
    width: 100%;
    min-width: 1279px;
  }
}

@import './Mobile.scss';
