.new-groups,
.existing-groups {
  border: 1px solid #ccc;
  height: 300px;
  box-shadow: 1px 1px 3px #ccc;
  overflow: scroll;
  overflow-x: auto;
  overflow-y: auto;
}

.reportgroup-cancel {
  font-size: 14px !important;
  font-weight: bold;
  color: #D41B3E;
  text-decoration: none;
}

.reportgroup-cancel:hover {
  color: #D41B3E;
  text-decoration: underline;
}

.save-group-btn,
.reportgroup-delete,
.reportgroup-cancel {
  border-radius: 0 !important;
}

.save-group-btn {
  background-color: #d41b3e !important;
  color: #ffffff !important;
  cursor: pointer;
  border-color: #D41B42 !important;
  float: left;
  padding: 10px 25px !important;
}

.move-group-store {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0;
}

.move-group-store div {
    margin: 5px;
}

.reportgroup-buttons {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.move-group-store .btn.btn-secondary,
.move-group-store .btn.btn-secondary:hover,
.move-group-store .btn.btn-secondary:focus {
  border: 1px solid #ccc;
  background-color: transparent;
  color: #000000;
  box-shadow: none;
}

.btn-pipe {
  font-size: 16px;
  padding: 0px 8px;
  font-weight: bold;
}

.group-btns {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 38%;
}

.file {
  position: relative;
  height: 30px;
  width: 100px;
}

.file>input[type="file"] {
  position: absoulte;
  opacity: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0
}

.file>label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #DC143C;
  color: #fff;
  border-radius: 5px;
  line-height: 30px;
  text-align: center;
  cursor: pointer
}

.import {
  padding-top: 30px;
}

.new-msg {
  margin-top: 19px;
  color: #D41B3E
}
