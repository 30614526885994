@import 'src/assets/styles/Variables';

.hme-form-label {
    font-family: $font-family;
    font-size: 16px;
    font-weight: 600;
    color: $main-black;
    text-transform: uppercase;
    letter-spacing: $letter-spacing-144;
}
