@import 'src/mixins/breakpoints.scss';

@include before-desktop-only {
    .scheduler-page {
        .top-container {
            padding: 0 20px;
        }   
    }

    .scheduler-container {
        &--indented {
            padding: 0;
        }
    }

    .scheduled-actions {
        &__edit-icon {
            margin-right: 5px;

            display: unset;
        }
    }

    .separator {
        margin: 0 15px;
    }
}
