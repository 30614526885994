@font-face {
    font-family: 'icon-font';
    src:  url('./assets/fonts/iconFont/icon-font.eot?ei6629');
    src:  url('./assets/fonts/iconFont/icon-font.eot?ei6629#iefix') format('embedded-opentype'),
      url('./assets/fonts/iconFont/icon-font.ttf?ei6629') format('truetype'),
      url('./assets/fonts/iconFont/icon-font.woff?ei6629') format('woff'),
      url('./assets/fonts/iconFont/icon-font.svg?ei6629#icon-font') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icon-font' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-account:before {
    content: "\61";
  }
  .icon-settings:before {
    content: "\62";
  }
  .icon-reports:before {
    content: "\63";
  }
  .icon-log-out:before {
    content: "\64";
  }
  .icon-home:before {
    content: "\65";
  }
  .icon-help:before {
    content: "\66";
  }
  .icon-dashboard:before {
    content: "\67";
  }
  .icon-alert:before {
    content: "\68";
  }
  .icon-error-2:before {
    content: "\69";
  }
  .icon-info:before {
    content: "\6a";
  }
  .icon-warning-fill:before {
    content: "\6b";
  }
  .icon-info-alt:before {
    content: "\6c";
  }
  .icon-success:before {
    content: "\6d";
  }
  .icon-down:before {
    content: "\6e";
  }
  .icon-timer:before {
    content: "\e900";
  }
  .icon-up-strong:before {
    content: "\e901";
  }
  .icon-down-strong:before {
    content: "\e902";
  }
  .icon-calendar:before {
    content: "\6f";
  }
  .icon-error-1-filled:before {
    content: "\70";
  }
  .icon-check-filled:before {
    content: "\71";
  }
  .icon-neutral-filled:before {
    content: "\72";
  }
  .icon-error-1:before {
    content: "\73";
  }
  .icon-right:before {
    content: "\74";
  }
  .icon-left:before {
    content: "\75";
  }
  .icon-close-alt-1:before {
    content: "\76";
  }
  .icon-check:before {
    content: "\77";
  }
  .icon-warning:before {
    content: "\78";
  }
  .icon-error:before {
    content: "\79";
  }
  .icon-group:before {
    content: "\7a";
  }
  .icon-circle:before {
    content: "\41";
  }
  .icon-caret-right:before {
    content: "\42";
  }
  
  