.device-upgrade-list__footer {
  z-index: 1;
  position: relative;
  text-align: right;
  padding: 35px 25px 35px 50px;
  background-color: #ffffff;
  margin-left: -50px;
  margin-right: -25px;
  border-top: 1px solid var(--gray-37); }
  @media screen and (max-width: 1173px) {
    .device-upgrade-list__footer {
      padding-left: 65px;
      padding-right: 40px;
      margin-left: -65px;
      margin-right: -40px; } }
  .device-upgrade-list__footer .device-upgrade-list__paginate {
    margin: 0;
    min-width: auto;
    padding: 0 50px; }
    .device-upgrade-list__footer .device-upgrade-list__paginate.hme-paginate--hidden {
      display: none; }
    .device-upgrade-list__footer .device-upgrade-list__paginate .hme-label {
      color: #606672;
      text-transform: none;
      font-weight: normal; }
    @media screen and (max-width: 1604px) {
      .device-upgrade-list__footer .device-upgrade-list__paginate {
        padding: 0; } }
