@import 'src/assets/styles/Variables';

.hme-system-status-devices-grid {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-left: 30px;

  &.hme-section-without-title .hme-title:not(:empty) {
    display: block;
    margin: 0;

    font-size: 18px;
    font-weight: 600;
    line-height: 1.33;
    letter-spacing: 0.02px;
    color: $light-grey;
    text-transform: uppercase;
  }

  &__headline {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  & &__headline-expand-all {
    padding-left: 20px;

    font-size: 16px;
    color: $main-color;
    border-left: 1px solid $gray-25;
    border-radius: 0;
  }

  .hme-grid {
    min-width: 1000px;
  }

  .hme-grid-row {
    padding-left: 0;
    border-bottom: 1px solid $gray-25;
    
    &:last-child {
      border-bottom: none;
    }
  }

  .hme-grid-cell-expand-icon {
    padding-right: 8px;
  }

  .hme-grid-header-row.hme-grid-row .hme-grid-cell {
    padding-top: 0;
    padding-bottom: 14px;
  }

  .hme-grid-expanded-parent-row,
  .hme-grid-expanded-row {
    background-color: #e8f0fe;
  }

  .hme-grid-expanded-parent-row {
    border-bottom: none;
  }

  .hme-grid-cell .hme-grid-cell-content {
    padding-left: 7px;
  }
  
  .hme-grid-model-name {
    font-weight: 600;

    .hme-grid-cell-content {
      padding-left: 0;
    }
  }

  .hme-grid-expanded-content {
    .hme-grid-row {
      border-bottom: 0;
    }

    .hme-grid-cell-content .hme-grid .hme-grid-cell-content {
      padding-left: 7px;
    }
  }

  .hme-grid-cell {
    &--uppercase {
      text-transform: uppercase;
    }

    &--borderless {
      border-top: none !important; // in order to redefine this rule we need to win in specificity race so it's much easier to use !important
    }
  }

  // START Tile-List styles
  &__tile-list-wrapper {
    padding: 0;
  }

  .hme-tile-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  &__tile {
    display: block;
    min-height: auto;
    padding: 15px;
    margin: 0;
    min-width: unset;
  }

  &__tile-peripheral-device-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  &__tile-peripheral-device {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-top: 15px;

    
    & ~ & {
      border-top: 1px solid #e8e9ed;
      border-top: 0;
    }
  }

  &__tile-item-field {
    display: flex;
    gap: 25px;
  }

  &__tile-item-key,
  &__tile-item-value {
    flex-basis: 45%;
    max-width: 45%;
    min-width: 45%;
    overflow-wrap: break-word;
  }

  &__tile-item-key {
    color: $main-gray;
    font-size: 12px;
    line-height: 24px;
    text-transform: uppercase;
  }
  
  &__tile-item-value {
    color: $main-black;
    font-size: 14px;
  }
  // END Tile-List styles
}

@import './Tablet.scss';
@import './Mobile.scss';
