.hme-create-device-upgrade-form {
    .hme-label {
        font-size: 12px;
        color: #606672;
        margin: 0 0 5px;
    }

    .hme-create-device-upgrade-schedule-date {
        padding: 0 0 20px;
    }

    .hme-create-device-upgrade-form-section {
        margin: 0 0 20px;

        &.hme-validation-error,
        &.hme-input-text-with-remaining {
            margin: 0;
        }

        &.hme-validation-error {
            .hme-remaining-count {
                display: none;
            }
        }

        .hme-label-error,
        .hme-remaining-count {
            padding: 0 26px;
        }

        .hme-input-label-inside-variant {
            .hme-label {
                text-transform: uppercase;
            }

            .hme-input-component-before {
                top: 8px;
            }
        }

        &.hme-create-device-upgrade-form-all-days-upgrade {
            .hme-checkbox-component {
                padding-left: 12px;
            }
        }
    }

    .hme-input-component {
        &:disabled {
            color: #606672;
        }
    }

    .schedule-days {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;

        .hme-checkbox-component {
            border: 1px solid #ccc;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            position: relative;

            .hme-checkbox-component-icon {
                visibility: hidden;
            }

            .hme-checkbox-label {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                text-align: center;
                line-height: 38px;
                margin: 0;
                color: #a7adba;
                text-transform: uppercase;
                cursor: pointer;
            }

            &.hme-checkbox-component-checked {
                background: #186df4;

                .hme-checkbox-label {
                    color: #fff;
                }

                &.hme-checkbox-component-disabled {
                    background: #c7ccd6;

                    .hme-checkbox-label {
                        color: #888b92;
                    }
                }
            }

            &.hme-checkbox-component-disabled {
                .hme-checkbox-label {
                    cursor: default;
                }
            }
        }
    }

    .hme-single-select {
        &.hme-single-select--timer-icon-variant {
            .hme-single-select__box {
                padding: 0 0 0 22px;
                background: url('~images/icon-clock.svg') no-repeat 10px 12px;
            }

            .hme-label {
                left: 48px;
                text-transform: uppercase;
            }
        }
    }

    .hme-input-container.hme-input-disabled-container {
        input.hme-input-component {
            border: 0;
            color: #606672;
        }
    }

    .hme-single-select--disabled {
        .hme-single-select__box__text {
            input {
                color: #606672;
            }
        }
    }

    .hme-single-select.hme-single-select--with-icon-variant {
        .hme-label {
            left: 53px;
        }
    }
}
