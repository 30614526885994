@import 'src/mixins/breakpoints.scss';

@include before-desktop-only {
    .hme-view-role__header {
        margin-bottom: 20px;

        .hme-title {
            font-size: 20px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.3;
            letter-spacing: -0.28px;
        }

        .hme-view-role__header__remove-button {
            margin-left: 20px;
            position: relative;
    
            &:before {
                display: none;
            }
        }
    }
}
