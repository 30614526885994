$main-color: #0074ef;

.hme-filter-multi-popup {
    position: absolute;
    top: 100%;
    margin-top: 14px;
    left: 30px;
    z-index: 10;
    text-transform: none;
    width: 272px;

    background-color: #ffffff;
    padding: 15px 4px 15px 15px;
    border-radius: 8px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
    border: solid 1px $main-color;
    min-width: 272px;
    max-height: 370px;
    width: unset;
    overflow-y: hidden;

    .hme-checkbox-component {
        margin: 0 auto 0 0;
        white-space: nowrap;
        .hme-checkbox-label {
            font-size: 16px;
            line-height: 1.31;
            letter-spacing: 1.28px;
            display: inline-block;
            white-space: nowrap;
        }

        .hme-checkbox-component-icon {
            margin-top: -3px;
        }
    }
    .hme-filter-multi-popup-body {
        overflow-x: hidden;
        overflow-y: scroll;
        max-height: 300px;
        &::-webkit-scrollbar-thumb {
            background-color: $main-color;
            border-radius: 4px;
        }
        &::-webkit-scrollbar {
           width: 6px;
           height: 15px;
        }
    }
    .hme-filter-multi-popup-header {
        font-family: Assistant;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.31;
        letter-spacing: 1.28px;
        text-align: left;
        color: #606672;
        padding-bottom: 18px;

        .hme-filter-multi-popup-close {
            float: right;
            font-size: 26px;
            line-height: 15px;
            color: #5c6670;
            font-weight: 600;
            cursor: pointer;
            margin: 0 15px 0 0;
        }
    }

    .hme-filter-multi-popup-row {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        padding-bottom: 10px;

        &:last-of-type {
            padding-bottom: 0;
        }

        .hme-filter-multi-popup-only-label {
            font-family: Assistant;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.31;
            letter-spacing: 1.28px;
            text-align: left;
            color: $main-color;
            text-decoration: underline;
            padding-left: 15px;
            display: inline-block;
            margin: 0 10px 0 auto;

            span {
                cursor: pointer;
            }
        }

        &.hme-filter-multi-popup-item {
            padding-left: 15px;
        }
    }
}
