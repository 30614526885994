@import 'src/mixins/breakpoints.scss';
@import 'src/assets/styles/Variables';

@include before-desktop-only {
    .hme-store-access {
        position: relative;

        .hme-label {
            position: absolute;
            padding-left: 25px;
            padding-top: 5px;

            font-size: 12px;
            color: $main-gray;
        }

        &__stores {
            padding-top: 25px;
        }
    }
}
