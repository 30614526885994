@import 'src/mixins/breakpoints';

@include before-desktop-only {
    .hme-certificate-container {
        margin: 0 15px;

        &.hme-modal-component &__content {
            margin-top: 0;
        }
    }
}
