.leaderboard-desc-textbox {
  /* height: 150px; */
  width: 100%;
  border-radius: 24px;
  border: solid 1px #a7adba;
  background-color: #ffffff;
  padding: 1em;
}
.leaderboard-desc-textbox:focus{
  outline: none;
  border: solid 2px #0074ef;
}
.leaderboard-name-textbox {
  width: 345px;
  height: 48px;
  margin: 8px 0 0;
  padding: 14px 56px 9px 16px;
  border-radius: 24px;
  border: solid 1px #a7adba;
  background-color: #ffffff;
}
.leaderboard-page-title {
  font-family: Assistant;
  font-size: 31px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.32;
  letter-spacing: -0.43px;
  text-align: left;
  color: #000819;
}
.leaderboard-section-header {
  font-family: Assistant;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.24px;
  text-align: left;
  color: #000819;
}
.leaderboard-text  {
  margin: 0 179px 8px 0;
  font-family: Assistant;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1.44px;
  text-align: left;
  color: #000819;
  white-space: nowrap;
  /* flex: flex-grow !important; */
}
.leaderboard-input-label {
  margin: 0 0 8px 0;
  font-family: Assistant;
  font-size: 16px;
  font-weight: bolder;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1.44px;
  text-align: left;
  color: #000819;
  white-space: normal;
  width: 100%;
  /* flex: flex-grow !important; */
}

.leaderboard-store-selection {
  display: flex;
  margin-left: 15px;
}
.leaderboard-store-selection-border {
  width: 100%;
  height: 270px;
  margin-top: 8px;
  border-radius: 24px;
  border: solid 1px #a7adba;
  background-color: #ffffff;
}
.leaderboard-search-stores {
  width: 450px;
  height: 48px;
  margin: 8px 41px 20px 0;
  padding: 14px 24px 13px 14px;
  border-radius: 24px;
  border: solid 1px #a7adba;
  background-color: #ffffff;
}
.leaderboard-select-move-stores {
  text-align: center;
  top: 200px;
  left: 0;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
}
.leaderboard-select-move-stores .btn.btn-secondary,
.leaderboard-select-move-stores .btn.btn-secondary:hover,
.leaderboard-select-move-stores .btn.btn-secondary:focus {
  border: 1px solid #ccc;
  background-color: transparent;
  color: #000000;
  box-shadow: none;
}
.leaderboard-default-participating-stores {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  flex: 0 0 350px;
}

.hme-leaderboard-participating-stores__with__margin {
  margin-top: 95px;
}

.leaderboard-default-participating-stores .leaderboard-input-label {
  margin: 0;
}

.leaderboard-group {
  margin-left: 0% !important;
  margin-right: 0% !important;
  overflow-x:hidden;
  display: block;
}
div.super-tall {
  height: 650px;
  padding-left: 1em;
  border-left: solid 2px #e8e9ed;
}
.leaderboard-group-display {
  /* margin-left: 0% !important; */
  /* margin-right: 0% !important; */
  overflow-x:auto;
  /* display: block; */
}
.mid-header{
  min-width:7em;
  vertical-align:middle !important;
  padding: 10px;
}
.large-header{
  vertical-align:middle !important;
  padding: 10px;
}
.invite-lb-group-btn {
  float: right;
  margin-top:.3em;
  margin-bottom:.3em;
  white-space: nowrap;
  margin-left:24.5em;
}
.participating-accounts-col {
  font-family: Assistant;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #606672;
}
.bottom-border {
  margin: 10px 0 0 0.5px;
  border-bottom: solid 1px #e8e9ed;
}
.leaderboard-info-text {
  font-family: Assistant;
  font-size: 16px;
  font-weight: 550;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: 1.44px;
  text-align: left;
  color: #000819;
}
.leaderboard-audit-info-text {
  font-size: 14px;
}
.drop-down {
  align-content: center;
}
.padding-top-2 {
  padding-top: 2rem;
}
.padding-bottom-3 {
  padding-bottom: 3rem;
}
.padding-bottom-7 {
  padding-bottom: 7rem;
}
.padding-left-0 {
  padding-left: 0px;
}

.leaderboard-group-columns {
  display: flex;
  flex: 1 1 100%;
  height: 100%;
  min-height: 865px;
  overflow-x: scroll;
  overflow-y: auto;
  margin: 0;
}
.leaderboard-group-column-1 {
  min-width: 400px;
  max-width: 400px;
  flex: 1 1;
}
.leaderboard-group-column-2 {
  min-width: 300px;
  max-width: 300px;
  flex: 1 1;
}
.leaderboard-group-column-3 {
  min-width: 1100px;
  max-width: 1100px;
  flex: 1 1;
}
.no-wrap {
  white-space: nowrap;
}
.stores-transfer-btn {
  width: 40px;
  height: 40px;
  padding: 12px;
  border: solid 1px #c7ccd6;
  background-image: linear-gradient(to bottom, #ffffff, #ccd3e2);
  border-radius: 100%;
}
div.modal-dialog.hme-modal-component.invite.confirm-popup .modal-content::before {
  content: url("../../assets/icons/iconInvite.svg");
  display: flex;
  justify-content: center;
  padding: 0 0 20px 0; }

.invite-text {
  width: 426px;
  height: 46px;
  margin: 20px 0 31px;
  font-family: Assistant;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: 0.18px;
  text-align: center;
  color: #000819;
}
.invite-input-email {
  width: 80%!important;
}
.invite-error-msg {
  padding: 18px 20px 19px 20px;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #cc0738;
  margin: 0 0 0 10px;
  font-family: Assistant;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.54px;
  text-align: left;
  color: #ffffff;
}
.border-red .hme-input-component{
  border-radius: 30px;
  border: solid 2px #cc1838;
  background-color: #ffffff;

}
.border-red .hme-input-component:focus {
  border-radius: 30px;
  border: solid 2px #cc1838;
  background-color: #ffffff;
}
.participating-accounts-row-text {
  width: 87px;
  height: 21px;
  margin: 41px 0 45px;
  font-family: Assistant;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #000819;
}
.center-align{
  text-align: center;
}
.summary-info-container{
  padding-top: 1rem;
  border-top: 1px solid #e8e9ed;
}
.preview-template-link {
  font-family: Assistant;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: left;
  color: #0074ef;
  float: right;
}
.preview-template-link:hover {
  text-decoration: underline;
  cursor: pointer;
}
.preview-icon {
  width: 24px;
  height: 16px;
  margin: 3px 5px 2px 0;
  object-fit: contain;
}
.opacity-50 {
  opacity: 0.50;
}
