.hme-list {
    .hme-label {
        line-height: 1.31;
        letter-spacing: normal;
        margin-bottom: 12px;
        text-transform: none;
    }

    .hme-list-items {
        padding-left: 40px;
        list-style: none;
        margin-bottom: 0;
    }

    .hme-list-item {
        position: relative;
        font-family: Assistant;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.31;
        letter-spacing: normal;
        text-align: left;
        color: #000819;
        margin-bottom: 5px;

        &:last-child {
            margin-bottom: 0;
        }

        &::before {
            content: "";
            position: absolute;
            width: 8px;
            height: 8px;
            background-color: #0074ef;
            border-radius: 50%;
            top: 6px;
            left: -25px
        }
    }
}
