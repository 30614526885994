.hme-file-plain-input {
    &__text-input {
        .hme-input-wrapper {
            display: flex;
            position: relative;

            .hme-file-plain-input__file {
                opacity: 0;
                cursor: pointer;
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                border-radius: 24px;
                z-index: 100;

                &::file-selector-button {
                    cursor: pointer;
                }
            }
        }

        .hme-button-component {
            height: 48px;
            width: max-content;
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            white-space: nowrap;
        }

        .hme-input-component {
            width: unset;
            flex: 1;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
            border-right: none;
        }
    }
}
