@import 'src/mixins/breakpoints.scss';

@include before-desktop-only {
    .leaderboard-templates {
        &-sections--modal {
            flex-wrap: wrap;
            min-height: unset;

            .hme-section {
                min-width: unset;
                max-width: unset;
                padding: 0 25px 20px;

                border-bottom: 1px solid #e8e9ed;

                .hme-title {
                    margin-bottom: 15px;

                    font-size: 20px;
                }
            }

            .hme-toggle {
                margin-bottom: 20px;
            }

            .hme-titled-row-select {
                height: unset;
                min-height: 60px;

                .hme-select {
                    height: 100%;
                    align-items: flex-start;
                }

                .hme-select-dropdown {
                    position: relative;
                    top: 20px;

                    width: 100%;
                    height: unset;
                }

                .hme-select-dropdown-item {
                    min-height: unset;
                }
            }

            .account-nav-dropdown,
            .btn-group.open,
            .dropdown-toggle {
                padding: 5px;
            }

            #containment-wrapper.sortableboard.hme-new-sortable-board .ui-empty-slot,
            #containment-wrapper.sortableboard.hme-new-sortable-board > #drags-container > .table-slots,
            #containment-wrapper.sortableboard.hme-new-sortable-board .ui-empty-slot > .dropdown,
            #containment-wrapper.sortableboard.hme-new-sortable-board > #drags-container > .sortable-tables.ui-sortable > .sortable-tables.ui-sortable > li,
            #containment-wrapper.sortableboard.hme-new-sortable-board > #drags-container > .sortable-tables.ui-sortable > .sortable-tables.ui-sortable > li > .cont-table > thead > .hd > th {
                width: 100% !important;
            }

            #containment-wrapper.sortableboard.hme-new-sortable-board > #drags-container > .sortable-tables.ui-sortable > .sortable-tables.ui-sortable > li > .cont-table > tbody > tr > td > .col-cont > .col-slots-cont > li,
            #containment-wrapper.sortableboard.hme-new-sortable-board > #drags-container > .sortable-tables.ui-sortable > .sortable-tables.ui-sortable > li > .cont-table > tbody > tr > td > .col-cont > .sortable-cols.ui-sortable > li {
                width: 105px;
            }
 
            #containment-wrapper.sortableboard.hme-new-sortable-board > #drags-container > .sortable-tables.ui-sortable {
                width: 100%;                
            }

            .leaderboard-templates-sections--modal #containment-wrapper.sortableboard.hme-new-sortable-board > #drags-container > .sortable-tables.ui-sortable > .sortable-tables.ui-sortable > li > .cont-table > tbody > tr > td > .col-cont > .col-slots-cont > li {
                width: auto;
                flex-grow: 1;
            }
        }

        &-sections--modal &-3-section,
        &-sections--modal .hme-section:last-of-type {
            flex-basis: 100%;
            min-width: unset;
            max-width: unset;
            padding: 20px 25px;

            border-bottom: 0;
        }

        &-sections {
            &__modal-close-btn {
                display: flex;
                flex-basis: 100%;
                justify-content: center;
            }
        }
    }
}
