.hme-device-individual-active-status {
    .hme-status-circle {
        height: 14px;
        width: 14px;
        border-radius: 50%;
        display: inline-block;

        margin-bottom: -2px;
        margin-right: 5px;
    }

    .hme-status-circle--online {
        background-color: #00b107;
    }

    .hme-status-circle--offline {
        background-color: #ff0000;
    }
}
