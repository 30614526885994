.firmware-management-view-wrapper {
    height: 100%;
    min-width: 1380px;

    &.loading {
        display: flex;
    }

    .firmware-management-view {
        height: 100%;
        display: flex;
        flex-direction: column;

        &__content {
            overflow: auto;
            height: 100%;
            padding: 32px 48px 62px 50px;
        }

        &__sections {
            display: flex;
            flex: 1 1 100%;
            min-height: 620px;
        }
    }
}
