.hme-input__char-counter {
  padding-left: 1em;
}
.hme-input__char-counter span:nth-child(2) {
  margin-left: 0.5em;
  padding-left: 0.5em;
  border-left: 1px solid #bdbdbd;
  border-color: #000000;
}
.hme-input__char-counter--hidden {
  display: none;
}
