@import 'src/mixins/breakpoints.scss';

@include mobile-only {
  .admin-users-page {
    .hme-users--list {
      .hme-users__header__wrapper {
        .hme-users__header {
          margin-bottom: 10px;
        }

        .hme-button-component {
          margin-top: 20px;
        }

        margin-bottom: 10px;
      }

      .hme-grid{
        .hme--users--edit-icon {
          display: none
        }
      }

      .users-list-footer {
        width: 100%;

        .hme-paginate {
          border: 0;
        }
      }
    }
  }
}

@include tablet-only {
  .admin-users-page {
    .hme-users--list {
      .hme-users__header__wrapper {
        margin-bottom: 10px;
      }

      .hme-grid{
        .hme--users--edit-icon {
          display: none
        }
      }

      .users-list-footer {
        width: 100%;

        .hme-paginate {
          border: 0;
        }
      }
    }
  }
}

@include before-desktop-only {
  .admin-users-page {
    .hme-users--list {
      height: 100%;
      overflow: auto;
      padding: 0;

      .hme-grid {
        display: block;
      }
    }
  }
}

@include desktop-only {
  .admin-users-page {
    .hme-users--list {
      .hme-users__header__wrapper {
        margin-bottom: 40px;
      }
      .hme-grid{
        .hme--users--edit-icon {
          display: none
        }
      }
    }
  }
};
