.hme-status-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    &__companion {
        --size: 20px;
    }
}
