.hme-contest-view-edit-form__results__rank {
    display: inline-block;
    position: relative;

    &__ribbon {
        --height: 33px;

        width: 29px;
        height: var(--height);
        margin-left: 8px;
        margin-top: calc(var(--height) / 2 * -1);
        position: absolute;
        left: 100%;
        top: 50%;
    }
}

@import 'Tablet.scss';
