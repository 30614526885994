.merge-devices-popup-table {
    table {
        margin: 1.25rem auto;
        text-align: left;
        font-size: 1.5rem;
    }

    td {
        width: 135px;
        line-height: 25px;
        padding-left: 10px;
    }

    .merge-devices-popup-table-cell-description {
        color: #78797c;
        font-size: 1.3rem;
        font-weight: bold;
        padding-left: 30px;
        text-transform: uppercase;
    }
}
