@import 'src/mixins/breakpoints.scss';

@include before-desktop-only {
  .partner-api-management-form-page {

    .partner-api-management-form-page-sections{
      flex-wrap: wrap;

      .partner-api-management-form-page-general-section {
        max-width: 50%;
        margin-bottom: 20px;
        padding-bottom: 20px;
      }
      .partner-api-management-form-page-send-to-section {
        max-width: 45%;
        margin-bottom: 20px;
        padding-bottom: 20px;
      }
      .partner-api-management-form-page-vaio-providers-section {
        border-left: none;
        padding-left: 0;
        margin-bottom: 20px;
        padding-bottom: 20px;
      }

    }

    .url-tooltip {
      width: 95%;
      margin: 0px;
    }

  }
}
