/*
    StoreRankingTile CSS
    Custom Styles for the Store Ranking Tile
*/

.store-ranking-tile-vertical-center {
    display: inline-block;
    vertical-align: middle;
    float: none;
    margin: 2px;
}
.rankrow {
    display: flex;
    align-content: center;
    margin-left: -15px;
    margin-right:-15px;

}
.rankrow col:not(:first-child) {
    padding: 0;
}
.rankrow col:nth-child(2) {
    flex: 1 1 auto;
}
.rankrow col:nth-child(2) p.H4_Left {
    line-height: 1;
}
.rankrow col:nth-child(3) {
    display: flex;
    align-items: center;
}
.store-ranking-inline-goal {
    display: inline-block;
    width: 33.33333%!important;
    max-width: unset;
    padding-left: 0;
    padding-right: 0;
}
.store-ranking-rank-size {
    font-size: 37px;
}