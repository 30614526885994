body .modal {
    display: flex !important;
    margin: 0;
    align-items: center;
    justify-content: center;
    top: 0 !important;
    left: 0;
    transform: none;
    width: 100%;
    z-index: 1050!important;
}

.modal-dialog.hme-modal-component {
    max-width: 90%;
    margin: unset;
    align-self: center;
    overflow: auto;

    .modal-content {
        max-height: 90vh;
        min-height: 270px;
        padding-top: 20px;
        padding-bottom: 20px;
        width: 100%;
    }

    .modal-body {
        padding: 0;
    }

    .modal-header {
        display: block;
        text-align: center;
        border-bottom: none;
        width: 100%;
        margin-bottom: 40px;
        padding: 0;

        .hme-title {
            display: inline-block;
            font-size: 24px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: 0.72px;
            color: #000819;
        }

        .btn-close {
            margin-top: 0;
            margin-right: 20px;
            font-size: 34px;
            font-weight: normal;
            color: #000819;
            padding: 0;
            line-height: 20px;
            opacity: 1;
            float: right;
            transform: scale(0.4);

            &:focus {
                outline: none;
            }

            &:not(:disabled):not(.disabled):focus,
            &:not(:disabled):not(.disabled):hover {
                opacity: 1;
                color: #000819;
            }
        }
    }

    .modal-body {
        overflow: auto;
    }

    .modal-footer {
        border-top: none;
        padding: 20px 30px 0 0;
        display: flex;
        flex-wrap: nowrap;
    }
}
