@import 'src/assets/styles/Variables';

.hme-overlay-loader {
    --padding: 30px;

    position: absolute;
    inset: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(0, 0, 0, 0.3);

    &__wrapper {
        background-color: $main-white;
        padding: var(--padding);
    }
}
