@import url('https://fonts.googleapis.com/css?family=Raleway');

body {
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif !important;
    color: rgb(0, 0, 0) !important;
}
table.user_login {
    border: 1px solid #b9b9b9;
    background-color: #ebebeb;
    margin-left: auto;
    margin-right: auto;
    padding: 0px;
    height: 150px;
    width: 338px;
}
.user_login tbody tr th {
    width: 100%;
    text-align: left;
    padding-left: 8px;
    font-size: 14px;
}

.login_reminder {
    font-style: italic!important;
    max-width: 26em;
    width: 100%;
    margin: 0 auto;
    padding-top: 0;
    font-size: 13px;
    line-height: 1em;
    margin-top: -15px;
}

.rc-time-picker-input[disabled] {
    border-radius: 0 !important;
}
.user_login tbody tr th label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 0px !important;
    font-weight: 700;
    font-weight:lighter;
    font-size: 14px;
}

h5.forgot_up {
    padding: 0px;
}
.forgot_up a{
    text-align: center;
    color: #D41B3E !important;
}

.forgot_up a.hover:hover {
    color: red;
    text-decoration: none;
}

.forgot_up a.hover:hover {
    text-decoration: underline;
}

.user_login tbody tr td {
    text-align: center;
}


.user_login tbody tr {
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

 .user_login tbody tr td {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }


.forms .user_login {
    margin-bottom: 25px;
    margin-top: 40px;
}
.col1 {
    margin-left: auto;
    margin-right: auto;
}

.loginInputs {
    width:197px;
}
.user_login tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
}

.forms .user_login input[type=submit] {
    background-color: #d41b3e;
    border: none;
    float: right;
    color: #ffffff;
    cursor: pointer;
    padding: 10px 25px;
}

.forms .user_login input[type=submit]:disabled {
    background-color: grey;
}

.emptyRequired {
    border: 1px solid #d41b3e !important;
    background-color: rgba(212, 27, 62, 0.1) !important;
}

.center {
    font-family: 'Raleway', sans-serif;
    background-color: #1abc9c;
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card {
    background-color: #fff;
    border-radius: 15px;
    padding: 0.8rem;
}

    .card > form {
        display: flex;
        flex-direction: column;
    }

    .card h1 {
        text-align: center;
        margin-top: 0;
        margin-bottom: 10px;
    }

.form-item {
    font-family: 'Raleway', sans-serif;
    padding: 5px;
    margin-bottom: 2rem;
    height: 30px;
    width: 16rem;
    border: 1px solid grey;
}

.form-submit {
    font-family: 'Raleway', sans-serif;
    height: 35px;
    color: #fff;
    background-color: #1abc9c;
    border: none;
    letter-spacing: 0.2rem;
    transition: 0.3s opacity ease;
    cursor: pointer;
}

    .form-submit:hover {
        opacity: 0.6;
    }


.reports-pane-section {
    width: 100%;
    color: #696969;
}

.reports-header {
    background-color: #F5F5F5;
    padding: 10px;
    font-size: 16px;
    color: rgb(0, 0, 0);
    border: 1px solid #d5d1d1;
    border-bottom: none;
    width:100%;
}

.report-form {
    border: 1px solid #d5d1d1;
    background-color: #EBEBEB;
}

@media screen and (min-width: 768px) {
    .reports-pane:first-child {
        border-right: 1px solid #d5d1d1;
    }
    .reports {
        width: 733px;
        margin-top: 45px;
        margin-bottom: 40px;
        margin-left: auto;
        margin-right: auto;
    }

}
@media screen and (min-width: 993px) {
    .reports {
        width: 733px;
        margin-top: 45px;
        margin-bottom: 40px;
        margin-left: auto;
        margin-right: auto;
    }
}
@media screen and (max-width: 992px) {
    .advancedOptions {
        font-size: 11px !important;
    }
    .reports {
        width: 98%;
        margin-top: 45px;
        margin-bottom: 40px;
        margin-left: auto;
        margin-right: auto;
    }
}
.reports-pane {
    min-height: 500px;
    padding: 20px 25px;
    position: relative;
    float: left;
}

.reports-pane .container.criteria {
    padding-right: 15px !important;
    padding-left: 15px !important;
}

.saved-reports {
    background-color: white;
    color: black;
    min-height: 108px;
    padding: 0px 0px;
    margin: 5px 0px;
    margin-bottom: 20px;
    border: 1px solid #B9B9B9;
    overflow-y: auto;
    height: 108px;
    cursor: pointer;
}

.reports-page-header {
    background-color: white;
    min-height: 48px;
    display: block;
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 10px 0px;
}

.reports-page-header-admin {
    background-color: white;
    padding: 15px 0px;
    display: block;
    width: 80%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 auto;
}

.reports-navigation-header {
    font-size: 14px;
    padding: 0px 10%;
    margin: 0 auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background: #06b1cb;
}

    .reports-navigation-header .nav-items {
        display: flex;
    }

.menu-items {
    text-transform: uppercase;
    text-decoration: none;
    padding: 0px 30px 0px 0px;
    display: flex;
    align-items: center;
}

    .menu-items a {
        text-decoration: none;
        color: #88dde8;
    }
    .cogWheelSection {
        display:flex;
        align-items:center;
        height:100%;
    }
    .cogWheel {
        height: 30px;
        width: 30px;
        cursor:pointer;
    }

.save-template {
    width: 100%;
    height: 35px;
    margin-top: 7px;
}

.generate-reports {
    padding: 12px;
    text-align: center;
    font-size: 16px;
    color: white;
    margin-top: 30px;
    width: 100%;
    background-color: #d41b3e;
    -ms-flex-pack: center;
    cursor: pointer;
    bottom: 25px;
}

.criteria {
    width: 100% !important;
    min-height: 100px;
    padding: 10px 0px !important;
    margin-top: 10px;
    margin-bottom: 18px;
    border: 1px solid #B9B9B9;
    background-color: #f5f5f5;
    color: black;
    font-size: 13px;
}

.time-measures {
    /* width: 100%; */
    position: absolute;
    height: 35px;
    width: 100%;
    padding-left: 8px;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    margin-top: 11px;
    border: 1px solid #c2c2c2 !important;
    font-size: 16px;
    opacity:0;
    z-index:15
}
.selectSpan {
    position: absolute;
    top: 19px;
    z-index: 10;
    left: 10px;
}

@media screen and (min-width: 768px) and (max-width: 840px) {
    .form-control[readonly] {
        width: 95px !important;
    }
    .fr-13 {
        font-size: 13px !important;
    }
}

.form-control[readonly] {
    color: black;
    background: white !important;
    width: 108px;
    margin-left: 5px;
    border-radius: 0px;
}
.checkbox-sections {
    display: flex;
    background: white;
    color: black;
    border: 1px solid #c2c2c2;
    justify-content: space-around;
    height: 35px;
    align-items: center;
    margin: 10px 0px;
    font-size: 12px;
}

.cover-select {
    position: relative;
    height: 50px;
}

.cover-select-image {
    position: absolute;
    width: 100%;
    margin-top: 11px;
    height: 35px;
    border: 1px solid #c2c2c2 !important;
}
.checkbox-sections-advanced {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -ms-flex-align: center;
    align-items: center;
    font-size: 12px;
    margin-bottom: 5px;
}

.logout {
    background-color: #e9e9e9;
    border: 1px solid #b9b9b9;
    /* width: 60px; */
    font-size: 10px;
    font-weight: bold;
    padding: 10px 20px;
    text-align: center;
    margin: 0px 0px 0px 10px;
}

.calendar-section {
    display: flex;
    padding: 0 0 15px 0px;
    margin-bottom: 13px;
    margin-top: 16px;
}

.date-from {
    width: 100%;
}
.date-from span, .date-to  span {
    margin-bottom: 5px;
}
.date-to {
    width: 100%;
}

.date-time input {
    width: 100%;
    height: 30px;
}

.calendar {
    display: flex;
}

.calendar-icon {
    display: flex;
    align-items: center;
    margin: 0px 5px;
}

.timings {
    width: 100%;
    display: flex;
    align-items: center;
 }

 @media screen and (max-width: 1280px) {
    .selectAllLabel{
        padding-left: 33px;
        width: 351px;
    }

    .BrandTitle {
        padding-left: 0px !important;
    }
    span.StoreTitile.level-0 {
        width: 400px;
      }
      span.StoreTitile.level-1 {
        width: 269px;
      }

      span.StoreTitile.level-2 {
        width: 177px;
      }

      span.StoreTitile.level-3 {
        width: 109px;
      }

      span.StoreTitile.level-4 {
        width: 59px;
      }

      span.StoreTitile.level-5 {
        width: 400px;
      }

  }
  @media screen and (max-width: 1415px) and (min-width: 1281px) {
    .selectAllLabel{
        padding-left: 33px;
        width: 361px;
    }
    .BrandTitle {
        padding-left: 0px !important;
    }

    span.StoreTitile.level-0 {
        width: 400px;
      }
      span.StoreTitile.level-1 {
        width: 269px;
      }

      span.StoreTitile.level-2 {
        width: 177px;
      }

      span.StoreTitile.level-3 {
        width: 109px;
      }

      span.StoreTitile.level-4 {
        width: 59px;
      }

      span.StoreTitile.level-5 {
        width: 400px;
      }
  }

@media screen and (max-width: 1540px) and (min-width: 1416px)
{
.selectAllLabel {
    padding-left: 33px;
    width: 325px;
}
.BrandTitle {
    padding-left: 0px !important;
}
span.StoreTitile.level-0 {
    width: 400px;
  }
  span.StoreTitile.level-1 {
    width: 269px;
  }

  span.StoreTitile.level-2 {
    width: 177px;
  }

  span.StoreTitile.level-3 {
    width: 109px;
  }

  span.StoreTitile.level-4 {
    width: 59px;
  }

  span.StoreTitile.level-5 {
    width: 400px;
  }
}

@media screen and (max-width: 1892px) and (min-width: 1541px)
{
.selectAllLabel {
    padding-left: 33px;
    width: 275px;
}
.BrandTitle {
    padding-left: 34px !important;
}

span.StoreTitile.level-0 {
    width: 500px;
  }
  span.StoreTitile.level-1 {
    width: 320px;
  }

  span.StoreTitile.level-2 {
    width: 206px;
  }

  span.StoreTitile.level-3 {
    width: 140px;
  }

  span.StoreTitile.level-4 {
    width: 81px;
  }

  span.StoreTitile.level-5 {
    width: 34px;
  }
}
@media screen and (max-width: 1920px) and (min-width: 1892px)
{
.selectAllLabel {
    padding-left: 33px;
    width: 260px;
}
.BrandTitle {
    padding-left: 40px !important;
}
span.StoreTitile.level-0 {
    width: 500px;
  }
  span.StoreTitile.level-1 {
    width: 320px;
  }

  span.StoreTitile.level-2 {
    width: 206px;
  }

  span.StoreTitile.level-3 {
    width: 140px;
  }

  span.StoreTitile.level-4 {
    width: 81px;
  }

  span.StoreTitile.level-5 {
    width: 34px;
  }
}


.BrandTitle
{
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 5px;
}

    .timings span {
        margin-left: 3px;
    }

.note {
    font-size: 8.5px;
    color: gray;
    margin-left: -2px;
}

span.tip {
    cursor: help;
    display: inline-block;
    width: 12px;
    height: 13px;
    background-color: #c2c2c2;
    border: 1px solid white;
    line-height: 11px;
    color: white;
    font-size: 10px;
    font-weight: bold;
    border-radius: 3px;
    margin-top: 0px;
    margin-left: 3px;
    text-align: center;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

span.tip:hover {
    background-color: #DC4864;
}
.span-heading {
    display: flex;
    align-items: center;
}

.type-sub-section {
    display: flex;
    align-items: center;
}

.timings span.rc-time-picker {
    margin-left: 0px;
    width: 100%;
    margin-right: 25px;
}

.hidden {
    display: none!important;
}

.show {
    display: block!important;
}

.inline-block {
    display: inline-block;
}
.advanced-option-section {
    border: 1px solid #cac7c7;
    margin: 0px -10px;
    padding: 10px;
    z-index: 0;
    min-height: 7px;
}

.rc-time-picker-panel-select {
    overflow-x: hidden;
}

.delete-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #f7cbcb;
    cursor: pointer;
}

input[type=checkbox], input[type=radio] {
    margin: 0 !important;
}


.rc-tree-list-holder-inner div.rc-tree-treenode {
    padding: 5px !important;
}


.rnshadeg {
    background-color: #ffffff;
}

td.repTemplateRow:hover {
    background-color: #c4e9f1;
    cursor: pointer;
}

.rshadeg {
    background-color: #f5f5f5;
}

.alignCenter {
    display: flex;
    align-items: center;
}
.textPaddingLarge {
    padding: 0px 10px;
}
.textPaddingSmall-reports {
    padding: 0px 15px;
    background-color: #EBEBEB;
    color: #d31b3d;
}
.showIcon{
    padding: 0 20px 0 0;
    width:  10px !important;
    background: #EBEBEB;
}
span.textPaddingSmall-reports,
span.showIcon {
    position: relative !important;
    margin: -9px 0 !important;
    top: 0;
}
.__react_component_tooltip {
    width: 250px !important;
    font-size: 12px !important;
    border-radius: 5px !important;
    padding: 10px !important;
    text-align: left !important;
    background-color: #DC4864 !important;
}

.__react_component_tooltip::before {
    background-color: #DC4864 !important;
}

.__react_component_tooltip.type-dark.place-top:after {
    border-top-color: #d41b3e !important;
}

.__react_component_tooltip.type-dark.place-bottom:after {
    border-bottom-color: #d41b3e !important;
}

.__react_component_tooltip.type-dark.place-right:after {
    border-right-color: #d41b3e !important;
}

.__react_component_tooltip.type-dark.place-left:after {
    border-left-color: #d41b3e !important;
}

.advancedOptions {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    position: relative;
     width: 100%;
}
.shortBorder{
    border-top: 1px solid #cac7c7;
}
.container.criteria div {
    font-size: 14px;
    margin-bottom: 5px;
}

.container.criteria div:last-child {
    margin-bottom: 0;
}

.rshadeg {
    background-color: #f5f5f5;
}
.savedName {
padding:5px 5px;
}
.delete-icon {
    padding: 5px 5px;
}

.delete-icon:hover {
    background-color: #c4e9f1;
    cursor: pointer;
}
.savedName:hover {
    background-color: #c4e9f1;
    cursor: pointer;
}


.rnshadebr {
    border-right: 1px solid #f5f5f5;
}

.even {
    background-color: #FFFFFF;
}

.odd {
    background-color: #f5f5f5;
}
.criteriaHeading{
    padding-right: 5px;
}

.toastButtons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
    .toastButtons button {
        margin: 0px 5px;
        color: white !important;
        background-color: rgb(212, 27, 62);
        border: none;
        padding: 5px;
    }
    .toastButtons button a{
        color: white !important;
    }

      .storeWrap span:not(:first-child){
        color:red;
      }
      input.save-template::placeholder, input.save-template::-webkit-input-placeholder, input.save-template::-moz-placeholder, input.save-template::-ms-input-placeholder  {
       font-style: italic;
      }

    .includeCriteria{
        margin-top: 15px;
    }
    .save-template::-webkit-input-placeholder {
        font-style: italic;
        padding-left:3px;
    }
    .save-template:-moz-placeholder {
        font-style: italic;
        padding-left:3px;
    }
    .save-template::-moz-placeholder {
        font-style: italic;
        padding-left:3px;
    }
    .save-template:-ms-input-placeholder {
        font-style: italic;
        padding-left:3px;
    }

    #LoginContent  {
        width: 100%;
    }

    #LoginContent div.appr .error h4 {
        color: #D41B3E;
        font-weight: bold;
        margin-bottom: 0;
        padding-bottom: 0px;
        font-family: Arial;
        font-size: 20px;
    }

    #LoginContent .errorMessages {
        padding-top: 25px;
    }

    #LoginContent .status-messages {
        padding-top: 25px;
    }
    #LoginContent .adminerrorMessages {
        width:60%;
    }
    #LoginContent .adminerrorMessages {
        color: #D41B3E;
        font-weight: bold;
        margin-bottom: 0;
        padding-bottom: 10px;
        font-family: Arial;
        font-size: 16px;
    }
    #LoginContent .adminerrorMessages h3 {
        font-weight: bold;
        font-size: 20px;
    }

    @media only screen and (max-width: 600px) {
        #LoginContent .adminerrorMessages {
           width:100%;
        }
    }

    @media only screen and (max-width: 600px) {
        #LoginContent .adminerrorMessages {
            padding-top: 25px;
         }
    }
