@import '../../assets/styles/Variables';

.hme-multi-select {
    &__container {
        width: 100%;
        position: relative;

        .hme-single-select__box__star {
            display: none;
            position: absolute;
            top: 8px;
            left: 17px;
            font-family: $font-family;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: 1.44px;
            color: #186df4;
            z-index: 2;
        }

        &.hme-multi-select__label-inside-variant {
            .hme-multi-select__labels {
                position: absolute;
                z-index: 2;
                left: 25px;
                top: 4px;
            }

            &.hme-multi-select__search-variant {
                .hme-single-select__box__star {
                    left: 41px;
                }
                .hme-multi-select__labels {
                    left: 49px;
                }
            }
        }
    }

    &__labels {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 5px;

        .hme-label {
            span {
                color: $main-color;
            }
        }
    }

    &__required {
        .hme-single-select__box__star {
            display: inline;
        }
    }
}
