@import 'src/mixins/breakpoints';

@include before-desktop-only {
    .hme-contests {
        .hme-page .hme-page-content {
            overflow-y: auto;
        }

        &__header-title {
            order: -1;
            margin: 0;
        }
        
        &__add-contest {
            order: -1;
        }

        &__content {
            gap: 10px;
            padding: 20px 15px;

            &--reset-filter-shown .hme-grid-filters-count .hme-grid-filters-count-reset-button {
                display: block;
            }
        }
        
        &__header {
            gap: 0;
            flex-wrap: wrap;
            margin-bottom: 0;
        }

        &__list {
            padding: 0;
            overflow-y: unset;
        }
    }
}
