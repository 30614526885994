.create-snapshot {
    .create-snapshot-device-filter {
        padding-left: 20px;

        .create-snapshot-setting-info-form-box {
            margin: 0 0 10px;

            .hme-input-container {
                margin: 0;
            }

            &--serial-number {
                position: relative;
                z-index: 101;
            }

            &--product-id {
                position: relative;
                z-index: 100;
            }
        }

        .device-filter-form-input {
            position: relative;

            .hme-auto-complete-suggestions {
                width: 540px;
                right: 3px;
                left: auto;
                padding: 0;
                max-height: 360px;
                overflow: auto;

                .hme-tile-list {
                    display: none;
                }

                .hme-label {
                    position: static;
                    top: 0;
                    left: 0;
                }

                .hme-auto-complete-suggestions-label {
                    padding-top: 18px;
                }
            }

            &.hme-source-device-account-input {
                z-index: 103;

                .hme-auto-complete-suggestions {
                    width: 348px;

                    .hme-grid-header {
                        display: none;
                    }
                }
            }

            &.hme-source-device-store-input,
            &.hme-source-device-serial-number-input {
                z-index: 104;
            }
        }

        .react-autosuggest__container--open .react-autosuggest__suggestions-container {
            max-height: 100px;
        }

        &--loading {
            text-align: center;
            height: 48px;
            line-height: 48px;
        }

        &--serial-number {
            z-index: 101;
        }

        &--store-number {
            z-index: 102;
        }

        &--account {
            z-index: 103;
        }
    }
}
