@import 'src/assets/styles/Variables';

.hme-new-user {
    min-height: calc(100vh - 250px);

    .hme-layout .hme-page &__page {
        padding-bottom: 30px;
        overflow-y: auto;
    }

    &__content {
        margin-top: 80px;
    }

    &__form {}

    &__checkbox {
        margin-top: 20px;
        margin-bottom: 40px;

        font-size: 16px;
    }

    .hme-reset-password .hme-reset-password__form &__submit-btn.hme-submit-button-component {
        display: flex;
    }

    &__loader-wrapper {
        min-width: 115px;
    }

    &__btn-loader {
        width: 23px;
        height: 23px;
        border-top-color: $main-white;
        border-left-color: $main-white;
    }
}

@import './Tablet.scss';
