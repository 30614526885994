@import 'src/mixins/breakpoints.scss';
@import 'src/assets/styles/Variables';

@include before-desktop-only {
    .hme-login-details {
        border-bottom: 1px solid #e8e9ed;

        &__password-field {
        }

        &__password-btn.hme-button-component {
            top: -2px;
            padding: 20px;
            padding-right: 0;

            font-size: 14px;
        }

        
        &__password {
            .hme-label {
                font-size: 14px;
                color: $main-gray;
            }
            .hme-account-input {
                min-height: 55px;
            }
        }


        &__password-modal .hme-modal-component {
            .modal-content {
                margin-top: 0;
                padding-bottom: 60px;
            }

            .modal-header {}
        }

        &__password-modal-dialog {}
    }
}
