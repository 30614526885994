.hme-create_edit-sso-management-page {
    display: flex;
    flex-direction: column;
    overflow-y: auto!important;
    &__header{
        display: flex;
        justify-content: space-between;
    }
}
.hme-tab-panel-wrapper{
    padding: 20px 50px;
    height: 100%;
}
.sso-management-form-container{
    display: flex;
    justify-content: flex-start;
    .sso-form-input{
        margin: 0 0 20px 10px;
        .hme-label{
            margin-bottom: 8px;
        }
        input:disabled{
            background-color: #f5f5f5;
            cursor: not-allowed;
        }
    }
    .hme-section-sm {
        &_section-1{
            border-right: 1px solid #e8e9ed;
        }
        &_section-2{
            margin-left:50px;
            width: 33%;
            .sso-form-input{
                .hme-email-type-ahead-label{
                    .hme-asterisk{
                        color: #186df4;
                    }
                }
            }
        }
        padding: 20px;
        height: 60vh;
            .hme-label{
                margin-bottom: 8px;
            }
            .hme-title{
                display:block;
                margin-bottom: 20px;
            }
        }
}

.hme-section{
    border:none;
}


