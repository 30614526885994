@import 'src/mixins/breakpoints.scss';

@include mobile-only {
    .hme-settings-display {
        &__header {
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 30px;

            & > *:not(:first-child):not(:last-child) {
                border: none;
                padding: 0;
            }
        }

        &__main-content {
            gap: 30px;
        }

        &__select-metric {
            order: 1;
            flex-basis: 100%;
            max-width: none;
            border: none;
        }

        &__section-content {
            flex-direction: column;
        }

        &__section-column {
            max-width: none;
        }

        &__section:not(:last-of-type) &__section-column:first-of-type {
            padding-bottom: 0;
            border-bottom: none;
        }

        .hme-tooltip {
            max-width: 90%;

            .tooltip-inner {
                font-size: 14px;
            }
        }
    }
}