.firmware-management-view {
    &__confirm-popup {
        .confirm-popup {
            &.modal-dialog {
                &.hme-modal-component {
                    max-width: 820px;
                }
            }

            .modal-footer {
                .hme-button-component {
                    margin: 10px;
                    min-width: 150px;
                }
            }
        }
    }

    &__confirm-details {
        &__subtitle {
            margin: 0 0 23px;
        }

        .hme-title {
            font-family: Assistant;
            font-size: 18px;
            font-weight: 600;
            letter-spacing: 0.18px;
            text-align: center;
            margin: 0 0 20px;
        }
        &__action {
            font-family: Assistant;
            font-size: 16px;
            font-weight: 600;
            line-height: 1.5;
            letter-spacing: 1.28px;
            text-align: center;
            text-transform: uppercase;
            margin: 0 0 15px;
        }
        &__sections {
            display: flex;
        }

        &__section {
            width: 50%;
            border-left: 1px solid #e5e5e5;
            padding-left: 20px;
            margin-left: 20px;
            box-sizing: content-box;

            &:first-child {
                border-left: none;
                padding-left: 0;
                margin-left: 0;
            }

            .hme-grid-row {
                padding: 0 5px;
            }

            .hme-grid-cell {
                .hme-grid-cell-content {
                    padding: 0 10px;
                }
            }
        }
    }
}
