.hme-admin-zoom-system-status-info.hme-section {
    padding-right: 50px;

    .hme-info-field ~ .hme-info-field {
        border-top: 1px solid #e8e9ed;
        margin-top: 10px;
        padding-top: 10px;
    }

    .hme-info-field {
        margin-top: 0;
        padding-top: 0;
        border-top: 0;

        .hme-info-field-content {
            overflow-wrap: break-word;
        }
    }

    .hme-info-field:first-child {
        border-top: 0;
    }
}
